import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {
  CognitoUser,
  Config, getCognitoUser,
  getConfig, getSelectedBusinessUnit, initialCognitoUserState,
  initialConfigState,
  isLoading, updateCognitoUser, updateConfig, updateScreenWidth, updateScrenHeight,
} from "./modules/redux";
import Amplify, {Auth} from 'aws-amplify';
import {Hub, Logger} from 'aws-amplify';
import {environment} from "../environments/environment";
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {delay, Subscription} from "rxjs";
import {loadLookup} from "./modules/redux/stores/lookups/lookups.actions";
import {ISignalr, LookupType} from "./models";
import {Router} from "@angular/router";
import {LookupService, SignalrService} from "./services";
import {IBusinessUnit} from "./modules/business-units/models";
import {isNavigating} from "./modules/redux/stores/config/config.selectors";
import {AppFade} from "./animations/animations";
import * as Sentry from "@sentry/angular-ivy";
import { IAppLoader } from './models/iloader';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [AppFade()]
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  appVersion = require('../../package.json').version;
  title = '';
  animationState = true;
  cognito$: Subscription = new Subscription();
  config$: Subscription = new Subscription();
  loading$: Subscription = new Subscription();
  navigating$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
  config: Config = initialConfigState;
  cognitoUser: CognitoUser = initialCognitoUserState;

  isLoading = false;
  message: string = '';
  isNavigating = false;

  // allNotification$: Subscription = new Subscription();

  constructor(
    private store: Store,
    public dialog: MatDialog,
    public toastrService: ToastrService,
    private router: Router,
    // private signalr: SignalrService
  ) {

    // Reza disabled Sentry for local work
    // Sentry.init({
    //   release: this.appVersion,
    //   environment: environment.sentry_trace,
    //   dsn: environment.sentry_dns,
    //   debug: !environment.production,
    //   integrations: [
    //     // Registers and configures the Tracing integration,
    //     // which automatically instruments your application to monitor its
    //     // performance, including custom Angular routing instrumentation
    //     Sentry.browserTracingIntegration(),
    //     // Registers the Replay integration,
    //     // which automatically captures Session Replays
    //     Sentry.replayIntegration(),
    //   ],
    //   // Set tracesSampleRate to 1.0 to capture 100%
    //   // of transactions for performance monitoring.
    //   // We recommend adjusting this value in production
    //   tracesSampleRate: 1.0,
    //   // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    //   tracePropagationTargets: [environment.sentry_trace, environment.sentry_origin],

    //   // Capture Replay for 10% of all sessions,
    //   // plus for 100% of sessions with an error
    //   replaysSessionSampleRate: 0.1,
    //   replaysOnErrorSampleRate: 1.0,
    // });

    //initialize amplify service
    Amplify.configure({
      Auth: environment.cognito.auth,
      //todo localStorage or sessionStorage determines if a user session continues across tabs
      // storage: sessionStorage
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      if (!!bu && !!bu.businessUnitId && this.selectedBusinessUnit.businessUnitId != bu.businessUnitId) {
        this.selectedBusinessUnit = bu;
        // this.allNotification$.unsubscribe();
        //todo the signalr connection is periodically lost and we need to implement
        // reconnecting to the connection when dropped
        // this.startConnection();
      }
    });

    //subscribe to load state
    this.loading$ = this.store.select(isLoading).pipe(delay(0)).subscribe((loader: IAppLoader) => {
      console.log("loading called", loader)
      this.isLoading = loader.loading;
      this.message = loader.message || '';

      if (this.isLoading == false) {
        // clear the message
        this.message = '';
      }
    });

    //subscribe to navigation state
    this.navigating$ = this.store.select(isNavigating).pipe(delay(0)).subscribe((navigating: boolean) => {
      this.isNavigating = navigating;
    });

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
      // Configure Sentry custom tags
      Sentry.setTags({
        "business-unit": this.config.selected_business_unit.businessUnitId,
        "selected-business-unit": this.config.editing_business_unit.businessUnitId
      });
    });

    this.cognito$ = this.store.select(getCognitoUser).subscribe((cognitoUser: CognitoUser) => {
      if (!!cognitoUser && cognitoUser.username != null) {
        this.cognitoUser = cognitoUser;
        // Configure Sentry custom user
        Sentry.setUser({
          id: this.cognitoUser.attributes.sub,
          email: this.cognitoUser.attributes.email,
          username: this.cognitoUser.attributes.given_name + ' ' + this.cognitoUser.attributes.family_name
        });
      }
      // if (cognito == null || cognito.username == null) {
      //   this.logOut();
      // } else {
      //   this.cognitoUser = cognito;
      //   // Configure Sentry custom user
      //   Sentry.setUser({
      //     id: this.cognitoUser.attributes.sub,
      //     email: this.cognitoUser.attributes.email,
      //     username: this.cognitoUser.attributes.given_name + ' ' + this.cognitoUser.attributes.family_name
      //   });
      // }
    });

    this.setUpHub();
  }

  ngAfterViewInit() {
    this.store.dispatch(loadLookup({lookup: LookupType.Public}));
  }

  public ngOnInit(): void {

  }

  // public startConnection() {
  //   // 1 - start a connection
  //   this.signalr.startConnection(this.selectedBusinessUnit.businessUnitId).then(() => {
  //     console.log("connected");

  //     // 2 - register for ALL relay
  //     this.signalr.listenToAllFeeds();

  //     // 3 - subscribe to the messages received
  //     this.allNotification$ = this.signalr.AllFeedObservable
  //       .subscribe((res: ISignalr) => {
  //         this.signalr.processFeed(res);
  //       });
  //   });
  // }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.loading$.unsubscribe();
    this.navigating$.unsubscribe();
    this.cognito$.unsubscribe();
    // this.allNotification$.unsubscribe();
  }

  /**
   * logs out of the app
   */
  public async logOut(): Promise<void> {
    Auth.signOut().then(() => {
      localStorage.clear();
      this.router.navigate(['/auth/login']).then(() => {
        this.store.dispatch(updateConfig({config: initialConfigState}));
        this.store.dispatch(updateCognitoUser({cognito: initialCognitoUserState}));
      });
    });
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.store.dispatch(updateScrenHeight({height: window.innerHeight}));
    this.store.dispatch(updateScreenWidth({width: window.innerWidth}));
    // console.log("Window Resize: " + window.innerHeight + " "  + window.innerWidth);
  }

  private setUpHub() {
    const logger = new Logger('My-Logger');
    const listener = (data: any) => {
      switch (data.payload.event) {
        case 'signIn':
          console.info('user signed in');
          break;
        case 'signUp':
          console.info('user signed up');
          break;
        case 'signOut':
          console.info('user signed out');
          break;
        case 'signIn_failure':
          console.error('user sign in failed');
          break;
        case 'tokenRefresh':
          console.info('token refresh succeeded');
          break;
        case 'tokenRefresh_failure':
          console.error('token refresh failed');
          break;
        case 'autoSignIn':
          console.info('Auto Sign In after Sign Up succeeded');
          break;
        case 'autoSignIn_failure':
          console.error('Auto Sign In after Sign Up failed');
          break;
        case 'configured':
          console.info('the Auth module is configured');
      }
    }
    Hub.listen('auth', listener);
  }

}
