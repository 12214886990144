<div style="height:calc(100vh - 64px); overflow-y: auto;">
  <div class="container-fluid">
    <br/><br/>
    <div class="row justify-content-center">
      <div class="col-12 col-md-5">
        <div style="display: flex;">
          <mat-icon color="primary" class="rm-header-icon">corporate_fare</mat-icon>&nbsp;
          <span class="rm-title rm-title-3">Business Units</span>
        </div>
        <span class="rm-title rm-title-6 rm-lighter">These are all of the business units you have access to:</span>
      </div>
      <div class="col-md-6 d-none d-md-block text-end ">
        <button mat-raised-button
                color="primary"
                (click)="openBusinessUnitModal()"
                [disabled]="!util.hasPermission(util.Permission.CAN_ADD_BUSINESS_UNITS)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_BUSINESS_UNITS) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_ADD_BUSINESS_UNITS) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
        >
          <mat-icon>group_add</mat-icon>&nbsp;
          Create New Business Unit
        </button>
      </div>
      <div class="col-12 d-flex d-md-none" style="padding-top: 25px;">
        <button mat-raised-button
                color="primary"
                (click)="openBusinessUnitModal()"
                [disabled]="!util.hasPermission(util.Permission.CAN_ADD_BUSINESS_UNITS)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_BUSINESS_UNITS) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_ADD_BUSINESS_UNITS) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
        >
          <mat-icon>group_add</mat-icon>&nbsp;
          Create New Business Unit
        </button>
      </div>
    </div>
    <!--    table start-->
    <div class="row justify-content-center d-none d-md-flex" style="padding-top: 25px;">
      <div class="col-12 col-md-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <table mat-table [dataSource]="dataSource" [multiTemplateDataRows]="true" matSort
                     (matSortChange)="sortData($event)">
                <!-- Search Header -->
                <ng-container matColumnDef="table-name">
                  <th colspan="4" mat-header-cell *matHeaderCellDef>
                    <span style="font-size: large;">Business Units</span>
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="5" mat-header-cell *matHeaderCellDef>
                    <form>
                      <mat-form-field style="width: 100%;">
                        <mat-label>Search</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input/>
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form>
                  </th>
                </ng-container>
                <!-- Display Name Column -->
                <ng-container matColumnDef="DisplayName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="displayName"
                      sortActionDescription="Sort by Business Unit"> Business Unit
                  </th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    <app-user-card [firstName]="element['displayName']"
                                   [displayName]="element['slug']"
                                   [borderColor]="'transparent'"
                                   [sideColor]="'#ff0000'"
                                   [image]="element['imageUrl']"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- Legal Entity Name Column -->
                <ng-container matColumnDef="InternalName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="internalName"
                      sortActionDescription="Sort by Legal Entity Name"> Legal Entity Name
                  </th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ element['internalName'] }}
                  </td>
                </ng-container>
                <!-- Slug Column -->
                <ng-container matColumnDef="Slug">
                  <th mat-header-cell *matHeaderCellDef> Business Unit Slug</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ element['slug'] }}
                  </td>
                </ng-container>
                <!-- Type Column -->
                <ng-container matColumnDef="Type">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="businessUnitType"
                      sortActionDescription="Sort by Profile Type"> Profile Type
                  </th>
                  <td mat-cell *matCellDef="let element"> {{ element["businessUnitType"]?.toUpperCase() }}
                  </td>
                </ng-container>
                <!-- User Column -->
                <ng-container matColumnDef="User">
                  <th mat-header-cell *matHeaderCellDef> Representative</th>
                  <td mat-cell
                      *matCellDef="let element"> {{ (element["representative"]?.["firstName"] || '') + ' ' + (element["representative"]?.["lastName"] || '') }}
                    <br/>
                    {{ element["representative"]?.["email"] || '' }}
                  </td>
                </ng-container>

                <!-- KYC Column -->
                <ng-container matColumnDef="KYC">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="kycStatus"
                      sortActionDescription="Sort by KYC Status">KYC Status
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span [appStatus]="element.kycStatus">{{ element['kycStatus'] }} </span>
                  </td>
                </ng-container>

                <!-- Submissions Column -->
                <ng-container matColumnDef="Submissions">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="submissions"
                      sortActionDescription="Sort by # of Submissions">Number of Submissions
                  </th>
                  <td mat-cell *matCellDef="let element" class="text-center">
                    <span>{{ element.submissionsCount }}</span>
                  </td>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="Employees">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="employees"
                      sortActionDescription="Sort by # of Employees">Number of Employees
                  </th>
                  <td mat-cell *matCellDef="let element" class="text-center">
                    <span>{{ element.employeesCount }}</span>
                  </td>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="Status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="status"
                      sortActionDescription="Sort by Business Unit Status">BU Status
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span [appStatus]="element.status">{{ element.status }}</span>
                  </td>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="Created">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="created"
                      sortActionDescription="Sort by Created Date"> Created
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="date-stacked">
                      <span>{{ element.created | date : 'mediumDate' }}</span>
                      <span>{{ element.created | date : 'shortTime' }}</span>
                    </div>
                  </td>
                </ng-container>

                <!-- Intake Desc Column -->
                <ng-container matColumnDef="Desc">
                  <th mat-header-cell *matHeaderCellDef> Intake Description</th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element['intakeDescription'] }} </span>
                  </td>
                </ng-container>

                <!-- Actions Column -->
                <ng-container matColumnDef="Actions">
                  <th mat-header-cell *matHeaderCellDef> Actions</th>
                  <td mat-cell *matCellDef="let element">
                    <div style="display: flex;">
                      <button mat-icon-button (click)="viewHistory(element)" matTooltip="View History"
                              matTooltipPosition="left">
                        <mat-icon>history</mat-icon>
                      </button>
                      <button mat-icon-button
                              (click)="viewBusinessUnit(element)"
                              [disabled]="!util.hasPermission(util.Permission.CAN_ACCESS_BUSINESS_UNITS)"
                              [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ACCESS_BUSINESS_UNITS) ? util.NoAccessCursor: ''}"
                              [matTooltip]="!util.hasPermission(util.Permission.CAN_ACCESS_BUSINESS_UNITS) ? util.NoAccessMsg : 'View Business Unit'"
                              matTooltipPosition="left"
                      >
                        <mat-icon>east</mat-icon>
                      </button>
                    </div>
                  </td>
                </ng-container>

                <!-- Row start-->
                <tr mat-header-row *matHeaderRowDef="['table-name','search-header'];"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"></tr>
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="7">No data matching the filter.</td>
                </tr>
                <!-- Row end-->
              </table>
            </div>
          </div>
          <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[100, 200, 500]"
                         [pageSize]="100"
                         aria-label="Select page of users">
          </mat-paginator>
        </div>
      </div>
    </div>
    <!--    list start-->
    <div class="row justify-content-center d-flex d-md-none" style="padding-bottom: 150px; padding-top: 15px;">
      <div class="col-12">
        <ul style="list-style-type: none; padding: 25px 0 0 0;">
          <li *ngFor="let element of dataSource.data"
              style="margin-bottom: 5px; display: flex; justify-content: space-between;">
            <app-user-card [firstName]="element?.displayName"
                           [displayName]="element?.slug"
                           [borderColor]="'transparent'"
                           [sideColor]="'#ff0000'"
                           [image]="element?.['imageUrl']"
                           [imageShape]="'square'"
            ></app-user-card>
            <div style="display: flex;">
              <button mat-icon-button (click)="viewHistory(element)" matTooltip="View History"
                      matTooltipPosition="left">
                <mat-icon>history</mat-icon>
              </button>
              <button mat-icon-button (click)="viewBusinessUnit(element)" matTooltip="View Business Unit"
                      matTooltipPosition="left">
                <mat-icon>east</mat-icon>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
