<div class="rm-header">
  <span>Import</span>
</div>
<form [formGroup]="form" (submit)="submit()">
  <mat-dialog-content>
    <!--    {{ data.collection | json }}-->

    <div class="row" *ngIf="!!data.collection" style="padding-top: 25px;">
      <div class="col-12">
        <span style="font-weight: bold;">1. Select a Business Entity...</span><br/><br/>
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Select Entity</mat-label>
          <mat-select
            id="selEnt"
            (selectionChange)="selectEntity($event.value)"
            [ngModel]="selectedEntity"
            [ngModelOptions]="{standalone: true}"
          >
            <mat-option *ngFor="let entity of data.collection" [value]="entity">
              {{ entity.businessUnitEntityName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <div class="col-12">
        <span style="font-weight: bold;">2. Select an Entity KYC...</span><br/><br/>
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Select KYC</mat-label>
          <mat-select
            id="selKyc"
            (selectionChange)="selectKyc($event.value)"
            [ngModel]="selectedKyc"
            [ngModelOptions]="{standalone: true}"
          >
            <mat-option *ngFor="let kyc of selectedEntity?.kyc" [value]="kyc">
              {{ kyc.kycName == '' ? '[' + kyc.kycId + ']' : kyc.kycName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>
      <!--          -->
      <!--  PEOPLE  -->
      <!--          -->
      <div class="col-12" *ngIf="data.type == 'people'">
        <span style="font-weight: bold;">3. Select Natural Person(s) to Import...</span><br/><br/>
        <table class="table table-striped form-table">
          <thead>
          <th class="form-table-header"></th>
          <th class="form-table-header">First Name</th>
          <th class="form-table-header">Last Name</th>
          <th class="form-table-header">Year of Birth</th>
          </thead>
          <tbody>
          <tr *ngFor="let person of selectedKyc?.naturalPersons">
            <td>
              <mat-checkbox
                color="primary"
                (change)="addOrRemoveId($event, person.id)"
                [ngModel]="getCheckboxState(person.id)"
                [ngModelOptions]="{standalone: true}"
              ></mat-checkbox>
            </td>
            <td>{{ person.firstName }}</td>
            <td>{{ person.lastName }}</td>
            <td>{{ person.yearofBirth }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <!--                  -->
      <!--  LEGAL ENTITIES  -->
      <!--                  -->
      <div class="col-12" *ngIf="data.type == 'legalentities'">
        <span style="font-weight: bold;">3. Select Legal Entity to Import...</span><br/><br/>
        <table class="table table-striped form-table">
          <thead>
          <th class="form-table-header"></th>
          <th class="form-table-header">Legal Name</th>
          <th class="form-table-header">Domicile</th>
          </thead>
          <tbody>
          <tr *ngFor="let entity of selectedKyc?.legalEntites">
            <td>
              <mat-checkbox
                color="primary"
                (change)="addOrRemoveId($event, entity.id)"
                [ngModel]="getCheckboxState(entity.id)"
                [ngModelOptions]="{standalone: true}"
              ></mat-checkbox>
            </td>
            <td>{{ entity.legalName }}</td>
            <td>{{ entity.legalDomicile }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </mat-dialog-content>

  <!--      row-->
  <mat-dialog-actions class="row justify-content-center" style="padding-top:25px;">
    <div class="col-6 text-start">
      <button mat-raised-button type="button" (click)="dialogRef.close()">
        Cancel
      </button>
    </div>
    <div class="col-6 text-end">
      <button mat-raised-button color="primary" type="submit">
        SUBMIT
      </button>
    </div>
  </mat-dialog-actions>
</form>
