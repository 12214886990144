import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {
  Config,
  getConfig,
  getLookups,
  getSelectedBusinessUnit,
  initialConfigState,
  initialLookupsState,
  initialThemeState,
  Lookups,
  Theme,
  toggleLoading,
  updateDealId,
  updateEditingBusinessUnit,
  updateSelectedEntityId,
  updateSelectedEntityKyc,
  updateSelectedEntityKycId
} from "../../../redux";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {EntitiesService} from "../../../entities/services/entities.service";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {UtilService} from "../../../shared/services";
import {IBusinessUnit} from "../../../business-units/models";
import {toggleNavigating} from "../../../redux/stores/config/config.actions";
import {ILookup} from "../../../../models";
import {EntityKycService} from "../../../entities/services/entity-kyc.service";
import {IBusinessUnitEntityKyc} from "../../models/kyc";

@Component({
  selector: 'app-business-unit-kyc-list',
  templateUrl: './business-unit-kyc-list.component.html',
  styleUrls: ['./business-unit-kyc-list.component.scss']
})
export class BusinessUnitKycListComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();

  dataSource: MatTableDataSource<IBusinessUnitEntityKyc> = new MatTableDataSource();

  kycList: IBusinessUnitEntityKyc[] = [];
  sortedKyc: IBusinessUnitEntityKyc[] = [];
  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;
  theme: Theme = initialThemeState;
  columnsToDisplay = ['business', 'entityName', 'entityType', 'kycName', 'kycStatus', 'kycCreated', 'kycModified', 'actions'];
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  constructor(private store: Store,
              private router: Router,
              private entityService: EntitiesService,
              private kycService: EntityKycService,
              private toastr: ToastrService,
              private dialog: MatDialog,
              public util: UtilService) {

    this.config$ = this.store.select(getConfig).subscribe((configState: any) => {
      this.config = configState;
    });

    this.lookup$ = this.store.select(getLookups).subscribe((lookups: any) => {
      this.lookups = lookups;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      if (!!bu && !!bu.businessUnitId) {
        this.subscribeToDatasource();
      }
    });
  }

  ngOnInit(): void {
    this.store.dispatch(updateEditingBusinessUnit({businessUnit: {} as IBusinessUnit}));
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
  }

  subscribeToDatasource() {
    console.log("Fetching Entity KYC...");
    this.store.dispatch(updateDealId({dealId: ''}));
    this.store.dispatch(toggleLoading({loading: true}));
    //fetch the users
    this.kycService.getEntityKycByBusinessUnitId(this.config.selected_business_unit.businessUnitId).subscribe({
      next: (entities: IBusinessUnitEntityKyc[]) => {
        this.kycList = entities;
        this.dataSource = new MatTableDataSource(this.kycList);
        this.initDataSource();
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: err => {
        this.toastr.error("Unable to get KYC!");
        this.store.dispatch(toggleLoading({loading: false}));
      }
    });
  }

  initDataSource() {
    this.dataSource.filterPredicate = (data: IBusinessUnitEntityKyc, filter: string) => {
      const lc_filter = filter.toLowerCase();
      let ENTITY_NAME = !!data.businessUnitEntityName ? data.businessUnitEntityName?.toLowerCase().includes(lc_filter) : false;
      let ENTITY_TYPE = !!data.businessUnitEntityType ? data.businessUnitEntityType?.toLowerCase().includes(lc_filter) : false;
      let KYC_NAME = !!data.kycName ? data.kycName?.toLowerCase().includes(lc_filter) : false;
      let KYC_STATUS = !!data.kycRondeivuStatus ? data.kycRondeivuStatus?.toLowerCase().includes(lc_filter) : false;

      return ENTITY_NAME || ENTITY_TYPE || KYC_NAME || KYC_STATUS;
    };
    if (!!this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  viewEntityKyc(element: IBusinessUnitEntityKyc) {
    if (!!element.businessUnitEntityKycId) {
      this.store.dispatch(updateSelectedEntityId({entityId: element.businessUnitEntityId}));
      this.store.dispatch(updateSelectedEntityKycId({kycId: element.businessUnitEntityKycId}));
      this.store.dispatch(updateSelectedEntityKyc({entityKyc: element}));
      this.store.dispatch(toggleNavigating({navigating: true}));
      setTimeout(() => {
        this.router.navigate(['/' + this.config.selected_business_unit.slug + '/entities/' + element.businessUnitEntityId + '/kyc/' + element.businessUnitEntityKycId]).then(() => {
          this.store.dispatch(toggleNavigating({navigating: false}));
          this.toastr.success(`Now viewing ${element.kycName} KYC`)
        });
      }, 1000);
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //sort
  sortData(sort: Sort) {
    const data = this.kycList.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedKyc = data;
      return;
    }

    this.sortedKyc = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'business':
          return compare(a.businessUnitMiniModel.displayName, b.businessUnitMiniModel.displayName, isAsc);
        case 'entityName':
          return compare(a.businessUnitEntityName, b.businessUnitEntityName, isAsc);
        case 'entityType':
          return compare(a.businessUnitEntityType, b.businessUnitEntityType, isAsc);
        case 'kycName':
          return compare(a.kycName, b.kycName, isAsc);
        case 'kycStatus':
          return compare(a.kycRondeivuStatus, b.kycRondeivuStatus, isAsc);
        case 'kycCreated':
          return compare(a.createdOn, b.createdOn, isAsc);
        case 'kycModified':
          return compare(a.lastModified, b.lastModified, isAsc);
        case 'kycSubmitted':
          return compare(a.submittedOn, b.submittedOn, isAsc);
        default:
          return 0;
      }
    });

    this.dataSource = new MatTableDataSource(this.sortedKyc);
    this.initDataSource();
  }

  getEntityTypeString(entity: IBusinessUnitEntityKyc) {
    if (!!this.config) {
      return this.getValueForKey(this.lookups.businessUnitEntityType, entity.businessUnitEntityType);
    }
    return '';
  }

  getValueForKey(lookup: ILookup[], key: string): string {
    let item: string = '';
    lookup.forEach((l: ILookup) => {
      if (l.key == key) {
        item = l.value;
      }
    });
    return item;
  }

  getStatusString(key: string) {
    if (!!this.config) {
      return this.util.getLookupValueForKey(this.lookups.kycRondeivuStatus, key);
    }
    return '';
  }
}


function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
