<div class="rm-header">
  <span *ngIf="!data">Add Employee</span>
  <span *ngIf="!!data">Edit Employee</span>
</div>

<div class="container-fluid">
  <!--header-->
  <div class="row justify-content-start" *ngIf="newEmployeeModalState">
    <div class="col-12 pt-4 pb-4">
      Please select a user to add as a Rondeivu employee from the dropdown below.
      <br/> 
      If an employee does not exist here, please create them as a user in the system from the 'Users' tab.
    </div>
  </div>

  <form [formGroup]="userForm" (ngSubmit)="submitEdit()">
    <!--  body-->
    <div class="row justify-content-center pb-2">
      <div class="col-12">
        <mat-dialog-content class="mat-typography" style="overflow: hidden;">
          <!--        EMPLOYEE ADD-->
          <div class="row" *ngIf="newEmployeeModalState">
            <div class="col-12" *ngIf="(potentialEmployees$ | async) as data">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Potential Rondeivu Employees</mat-label>
                <mat-select style="font-size: medium;" (selectionChange)="onSelectionChange($event)">
                  <mat-option *ngFor="let u of data" [value]="u" >
                    {{ u.firstName + ' ' + u.lastName + ' - ' + u.email }}
                  </mat-option>
                  <mat-hint align="end">These are the users that can be set as Rondeivu employees</mat-hint>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-12 pt-2">
              <p>
                Please note that after designating the user a Rondeivu Employee, you should set the user's Permissions under the 'My Company' navigation tab.
                <br />
                And set the user's public phone number and calendar via the edit icon.
              </p>
            </div>
          </div>
          <!--        EMPLOYEE ADD END-->
          <!--        EMPLOYEE EDIT-->
          <div class="row form-row pt-4" *ngIf="newEmployeeModalState == false">
            <div class="col-12">
              <!--        user menu button-->
              <app-user-card [firstName]="data.user['firstName'] "
                             [lastName]="data.user['lastName']"
                             [displayName]="data.user['email']"
                             [image]="data.user['imageUrl']"
                             [borderColor]="'transparent'"
                             [background]="'#F1EDE4'"
                             [sideColor]="'#002150'"
              ></app-user-card>
            </div>
            <div class="col-12 pt-4">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Link To Schedule (URL)</mat-label>
                <input id="link" matInput type="text" formControlName="calendarLink"
                       placeholder="i.e. https//calendly.com/rondeivu"/>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Public Notes</mat-label>
                <textarea id="notes" matInput formControlName="publicNotes"
                          style="min-height: 90px; max-height: 250px;"></textarea>
              </mat-form-field>
            </div>
            <div class="col-12 pt-4">
              <p>
                The phone number below will be displayed to the clients where this employee is their Rep!
              </p>
            </div>
            <div class="col-4">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Country Code</mat-label>
                <mat-select id="cc" formControlName="countryCode">
                  <mat-option *ngFor="let cc of lookupService.lookups.countryCodes" [value]="cc.key">
                    {{ cc.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-8">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label style="font-size: medium;">Phone Number</mat-label>
                <input class="form-input" matInput formControlName="mobile" [required]="true"/>
              </mat-form-field>
            </div>
          </div>

          <!--EMPLOYEE EDIT END-->
        </mat-dialog-content>
      </div>
    </div>
    <!--Navigation-->
    <div class="space-between" mat-dialog-actions>
      <button mat-raised-button type="button" (click)="dialogRef.close()">Cancel</button>
      <button mat-raised-button color="primary" type="button" (click)="addNewEmployee()"
              *ngIf="newEmployeeModalState" [disabled]="!selectedUser">Add New Employee
      </button>
      <button mat-raised-button *ngIf="newEmployeeModalState == false" color="primary" type="submit">Save Changes to Employee
      </button>
    </div>
  </form>
</div>






