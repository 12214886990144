import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Subscription} from "rxjs";
import {
  Config,
  getConfig,
  getLookups,
  initialConfigState,
  initialLookupsState,
  Lookups,
  toggleLoading
} from "../../../redux";
import {FormControl, FormGroup} from "@angular/forms";
import {UtilService} from "../../../shared/services";
import {Store} from "@ngrx/store";
import {UuidService} from "../../../../services";
import {ToastrService} from "ngx-toastr";
import {SubdocService} from "../../services/subdoc/subdoc.service";
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ISubDocField, ISubDocFieldsResponse, ISyndicationSubdoc} from "../../models/syndication";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-deal-subscription-subdoc-form-modal',
  templateUrl: './deal-subscription-subdoc-form-modal.component.html',
  styleUrls: ['./deal-subscription-subdoc-form-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DealSubscriptionSubdocFormModalComponent implements OnInit, OnDestroy {
  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;

  protected readonly FORM_ANSWERS = 'answers';

  form = new FormGroup({
    answers: new FormControl(),
  });

  fieldResponse: ISubDocFieldsResponse = {} as unknown as ISubDocFieldsResponse;
  fieldsList: ISubDocField[] = [];
  fieldHtml: string = '';
  splitFieldHtml: string[] = [];
  matches: string[] = [];

  @ViewChild('fileUpload') private fileInput: ElementRef | undefined;

  constructor(
    public util: UtilService,
    private store: Store,
    private uuid: UuidService,
    private toastr: ToastrService,
    private subDocService: SubdocService,
    private router: Router,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<DealSubscriptionSubdocFormModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { subdoc: ISyndicationSubdoc }) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });
  }

  ngOnInit(): void {
    if (!!this.data) {
      this.getFields();
    }
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
  }

  getFields() {
    console.log("Fetching Fields...");
    this.store.dispatch(toggleLoading({loading: true}));
    this.subDocService.getDocumentFields(this.data.subdoc.id).subscribe({
      next: (fields: ISubDocFieldsResponse) => {
        this.fieldResponse = fields;
        this.fieldsList = fields.fieldsList;
        // add the current answers to the form
        this.setAnswersFromFieldList(this.fieldsList);
        // parse the html
        this.processContent(this.fieldResponse.content);
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: err => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get fields!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  private setAnswersFromFieldList(fieldsList: ISubDocField[]) {
    let answers: IFormAnswerResponse[] = [];
    for (let x = 0; x <= fieldsList.length - 1; x++) {
      let uuid = fieldsList[x].uuid || '';
      let values = fieldsList[x].values || [];
      answers.push({uuid: uuid, values: values});
    }
    // set the form value
    this.form.get(this.FORM_ANSWERS)?.setValue(answers);
  }

  /**
   * Save the form fields
   * @param approveForSig
   */
  saveFields(approveForSig: boolean) {
    console.log("Updating Fields...");
    this.store.dispatch(toggleLoading({loading: true}));
    let payload = {
      fieldsList: this.form.get(this.FORM_ANSWERS)?.value || [],
      approveForInvestorSignatures: approveForSig
    };
    this.subDocService.updateDocumentFields(this.data.subdoc.id, payload).subscribe({
      next: (res: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Sub Doc Fields Updated!", $localize`:@@companyName:Rondeivu`);
      }, error: err => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get fields!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  /**
   * Parse the field response content html for '<x-concord-field>' tag
   * @param content the field response html
   * @private
   */
  private processContent(content: string) {
    const prefix = '<x-concord-field data-field-uuid="';
    const suffix = '"></x-concord-field>';
    const separator = '<span class="field-marker">**********</span>';
    const regex: RegExp = new RegExp(prefix + '.{36}' + suffix, 'g');
    const matches = content.match(regex);
    if (!!matches) {
      for (let i = 0; i <= matches.length - 1; i++) {
        this.matches?.push(matches[i].replace(prefix, '').replace(suffix, ''));
      }
    }

    const replacedContent = content.replace(regex, separator);
    const splitContent = replacedContent.split(separator);
    this.fieldHtml = replacedContent;
    this.splitFieldHtml = splitContent;
  }

  /**
   * Download the subdoc
   */
  downloadPdf() {
    this.downloadSubdoc(this.data.subdoc);
  }

  private downloadSubdoc(element: ISyndicationSubdoc) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.subDocService.getSubdocPdf(element.id).subscribe({
      next: (res: Blob) => {
        let filename = 'download.pdf';
        let blob: Blob = res as Blob;
        let a = document.createElement('a');
        a.download = filename;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.store.dispatch(toggleLoading({loading: false}));
      },
      error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to download file!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  submit(): void {
    // this.dialogRef.close();
  }

  getSafeHtml(content: string) {
    // Bypassing Angular HTML sanitizer
    return this.sanitizer
      .bypassSecurityTrustHtml(content);
  }

  inputChange(event: any) {
    this.updateFormAnswer(event.answer, FormAnswerType.INPUT);
  }

  checkChange(event: any) {
    this.updateFormAnswer(event.answer, FormAnswerType.CHECKBOX);
  }

  radioChange(event: any) {
    this.updateFormAnswer(event.answer, FormAnswerType.RADIO);
  }

  selectChange(event: any) {
    this.updateFormAnswer(event.answer, FormAnswerType.SELECT);
  }

  private updateFormAnswer(formAnswer: IFormAnswer, type: FormAnswerType) {
    this.updateCollectionQuestion(this.FORM_ANSWERS, formAnswer, type);
  }

  private updateCollectionQuestion(collection: string, formAnswer: IFormAnswer, type: FormAnswerType) {
    let answers: IFormAnswerResponse[] = this.form.get(collection)?.value || [];
    // check if answer exists already
    let answerSearch: {
      answer: IFormAnswerResponse | undefined,
      index: number
    } = this.getFormAnswerAndIdxByUuid(answers, formAnswer.uuid);
    let existingAnswer: IFormAnswerResponse | undefined = answerSearch.answer;
    // the answer exists
    if (!!existingAnswer) {
      // check for existing values
      let existingValues: string[] = existingAnswer.values || [];
      let valueFoundIdx = -1;
      if (existingValues.length > 0) {
        valueFoundIdx = existingValues.indexOf(formAnswer.value);
      }
      // handle based on form input type
      switch (type) {
        case FormAnswerType.RADIO:
        case FormAnswerType.INPUT:
        case FormAnswerType.SELECT:
          existingValues = Object.assign([]);
          existingValues.push(formAnswer.value);
          break;
        case FormAnswerType.CHECKBOX:
          if (valueFoundIdx >= 0) {
            existingValues.splice(valueFoundIdx, 1);
          } else {
            existingValues.push(formAnswer.value);
          }
          break;
        default:
          break;
      }
      // set the existing answer in the collection
      existingAnswer.values = existingValues;
      answers.splice(answerSearch.index, 1);
      answers.push(existingAnswer);
    } else {
      // no answer exists - create new
      let values = [];
      values.push(formAnswer.value);
      answers.push({
        uuid: formAnswer.uuid,
        values: values
      });
    }
    // set the form answers
    this.form.get(collection)?.setValue(answers);
    // console.log(JSON.stringify(this.form.get(collection)?.value));
  }


  /**
   * Helper function to get the existing answer object and index in one pass though the form collection
   * @param answers the form collection
   * @param uuid the id to match
   * @private
   */
  private getFormAnswerAndIdxByUuid(answers: IFormAnswerResponse[], uuid: string): {
    answer: IFormAnswerResponse | undefined,
    index: number
  } {
    let rtnAns;
    let idx = -1;
    if (!!answers) {
      for (let i = 0; i <= answers.length - 1; i++) {
        if (answers[i].uuid == uuid) {
          idx = i;
          rtnAns = answers[i];
          break;
        }
      }
    }
    return {answer: rtnAns, index: idx};
  }

}


interface IFormAnswerResponse {
  uuid: string;
  values: string[];
}

interface IFormAnswer {
  uuid: string;
  value: string;
}

enum FormAnswerType {
  INPUT = 'INPUT',
  CHECKBOX = 'CHECKBOX',
  RADIO = 'RADIO',
  SELECT = 'SELECT'
}
