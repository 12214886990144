<!--floating button-->
<app-floater
  [icon]="'description'"
  [topPos]="'200px'"
  [rightPos]="'5px'"
  [bgColor]="theme.pill_1_bg"
  [color]="theme.pill_1_fg"
  (onClick)="toggleDrawer($event)"
  [hidden]="!config.show_notes_tab"
  [toolTip]="'Deal Team Notes'"
></app-floater>
<!--floating button end-->
<!--right nav start-->
<mat-drawer-container id="rightnav" *ngIf="!!theme && !!config">
  <!--  drawer start-->
  <mat-drawer
    mode="over"
    [opened]="config.right_drawer_open"
    (openedChange)="closeDrawer($event)"
    position="end"
    id="right-drawer"
    [ngStyle]="{ 'background-color': theme.nav_bg, 'width': util.getDrawerWidth() }"
  >
    <mat-tab-group
      id="rightTabs"
      [selectedIndex]="config.right_drawer_tab_idx"
      (selectedIndexChange)="changeTab($event)"
      [ngStyle]="{
        'background-color': theme.header_bg,
        color: theme.header_fg
      }"
    >
      <!-- notes -->
      <mat-tab>
        <ng-template mat-tab-label>
          <!-- <mat-icon class="example-tab-icon">thumb_up</mat-icon> -->
          <div [ngStyle]="{ color: theme.header_fg }">Notes</div>
        </ng-template>
        <div class="right-content" [ngStyle]="{'background-color': theme.content_bg,color: theme.content_fg}"
             style="overflow-y: hidden;">
          <app-notes></app-notes>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-drawer>
  <!--  drawer end-->
  <mat-drawer-content id="content">
    <!-- toolbar start-->
    <mat-toolbar id="toolbar-nav" class="mat-primary" *ngIf="!!theme"
                 [ngStyle]="{'background-color': theme.header_bg,color: theme.header_fg}"
                 [hidden]="!config.show_navigation_toolbar" style="display: flex; justify-content: space-between;">
      <div id="img-box-nav" (click)="home()">
        <img id="app-img-nav" src="assets/images/Rondeivu_Logo_Lockup-3_white.png"
             style="width: 100%; object-fit: contain;">
      </div>
      <!-- context button -->
      <div class="user-right">
        <!--        inbox button-->
        <button mat-mini-fab color="primary" style="margin: 10px;" [matMenuTriggerFor]="navigation"
                *ngIf="!!cognitoUser" class="d-md-none">
          <mat-icon>menu</mat-icon>
        </button>
        <!--        inbox button-->
        <div [matMenuTriggerFor]="tasks" *ngIf="!!cognitoUser">
          <button mat-mini-fab color="primary" style="margin: 10px;" class="d-md-block">
            <mat-icon style="height: 40px; width: 40px;"
                      [matBadge]="(!!config.tasks && config.tasks.length > 0) ? config.tasks.length : ''"
                      matBadgeColor="warn">
              inbox
            </mat-icon>
          </button>
        </div>
        <!--        user menu button-->
        <div style="padding: 10px 0;">
          <app-user-card [matMenuTriggerFor]="menu" *ngIf="!!cognitoUser"
                         [firstName]="cognitoUser?.['attributes']?.['given_name']"
                         [lastName]="cognitoUser?.['attributes']?.['family_name']"
                         [displayName]="config.selected_business_unit.displayName?.toUpperCase()"
                         [borderColor]="'transparent'"
                         [sideColor]="'transparent'"
                         [background]="'#FFFFFF'"
                         [size]="userCardSize.MD"
                         [image]="selectedBusinessUnit.imageUrl"
          ></app-user-card>
        </div>
        <!--user menu end-->
      </div>
      <!-- NAV MENU START-->
      <mat-menu #navigation="matMenu" id="navigation">
        <ul style="list-style-type: none; padding: 15px;margin: 0;">
          <li [matTooltip]="obj.toolTip"
              matTooltipPosition="right"
              *ngFor="let obj of links"
              (click)="navigate(obj)"
              routerLinkActive="active-title"
              [hidden]="obj.hidden"
              style="width: 100%;"
          >
            <button mat-button [disabled]="!obj.enabled"
                    style="width: 100%; text-align: left;">
              <mat-icon>{{ obj.icon }}</mat-icon>&nbsp;
              <span style="line-height: 24px;">{{ obj.title }}</span>
            </button>
          </li>
        </ul>
      </mat-menu>
      <!--NAV MENU END-->
      <!-- TASK MENU START-->
      <mat-menu #tasks="matMenu" id="tasks">
        <div style="display: flex; padding: 10px 8px;">
          <div class="text-center task-icon">
            <mat-icon>info_outline</mat-icon>
          </div>
          <h3 style="margin: 0; font-weight: bold;">Notifications & Tasks</h3>
        </div>
        <ul id="task-item-list">
          <li *ngFor="let t of config.tasks">
            <div *ngIf="!!cognitoUser" class="task-item"
                 [ngStyle]="{'background-color':getTaskBg(t)}">
              <div class="text-center task-icon">
                <mat-icon>{{ getTaskImage(t.status) }}</mat-icon>
              </div>
              <div>
                <span style="font-size:medium; font-weight: bold;">{{ t?.title }}</span><br/>
                <span style="font-size:small;">{{ t?.message }}</span><br/>
                <span style="font-size:small; font-weight: lighter; cursor: pointer; color: #0033ff;"
                      (click)="goToTask(t)"><u>{{ t?.linkText }}</u></span>
              </div>
            </div>
          </li>
          <li *ngIf="config.tasks.length == 0" class="task-item">
            <span style="font-size:medium;">You have no tasks.</span>
          </li>
        </ul>
      </mat-menu>
      <!--TASK MENU END-->
      <!--     USER MENU START-->
      <mat-menu #menu="matMenu" id="menu">
        <div *ngIf="!!cognitoUser" style="width: 100%; text-align: center; padding: 24px 16px; gap: 16px;">
          <div style="width: 90%; margin:auto;">
            <app-user-card [firstName]="cognitoUser?.['attributes']?.['given_name']"
                           [lastName]="cognitoUser?.['attributes']?.['family_name']"
                           [displayName]="cognitoUser?.['attributes']?.['email']"
                           [borderColor]="'transparent'"
                           [sideColor]="'#002150'"
                           [background]="'#FFF'"
                           [size]="userCardSize.LG"
                           [image]="userSettings.imageUrl"
            >
            </app-user-card>
          </div>
          <br/>
          <div>
            <span style="font-weight: bold;">{{ config.selected_business_unit_role.role?.name || 'Consultant' }}</span>
          </div>
          <br/>
          <button mat-raised-button (click)="navToProfile()">
            <span style="color: #0047AB">MANAGE PROFILE</span>
          </button>
        </div>


        <div *ngIf="!!config?.business_units"
             style=" border-top: 1px solid rgba(128,128,128,0.50); border-bottom: 1px solid rgba(128,128,128,0.50);">

          <div class="business-row" *ngFor="let businessUnit of config.business_units; index as i;"
               [ngStyle]="{'background-color': getRowColor(businessUnit)}">
            <app-user-card
              (click)="selectBusinessUnit(businessUnit)"
              [firstName]="businessUnit.displayName"
              [displayName]="businessUnit['businessUnitType'].toUpperCase()"
              [image]="businessUnit['imageUrl']"
              [size]="userCardSize.MD"
              [ngStyle]="{'cursor':!businessUnit.isEmployeeActive ? util.NoAccessCursor: ''}"
              [matTooltip]="!businessUnit.isEmployeeActive ? 'You have been temporarily suspended' : ''"
              matTooltipPosition="left"
            >
            </app-user-card>
          </div>
        </div>

        <div style="width: 100%; text-align: center; padding: 25px;">
          <button mat-raised-button color="primary" (click)="logOut()" style="height: auto;width: auto;">
            <!--            <mat-icon>logout</mat-icon>-->
            <span>SIGN OUT</span>
          </button>
        </div>

        <div style="width: 100%; text-align: center; padding: 5px;">
          <span>Version: {{ appVersion }}</span>
        </div>
        <div style="width: 100%; text-align: center; padding: 5px;">
          <span>API Version: {{ apiVersion }}</span>
        </div>

      </mat-menu>
      <!--      MENU END-->
      <!--      MENU END-->
      <!--      MENU END-->
    </mat-toolbar>
    <!-- toolbar end -->
    <mat-drawer-container id="sidenav">
      <mat-drawer id="drawer"
                  mode="side"
                  [opened]="config.show_navigation_menu"
                  [ngStyle]="{'background':theme.nav_bg}"
                  class="d-none d-md-block" [disableClose]="true">
        <div id="nav-list" *ngIf="!!links">
          <mat-nav-list dense style="height:100%">
            <mat-list-item [matTooltip]="obj.toolTip"
                           matTooltipPosition="right"
                           [class]="getActiveRouteClass(obj)"
                           *ngFor="let obj of links"
                           (click)="navigate(obj)"
                           routerLinkActive="active-title"
                           [hidden]="obj.hidden"
            >
              <!-- nav item content -->
              <mat-icon matListIcon [ngStyle]="{'color': isActiveRoute(obj)? theme.nav_highlight : theme.nav_fg}">
                {{ obj.icon }}
              </mat-icon>
              <div>
                <span class="nav-title" style="font-size: small;"
                      [ngStyle]="{'color': isActiveRoute(obj)? theme.nav_highlight : theme.nav_fg}">
                  {{ obj.title }}
                </span>
              </div>
            </mat-list-item>
            <mat-list-item style="color: white;" (click)="contactUs()">
              <mat-icon style="color: #ffffff;" matListIcon>help</mat-icon>
              <a>
                <span style="font-size: small; color: #ffffff; font-weight: bold;">Contact Us</span>
              </a>
            </mat-list-item>
          </mat-nav-list>
        </div>
        <div id="drawer-bg"></div>
        <div style="position: absolute; top: calc(90vh - 36px);"></div>
      </mat-drawer>
      <mat-drawer-content
        [ngStyle]="{
          'background-color': theme.content_bg,
          color: theme.content_fg
        }" style="overflow: hidden;"
      >
        <div>
          <div [@appFade]="animationState">
            <div *ngIf="show">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </mat-drawer-content>
</mat-drawer-container>
<!-- right nav end -->
