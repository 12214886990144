<div class="rm-header">
  <span>Rondeivu</span>
</div>
<mat-dialog-content #scroll 
  (scroll)="onScroll($event)"
  style="height: 350px; overflow-y: auto;">
  <div>
    <h1>Terms & Conditions</h1>
    <div>
      <span style="font-size:small; color: orange; margin-left:auto;">
        <mat-icon style="padding-top: 5px;">warning</mat-icon>Please read and scroll to the bottom of the Terms & Conditions before accepting
      </span>
    </div>
    <br/>
    <p [innerHTML]="data.bodyHtml"></p>
  </div>

</mat-dialog-content>

<mat-dialog-actions style="display: flex; justify-content: space-between;">
  <button mat-raised-button (click)="logOut()">Logout</button>
  <button mat-raised-button color="primary" (click)="action(data)"
          [disabled]="disableAgreementButton">Agree to Terms & Conditions
  </button>
</mat-dialog-actions>






