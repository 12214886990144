<div class="rm-header">
  <span>Decline Deal</span>
</div>

<div class="row justify-content-center pt-3 pb-2">
  <div class="col-12">
    <span>Please let us know why you choose to decline this deal:</span>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-12 text-center">
    <form>
      <mat-form-field class="form-field">
        <mat-label>Reason</mat-label>
        <textarea id="reason" name="reason" [(ngModel)]="data.reason" matInput placeholder="Type here..."
                  [required]="true"></textarea>
      </mat-form-field>
    </form>
  </div>
</div>
<div class="space-between">
  <button mat-raised-button (click)="dialogRef.close()">Cancel</button>
  <button mat-raised-button color="warn" (click)="dialogRef.close(data)" [disabled]="!data.reason || data.reason == ''">Decline Deal</button>
</div>
