import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-authorized-signatory',
  templateUrl: './add-authorized-signatory.component.html',
  styleUrls: ['./add-authorized-signatory.component.scss']
})
export class AddAuthorizedSignatoryComponent {

  fg: FormGroup = new FormGroup({
    authorizedSignatory: new FormControl(undefined, Validators.required),
    companyName: new FormControl(undefined, Validators.required),
    ownership: new FormControl(undefined, [Validators.required, Validators.min(0), Validators.max(100)])
  });
}
