import {Injectable} from '@angular/core';
import {HttpHeaders} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {
  IDealInfoRequest,
  IDealSettingsRequest,
  IDealRequest,
  ICommunicationRequest,
  IDealHighlightsRequest
} from "../../models";
import {RondeivuHttpClient} from "../../../../handlers";
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {Config, getConfig, initialConfigState} from "../../../redux";
import {ISubdocAccessPerson} from "../../models/syndication";

@Injectable({
  providedIn: 'root'
})
export class DealService {

  private config: Config = initialConfigState;
  private readonly BASE_URL = environment.rondeivu_api;
  private readonly FRAGMENT = '/deals';

  private readonly HIGHLIGHTS = '/highlights';
  private readonly DECLINE = '/decline-deal';
  private readonly CHECK_MNPI = '/check-mnpi';
  private readonly INVESTOR_CALL_REQ = '/investor-call-request';
  private readonly INVESTOR_NDA_UPDATE = '/investor-nda-update';

  //deal metrics
  private readonly DEAL_INFO = '/deal-info';
  //deal information
  private readonly INFO_FRAGMENT = '/dealinformation';
  private readonly UPLOAD_FRAGMENT = '/upload-image';
  //deal settings
  private readonly SETTINGS_FRAGMENT = '/dealsettings';
  //deal signatories
  private readonly SIGNATORIES_FRAGMENT = '/signatories';

  constructor(private store: Store, private http: RondeivuHttpClient) {
    this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
  }

  /**
   *
   *
   * DEAL TILE
   *
   *
   */

  /**
   * Add a new deal
   * @param deal the deal request
   */
  addDeal(deal: IDealRequest): Observable<any> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.BASE_URL + this.FRAGMENT, deal, {headers: httpHeaders});
  }

  /**
   * Get deals based on status filter
   * @param status
   */
  getDeals(status?: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + (!!status ? '?status=' + status : ''));
  }

  /**
   * Get a deal by id
   * @param id
   */
  getDeal(id: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + '/' + id);
  }

  /**
   * Get the deal highlight information
   */
  getDealHighlights(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.HIGHLIGHTS + '/' + this.config.selected_deal_id);
  }

  /**
   * investor only - decline the deal
   */
  declineDeal(reason: string): Observable<any> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.DECLINE + '/' + this.config.selected_deal_id, {reason: reason}, {headers: httpHeaders});
  }

  /**
   * investor only - check mnpi status
   */
  checkMnpi(): Observable<any> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.CHECK_MNPI + '/' + this.config.selected_deal_id, '', {headers: httpHeaders});
  }

  /**
   * investor only - accept mnpi disclaimer
   */
  investorCallRequest(payload: ICommunicationRequest): Observable<any> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.INVESTOR_CALL_REQ + '/' + this.config.selected_deal_id, payload, {headers: httpHeaders});
  }

  /**
   * Update the Deal NDA status
   */
  investorNdaUpdate(payload: IDealHighlightsRequest): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.INVESTOR_NDA_UPDATE + '/' + this.config.selected_deal_id, payload);
  }


  /**
   *
   *
   * DEAL METRICS
   *
   *
   */

  /**
   * Get the Deal Metrics information
   */
  getDealMetrics(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.DEAL_INFO + '/' + this.config.selected_deal_id);
  }

  /**
   * Update the Deal Metrics
   * @param deal
   */
  updateDealMetrics(deal: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.DEAL_INFO + '/' + this.config.selected_deal_id, deal);
  }


  /**
   *
   *
   * DEAL INFORMATION
   *
   *
   */


  /**
   * Get the deal information
   */
  getDealInformation(): Observable<any> {
    return this.http.get(this.BASE_URL + this.INFO_FRAGMENT + '/' + this.config.selected_deal_id);
  }

  /**
   * Update the deal information for the selected deal id
   * @param dealInfo
   */
  updateDealInfo(dealInfo: IDealInfoRequest): Observable<any> {
    return this.http.put(this.BASE_URL + this.INFO_FRAGMENT + '/' + this.config.selected_deal_id, dealInfo);
  }

  /**
   * Upload the deal image for the selected deal id
   */
  uploadDealImage(file: any, type: string): Observable<any> {
    let formData = new FormData();
    formData.append("formFile", file);
    return this.http.post(this.BASE_URL + this.INFO_FRAGMENT + this.UPLOAD_FRAGMENT + '/' + this.config.selected_deal_id + '/' + type, formData);
  }


  /**
   *
   *
   * DEAL SETTINGS
   * N.B. These functions query the /dealsettings API
   *
   *
   */


  /**
   * Get the deal setting for the selected deal id
   */
  getDealSettings(): Observable<any> {
    return this.http.get(this.BASE_URL + this.SETTINGS_FRAGMENT + '/' + this.config.selected_deal_id);
  }

  /**
   * Update the deal settings for the selected deal id
   * @param dealSettings
   */
  updateDealSettings(dealSettings: IDealSettingsRequest): Observable<any> {
    return this.http.put(this.BASE_URL + this.SETTINGS_FRAGMENT + '/' + this.config.selected_deal_id, dealSettings);
  }

  /**
   *
   *
   * DEAL SIGNATORIES
   *
   *
   */


  /**
   * Get the deal signatories for the selected deal id
   */
  getDealSignatories(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.SIGNATORIES_FRAGMENT + '/' + this.config.selected_deal_id);
  }

  /**
   * Update the deal signatories for the selected deal id
   * @param dealSignatories
   */
  updateDealSignatories(dealSignatories: ISubdocAccessPerson[]): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.SIGNATORIES_FRAGMENT + '/' + this.config.selected_deal_id, dealSignatories);
  }


}
