<div class="container-fluid" style="height: calc(100vh - 64px); overflow-y: auto;">
  <br/> <br/>
  <div class="row justify-content-center" style="padding-bottom: 25px;">
    <div class="col-11">
      <div style="display:flex; padding-bottom: 5px;">
        <div>
          <mat-icon color="primary">phone</mat-icon>
        </div>&nbsp;
        <span class="rm-title rm-title-3">Contact Us</span>
      </div>
      <br/>
      <span>Thank you for choosing <b>Rondeivu</b>!<br/> Please contact us with any queries or questions.</span>
    </div>
  </div>
  <div class="row justify-content-center" style="padding: 0 50px;">
    <div class="col-12" *ngIf="!!contact && contact.user; else displayGeneralInfo">
      <h2>Your Rondeivu Representative</h2>
      <div class="col-12" *ngIf="!!contact.user.imageUrl">
        <img [src]="contact.user.imageUrl" style="width: 150px; height: 150px; border-radius: 50%;" alt="">
      </div>
      <div class="col-12">
        <div style="margin-top: 5px; display: inline-flex;">
          <mat-icon color="accent">person</mat-icon>
          <span
            style="margin-top: 5px; margin-left: 5px; font-weight: bold;">{{ contact.user.firstName + ' ' + contact.user.lastName }}
          </span>
        </div>
      </div>
      <div class="col-12" *ngIf="contact.user.mobile">
        <div style="margin-top: 5px; display: inline-flex;">
          <mat-icon color="accent">phone</mat-icon>
          <span
            style="margin-top: 4px; margin-left: 5px;">{{ contact.user.countryCode + ' ' + contact.user.mobile }}</span>
        </div>
      </div>
      <div class="col-12">
        <div style="margin-top: 5px; display: inline-flex;">
          <mat-icon color="accent">email</mat-icon>
          <span style="margin-top: 4px; margin-left: 5px;">
            <a href="mailto:{{contact.user.email}}">{{contact.user.email}}</a>
          </span><br/>
        </div>
      </div>
      <div class="col-12" *ngIf="contact.calendarLink">
        <div style="margin-top: 5px; display: inline-flex;">
          <mat-icon color="accent">event</mat-icon>
          <span style="margin-top: 4px; margin-left: 5px;">
            <a [href]="contact.calendarLink" target="_blank">{{contact.calendarLink}}</a>
          </span><br/>
        </div>
      </div>

    </div>


    <ng-template class="col-12" #displayGeneralInfo>
      <h2>Rondeivu General Help Line</h2>
      <div class="col-12">
        <div style="margin-top: 5px; display: inline-flex;">
          <mat-icon color="accent">person</mat-icon>
          <span style="margin-top: 5px; margin-left: 5px; font-weight: bold;">Rondeivu General Helpline</span>
        </div>
      </div>
      <!-- <div class="col-12">
        <div style="margin-top: 5px; display: inline-flex;">
          <mat-icon color="accent">phone</mat-icon>
          <span
            style="margin-top: 4px; margin-left: 5px;">{{ contact.user.countryCode + ' ' + contact.user.mobile }}</span>
        </div>
      </div> -->
      <div class="col-12">
        <div style="margin-top: 5px; display: inline-flex;">
          <mat-icon color="accent">email</mat-icon>
          <span style="margin-top: 4px; margin-left: 5px;">
            <a href="mailto:info@rondeivu.com">info&#64;rondeivu.com</a>
          </span><br/>
        </div>
      </div>
    </ng-template>
</div>
