import { IBusinessUnit } from "../../business-units/models";
import { IUserDTO } from "../../kyc/models/kyc";

export enum NdaType {
  ISSUER_NDA = "ISSUER_NDA",
  INVESTOR_DEAL_BY_DEAL_NDA = "INVESTOR_DEAL_BY_DEAL_NDA",
  INVESTOR_GLOBAL_NDA = "INVESTOR_GLOBAL_NDA"
}

export interface INdaResponse {
  comments: string;
  investorShowDealNdaOption: boolean;
  investorShowGlobalNdaOption: boolean;
  issuerShowDealNdaOption: boolean;
  ndaItems: INdaResponseItem[];
  showMNPIRequirement: boolean;
  ndaPassed: boolean;
}

export interface INdaResponseItem {
  id: string;
  dealId: string;
  projectName: string;
  type: string;
  status: string;
  businessUnit: IBusinessUnit;
  signatory: IUserDTO;
  created: Date;
  envelopId: string;
}
