export interface IPermissionGroup {
  id: string;
  name: string;
  description: string;
  permissionType: string;
  sortOrder: number;
  permissionOptions: IPermissionOption[];
  icon: string;
}

export interface IPermissionOption {
  id: string;
  name: string;
  description: string;
  permissionGroupId: string;
  sortOrder: number;
  icon: string;
  color: string;
}

export interface IEmployeeRole {
  id: string;
  name: string;
  description: string;
  isCustomRole: boolean;
  permissionGroups: IEmployeePermissionGroup[];
}

export interface IEmployeePermissionGroup {
  id: string;
  permissionGroupId: string;
  permissionGroupOptionId: string;
}

export enum Permission {

  // Business Unit Permissions

  CAN_LIST_DEALS = 'CAN_LIST_DEALS', // Not used in FE
  CAN_ACCESS_DEALS = 'CAN_ACCESS_DEALS',
  CAN_ADD_DEALS = 'CAN_ADD_DEALS', // new deal button
  CAN_ACCESS_MANDATES = 'CAN_ACCESS_MANDATES',
  CAN_ADD_MANDATES = 'CAN_ADD_MANDATES', // new mandate button
  CAN_LIST_MANDATES = 'CAN_LIST_MANDATES', // Not used in FE
  CAN_EDIT_MANDATES = 'CAN_EDIT_MANDATES', // save mandate info
  CAN_DELETE_MANDATES = 'CAN_DELETE_MANDATES', //  Deleting mandates is not supported
  CAN_ACCESS_MANDATE_NOTES = 'CAN_ACCESS_MANDATE_NOTES', // only when viewing a mandate (admin only)
  CAN_ADD_MANDATE_NOTES = 'CAN_ADD_MANDATE_NOTES', // only when viewing a mandate (admin only)
  CAN_EDIT_MANDATE_NOTES = 'CAN_EDIT_MANDATE_NOTES', // only when viewing a mandate (admin only)
  CAN_DELETE_MANDATE_NOTES = 'CAN_DELETE_MANDATE_NOTES', // only when viewing a mandate (admin only)
  CAN_ACCESS_KYC = 'CAN_ACCESS_KYC',
  CAN_LIST_KYC = 'CAN_LIST_KYC', // Not used in FE
  CAN_EDIT_KYC = 'CAN_EDIT_KYC',
  CAN_ADD_KYC = 'CAN_ADD_KYC',
  CAN_SUBMIT_KYC = 'CAN_SUBMIT_KYC',
  CAN_DELETE_KYC = 'CAN_DELETE_KYC',
  CAN_CHANGE_KYC_STATUS = 'CAN_CHANGE_KYC_STATUS',
  CAN_ACCESS_KYC_DATAROOM = 'CAN_ACCESS_KYC_DATAROOM', //todo this should be provided in the if can_list_kyc
  CAN_EDIT_KYC_DATAROOM = 'CAN_EDIT_KYC_DATAROOM',
  CAN_ACCESS_KYC_DATAROOM_DOWNLOAD_ALL = 'CAN_ACCESS_KYC_DATAROOM_DOWNLOAD_ALL',
  CAN_EDIT_COMPANY_LOGO = 'CAN_EDIT_COMPANY_LOGO',
  CAN_LIST_COMPANY = 'CAN_LIST_COMPANY', // Not used in FE
  CAN_EDIT_COMPANY_EMPLOYEES = 'CAN_EDIT_COMPANY_EMPLOYEES',
  CAN_LIST_COMPANY_EMPLOYEES = 'CAN_LIST_COMPANY_EMPLOYEES', // Not used in FE
  CAN_ADD_COMPANY_EMPLOYEES = 'CAN_ADD_COMPANY_EMPLOYEES',
  CAN_ACCESS_COMPANY = 'CAN_ACCESS_COMPANY', // Not used in FE
  CAN_DELETE_COMPANY_EMPLOYEES = 'CAN_DELETE_COMPANY_EMPLOYEES',
  CAN_ACCESS_DASHBOARD = 'CAN_ACCESS_DASHBOARD',
  CAN_EDIT_BU_APPROVALS_ISSUER = 'CAN_EDIT_BU_APPROVALS_ISSUER',
  CAN_EDIT_BU_APPROVALS_INVESTOR = 'CAN_EDIT_BU_APPROVALS_INVESTOR',
  CAN_ACCESS_APPROVALS_INVESTOR = 'CAN_ACCESS_APPROVALS_INVESTOR',
  CAN_LIST_APPROVALS = 'CAN_LIST_APPROVALS', // Not used in FE
  CAN_ACCESS_APPROVALS_ISSUER = 'CAN_ACCESS_APPROVALS_ISSUER',
  CAN_EDIT_APPROVALS_INVESTOR = 'CAN_EDIT_APPROVALS_INVESTOR',
  CAN_EDIT_APPROVALS_ISSUER = 'CAN_EDIT_APPROVALS_ISSUER',
  CAN_DELETE_BUSINESS_UNITS = 'CAN_DELETE_BUSINESS_UNITS', // not needed
  CAN_EDIT_BUSINESS_UNITS_SIMPLE = 'CAN_EDIT_BUSINESS_UNITS_SIMPLE',
  CAN_ACCESS_BUSINESS_UNITS = 'CAN_ACCESS_BUSINESS_UNITS',
  CAN_EDIT_BUSINESS_UNITS_FULL = 'CAN_EDIT_BUSINESS_UNITS_FULL',
  CAN_LIST_BUSINESS_UNITS = 'CAN_LIST_BUSINESS_UNITS', // Not used in FE
  CAN_CHANGE_BUSINESS_UNITS_STATUS = 'CAN_CHANGE_BUSINESS_UNITS_STATUS',
  CAN_ADD_BUSINESS_UNITS = 'CAN_ADD_BUSINESS_UNITS',
  CAN_ACCESS_BUSINESS_UNITS_NOTES = 'CAN_ACCESS_BUSINESS_UNITS_NOTES', // only when viewing a business unit (admin only)
  CAN_ADD_BUSINESS_UNITS_NOTES = 'CAN_ADD_BUSINESS_UNITS_NOTES', // only when viewing a business unit (admin only)
  CAN_EDIT_BUSINESS_UNITS_NOTES = 'CAN_EDIT_BUSINESS_UNITS_NOTES', // only when viewing a business unit (admin only)
  CAN_DELETE_BUSINESS_UNITS_NOTES = 'CAN_DELETE_BUSINESS_UNITS_NOTES', // only when viewing a business unit (admin only)
  CAN_ADD_USERS = 'CAN_ADD_USERS',
  CAN_ACCESS_USERS = 'CAN_ACCESS_USERS',
  CAN_EDIT_USERS = 'CAN_EDIT_USERS',
  CAN_LIST_USERS = 'CAN_LIST_USERS', // Not used in FE
  CAN_ACCESS_LEGALS = 'CAN_ACCESS_LEGALS', // Not used in FE
  CAN_LIST_LEGALS = 'CAN_LIST_LEGALS', // Not used in FE
  CAN_EDIT_LEGALS = 'CAN_EDIT_LEGALS', // Not used in FE
  CAN_LIST_SUPER_ADMIN = 'CAN_LIST_SUPER_ADMIN', // Not used in FE
  CAN_ACCESS_SUPER_ADMIN = 'CAN_ACCESS_SUPER_ADMIN', // Not used in FE
  CAN_EDIT_SUPER_ADMIN = 'CAN_EDIT_SUPER_ADMIN', // Not used in FE

  // Sub Doc Permissions
  CAN_ACCESS_DEAL_SUBDOCS = 'CAN_ACCESS_DEAL_SUBDOCS',
  CAN_ADD_DEAL_SUBDOCS = 'CAN_ADD_DEAL_SUBDOCS',
  CAN_ACCESS_DEAL_SUBDOCS_PDF = 'CAN_ACCESS_DEAL_SUBDOCS_PDF',
  CAN_DELETE_DEAL_SUBDOCS = 'CAN_DELETE_DEAL_SUBDOCS',
  CAN_EDIT_DEAL_SUBDOCS_STATUS = 'CAN_EDIT_DEAL_SUBDOCS_STATUS',
  CAN_EDIT_DEAL_SUBDOCS_APPROVE_INVESTOR = 'CAN_EDIT_DEAL_SUBDOCS_APPROVE_INVESTOR',
  CAN_EDIT_DEAL_SUBDOCS_FILL_INVESTOR = 'CAN_EDIT_DEAL_SUBDOCS_FILL_INVESTOR',
  CAN_EDIT_DEAL_SUBDOCS_APPROVE_ISSUER = 'CAN_EDIT_DEAL_SUBDOCS_APPROVE_ISSUER',
  CAN_EDIT_DEAL_SUBDOCS_APPROVE_ADMIN = 'CAN_EDIT_DEAL_SUBDOCS_APPROVE_ADMIN',
  CAN_ADD_DEAL_SUBDOCS_UPLOAD = 'CAN_ADD_DEAL_SUBDOCS_UPLOAD',
  CAN_DOWNLOAD_DEAL_SUBDOCS_UPLOAD = 'CAN_DOWNLOAD_DEAL_SUBDOCS_UPLOAD',
  CAN_DOWNLOAD_ALL_DEAL_SUBDOCS_UPLOAD = 'CAN_DOWNLOAD_ALL_DEAL_SUBDOCS_UPLOAD',
  CAN_DELETE_DEAL_SUBDOCS_UPLOAD = 'CAN_DELETE_DEAL_SUBDOCS_UPLOAD',

  // Global Deal Team Permissions
  CAN_ACCESS_DEAL_DECLINE = 'CAN_ACCESS_DEAL_DECLINE',
  CAN_ACCESS_DEAL_INVESTOR_MATCH_EXCLUSION_LIST = 'CAN_ACCESS_DEAL_INVESTOR_MATCH_EXCLUSION_LIST',
  CAN_EDIT_DEAL_INVESTOR_MATCH_EXCLUSION_LIST = 'CAN_EDIT_DEAL_INVESTOR_MATCH_EXCLUSION_LIST',
  CAN_EDIT_DEAL_INVESTOR_MATCH_INTERNAL_SCORE = 'CAN_EDIT_DEAL_INVESTOR_MATCH_INTERNAL_SCORE',
  CAN_EDIT_DEAL_INVESTOR_MATCH = 'CAN_EDIT_DEAL_INVESTOR_MATCH',
  CAN_EDIT_DEAL_STATUS = 'CAN_EDIT_DEAL_STATUS',
  CAN_ACCESS_AUDITS = 'CAN_ACCESS_AUDITS',
  CAN_ACCESS_DEAL_NOTES = 'CAN_ACCESS_DEAL_NOTES', // only when viewing a deal
  CAN_EDIT_DEAL_NOTES = 'CAN_EDIT_DEAL_NOTES', // only when viewing a deal
  CAN_EDIT_DEAL_ALL_NOTES = 'CAN_EDIT_DEAL_ALL_NOTES',
  CAN_ADD_DEAL_NOTES = 'CAN_ADD_DEAL_NOTES', // only when viewing a deal
  CAN_DELETE_DEAL_NOTES = 'CAN_DELETE_DEAL_NOTES', // only when viewing a deal
  CAN_DELETE_DEAL_ALL_NOTES = 'CAN_DELETE_DEAL_ALL_NOTES',
  CAN_ACCESS_NOTIFICATIONS = 'CAN_ACCESS_NOTIFICATIONS',
  CAN_ACCESS_DEAL_INFO = 'CAN_ACCESS_DEAL_INFO', // Not used in FE
  CAN_EDIT_DEAL_INFO = 'CAN_EDIT_DEAL_INFO', // save deal info
  CAN_INITIATE_DEAL_NDA = 'CAN_INITIATE_DEAL_NDA',
  CAN_EDIT_DEAL_NDA = 'CAN_EDIT_DEAL_NDA',
  CAN_ACCESS_DEAL_NDA = 'CAN_ACCESS_DEAL_NDA', // Not used in FE
  CAN_NEGOTIATE_DEAL_NDA = 'CAN_NEGOTIATE_DEAL_NDA',
  CAN_ACCESS_DEAL_DATAROOM = 'CAN_ACCESS_DEAL_DATAROOM', // Not used in FE
  CAN_EDIT_DEAL_DATAROOM = 'CAN_EDIT_DEAL_DATAROOM', // dataroom toolbar buttons and dNd upload
  CAN_ACCESS_DEAL_DATAROOM_DOWNLOAD_ALL = 'CAN_ACCESS_DEAL_DATAROOM_DOWNLOAD_ALL', //todo
  CAN_ACCESS_DEAL_DOCUMENTS = 'CAN_ACCESS_DEAL_DOCUMENTS', // Not used in FE
  CAN_EDIT_DEAL_DOCUMENTS = 'CAN_EDIT_DEAL_DOCUMENTS', // dataroom toolbar buttons and dNd upload
  CAN_ACCESS_DEAL_DOCUMENTS_DOWNLOAD_ALL = 'CAN_ACCESS_DEAL_DOCUMENTS_DOWNLOAD_ALL', //todo
  CAN_ACCESS_DEAL_MEDIA = 'CAN_ACCESS_DEAL_MEDIA', // todo remove this usage
  CAN_DELETE_DEAL_MEDIA = 'CAN_DELETE_DEAL_MEDIA',
  CAN_EDIT_DEAL_MEDIA = 'CAN_EDIT_DEAL_MEDIA',
  CAN_ADD_DEAL_MEDIA = 'CAN_ADD_DEAL_MEDIA',
  CAN_ACCESS_DEAL_INVESTOR_COMMUNICATIONS = 'CAN_ACCESS_DEAL_INVESTOR_COMMUNICATIONS', // Not used in FE
  CAN_EDIT_DEAL_INVESTOR_COMMUNICATIONS = 'CAN_EDIT_DEAL_INVESTOR_COMMUNICATIONS',
  CAN_ADD_DEAL_SYNDICATION = 'CAN_ADD_DEAL_SYNDICATION', // can indicate interest button
  CAN_ACCESS_DEAL_SYNDICATION = 'CAN_ACCESS_DEAL_SYNDICATION', // Not used in FE
  CAN_LOCK_DEAL_SYNDICATION = 'CAN_LOCK_DEAL_SYNDICATION',
  CAN_EDIT_DEAL_SYNDICATION = 'CAN_EDIT_DEAL_SYNDICATION',
  CAN_DELETE_DEAL_SYNDICATION = 'CAN_DELETE_DEAL_SYNDICATION',
  CAN_ACCESS_DEAL_SUBSCRIPTION = 'CAN_ACCESS_DEAL_SUBSCRIPTION', // Not used in FE
  CAN_ADD_DEAL_SUBSCRIPTION = 'CAN_ADD_DEAL_SUBSCRIPTION', // can subscribe button
  CAN_LOCK_DEAL_SUBSCRIPTION = 'CAN_LOCK_DEAL_SUBSCRIPTION',
  CAN_DELETE_DEAL_SUBSCRIPTION = 'CAN_DELETE_DEAL_SUBSCRIPTION',
  CAN_EDIT_DEAL_SUBSCRIPTION = 'CAN_EDIT_DEAL_SUBSCRIPTION',
  CAN_ADD_DEAL_TEAMMEMBERS = 'CAN_ADD_DEAL_TEAMMEMBERS',
  CAN_EDIT_DEAL_TEAMMEMBERS = 'CAN_EDIT_DEAL_TEAMMEMBERS',
  CAN_DELETE_DEAL_TEAMMEMBERS = 'CAN_DELETE_DEAL_TEAMMEMBERS',
  CAN_ACCESS_DEAL_TEAMMEMBERS = 'CAN_ACCESS_DEAL_TEAMMEMBERS', // Not used in FE
  CAN_ACCESS_DEAL_HIGHLIGHTS = 'CAN_ACCESS_DEAL_HIGHLIGHTS',
  CAN_EDIT_DEAL_HIGHLIGHTS = 'CAN_EDIT_DEAL_HIGHLIGHTS',
  CAN_ACCESS_DEAL_METRICS = 'CAN_ACCESS_DEAL_METRICS', // Not used in FE
  CAN_EDIT_DEAL_METRICS = 'CAN_EDIT_DEAL_METRICS', // save deal metrics
  CAN_ACCESS_DEAL_SETTINGS = 'CAN_ACCESS_DEAL_SETTINGS', // Not used in FE
  CAN_ACCESS_DEAL_SETTINGS_ADMIN = 'CAN_ACCESS_DEAL_SETTINGS_ADMIN', // hide or show admin panel on deal settings page
  CAN_EDIT_DEAL_SETTINGS = 'CAN_EDIT_DEAL_SETTINGS', // save deal settings
  CAN_EDIT_DEAL_SETTINGS_ADMIN = 'CAN_EDIT_DEAL_SETTINGS_ADMIN'
}
