import {createReducer, on} from '@ngrx/store';
import * as ConfigActions from './config.actions';
import {initialConfigState} from "./config.init";

// the key feature
export const configFeatureKey = 'config';

// the update reducer
export const configReducer = createReducer(
  initialConfigState,
  on(ConfigActions.updateConfig, (state, {config}) => ({
      screenHeight: config.screenHeight,
      screenWidth: config.screenWidth,
      is_loading: config.is_loading,
      is_navigating: config.is_navigating,
      show_navigation_menu: config.show_navigation_menu,
      show_navigation_toolbar: config.show_navigation_toolbar,
      show_notes_tab: config.show_notes_tab,
      right_drawer_open: config.right_drawer_open,
      right_drawer_tab_idx: config.right_drawer_tab_idx,
      rondeivu_app_title: config.rondeivu_app_title,
      rondeivu_header_title: config.rondeivu_header_title,
      rondeivu_address_1: config.rondeivu_address_1,
      rondeivu_address_2: config.rondeivu_address_2,
      rondeivu_address_3: config.rondeivu_address_3,
      rondeivu_email: config.rondeivu_email,
      rondeivu_phone: config.rondeivu_phone,
      rondeivu_api: config.rondeivu_api,
      rondeivu_api_base_uri: config.rondeivu_api_base_uri,
      rondeivu_theme_dark: config.rondeivu_theme_dark,
      user_groups: config.user_groups,
      nav_items: config.nav_items,
      selected_user_view: config.selected_user_view,
      selected_deal_id: config.selected_deal_id,
      selected_deal: config.selected_deal,
      deal_tabs: config.deal_tabs,
      selected_deal_role: config.selected_deal_role,
      selected_deal_root_folders: config.selected_deal_root_folders,
      selected_business_unit: config.selected_business_unit,
      selected_business_unit_role: config.selected_business_unit_role,
      selected_business_unit_entity_id: config.selected_business_unit_entity_id,
      selected_business_unit_entity: config.selected_business_unit_entity,
      business_units: config.business_units,
      editing_business_unit: config.editing_business_unit,
      editing_business_unit_employee: config.editing_business_unit_employee,
      selected_mandate_id: config.selected_mandate_id,
      selected_mandate: config.selected_mandate,
      selected_deal_team_id: config.selected_deal_team_id,
      selected_investor_deal_match_id: config.selected_investor_deal_match_id,
      selected_user_id: config.selected_user_id,
      selected_user: config.selected_user,
      selected_legal_id: config.selected_legal_id,
      selected_legal: config.selected_legal,
      selected_kyc_id: config.selected_kyc_id,
      selected_kyc: config.selected_kyc,
      deal_subscription: config.deal_subscription,
      deal_syndication: config.deal_syndication,
      tasks: config.tasks,
      selected_employee_id: config.selected_employee_id,
      selected_employee: config.selected_employee,
      selected_deal_team_member: config.selected_deal_team_member,
      selected_entity: config.selected_entity,
      selected_entity_id: config.selected_entity_id,
      selected_entity_kyc: config.selected_entity_kyc,
      selected_entity_kyc_id: config.selected_entity_kyc_id
    }
    )
  ),
  on(ConfigActions.updateScrenHeight, (state, {height}) => ({...state, screenHeight: height})),
  on(ConfigActions.updateScreenWidth, (state, {width}) => ({...state, screenWidth: width})),
  on(ConfigActions.toggleDarkMode, (state, {mode}) => ({...state, rondeivu_theme_dark: mode})),
  on(ConfigActions.toggleLoading, (state, appLoader) => ({...state, is_loading: appLoader })),
  on(ConfigActions.toggleNavigating, (state, {navigating}) => ({...state, is_navigating: navigating})),
  on(ConfigActions.updateDrawerState, (state, {open}) => ({...state, right_drawer_open: open})),
  on(ConfigActions.updateDrawerTabIdx, (state, {idx}) => ({...state, right_drawer_tab_idx: idx})),
  on(ConfigActions.updateUserGroups, (state, {groups}) => ({...state, user_groups: groups})),
  on(ConfigActions.updateNavItems, (state, {items}) => ({...state, nav_items: items})),
  on(ConfigActions.updateSelectedUserView, (state, {view}) => ({...state, selected_user_view: view.toLowerCase()})),
  on(ConfigActions.showNavigationMenu, (state, {show}) => ({...state, show_navigation_menu: show})),
  on(ConfigActions.showNavigationToolbar, (state, {show}) => ({...state, show_navigation_toolbar: show})),
  on(ConfigActions.showNotesTab, (state, {show}) => ({...state, show_notes_tab: show})),
  on(ConfigActions.updateDealId, (state, {dealId}) => ({...state, selected_deal_id: dealId})),
  on(ConfigActions.updateDeal, (state, {deal}) => ({...state, selected_deal: deal})),
  on(ConfigActions.updateDealTabs, (state, {tabs}) => ({...state, deal_tabs: tabs})),
  on(ConfigActions.updateDealRole, (state, {dealRole}) => ({...state, selected_deal_role: dealRole})),
  on(ConfigActions.updateDealDocumentRootFolders, (state, {rootFolders}) => ({
    ...state,
    selected_deal_root_folders: rootFolders
  })),
  on(ConfigActions.updateBusinessUnits, (state, {businessUnits}) => ({...state, business_units: businessUnits})),
  on(ConfigActions.updateSelectedBusinessUnit, (state, {businessUnit}) => ({
    ...state,
    selected_business_unit: businessUnit
  })),
  on(ConfigActions.updateSelectedBusinessUnitRole, (state, {businessUnitRole}) => ({
    ...state,
    selected_business_unit_role: businessUnitRole
  })),
  on(ConfigActions.updateSelectedBusinessUnitEntityId, (state, {businessUnitEntityId}) => ({
    ...state,
    selected_business_unit_entity_id: businessUnitEntityId
  })),
  on(ConfigActions.updateSelectedBusinessUnitEntity, (state, {businessUnitEntity}) => ({
    ...state,
    selected_business_unit_entity: businessUnitEntity
  })),
  on(ConfigActions.updateEditingBusinessUnit, (state, {businessUnit}) => ({
    ...state,
    editing_business_unit: businessUnit
  })),
  on(ConfigActions.updateEditingBusinessUnitEmployee, (state, {businessUnitEmployee}) => ({
    ...state,
    editing_business_unit_employee: businessUnitEmployee
  })),
  on(ConfigActions.updateSelectedDealTeamId, (state, {selectedDealTeamId}) => ({
    ...state,
    selected_deal_team_id: selectedDealTeamId
  })),
  on(ConfigActions.updateMandateId, (state, {mandateId}) => ({...state, selected_mandate_id: mandateId})),
  on(ConfigActions.updateMandate, (state, {mandate}) => ({...state, selected_mandate: mandate})),
  on(ConfigActions.updateUserId, (state, {userId}) => ({...state, selected_user_id: userId})),
  on(ConfigActions.updateUser, (state, {user}) => ({...state, selected_user: user})),
  on(ConfigActions.updateLegalId, (state, {legalId}) => ({...state, selected_legal_id: legalId})),
  on(ConfigActions.updateLegal, (state, {legal}) => ({...state, selected_legal: legal})),
  on(ConfigActions.updateKycId, (state, {kycId}) => ({...state, selected_kyc_id: kycId})),
  on(ConfigActions.updateKyc, (state, {kyc}) => ({...state, selected_kyc: kyc})),
  on(ConfigActions.updateDealSubscription, (state, {dealSubscription}) => ({
    ...state,
    deal_subscription: dealSubscription
  })),
  on(ConfigActions.updateDealSyndication, (state, {dealSyndication}) => ({
    ...state,
    deal_syndication: dealSyndication
  })),
  on(ConfigActions.updateTasks, (state, {tasks}) => ({...state, tasks: tasks})),
  on(ConfigActions.updateEmployeeId, (state, {employeeId}) => ({...state, selected_employee_id: employeeId})),
  on(ConfigActions.updateEmployee, (state, {employee}) => ({...state, selected_employee: employee})),
  on(ConfigActions.updateDealTeamMember, (state, {dealTeamMember}) => ({
    ...state,
    selected_deal_team_member: dealTeamMember
  })),
  on(ConfigActions.updateSelectedEntity, (state, {entity}) => ({
    ...state,
    selected_entity: entity
  })),
  on(ConfigActions.updateSelectedEntityId, (state, {entityId}) => ({
    ...state,
    selected_entity_id: entityId
  })),
  on(ConfigActions.updateSelectedEntityKyc, (state, {entityKyc}) => ({
    ...state,
    selected_entity_kyc: entityKyc
  })),
  on(ConfigActions.updateSelectedEntityKycId, (state, {kycId}) => ({
    ...state,
    selected_entity_kyc_id: kycId
  }))
);

