import {Injectable} from '@angular/core';
import {Store} from "@ngrx/store";
import {RondeivuHttpClient} from "../../../../handlers";
import {UuidService} from "../../../../services";
import {Config, getConfig, initialConfigState} from "../../../redux";
import {environment} from "../../../../../environments/environment";
import {HttpHeaders} from "@angular/common/http";
import { Observable } from 'rxjs';
import { IDealSyndication, ISyndicationItem } from '../../models/syndication';

@Injectable({
  providedIn: 'root'
})
export class SyndicationService {

  private config: Config = initialConfigState;
  private readonly BASE_URL = environment.rondeivu_api;
  private readonly FRAGMENT = '/syndication';
  private readonly INTEREST = '/indicate-interest';
  private readonly LOCK = '/change-lock-status';

  constructor(private store: Store, private http: RondeivuHttpClient, private uuid: UuidService) {
    this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
  }

  /**
   * Get the syndication data
   */
  getSyndication() : Observable<IDealSyndication> {
    return this.http.get<IDealSyndication>(this.BASE_URL + this.FRAGMENT + '/' + this.config.selected_deal_id);
  }

  /**
   * The investor calls this endpoint when the indication of interest is submitted on draw down deals
   */
  indicateInterest(syndication: { amount: number, restrictionType: string, notes: string }) {
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.INTEREST + '/' + this.config.selected_deal_id, syndication);
  }

  /**
   * Admin only delete an indication of interest from the syndication pool
   */
  deleteSyndication(syndication: any) {
    return this.http.delete(this.BASE_URL + this.FRAGMENT + '/' + syndication.id);
  }

  /**
   * Update the allocation amount of the submitted syndication
   */
  updateSyndication(syndication: any) {
    return this.http.put(this.BASE_URL + this.FRAGMENT + '/' + syndication.id, syndication);
  }

  /**
   * Change the subscription lock status
   */
  changeLockStatus(lock: any) {
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.LOCK + '/' + this.config.selected_deal_id, lock);
  }

  /**
   * Change syndication status
   */
  changeSyndicationStatus(syndicationId: string, payload: any) {
    return this.http.put(this.BASE_URL + this.FRAGMENT + '/status/' + syndicationId, payload);
  }

}
