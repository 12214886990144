import { Injectable } from '@angular/core';
import { IAppUser, INavItem } from 'src/app/models';
import { IBusinessUnit, IBusinessUnitRole } from 'src/app/modules/business-units/models';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  // All BUs the user has access to
  businessUnits: IBusinessUnit[] = [];


  // The currently selected BU (from a user profile perspective, this is not the selected BU while Admin is browsing BUs)
  currentBusinessUnit?: IBusinessUnit;
  currentBusinessUnitProfile?: string;

  // current user info
  currentUser?: IAppUser;

  // current employee info / role / permissions

  currentEmployeeId?: string;
  currentEmployeeRole?: IBusinessUnitRole;
  currentEmployeePermissions?: any;

  // navigation
  navItems: INavItem[] =[];

  

  constructor() { }

  getBuSlug() : string { return this.currentBusinessUnit?.slug || '' }
  getBuId() : string { return this.currentBusinessUnit?.id || '' }

  public print() {
    console.log("------------- Data Store Dump -------------");
    console.log("BUs", this.businessUnits);
    console.log("currentBu", this.currentBusinessUnit);
    console.log("AppUser", this.currentUser);
    console.log("Nav Items", this.navItems)
  }
}
