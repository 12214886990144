<div class="deal-window-container">
  <div class="deal-toolbar space-between" [ngStyle]="{'background-color':theme.subheader_bg, 'color':theme.subheader_fg}">
    <span style="display: flex; align-items: center; gap: 10px;">
      <button mat-icon-button (click)="goBack()" title="List Deals">
        <mat-icon>list</mat-icon>
      </button>
      <button style="background-color: orange;" mat-mini-fab [matMenuTriggerFor]="alerts"
              *ngIf="!!selectedDeal?.dealAlerts && selectedDeal.dealAlerts.length > 0">
        <mat-icon style="color: white;" [matBadge]="selectedDeal.dealAlerts.length" matBadgeColor="warn">warning
        </mat-icon>
      </button>
      <button style="background-color: green; cursor: default;" mat-mini-fab
              *ngIf="!!selectedDeal.dealAlerts && selectedDeal.dealAlerts.length == 0">
        <mat-icon style="color: white; height: 40px; width: 40px;">verified</mat-icon>
      </button>
      <!-- <mat-spinner *ngIf="loading" [diameter]="36" color="accent"></mat-spinner> -->

      <!-- ALERT MENU START-->
      <mat-menu #alerts="matMenu" id="alerts">
        <ul id="alert-item-list">
          <li *ngFor="let t of selectedDeal?.dealAlerts">
            <div class="task-item"
                  [ngStyle]="{'background-color':getTaskBg(t)}">
              <div class="text-center task-icon">
                <mat-icon>{{getTaskImage(t.status)}}</mat-icon>
              </div>
              <div>
                <span style="font-size:medium; font-weight: bold;">{{t?.title}}</span><br/>
                <span style="font-size:small;">{{t?.message}}</span><br/>
                <span style="font-size:small; font-weight: lighter; cursor: pointer; color: #0033ff;"
                      (click)="goToTask(t)"><u>{{t?.linkText}}</u></span>
              </div>
            </div>
          </li>
        </ul>
      </mat-menu>
      <!--ALERT MENU END-->
      <span>{{ selectedDeal.projectName || 'Viewing Deal' }}</span>


    </span>


    <span style="display: flex; align-items: center; gap: 2px;">

      <div *ngIf="allowApproval()" style="display: flex; align-items: center; gap: 15px;">
        <button  mat-raised-button color="primary"
                (click)="approveDeal(selectedDeal)">
                ADMIN Approve Deal
        </button>
        <button mat-raised-button color="warn"
                (click)="declineDeal(selectedDeal)">
                ADMIN Decline Deal
        </button>
      </div>

      <!--      issuer actions-->
      <div *ngIf="util.isIssuer()" style="display: flex; align-items: center; gap: 15px;">
        <button mat-raised-button color="primary" 
          *ngIf="selectedDeal.isPreNda" 
          (click)="investorSignNda(selectedDeal)">
          Sign NDA
        </button>
      </div>

      <!--      investor actions-->
      <div *ngIf="util.isInvestor()" style="display: flex; align-items: center; gap: 15px;">
        <button mat-raised-button color="warn"
                (click)="investorDecline(selectedDeal)"
                [disabled]="!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_DECLINE) || (selectedDeal.investorDealWorkflow == 'DECLINED')"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_DECLINE) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_DECLINE) ? util.NoAccessMsg : ''"
                matTooltipPosition="left">
          Decline Deal
        </button>
        <button mat-raised-button color="primary"
                *ngIf="selectedDeal.isPreNda && selectedDeal.investorDealWorkflow != 'DECLINED'"
                (click)="investorSignNda(selectedDeal)"
                [disabled]="!util.hasPermission(util.Permission.CAN_INITIATE_DEAL_NDA)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_INITIATE_DEAL_NDA) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_INITIATE_DEAL_NDA) ? util.NoAccessMsg : ''"
                matTooltipPosition="left">
                Sign NDA
        </button>
        <button mat-raised-button color="primary"
                *ngIf="!selectedDeal.isPreNda && selectedDeal.dealCloseType?.toLowerCase() === 'drawdown'"
                (click)="investorIndicateInterest(selectedDeal)"
                [disabled]="!util.hasPermission(util.Permission.CAN_ADD_DEAL_SYNDICATION)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_DEAL_SYNDICATION) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_ADD_DEAL_SYNDICATION) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
                >Indicate Interest
        </button>
        <button mat-raised-button color="primary"
                *ngIf="!selectedDeal.isPreNda && selectedDeal.dealCloseType?.toLowerCase() === 'evergreen'"
                (click)="investorSubscribe(selectedDeal)"
                [disabled]="!util.hasPermission(util.Permission.CAN_ADD_DEAL_SUBSCRIPTION)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_DEAL_SUBSCRIPTION) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_ADD_DEAL_SUBSCRIPTION) ? util.NoAccessMsg : ''"
                matTooltipPosition="left">
                Subscribe
        </button>
      </div>
    </span>
  </div>
  <div class="deal-navbar">
    <nav id="deal-nav" dense
        mat-tab-nav-bar
        style="width: 100%"
        [ngStyle]="{'background-color': theme.subheader_bg}"
  >
    <div *ngFor="let link of links">
      <a *ngIf="link.enabled"
          mat-tab-link
          (click)="activeLink = link; navigate(link)"
          [active]="activeLink.link == link.link"
          [disabled]="!link.enabled"
          [hidden]="link.hidden"
          [ngStyle]="{'background-color':theme.subheader_bg, 'color':theme.subheader_fg}"
      ><span>{{ link.title }}</span></a>
      <span class="fake-tab" *ngIf="!link.enabled" [matTooltip]="link.toolTip" [hidden]="link.hidden"
            matTooltipPosition="below">{{ link.title }}</span>
      </div>
    </nav>
  </div>
  <div class="deal-content-container">
    <router-outlet></router-outlet>
  </div>
</div>