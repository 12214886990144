import {RouterModule, Routes} from "@angular/router";
import {PageNotFoundComponent} from "../../components";
import {NgModule} from "@angular/core";
import {
  BusinessUnitDealsComponent,
  BusinessUnitEmployeeInfoComponent,
  BusinessUnitEmployeeInnerNavigationComponent,
  BusinessUnitEmployeeNavigationComponent,
  BusinessUnitEmployeePermissionsComponent,
  BusinessUnitEmployeesComponent, BusinessUnitEntityComponent,
  BusinessUnitInfoComponent,
  BusinessUnitKycComponent,
  BusinessUnitMandatesComponent,
  BusinessUnitsListComponent,
  BusinessUnitsNavigationComponent, BusinessUnitThirdPartyDeterminationComponent
} from "./components";
import {SelectedBusinessUnitResolver} from "../../resolvers/selected-business-unit.resolver";
import {BusinessUnitEmployeeResolver} from "../../resolvers/business-unit-employee.resolver";


const routes: Routes = [
  {path: '', component: BusinessUnitsListComponent},
  {
    path: ':id', component: BusinessUnitsNavigationComponent,
    resolve: { SelectedBusinessUnitResolver },
    children: [
      {path: 'information', component: BusinessUnitInfoComponent},
      {path: 'regulatory', component: BusinessUnitThirdPartyDeterminationComponent},
      {path: 'entities', component: BusinessUnitEntityComponent},
      {path: 'deals', component: BusinessUnitDealsComponent},
      {path: 'mandates', component: BusinessUnitMandatesComponent},
      {
        path: 'employees', component: BusinessUnitEmployeeNavigationComponent,
        children: [
          {path: '', component: BusinessUnitEmployeesComponent},
          {
            path: ':id', component: BusinessUnitEmployeeInnerNavigationComponent,
            resolve: {
              BusinessUnitEmployeeResolver
            },
            children: [
              {path: 'account', component: BusinessUnitEmployeeInfoComponent},
              {path: 'permissions', component: BusinessUnitEmployeePermissionsComponent},
              {path: '', redirectTo: 'information', pathMatch: 'full'}
            ]
          }
        ]
      },
      {path: 'kyc', component: BusinessUnitKycComponent},
      {path: '', redirectTo: 'information', pathMatch: 'full'},
      {path: '**', redirectTo: 'information'}
    ]
  },
  {path: '', redirectTo: '', pathMatch: 'full'},
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BusinessUnitsRoutingModule {
}
