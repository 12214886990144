<div class="rm-header">
  <span>{{ data.title }}</span>
</div>
<mat-dialog-content>
  <div style="padding-bottom: 25px; padding-top: 25px; min-width: 150px;">
    <span style="font-size: large;">{{ data.message }}</span>
  </div>
</mat-dialog-content>
<mat-dialog-actions style="display: flex; justify-content: space-between;">
  <button mat-raised-button (click)="dialogRef.close()">Cancel</button>
  <button mat-raised-button color="primary" (click)="dialogRef.close(true)" cdkFocusInitial>{{ data.btnText }}</button>
</mat-dialog-actions>
