<div class="rm-header">
  <span style="font-size: x-large;">{{data.isEditing ? 'Edit' : 'Add'}} Employee</span>
</div>
<br/>
<div class="row justify-content-center" style="padding-bottom: 20px;" *ngIf="!!data.employee.user">
  <div class="col-11">
    <!--        user menu button-->
    <app-user-card [firstName]="data.employee?.user['firstName'] "
                   [lastName]="data.employee?.user['lastName']"
                   [displayName]="data.employee?.user['email']"
                   [image]="data.employee?.user['imageUrl']"
                   [borderColor]="'transparent'"
                   [background]="'#F1EDE4'"
                   [sideColor]="'#002150'"
    ></app-user-card>
  </div>
</div>
<form [formGroup]="userForm" (ngSubmit)="submit()">
  <mat-dialog-content>
    <div class="row justify-content-center" style="padding-bottom: 20px;">
      <div class="col-11" style="padding-top:25px; padding-bottom: 35px;">
      <span>
        <b>Kindly input the information of the person you wish to add as an employee to this company.</b><br/><br/>
        If they are already a Rondeivu user, they will receive an email to accept the invitation;
        otherwise, they will be guided through the registration process.
        Customize their access permissions by selecting the "Employee Permissions" icon.
        <!--        <b>Please enter the email of the individual you would like to add as an employee of this company.</b>-->
        <!--        If they are already registered with Rondeivu, they will receive an email to accept the invite. If not, they-->
        <!--        will be prompted to register. <u>You can set their access permissions by clicking on the "Employee Permissions" icon.</u>-->
      </span>
      </div>
      <div class="col-11">
        <!--      row-->
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>First Name (Optional)</mat-label>
          <input id="fn" matInput type="text" formControlName="firstName">
        </mat-form-field>
        <!--      row-->
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Last Name (Optional)</mat-label>
          <input id="ln" matInput type="text" formControlName="lastName">
        </mat-form-field>
        <!--      row-->
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Email</mat-label>
          <input id="email" matInput type="email" placeholder="example@rondeivu.com" formControlName="email">
        </mat-form-field>
        <!--      row-->
        <mat-form-field class="form-field" appearance="outline" [hidden]="!data.isEditing">
          <mat-label>Title</mat-label>
          <input id="title" matInput type="text" placeholder="i.e. CEO" formControlName="title">
        </mat-form-field>
        <!--      row-->
        <mat-form-field class="form-field" appearance="outline" [hidden]="!data.isEditing">
          <mat-label>Role</mat-label>
          <mat-select formControlName="role">
            <mat-option *ngFor="let r of lookups.teamUserRoleTypes" [value]="r.key">
              {{ r.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!--      row-->
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="row justify-content-center">
    <div class="col-6 text-start">
      <button mat-raised-button type="button" (click)="dialogRef.close()">Cancel</button>
    </div>
    <div class="col-6 text-end">
      <button mat-raised-button color="primary" type="submit"
              [disabled]="userForm.invalid">{{data.isEditing ? 'SAVE' : 'SUBMIT'}}</button>
    </div>
  </mat-dialog-actions>
</form>
