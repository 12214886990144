<div class="rm-header">
    <span style="font-size: x-large;">Add Employee</span>
</div>
<form [formGroup]="userForm">
<mat-dialog-content>
    <div class="col-12 pt-2 pb-2">
        <p>
            Please input the information of the person you wish to add as an employee to this company.
            If they are already a user on the platform, they will be added to your business unit, otherwise they will be guided through the registration process.
        </p>
        <p>
            You can edit their title, and permissions from the employees list.
        </p>
    </div>
    <div class="row pt-2">
        <!--      row-->
        <div class="col-6">
            <mat-form-field class="form-field" appearance="outline">
                <mat-label>First Name (Optional)</mat-label>
                <input id="fn" matInput type="text" formControlName="firstName">
            </mat-form-field>
        </div>
        <!--      row-->
        <div class="col-6">
            <mat-form-field class="form-field" appearance="outline">
                <mat-label>Last Name (Optional)</mat-label>
                <input id="ln" matInput type="text" formControlName="lastName">
            </mat-form-field>
        </div>
        <!--      row-->
        <div class="col-12">
            <mat-form-field class="form-field" appearance="outline">
                <mat-label>Email</mat-label>
                <input id="email" matInput type="email" placeholder="example@rondeivu.com" formControlName="email">
                <mat-hint align="end">The an invitation email will go to this email address</mat-hint>
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="space-between">
    <button mat-raised-button type="button" [mat-dialog-close]="false">Cancel</button>
    <button mat-raised-button color="primary" type="submit" [mat-dialog-close]="userForm.value"
            [disabled]="userForm.invalid">Add New Employee</button>
</mat-dialog-actions>
</form>
  