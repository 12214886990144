<div class="rm-header">
  <span>MNPI</span>
</div>
<mat-dialog-content>
  <div class="row justify-content-center" style="padding-bottom: 20px; padding-top: 25px;">
    <div class="col-11 mnpi">
      <form [formGroup]="userForm" (ngSubmit)="submit()">
        <div style="padding-bottom: 25px; display: flex;">
          <mat-icon class="mnpi-icon">warning</mat-icon>&nbsp;
          <span class="mnpi-text">Material Non-Public Information</span>
        </div>
        <div style="padding-bottom: 25px;">
          <span>This deal contains MNPI. If you have any questions please contact your Rondeivu Rep.</span>
        </div>
        <div style="padding-bottom: 25px;">
          <mat-checkbox formControlName="agree" color="primary"><b>I understand that I am about to view Material
            Non-Public Information</b>
          </mat-checkbox>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions style="display: flex; justify-content: space-between;">
  <button mat-raised-button (click)="dialogRef.close()">Cancel
  </button>
  <button mat-raised-button color="primary" (click)="dialogRef.close(userForm.get('agree')?.value)"
          [disabled]="userForm.get('agree')?.value != true">PROCEED
  </button>
</mat-dialog-actions>



