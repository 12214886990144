import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {Subject, Subscription, first, interval, takeUntil} from "rxjs";
import {INdaResponse, INdaResponseItem, NdaType} from "../../models";
import {MatTableDataSource} from "@angular/material/table";
import {
  Config,
  getConfig,
  getLookups,
  getSelectedBusinessUnit, getSelectedDeal,
  getSelectedDealId,
  initialConfigState,
  initialLookupsState,
  initialThemeState,
  isLoading,
  Lookups,
  Theme,
  toggleLoading, updateDeal
} from "../../../redux";
import {IBusinessUnit} from "../../../business-units/models";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {DealNdaService, DealService} from "../../services";
import {MatDialog} from "@angular/material/dialog";
import {MnpiModalComponent} from "./mnpi-modal/mnpi-modal.component";
import {ActivatedRoute, Router} from "@angular/router";
import {NegotiateModalComponent} from "./negotiate-modal/negotiate-modal.component";
import {ModalSize, UtilService} from "../../../shared/services/util/util.service";
import {IDealCard} from "../../../shared/models";
import { LookupService } from 'src/app/services';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-deal-nda',
  templateUrl: './deal-nda.component.html',
  styleUrls: ['./deal-nda.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DealNdaComponent implements OnInit, OnDestroy, AfterViewInit {
  config$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  document$: Subscription = new Subscription();

  config: Config = initialConfigState;
  theme: Theme = initialThemeState;

  // bu: IBusinessUnit = {} as unknown as IBusinessUnit;
  // selectedDeal$: Subscription = new Subscription();
  // selectedDeal: IDealCard = {} as unknown as IDealCard;

  dealId: string;
  NdaType = NdaType;
  nda: INdaResponse = {} as unknown as INdaResponse;
  ndaStatus: "PENDING" | "SENT" | "COMPLETED" | "DECLINED" = "PENDING";
  dataSource: MatTableDataSource<INdaResponseItem> = new MatTableDataSource();
  columnsToDisplay = ['businessUnit', 'signatory', 'type', 'status', 'created', 'actions'];
  intervalSub!: Subscription;

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  loading: boolean = false; 

  constructor(private store: Store,
              private toastr: ToastrService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              public lookupService: LookupService,
              private dealService: DealService,
              private dealNdaService: DealNdaService,
              public dialog: MatDialog,
              public util: UtilService
  ) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.dealId = this.activatedRoute.parent?.snapshot.paramMap.get("id") || '';
    this.getDealNda();
    // // this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
    // //   this.bu = Object.assign({}, bu);
    // // });

    // this.selectedDeal$ = this.store.select(getSelectedDeal).subscribe((deal: IDealCard) => {
    //   if (this.selectedDeal.id != deal.id) {
    //     this.selectedDeal = deal;
    //     this.getDealNda();
    //   } else {
    //     this.selectedDeal = deal;
    //   }
    // });
  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    // get params
    let envelopeId: string = this.activatedRoute.snapshot.queryParamMap.get('envelopeId') || '';
    let event: string = this.activatedRoute.snapshot.queryParamMap.get('event') || '';
    console.log("Get NDA by EnvelopeId", envelopeId, event);

    if (!!envelopeId && !!event) {
      // there are values to these query params, so search for the NDA
      this.intervalSub = interval(3000).subscribe(() => {
        console.log("Interval elapsed!");
        this.store.dispatch(toggleLoading({ loading: true, message: "We're processing your NDA status with DocuSign! This could take up to 30 seconds, please wait..." }));
        
        this.dealNdaService.getNdaStatusByEnvelopeId(envelopeId).subscribe({
          next: (res: { isSigned: boolean, status: "PENDING" | "SENT" | "COMPLETED" | "DECLINED" }) => {
            console.log("Get NDA by EnvelopeId result", res);

            if (res.isSigned == true) {
              this.intervalSub?.unsubscribe();
              this.store.dispatch(toggleLoading({ loading: false }));
            }

            this.ndaStatus = res.status;
          },
          error: (err:  HttpErrorResponse) => {
            console.error(err);
            this.intervalSub?.unsubscribe();
            this.store.dispatch(toggleLoading({ loading: false }));

            if (err.status == 404) {
              this.toastr.error("Enveloped Id for NDA not found!");
            }
          }
        });
      });
     }
  }

 
  ngOnDestroy() {
    this.config$.unsubscribe();
    // this.lookup$.unsubscribe();
    // this.selectedBusinessUnit$.unsubscribe();
    // this.selectedDeal$.unsubscribe();
    // this.document$.unsubscribe();
    this.intervalSub?.unsubscribe();
  }

  // private getParams() {
  //   this.activatedRoute.queryParams
  //     .subscribe(params => {
  //       this.document = {
  //         envelopeId: params['envelopeId'],
  //         event: params['event']
  //       }

  //       // the page has params
  //       if (!!this.document.envelopeId && !!this.document.event) {
  //         if (this.document?.envelopeId != '' && this.document?.event != '') {
  //             //try every second until we pass NDA
  //             this.loading = true;
  //           this.document$ = interval(3000).subscribe({
  //             next: () => {
  //               this.dealNdaService.getNdaStatusByEnvelopeId(this.document.envelopeId).subscribe({
  //                 next: (res: { isSigned: string, status: "PENDING" | "SENT" | "COMPLETED" | "DECLINED" }) => {
  //                   this.ndaStatus = res.status;
  //                   // don't query the api once the nda is passed
  //                   if (this.ndaStatus != null && this.ndaStatus.toUpperCase() != "PENDING" && this.ndaStatus.toUpperCase() != "SENT") {
  //                     this.getDealNda();
  //                     this.fetchDealAndDispatch();
  //                     this.document$.unsubscribe();
  //                     this.loading = false;
  //                   }
  //                 }, error: (err: any) => {
  //                   console.log(err);
  //                 }
  //               });
  //             }, error: (err: any) => {
  //               console.log(err);
  //             }
  //             });
  //           }
  //         }
  //       }
  //     );
  // }

  isStatus(status: string) {
    return this.ndaStatus == status;
  }

  /**
   * Get the deal nda
   */
  private getDealNda() {
    console.log("Fetching Deal NDA, for deal Id", this.dealId);

    if (!!this.dealId) {
      this.dealNdaService.getNdaByDealId(this.dealId).subscribe({
        next: (nda: INdaResponse) => {
          console.log("NDA response", nda)
          this.nda = nda;
          this.dataSource = new MatTableDataSource(nda.ndaItems);
          this.initDataSource();
          if (this.nda.showMNPIRequirement) {
            this.openMnpiModal();
          }
        }, error: (err: any) => {
          this.toastr.error("Something went wrong!");
        }
      });
    }
  }

  /**
   * Get the deal and update the store
   */
  // private fetchDealAndDispatch() {
  //   if (!!this.dealId) {
  //     this.dealService.getDeal(this.dealId).subscribe({
  //       next: (deal: IDealCard) => {
  //         this.store.dispatch(updateDeal({deal: deal}));
  //       }, error: (error: any) => {
  //         console.error("Unable to get deal!", $localize`:@@companyName:Rondeivu`);
  //       }
  //     });
  //   }
  // }

  private initDataSource() {
    this.dataSource.filterPredicate = (data: INdaResponseItem, filter: string) => {
      const fullName = data?.signatory?.firstName + ' ' + data?.signatory?.lastName;
      return data.businessUnit?.displayName?.toLowerCase().includes(filter.toLowerCase()) ||
        fullName.toLowerCase().includes(filter.toLowerCase()) ||
        data.signatory?.email?.toLowerCase().includes(filter.toLowerCase());
    };
    if (!!this.paginator) {
      this.paginator.pageSize = 100;
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(event: Event) {
    // const filterValue = (event.target as HTMLInputElement).value;
    // this.dataSource.filter = filterValue.trim();

    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
  }

  // sync(id: string, envelopId: string) {
  //   this.router.navigate(['/' + this.config.selected_business_unit.slug + '/deals/' + this.selectedDeal.id + '/nda'], {
  //     queryParams: {
  //       envelopeId: envelopId,
  //       event: 'status'
  //     }
  //   });
  // }

  download(envelopId: string) {
    // download
    this.store.dispatch(toggleLoading({loading: true}));
    this.dealNdaService.downloadNdaByEnvelopeId(envelopId).subscribe({
      next: (res: Blob) => {
        let a = document.createElement('a');
        a.download = "NDA Download.pdf";
        a.href = window.URL.createObjectURL(res);
        a.click();
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("NDA Downloaded!", $localize`:@@companyName:Rondeivu`);
      },
      error: (err) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Could not download file");
      }
    })
  }

  negotiate(type: NdaType) {
    // get HR form of the nda type
    let t = '';
    switch (type) {
      case NdaType.INVESTOR_DEAL_BY_DEAL_NDA:
        t = "Deal-By-Deal";
        break;
      case NdaType.INVESTOR_GLOBAL_NDA:
        t = "Global";
        break;
    }

    const negRef = this.dialog.open(NegotiateModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      disableClose: true,
      data: {
        ndaType: t
      }
    });

    negRef.afterClosed().subscribe(result => {
      console.log("Dialog closed!");
      if (result) {
        console.log("Negotiate Deal NDA...");
        this.store.dispatch(toggleLoading({loading: true}));
        this.dealNdaService.negotiateNda(type, this.dealId).subscribe({
          next: (nda: any) => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("Negotiation Started! Please check your email for the link.", $localize`:@@companyName:Rondeivu`);
          }, error: (err: any) => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to start negotiation! Please contact admin.", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  viewNdaTemplate(type: NdaType) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/' + this.config.selected_business_unit.slug + '/documents/' + type.toString()], {queryParams: {'dealId': this.dealId}})
    );
    window.open(url, '_blank');
  }


  /**
   * Creates a NDA based on the type
   * @param type
   */
  createNda(type: NdaType) {
    console.log("Creating Deal NDA...");
    this.store.dispatch(toggleLoading({loading: true}));
    this.dealNdaService.createNda({ndaType: type.toString(), dealId: this.dealId}).subscribe({
      next: (nda: any) => {
        if (!!nda) {
          window.open(nda.url, '_self')?.focus();
        }
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("NDA Created!", $localize`:@@companyName:Rondeivu`);
      }, error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Something went wrong!");
      }
    });
  }

  private openMnpiModal() {
    const mnpiRef = this.dialog.open(MnpiModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.MEDIUM),
      disableClose: true
    });

    mnpiRef.afterClosed().subscribe(result => {
      console.log("Dialog closed!");
      if (result) {
        console.log("Checking Deal MNPI...");
        this.store.dispatch(toggleLoading({loading: true}));
        this.dealService.checkMnpi().subscribe({
          next: (res: any) => {
            // this.fetchDealAndDispatch();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("MNPI Checked!", $localize`:@@companyName:Rondeivu`);
          }, error: (err: any) => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Something went wrong!");
          }
        });
      }
    });
  }

  //sort
  // sortData(sort: Sort) {
  //   const data = this.ndaList.slice();
  //   if (!sort.active || sort.direction === '') {
  //     this.sortedNdas = data;
  //     return;
  //   }

  //   this.sortedNdas = data.sort((a: INdaResponseItem, b: INdaResponseItem) => {
  //     const isAsc = sort.direction === 'asc';
  //     switch (sort.active) {
  //       case 'company':
  //         return compare(a.businessUnit.displayName, b.businessUnit.displayName, isAsc);
  //       case 'employee':
  //         const n1 = a.signatory.firstName + ' ' + a.signatory.lastName;
  //         const n2 = b.signatory.firstName + ' ' + b.signatory.lastName;
  //         return compare(n1, n2, isAsc);
  //       case 'type':
  //         return compare(this.getTypeText(a.type), this.getTypeText(b.type), isAsc);
  //       case 'status':
  //         return compare(a.status, b.status, isAsc);
  //       case 'created':
  //         return compare(a.created, b.created, isAsc);
  //       default:
  //         return 0;
  //     }
  //   });

  //   this.dataSource = new MatTableDataSource(this.sortedNdas);
  //   // this.initDataSource();
  // }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

