<div class="container-fluid">
  <div style="overflow-x: hidden;">
    <div class="row justify-content-center pt-4 pb-4">
      <div class="col-12 col-md-11">
        <span class="rm-title rm-title-3">Legal Entity Information</span><br/>
        <span class="rm-title rm-title-6 rm-lighter">This is where you can edit the basic information of your Legal Entity</span>
      </div>
    </div>

    <!-- edit fields  -->
    <div class="row justify-content-center pb-4">
      <div class="col-11">
        <form [formGroup]="entityForm"
              (ngSubmit)="save()">
          <div class="row justify-content-center pb-4">
            <div class="col-12 ">
              <app-user-card [firstName]="entityForm.get('entityName')?.value || undefined"
                              [displayName]="lookupService.getLookupValueForKey(lookupService.lookups.businessUnitEntityType, entityForm.get('entityType')?.value )"
                              
                              [borderColor]="'transparent'"
                              [sideColor]="'#002150'"
                              [background]="'#F1EDE4'"
                              [size]="userCardSize.LG"
                              [image]="entityForm.get('businessUnit')?.value?.imageUrl || undefined"
              ></app-user-card>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 ">
              <div class="row">
                <div class="col-12 col-md-6">
                  <mat-form-field class="form-field" appearance="outline">
                    <mat-label>Legal Entity Name</mat-label>
                    <input class="form-input" matInput formControlName="entityName" [required]="true"
                            placeholder="My Company Entity"/>
                    <mat-hint align="end">Name of the legal entity</mat-hint>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                  <mat-form-field class="form-field" appearance="outline">
                    <mat-label>Entity Type</mat-label>
                    <mat-select id="entityType" formControlName="entityType">
                      <mat-option *ngFor="let c of lookupService.lookups.businessUnitEntityType" [value]="c.key">
                        {{ c.value }}
                      </mat-option>
                    </mat-select>
                    <mat-hint align="end">The type of Legal Entity / Natural Person</mat-hint>
                  </mat-form-field>
                </div>
              </div>
              <!--          row start-->
              <div class="row pt-2">
                <div class="col-12 col-md-6">
                  <mat-form-field class="form-field" appearance="outline">
                    <mat-label>Legal Domicile</mat-label>
                    <mat-select id="country" formControlName="domicile">
                      <mat-option *ngFor="let c of lookupService.lookups.countries" [value]="c.key">
                        {{ c.value }}
                      </mat-option>
                    </mat-select>
                    <mat-hint align="end">Legal Domicile of the Legal Entity / Natural Person</mat-hint>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="row pt-4 pb-2">
            <div class="col-12 text-end">
              <button type="submit"
                      mat-raised-button
                      color="primary"
              >Save Entity Information
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

