
<div class="rm-header">
    <span style="font-size: x-large;">Add Owner of Voting Shares</span>
</div>
<mat-dialog-content>
    <form [formGroup]="fg">
        <div class="col-12 pt-2 pb-2">
            <p>
                Does the client or any of the authorized signatories own, alone or as part of a group, 10% or more of the voting rights of a publicly traded company?
            </p>
        </div>
        <div class="row pt-2">
            <!--      row-->
            <div class="col-12">
                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>Name of Owner of Voting Shares</mat-label>
                    <input matInput type="text" required formControlName="ownerOfVotingRights">
                    <mat-error *ngIf="fg.get('ownerOfVotingRights')?.hasError('required')">Field is required</mat-error>
                </mat-form-field>
            </div>
            <!--      row-->
            <div class="col-6">
                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>Name of Company</mat-label>
                    <input matInput type="text" required formControlName="publiclyTradedCompany">
                    <mat-error *ngIf="fg.get('publiclyTradedCompany')?.hasError('required')">Field is required</mat-error>
                </mat-form-field>
            </div>
            <!--      row-->
            <div class="col-6">
                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>Percentage Ownership</mat-label>
                    <input id="email" matInput type="number" required
                        placeholder="Percent ownership (eg. 15%)" formControlName="ownership">
                    <mat-error *ngIf="fg.get('ownership')?.hasError('required')">Field is required</mat-error>
                    <mat-error *ngIf="fg.get('ownership')?.hasError('min')">Should be a minimum of 0</mat-error>
                    <mat-error *ngIf="fg.get('ownership')?.hasError('max')">Should be a maximum of 100</mat-error>
                    <!-- <mat-hint align="end">The an invitation email will go to this email address</mat-hint> -->
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions class="space-between">
    <button mat-raised-button type="button" mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" type="submit" [mat-dialog-close]="fg.value"
            [disabled]="fg.invalid">Submit</button>
</mat-dialog-actions>
  