<div class="rm-header">
  <span>Business Select</span>
</div>
<mat-dialog-content>
  <div style="margin-top: 15px;">
    <p>
      If you have previously added any Legal Entities to this KYC, you can select them from this list so you won't have to re-enter their information.
    </p>
  
    <p>
      If the Legal Entity <b>does not exist</b> in this list, please click the "Add New Legal Entity" button.
    </p>

    <p>
      If this field requies more information about this Legal Entity, then you will see it on the "Legal Entities" right hand dashboard, and you can edit the its information.
    </p>
  </div>
  <form [formGroup]="form" (submit)="submit()">
    <div class="row" style="padding-top:25px;">
      <div *ngFor="let element of form.get('businesses')?.value" style="margin-bottom: 5px; cursor: pointer;"
           (click)="selectBusiness(element)">
        <mat-card>
          <div style="display: flex; justify-content: space-between; width: 100%; vertical-align: middle;">
            <div>
              <app-user-card
                [firstName]="element?.['legalName']"
                [displayName]="element?.['streetAddress'] + ' ' + element?.['city']"
                [borderColor]="'transparent'"
                [background]="'#ffffff'"
                [sideColor]="'#c1002d'"
                [image]="element?.['imageUrl']"
                [size]="userCardSize.MD"
              ></app-user-card>
            </div>
            <div style="padding-top: 10px;">
              <div *ngIf="isMoreInfoRequired(element?.['kycLegalEntityType'])" matTooltip="More information is required"
                   matTooltipPosition="left">
                <mat-icon color="accent">warning</mat-icon>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
    <!--      row-->
  </form>
</mat-dialog-content>

<mat-dialog-actions class="space-between" style="padding-top:25px;">
  <button mat-raised-button type="button" (click)="dialogRef.close()">Cancel</button>
  <button mat-raised-button type="button" (click)="addNewBusiness()" color="primary">Add New Legal Entity</button>
</mat-dialog-actions>
