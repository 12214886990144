import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { from, Observable, of, tap } from 'rxjs';
import { IBusinessUnit } from '../modules/business-units/models';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { BusinessUnitService } from '../modules/business-units/services';
import { getSelectedBusinessUnit, Lookups, updateNavItems } from '../modules/redux';
import { UtilService } from '../modules/shared/services';
import { AppConfigService, AuthService } from '../services';
import { INavItem } from '../models';
import { DataService } from '../services/data/data.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationResolver implements Resolve<INavItem[]> {

  // these routes are allowed, even without the navigation (since there are no navigations for them)
  allowableTopRoutes: string[] = [ 'contact-us', 'settings', 'error', 'documents', 'history' ];

  constructor(
    private appConfig: AppConfigService,
    private store: Store,
    private router: Router,
    private toastr: ToastrService,
    private dataService: DataService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<INavItem[]> {
    return this.appConfig.getNavigationItems().pipe(tap(navItems => {
      // console.log("Navigation Resolver", navItems);
      
       // programatically put in the "contact us" navigation
       navItems.push(<INavItem> {
        title : "Contact Us",
        // toolTip : "Contact Us",
        hidden : false,
        enabled: true,
        link : "/contact-us",
        icon: 'help'
      });


      // put into the store
      this.store.dispatch(updateNavItems({items: navItems}));

      // put into data service
      this.dataService.navItems = navItems;

      let buSlug = route.url[0].path;

      try {
        const noQueryParams = state.url.split('?')[0];
        const requestedRouteSegments = noQueryParams.split('/').filter(x => !!x);

        if (requestedRouteSegments.length > 1) {
          var navRoute = requestedRouteSegments[1];

          if (this.allowableTopRoutes.includes(navRoute) == false) {

            var matchingNavItem = navItems.filter(x => x.link == "/" + navRoute);
            // console.log("------------------------------------------------------")
            // console.log("matchingNavItem", matchingNavItem);
  
            if (matchingNavItem.length == 0 || 
                matchingNavItem[0].enabled == false ||
                matchingNavItem[0].hidden == true) {
  
                throw new Error(`You do not have permission to access the route '${navRoute}'`)
            }
          }
        }

      } catch (err: any) {
        // console.error(err);
        this.toastr.error(err);
        this.router.navigate([buSlug, 'error']);
      }
    }));
  }
}
