<div style="height:calc(100vh - 176px); overflow-y: auto;">
  <div class="container-fluid">
    <!--    GENERAL SETTINGS-->
    <form id="buForm" #buForm
          [formGroup]="userForm"
          (ngSubmit)="save()">
      <!--      row start-->
      <div class="row justify-content-center" style="padding-top: 25px;">
        <div class="col-12 col-md-11">
          <span class="rm-title rm-title-3">Mandate Information</span><br/>
          <span *ngIf="util.isType('investor')" class="rm-title rm-title-6"><br/>
            Your investment mandates help our algorithms match you with deals that are a natural fit for your investment strategy.  If you need to adjust or update your mandate metrics, please contact Rondeivu.
          </span>
          <span *ngIf="util.isType('admin')" class="rm-title rm-title-6"><br/>
            Rondeivu Admin can update mandate metrics, or change mandate status from this page.
          </span>
        </div>
      </div>
      <!--      row start-->
      <div class="row justify-content-center" style="padding-top: 50px; padding-bottom: 150px;">
        <div class="col-12 col-md-11">
          <!--          <div class="row">-->
          <!--            <span style="font-size: x-large;">General Information</span>-->
          <!--          </div>-->
          <!--      row start-->
          <div class="row">
            <!--            <div class="col-6" hidden>-->
            <!--              <mat-label>Title</mat-label>-->
            <!--              <mat-form-field class="form-field" appearance="outline">-->
            <!--                <input matInput id="title" required formControlName="title"/>-->
            <!--              </mat-form-field>-->
<!--            </div>-->
            <div class="col-12 col-md-6">
              <mat-label style="font-size: large;font-weight: bold;">Status</mat-label>
              <div *ngIf="!util.isType('admin')">
                <br/><span style="font-size: large;"
                           [appStatus]="userForm.get('workflowStatus')?.value || ''">{{userForm.get('workflowStatus')?.value}}</span>
              </div>

              <div *ngIf="util.isType('admin')">
                <mat-form-field class="form-field" appearance="outline">
                  <mat-select id="mandate-status" placeholder="Pending Approval" formControlName="workflowStatus">
                    <mat-option *ngFor="let c of lookups.investorMandateWorkflowStatus" [value]="c.key">
                      {{c.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <!--      row start-->
          <div class="row" style="padding-top: 50px;">
            <span style="font-size: large;font-weight: bold;">Mandate Details</span>
          </div>
          <br/>
          <!--          row start-->
          <div class="row" *ngIf="!!userForm">
            <div class="col-12 col-md-6">
              <!--              deal type-->
              <div class="radio-group">
                <mat-label>Deal Type<sup>*</sup></mat-label>
                <mat-error *ngIf="userForm.invalid && (selectedDealIdx?.length == 0)">Please select at least one deal
                  type.
                </mat-error>
                <div *ngFor="let dealType of lookups.dealTypes; index as i;">
                  <mat-checkbox [ngModelOptions]="{standalone: true}" color="primary"
                                [id]="'dt'+ i" [name]="'dt'+i" [(ngModel)]="selectedDealIdx[i]"
                                (change)="toggleDealType(dealType)">{{ dealType.value }}
                  </mat-checkbox>
                  <br/>
                </div>
              </div>
            </div>
            <div class="col-12 d-md-none">
              &nbsp;
            </div>
            <div class="col-12 col-md-6">
              <div class="radio-group">
                <mat-label>Asset Class<sup>*</sup></mat-label>
                <mat-error *ngIf="userForm.invalid && (selectedAssetIdx.length == 0)">Please select at least one asset
                  class.
                </mat-error>
                <div *ngFor="let asset of lookups.assetTypes;index as i;">
                  <mat-checkbox [ngModelOptions]="{standalone: true}" color="primary"
                                [id]="'ac'+ i" [name]="'ac'+i" [(ngModel)]="selectedAssetIdx[i]"
                                (change)="toggleAssetClass(asset)">{{ asset.value }}
                  </mat-checkbox>
                  <br/>
                </div>
              </div>
            </div>
          </div>
          <br/>
<!--          Investment Opportunities-->
          <div class="row" style="padding-top: 50px;">
            <span style="font-size: large;font-weight: bold;">Investment Opportunities</span>
          </div>
          <br/>
          <!-- row start-->
          <div class="row">
            <div class="col-12">
              <!--notes-->
              <mat-label style="font-size: medium;">Notes</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <textarea matInput id="notes" placeholder="Type here..."
                          formControlName="notes"></textarea>
              </mat-form-field>
            </div>
          </div>
<!--          Investment Opportunities END-->
          <!--          row start-->
          <div class="row">
            <div class="col-12">
              <mat-label style="font-size: medium;">Keywords</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <mat-chip-list #chipList aria-label="Theme selection">
                  <mat-chip
                    *ngFor="let st of selectedThemes"
                    (removed)="removeTheme(st)">
                    {{st}}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                  <input
                    [placeholder]="selectedThemes.length==0? 'Townhouse, Real Estate, New Build': ''"
                    #themeInput
                    [formControl]="themeCtrl"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="addTheme($event)"/>
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTheme($event)">
                  <mat-option *ngFor="let ft of filteredThemes | async" [value]="ft">
                    {{ft}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
          <!--      row start-->
          <div class="row" style="padding-top: 50px;">
            <span style="font-size: large;font-weight: bold;">AUM</span>
          </div>
          <!--      row start-->
          <div class="row justify-content-center" style="padding-top: 25px;">
            <div class="col-12 col-md-6">
              <mat-label>Firm AUM</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input matInput id="firm-aum" type="number" [required]="true" formControlName="firmAum"/>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <mat-label>Program AUM</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input matInput id="program-aum" type="number" [required]="true" formControlName="programAum"/>
              </mat-form-field>
            </div>
          </div>
          <!--      row start-->
          <div class="row" style="padding-top: 50px;">
            <span style="font-size: large;font-weight: bold;">MOIC</span>
          </div>
          <!--      row start-->
          <div class="row justify-content-center" style="padding-top: 25px;">
            <div class="col-12 col-md-6">
              <mat-label>Target Net MOIC</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input matInput id="target-moic" type="number" [required]="true" formControlName="targetNetMoic"/>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <mat-label>Target Net IRR</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input matInput id="target-irr" type="number" [required]="true" formControlName="targetNetIrr"/>
              </mat-form-field>
            </div>
          </div>
          <!--      row start-->
          <div class="row" style="padding-top: 50px;">
            <span style="font-size: large;font-weight: bold;">Duration</span>
          </div>
          <!--      row start-->
          <div class="row justify-content-center" style="padding-top: 25px;">
            <div class="col-12 col-md-6">
              <mat-label>Minimum Duration</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input matInput type="number" id="min-duration" [required]="true"
                       formControlName="minimumDuration"/>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <mat-label>Maximum Duration</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input matInput type="number" id="max-duration" [required]="true"
                       formControlName="maximumDuration"/>
              </mat-form-field>
            </div>
          </div>
          <!--      row start-->
          <div class="row" style="padding-top: 50px;">
            <span style="font-size: large;font-weight: bold;">Ticket Size</span>
          </div>
          <!--      row start-->
          <div class="row justify-content-center" style="padding-top: 25px;">
            <div class="col-12 col-md-4">
              <mat-label>Minimum Ticket Size</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input matInput type="number" id="min-ticket" [required]="true"
                       formControlName="minTicketSize">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-4">
              <mat-label>Maximum Ticket Size</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input matInput type="number" id="max-ticket" required
                       formControlName="maxTicketSize">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-4">
              <mat-label>Average Ticket Size</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input matInput type="number" id="avg-ticket" required
                       formControlName="avgTicketSize">
              </mat-form-field>
            </div>
          </div>
          <!--      row start-->
          <div class="row" style="padding-top: 50px;">
            <span style="font-size: large;font-weight: bold;">Other</span>
          </div>
          <!--      row start-->
          <div class="row" style="padding-top: 25px;">
            <div class="col-12 col-md-6">
              <mat-label>Minimum Timeline to Close</mat-label>
              <mat-form-field appearance="outline" class="form-field">
                <mat-select id="mttc2" formControlName="investorMandateTimeLines">
                  <mat-option *ngFor="let tl of lookups.timeLineTypes" [value]="tl.key">
                    {{tl.value}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!--            investorMandateAnchorInterests-->
            <div class="col-12 col-md-6">
              <div class="radio-group">
                <mat-label>Are you interested in being any of the following?</mat-label>
                <div *ngFor="let type of lookups.anchorInterestTypes; index as i;">
                  <mat-checkbox [ngModelOptions]="{standalone: true}" color="primary"
                                [id]="'anchor'+ i" [name]="'anchor'+i" [(ngModel)]="selectedAnchorIdx[i]"
                                (change)="toggleAnchorInterest(type)">{{ type.value }}
                  </mat-checkbox>
                  <br/>
                </div>
              </div>
            </div>
          </div>
          <!--      row start-->
          <div class="row justify-content-center" style="padding-top: 75px;">
            <div class="col-12 text-end">
              <button
                mat-raised-button
                color="primary"
                type="submit"
                [disabled]="userForm.invalid || !util.hasPermission(util.Permission.CAN_EDIT_MANDATES)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_MANDATES) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_MANDATES) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
