import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {Subscription} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {
  Config, fetchTasks,
  getConfig,
  getLookups,
  getSelectedBusinessUnit,
  getSelectedEntity,
  initialConfigState,
  initialLookupsState,
  initialThemeState,
  Lookups,
  Theme, toggleLoading,
  updateSelectedEntityKyc
} from "../../../redux";
import {IBusinessUnit} from "../../../business-units/models";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {ConfirmationModalComponent, UserCardSize} from "../../../shared/components";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {UtilService} from "../../../shared/services";
import {EntityKycService} from "../../services/entity-kyc.service";
import {IEntityKycListResponse} from "../../models/entity";

@Component({
  selector: 'app-entity-kyc-list',
  templateUrl: './entity-kyc-list.component.html',
  styleUrls: ['./entity-kyc-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EntityKycListComponent implements OnInit, OnDestroy {
  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  config: Config = initialConfigState;
  theme: Theme = initialThemeState;
  lookups: Lookups = initialLookupsState;

  entityKycs: IEntityKycListResponse[] = [];
  sortedEntityKycs: IEntityKycListResponse[] = [];
  dataSource: MatTableDataSource<IEntityKycListResponse> = new MatTableDataSource();

  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
  selectedEntity$: Subscription = new Subscription();
  selectedEntity: any = {};

  columnsToDisplay = ['name', 'title', 'status', 'created', 'actions'];

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  userCardSize = UserCardSize;

  constructor(
    private router: Router,
    private store: Store,
    private toastr: ToastrService,
    private entityKycService: EntityKycService,
    public dialog: MatDialog,
    public util: UtilService
  ) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.selectedBusinessUnit = Object.assign({}, bu);
    });

    this.selectedEntity$ = this.store.select(getSelectedEntity).subscribe((entity: any) => {
      this.selectedEntity = entity;
      this.subscribeToDatasource();
    });

  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedEntity$.unsubscribe();
  }

  subscribeToDatasource(): void {
    this.getEntityKycs();
  }

  /**
   * Gets the entity kycs
   */
  getEntityKycs() {
    console.log("Fetching Entity KYCs...");
    this.entityKycService.getKycByEntityId(this.selectedEntity.id).subscribe({
      next: (kycs: any[]) => {
        this.entityKycs = kycs;
        this.dataSource = new MatTableDataSource(this.entityKycs);
        this.initDataSource();
      }, error: (err: any) => {
        this.toastr.error("Unable to get entity kycs!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  addKyc() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.entityKycService.addEntityKyc({entityId: this.selectedEntity.id}).subscribe({
      next: (kycObject: IEntityKycListResponse) => {
        if (!!kycObject && !!kycObject.id) {
          this.store.dispatch(updateSelectedEntityKyc({entityKyc: kycObject}))
          this.store.dispatch({type: fetchTasks});
          this.router.navigate(['/' + this.config.selected_business_unit.slug + '/entities/' + this.config.selected_entity_id + '/kyc/' + kycObject.id]);
        }
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("KYC Initiated!", $localize`:@@companyName:Rondeivu`);
      }, error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to initiate KYC!");
      }
    });
  }

  downloadKyc(entityKyc: IEntityKycListResponse) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.entityKycService.downloadKyc(entityKyc.businessUnitEntityKycId).subscribe({
      next: (res: any) => {
        let filename = entityKyc.kycTitle + '.pdf';
        let blob: Blob = res as Blob;
        let a = document.createElement('a');
        a.download = filename;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: error => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to download document!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  cloneKyc(entityKyc: IEntityKycListResponse) {
    const confRef = this.dialog.open(ConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      data: true
    });

    confRef.afterClosed().subscribe(result => {
      if (!!result) {
        console.log("Cloning Entity KYC...");
        this.entityKycService.cloneEntityKyc(entityKyc).subscribe({
          next: (res: any) => {
            this.getEntityKycs();
            this.toastr.success("KYC Cloned!", $localize`:@@companyName:Rondeivu`);
          }, error: (err: any) => {
            this.toastr.error("Unable to get entity kycs!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  viewKyc(entityKyc: IEntityKycListResponse) {
    this.store.dispatch(updateSelectedEntityKyc({entityKyc: entityKyc}));
    if (!!entityKyc.businessUnitEntityKycId) {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/entities/' + this.config.selected_entity_id + '/kyc/' + entityKyc.businessUnitEntityKycId]);
    }
  }

  initDataSource() {
    this.dataSource.filterPredicate = (data: IEntityKycListResponse, filter: string) => {
      const kycId = data.businessUnitEntityKycId.includes(filter);
      const entityId = data.businessUnitEntityId.includes(filter);
      const name = data.businessUnitEntityName?.toLowerCase().includes(filter.toLowerCase());
      const type = data.entityType?.toLowerCase().includes(filter.toLowerCase());
      const status = data.kycRondeivuStatus?.toLowerCase().includes(filter.toLowerCase());
      return kycId || entityId || name || type || status;
    };
    if (!!this.paginator) {
      this.paginator.pageSize = 10;
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //sort
  sortData(sort: Sort) {
    const data = this.entityKycs.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedEntityKycs = data;
      return;
    }

    this.sortedEntityKycs = data.sort((a: IEntityKycListResponse, b: IEntityKycListResponse) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'kycId':
          return compare(a.businessUnitEntityKycId, b.businessUnitEntityKycId, isAsc);
        case 'entityId':
          return compare(a.businessUnitEntityId, b.businessUnitEntityId, isAsc);
        case 'name':
          return compare(a.kycName, b.kycName, isAsc);
        case 'title':
          return compare(a.kycTitle, b.kycTitle, isAsc);
        case 'type':
          return compare(a.entityType, b.entityType, isAsc);
        case 'status':
          return compare(a.kycRondeivuStatus, b.kycRondeivuStatus, isAsc);
        case 'comments':
          return compare(a.publicComments, b.publicComments, isAsc);
        default:
          return 0;
      }
    });

    this.dataSource = new MatTableDataSource(this.sortedEntityKycs);
    this.initDataSource();
  }

  getStatusString(key: string) {
    if (!!this.config) {
      return this.util.getLookupValueForKey(this.lookups.kycRondeivuStatus, key);
    }
    return '';
  }

}

//this is the table sort comparator
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

