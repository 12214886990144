import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppConfigService} from "../../services";
import {IContact} from "../../models";
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {
  CognitoUser,
  Config, getCognitoUser,
  getConfig,
  getSelectedBusinessUnit,
  initialCognitoUserState,
  initialConfigState
} from "../../modules/redux";
import {IBusinessUnit} from "../../modules/business-units/models";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  config: Config = initialConfigState;
  cognito$: Subscription = new Subscription();
  cognitoUser = initialCognitoUserState;

  contact: IContact = {} as unknown as IContact;
  link : SafeResourceUrl | null = null;

  constructor(private store: Store, private appConfig: AppConfigService, private domSanitizer: DomSanitizer) {
    // subscribe to drawer state
    this.config$ = this.store.select(getConfig).subscribe((configState: Config) => {
      this.config = configState;
    });

    // subscribe to the cognito store
    this.cognito$ = this.store.select(getCognitoUser).subscribe((cognito: CognitoUser) => {
      this.cognitoUser = cognito;
    });

    // attestations is business unit specific
    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((selected: IBusinessUnit) => {
      if (!!selected.businessUnitId) {
        this.getContact()
      }
    });
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.cognito$.unsubscribe();
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
  }

  getContact() {
    this.appConfig.getContactInfo().subscribe((contact: IContact) => {
      // console.log("Contact us Info", contact)
      this.contact = contact;
      // this.link = null;
      // Reza removed this for now, so that Calendar links don't show up.
      // if (this.contact != null && this.contact.calendarLink != null && this.contact.calendarLink != '') {
      //   // does a cognito user exist?
      //   if(!!this.cognitoUser){
      //     const fn = this.cognitoUser.attributes.given_name;
      //     const ln = this.cognitoUser.attributes.family_name;
      //     const email = this.cognitoUser.attributes.email;
      //     this.link = this.domSanitizer.bypassSecurityTrustResourceUrl(this.contact.calendarLink + "?name=" + fn + ' ' + ln + '&email=' + email);
      //   }
      //   else {
      //     this.link = this.domSanitizer.bypassSecurityTrustResourceUrl(this.contact.calendarLink);
      //   }
      // }
    });
  }

  // private getLink() {
  //   if(!!this.cognitoUser){
  //     const fn = this.cognitoUser.attributes.given_name;
  //     const ln = this.cognitoUser.attributes.family_name;
  //     const email = this.cognitoUser.attributes.email;
  //     var linkWithUserInfo = this.contact?.calendarLink + "?name=" + fn + ' ' + ln + '&email=' + email;
  //     return this.domSanitizer.bypassSecurityTrustResourceUrl(linkWithUserInfo);
  //   }
  //   return null;
  // }

}
