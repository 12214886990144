<div class="rm-header">
  <span>Person Select</span>
</div>
<mat-dialog-content>

  <div style="margin-top: 15px;">
    <p>
      If you have previously added any persons to this KYC, you can select them from this list so you won't have to re-enter their information.
    </p>
  
    <p>
      If the person <b>does not exist</b> in this list, please click the "Add New Person" button.
    </p>

    <p>
      If this field requies more information about this person, then you will see it on the "Natural Persons" right hand dashboard, and you can edit the person's information.
    </p>
  </div>

  <form [formGroup]="form" (submit)="submit()">
    <div class="row" style="padding-top:5px;">
      <div *ngFor="let element of form.get('people')?.value" style="margin-bottom: 5px; cursor: pointer;"
           (click)="selectPerson(element)">
        <mat-card>
          <div style="display: flex; justify-content: space-between; width: 100%; vertical-align: middle;">
            <div>
              <app-user-card
                [firstName]="element?.['firstName']"
                [lastName]="element?.['lastName']"
                [displayName]="element?.['email']"
                [borderColor]="'transparent'"
                [background]="'#ffffff'"
                [sideColor]="'#002150'"
                [image]="element?.['imageUrl']"
                [size]="userCardSize.MD"
              ></app-user-card>
            </div>
            <div style="padding-top: 10px;">
              <div *ngIf="isMoreInfoRequired(element?.['kycPersonType'])" matTooltip="More information is required"
                   matTooltipPosition="left">
                <mat-icon color="accent">warning</mat-icon>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </form>

</mat-dialog-content>
<mat-dialog-actions class="space-between" style="padding-top: 25px;">
  <button mat-raised-button type="button" mat-dialog-close="">Cancel</button>
  <button mat-raised-button type="button" (click)="addNewPerson()" color="primary">Add New Person</button>
</mat-dialog-actions>

