import { Component, Inject, QueryList, ViewChildren} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { MatCheckbox} from "@angular/material/checkbox";
import { IAttestation} from "../../models";
import { UtilService } from 'src/app/modules/shared/services';

@Component({
  selector: 'app-attestation-modal',
  templateUrl: './attestation-modal.component.html',
  styleUrls: ['./attestation-modal.component.scss']
})
export class AttestationModalComponent  {

  @ViewChildren(MatCheckbox) viewChildren!: QueryList<MatCheckbox>;

  constructor(
    private util: UtilService,
    public dialogRef: MatDialogRef<AttestationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IAttestation
  ) {
  }

  /**
   * Determines if all of the attestations have been checked
   */
  canAction() {
    let allChecked = true;
    //if even one is false break
    if(!!this.viewChildren){
      for (let i = 0; i <= this.viewChildren.length - 1; i++) {
        if (!!this.viewChildren.get(i) && !this.viewChildren.get(i)?.checked) {
          allChecked = false;
          break;
        }
      }
    } else {
      return false;
    }
    return allChecked;
  }

  /**
   * Allows the modal action if all attestations have been checked
   * @param data
   */
  action(data: IAttestation): void {
    if (this.canAction()) {
      this.dialogRef.close(data);
    }
  }

  logOut() {
    this.util.logOut().then(() => {
      this.dialogRef.close();
    })
  }

}
