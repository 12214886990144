<div style="height: 100px;">
    <div class="row" style="text-align: center; padding-top: 150px">
      <h1>ERROR!</h1>
      <!-- <span>Oops! Page not Found</span><br /> -->
      <span
        >...Sorry, but the page you are looking for is not found. Please make sure
        you have typed the correct URL.</span
      >
    </div>
</div>
  