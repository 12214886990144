import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { ILookup, LookupType } from '../models';
import { LookupService } from '../services';
import { Lookups } from '../modules/redux';

@Injectable({
  providedIn: 'root'
})
export class PrivateLookupsResolver implements Resolve<Lookups> {
  /**
   *
   */
  constructor(private lookupService: LookupService) {
    
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Lookups> {
    // this.lookupService.loadLookup(LookupType.Private);
    return this.lookupService.loadPrivateLookups$();
  }
}
