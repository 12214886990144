<div class="rm-header">
  <span class="">Add Business Unit</span>
</div>
<div>
  <form id="buForm" #buForm
        [formGroup]="userForm"
        (ngSubmit)="submit()">
    <mat-dialog-content class="mat-typography">
      <p>
        This is where you can add a new Business Unit to the system.  You must then approve it, and add employees to it manually.
      </p>

      <!-- Display Name -->
      <div class="row justify-content-center" style="padding-top: 25px;">
        <div class="col-6">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Display Name</mat-label>
            <input matInput id="dname" [required]="true" minlength="4" formControlName="displayName"/>
            <mat-error>Must be at least 4 characters long.</mat-error>
            <mat-hint align="end">The display name visible everywhere for the Business Unit</mat-hint>
          </mat-form-field>
        </div>

        <div class="col-6">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Legal Entity Name</mat-label>
            <input matInput id="iname" [required]="true" minlength="4" formControlName="internalName"/>
            <mat-error>Must be at least 4 characters long.</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--  Business Unit Slug -->
      <div class="row justify-content-center">
        <div class="col-6">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Business Unit Slug</mat-label>
            <input matInput id="buslug" [required]="true" pattern="^\S+$" formControlName="slug"/>
            <mat-error>Slug must be non-empty and contain no whitespace.</mat-error>
          </mat-form-field>
        </div>

        <div class="col-6">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Business Unit Domicile</mat-label>
            <mat-select id="cod" [required]="true" formControlName="domicile">
              <mat-option *ngFor="let opt of lookupService.lookups.countries" [value]="opt.key">
                {{ opt.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <!-- Type -->
      <div class="row justify-content-center pt-4">
        <div class="col-12">
          <mat-label>Business Unit Profile Type</mat-label><br />
          <em class="text-muted">Once a Business Unit is created as an Investor or Issuer, it cannot be changed!</em>
          <br />
          <mat-radio-group class="pt-4"
            class="radio-group" id="type" [required]="true" formControlName="businessUnitType">
            <mat-radio-button class="radio-button" color="primary" value="INVESTOR">INVESTOR</mat-radio-button>&nbsp;
            <mat-radio-button class="radio-button" color="primary" value="ISSUER">ISSUER</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!--  Business Sub Type -->
      <!--  Type of investor-->
      <div class="row justify-content-center pt-4" *ngIf="userForm.get('businessUnitType')?.value === 'INVESTOR'">
        <div class="col-12">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Subtype (Investor)</mat-label>
            <mat-select id="toi" [required]="true" formControlName="subType">
              <mat-option *ngFor="let i of lookupService.lookups.investorTypes" [value]="i.value">
                {{ i.value }}
              </mat-option>
              <mat-hint align="end">Notes about the creation of the Business Unit and why it was created, and by whom</mat-hint>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- Type of issuer-->
      <div class="row justify-content-center pt-4" *ngIf="userForm.get('businessUnitType')?.value === 'ISSUER'">
        <div class="col-12">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Subtype (Issuer)</mat-label>
            <mat-select id="ti" [required]="true" formControlName="subType">
              <mat-option *ngFor="let i of lookupService.lookups.issuerTypes" [value]="i.value">
                {{ i.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- Intake Description -->
      <div class="row justify-content-center pt-4">
        <div class="col-12">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Intake Description</mat-label>
            <input matInput id="iDesc" formControlName="intakeDescription"/>
            <mat-hint align="end">Some information about why this Business Unit was created</mat-hint>
          </mat-form-field>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions class="space-between pt-2">
      <button mat-raised-button type="button" (click)="dialogRef.close()">Cancel</button>
      <button mat-raised-button color="primary" type="submit" [disabled]="userForm.invalid">Create Business Unit</button>
    </mat-dialog-actions>
  </form>
</div>
