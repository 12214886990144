import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {MatTableDataSource} from "@angular/material/table";
import {
  Config,
  getConfig,
  getSelectedBusinessUnit,
  initialConfigState,
  toggleLoading, updateDealId,
  updateEditingBusinessUnit
} from "../../../redux";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";

import {ToastrService} from "ngx-toastr";
import {BusinessUnitService} from "../../services";
import {LookupService} from "../../../../services";
import {IBusinessUnit} from "../../models";
import {Subscription} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {BusinessUnitModalComponent} from "../business-unit-modal/business-unit-modal.component";
import {UtilService} from "../../../shared/services";
import {ModalSize} from "../../../shared/services/util/util.service";
import {addBusinessUnits} from "../../../redux/stores/business-unit/business-unit.actions";

@Component({
  selector: 'app-business-units-list',
  templateUrl: './business-units-list.component.html',
  styleUrls: ['./business-units-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BusinessUnitsListComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();

  dataSource: MatTableDataSource<IBusinessUnit> = new MatTableDataSource();

  buList: IBusinessUnit[] = [];
  sortedBusinessUnits: IBusinessUnit[] = [];
  config: Config = initialConfigState;

  columnsToDisplay = ['DisplayName', 'Type', 'InternalName', 'Submissions', 'Employees', 'Status', 'Created', 'Actions'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: IBusinessUnit | null = {} as IBusinessUnit;
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  constructor(private store: Store,
              private router: Router,
              private businessUnitService: BusinessUnitService,
              private toastr: ToastrService,
              private lookups: LookupService,
              private dialog: MatDialog,
              public util: UtilService
  ) {

    this.config$ = this.store.select(getConfig).subscribe((configState: any) => {
      this.config = configState;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      if (!!bu && !!bu.businessUnitId && bu.businessUnitType == 'ADMIN') {
        this.subscribeToDatasource();
      }
    });
  }

  ngOnInit(): void {
    this.store.dispatch(updateEditingBusinessUnit({businessUnit: {} as IBusinessUnit}));
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
  }

  /**
   * Business Unit Modal
   */
  openBusinessUnitModal() {
    const buModalRef = this.dialog.open(BusinessUnitModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      panelClass: 'rondeivu-modal'
    });

    buModalRef.afterClosed().subscribe((result: any) => {
      // console.log(`Dialog result: ${result}`);
      if (!!result) {
        console.log("Creating business unit...");
        this.store.dispatch(toggleLoading({loading: true}));
        this.businessUnitService.addBusinessUnit(result).subscribe({
          next: res => {
            this.subscribeToDatasource();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success('Business Unit Added!', $localize`:@@companyName:Rondeivu`);
          }, error: (error: HttpErrorResponse) => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error('Unable to add business unit!', $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  subscribeToDatasource() {
    console.log("Fetching Business Units...");
    this.store.dispatch(updateDealId({dealId: ''}));
    this.store.dispatch(toggleLoading({loading: true}));
    //fetch the bu
    this.businessUnitService.getBusinessUnits().subscribe({
      next: (bu: IBusinessUnit[]) => {
        this.buList = bu;
        this.store.dispatch(addBusinessUnits({businessUnits: this.buList}));
        this.dataSource = new MatTableDataSource(this.buList);
        this.initDataSource();
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: err => {
        this.toastr.error("Unable to get business units!", $localize`:@@companyName:Rondeivu`);
        this.store.dispatch(toggleLoading({loading: false}));
      }
    });
  }

  initDataSource() {
    this.dataSource.filterPredicate = (data: IBusinessUnit, filter: string) => {
      const lc_filter = filter.toLowerCase();
      const DN = !!data.displayName ? data.displayName?.toLowerCase().includes(lc_filter) : false;
      const SL = !!data.slug ? data.slug?.toLowerCase().includes(lc_filter) : false;
      const IN = !!data.internalName ? data.internalName?.toLowerCase().includes(lc_filter) : false;
      const ID = !!data.intakeDescription ? data.intakeDescription?.toLowerCase().includes(lc_filter) : false;
      const ST = !!data.status ? data.status?.toLowerCase().includes(lc_filter) : false;
      const KY = !!data.kycStatus ? data.kycStatus?.toLowerCase().includes(lc_filter) : false;
      const BU = !!data.businessUnitType ? data.businessUnitType?.toLowerCase().includes(lc_filter) : false;
      // let FN = !!data.representative?.firstName? data.representative.firstName.includes(filter) : false;
      // let LN = !!data.representative?.lastName? data.representative.lastName.includes(filter) : false;
      // let EM = !!data.representative?.email? data.representative.email.includes(filter) : false;
      const SUB = !!data.submissionsCount ? data.submissionsCount?.toString().toLowerCase().includes(lc_filter) : false;
      const EMP = !!data.employeesCount ? data.employeesCount?.toString().toLowerCase().includes(lc_filter) : false;

      return DN || SL || IN || ID || ST || KY || BU || SUB || EMP;
    };
    if (!!this.paginator) {
      this.paginator.pageSize = 100;
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  viewBusinessUnit(bu: IBusinessUnit) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.store.dispatch(updateEditingBusinessUnit({businessUnit: bu}));
    this.router.navigate(['/' + this.config.selected_business_unit.slug + '/business-units/' + bu.businessUnitId + '/information']).then(() => {
      setTimeout(() => {
        this.store.dispatch(toggleLoading({loading: false}));
      }, 700);
    });

  }

  viewHistory(bu: IBusinessUnit) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.router.navigate(['/' + this.config.selected_business_unit.slug + '/history/businessunits'], {queryParams: {id: bu.businessUnitId}}).then(() => {
      setTimeout(() => {
        this.store.dispatch(toggleLoading({loading: false}));
      }, 700);
    });
  }

  delete(bu: IBusinessUnit) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.businessUnitService.deleteBusinessUnit(bu).subscribe({
      next: res => {
        this.toastr.success("Business Unit Deleted!", $localize`:@@companyName:Rondeivu`);
        this.subscribeToDatasource();
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: err => {
        this.toastr.error("Unable to delete business unit!", $localize`:@@companyName:Rondeivu`);
        this.store.dispatch(toggleLoading({loading: false}));
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  sortData(sort: Sort) {
    const data = this.buList.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedBusinessUnits = data;
      return;
    }

    this.sortedBusinessUnits = data.sort((a: IBusinessUnit, b: IBusinessUnit) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'displayName':
          return compare(a.displayName, b.displayName, isAsc);
        case 'created':
          return compare(a.representative?.created, b.representative?.created, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'kycStatus':
          return compare(a.kycStatus, b.kycStatus, isAsc);
        case 'internalName':
          return compare(a.internalName, b.internalName, isAsc);
        case 'businessUnitType':
          return compare(a.businessUnitType, b.businessUnitType, isAsc);
        case 'submissions':
          return compare(a.submissionsCount, b.submissionsCount, isAsc);
        case 'employees':
          return compare(a.employeesCount, b.employeesCount, isAsc);
        default:
          return 0;
      }
    });

    this.dataSource = new MatTableDataSource(this.sortedBusinessUnits);
    this.initDataSource();
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
