<div style="overflow-y: auto; height: calc(100vh - 112px);">
  <div class="container-fluid">
    <br/><br/>
    <div class="row justify-content-center">
      <div class="col-11 col-md-11">
        <span class="rm-title rm-title-3">Rondeivu Employees</span><br/>
        <span class="rm-title rm-title-6 rm-lighter">These are all of the Rondeivu employees:</span>
      </div>
    </div>
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <table mat-table [dataSource]="dataSource" [multiTemplateDataRows]="true" matSort>
                <!-- Search Header -->
                <ng-container matColumnDef="table-name">
                  <th colspan="1" mat-header-cell *matHeaderCellDef>
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                      <span>Rondeivu Employees</span>
                      <button mat-raised-button color="primary" class="frm-btn" (click)="addMember()">
                        <mat-icon>person_add</mat-icon>&nbsp;Add a User as a Rondeivu Employee
                      </button>
                    </div>
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="2" mat-header-cell *matHeaderCellDef>
                    <form>
                      <mat-form-field style="width: 100%; margin-left: 15px;">
                        <mat-label>Search</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input />
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form>
                  </th>
                </ng-container>
                <!-- First Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> User</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    <!--        user menu button-->
                    <app-user-card [firstName]="element.user?.['firstName']"
                                   [lastName]="element.user?.['lastName']"
                                   [displayName]="element.user?.['email']"
                                   [borderColor]="'transparent'"
                                   [background]="'#F1EDE4'"
                                   [sideColor]="'#002150'"
                                   [image]="element.user?.['imageUrl']"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- Roles Column -->
                <!-- <ng-container matColumnDef="roles">
                  <th mat-header-cell *matHeaderCellDef> Roles</th>
                  <td mat-cell *matCellDef="let element">
                    <ul *ngIf="!!element['roles']" style="padding: 0; list-style: none;">
                      <li *ngFor="let r of element['roles']" style="font-size: x-small;">
                        {{ r }}
                      </li>
                    </ul>
                  </td>
                </ng-container> -->
                <!-- Member Invite Column -->
                <ng-container matColumnDef="created">
                  <th mat-header-cell *matHeaderCellDef class="text-start"> Employee as of Date</th>
                  <td mat-cell *matCellDef="let element" class="text-start">
                    <!-- <span>{{ element['user']["created"] | date }} </span> -->
                    <div class="date-stacked">
                      <span>{{ element.user.created | date : 'mediumDate' }}</span>
                      <span>{{ element.user.created | date : 'shortTime' }}</span>
                    </div>
                  </td>
                </ng-container>

                <!-- Actions Column -->
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef class="text-center"> Actions</th>
                  <td mat-cell *matCellDef="let element" class="text-center">
                    <button mat-icon-button color="primary" title="Edit Rondeivu Employee" (click)="edit(element)">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button color="warn" title="Delete Rondeivu Employee" (click)="delete(element)">
                      <mat-icon>delete</mat-icon>
                    </button>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="['table-name','search-header']"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
                    class="example-element-row"
                    [class.example-expanded-row]="expandedElement === element">
                </tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter.</td>
                </tr>
              </table>
            </div>
          </div>
          <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[5, 10, 25, 100]"
                         aria-label="Select page of users"></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
