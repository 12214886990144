<div class="page">
  <div class="container-fluid" style="padding-right: 0;">
    <mat-sidenav-container>
      <mat-sidenav #sidenav mode="side" position="end" [opened]="openState"
                   style="width: 350px;">

        <!--               -->
        <!-- SIDE PANEL FOR NATURAL PERSONS / LEGAL ENTITIES IN THE KYC -->
        <!--               -->
        <div class="side-panel">
          <div class="row pt-3 pb-3">
            <!--            <div class="col-6 text-start">-->
            <!--              <div style="padding-left: 12px;">-->
            <!--                <span class="dot" [ngStyle]="{'background-color': IN_USE_COLOR}"></span>&nbsp;Used In KYC<br/>-->
            <!--                <span class="dot" [ngStyle]="{'background-color': NOT_IN_USE_COLOR}"></span>&nbsp;Unused-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="col-6 text-center">-->
            <!--              <mat-slide-toggle color="primary" [(ngModel)]="formDisabled"-->
            <!--                                [ngModelOptions]="{standalone: true}">{{ isFormDisabled() ? 'Unlock' : 'Lock' }} KYC-->
            <!--              </mat-slide-toggle>-->
            <!--            </div>-->
            <div class="col-12">
              <span style="font-size: small;">
                This section contains 'Natural Persons' and 'Legal Entities' either used within this KYC, or available to be used with the KYC.
                You can edit them to fill in the information required, and select them whenever <strong style="white-space: nowrap;">"Add Natural Person"</strong>, and <strong style="white-space: nowrap;">"Add Legal Entity"</strong> buttons appear.
              </span>
            </div>
          </div>
          
          <div class="row">
            <mat-accordion multi="true">
              <!--              -->
              <!-- Right Panel Start -->
              <!--              -->
              <mat-expansion-panel expanded="true">
                <mat-expansion-panel-header>
                  <mat-panel-title class="panel-header">
                    <mat-icon>people</mat-icon>&nbsp;Natural Persons &nbsp;
                    <mat-icon color="primary" 
                              title="A collection of Natural Persons either used within this KYC, or available to be used within the KYC.">help_outline
                    </mat-icon>
                  </mat-panel-title>
                  <!-- <mat-panel-description>
                    <div style="display: flex; width: 100%; justify-content: end; justify-items: end;">
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              [matTooltip]="isFormDisabled() ? '': 'Import Natural Person(s)'"
                              matTooltipPosition="left"
                              (click)="importNaturalPersons()"
                      >
                        <mat-icon
                          [ngStyle]="{'color': isFormDisabled() ? '' : 'grey', 'cursor': isFormDisabled() ? 'not-allowed': ''}">
                          system_update_alt
                        </mat-icon>
                      </button>
                      <button mat-icon-button type="button" (click)="addPersonToCollection()"
                              [disabled]="isFormDisabled()"
                              [matTooltip]="isFormDisabled() ? '': 'Add Natural Person'" matTooltipPosition="left">
                        <mat-icon
                          [ngStyle]="{'color': isFormDisabled() ? '' : 'green', 'cursor': isFormDisabled() ? 'not-allowed': ''}">
                          add
                        </mat-icon>
                      </button>
                    </div>
                  </mat-panel-description> -->
                </mat-expansion-panel-header>
                <!--                -->
                <!-- people -->
                <!--                -->
                <div *ngIf="formCache.get('people')?.value.length == 0">
                  <em>As you add persons to your KYC and fill in their info, this list will populate and you can reuse the information block.</em>
                </div>
                <div *ngFor="let element of formCache.get('people')?.value" style="margin-bottom: 10px;">
                  <!--                  <mat-card style="overflow: hidden;">-->
                  <div style="display: flex; justify-content: space-between; width: 100%; vertical-align: middle;">
                    <div style="max-width: 200px;">

                      <div>
                        <span
                          style="font-weight: bold;">{{ element?.['firstName'] + ' ' + element?.['lastName'] }}</span><br/>
                        <em style="font-size: smaller" [ngStyle]="{'color': getPersonStatusColor(element)}">{{ getPersonStatusText(element) }}</em>
                      </div>

                      <!--                        <app-user-card-->
                      <!--                          [firstName]="element?.['firstName']"-->
                      <!--                          [lastName]="element?.['lastName']"-->
                      <!--                          [displayName]="element?.['email']"-->
                      <!--                          [borderColor]="'transparent'"-->
                      <!--                          [background]="'#ffffff'"-->
                      <!--                          [sideColor]="getPersonStatusColor(element)"-->
                      <!--                          [icon]="'person'"-->
                      <!--                          [size]="userCardSize.MD"-->
                      <!--                        ></app-user-card>-->
                    </div>
                    <div>
                      <button mat-icon-button [matMenuTriggerFor]="personMenu"
                              [matBadge]="element.errors.length > 0 ? element.errors.length: null"
                              [matBadgeColor]="element.errors.length > 0 ? 'warn':'primary'">
                        <mat-icon>menu</mat-icon>
                      </button>
                      <mat-menu #personMenu="matMenu">
                        <button mat-menu-item (click)="editPerson(element)">
                          <mat-icon [matBadge]="element.errors.length > 0 ? element.errors.length: null"
                                    [matBadgeColor]="element.errors.length > 0 ? 'warn':'primary'">
                            {{ isFormDisabled() ? 'edit_off' : 'edit' }}
                          </mat-icon>
                          <span>Edit</span>
                        </button>
                        <button mat-menu-item (click)="deletePerson(element)"
                                [disabled]="isFormDisabled()"
                                [ngStyle]="{'cursor': isFormDisabled()? 'not-allowed' : ''}">
                          <mat-icon>delete</mat-icon>
                          <span>Delete</span>
                        </button>
                      </mat-menu>
                    </div>
                  </div>
                  <!--                  </mat-card>-->
                </div>
              </mat-expansion-panel>
              <!--              -->
              <!-- Panel End -->
              <!--              -->

              <!--              -->
              <!-- Panel Start -->
              <!--              -->
              <mat-expansion-panel expanded="true">
                <mat-expansion-panel-header>
                  <mat-panel-title class="panel-header">
                    <mat-icon>business</mat-icon>&nbsp;Legal Entities &nbsp;
                    <mat-icon color="primary"
                              title="A collection of Legal Entities either used within this KYC, or available to be used within the KYC.">help_outline
                    </mat-icon>
                  </mat-panel-title>
                  <!-- <mat-panel-description>
                    <div style="display: flex; width: 100%; justify-content: end; justify-items: end;">
                      <button mat-icon-button type="button"
                              [disabled]="isFormDisabled()"
                              [matTooltip]="isFormDisabled() ? '': 'Import Legal Entity'"
                              matTooltipPosition="left"
                              (click)="importLegalEntities()"
                      >
                        <mat-icon
                          [ngStyle]="{'color': isFormDisabled() ? '' : 'grey', 'cursor': isFormDisabled() ? 'not-allowed': ''}">
                          system_update_alt
                        </mat-icon>
                      </button>
                      <button mat-icon-button type="button" (click)="addBusinessToCollection()"
                              [disabled]="isFormDisabled()"
                              [matTooltip]="isFormDisabled() ? '': 'Add Legal Entity'" matTooltipPosition="left">
                        <mat-icon
                          [ngStyle]="{'color': isFormDisabled() ? '' : 'green', 'cursor': isFormDisabled() ? 'not-allowed': ''}">
                          add
                        </mat-icon>
                      </button>
                    </div>
                  </mat-panel-description> -->
                </mat-expansion-panel-header>
                <!--                -->
                <!-- businesses -->
                <!--                -->
                <div *ngIf="formCache.get('businesses')?.value.length == 0">
                  <em>As you add legal entities to your KYC and fill in their info, this list will populate and you can reuse the information block.</em>
                </div>
                <div *ngFor="let element of formCache.get('businesses')?.value" style="margin-bottom: 10px;">
                  <!--                  <mat-card style="overflow: hidden;">-->
                  <div style="display: flex; justify-content: space-between; width: 100%; vertical-align: middle;">
                    <div style="max-width: 200px;">
                      <div>
                        <span style="font-weight: bold;">{{ element?.['legalName'] }}</span><br/>
                        <em style="font-size: smaller;"
                          [ngStyle]="{'color': getBusinessStatusColor(element)}">{{ getBusinessStatusText(element) }}</em>
                      </div>
                      <!--                        <app-user-card-->
                      <!--                          [firstName]="element?.['legalName']"-->
                      <!--                          [displayName]="element?.['streetAddress'] + ' ' + element?.['city']"-->
                      <!--                          [borderColor]="'transparent'"-->
                      <!--                          [background]="'#ffffff'"-->
                      <!--                          [sideColor]="getBusinessStatusColor(element)"-->
                      <!--                          [icon]="'business'"-->
                      <!--                          [size]="userCardSize.MD"-->
                      <!--                        ></app-user-card>-->
                    </div>
                    <div>
                      <button mat-icon-button [matMenuTriggerFor]="businessMenu"
                              [matBadge]="element.errors.length > 0 ? element.errors.length: null"
                              [matBadgeColor]="element.errors.length > 0 ? 'warn':'primary'">
                        <mat-icon>menu</mat-icon>
                      </button>
                      <mat-menu #businessMenu="matMenu">
                        <button mat-menu-item (click)="editBusiness(element)">
                          <mat-icon [matBadge]="element.errors.length > 0 ? element.errors.length: null"
                                    [matBadgeColor]="element.errors.length > 0 ? 'warn':'primary'">
                            {{ isFormDisabled() ? 'edit_off' : 'edit' }}
                          </mat-icon>
                          <span>Edit</span>
                        </button>
                        <button mat-menu-item (click)="deleteBusiness(element)"
                                [disabled]="isFormDisabled()"
                                [ngStyle]="{'cursor': isFormDisabled()? 'not-allowed' : ''}">
                          <mat-icon>delete</mat-icon>
                          <span>Delete</span>
                        </button>
                      </mat-menu>
                    </div>
                  </div>
                  <!--                  </mat-card>-->
                </div>
              </mat-expansion-panel>
              <!--              -->
              <!-- Panel End -->
              <!--              -->
            </mat-accordion>
          </div>
        </div>
      </mat-sidenav>


      <!--               -->
      <!-- CONTENT START -->
      <!--               -->
      <mat-sidenav-content class="p-3">
        <form [formGroup]="form" (submit)="saveKyc()">
          
          <!--                -->
          <!-- ADMIN ONLY SECTION -->
          <!--                -->
          <div *ngIf="util.isAdmin()">
            <!-- ADMIN SECTION -->
            <div class="row pt-1">
              <div class="col-12">
                <span class="rm-title-2-5">Admin Information</span>
              </div>
            </div>
            <!-- KYC STATUS-->
            <div class="row pt-4">
              <div class="col-12">
                <mat-form-field class="form-field" appearance="outline">
                  <mat-label>KYC Status</mat-label>
                  <mat-select id="kycStatus" formControlName="kycRondeivuStatus">
                    <mat-option *ngFor="let s of lookupService.lookups.kycRondeivuStatus" [value]="s.key">{{ s.value }}</mat-option>
                  </mat-select>
                  <mat-hint align="end">A change in KYC status can send out emails to users</mat-hint>
                </mat-form-field>
              </div>
              <div class="col-12 pt-2">
                <mat-checkbox color="primary"
                              formControlName="archived"
                              [matTooltip]="'Archiving is automated, but can be set manually using this control'"
                              matTooltipPosition="left"
                >
                  Archive KYC<br />
                  <em class="text-muted">If a KYC is archived it is no longer in use, it will not show up or be usable in the platform</em>
                </mat-checkbox>
              </div>
            </div>
            <!-- KYC NAME-->
            <div class="row pt-4">
              <div class="col-12">
                <mat-form-field appearance="outline" class="form-field">
                  <mat-label>KYC Name</mat-label>
                  <input matInput type="text"
                         [formControlName]="FORM_KYC_NAME"
                         [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                         placeholder="e.g. Sample Kyc"
                         (change)="formDirty = true;"
                         required
                  />
                </mat-form-field>
              </div>
            </div>
            <!--                -->
            <!-- COMMENTS START -->
            <!--                -->
            <div class="row pt-2">
              <div class="col-12">
                <mat-form-field class="form-field" appearance="outline" floatLabel="always">
                  <mat-label>Public Comments to User</mat-label>
                  <textarea matInput type="textarea"
                            [formControlName]="FORM_PUB_COMM"
                            placeholder="Comments typed in here will always be visible to the KYC clients.  Use this space to let them know about things they need to change, update, or add in order for the KYC to become acceptable to Rondeivu."
                            style="min-height: 75px;max-height: 175px;"
                  ></textarea>
                </mat-form-field>
              </div>
              <div class="col-12">
                <mat-form-field class="form-field" appearance="outline" floatLabel="always">
                  <mat-label>Private Comments for Rondeivu</mat-label>
                  <textarea matInput type="textarea"
                            [formControlName]="FORM_PRIV_COMM"
                            placeholder="Comments here are only seen by Rondeivu Admin.  Use this as a place for private notes on this KYC."
                            style="min-height: 75px;max-height: 175px;"
                  ></textarea>
                </mat-form-field>
              </div>
            </div>
            <!-- SAVE BUTTON -->
            <div class="row" style="padding-bottom: 50px;">
              <div class="col-12 text-end">
                <button type="button" mat-raised-button color="primary"
                        (click)="saveAdminSettings()">
                  Save Admin Settings
                </button>
              </div>
            </div>
            <!-- CLIENT SECTION -->
            <div class="row pt-4">
              <div class="col-12">
                <span class="rm-title-2-5">Public View</span>&nbsp;
                <em>Everything below is what will be seen by non-admin users</em>
              </div>
            </div>
            <hr/>
          </div>
          <!--                -->
          <!-- COMMENTS START -->
          <!--                -->
          <div class="row" style="margin: 10px 0;"
               *ngIf="!!form.get(FORM_PUB_COMM)?.value && ((form.get(FORM_PUB_COMM)?.value != '') ||
                (form.get(FORM_PUB_COMM)?.value != null) || (form.get(FORM_PUB_COMM)?.value != undefined))">
            <div class="col-12" style="padding: 0;">
              <div class="comment-box">
                <span class="rm-title-2">Rondeivu's Comments</span><br/>
                {{ form.get(FORM_PUB_COMM)?.value }}
              </div>
            </div>
          </div>
          <!-- HEADER TEXTS-->
          <div class="row" style="padding-top: 25px;">
            <p *ngFor="let p of selectedEntityKyc.kycHeader.headerTexts">{{p.detail}}</p>
          </div>

          <!-- QUESTIONS-->
          <div class="row">
            <app-kyc-input-section-questions
              [sectionQuestions]="selectedEntityKyc.kycHeader.kycQuestions"
              [disableAll]="isFormDisabled()"
              (onValueChange)="updateFormHeaderQuestion($event)"
            >
            </app-kyc-input-section-questions>
          </div>
          <br/><br/>
          <!-- FOOTER TEXTS-->
          <div class="row">
            <app-kyc-input-section-text
              [sectionTexts]="selectedEntityKyc.kycHeader.footerTexts"
            >
            </app-kyc-input-section-text>
          </div>

          <hr>

          <!--               -->
          <!-- PANELS START  -->
          <!--               -->
          <div class="row" style="padding-bottom: 25px;">
            <mat-accordion multi="false">

              <!-- ONBOARDING -->
              <!-- Panel Start -->
              <mat-expansion-panel [expanded]="step === SectionType.ONBOARDING"
                                   (opened)="setStep(SectionType.ONBOARDING)"
                                   *ngIf="isSection(SectionType.ONBOARDING)">
                <mat-expansion-panel-header>
                  <mat-panel-title class="panel-header">
                    {{ getKycSection(SectionType.ONBOARDING).kycSectionTitle }}
                  </mat-panel-title>
                  <mat-panel-description>
                    <div style="display: flex; justify-content: end; width: 100%;">
                      <mat-icon
                        [ngStyle]="{'color':getSectionColor(SectionType.ONBOARDING)}"
                        [matTooltip]="getSectionTooltip(SectionType.ONBOARDING)"
                        matTooltipPosition="left"
                      >
                        {{ getSectionIcon(SectionType.ONBOARDING) }}
                      </mat-icon>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <!-- HEADER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.ONBOARDING).kycSectionHeader.headerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- QUESTIONS-->
                <div class="row">
                  <app-kyc-input-section-questions
                    [sectionQuestions]="getKycSection(SectionType.ONBOARDING).kycSectionHeader.kycQuestions"
                    [disableAll]="isFormDisabled()"
                    (onValueChange)="updateFormQuestion($event)"
                  >
                  </app-kyc-input-section-questions>
                </div>

                <!-- FOOTER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.ONBOARDING).kycSectionFooter.footerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- actions start -->
                <div class="row" style="padding: 15px 0;">
                  <div class="col-3">
                    <button mat-raised-button type="button" color="primary"
                            (click)="selectPersonForField(FORM_OB_PEOPLE, Level.BASIC)"
                            [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                            [disabled]="((form.get(FORM_OB_PEOPLE)?.value).length >= 1) || isFormDisabled()"
                    >
                      Add Natural Person
                    </button>
                  </div>
                </div>

                <!-- table start-->
                <table class="table table-striped form-table">
                  <thead>
                  <th class="form-table-header">Onboarding Person (Natural Person)</th>
                  <th class="form-table-header">Actions</th>
                  </thead>
                  <tbody>
                  <tr *ngFor="let cp of form.get(FORM_OB_PEOPLE)?.value; index as i;">
                    <td>
                      <div style="line-height:38px; display: flex;">
                        <mat-icon style="margin-top:6px;"
                                  [matBadge]="getPersonErrorsForSection(cp, SectionType.ONBOARDING).length > 0 ? getPersonErrorsForSection(cp, SectionType.ONBOARDING).length: null"
                                  [matBadgeColor]="getPersonErrorsForSection(cp, SectionType.ONBOARDING).length > 0 ? 'warn':'primary'"
                                  matBadgePosition="before"
                                  [matMenuTriggerFor]="errorMenu"
                        >
                          person
                        </mat-icon>&nbsp;
                        <span>{{ getByIdFromFormCache(cp.id, 'people')?.firstName + ' ' + getByIdFromFormCache(cp.id, 'people')?.lastName }}</span>
                      </div>
                    </td>
                    <td class="text-center">
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="editPerson(getFormCacheObjById(FORM_CACHE_PEOPLE, cp.id))">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'primary'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Edit Onboarding Person ' + (i + 1)"
                                  matTooltipPosition="left">
                          {{ isFormDisabled() ? 'edit_off' : 'edit' }}
                        </mat-icon>
                      </button>
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="deleteFormArray(FORM_OB_PEOPLE, i)">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Remove Onboarding Person ' + (i + 1)"
                                  matTooltipPosition="left">
                          delete
                        </mat-icon>
                      </button>
                    </td>
                    <!--      -->
                    <!-- ERROR CONTEXT MENU-->
                    <!--      -->
                    <mat-menu #errorMenu="matMenu">
                      <div style="padding: 15px;">
                        <div *ngIf="getPersonErrorsForSection(cp,SectionType.ONBOARDING).length > 0">
                          Errors
                          <ul style="color: red;">
                            <li *ngFor="let error of getPersonErrorsForSection(cp,SectionType.ONBOARDING)">
                              {{ error.errorMessage }}
                            </li>
                          </ul>
                        </div>
                        <div *ngIf="getPersonErrorsForSection(cp,SectionType.ONBOARDING).length == 0">
                          No Errors
                        </div>
                      </div>
                    </mat-menu>
                  </tr>
                  <tr *ngIf="isFormCollectionEmpty(FORM_OB_PEOPLE)">
                    <td colspan="4">No data in this table.</td>
                  </tr>
                  </tbody>
                </table>

                <!-- NEXT BUTTON-->
                <div class="row" style="padding-top:25px;">
                  <div class="col-12 text-end">
                    <button type="button" mat-raised-button color="primary"
                            (click)="nextPanel(SectionType.ONBOARDING)">
                      {{ isFormDisabled() ? 'Next' : 'Save Draft & Move to Next Section' }}
                    </button>
                  </div>
                </div>

              </mat-expansion-panel>
              <!-- Panel End -->

              <!-- CLIENT INFORMATION - this is the onboarding person information -->
              <!-- Panel Start -->
              <mat-expansion-panel [expanded]="step === SectionType.ENTITY_INFORMATION"
                                   (opened)="setStep(SectionType.ENTITY_INFORMATION)"
                                   *ngIf="isSection(SectionType.ENTITY_INFORMATION)">
                <mat-expansion-panel-header>
                  <mat-panel-title class="panel-header">
                    {{ getKycSection(SectionType.ENTITY_INFORMATION).kycSectionTitle }}
                  </mat-panel-title>
                  <mat-panel-description>
                    <div style="display: flex; justify-content: end; width: 100%;">
                      <mat-icon
                        [ngStyle]="{'color':getSectionColor(SectionType.ENTITY_INFORMATION)}"
                        [matTooltip]="getSectionTooltip(SectionType.ENTITY_INFORMATION)"
                        matTooltipPosition="left"
                      >
                        {{ getSectionIcon(SectionType.ENTITY_INFORMATION) }}
                      </mat-icon>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <!-- HEADER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.ENTITY_INFORMATION).kycSectionHeader.headerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- QUESTIONS-->
                <div class="row">
                  <app-kyc-input-section-questions
                    [sectionQuestions]="getKycSection(SectionType.ENTITY_INFORMATION).kycSectionHeader.kycQuestions"
                    [disableAll]="isFormDisabled()"
                    (onValueChange)="updateFormQuestion($event)"
                  >
                  </app-kyc-input-section-questions>
                </div>

                <!-- FOOTER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.ENTITY_INFORMATION).kycSectionFooter.footerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- actions start -->
                <div class="row" style="padding: 15px 0;">
                  <div class="col-12">
                    <button mat-raised-button type="button" color="primary"
                            (click)="selectLegalEntityForField(FORM_OB_ENT, Level.EXTENDED)"
                            [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                            [disabled]="((form.get(FORM_OB_ENT)?.value).length >= 1) || isFormDisabled()"
                    >
                      Add Legal Entity
                    </button>
                  </div>
                </div>

                <!-- table start-->
                <table class="table table-striped form-table">
                  <thead>
                  <th class="form-table-header">Onboarding Entity (Legal Entity)</th>
                  <th class="form-table-header">Nature of Business</th>
                  <th class="form-table-header">Source of Fund</th>
                  <th class="form-table-header">Actions</th>
                  </thead>
                  <tbody>
                  <tr *ngFor="let business of form.get(FORM_OB_ENT)?.value; index as i;">
                    <td>
                      <div style="line-height:38px; display: flex;">
                        <mat-icon style="margin-top:6px;"
                                  [matBadge]="getBusinessErrorsForSection(business, SectionType.ENTITY_INFORMATION).length > 0 ? getBusinessErrorsForSection(business, SectionType.ENTITY_INFORMATION).length: null"
                                  [matBadgeColor]="getBusinessErrorsForSection(business, SectionType.ENTITY_INFORMATION).length > 0 ? 'warn':'primary'"
                                  matBadgePosition="before"
                                  [matMenuTriggerFor]="errorMenu"
                        >
                          business
                        </mat-icon>&nbsp;
                        <span>{{ getByIdFromFormCache(business.id, 'businesses')?.legalName }}</span>
                      </div>
                    </td>
                    <td>
                      <input class="form-table-input" matInput type="text"
                             [(ngModel)]="business.natureoftheBusiness"
                             [ngModelOptions]="{standalone: true}"
                             [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                             placeholder="type here..."
                             [disabled]="isFormDisabled()"
                             (change)="formDirty = true;"
                      />
                    </td>
                    <td>
                      <input class="form-table-input" matInput type="text"
                             [(ngModel)]="business.businessSourceofFund"
                             [ngModelOptions]="{standalone: true}"
                             [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                             placeholder="type here..."
                             [disabled]="isFormDisabled()"
                             (change)="formDirty = true;"
                      />
                    </td>
                    <td class="text-center">
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="editBusiness(getFormCacheObjById(FORM_CACHE_BUSINESSES, business.id))">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'primary'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Edit Onboarding Business ' + (i + 1)"
                                  matTooltipPosition="left">
                          {{ isFormDisabled() ? 'edit_off' : 'edit' }}
                        </mat-icon>
                      </button>
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="deleteFormArray(FORM_OB_ENT, i)">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Remove Onboarding Business ' + (i + 1)"
                                  matTooltipPosition="left">
                          delete
                        </mat-icon>
                      </button>
                    </td>
                    <!--      -->
                    <!-- ERROR CONTEXT MENU-->
                    <!--      -->
                    <mat-menu #errorMenu="matMenu">
                      <div style="padding: 15px;">
                        <div *ngIf="getBusinessErrorsForSection(business,SectionType.ENTITY_INFORMATION).length > 0">
                          Errors
                          <ul style="color: red;">
                            <li
                              *ngFor="let error of getBusinessErrorsForSection(business,SectionType.ENTITY_INFORMATION)">
                              {{ error.errorMessage }}
                            </li>
                          </ul>
                        </div>
                        <div *ngIf="getBusinessErrorsForSection(business,SectionType.ENTITY_INFORMATION).length == 0">
                          No Errors
                        </div>
                      </div>
                    </mat-menu>
                  </tr>
                  <tr *ngIf="isFormCollectionEmpty(FORM_OB_ENT)">
                    <td colspan="4">No data in this table.</td>
                  </tr>
                  </tbody>
                </table>
                <!-- NEXT BUTTON-->
                <div class="row" style="padding-top: 25px;">
                  <div class="col-6 text-start">
                    <button type="button" mat-raised-button (click)="prevPanel(SectionType.ENTITY_INFORMATION)">Previous
                    </button>
                  </div>
                  <div class="col-6 text-end">
                    <button type="button" mat-raised-button color="primary"
                            (click)="nextPanel(SectionType.ENTITY_INFORMATION)">
                      {{ isFormDisabled() ? 'Next' : 'Save Draft & Move to Next Section' }}
                    </button>
                  </div>
                </div>

              </mat-expansion-panel>
              <!-- Panel End -->

              <!-- DIRECTORS -->
              <!-- Panel Start -->
              <mat-expansion-panel [expanded]="step === SectionType.DIRECTORS" (opened)="setStep(SectionType.DIRECTORS)"
                                   *ngIf="isSection(SectionType.DIRECTORS)">
                <mat-expansion-panel-header>
                  <mat-panel-title class="panel-header">
                    {{ getKycSection(SectionType.DIRECTORS).kycSectionTitle }}
                  </mat-panel-title>
                  <mat-panel-description>
                    <div style="display: flex; justify-content: end; width: 100%;">
                      <mat-icon
                        [ngStyle]="{'color':getSectionColor(SectionType.DIRECTORS)}"
                        [matTooltip]="getSectionTooltip(SectionType.DIRECTORS)"
                        matTooltipPosition="left"
                      >
                        {{ getSectionIcon(SectionType.DIRECTORS) }}
                      </mat-icon>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <!-- HEADER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.DIRECTORS).kycSectionHeader.headerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- QUESTIONS-->
                <div class="row">
                  <app-kyc-input-section-questions
                    [sectionQuestions]="getKycSection(SectionType.DIRECTORS).kycSectionHeader.kycQuestions"
                    [disableAll]="isFormDisabled()"
                    (onValueChange)="updateFormQuestion($event)"
                  >
                  </app-kyc-input-section-questions>
                </div>

                <!-- FOOTER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.DIRECTORS).kycSectionFooter.footerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- actions start-->
                <div class="row" style="padding: 15px 0;">
                  <div class="col-3">
                    <button mat-raised-button type="button" color="primary"
                            (click)="selectPersonForField(FORM_DIRECTORS, Level.BASIC)"
                            [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                            [disabled]="isFormDisabled()">
                      Add Natural Person
                    </button>
                  </div>
                </div>

                <!-- table start-->
                <table class="table table-striped form-table">
                  <thead>
                  <th class="form-table-header">Directors (Natural Persons)</th>
                  <th class="form-table-header">Actions</th>
                  </thead>
                  <tbody>
                  <tr *ngFor="let cp of form.get(FORM_DIRECTORS)?.value; index as i;">
                    <td>
                      <div style="line-height:38px; display: flex;">
                        <mat-icon style="margin-top:6px;"
                                  [matBadge]="getPersonErrorsForSection(cp, SectionType.DIRECTORS).length > 0 ? getPersonErrorsForSection(cp, SectionType.DIRECTORS).length: null"
                                  [matBadgeColor]="getPersonErrorsForSection(cp, SectionType.DIRECTORS).length > 0 ? 'warn':'primary'"
                                  matBadgePosition="before"
                                  [matMenuTriggerFor]="errorMenu"
                        >
                          person
                        </mat-icon>&nbsp;
                        <span>{{ getByIdFromFormCache(cp.id, 'people')?.firstName + ' ' + getByIdFromFormCache(cp.id, 'people')?.lastName }}</span>
                      </div>
                    </td>
                    <td class="text-center">
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="editPerson(getFormCacheObjById(FORM_CACHE_PEOPLE, cp.id))">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'primary'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Edit Director ' + (i + 1)"
                                  matTooltipPosition="left">
                          {{ isFormDisabled() ? 'edit_off' : 'edit' }}
                        </mat-icon>
                      </button>
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="deleteFormArray(FORM_DIRECTORS, i)">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Remove Director ' + (i + 1)"
                                  matTooltipPosition="left">
                          delete
                        </mat-icon>
                      </button>
                    </td>
                    <!--      -->
                    <!-- ERROR CONTEXT MENU-->
                    <!--      -->
                    <mat-menu #errorMenu="matMenu">
                      <div style="padding: 15px;">
                        <div *ngIf="getPersonErrorsForSection(cp,SectionType.DIRECTORS).length > 0">
                          Errors
                          <ul style="color: red;">
                            <li *ngFor="let error of getPersonErrorsForSection(cp,SectionType.DIRECTORS)">
                              {{ error.errorMessage }}
                            </li>
                          </ul>
                        </div>
                        <div *ngIf="getPersonErrorsForSection(cp,SectionType.DIRECTORS).length == 0">
                          No Errors
                        </div>
                      </div>
                    </mat-menu>
                  </tr>
                  <tr *ngIf="isFormCollectionEmpty(FORM_DIRECTORS)">
                    <td colspan="4">No data in this table.</td>
                  </tr>
                  </tbody>
                </table>

                <!-- NEXT BUTTON-->
                <div class="row" style="padding-top: 25px;">
                  <div class="col-6 text-start">
                    <button type="button" mat-raised-button (click)="prevPanel(SectionType.DIRECTORS)">Previous
                    </button>
                  </div>
                  <div class="col-6 text-end">
                    <button type="button" mat-raised-button color="primary"
                            (click)="nextPanel(SectionType.DIRECTORS)">
                      {{ isFormDisabled() ? 'Next' : 'Save Draft & Move to Next Section' }}
                    </button>
                  </div>
                </div>
              </mat-expansion-panel>
              <!-- Panel End -->

              <!-- REGULATORY QUESTIONS -->
              <!-- Panel Start -->
              <mat-expansion-panel [expanded]="step === SectionType.REGULATORY_QUESTIONS_1"
                                   (opened)="setStep(SectionType.REGULATORY_QUESTIONS_1)"
                                   *ngIf="isSection(SectionType.REGULATORY_QUESTIONS_1)">
                <mat-expansion-panel-header>
                  <mat-panel-title class="panel-header">
                    {{ getKycSection(SectionType.REGULATORY_QUESTIONS_1).kycSectionTitle }}
                  </mat-panel-title>
                  <mat-panel-description>
                    <div style="display: flex; justify-content: end; width: 100%;">
                      <mat-icon
                        [ngStyle]="{'color':getSectionColor(SectionType.REGULATORY_QUESTIONS_1)}"
                        [matTooltip]="getSectionTooltip(SectionType.REGULATORY_QUESTIONS_1)"
                        matTooltipPosition="left"
                      >
                        {{ getSectionIcon(SectionType.REGULATORY_QUESTIONS_1) }}
                      </mat-icon>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <!-- HEADER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.REGULATORY_QUESTIONS_1).kycSectionHeader.headerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- QUESTIONS-->
                <div class="row">
                  <app-kyc-input-section-questions
                    [sectionQuestions]="getKycSection(SectionType.REGULATORY_QUESTIONS_1).kycSectionHeader.kycQuestions"
                    [disableAll]="isFormDisabled()"
                    (onValueChange)="updateFormQuestion($event)"
                  >
                  </app-kyc-input-section-questions>
                </div>

                <!-- QUESTION HIDDEN START -->
                <div *ngIf="(getQuestionByAttribute(FORM_QUESTIONS, FORM_QUESTION_REG)?.questionAnswer == 'true')">

                  <!--                row start-->
                  <div class="row" style="padding: 15px 0;">
                    <div class="col-12">
                      <button mat-raised-button type="button" color="primary"
                              (click)="addNewObjectToCollection(FORM_REG_PEOPLE)"
                              [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                              [disabled]="getQuestionByAttribute(FORM_QUESTIONS, FORM_QUESTION_REG)?.questionAnswer == 'false' || isFormDisabled()"

                      >
                        Add Owner
                      </button>
                    </div>
                  </div>

                  <!-- table start-->
                  <table class="table table-striped form-table">
                    <thead>
                    <th class="form-table-header"></th>
                    <th class="form-table-header">Owner of Voting Right</th>
                    <th class="form-table-header">Publicly Traded Company</th>
                    <th class="form-table-header">Percent (%) Owned</th>
                    <th class="form-table-header">Actions</th>
                    </thead>
                    <tbody>
                    <tr *ngFor="let cp of form.get(FORM_REG_PEOPLE)?.value; index as i;">
                      <td>
                        <mat-icon style="margin-top:6px;"
                                  [matBadge]="getPersonErrorsForSection(cp, SectionType.REGULATORY_QUESTIONS_1).length > 0 ? getPersonErrorsForSection(cp, SectionType.REGULATORY_QUESTIONS_1).length: null"
                                  [matBadgeColor]="getPersonErrorsForSection(cp, SectionType.REGULATORY_QUESTIONS_1).length > 0 ? 'warn':'primary'"
                                  matBadgePosition="before"
                                  [matMenuTriggerFor]="errorMenu"
                        >
                          person
                        </mat-icon>&nbsp;
                      </td>
                      <td>
                        <input class="form-table-input" matInput type="text"
                               [(ngModel)]="form.get(FORM_REG_PEOPLE)?.value[i].ownerOfVotingRight"
                               [ngModelOptions]="{standalone: true}"
                               [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                               placeholder="type here..."
                               [disabled]="isFormDisabled()"
                               (change)="formDirty = true;"
                        />
                      </td>
                      <td>
                        <input class="form-table-input" matInput type="text"
                               [(ngModel)]="form.get(FORM_REG_PEOPLE)?.value[i].publicilyTradedCompany"
                               [ngModelOptions]="{standalone: true}"
                               [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                               placeholder="type here..."
                               [disabled]="isFormDisabled()"
                               (change)="formDirty = true;"
                        />
                      </td>
                      <td>
                        <input class="form-table-input" matInput type="number"
                               [(ngModel)]="form.get(FORM_REG_PEOPLE)?.value[i].ownerShip"
                               [ngModelOptions]="{standalone: true}"
                               [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                               placeholder="type here..."
                               [disabled]="isFormDisabled()"
                               (change)="formDirty = true;"
                        />
                      </td>
                      <td class="text-center">
                        <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                                (click)="deleteFormArray(FORM_REG_PEOPLE, i)">
                          <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"
                                    [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                    [matTooltip]="isFormDisabled() ? '' : 'Remove Owner of Company ' + (i + 1)"
                                    matTooltipPosition="left">
                            delete
                          </mat-icon>
                        </button>
                      </td>
                      <!--      -->
                      <!-- ERROR CONTEXT MENU-->
                      <!--      -->
                      <mat-menu #errorMenu="matMenu">
                        <div style="padding: 15px;">
                          <div *ngIf="getPersonErrorsForSection(cp,SectionType.REGULATORY_QUESTIONS_1).length > 0">
                            Errors
                            <ul style="color: red;">
                              <li
                                *ngFor="let error of getPersonErrorsForSection(cp,SectionType.REGULATORY_QUESTIONS_1)">
                                {{ error.errorMessage }}
                              </li>
                            </ul>
                          </div>
                          <div *ngIf="getPersonErrorsForSection(cp,SectionType.REGULATORY_QUESTIONS_1).length == 0">
                            No Errors
                          </div>
                        </div>
                      </mat-menu>
                    </tr>
                    <tr *ngIf="isFormCollectionEmpty(FORM_REG_PEOPLE)">
                      <td colspan="5">No data in this table.</td>
                    </tr>
                    </tbody>
                  </table>

                </div>
                <!-- QUESTION HIDDEN END -->


                <!-- FOOTER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.REGULATORY_QUESTIONS_1).kycSectionFooter.footerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- HEADER TEXTS 2 -->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.REGULATORY_QUESTIONS_2).kycSectionHeader.headerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- QUESTIONS 2 -->
                <div class="row">
                  <app-kyc-input-section-questions
                    [sectionQuestions]="getKycSection(SectionType.REGULATORY_QUESTIONS_2).kycSectionHeader.kycQuestions"
                    [disableAll]="isFormDisabled()"
                    (onValueChange)="updateFormQuestion($event)"
                  >
                  </app-kyc-input-section-questions>
                </div>

                <!-- BODY -->
                <!-- QUESTION HIDDEN START -->
                <div
                  *ngIf="getQuestionByAttribute(FORM_QUESTIONS, FORM_QUESTION_REG_2)?.questionAnswer == 'true'">

                  <!-- actions start-->
                  <div class="row" style="padding: 15px 0;">
                    <div class="col-12">
                      <button mat-raised-button type="button" color="primary"
                              (click)="addNewObjectToCollection(FORM_REG_ENT)"
                              [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                              [disabled]="(getQuestionByAttribute(FORM_QUESTIONS, FORM_QUESTION_REG_2)?.questionAnswer == 'false') || isFormDisabled()"
                      >
                        Add Owner
                      </button>
                    </div>
                  </div>

                  <!-- table start-->
                  <table class="table table-striped form-table">
                    <thead>
                    <th class="form-table-header"></th>
                    <th class="form-table-header">Authorized Signatory</th>
                    <th class="form-table-header">Company Name</th>
                    <th class="form-table-header">Percent (%) Owned</th>
                    <th class="form-table-header">Actions</th>
                    </thead>
                    <tbody>
                    <tr *ngFor="let business of form.get(FORM_REG_ENT)?.value; index as i;">
                      <td>
                        <mat-icon style="margin-top:6px;"
                                  [matBadge]="getBusinessErrorsForSection(business, SectionType.REGULATORY_QUESTIONS_2).length > 0 ? getBusinessErrorsForSection(business, SectionType.REGULATORY_QUESTIONS_2).length: null"
                                  [matBadgeColor]="getBusinessErrorsForSection(business, SectionType.REGULATORY_QUESTIONS_2).length > 0 ? 'warn':'primary'"
                                  matBadgePosition="before"
                                  [matMenuTriggerFor]="errorMenu"
                        >
                          person
                        </mat-icon>
                      </td>
                      <td>
                        <input class="form-table-input" matInput type="text"
                               [(ngModel)]="form.get(FORM_REG_ENT)?.value[i].authorizedSignatory"
                               [ngModelOptions]="{standalone: true}"
                               [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                               placeholder="type here..."
                               [disabled]="isFormDisabled()"
                               (change)="formDirty = true;"
                        />
                      </td>
                      <td>
                        <input class="form-table-input"
                               matInput type="text"
                               [(ngModel)]="form.get(FORM_REG_ENT)?.value[i].companyName"
                               [ngModelOptions]="{standalone: true}"
                               [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                               placeholder="type here..."
                               [disabled]="isFormDisabled()"
                               (change)="formDirty = true;"
                        />
                      </td>
                      <td>
                        <input class="form-table-input"
                               matInput type="number"
                               [(ngModel)]="form.get(FORM_REG_ENT)?.value[i].ownerShip"
                               [ngModelOptions]="{standalone: true}"
                               [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                               placeholder="type here..."
                               [disabled]="isFormDisabled()"
                               (change)="formDirty = true;"
                        />
                      </td>
                      <td class="text-center">
                        <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                                (click)="deleteFormArray(FORM_REG_ENT, i)">
                          <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"
                                    [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                    [matTooltip]="isFormDisabled() ? '' : 'Remove Director of Company ' + (i + 1)"
                                    matTooltipPosition="left">
                            delete
                          </mat-icon>
                        </button>
                      </td>
                      <!--      -->
                      <!-- ERROR CONTEXT MENU-->
                      <!--      -->
                      <mat-menu #errorMenu="matMenu">
                        <div style="padding: 15px;">
                          <div
                            *ngIf="getBusinessErrorsForSection(business,SectionType.REGULATORY_QUESTIONS_2).length > 0">
                            Errors
                            <ul style="color: red;">
                              <li
                                *ngFor="let error of getBusinessErrorsForSection(business,SectionType.REGULATORY_QUESTIONS_2)">
                                {{ error.errorMessage }}
                              </li>
                            </ul>
                          </div>
                          <div
                            *ngIf="getBusinessErrorsForSection(business,SectionType.REGULATORY_QUESTIONS_2).length == 0">
                            No Errors
                          </div>
                        </div>
                      </mat-menu>
                    </tr>
                    <tr *ngIf="isFormCollectionEmpty(FORM_REG_ENT)">
                      <td colspan="5">No data in this table.</td>
                    </tr>
                    </tbody>
                  </table>

                </div>
                <!-- QUESTION HIDDEN END -->

                <!-- FOOTER TEXTS 2 -->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.REGULATORY_QUESTIONS_2).kycSectionFooter.footerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- NEXT BUTTON-->
                <div class="row" style="padding-top: 25px;">
                  <div class="col-6 text-start">
                    <button type="button" mat-raised-button (click)="prevPanel(SectionType.REGULATORY_QUESTIONS_1)">
                      Previous
                    </button>
                  </div>
                  <div class="col-6 text-end">
                    <button type="button" mat-raised-button color="primary"
                            (click)="nextPanel(SectionType.REGULATORY_QUESTIONS_2)">
                      {{ isFormDisabled() ? 'Next' : 'Save Draft & Move to Next Section' }}
                    </button>
                  </div>
                </div>
              </mat-expansion-panel>
              <!-- Panel End -->

              <!-- CONTROL PERSON -->
              <!-- Panel Start -->
              <mat-expansion-panel [expanded]="step === SectionType.CONTROL_PERSON"
                                   (opened)="setStep(SectionType.CONTROL_PERSON)"
                                   *ngIf="isSection(SectionType.CONTROL_PERSON)">
                <mat-expansion-panel-header>
                  <mat-panel-title class="panel-header">
                    {{ getKycSection(SectionType.CONTROL_PERSON).kycSectionTitle }}
                  </mat-panel-title>
                  <mat-panel-description>
                    <div style="display: flex; justify-content: end; width: 100%;">
                      <mat-icon
                        [ngStyle]="{'color':getSectionColor(SectionType.CONTROL_PERSON)}"
                        [matTooltip]="getSectionTooltip(SectionType.CONTROL_PERSON)"
                        matTooltipPosition="left"
                      >
                        {{ getSectionIcon(SectionType.CONTROL_PERSON) }}
                      </mat-icon>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <!-- HEADER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.CONTROL_PERSON).kycSectionHeader.headerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- QUESTIONS-->
                <div class="row">
                  <app-kyc-input-section-questions
                    [sectionQuestions]="getKycSection(SectionType.CONTROL_PERSON).kycSectionHeader.kycQuestions"
                    [disableAll]="isFormDisabled()"
                    (onValueChange)="updateFormQuestion($event)"
                  >
                  </app-kyc-input-section-questions>
                </div>

                <!-- actions start-->
                <div class="row" style="padding: 15px 0;">
                  <div class="col-12">
                    <button mat-raised-button type="button" color="primary"
                            (click)="selectPersonForField(FORM_CTRL_PEOPLE, Level.FULL)"
                            [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                            [disabled]="isFormDisabled()">
                      Add Natural Person
                    </button>
                  </div>
                </div>

                <!-- table start-->
                <table class="table table-striped form-table">
                  <thead>
                  <th class="form-table-header">Control Persons (Natural Persons)</th>
                  <th class="form-table-header">Actions</th>
                  </thead>
                  <tbody>
                  <tr *ngFor="let cp of form.get(FORM_CTRL_PEOPLE)?.value; index as i;">
                    <td>
                      <div style="line-height:38px; display: flex;">
                        <mat-icon style="margin-top:6px;"
                                  [matBadge]="getPersonErrorsForSection(cp, SectionType.CONTROL_PERSON).length > 0 ? getPersonErrorsForSection(cp, SectionType.CONTROL_PERSON).length: null"
                                  [matBadgeColor]="getPersonErrorsForSection(cp, SectionType.CONTROL_PERSON).length > 0 ? 'warn':'primary'"
                                  matBadgePosition="before"
                                  [matMenuTriggerFor]="errorMenu"
                        >
                          person
                        </mat-icon>&nbsp;
                        <span>{{ getByIdFromFormCache(cp.id, 'people')?.firstName + ' ' + getByIdFromFormCache(cp.id, 'people')?.lastName }}</span>
                      </div>
                    </td>
                    <td class="text-center">
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="editPerson(getFormCacheObjById(FORM_CACHE_PEOPLE, cp.id))">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'primary'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Edit Control Person ' + (i + 1)"
                                  matTooltipPosition="left">
                          {{ isFormDisabled() ? 'edit_off' : 'edit' }}
                        </mat-icon>
                      </button>
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="deleteFormArray(FORM_CTRL_PEOPLE, i)">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Remove Control Person ' + (i + 1)"
                                  matTooltipPosition="left">
                          delete
                        </mat-icon>
                      </button>
                    </td>
                    <!--      -->
                    <!-- ERROR CONTEXT MENU-->
                    <!--      -->
                    <mat-menu #errorMenu="matMenu">
                      <div style="padding: 15px;">
                        <div *ngIf="getPersonErrorsForSection(cp,SectionType.CONTROL_PERSON).length > 0">
                          Errors
                          <ul style="color: red;">
                            <li *ngFor="let error of getPersonErrorsForSection(cp,SectionType.CONTROL_PERSON)">
                              {{ error.errorMessage }}
                            </li>
                          </ul>
                        </div>
                        <div *ngIf="getPersonErrorsForSection(cp,SectionType.CONTROL_PERSON).length == 0">
                          No Errors
                        </div>
                      </div>
                    </mat-menu>
                  </tr>
                  <tr *ngIf="isFormCollectionEmpty(FORM_CTRL_PEOPLE)">
                    <td colspan="4">No data in this table.</td>
                  </tr>
                  </tbody>
                </table>

                <!-- FOOTER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.CONTROL_PERSON).kycSectionFooter.footerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- NEXT BUTTON-->
                <div class="row" style="padding-top: 25px;">
                  <div class="col-6 text-start">
                    <button type="button" mat-raised-button (click)="prevPanel(SectionType.REGULATORY_QUESTIONS_2)">
                      Previous
                    </button>
                  </div>
                  <div class="col-6 text-end">
                    <button type="button" mat-raised-button color="primary"
                            (click)="nextPanel(SectionType.CONTROL_PERSON)">
                      {{ isFormDisabled() ? 'Next' : 'Save Draft & Move to Next Section' }}
                    </button>
                  </div>
                </div>

              </mat-expansion-panel>
              <!-- Panel End -->

              <!-- DIRECT BENEFICIAL OWNERS -->
              <!-- Panel Start -->
              <mat-expansion-panel [expanded]="step === SectionType.DIRECT_BENEFICIAL_OWNERS"
                                   (opened)="setStep(SectionType.DIRECT_BENEFICIAL_OWNERS)"
                                   *ngIf="isSection(SectionType.DIRECT_BENEFICIAL_OWNERS)">
                <mat-expansion-panel-header>
                  <mat-panel-title class="panel-header">
                    {{ getKycSection(SectionType.DIRECT_BENEFICIAL_OWNERS).kycSectionTitle }}
                  </mat-panel-title>
                  <mat-panel-description>
                    <div style="display: flex; justify-content: end; width: 100%;">
                      <mat-icon
                        [ngStyle]="{'color':getSectionColor(SectionType.DIRECT_BENEFICIAL_OWNERS)}"
                        [matTooltip]="getSectionTooltip(SectionType.DIRECT_BENEFICIAL_OWNERS)"
                        matTooltipPosition="left"
                      >
                        {{ getSectionIcon(SectionType.DIRECT_BENEFICIAL_OWNERS) }}
                      </mat-icon>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <!-- HEADER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.DIRECT_BENEFICIAL_OWNERS).kycSectionHeader.headerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- QUESTIONS-->
                <div class="row">
                  <app-kyc-input-section-questions
                    [sectionQuestions]="getKycSection(SectionType.DIRECT_BENEFICIAL_OWNERS).kycSectionHeader.kycQuestions"
                    [disableAll]="isFormDisabled()"
                    (onValueChange)="updateFormQuestion($event)"
                  >
                  </app-kyc-input-section-questions>
                </div>

                <!-- BODY-->
                <!-- QUESTION HIDDEN START -->
                <div *ngIf="getQuestionByAttribute(FORM_QUESTIONS, FORM_QUESTION_DBO)?.questionAnswer == 'true'">

                  <!-- actions start-->
                  <div class="row" style="padding: 15px 0;">
                    <div class="col-12">
                      <button mat-raised-button type="button" color="primary"
                              (click)="selectPersonForField(FORM_DBO_PEOPLE, Level.EXTENDED)"
                              [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                              [disabled]="(getQuestionByAttribute(FORM_QUESTIONS, FORM_QUESTION_DBO)?.questionAnswer == 'false') || isFormDisabled()">
                        Add Natural Person
                      </button> &nbsp;
                      <button mat-raised-button type="button" color="primary"
                              (click)="selectLegalEntityForField(FORM_DBO_ENT, Level.EXTENDED)"
                              [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                              [disabled]="isFormDisabled()">
                        Add Legal Entity
                      </button>
                    </div>
                  </div>

                  <!-- table start-->
                  <table class="table table-striped form-table">
                    <thead>
                    <th class="form-table-header">Direct Beneficial Owners</th>
                    <th class="form-table-header">Percent (%) Owned</th>
                    <th class="form-table-header">Actions</th>
                    </thead>
                    <tbody>
                    <!-- Natural Persons-->
                    <tr *ngFor="let cp of form.get(FORM_DBO_PEOPLE)?.value; index as i;">
                      <td>
                        <div style="line-height:38px; display: flex;">
                          <mat-icon style="margin-top:6px;"
                                    [matBadge]="getPersonErrorsForSection(cp, SectionType.DIRECT_BENEFICIAL_OWNERS).length > 0 ? getPersonErrorsForSection(cp, SectionType.DIRECT_BENEFICIAL_OWNERS).length: null"
                                    [matBadgeColor]="getPersonErrorsForSection(cp, SectionType.DIRECT_BENEFICIAL_OWNERS).length > 0 ? 'warn':'primary'"
                                    matBadgePosition="before"
                                    [matMenuTriggerFor]="errorMenu"
                          >
                            person
                          </mat-icon>&nbsp;
                          <span>{{ getByIdFromFormCache(cp.id, 'people')?.firstName + ' ' + getByIdFromFormCache(cp.id, 'people')?.lastName }}</span>
                        </div>
                      </td>
                      <td>
                        <input class="form-table-input" matInput type="number"
                               [(ngModel)]="cp.ownerShip"
                               [ngModelOptions]="{standalone: true}"
                               [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                               placeholder="type here..."
                               [disabled]="isFormDisabled()"
                               (change)="formDirty = true;"
                        />
                      </td>
                      <td class="text-center">
                        <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                                (click)="editPerson(getFormCacheObjById(FORM_CACHE_PEOPLE, cp.id))">
                          <mat-icon [color]="isFormDisabled() ? undefined : 'primary'"
                                    [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                    [matTooltip]="isFormDisabled() ? '' : 'Edit Direct Beneficial Owner ' + (i + 1)"
                                    matTooltipPosition="left">
                            {{ isFormDisabled() ? 'edit_off' : 'edit' }}
                          </mat-icon>
                        </button>
                        <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                                (click)="deleteFormArray(FORM_DBO_PEOPLE, i)">
                          <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"
                                    [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                    [matTooltip]="isFormDisabled() ? '' : 'Remove Direct Beneficial Owner ' + (i + 1)"
                                    matTooltipPosition="left">
                            delete
                          </mat-icon>
                        </button>
                      </td>
                      <!--      -->
                      <!-- ERROR CONTEXT MENU-->
                      <!--      -->
                      <mat-menu #errorMenu="matMenu">
                        <div style="padding: 15px;">
                          <div *ngIf="getPersonErrorsForSection(cp,SectionType.DIRECT_BENEFICIAL_OWNERS).length > 0">
                            Errors
                            <ul style="color: red;">
                              <li
                                *ngFor="let error of getPersonErrorsForSection(cp,SectionType.DIRECT_BENEFICIAL_OWNERS)">
                                {{ error.errorMessage }}
                              </li>
                            </ul>
                          </div>
                          <div *ngIf="getPersonErrorsForSection(cp,SectionType.DIRECT_BENEFICIAL_OWNERS).length == 0">
                            No Errors
                          </div>
                        </div>
                      </mat-menu>
                    </tr>
                    <!-- Legal Entities-->
                    <tr *ngFor="let business of form.get(FORM_DBO_ENT)?.value; index as i;">
                      <td>
                        <div style="line-height:38px; display: flex;">
                          <mat-icon style="margin-top:6px;"
                                    [matBadge]="getBusinessErrorsForSection(business, SectionType.DIRECT_BENEFICIAL_OWNERS).length > 0 ? getBusinessErrorsForSection(business, SectionType.DIRECT_BENEFICIAL_OWNERS).length: null"
                                    [matBadgeColor]="getBusinessErrorsForSection(business, SectionType.DIRECT_BENEFICIAL_OWNERS).length > 0 ? 'warn':'primary'"
                                    matBadgePosition="before"
                                    [matMenuTriggerFor]="errorMenu"
                          >
                            business
                          </mat-icon>&nbsp;
                          <span>{{ getByIdFromFormCache(business.id, 'businesses')?.legalName }}</span>
                        </div>
                      </td>
                      <td>
                        <input class="form-table-input" matInput type="number"
                               [(ngModel)]="business.ownerShip"
                               [ngModelOptions]="{standalone: true}"
                               [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                               placeholder="type here..."
                               [disabled]="isFormDisabled()"
                        />
                      </td>
                      <td class="text-center">
                        <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                                (click)="editBusiness(getFormCacheObjById(FORM_CACHE_BUSINESSES, business.id))">
                          <mat-icon [color]="isFormDisabled() ? undefined : 'primary'"
                                    [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                    [matTooltip]="isFormDisabled() ? '' : 'Edit Direct Beneficial Owning Entity ' + (i + 1)"
                                    matTooltipPosition="left">
                            {{ isFormDisabled() ? 'edit_off' : 'edit' }}
                          </mat-icon>
                        </button>
                        <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                                (click)="deleteFormArray(FORM_DBO_ENT, i)">
                          <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"
                                    [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                    [matTooltip]="isFormDisabled() ? '' : 'Remove Direct Beneficial Owning Entity ' + (i + 1)"
                                    matTooltipPosition="left">
                            delete
                          </mat-icon>
                        </button>
                      </td>
                      <!--      -->
                      <!-- ERROR CONTEXT MENU-->
                      <!--      -->
                      <mat-menu #errorMenu="matMenu">
                        <div style="padding: 15px;">
                          <div
                            *ngIf="getBusinessErrorsForSection(business,SectionType.DIRECT_BENEFICIAL_OWNERS).length > 0">
                            Errors
                            <ul style="color: red;">
                              <li
                                *ngFor="let error of getBusinessErrorsForSection(business,SectionType.DIRECT_BENEFICIAL_OWNERS)">
                                {{ error.errorMessage }}
                              </li>
                            </ul>
                          </div>
                          <div
                            *ngIf="getBusinessErrorsForSection(business,SectionType.DIRECT_BENEFICIAL_OWNERS).length == 0">
                            No Errors
                          </div>
                        </div>
                      </mat-menu>
                    </tr>
                    <tr *ngIf="isFormCollectionEmpty(FORM_DBO_PEOPLE) && isFormCollectionEmpty(FORM_DBO_ENT)">
                      <td colspan="4">No data in this table.</td>
                    </tr>
                    </tbody>
                  </table>

                  <!-- table start-->
                  <!--                  <table class="table table-striped form-table">-->
                  <!--                    <thead>-->
                  <!--                    <th class="form-table-header">Direct Beneficial Owners (Legal Entities)</th>-->
                  <!--                    <th class="form-table-header">Percent (%) Owned</th>-->
                  <!--                    <th class="form-table-header">Actions</th>-->
                  <!--                    </thead>-->
                  <!--                    <tbody>-->
                  <!--                    <tr *ngFor="let business of form.get(FORM_DBO_ENT)?.value; index as i;">-->
                  <!--                      <td>-->
                  <!--                        <div style="line-height:38px; display: flex;">-->
                  <!--                          <mat-icon style="margin-top:6px;"-->
                  <!--                                    [matBadge]="getBusinessErrorsForSection(business, SectionType.DIRECT_BENEFICIAL_OWNERS).length > 0 ? getBusinessErrorsForSection(business, SectionType.DIRECT_BENEFICIAL_OWNERS).length: null"-->
                  <!--                                    [matBadgeColor]="getBusinessErrorsForSection(business, SectionType.DIRECT_BENEFICIAL_OWNERS).length > 0 ? 'warn':'primary'"-->
                  <!--                                    matBadgePosition="before"-->
                  <!--                                    [matMenuTriggerFor]="errorMenu"-->
                  <!--                          >-->
                  <!--                            business-->
                  <!--                          </mat-icon>&nbsp;-->
                  <!--                          <span>{{ getByIdFromFormCache(business.id, 'businesses')?.legalName }}</span>-->
                  <!--                        </div>-->
                  <!--                      </td>-->
                  <!--                      <td>-->
                  <!--                        <input class="form-table-input" matInput type="number"-->
                  <!--                               [(ngModel)]="business.ownerShip"-->
                  <!--                               [ngModelOptions]="{standalone: true}"-->
                  <!--                               [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"-->
                  <!--                               placeholder="type here..."-->
                  <!--                               [disabled]="isFormDisabled()"-->
                  <!--                        />-->
                  <!--                      </td>-->
                  <!--                      <td class="text-center">-->
                  <!--                        <button mat-icon-button type="button" [disabled]="isFormDisabled()"-->
                  <!--                                (click)="editBusiness(getFormCacheObjById(FORM_CACHE_BUSINESSES, business.id))">-->
                  <!--                          <mat-icon [color]="isFormDisabled() ? undefined : 'primary'"-->
                  <!--                                    [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"-->
                  <!--                                    [matTooltip]="isFormDisabled() ? '' : 'Edit Direct Beneficial Owning Entity ' + (i + 1)"-->
                  <!--                                    matTooltipPosition="left">-->
                  <!--                            {{ isFormDisabled() ? 'edit_off' : 'edit' }}-->
                  <!--                          </mat-icon>-->
                  <!--                        </button>-->
                  <!--                        <button mat-icon-button type="button" [disabled]="isFormDisabled()"-->
                  <!--                                (click)="deleteFormArray(FORM_DBO_ENT, i)">-->
                  <!--                          <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"-->
                  <!--                                    [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"-->
                  <!--                                    [matTooltip]="isFormDisabled() ? '' : 'Remove Direct Beneficial Owning Entity ' + (i + 1)"-->
                  <!--                                    matTooltipPosition="left">-->
                  <!--                            delete-->
                  <!--                          </mat-icon>-->
                  <!--                        </button>-->
                  <!--                      </td>-->
                  <!--                      &lt;!&ndash;      &ndash;&gt;-->
                  <!--                      &lt;!&ndash; ERROR CONTEXT MENU&ndash;&gt;-->
                  <!--                      &lt;!&ndash;      &ndash;&gt;-->
                  <!--                      <mat-menu #errorMenu="matMenu">-->
                  <!--                        <div style="padding: 15px;">-->
                  <!--                          <div-->
                  <!--                            *ngIf="getBusinessErrorsForSection(business,SectionType.DIRECT_BENEFICIAL_OWNERS).length > 0">-->
                  <!--                            Errors-->
                  <!--                            <ul style="color: red;">-->
                  <!--                              <li-->
                  <!--                                *ngFor="let error of getBusinessErrorsForSection(business,SectionType.DIRECT_BENEFICIAL_OWNERS)">-->
                  <!--                                {{ error.errorMessage }}-->
                  <!--                              </li>-->
                  <!--                            </ul>-->
                  <!--                          </div>-->
                  <!--                          <div-->
                  <!--                            *ngIf="getBusinessErrorsForSection(business,SectionType.DIRECT_BENEFICIAL_OWNERS).length == 0">-->
                  <!--                            No Errors-->
                  <!--                          </div>-->
                  <!--                        </div>-->
                  <!--                      </mat-menu>-->
                  <!--                    </tr>-->
                  <!--                    <tr *ngIf="isFormCollectionEmpty(FORM_DBO_ENT)">-->
                  <!--                      <td colspan="4">No data in this table.</td>-->
                  <!--                    </tr>-->
                  <!--                    </tbody>-->
                  <!--                  </table>-->


                </div>
                <!-- QUESTION HIDDEN END -->


                <!-- FOOTER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.DIRECT_BENEFICIAL_OWNERS).kycSectionFooter.footerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- NEXT BUTTON-->
                <div class="row" style="padding-top: 25px;">
                  <div class="col-6 text-start">
                    <button type="button" mat-raised-button (click)="prevPanel(SectionType.DIRECT_BENEFICIAL_OWNERS)">
                      Previous
                    </button>
                  </div>
                  <div class="col-6 text-end">
                    <button type="button" mat-raised-button color="primary"
                            (click)="nextPanel(SectionType.DIRECT_BENEFICIAL_OWNERS)">
                      {{ isFormDisabled() ? 'Next' : 'Save Draft & Move to Next Section' }}
                    </button>
                  </div>
                </div>

              </mat-expansion-panel>
              <!-- Panel End -->

              <!-- INDIRECT BENEFICIAL OWNERS -->
              <!-- Panel Start -->
              <mat-expansion-panel [expanded]="step === SectionType.INDIRECT_BENEFICIAL_OWNERS"
                                   (opened)="setStep(SectionType.INDIRECT_BENEFICIAL_OWNERS)"
                                   *ngIf="isSection(SectionType.INDIRECT_BENEFICIAL_OWNERS)">
                <mat-expansion-panel-header>
                  <mat-panel-title class="panel-header">
                    {{ getKycSection(SectionType.INDIRECT_BENEFICIAL_OWNERS).kycSectionTitle }}
                  </mat-panel-title>
                  <mat-panel-description>
                    <div style="display: flex; justify-content: end; width: 100%;">
                      <mat-icon
                        [ngStyle]="{'color':getSectionColor(SectionType.INDIRECT_BENEFICIAL_OWNERS)}"
                        [matTooltip]="getSectionTooltip(SectionType.INDIRECT_BENEFICIAL_OWNERS)"
                        matTooltipPosition="left"
                      >
                        {{ getSectionIcon(SectionType.INDIRECT_BENEFICIAL_OWNERS) }}
                      </mat-icon>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <!-- HEADER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.INDIRECT_BENEFICIAL_OWNERS).kycSectionHeader.headerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- QUESTIONS-->
                <div class="row">
                  <app-kyc-input-section-questions
                    [sectionQuestions]="getKycSection(SectionType.INDIRECT_BENEFICIAL_OWNERS).kycSectionHeader.kycQuestions"
                    [disableAll]="isFormDisabled()"
                    (onValueChange)="updateFormQuestion($event)"
                  >
                  </app-kyc-input-section-questions>
                </div>

                <!-- QUESTION HIDDEN START -->
                <div *ngIf="(getQuestionByAttribute(FORM_QUESTIONS, FORM_QUESTION_IDBO)?.questionAnswer == 'true')">

                  <!-- actions btn-->
                  <div class="row" style="padding: 15px 0;">
                    <div class="col-12">
                      <button mat-raised-button type="button" color="primary"
                              (click)="selectPersonForField(FORM_IDBO_PEOPLE, Level.FULL)"
                              [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                              [disabled]="(getQuestionByAttribute(FORM_QUESTIONS, FORM_QUESTION_IDBO)?.questionAnswer == 'false') || isFormDisabled()"
                      >
                        Add Natural Person
                      </button>&nbsp;
                      <button mat-raised-button type="button" color="primary"
                              (click)="selectLegalEntityForField(FORM_IDBO_ENT, Level.FULL)"
                              [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                              [disabled]="isFormDisabled()">
                        Add Legal Entity
                      </button>
                    </div>
                  </div>

                  <!-- table start-->
                  <table class="table table-striped form-table">
                    <thead>
                    <th class="form-table-header">Indirect Beneficial Owners</th>
                    <th class="form-table-header">Percent (%) Owned</th>
                    <th class="form-table-header">Actions</th>
                    </thead>
                    <tbody>
                    <tr *ngFor="let cp of form.get(FORM_IDBO_PEOPLE)?.value; index as i;">
                      <td>
                        <div style="line-height:38px; display: flex;">
                          <mat-icon style="margin-top:6px;"
                                    [matBadge]="getPersonErrorsForSection(cp, SectionType.INDIRECT_BENEFICIAL_OWNERS).length > 0 ? getPersonErrorsForSection(cp, SectionType.INDIRECT_BENEFICIAL_OWNERS).length: null"
                                    [matBadgeColor]="getPersonErrorsForSection(cp, SectionType.INDIRECT_BENEFICIAL_OWNERS).length > 0 ? 'warn':'primary'"
                                    matBadgePosition="before"
                                    [matMenuTriggerFor]="errorMenu"
                          >
                            person
                          </mat-icon>&nbsp;
                          <span>{{ getByIdFromFormCache(cp.id, 'people')?.firstName + ' ' + getByIdFromFormCache(cp.id, 'people')?.lastName }}</span>
                        </div>
                      </td>
                      <td>
                        <input class="form-table-input" matInput type="number"
                               [(ngModel)]="cp.ownerShip"
                               [ngModelOptions]="{standalone: true}"
                               [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                               placeholder="type here..."
                               [disabled]="isFormDisabled()"
                               (change)="formDirty = true;"
                        />
                      </td>
                      <td class="text-center">
                        <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                                (click)="editPerson(getFormCacheObjById(FORM_CACHE_PEOPLE, cp.id))">
                          <mat-icon [color]="isFormDisabled() ? undefined : 'primary'"
                                    [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                    [matTooltip]="isFormDisabled() ? '' : 'Edit Indirect Beneficial Owner ' + (i + 1)"
                                    matTooltipPosition="left">
                            {{ isFormDisabled() ? 'edit_off' : 'edit' }}
                          </mat-icon>
                        </button>
                        <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                                (click)="deleteFormArray(FORM_IDBO_PEOPLE, i)">
                          <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"
                                    [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                    [matTooltip]="isFormDisabled() ? '' : 'Remove Indirect Beneficial Owner ' + (i + 1)"
                                    matTooltipPosition="left">
                            delete
                          </mat-icon>
                        </button>
                      </td>
                      <!--      -->
                      <!-- ERROR CONTEXT MENU-->
                      <!--      -->
                      <mat-menu #errorMenu="matMenu">
                        <div style="padding: 15px;">
                          <div *ngIf="getPersonErrorsForSection(cp,SectionType.INDIRECT_BENEFICIAL_OWNERS).length > 0">
                            Errors
                            <ul style="color: red;">
                              <li
                                *ngFor="let error of getPersonErrorsForSection(cp,SectionType.INDIRECT_BENEFICIAL_OWNERS)">
                                {{ error.errorMessage }}
                              </li>
                            </ul>
                          </div>
                          <div *ngIf="getPersonErrorsForSection(cp,SectionType.INDIRECT_BENEFICIAL_OWNERS).length == 0">
                            No Errors
                          </div>
                        </div>
                      </mat-menu>
                    </tr>
                    <tr *ngFor="let business of form.get(FORM_IDBO_ENT)?.value; index as i;">
                      <td>
                        <div style="line-height:38px; display: flex;">
                          <mat-icon style="margin-top:6px;"
                                    [matBadge]="getBusinessErrorsForSection(business, SectionType.INDIRECT_BENEFICIAL_OWNERS).length > 0 ? getBusinessErrorsForSection(business, SectionType.INDIRECT_BENEFICIAL_OWNERS).length: null"
                                    [matBadgeColor]="getBusinessErrorsForSection(business, SectionType.INDIRECT_BENEFICIAL_OWNERS).length > 0 ? 'warn':'primary'"
                                    matBadgePosition="before"
                                    [matMenuTriggerFor]="errorMenu"
                          >
                            business
                          </mat-icon>&nbsp;
                          <span>{{ getByIdFromFormCache(business.id, 'businesses')?.legalName }}</span>
                        </div>
                      </td>
                      <td>
                        <input class="form-table-input" matInput type="number"
                               [(ngModel)]="business.ownerShip"
                               [ngModelOptions]="{standalone: true}"
                               [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                               placeholder="type here..."
                               [disabled]="isFormDisabled()"
                        />
                      </td>
                      <td class="text-center">
                        <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                                (click)="editBusiness(getFormCacheObjById(FORM_CACHE_BUSINESSES, business.id))">
                          <mat-icon [color]="isFormDisabled() ? undefined : 'primary'"
                                    [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                    [matTooltip]="isFormDisabled() ? '' : 'Edit Indirect Beneficial Owning Entity ' + (i + 1)"
                                    matTooltipPosition="left">
                            {{ isFormDisabled() ? 'edit_off' : 'edit' }}
                          </mat-icon>
                        </button>
                        <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                                (click)="deleteFormArray(FORM_IDBO_ENT, i)">
                          <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"
                                    [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                    [matTooltip]="isFormDisabled() ? '' : 'Remove Indirect Beneficial Owning Entity ' + (i + 1)"
                                    matTooltipPosition="left">
                            delete
                          </mat-icon>
                        </button>
                      </td>
                      <!--      -->
                      <!-- ERROR CONTEXT MENU-->
                      <!--      -->
                      <mat-menu #errorMenu="matMenu">
                        <div style="padding: 15px;">
                          <div
                            *ngIf="getBusinessErrorsForSection(business,SectionType.INDIRECT_BENEFICIAL_OWNERS).length > 0">
                            Errors
                            <ul style="color: red;">
                              <li
                                *ngFor="let error of getBusinessErrorsForSection(business,SectionType.INDIRECT_BENEFICIAL_OWNERS)">
                                {{ error.errorMessage }}
                              </li>
                            </ul>
                          </div>
                          <div
                            *ngIf="getBusinessErrorsForSection(business,SectionType.INDIRECT_BENEFICIAL_OWNERS).length == 0">
                            No Errors
                          </div>
                        </div>
                      </mat-menu>
                    </tr>
                    <tr *ngIf="isFormCollectionEmpty(FORM_IDBO_PEOPLE) && isFormCollectionEmpty(FORM_IDBO_ENT)">
                      <td colspan="4">No data in this table.</td>
                    </tr>
                    </tbody>
                  </table>


                  <!-- table start-->
                  <!--                  <table class="table table-striped form-table">-->
                  <!--                    <thead>-->
                  <!--                    <th class="form-table-header">Indirect Beneficial Owners (Legal Entities)</th>-->
                  <!--                    <th class="form-table-header">Percent (%) Owned</th>-->
                  <!--                    <th class="form-table-header">Actions</th>-->
                  <!--                    </thead>-->
                  <!--                    <tbody>-->
                  <!--                    <tr *ngFor="let business of form.get(FORM_IDBO_ENT)?.value; index as i;">-->
                  <!--                      <td>-->
                  <!--                        <div style="line-height:38px; display: flex;">-->
                  <!--                          <mat-icon style="margin-top:6px;"-->
                  <!--                                    [matBadge]="getBusinessErrorsForSection(business, SectionType.INDIRECT_BENEFICIAL_OWNERS).length > 0 ? getBusinessErrorsForSection(business, SectionType.INDIRECT_BENEFICIAL_OWNERS).length: null"-->
                  <!--                                    [matBadgeColor]="getBusinessErrorsForSection(business, SectionType.INDIRECT_BENEFICIAL_OWNERS).length > 0 ? 'warn':'primary'"-->
                  <!--                                    matBadgePosition="before"-->
                  <!--                                    [matMenuTriggerFor]="errorMenu"-->
                  <!--                          >-->
                  <!--                            business-->
                  <!--                          </mat-icon>&nbsp;-->
                  <!--                          <span>{{ getByIdFromFormCache(business.id, 'businesses')?.legalName }}</span>-->
                  <!--                        </div>-->
                  <!--                      </td>-->
                  <!--                      <td>-->
                  <!--                        <input class="form-table-input" matInput type="number"-->
                  <!--                               [(ngModel)]="business.ownerShip"-->
                  <!--                               [ngModelOptions]="{standalone: true}"-->
                  <!--                               [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"-->
                  <!--                               placeholder="type here..."-->
                  <!--                               [disabled]="isFormDisabled()"-->
                  <!--                        />-->
                  <!--                      </td>-->
                  <!--                      <td class="text-center">-->
                  <!--                        <button mat-icon-button type="button" [disabled]="isFormDisabled()"-->
                  <!--                                (click)="editBusiness(getFormCacheObjById(FORM_CACHE_BUSINESSES, business.id))">-->
                  <!--                          <mat-icon [color]="isFormDisabled() ? undefined : 'primary'"-->
                  <!--                                    [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"-->
                  <!--                                    [matTooltip]="isFormDisabled() ? '' : 'Edit Indirect Beneficial Owning Entity ' + (i + 1)"-->
                  <!--                                    matTooltipPosition="left">-->
                  <!--                            {{ isFormDisabled() ? 'edit_off' : 'edit' }}-->
                  <!--                          </mat-icon>-->
                  <!--                        </button>-->
                  <!--                        <button mat-icon-button type="button" [disabled]="isFormDisabled()"-->
                  <!--                                (click)="deleteFormArray(FORM_IDBO_ENT, i)">-->
                  <!--                          <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"-->
                  <!--                                    [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"-->
                  <!--                                    [matTooltip]="isFormDisabled() ? '' : 'Remove Indirect Beneficial Owning Entity ' + (i + 1)"-->
                  <!--                                    matTooltipPosition="left">-->
                  <!--                            delete-->
                  <!--                          </mat-icon>-->
                  <!--                        </button>-->
                  <!--                      </td>-->
                  <!--                      &lt;!&ndash;      &ndash;&gt;-->
                  <!--                      &lt;!&ndash; ERROR CONTEXT MENU&ndash;&gt;-->
                  <!--                      &lt;!&ndash;      &ndash;&gt;-->
                  <!--                      <mat-menu #errorMenu="matMenu">-->
                  <!--                        <div style="padding: 15px;">-->
                  <!--                          <div-->
                  <!--                            *ngIf="getBusinessErrorsForSection(business,SectionType.INDIRECT_BENEFICIAL_OWNERS).length > 0">-->
                  <!--                            Errors-->
                  <!--                            <ul style="color: red;">-->
                  <!--                              <li-->
                  <!--                                *ngFor="let error of getBusinessErrorsForSection(business,SectionType.INDIRECT_BENEFICIAL_OWNERS)">-->
                  <!--                                {{ error.errorMessage }}-->
                  <!--                              </li>-->
                  <!--                            </ul>-->
                  <!--                          </div>-->
                  <!--                          <div-->
                  <!--                            *ngIf="getBusinessErrorsForSection(business,SectionType.INDIRECT_BENEFICIAL_OWNERS).length == 0">-->
                  <!--                            No Errors-->
                  <!--                          </div>-->
                  <!--                        </div>-->
                  <!--                      </mat-menu>-->
                  <!--                    </tr>-->
                  <!--                    <tr *ngIf="isFormCollectionEmpty(FORM_IDBO_ENT)">-->
                  <!--                      <td colspan="4">No data in this table.</td>-->
                  <!--                    </tr>-->
                  <!--                    </tbody>-->
                  <!--                  </table>-->
                </div>
                <!-- QUESTION HIDDEN END -->

                <!-- FOOTER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.INDIRECT_BENEFICIAL_OWNERS).kycSectionFooter.footerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- NEXT BUTTON-->
                <div class="row" style="padding-top: 25px;">
                  <div class="col-6 text-start">
                    <button type="button" mat-raised-button (click)="prevPanel(SectionType.INDIRECT_BENEFICIAL_OWNERS)">
                      Previous
                    </button>
                  </div>
                  <div class="col-6 text-end">
                    <button type="button" mat-raised-button color="primary"
                            (click)="nextPanel(SectionType.INDIRECT_BENEFICIAL_OWNERS)">
                      {{ isFormDisabled() ? 'Next' : 'Save Draft & Move to Next Section' }}
                    </button>
                  </div>
                </div>

              </mat-expansion-panel>
              <!-- Panel End -->

              <!-- GOVERNING BODY -->
              <!-- Panel Start -->
              <mat-expansion-panel [expanded]="step === SectionType.GOVERNING_BODY"
                                   (opened)="setStep(SectionType.GOVERNING_BODY)"
                                   *ngIf="isSection(SectionType.GOVERNING_BODY)">
                <mat-expansion-panel-header>
                  <mat-panel-title class="panel-header">
                    {{ getKycSection(SectionType.GOVERNING_BODY).kycSectionTitle }}
                  </mat-panel-title>
                  <mat-panel-description>
                    <div style="display: flex; justify-content: end; width: 100%;">
                      <mat-icon
                        [ngStyle]="{'color':getSectionColor(SectionType.GOVERNING_BODY)}"
                        [matTooltip]="getSectionTooltip(SectionType.GOVERNING_BODY)"
                        matTooltipPosition="left"
                      >
                        {{ getSectionIcon(SectionType.GOVERNING_BODY) }}
                      </mat-icon>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <!-- HEADER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.GOVERNING_BODY).kycSectionHeader.headerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- QUESTIONS-->
                <div class="row">
                  <app-kyc-input-section-questions
                    [sectionQuestions]="getKycSection(SectionType.GOVERNING_BODY).kycSectionHeader.kycQuestions"
                    [disableAll]="isFormDisabled()"
                    (onValueChange)="updateFormQuestion($event)"
                  >
                  </app-kyc-input-section-questions>
                </div>

                <!-- actions start -->
                <div class="row" style="padding: 15px 0;">
                  <div class="col-12">
                    <button mat-raised-button type="button" color="primary"
                            (click)="selectPersonForField(FORM_GB_PEOPLE, Level.BASIC)"
                            [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                            [disabled]="isFormDisabled()">
                      Add Natural Person
                    </button>&nbsp;
                    <button mat-raised-button type="button" color="primary"
                            (click)="selectLegalEntityForField(FORM_GB_ENT, Level.BASIC)"
                            [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                            [disabled]="isFormDisabled()">
                      Add Legal Entity
                    </button>
                  </div>
                </div>

                <!-- table start-->
                <table class="table table-striped form-table">
                  <thead>
                  <th class="form-table-header">Governing Bodies</th>
                  <th class="form-table-header">Actions</th>
                  </thead>
                  <tbody>
                  <tr *ngFor="let cp of form.get(FORM_GB_PEOPLE)?.value; index as i;">
                    <td>
                      <div style="line-height:38px; display: flex;">
                        <mat-icon style="margin-top:6px;"
                                  [matBadge]="getPersonErrorsForSection(cp, SectionType.GOVERNING_BODY).length > 0 ? getPersonErrorsForSection(cp, SectionType.GOVERNING_BODY).length: null"
                                  [matBadgeColor]="getPersonErrorsForSection(cp, SectionType.GOVERNING_BODY).length > 0 ? 'warn':'primary'"
                                  matBadgePosition="before"
                        >
                          person
                        </mat-icon>&nbsp;
                        <span>{{ getByIdFromFormCache(cp.id, 'people')?.firstName + ' ' + getByIdFromFormCache(cp.id, 'people')?.lastName }}</span>
                      </div>
                    </td>
                    <td class="text-center">
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="editPerson(getFormCacheObjById(FORM_CACHE_PEOPLE, cp.id))">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'primary'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Edit Governing Body ' + (i + 1)"
                                  matTooltipPosition="left">
                          {{ isFormDisabled() ? 'edit_off' : 'edit' }}
                        </mat-icon>
                      </button>
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="deleteFormArray(FORM_GB_PEOPLE, i)">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Remove Governing Body ' + (i + 1)"
                                  matTooltipPosition="left">
                          delete
                        </mat-icon>
                      </button>
                    </td>
                  </tr>
                  <tr *ngFor="let business of form.get(FORM_GB_ENT)?.value; index as i;">
                    <td>
                      <div style="line-height:38px; display: flex;">
                        <mat-icon style="margin-top:6px;"
                                  [matBadge]="getBusinessErrorsForSection(business, SectionType.GOVERNING_BODY).length > 0 ? getBusinessErrorsForSection(business, SectionType.GOVERNING_BODY).length: null"
                                  [matBadgeColor]="getBusinessErrorsForSection(business, SectionType.GOVERNING_BODY).length > 0 ? 'warn':'primary'"
                                  matBadgePosition="before"
                        >
                          business
                        </mat-icon>&nbsp;
                        <span>{{ getByIdFromFormCache(business.id, 'businesses')?.legalName }}</span>
                      </div>
                    </td>
                    <td class="text-center">
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="editBusiness(getFormCacheObjById(FORM_CACHE_BUSINESSES, business.id))">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'primary'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Edit Governing Body ' + (i + 1)"
                                  matTooltipPosition="left">
                          {{ isFormDisabled() ? 'edit_off' : 'edit' }}
                        </mat-icon>
                      </button>
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="deleteFormArray(FORM_GB_ENT, i)">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Remove Governing Body ' + (i + 1)"
                                  matTooltipPosition="left">
                          delete
                        </mat-icon>
                      </button>
                    </td>
                  </tr>
                  <tr *ngIf="isFormCollectionEmpty(FORM_GB_PEOPLE) && isFormCollectionEmpty(FORM_GB_ENT)">
                    <td colspan="4">No data in this table.</td>
                  </tr>
                  </tbody>
                </table>

                <!-- table start-->
                <!--                <table class="table table-striped form-table">-->
                <!--                  <thead>-->
                <!--                  <th class="form-table-header">Governing Bodies (Legal Entities)</th>-->
                <!--                  <th class="form-table-header">Actions</th>-->
                <!--                  </thead>-->
                <!--                  <tbody>-->
                <!--                  <tr *ngFor="let business of form.get(FORM_GB_ENT)?.value; index as i;">-->
                <!--                    <td>-->
                <!--                      <div style="line-height:38px; display: flex;">-->
                <!--                        <mat-icon style="margin-top:6px;"-->
                <!--                                  [matBadge]="getBusinessErrorsForSection(business, SectionType.GOVERNING_BODY).length > 0 ? getBusinessErrorsForSection(business, SectionType.GOVERNING_BODY).length: null"-->
                <!--                                  [matBadgeColor]="getBusinessErrorsForSection(business, SectionType.GOVERNING_BODY).length > 0 ? 'warn':'primary'"-->
                <!--                                  matBadgePosition="before"-->
                <!--                        >-->
                <!--                          business-->
                <!--                        </mat-icon>&nbsp;-->
                <!--                        <span>{{ getByIdFromFormCache(business.id, 'businesses')?.legalName }}</span>-->
                <!--                      </div>-->
                <!--                    </td>-->
                <!--                    <td class="text-center">-->
                <!--                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"-->
                <!--                              (click)="editBusiness(getFormCacheObjById(FORM_CACHE_BUSINESSES, business.id))">-->
                <!--                        <mat-icon [color]="isFormDisabled() ? undefined : 'primary'"-->
                <!--                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"-->
                <!--                                  [matTooltip]="isFormDisabled() ? '' : 'Edit Governing Body ' + (i + 1)"-->
                <!--                                  matTooltipPosition="left">-->
                <!--                          {{ isFormDisabled() ? 'edit_off' : 'edit' }}-->
                <!--                        </mat-icon>-->
                <!--                      </button>-->
                <!--                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"-->
                <!--                              (click)="deleteFormArray(FORM_GB_ENT, i)">-->
                <!--                        <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"-->
                <!--                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"-->
                <!--                                  [matTooltip]="isFormDisabled() ? '' : 'Remove Governing Body ' + (i + 1)"-->
                <!--                                  matTooltipPosition="left">-->
                <!--                          delete-->
                <!--                        </mat-icon>-->
                <!--                      </button>-->
                <!--                    </td>-->
                <!--                  </tr>-->
                <!--                  <tr *ngIf="isFormCollectionEmpty(FORM_GB_ENT)">-->
                <!--                    <td colspan="4">No data in this table.</td>-->
                <!--                  </tr>-->
                <!--                  </tbody>-->
                <!--                </table>-->


                <!-- FOOTER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.GOVERNING_BODY).kycSectionFooter.footerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- NEXT BUTTON-->
                <div class="row" style="padding-top: 25px;">
                  <div class="col-6 text-start">
                    <button type="button" mat-raised-button (click)="prevPanel(SectionType.GOVERNING_BODY)">Previous
                    </button>
                  </div>
                  <div class="col-6 text-end">
                    <button type="button" mat-raised-button color="primary"
                            (click)="nextPanel(SectionType.GOVERNING_BODY)">
                      {{ isFormDisabled() ? 'Next' : 'Save Draft & Move to Next Section' }}
                    </button>
                  </div>
                </div>

              </mat-expansion-panel>
              <!-- Panel End -->

              <!-- TRUSTEES -->
              <!-- Panel Start -->
              <mat-expansion-panel [expanded]="step === SectionType.TRUSTEES" (opened)="setStep(SectionType.TRUSTEES)"
                                   *ngIf="isSection(SectionType.TRUSTEES)">
                <mat-expansion-panel-header>
                  <mat-panel-title class="panel-header">
                    {{ getKycSection(SectionType.TRUSTEES).kycSectionTitle }}
                  </mat-panel-title>
                  <mat-panel-description>
                    <div style="display: flex; justify-content: end; width: 100%;">
                      <mat-icon
                        [ngStyle]="{'color':getSectionColor(SectionType.TRUSTEES)}"
                        [matTooltip]="getSectionTooltip(SectionType.TRUSTEES)"
                        matTooltipPosition="left"
                      >
                        {{ getSectionIcon(SectionType.TRUSTEES) }}
                      </mat-icon>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <!-- HEADER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.TRUSTEES).kycSectionHeader.headerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- QUESTIONS-->
                <div class="row">
                  <app-kyc-input-section-questions
                    [sectionQuestions]="getKycSection(SectionType.TRUSTEES).kycSectionHeader.kycQuestions"
                    [disableAll]="isFormDisabled()"
                    (onValueChange)="updateFormQuestion($event)"
                  >
                  </app-kyc-input-section-questions>
                </div>

                <!-- actions start -->
                <div class="row" style="padding: 15px 0;">
                  <div class="col-12">
                    <button mat-raised-button type="button" color="primary"
                            (click)="selectPersonForField(FORM_TRUST_PEOPLE, Level.FULL)"
                            [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                            [disabled]="isFormDisabled()">
                      Add Natural Person
                    </button>&nbsp;
                    <button mat-raised-button type="button" color="primary"
                            (click)="selectLegalEntityForField(FORM_TRUST_ENT, Level.LONG)"
                            [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                            [disabled]="isFormDisabled()">
                      Add Legal Entity
                    </button>
                  </div>
                </div>

                <!-- table start-->
                <table class="table table-striped form-table">
                  <thead>
                  <th class="form-table-header">Trustees</th>
                  <th class="form-table-header">Actions</th>
                  </thead>
                  <tbody>
                  <tr *ngFor="let cp of form.get(FORM_TRUST_PEOPLE)?.value; index as i;">
                    <td>
                      <div style="line-height:38px; display: flex;">
                        <mat-icon style="margin-top:6px;"
                                  [matBadge]="getPersonErrorsForSection(cp, SectionType.TRUSTEES).length > 0 ? getPersonErrorsForSection(cp, SectionType.TRUSTEES).length: null"
                                  [matBadgeColor]="getPersonErrorsForSection(cp, SectionType.TRUSTEES).length > 0 ? 'warn':'primary'"
                                  matBadgePosition="before"
                                  [matMenuTriggerFor]="errorMenu"
                        >
                          person
                        </mat-icon>&nbsp;
                        <span>{{ getByIdFromFormCache(cp.id, 'people')?.firstName + ' ' + getByIdFromFormCache(cp.id, 'people')?.lastName }}</span>
                      </div>
                    </td>
                    <td class="text-center">
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="editPerson(getFormCacheObjById(FORM_CACHE_PEOPLE, cp.id))">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'primary'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Edit Trustee ' + (i + 1)"
                                  matTooltipPosition="left">
                          {{ isFormDisabled() ? 'edit_off' : 'edit' }}
                        </mat-icon>
                      </button>
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="deleteFormArray(FORM_TRUST_PEOPLE, i)">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Remove Trustee ' + (i + 1)"
                                  matTooltipPosition="left">
                          delete
                        </mat-icon>
                      </button>
                    </td>
                    <!--      -->
                    <!-- ERROR CONTEXT MENU-->
                    <!--      -->
                    <mat-menu #errorMenu="matMenu">
                      <div style="padding: 15px;">
                        <div *ngIf="getPersonErrorsForSection(cp,SectionType.TRUSTEES).length > 0">
                          Errors
                          <ul style="color: red;">
                            <li *ngFor="let error of getPersonErrorsForSection(cp,SectionType.TRUSTEES)">
                              {{ error.errorMessage }}
                            </li>
                          </ul>
                        </div>
                        <div *ngIf="getPersonErrorsForSection(cp,SectionType.TRUSTEES).length == 0">
                          No Errors
                        </div>
                      </div>
                    </mat-menu>
                  </tr>
                  <tr *ngFor="let business of form.get(FORM_TRUST_ENT)?.value; index as i;">
                    <td>
                      <div style="line-height:38px; display: flex;">
                        <mat-icon style="margin-top:6px;"
                                  [matBadge]="getBusinessErrorsForSection(business, SectionType.TRUSTEES).length > 0 ? getBusinessErrorsForSection(business, SectionType.TRUSTEES).length: null"
                                  [matBadgeColor]="getBusinessErrorsForSection(business, SectionType.TRUSTEES).length > 0 ? 'warn':'primary'"
                                  matBadgePosition="before"
                                  [matMenuTriggerFor]="errorMenu"
                        >
                          business
                        </mat-icon>&nbsp;
                        <span>{{ getByIdFromFormCache(business.id, 'businesses')?.legalName }}</span>
                      </div>
                    </td>
                    <td class="text-center">
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="editBusiness(getFormCacheObjById(FORM_CACHE_BUSINESSES, business.id))">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'primary'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Edit Trustee ' + (i + 1)"
                                  matTooltipPosition="left">
                          {{ isFormDisabled() ? 'edit_off' : 'edit' }}
                        </mat-icon>
                      </button>
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="deleteFormArray(FORM_TRUST_ENT, i)">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Remove Trustee ' + (i + 1)"
                                  matTooltipPosition="left">
                          delete
                        </mat-icon>
                      </button>
                    </td>
                    <!--      -->
                    <!-- ERROR CONTEXT MENU-->
                    <!--      -->
                    <mat-menu #errorMenu="matMenu">
                      <div style="padding: 15px;">
                        <div *ngIf="getBusinessErrorsForSection(business,SectionType.TRUSTEES).length > 0">
                          Errors
                          <ul style="color: red;">
                            <li *ngFor="let error of getBusinessErrorsForSection(business,SectionType.TRUSTEES)">
                              {{ error.errorMessage }}
                            </li>
                          </ul>
                        </div>
                        <div *ngIf="getBusinessErrorsForSection(business,SectionType.TRUSTEES).length == 0">
                          No Errors
                        </div>
                      </div>
                    </mat-menu>
                  </tr>
                  <tr *ngIf="isFormCollectionEmpty(FORM_TRUST_PEOPLE) && isFormCollectionEmpty(FORM_TRUST_ENT)">
                    <td colspan="4">No data in this table.</td>
                  </tr>
                  </tbody>
                </table>


                <!-- table start-->
                <!--                <table class="table table-striped form-table">-->
                <!--                  <thead>-->
                <!--                  <th class="form-table-header">Trustees (Legal Entities)</th>-->
                <!--                  <th class="form-table-header">Actions</th>-->
                <!--                  </thead>-->
                <!--                  <tbody>-->
                <!--                  <tr *ngFor="let business of form.get(FORM_TRUST_ENT)?.value; index as i;">-->
                <!--                    <td>-->
                <!--                      <div style="line-height:38px; display: flex;">-->
                <!--                        <mat-icon style="margin-top:6px;"-->
                <!--                                  [matBadge]="getBusinessErrorsForSection(business, SectionType.TRUSTEES).length > 0 ? getBusinessErrorsForSection(business, SectionType.TRUSTEES).length: null"-->
                <!--                                  [matBadgeColor]="getBusinessErrorsForSection(business, SectionType.TRUSTEES).length > 0 ? 'warn':'primary'"-->
                <!--                                  matBadgePosition="before"-->
                <!--                                  [matMenuTriggerFor]="errorMenu"-->
                <!--                        >-->
                <!--                          business-->
                <!--                        </mat-icon>&nbsp;-->
                <!--                        <span>{{ getByIdFromFormCache(business.id, 'businesses')?.legalName }}</span>-->
                <!--                      </div>-->
                <!--                    </td>-->
                <!--                    <td class="text-center">-->
                <!--                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"-->
                <!--                              (click)="editBusiness(getFormCacheObjById(FORM_CACHE_BUSINESSES, business.id))">-->
                <!--                        <mat-icon [color]="isFormDisabled() ? undefined : 'primary'"-->
                <!--                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"-->
                <!--                                  [matTooltip]="isFormDisabled() ? '' : 'Edit Trustee ' + (i + 1)"-->
                <!--                                  matTooltipPosition="left">-->
                <!--                          {{ isFormDisabled() ? 'edit_off' : 'edit' }}-->
                <!--                        </mat-icon>-->
                <!--                      </button>-->
                <!--                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"-->
                <!--                              (click)="deleteFormArray(FORM_TRUST_ENT, i)">-->
                <!--                        <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"-->
                <!--                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"-->
                <!--                                  [matTooltip]="isFormDisabled() ? '' : 'Remove Trustee ' + (i + 1)"-->
                <!--                                  matTooltipPosition="left">-->
                <!--                          delete-->
                <!--                        </mat-icon>-->
                <!--                      </button>-->
                <!--                    </td>-->
                <!--                    &lt;!&ndash;      &ndash;&gt;-->
                <!--                    &lt;!&ndash; ERROR CONTEXT MENU&ndash;&gt;-->
                <!--                    &lt;!&ndash;      &ndash;&gt;-->
                <!--                    <mat-menu #errorMenu="matMenu">-->
                <!--                      <div style="padding: 15px;">-->
                <!--                        <div *ngIf="getBusinessErrorsForSection(business,SectionType.TRUSTEES).length > 0">-->
                <!--                          Errors-->
                <!--                          <ul style="color: red;">-->
                <!--                            <li *ngFor="let error of getBusinessErrorsForSection(business,SectionType.TRUSTEES)">-->
                <!--                              {{ error.errorMessage }}-->
                <!--                            </li>-->
                <!--                          </ul>-->
                <!--                        </div>-->
                <!--                        <div *ngIf="getBusinessErrorsForSection(business,SectionType.TRUSTEES).length == 0">-->
                <!--                          No Errors-->
                <!--                        </div>-->
                <!--                      </div>-->
                <!--                    </mat-menu>-->
                <!--                  </tr>-->
                <!--                  <tr *ngIf="isFormCollectionEmpty(FORM_TRUST_ENT)">-->
                <!--                    <td colspan="4">No data in this table.</td>-->
                <!--                  </tr>-->
                <!--                  </tbody>-->
                <!--                </table>-->


                <!-- FOOTER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.TRUSTEES).kycSectionFooter.footerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- NEXT BUTTON-->
                <div class="row" style="padding-top:25px;">
                  <div class="col-6 text-start">
                    <button type="button" mat-raised-button (click)="prevPanel(SectionType.REGULATORY_QUESTIONS_2)">
                      Previous
                    </button>
                  </div>
                  <div class="col-6 text-end">
                    <button type="button" mat-raised-button color="primary" (click)="nextPanel(SectionType.TRUSTEES)">
                      {{ isFormDisabled() ? 'Next' : 'Save Draft & Move to Next Section' }}
                    </button>
                  </div>
                </div>

              </mat-expansion-panel>
              <!-- Panel End -->

              <!-- TRUST SETTLORS -->
              <!-- Panel Start -->
              <mat-expansion-panel [expanded]="step === SectionType.TRUST_SETTLORS"
                                   (opened)="setStep(SectionType.TRUST_SETTLORS)"
                                   *ngIf="isSection(SectionType.TRUST_SETTLORS)">
                <mat-expansion-panel-header>
                  <mat-panel-title class="panel-header">
                    {{ getKycSection(SectionType.TRUST_SETTLORS).kycSectionTitle }}
                  </mat-panel-title>
                  <mat-panel-description>
                    <div style="display: flex; justify-content: end; width: 100%;">
                      <mat-icon
                        [ngStyle]="{'color':getSectionColor(SectionType.TRUST_SETTLORS)}"
                        [matTooltip]="getSectionTooltip(SectionType.TRUST_SETTLORS)"
                        matTooltipPosition="left"
                      >
                        {{ getSectionIcon(SectionType.TRUST_SETTLORS) }}
                      </mat-icon>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <!-- HEADER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.TRUST_SETTLORS).kycSectionHeader.headerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- QUESTIONS-->
                <div class="row">
                  <app-kyc-input-section-questions
                    [sectionQuestions]="getKycSection(SectionType.TRUST_SETTLORS).kycSectionHeader.kycQuestions"
                    [disableAll]="isFormDisabled()"
                    (onValueChange)="updateFormQuestion($event)"
                  >
                  </app-kyc-input-section-questions>
                </div>

                <!-- actions start -->
                <div class="row" style="padding: 15px 0;">
                  <div class="col-12">
                    <button mat-raised-button type="button" color="primary"
                            (click)="selectPersonForField(FORM_TRUST_SET_PEOPLE, Level.LONG)"
                            [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                            [disabled]="isFormDisabled()">
                      Add Natural Person
                    </button>&nbsp;
                    <button mat-raised-button type="button" color="primary"
                            (click)="selectLegalEntityForField(FORM_TRUST_SET_ENT, Level.LONG)"
                            [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                            [disabled]="isFormDisabled()">
                      Add Legal Entity
                    </button>
                  </div>
                </div>

                <!-- table start-->
                <table class="table table-striped form-table">
                  <thead>
                  <th class="form-table-header">Trust Settlors</th>
                  <th class="form-table-header">Actions</th>
                  </thead>
                  <tbody>
                  <tr *ngFor="let cp of form.get(FORM_TRUST_SET_PEOPLE)?.value; index as i;">
                    <td>
                      <div style="line-height:38px; display: flex;">
                        <mat-icon style="margin-top:6px;"
                                  [matBadge]="getPersonErrorsForSection(cp, SectionType.TRUST_SETTLORS).length > 0 ? getPersonErrorsForSection(cp, SectionType.TRUST_SETTLORS).length: null"
                                  [matBadgeColor]="getPersonErrorsForSection(cp, SectionType.TRUST_SETTLORS).length > 0 ? 'warn':'primary'"
                                  matBadgePosition="before"
                                  [matMenuTriggerFor]="errorMenu"
                        >
                          person
                        </mat-icon>&nbsp;
                        <span>{{ getByIdFromFormCache(cp.id, 'people')?.firstName + ' ' + getByIdFromFormCache(cp.id, 'people')?.lastName }}</span>
                      </div>
                    </td>
                    <td class="text-center">
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="editPerson(getFormCacheObjById(FORM_CACHE_PEOPLE, cp.id))">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'primary'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Edit Trust Settlor ' + (i + 1)"
                                  matTooltipPosition="left">
                          {{ isFormDisabled() ? 'edit_off' : 'edit' }}
                        </mat-icon>
                      </button>
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="deleteFormArray(FORM_TRUST_SET_PEOPLE, i)">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Remove Trust Settlor ' + (i + 1)"
                                  matTooltipPosition="left">
                          delete
                        </mat-icon>
                      </button>
                    </td>
                    <!--      -->
                    <!-- ERROR CONTEXT MENU-->
                    <!--      -->
                    <mat-menu #errorMenu="matMenu">
                      <div style="padding: 15px;">
                        <div *ngIf="getPersonErrorsForSection(cp,SectionType.TRUST_SETTLORS).length > 0">
                          Errors
                          <ul style="color: red;">
                            <li *ngFor="let error of getPersonErrorsForSection(cp,SectionType.TRUST_SETTLORS)">
                              {{ error.errorMessage }}
                            </li>
                          </ul>
                        </div>
                        <div *ngIf="getPersonErrorsForSection(cp,SectionType.TRUST_SETTLORS).length == 0">
                          No Errors
                        </div>
                      </div>
                    </mat-menu>
                  </tr>
                  <tr *ngFor="let business of form.get(FORM_TRUST_SET_ENT)?.value; index as i;">
                    <td>
                      <div style="line-height:38px; display: flex;">
                        <mat-icon style="margin-top:6px;"
                                  [matBadge]="getBusinessErrorsForSection(business, SectionType.TRUST_SETTLORS).length > 0 ? getBusinessErrorsForSection(business, SectionType.TRUST_SETTLORS).length: null"
                                  [matBadgeColor]="getBusinessErrorsForSection(business, SectionType.TRUST_SETTLORS).length > 0 ? 'warn':'primary'"
                                  matBadgePosition="before"
                                  [matMenuTriggerFor]="errorMenu"
                        >
                          business
                        </mat-icon>&nbsp;
                        <span>{{ getByIdFromFormCache(business.id, 'businesses')?.legalName }}</span>
                      </div>
                    </td>
                    <td class="text-center">
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="editBusiness(getFormCacheObjById(FORM_CACHE_BUSINESSES, business.id))">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'primary'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Edit Trust Settlor ' + (i + 1)"
                                  matTooltipPosition="left">
                          {{ isFormDisabled() ? 'edit_off' : 'edit' }}
                        </mat-icon>
                      </button>
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="deleteFormArray(FORM_TRUST_SET_ENT, i)">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Remove Trust Settlor ' + (i + 1)"
                                  matTooltipPosition="left">
                          delete
                        </mat-icon>
                      </button>
                    </td>
                    <!--      -->
                    <!-- ERROR CONTEXT MENU-->
                    <!--      -->
                    <mat-menu #errorMenu="matMenu">
                      <div style="padding: 15px;">
                        <div *ngIf="getBusinessErrorsForSection(business,SectionType.TRUST_SETTLORS).length > 0">
                          Errors
                          <ul style="color: red;">
                            <li *ngFor="let error of getBusinessErrorsForSection(business,SectionType.TRUST_SETTLORS)">
                              {{ error.errorMessage }}
                            </li>
                          </ul>
                        </div>
                        <div *ngIf="getBusinessErrorsForSection(business,SectionType.TRUST_SETTLORS).length == 0">
                          No Errors
                        </div>
                      </div>
                    </mat-menu>
                  </tr>
                  <tr
                    *ngIf="isFormCollectionEmpty(FORM_TRUST_SET_PEOPLE) && isFormCollectionEmpty(FORM_TRUST_SET_ENT)">
                    <td colspan="4">No data in this table.</td>
                  </tr>
                  </tbody>
                </table>


                <!-- table start-->
                <!--                <table class="table table-striped form-table">-->
                <!--                  <thead>-->
                <!--                  <th class="form-table-header">Trust Settlors (Legal Entities)</th>-->
                <!--                  <th class="form-table-header">Actions</th>-->
                <!--                  </thead>-->
                <!--                  <tbody>-->
                <!--                  <tr *ngFor="let business of form.get(FORM_TRUST_SET_ENT)?.value; index as i;">-->
                <!--                    <td>-->
                <!--                      <div style="line-height:38px; display: flex;">-->
                <!--                        <mat-icon style="margin-top:6px;"-->
                <!--                                  [matBadge]="getBusinessErrorsForSection(business, SectionType.TRUST_SETTLORS).length > 0 ? getBusinessErrorsForSection(business, SectionType.TRUST_SETTLORS).length: null"-->
                <!--                                  [matBadgeColor]="getBusinessErrorsForSection(business, SectionType.TRUST_SETTLORS).length > 0 ? 'warn':'primary'"-->
                <!--                                  matBadgePosition="before"-->
                <!--                                  [matMenuTriggerFor]="errorMenu"-->
                <!--                        >-->
                <!--                          business-->
                <!--                        </mat-icon>&nbsp;-->
                <!--                        <span>{{ getByIdFromFormCache(business.id, 'businesses')?.legalName }}</span>-->
                <!--                      </div>-->
                <!--                    </td>-->
                <!--                    <td class="text-center">-->
                <!--                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"-->
                <!--                              (click)="editBusiness(getFormCacheObjById(FORM_CACHE_BUSINESSES, business.id))">-->
                <!--                        <mat-icon [color]="isFormDisabled() ? undefined : 'primary'"-->
                <!--                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"-->
                <!--                                  [matTooltip]="isFormDisabled() ? '' : 'Edit Trust Settlor ' + (i + 1)"-->
                <!--                                  matTooltipPosition="left">-->
                <!--                          {{ isFormDisabled() ? 'edit_off' : 'edit' }}-->
                <!--                        </mat-icon>-->
                <!--                      </button>-->
                <!--                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"-->
                <!--                              (click)="deleteFormArray(FORM_TRUST_SET_ENT, i)">-->
                <!--                        <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"-->
                <!--                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"-->
                <!--                                  [matTooltip]="isFormDisabled() ? '' : 'Remove Trust Settlor ' + (i + 1)"-->
                <!--                                  matTooltipPosition="left">-->
                <!--                          delete-->
                <!--                        </mat-icon>-->
                <!--                      </button>-->
                <!--                    </td>-->
                <!--                    &lt;!&ndash;      &ndash;&gt;-->
                <!--                    &lt;!&ndash; ERROR CONTEXT MENU&ndash;&gt;-->
                <!--                    &lt;!&ndash;      &ndash;&gt;-->
                <!--                    <mat-menu #errorMenu="matMenu">-->
                <!--                      <div style="padding: 15px;">-->
                <!--                        <div *ngIf="getBusinessErrorsForSection(business,SectionType.TRUST_SETTLORS).length > 0">-->
                <!--                          Errors-->
                <!--                          <ul style="color: red;">-->
                <!--                            <li *ngFor="let error of getBusinessErrorsForSection(business,SectionType.TRUST_SETTLORS)">-->
                <!--                              {{ error.errorMessage }}-->
                <!--                            </li>-->
                <!--                          </ul>-->
                <!--                        </div>-->
                <!--                        <div *ngIf="getBusinessErrorsForSection(business,SectionType.TRUST_SETTLORS).length == 0">-->
                <!--                          No Errors-->
                <!--                        </div>-->
                <!--                      </div>-->
                <!--                    </mat-menu>-->
                <!--                  </tr>-->
                <!--                  <tr-->
                <!--                    *ngIf="isFormCollectionEmpty(FORM_TRUST_SET_ENT)">-->
                <!--                    <td colspan="4">No data in this table.</td>-->
                <!--                  </tr>-->
                <!--                  </tbody>-->
                <!--                </table>-->


                <!-- FOOTER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.TRUST_SETTLORS).kycSectionFooter.footerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- NEXT BUTTON-->
                <div class="row" style="padding-top:25px;">
                  <div class="col-6 text-start">
                    <button type="button" mat-raised-button (click)="prevPanel(SectionType.TRUST_SETTLORS)">Previous
                    </button>
                  </div>
                  <div class="col-6 text-end">
                    <button type="button" mat-raised-button color="primary"
                            (click)="nextPanel(SectionType.TRUST_SETTLORS)">
                      {{ isFormDisabled() ? 'Next' : 'Save Draft & Move to Next Section' }}
                    </button>
                  </div>
                </div>

              </mat-expansion-panel>
              <!-- Panel End -->

              <!-- TRUST BENEFICIARIES -->
              <!-- Panel Start -->
              <mat-expansion-panel [expanded]="step === SectionType.TRUST_BENEFICIARIES"
                                   (opened)="setStep(SectionType.TRUST_BENEFICIARIES)"
                                   *ngIf="isSection(SectionType.TRUST_BENEFICIARIES)">
                <mat-expansion-panel-header>
                  <mat-panel-title class="panel-header">
                    {{ getKycSection(SectionType.TRUST_BENEFICIARIES).kycSectionTitle }}
                  </mat-panel-title>
                  <mat-panel-description>
                    <div style="display: flex; justify-content: end; width: 100%;">
                      <mat-icon
                        [ngStyle]="{'color':getSectionColor(SectionType.TRUST_BENEFICIARIES)}"
                        [matTooltip]="getSectionTooltip(SectionType.TRUST_BENEFICIARIES)"
                        matTooltipPosition="left"
                      >
                        {{ getSectionIcon(SectionType.TRUST_BENEFICIARIES) }}
                      </mat-icon>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <!-- HEADER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.TRUST_BENEFICIARIES).kycSectionHeader.headerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- QUESTIONS-->
                <div class="row">
                  <app-kyc-input-section-questions
                    [sectionQuestions]="getKycSection(SectionType.TRUST_BENEFICIARIES).kycSectionHeader.kycQuestions"
                    [disableAll]="isFormDisabled()"
                    (onValueChange)="updateFormQuestion($event)"
                  >
                  </app-kyc-input-section-questions>
                </div>
                <!-- actions start -->
                <div class="row" style="padding: 15px 0;">
                  <div class="col-12">
                    <button mat-raised-button type="button" color="primary"
                            (click)="selectPersonForField(FORM_TRUST_BEN_PEOPLE, Level.LONG)"
                            [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                            [disabled]="isFormDisabled()">
                      Add Natural Person
                    </button>&nbsp;
                    <button mat-raised-button type="button" color="primary"
                            (click)="selectLegalEntityForField(FORM_TRUST_BEN_ENT, Level.LONG)"
                            [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                            [disabled]="isFormDisabled()">
                      Add Legal Entity
                    </button>
                  </div>
                </div>

                <!-- table start-->
                <table class="table table-striped form-table">
                  <thead>
                  <th class="form-table-header">Trust Beneficiaries</th>
                  <th class="form-table-header">Actions</th>
                  </thead>
                  <tbody>
                  <tr *ngFor="let cp of form.get(FORM_TRUST_BEN_PEOPLE)?.value; index as i;">
                    <td>
                      <div style="line-height:38px; display: flex;">
                        <mat-icon style="margin-top:6px;"
                                  [matBadge]="getPersonErrorsForSection(cp, SectionType.TRUST_BENEFICIARIES).length > 0 ? getPersonErrorsForSection(cp, SectionType.TRUST_BENEFICIARIES).length: null"
                                  [matBadgeColor]="getPersonErrorsForSection(cp, SectionType.TRUST_BENEFICIARIES).length > 0 ? 'warn':'primary'"
                                  matBadgePosition="before"
                                  [matMenuTriggerFor]="errorMenu"
                        >
                          person
                        </mat-icon>&nbsp;
                        <span>{{ getByIdFromFormCache(cp.id, 'people')?.firstName + ' ' + getByIdFromFormCache(cp.id, 'people')?.lastName }}</span>
                      </div>
                    </td>
                    <td class="text-center">
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="editPerson(getFormCacheObjById(FORM_CACHE_PEOPLE, cp.id))">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'primary'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Edit Trust Beneficiary' + (i + 1)"
                                  matTooltipPosition="left">
                          {{ isFormDisabled() ? 'edit_off' : 'edit' }}
                        </mat-icon>
                      </button>
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="deleteFormArray(FORM_TRUST_BEN_PEOPLE, i)">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Remove Trust Beneficiary ' + (i + 1)"
                                  matTooltipPosition="left">
                          delete
                        </mat-icon>
                      </button>
                    </td>
                    <!--      -->
                    <!-- ERROR CONTEXT MENU-->
                    <!--      -->
                    <mat-menu #errorMenu="matMenu">
                      <div style="padding: 15px;">
                        <div *ngIf="getPersonErrorsForSection(cp,SectionType.TRUST_BENEFICIARIES).length > 0">
                          Errors
                          <ul style="color: red;">
                            <li *ngFor="let error of getPersonErrorsForSection(cp,SectionType.TRUST_BENEFICIARIES)">
                              {{ error.errorMessage }}
                            </li>
                          </ul>
                        </div>
                        <div *ngIf="getPersonErrorsForSection(cp,SectionType.TRUST_BENEFICIARIES).length == 0">
                          No Errors
                        </div>
                      </div>
                    </mat-menu>
                  </tr>
                  <tr *ngFor="let business of form.get(FORM_TRUST_BEN_ENT)?.value; index as i;">
                    <td>
                      <div style="line-height:38px; display: flex;">
                        <mat-icon style="margin-top:6px;"
                                  [matBadge]="getBusinessErrorsForSection(business, SectionType.TRUST_BENEFICIARIES).length > 0 ? getBusinessErrorsForSection(business, SectionType.TRUST_BENEFICIARIES).length: null"
                                  [matBadgeColor]="getBusinessErrorsForSection(business, SectionType.TRUST_BENEFICIARIES).length > 0 ? 'warn':'primary'"
                                  matBadgePosition="before"
                                  [matMenuTriggerFor]="errorMenu"
                        >
                          business
                        </mat-icon>&nbsp;
                        <span>{{ getByIdFromFormCache(business.id, 'businesses')?.legalName }}</span>
                      </div>
                    </td>
                    <td class="text-center">
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="editBusiness(getFormCacheObjById(FORM_CACHE_BUSINESSES, business.id))">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'primary'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Edit Trust Beneficiary ' + (i + 1)"
                                  matTooltipPosition="left">
                          {{ isFormDisabled() ? 'edit_off' : 'edit' }}
                        </mat-icon>
                      </button>
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="deleteFormArray(FORM_TRUST_BEN_ENT, i)">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Remove Trust Beneficiary ' + (i + 1)"
                                  matTooltipPosition="left">
                          delete
                        </mat-icon>
                      </button>
                    </td>
                    <!--      -->
                    <!-- ERROR CONTEXT MENU-->
                    <!--      -->
                    <mat-menu #errorMenu="matMenu">
                      <div style="padding: 15px;">
                        <div *ngIf="getBusinessErrorsForSection(business,SectionType.TRUST_BENEFICIARIES).length > 0">
                          Errors
                          <ul style="color: red;">
                            <li
                              *ngFor="let error of getBusinessErrorsForSection(business,SectionType.TRUST_BENEFICIARIES)">
                              {{ error.errorMessage }}
                            </li>
                          </ul>
                        </div>
                        <div *ngIf="getBusinessErrorsForSection(business,SectionType.TRUST_BENEFICIARIES).length == 0">
                          No Errors
                        </div>
                      </div>
                    </mat-menu>
                  </tr>
                  <tr
                    *ngIf="isFormCollectionEmpty(FORM_TRUST_BEN_PEOPLE) && isFormCollectionEmpty(FORM_TRUST_BEN_ENT)">
                    <td colspan="4">No data in this table.</td>
                  </tr>
                  </tbody>
                </table>


                <!-- table start-->
                <!--                <table class="table table-striped form-table">-->
                <!--                  <thead>-->
                <!--                  <th class="form-table-header">Trust Beneficiaries (Legal Entities)</th>-->
                <!--                  <th class="form-table-header">Actions</th>-->
                <!--                  </thead>-->
                <!--                  <tbody>-->
                <!--                  <tr *ngFor="let business of form.get(FORM_TRUST_BEN_ENT)?.value; index as i;">-->
                <!--                    <td>-->
                <!--                      <div style="line-height:38px; display: flex;">-->
                <!--                        <mat-icon style="margin-top:6px;"-->
                <!--                                  [matBadge]="getBusinessErrorsForSection(business, SectionType.TRUST_BENEFICIARIES).length > 0 ? getBusinessErrorsForSection(business, SectionType.TRUST_BENEFICIARIES).length: null"-->
                <!--                                  [matBadgeColor]="getBusinessErrorsForSection(business, SectionType.TRUST_BENEFICIARIES).length > 0 ? 'warn':'primary'"-->
                <!--                                  matBadgePosition="before"-->
                <!--                                  [matMenuTriggerFor]="errorMenu"-->
                <!--                        >-->
                <!--                          business-->
                <!--                        </mat-icon>&nbsp;-->
                <!--                        <span>{{ getByIdFromFormCache(business.id, 'businesses')?.legalName }}</span>-->
                <!--                      </div>-->
                <!--                    </td>-->
                <!--                    <td class="text-center">-->
                <!--                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"-->
                <!--                              (click)="editBusiness(getFormCacheObjById(FORM_CACHE_BUSINESSES, business.id))">-->
                <!--                        <mat-icon [color]="isFormDisabled() ? undefined : 'primary'"-->
                <!--                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"-->
                <!--                                  [matTooltip]="isFormDisabled() ? '' : 'Edit Trust Beneficiary ' + (i + 1)"-->
                <!--                                  matTooltipPosition="left">-->
                <!--                          {{ isFormDisabled() ? 'edit_off' : 'edit' }}-->
                <!--                        </mat-icon>-->
                <!--                      </button>-->
                <!--                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"-->
                <!--                              (click)="deleteFormArray(FORM_TRUST_BEN_ENT, i)">-->
                <!--                        <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"-->
                <!--                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"-->
                <!--                                  [matTooltip]="isFormDisabled() ? '' : 'Remove Trust Beneficiary ' + (i + 1)"-->
                <!--                                  matTooltipPosition="left">-->
                <!--                          delete-->
                <!--                        </mat-icon>-->
                <!--                      </button>-->
                <!--                    </td>-->
                <!--                    &lt;!&ndash;      &ndash;&gt;-->
                <!--                    &lt;!&ndash; ERROR CONTEXT MENU&ndash;&gt;-->
                <!--                    &lt;!&ndash;      &ndash;&gt;-->
                <!--                    <mat-menu #errorMenu="matMenu">-->
                <!--                      <div style="padding: 15px;">-->
                <!--                        <div *ngIf="getBusinessErrorsForSection(business,SectionType.TRUST_BENEFICIARIES).length > 0">-->
                <!--                          Errors-->
                <!--                          <ul style="color: red;">-->
                <!--                            <li-->
                <!--                              *ngFor="let error of getBusinessErrorsForSection(business,SectionType.TRUST_BENEFICIARIES)">-->
                <!--                              {{ error.errorMessage }}-->
                <!--                            </li>-->
                <!--                          </ul>-->
                <!--                        </div>-->
                <!--                        <div *ngIf="getBusinessErrorsForSection(business,SectionType.TRUST_BENEFICIARIES).length == 0">-->
                <!--                          No Errors-->
                <!--                        </div>-->
                <!--                      </div>-->
                <!--                    </mat-menu>-->
                <!--                  </tr>-->
                <!--                  <tr-->
                <!--                    *ngIf="isFormCollectionEmpty(FORM_TRUST_BEN_ENT)">-->
                <!--                    <td colspan="4">No data in this table.</td>-->
                <!--                  </tr>-->
                <!--                  </tbody>-->
                <!--                </table>-->


                <!-- FOOTER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.TRUST_BENEFICIARIES).kycSectionFooter.footerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- NEXT BUTTON-->
                <div class="row" style="padding-top:25px;">
                  <div class="col-6 text-start">
                    <button type="button" mat-raised-button (click)="prevPanel(SectionType.TRUST_BENEFICIARIES)">
                      Previous
                    </button>
                  </div>
                  <div class="col-6 text-end">
                    <button type="button" mat-raised-button color="primary"
                            (click)="nextPanel(SectionType.TRUST_BENEFICIARIES)">
                      {{ isFormDisabled() ? 'Next' : 'Save Draft & Move to Next Section' }}
                    </button>
                  </div>
                </div>

              </mat-expansion-panel>
              <!-- Panel End -->

              <!-- AUTHORIZED SIGNATORIES -->
              <!-- Panel Start -->
              <mat-expansion-panel [expanded]="step === SectionType.AUTHORIZED_SIGNATORIES"
                                   (opened)="setStep(SectionType.AUTHORIZED_SIGNATORIES)"
                                   *ngIf="isSection(SectionType.AUTHORIZED_SIGNATORIES)">
                <mat-expansion-panel-header>
                  <mat-panel-title class="panel-header">
                    {{ getKycSection(SectionType.AUTHORIZED_SIGNATORIES).kycSectionTitle }}
                  </mat-panel-title>
                  <mat-panel-description>
                    <div style="display: flex; justify-content: end; width: 100%;">
                      <mat-icon
                        [ngStyle]="{'color':getSectionColor(SectionType.AUTHORIZED_SIGNATORIES)}"
                        [matTooltip]="getSectionTooltip(SectionType.AUTHORIZED_SIGNATORIES)"
                        matTooltipPosition="left"
                      >
                        {{ getSectionIcon(SectionType.AUTHORIZED_SIGNATORIES) }}
                      </mat-icon>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <!-- HEADER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.AUTHORIZED_SIGNATORIES).kycSectionHeader.headerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- QUESTIONS-->
                <div class="row">
                  <app-kyc-input-section-questions
                    [sectionQuestions]="getKycSection(SectionType.AUTHORIZED_SIGNATORIES).kycSectionHeader.kycQuestions"
                    [disableAll]="isFormDisabled()"
                    (onValueChange)="updateFormQuestion($event)"
                  >
                  </app-kyc-input-section-questions>
                </div>
                <!-- actions start-->
                <div class="row" style="padding: 15px 0;">
                  <div class="col-12">
                    <button mat-raised-button type="button" color="primary"
                            (click)="selectPersonForField(FORM_AUTH_PEOPLE, Level.EXTENDED)"
                            [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                            [disabled]="isFormDisabled()">
                      Add Natural Person
                    </button>
                  </div>
                </div>
                <!-- table start-->
                <table class="table table-striped form-table">
                  <thead>
                  <th class="form-table-header">Authorized Signatories (Natural Persons)</th>
                  <!--                  <th class="form-table-header">Percent (%) Owned</th>-->
                  <th class="form-table-header">Actions</th>
                  </thead>
                  <tbody>
                  <tr *ngFor="let cp of form.get(FORM_AUTH_PEOPLE)?.value; index as i;">
                    <td>
                      <div style="line-height:38px; display: flex;">
                        <mat-icon style="margin-top:6px;"
                                  [matBadge]="getPersonErrorsForSection(cp, SectionType.AUTHORIZED_SIGNATORIES).length > 0 ? getPersonErrorsForSection(cp, SectionType.AUTHORIZED_SIGNATORIES).length: null"
                                  [matBadgeColor]="getPersonErrorsForSection(cp, SectionType.AUTHORIZED_SIGNATORIES).length > 0 ? 'warn':'primary'"
                                  matBadgePosition="before"
                                  [matMenuTriggerFor]="errorMenu"
                        >
                          person
                        </mat-icon>&nbsp;
                        <span>{{ getByIdFromFormCache(cp.id, 'people')?.firstName + ' ' + getByIdFromFormCache(cp.id, 'people')?.lastName }}</span>
                      </div>
                    </td>
                    <!--                    <td>-->
                    <!--                      <input class="form-table-input" matInput type="number"-->
                    <!--                             [(ngModel)]="cp.ownerShip"-->
                    <!--                             [ngModelOptions]="{standalone: true}"-->
                    <!--                             [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"-->
                    <!--                             placeholder="type here..."-->
                    <!--                             [disabled]="isFormDisabled()"-->
                    <!--                             (change)="formDirty = true;"-->
                    <!--                      />-->
                    <!--                    </td>-->
                    <td class="text-center">
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="editPerson(getFormCacheObjById(FORM_CACHE_PEOPLE, cp.id))">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'primary'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Edit Signatory ' + (i + 1)"
                                  matTooltipPosition="left">
                          {{ isFormDisabled() ? 'edit_off' : 'edit' }}
                        </mat-icon>
                      </button>
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="deleteFormArray(FORM_AUTH_PEOPLE, i)">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Remove Signatory ' + (i + 1)"
                                  matTooltipPosition="left">
                          delete
                        </mat-icon>
                      </button>
                    </td>
                    <!--      -->
                    <!-- ERROR CONTEXT MENU-->
                    <!--      -->
                    <mat-menu #errorMenu="matMenu">
                      <div style="padding: 15px;">
                        <div *ngIf="getPersonErrorsForSection(cp,SectionType.AUTHORIZED_SIGNATORIES).length > 0">
                          Errors
                          <ul style="color: red;">
                            <li *ngFor="let error of getPersonErrorsForSection(cp,SectionType.AUTHORIZED_SIGNATORIES)">
                              {{ error.errorMessage }}
                            </li>
                          </ul>
                        </div>
                        <div *ngIf="getPersonErrorsForSection(cp,SectionType.AUTHORIZED_SIGNATORIES).length == 0">
                          No Errors
                        </div>
                      </div>
                    </mat-menu>
                  </tr>
                  <tr *ngIf="isFormCollectionEmpty(FORM_AUTH_PEOPLE)">
                    <td colspan="4">No data in this table.</td>
                  </tr>
                  </tbody>
                </table>


                <!-- FOOTER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.AUTHORIZED_SIGNATORIES).kycSectionFooter.footerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- NEXT BUTTON-->
                <div class="row" style="padding-top: 25px;">
                  <div class="col-6 text-start">
                    <button type="button" mat-raised-button (click)="prevPanel(SectionType.AUTHORIZED_SIGNATORIES)">
                      Previous
                    </button>
                  </div>
                  <div class="col-6 text-end">
                    <button type="button" mat-raised-button color="primary"
                            (click)="nextPanel(SectionType.AUTHORIZED_SIGNATORIES)">
                      {{ isFormDisabled() ? 'Next' : 'Save Draft & Move to Next Section' }}
                    </button>
                  </div>
                </div>

              </mat-expansion-panel>
              <!-- Panel End -->

              <!-- PRIMARY CONTACT -->
              <!-- Panel Start -->
              <mat-expansion-panel [expanded]="step === SectionType.PRIMARY_CONTACT"
                                   (opened)="setStep(SectionType.PRIMARY_CONTACT)"
                                   *ngIf="isSection(SectionType.PRIMARY_CONTACT)">
                <mat-expansion-panel-header>
                  <mat-panel-title class="panel-header">
                    {{ getKycSection(SectionType.PRIMARY_CONTACT).kycSectionTitle }}
                  </mat-panel-title>
                  <mat-panel-description>
                    <div style="display: flex; justify-content: end; width: 100%;">
                      <mat-icon
                        [ngStyle]="{'color':getSectionColor(SectionType.PRIMARY_CONTACT)}"
                        [matTooltip]="getSectionTooltip(SectionType.PRIMARY_CONTACT)"
                        matTooltipPosition="left"
                      >
                        {{ getSectionIcon(SectionType.PRIMARY_CONTACT) }}
                      </mat-icon>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <!-- HEADER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.PRIMARY_CONTACT).kycSectionHeader.headerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- QUESTIONS-->
                <div class="row">
                  <app-kyc-input-section-questions
                    [sectionQuestions]="getKycSection(SectionType.PRIMARY_CONTACT).kycSectionHeader.kycQuestions"
                    [disableAll]="isFormDisabled()"
                    (onValueChange)="updateFormQuestion($event)"
                  >
                  </app-kyc-input-section-questions>
                </div>
                <!-- actions start-->
                <div class="row" style="padding: 15px 0;">
                  <div class="col-12">
                    <button mat-raised-button type="button" color="primary"
                            (click)="addNewObjectToCollection(FORM_PRIMARY_CONTACTS)"
                            [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                            [disabled]="((form.get(FORM_PRIMARY_CONTACTS)?.value).length >= 1) || isFormDisabled()">
                      Add Primary Contact Person
                    </button>
                  </div>
                </div>

                <!-- table start-->
                <table class="table table-striped form-table">
                  <thead>
                  <th class="form-table-header"></th>
                  <th class="form-table-header">First Name</th>
                  <th class="form-table-header">Last Name</th>
                  <th class="form-table-header">Email</th>
                  <th class="form-table-header">Actions</th>
                  </thead>
                  <tbody>
                  <tr *ngFor="let cp of form.get(FORM_PRIMARY_CONTACTS)?.value; index as i;">
                    <td>
                      <mat-icon style="margin-top:6px;"
                                [matBadge]="getPersonErrorsForSection(cp, SectionType.PRIMARY_CONTACT).length > 0 ? getPersonErrorsForSection(cp, SectionType.PRIMARY_CONTACT).length: null"
                                [matBadgeColor]="getPersonErrorsForSection(cp, SectionType.PRIMARY_CONTACT).length > 0 ? 'warn':'primary'"
                                matBadgePosition="before"
                                [matMenuTriggerFor]="errorMenu"
                      >
                        person
                      </mat-icon>
                    </td>
                    <td>
                      <input class="form-table-input" matInput type="text"
                             [(ngModel)]="cp.firstName"
                             [ngModelOptions]="{standalone: true}"
                             [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                             placeholder="type here..."
                             [disabled]="isFormDisabled()"
                             (change)="formDirty = true;"
                      />
                    </td>
                    <td>
                      <input class="form-table-input" matInput type="text"
                             [(ngModel)]="cp.lastName"
                             [ngModelOptions]="{standalone: true}"
                             [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                             placeholder="type here..."
                             [disabled]="isFormDisabled()"
                             (change)="formDirty = true;"
                      />
                    </td>
                    <td>
                      <input class="form-table-input" matInput type="text"
                             [(ngModel)]="cp.email"
                             [ngModelOptions]="{standalone: true}"
                             [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                             placeholder="type here..."
                             [disabled]="isFormDisabled()"
                             (change)="formDirty = true;"
                      />
                    </td>
                    <td class="text-center">
                      <button mat-icon-button type="button" [disabled]="isFormDisabled()"
                              (click)="deleteFormArray(FORM_PRIMARY_CONTACTS, i)">
                        <mat-icon [color]="isFormDisabled() ? undefined : 'warn'"
                                  [ngStyle]="{'cursor': isFormDisabled() ? 'not-allowed': ''}"
                                  [matTooltip]="isFormDisabled() ? '' : 'Remove Contact ' + (i + 1)"
                                  matTooltipPosition="left">
                          delete
                        </mat-icon>
                      </button>
                    </td>
                    <!--      -->
                    <!-- ERROR CONTEXT MENU-->
                    <!--      -->
                    <mat-menu #errorMenu="matMenu">
                      <div style="padding: 15px;">
                        <div *ngIf="getPersonErrorsForSection(cp,SectionType.PRIMARY_CONTACT).length > 0">
                          Errors
                          <ul style="color: red;">
                            <li *ngFor="let error of getPersonErrorsForSection(cp,SectionType.PRIMARY_CONTACT)">
                              {{ error.errorMessage }}
                            </li>
                          </ul>
                        </div>
                        <div *ngIf="getPersonErrorsForSection(cp,SectionType.PRIMARY_CONTACT).length == 0">
                          No Errors
                        </div>
                      </div>
                    </mat-menu>
                  </tr>
                  <tr
                    *ngIf="isFormCollectionEmpty(FORM_PRIMARY_CONTACTS)">
                    <td colspan="5">No data in this table.</td>
                  </tr>
                  </tbody>
                </table>

                <!-- FOOTER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.PRIMARY_CONTACT).kycSectionFooter.footerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- NEXT BUTTON-->
                <div class="row" style="padding-top: 25px;">
                  <div class="col-6 text-start">
                    <button type="button" mat-raised-button (click)="prevPanel(SectionType.PRIMARY_CONTACT)">
                      Previous
                    </button>
                  </div>
                  <div class="col-6 text-end">
                    <button type="button" mat-raised-button color="primary"
                            (click)="nextPanel(SectionType.PRIMARY_CONTACT)">
                      {{ isFormDisabled() ? 'Next' : 'Save Draft & Move to Next Section' }}
                    </button>
                  </div>
                </div>

              </mat-expansion-panel>
              <!-- Panel End -->

              <!-- CERTIFICATION -->
              <!-- Panel Start -->
              <mat-expansion-panel [expanded]="step === SectionType.CERTIFICATION"
                                   (opened)="setStep(SectionType.CERTIFICATION)"
                                   *ngIf="isSection(SectionType.CERTIFICATION)">
                <mat-expansion-panel-header>
                  <mat-panel-title class="panel-header">
                    {{ getKycSection(SectionType.CERTIFICATION).kycSectionTitle }}
                  </mat-panel-title>
                  <mat-panel-description>
                    <div style="display: flex; justify-content: end; width: 100%;">
                      <mat-icon
                        [ngStyle]="{'color':getSectionColor(SectionType.CERTIFICATION)}"
                        [matTooltip]="getSectionTooltip(SectionType.CERTIFICATION)"
                        matTooltipPosition="left"
                      >
                        {{ getSectionIcon(SectionType.CERTIFICATION) }}
                      </mat-icon>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <!-- HEADER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.CERTIFICATION).kycSectionHeader.headerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- QUESTIONS-->
                <div class="row">
                  <app-kyc-input-section-questions
                    [sectionQuestions]="getKycSection(SectionType.CERTIFICATION).kycSectionHeader.kycQuestions"
                    [disableAll]="isFormDisabled()"
                    (onValueChange)="updateFormQuestion($event)"
                  >
                  </app-kyc-input-section-questions>
                </div>

                <!-- FOOTER TEXTS-->
                <div class="row">
                  <app-kyc-input-section-text
                    [sectionTexts]="getKycSection(SectionType.CERTIFICATION).kycSectionFooter.footerTexts">
                  </app-kyc-input-section-text>
                </div>

                <!-- SAVE BUTTON -->
                <div class="row">
                  <div class="col-12 text-start">
                    <button type="button" mat-raised-button
                            (click)="prevPanel(SectionType.CERTIFICATION)">Previous
                    </button>
                  </div>
                </div>
              </mat-expansion-panel>
              <!-- Panel End -->

            </mat-accordion>
          </div>
          <!-- SAVE BUTTON -->
          <div class="row" style="padding-bottom: 150px;">
            <div class="col-12 text-end">
              <button type="button" mat-raised-button
                      [ngStyle]="{'background-color': canSubmit()? '#ffa100': '', 'cursor': canSubmit()? '': 'not-allowed'}"
                      [disabled]="!canSubmit()"
                      (click)="submitKyc()">
                SUBMIT
              </button>&nbsp;
              <button type="submit" mat-raised-button color="primary" [disabled]="!canSave()">SAVE</button>
            </div>
          </div>
          <!--          {{ form.getRawValue() | json }}<br/><br/><br/><br/>-->
        </form>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>



