import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import * as Sentry from "@sentry/angular-ivy";
import {Router} from "@angular/router";
import {BrowserModule, Title} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
//libs
import {ToastrModule} from "ngx-toastr";
import {StoreModule} from "@ngrx/store";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {environment} from "../environments/environment";
import {routerReducer, StoreRouterConnectingModule} from "@ngrx/router-store";
import {EffectsModule} from "@ngrx/effects";
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";
import {PdfJsViewerModule} from "ng2-pdfjs-viewer";
//modules
import {AppRoutingModule} from './app-routing.module';
import {NgMaterialModule, ReduxModule, SharedModule} from './modules';
//components
import {AppComponent} from './app.component';
import {
  AttestationModalComponent, ContactUsComponent,
  FloaterComponent,
  GlassCardComponent,
  NavigationComponent, NotesComponent,
  PageNotFoundComponent,
  SpinnerComponent,
  TermsAndConditionsModalComponent, ThirdPartyDeterminationComponent,
  ViewerComponent
} from './components';
import {
  AuthBaseComponent,
  AuthLoginComponent,
  AuthRegisterComponent,
  AuthResetComponent
} from './components/auth';
//services
import {
  AdminService,
  AppConfigService,
  AuthService,
  LookupService,
  NotesService,
  UuidService,
} from './services';
import {DealService} from "./modules/deals/services";
import {MandateService} from "./modules/mandates/services";
import {BusinessUnitService} from "./modules/business-units/services";
//other
import {TestPageComponent} from './pages';
import {RondeivuInterceptor} from "./interceptors";
import {RondeivuHttpClient, RondeivuHttpHandler} from "./handlers";
import { ErrorComponent } from './components/error/error.component';
import { AddNewEmployeeComponent } from './dialogs/add-new-employee/add-new-employee.component';
import { AddAuthorizedSignatoryComponent } from './dialogs/thirdparty/add-authorized-signatory/add-authorized-signatory.component';
import { AddVotingRightsOwnerComponent } from './dialogs/thirdparty/add-voting-rights-owner/add-voting-rights-owner.component';


@NgModule({
  declarations: [
    AppComponent,
    TestPageComponent,
    GlassCardComponent,
    PageNotFoundComponent,
    NavigationComponent,
    SpinnerComponent,
    FloaterComponent,
    AuthBaseComponent,
    AuthLoginComponent,
    AuthRegisterComponent,
    AuthResetComponent,
    ViewerComponent,
    AttestationModalComponent,
    TermsAndConditionsModalComponent,
    ContactUsComponent,
    NotesComponent,
    ThirdPartyDeterminationComponent,
    ErrorComponent,
    AddNewEmployeeComponent,
    AddAuthorizedSignatoryComponent,
    AddVotingRightsOwnerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 3000,
      preventDuplicates: true,
    }), // ToastrModule added
    AppRoutingModule,
    NgxExtendedPdfViewerModule,
    PdfJsViewerModule,
    NgMaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    // The root store
    StoreModule.forRoot({
      router: routerReducer
    }),
    EffectsModule.forRoot([]),
    // The feature stores
    ReduxModule,
    // Connects RouterModule with StoreModule, uses MinimalRouterStateSerializer by default
    StoreRouterConnectingModule.forRoot(),
    // Instrumentation must be imported after importing StoreModule (config is optional)
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    })
  ],
  providers: [
    Title,
    AppConfigService,
    AdminService,
    AuthService,
    UuidService,
    NotesService,
    MandateService,
    DealService,
    BusinessUnitService,
    RondeivuHttpHandler,
    RondeivuHttpClient,
    {provide: HTTP_INTERCEPTORS, useClass: RondeivuInterceptor, multi: true},
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    }
  ],
  exports: [
    SpinnerComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {
  }
}
