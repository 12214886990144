
<div class="rm-header">
    <span style="font-size: x-large;">Add Name of Authorized Signatory</span>
</div>
<form [formGroup]="fg">
<mat-dialog-content>
    <div class="col-12 pt-2 pb-2">
        <p>
            Are any of the authorized signatories of the entity a Director or Officer of a company whose shares are traded on the stock exchange or on the OTC market?
        </p>
    </div>
    <div class="row pt-2">
        <!--      row-->
        <div class="col-12">
            <mat-form-field class="form-field" appearance="outline">
                <mat-label>Name of Authorized Signatory</mat-label>
                <input id="fn" matInput type="text" required formControlName="authorizedSignatory">
                <mat-error *ngIf="fg.get('authorizedSignatory')?.hasError('required')">Field is required</mat-error>
            </mat-form-field>
        </div>
        <!--      row-->
        <div class="col-8">
            <mat-form-field class="form-field" appearance="outline">
                <mat-label>Name of Company</mat-label>
                <input id="ln" matInput type="text" required formControlName="companyName">
                <mat-error *ngIf="fg.get('companyName')?.hasError('required')">Field is required</mat-error>
            </mat-form-field>
        </div>
        <!--      row-->
        <div class="col-4">
            <mat-form-field class="form-field" appearance="outline">
                <mat-label>Percentage Ownership</mat-label>
                <input id="email" matInput type="number" required
                    placeholder="Percent ownership (eg. 15%)" formControlName="ownership">
                <mat-error *ngIf="fg.get('ownership')?.hasError('required')">Field is required</mat-error>
                <mat-error *ngIf="fg.get('ownership')?.hasError('min')">Should be a minimum of 0</mat-error>
                <mat-error *ngIf="fg.get('ownership')?.hasError('max')">Should be a maximum of 100</mat-error>
                <!-- <mat-hint align="end">The an invitation email will go to this email address</mat-hint> -->
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="space-between">
    <button mat-raised-button type="button" mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" type="submit" [mat-dialog-close]="fg.value"
            [disabled]="fg.invalid">Submit</button>
</mat-dialog-actions>
</form>
  