import {createReducer, on} from '@ngrx/store';
import * as LookupsActions from './lookups.actions';
import {initialLookupsState} from "./lookups.init";
// the key feature
export const configFeatureKey = 'lookups';

// the update reducer
export const lookupsReducer = createReducer(
  initialLookupsState,
  on(LookupsActions.updateLookups, (state, {lookups}) => ({
        businessUnitProfileTypes: lookups.businessUnitProfileTypes,
        businessUnitWorkflowStatus: lookups.businessUnitWorkflowStatus,
        appUserWorkflowStatus: lookups.appUserWorkflowStatus,
        areasOfFocus: lookups.areasOfFocus,
        assetTypes: lookups.assetTypes,
        countries: lookups.countries,
        countryCodes: lookups.countryCodes,
        dealNdaRequirementTypes: lookups.dealNdaRequirementTypes,
        dealTypes: lookups.dealTypes,
        dealWorkflowStatus: lookups.dealWorkflowStatus,
        investorDescriptionTypes: lookups.investorDescriptionTypes,
        investorTypes: lookups.investorTypes,
        investorMandateWorkflowStatus: lookups.investorMandateWorkflowStatus,
        issuerTypes: lookups.issuerTypes,
        provinceTypes: lookups.provinceTypes,
        stateTypes: lookups.stateTypes,
        timeLineTypes: lookups.timeLineTypes,
        dealCloseTypes: lookups.dealCloseTypes,
        secDealTypes: lookups.secDealTypes,
        currencyTypes: lookups.currencyTypes,
        dealInterestTypes: lookups.dealInterestTypes,
        userIntakeSourceTypes: lookups.userIntakeSourceTypes,
        issuerEngagementAgreementStatusTypes: lookups.engagementAgreementStatusTypes,
        issuerNDAStatusTypes: lookups.issuerNDAStatusTypes,
        kycStatusTypes: lookups.kycStatusTypes,
        dealNotesAccessTypes: lookups.dealNotesAccessTypes,
        teamUserRoleTypes: lookups.teamUserRoleTypes,
        memberInviteStatus: lookups.memberInviteStatus,
        dealAlertTypes: lookups.dealAlertTypes,
        viewAccessControls: lookups.viewAccessControls,
        dataroomTypes: lookups.dataroomTypes,
        indicationOfInterestRestrictionTypes: lookups.indicationOnInterestRestrictionTypes,
        investorDealWorkflowStatus: lookups.investorDealWorkflowStatus,
        noteTypes: lookups.noteTypes,
        syndicationRestrictionTypes: lookups.syndicationRestrictionTypes,
        syndicationStatus: lookups.syndicationStatus,
        subscriptionRestrictionTypes: lookups.subscriptionRestrictionTypes,
        subscriptionStatus: lookups.subscriptionStatus,
        rondeivuEmployeeRoleTypes: lookups.rondeivuEmployeeRoleTypes,
        ndaTypes: lookups.ndaTypes,
        envelopeStatus: lookups.envelopeStatus,
        kycInvestorTypes: lookups.kycInvestorTypes,
        kycIssuerTypes: lookups.kycIssuerTypes,
      anchorInterestTypes: lookups.anchorInterestTypes,
      issuerLegalEntityType: lookups.issuerLegalEntityType,
      taxIdType: lookups.taxIdType,
      legalEntityProofofDocumentType: lookups.legalEntityProofofDocumentType,
      entityTabDocumentationType: lookups.entityTabDocumentationType,
      personGovernmentIssuedIdType: lookups.personGovernmentIssuedIdType,
      personProofofIdentificationIdType: lookups.personProofofIdentificationIdType,
      personProofofAddressDocumentType: lookups.personProofofAddressDocumentType,
      entityTaxDocumentationType: lookups.entityTaxDocumentationType,
      personProofofIdentificationType: lookups.personProofofIdentificationType,
      kycPersonRondeivuStatus: lookups.kycPersonRondeivuStatus,
      kycLegalEntityRondeivuStatus: lookups.kycLegalEntityRondeivuStatus,
      kycType: lookups.kycType,
      kycRondeivuStatus: lookups.kycRondeivuStatus,
      kycPersonType: lookups.kycPersonType,
      kycLegalEntityType: lookups.kycLegalEntityType,
      businessUnitEntityType: lookups.businessUnitEntityType,
      subDocStatus: lookups.subDocStatus
      }
    )
  ),
  on(LookupsActions.updateBusinessUnitProfileTypes, (state, {items}) => ({...state, businessUnitProfileTypes: items})),
  on(LookupsActions.updateBusinessUnitWorkflowStatus, (state, {items}) => ({
    ...state,
    businessUnitWorkflowStatus: items
  })),
  on(LookupsActions.updateAreasOfFocus, (state, {items}) => ({...state, areasOfFocus: items})),
  on(LookupsActions.updateAssetTypes, (state, {items}) => ({...state, assetTypes: items})),
  on(LookupsActions.updateCountries, (state, {items}) => ({...state, countries: items})),
  on(LookupsActions.updateCountryCodes, (state, {items}) => ({...state, countryCodes: items})),
  on(LookupsActions.updateDealNdaRequirementTypes, (state, {items}) => ({...state, dealNdaRequirementTypes: items})),
  on(LookupsActions.updateDealTypes, (state, {items}) => ({...state, dealTypes: items})),
  on(LookupsActions.updateDealWorkflowStatus, (state, {items}) => ({...state, dealWorkflowStatus: items})),
  on(LookupsActions.updateInvestorDescriptionTypes, (state, {items}) => ({...state, investorDescriptionTypes: items})),
  on(LookupsActions.updateInvestorTypes, (state, {items}) => ({...state, investorTypes: items})),
  on(LookupsActions.updateProvinceTypes, (state, {items}) => ({...state, provinceTypes: items})),
  on(LookupsActions.updateStateTypes, (state, {items}) => ({...state, stateTypes: items})),
  on(LookupsActions.updateTimelineTypes, (state, {items}) => ({...state, timeLineTypes: items})),
  on(LookupsActions.updateInvestorMandateWorkflowStatus, (state, {items}) => ({
    ...state,
    investorMandateWorkflowStatus: items
  })),
  on(LookupsActions.updateIssuerTypes, (state, {items}) => ({...state, issuerTypes: items})),
  on(LookupsActions.updateDealCloseTypes, (state, {items}) => ({...state, dealCloseTypes: items})),
  on(LookupsActions.updateSecDealTypes, (state, {items}) => ({...state, secDealTypes: items})),
  on(LookupsActions.updateCurrencyTypes, (state, {items}) => ({...state, currencyTypes: items})),
  on(LookupsActions.updateDealInterestTypes, (state, {items}) => ({...state, dealInterestTypes: items})),
  on(LookupsActions.updateUserIntakeSourceTypes, (state, {items}) => ({...state, userIntakeSourceTypes: items})),
  on(LookupsActions.updateIssuerEngagementAgreementStatusTypes, (state, {items}) => ({
    ...state,
    issuerEngagementAgreementStatusTypes: items
  })),
  on(LookupsActions.updateIssuerNDAStatusTypes, (state, {items}) => ({...state, issuerNDAStatusTypes: items})),
  on(LookupsActions.updateKYCStatusTypes, (state, {items}) => ({...state, kycStatusTypes: items})),
  on(LookupsActions.updateDealNotesAccessTypes, (state, {items}) => ({...state, dealNotesAccessTypes: items})),
  on(LookupsActions.updateTeamUserRoleTypes, (state, {items}) => ({...state, teamUserRoleTypes: items})),
  on(LookupsActions.updateMemberInviteStatus, (state, {items}) => ({...state, memberInviteStatus: items})),
  on(LookupsActions.updateDealAlertTypes, (state, {items}) => ({...state, dealAlertTypes: items})),
  on(LookupsActions.updateViewAccessControls, (state, {items}) => ({...state, viewAccessControls: items})),
  on(LookupsActions.updateDataroomTypes, (state, {items}) => ({...state, dataroomTypes: items})),
  on(LookupsActions.updateIndicationOfInterestRestrictionTypes, (state, {items}) => ({
    ...state,
    indicationOfInterestRestrictionTypes: items
  })),
  on(LookupsActions.updateInvestorDealWorkflowStatus, (state, {items}) => ({
    ...state,
    investorDealWorkflowStatus: items
  })),
  on(LookupsActions.updateNoteTypes, (state, {items}) => ({...state, noteTypes: items})),
  on(LookupsActions.updateSyndicationRestrictionTypes, (state, {items}) => ({
    ...state,
    syndicationRestrictionTypes: items
  })),
  on(LookupsActions.updateSyndicationStatus, (state, {items}) => ({...state, syndicationStatus: items})),
  on(LookupsActions.updateSubscriptionRestrictionTypes, (state, {items}) => ({
    ...state,
    subscriptionRestrictionTypes: items
  })),
  on(LookupsActions.updateSubscriptionStatus, (state, {items}) => ({...state, subscriptionStatus: items})),
  on(LookupsActions.updateRondeivuEmployeeRoleTypes, (state, {items}) => ({
    ...state,
    rondeivuEmployeeRoleTypes: items
  })),

  on(LookupsActions.updateNdaTypes, (state, {items}) => ({...state, ndaTypes: items})),
  on(LookupsActions.updateEnvelopeStatus, (state, {items}) => ({...state, envelopeStatus: items})),
  on(LookupsActions.updateKycInvestorTypes, (state, {items}) => ({...state, kycInvestorTypes: items})),
  on(LookupsActions.updateKycIssuerTypes, (state, {items}) => ({...state, kycIssuerTypes: items})),
  on(LookupsActions.updateAnchorInterestTypes, (state, {items}) => ({...state, anchorInterestTypes: items})),

  on(LookupsActions.updateIssuerLegalEntityType, (state, {items}) => ({...state, issuerLegalEntityType: items})),
  on(LookupsActions.updateTaxIdType, (state, {items}) => ({...state, taxIdType: items})),
  on(LookupsActions.updateLegalEntityProofofDocumentType, (state, {items}) => ({
    ...state,
    legalEntityProofofDocumentType: items
  })),
  on(LookupsActions.updateEntityTabDocumentationType, (state, {items}) => ({
    ...state,
    entityTabDocumentationType: items
  })),
  on(LookupsActions.updatePersonGovernmentIssuedIdType, (state, {items}) => ({
    ...state,
    personGovernmentIssuedIdType: items
  })),
  on(LookupsActions.updatePersonProofofIdentificationIdType, (state, {items}) => ({
    ...state,
    personProofofIdentificationIdType: items
  })),
  on(LookupsActions.updatePersonProofofAddressDocumentType, (state, {items}) => ({
    ...state,
    personProofofAddressDocumentType: items
  })),
  on(LookupsActions.updateEntityTaxDocumentationType, (state, {items}) => ({
    ...state,
    entityTaxDocumentationType: items
  })),
  on(LookupsActions.updatePersonProofofIdentificationType, (state, {items}) => ({
    ...state,
    personProofofIdentificationType: items
  })),
  on(LookupsActions.updateKycPersonRondeivuStatus, (state, {items}) => ({...state, kycPersonRondeivuStatus: items})),
  on(LookupsActions.updateKycLegalEntityRondeivuStatus, (state, {items}) => ({
    ...state,
    kycLegalEntityRondeivuStatus: items
  })),
  on(LookupsActions.updateKycType, (state, {items}) => ({...state, kycType: items})),
  on(LookupsActions.updateKycRondeivuStatus, (state, {items}) => ({...state, kycRondeivuStatus: items})),
  on(LookupsActions.updateKycPersonType, (state, {items}) => ({...state, kycPersonType: items})),
  on(LookupsActions.updateKycLegalEntityType, (state, {items}) => ({...state, kycLegalEntityType: items})),
  on(LookupsActions.updateBusinessUnitEntityType, (state, {items}) => ({...state, businessUnitEntityType: items})),
  on(LookupsActions.updateSubDocStatus, (state, {items}) => ({...state, subDocStatus: items}))
);

