<div class="custom-scroll">
  <div class="container-fluid">
    <div class="row justify-content-center header" style="padding: 15px 35px;">
      <div class="col-3">
        <div style="display: flex; line-height: 36px;">
          <mat-icon color="primary" class="rm-header-icon">task</mat-icon>&nbsp;
          <span class="rm-title rm-title-3">Deals</span>
        </div>
      </div>
      <div class="col-9 text-end">
        <div>
          <!--view cards-->
          <div style="display: flex; justify-content: end;">
            <div class="d-none d-md-flex" style="margin-right: 25px;" *ngIf="pageView == DealView.CARD">
              <mat-form-field style="height: 24px;">
                <mat-select [(ngModel)]="filterView"
                            [ngModelOptions]="{standalone: true}"
                            (selectionChange)="filterCards()"
                            [multiple]="true"
                >
                  <mat-option *ngFor="let opt of filterList" [value]="opt.key">
                    {{ opt.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div style="margin-right: 25px; min-width:80px;">
              <button mat-icon-button
                      (click)="pageView = DealView.CARD;"
                      [disabled]="pageView==DealView.CARD"
                      title="View as Cards"
              >
                <mat-icon>view_module</mat-icon>
              </button>
              <button mat-icon-button
                      (click)="pageView = DealView.TABLE;"
                      [disabled]="pageView==DealView.TABLE"
                      title="View as Table"
              >
                <mat-icon>list</mat-icon>
              </button>
            </div>
            <!--            full buttons-->
            <div class="d-none d-md-flex">
              <button
                *ngIf="config.selected_business_unit?.businessUnitType == 'ISSUER'"
                mat-raised-button
                color="primary"
                (click)="openDealModal()"
                [disabled]="!util.hasPermission(util.Permission.CAN_ADD_DEALS)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_DEALS) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_ADD_DEALS) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
              >
                <mat-icon>library_add</mat-icon>&nbsp;Add New Deal
              </button>
              <!--mandate modal-->
              <button
                *ngIf="config.selected_business_unit?.businessUnitType == 'INVESTOR'"
                mat-raised-button
                color="primary"
                (click)="openMandateModal()"
                [disabled]="!util.hasPermission(util.Permission.CAN_ADD_MANDATES)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_MANDATES) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_ADD_MANDATES) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
              >
                <mat-icon>library_add</mat-icon>&nbsp;Add New Mandate
              </button>
            </div>
            <!--            mobile buttons-->
            <div class="d-flex d-md-none">
              <button
                *ngIf="config.selected_business_unit?.businessUnitType == 'ISSUER'"
                mat-icon-button
                color="primary"
                (click)="openDealModal()"
                [disabled]="!util.hasPermission(util.Permission.CAN_ADD_DEALS)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_DEALS) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_ADD_DEALS) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
              >
                <mat-icon>library_add</mat-icon>
              </button>
              <!--mandate modal-->
              <button
                *ngIf="config.selected_business_unit?.businessUnitType == 'INVESTOR'"
                mat-icon-button
                color="primary"
                (click)="openMandateModal()"
                [disabled]="!util.hasPermission(util.Permission.CAN_ADD_MANDATES)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_MANDATES) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_ADD_MANDATES) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
              >
                <mat-icon>library_add</mat-icon>
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!--    card view-->
    <div class="row justify-content-center" *ngIf="pageView == DealView.CARD">
      <div class="row" *ngIf="filteredDeals.length > 0">
        <div id="deal-body">
          <!--      deal cards-->
          <div class="row" *ngIf="filteredDeals.length > 0">
            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4 deal-row"
                 *ngFor="let d of filteredDeals;index as i;">
              <app-deal-card class="deal-card" [deal]="filteredDeals[i]"
                             [canView]="util.hasPermission(util.Permission.CAN_ACCESS_DEALS) || false"></app-deal-card>
            </div>
          </div>
          <!--deal cards end-->
        </div>
      </div>
      <!--  no live deals start-->
      <div class="row justify-content-center d-none d-md-flex" *ngIf="filteredDeals.length == 0"
           style="margin: 50px;">
        <img alt="No Live Deals" src="assets/images/city.png"
             style="height: calc(100vh - 64px); width: 50vw; object-fit: contain;opacity: 0.2;"/>
      </div>
    </div>

    <!--    table start-->
    <div class="row justify-content-center d-none d-md-flex"*ngIf="pageView == DealView.TABLE"
         style="padding-top: 25px;">
      <div class="col-12 col-md-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <table mat-table [dataSource]="dataSource"
                     [multiTemplateDataRows]="true" matSort (matSortChange)="sortData($event)">
                <!-- Search Header -->
                <ng-container matColumnDef="table-name">
                  <th colspan="5" mat-header-cell *matHeaderCellDef>
                    <span style="font-size: large;">Deals</span>
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="4" mat-header-cell *matHeaderCellDef>
                    <form autocomplete="off">
                      <mat-form-field style="width: 100%;">
                        <mat-label>Search</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input/>
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form>
                  </th>
                </ng-container>
                <!-- ID Column -->
                <ng-container matColumnDef="Id">
                  <th mat-header-cell *matHeaderCellDef> ID</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ element.id }}
                  </td>
                </ng-container>
                <!-- project Column -->
                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="projectName"> Project</th>
                  <td mat-cell *matCellDef="let element" style="max-width: 200px;">
                    <app-user-card [firstName]="element.projectName"
                                   [displayName]="element.assetName"
                                   [borderColor]="'transparent'"
                                   [sideColor]="'#345'"
                                   [image]="element.dealImageUrl"
                                   [imageShape]="'square'"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- Business Unit Column -->
                <ng-container matColumnDef="businessUnit">
                  <!--                <th mat-header-cell *matHeaderCellDef mat-sort-header="displayName" sortActionDescription="Sort by Business Unit"> Business Unit</th>-->
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="displayName"
                      sortActionDescription="Sort by Business Unit"> Business Unit
                  </th>
                  <td mat-cell *matCellDef="let element" style="padding: 5px;">
                    <app-user-card [firstName]="element.businessUnit?.displayName"
                                   [displayName]="element.businessUnit?.slug"
                                   [borderColor]="'transparent'"
                                   [sideColor]="'#ff0000'"
                                   [image]="element.businessUnit?.['imageUrl']"
                                   (click)="util.isType('admin')? util.viewBusinessUnit(element.businessUnit?.id, 'deals') : null"
                                   [ngStyle]="{'cursor': util.isType('admin')? 'pointer':''}"
                                   [highlightOnHover]="util.isType('admin')"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- Deal Size Column -->
                <ng-container matColumnDef="dealSize">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="fundraiseSize"
                      sortActionDescription="Sort by Deal Size"> Deal Size
                  </th>
                  <td mat-cell *matCellDef="let element"> {{ '$' + element.fundraiseSize + 'M USD' }}
                  </td>
                </ng-container>
                <!-- SEC Type Column -->
                <ng-container matColumnDef="secType">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="secDealType"> Offering Type</th>
                  <td mat-cell *matCellDef="let element"> {{ element.secDealType }}
                  </td>
                </ng-container>
                <!-- status Column -->
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="dealWorkflow"
                      sortActionDescription="Sort by Status"> Status
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span [appStatus]="element.dealWorkflow">{{ element.dealWorkflow }}</span>
                  </td>
                </ng-container>
                <!-- CITY Column -->
                <ng-container matColumnDef="City">
                  <th mat-header-cell *matHeaderCellDef> City</th>
                  <td mat-cell *matCellDef="let element"> {{ element.city }}
                  </td>
                </ng-container>
                <!-- DESCRIPTION Column -->
                <ng-container matColumnDef="desc">
                  <th mat-header-cell *matHeaderCellDef> Post NDA Description</th>
                  <td mat-cell *matCellDef="let element" style="word-wrap: break-word;max-width: 150px;">
                    <span style="font-weight: lighter;" [innerHTML]="element.description"></span>
                  </td>
                </ng-container>
                <!-- Close Date Column -->
                <ng-container matColumnDef="closeDate">
                  <th mat-header-cell *matHeaderCellDef
                      mat-sort-header="closeDate" sortActionDescription="Sort by Close Date"> Close Date
                  </th>
                  <td mat-cell *matCellDef="let element"> {{ element.closeDate | date }}</td>
                </ng-container>
                <!-- created Column -->
                <ng-container matColumnDef="created">
                  <th mat-header-cell *matHeaderCellDef
                      mat-sort-header="created" sortActionDescription="Sort by Created"> Created
                  </th>
                  <td mat-cell *matCellDef="let element"> 
                    <div class="date-stacked">
                      <span>{{ element.created | date : 'mediumDate' }}</span>
                      <span>{{ element.created | date : 'shortTime' }}</span>
                    </div>
                  </td>
                </ng-container>
                <!-- CLOSE Column -->
                <ng-container matColumnDef="closeType">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="dealCloseType"> Close Type</th>
                  <td mat-cell *matCellDef="let element"> {{ element.dealCloseType }}</td>
                </ng-container>

                <!-- EDIT Column -->
                <ng-container matColumnDef="edit">
                  <th mat-header-cell *matHeaderCellDef> Actions</th>
                  <td mat-cell *matCellDef="let element">
                    <div style="display: flex;">
                      <button mat-icon-button
                              *ngIf="util.isType('admin')"
                              (click)="viewHistory(element)"
                              [disabled]="!util.hasPermission(util.Permission.CAN_ACCESS_AUDITS)"
                              [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ACCESS_AUDITS) ? util.NoAccessCursor: ''}"
                              [matTooltip]="!util.hasPermission(util.Permission.CAN_ACCESS_AUDITS) ? util.NoAccessMsg : 'View History'"
                              matTooltipPosition="left"
                      >
                        <mat-icon>history</mat-icon>
                      </button>
                      <button mat-icon-button (click)="viewDeal(element)" matTooltip="View Deal"
                              matTooltipPosition="right">
                        <mat-icon>east</mat-icon>
                      </button>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="['table-name','search-header'];"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;">
                </tr>
                <!--          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>-->
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter.</td>
                </tr>
              </table>
            </div>
          </div>
          <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[100, 200, 500]"
                         [pageSize]="100"
                         aria-label="Select page of deals"></mat-paginator>
        </div>
      </div>
    </div>

    <!--    list start-->
    <!-- <div class="row justify-content-center d-flex d-md-none" *ngIf="pageView == DealView.TABLE"
         style="padding-bottom: 150px; padding-top: 15px;">
      <div class="col-12">
        <ul style="list-style-type: none; padding: 25px 0 0 0;">
          <li *ngFor="let element of dataSource.data"
              style="margin-bottom: 5px; display: flex; justify-content: space-between;">
            <div>
              <app-user-card [firstName]="element.businessUnit?.displayName"
                             [displayName]="element.projectName"
                             [borderColor]="'transparent'"
                             [sideColor]="'#345'"
                             [image]="element.businessUnit?.['imageUrl']"
                             [imageShape]="'square'"
              ></app-user-card>
            </div>
            <div style="display: flex;">
              <span style="line-height: 42px; font-size: xx-small;"
                    [appStatus]="element.dealWorkflow">{{ element.dealWorkflow.substring(0, 4) }}</span>
              <button mat-icon-button (click)="viewHistory(element)" matTooltip="View History"
                      matTooltipPosition="left">
                <mat-icon>history</mat-icon>
              </button>
              <button mat-icon-button (click)="viewDeal(element)" matTooltip="View Deal"
                      matTooltipPosition="left">
                <mat-icon>east</mat-icon>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div> -->
    
  </div>
</div>
