import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotesModalComponent } from '../notes-modal/notes-modal.component';
import { ISyndicationItem } from '../../models/syndication';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LookupService } from 'src/app/services';
import { UtilService } from 'src/app/modules/shared/services';
import { SyndicationService } from '../../services';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-allocation-edit-modal',
  templateUrl: './allocation-edit-modal.component.html',
  styleUrls: ['./allocation-edit-modal.component.scss']
})
export class AllocationEditModalComponent implements OnInit {

  statusFg: FormGroup;
  fg: FormGroup;

  constructor(public dialogRef: MatDialogRef<AllocationEditModalComponent>,
              public lookupSerivice: LookupService,
              public toastr: ToastrService,
              private syndicationService: SyndicationService,
              public util: UtilService,
                @Inject(MAT_DIALOG_DATA) public data: ISyndicationItem) {

    this.statusFg = new FormGroup({
      status: new FormControl({ value: '', disabled: !util.isAdmin() }, Validators.required)
    });

    this.statusFg.patchValue(data);

    this.fg = new FormGroup({
      indicationAmount: new FormControl({ value: '', disabled: util.isIssuer() }, Validators.required),
      allocationAmount: new FormControl({ value: '', disabled: util.isInvestor() }, Validators.required),
      restrictionType: new FormControl({ value: '', disabled: !util.isAdmin() }, Validators.required),
      notes: new FormControl({ value: '', disabled: !util.isAdmin() }, Validators.required),

      sendInvestorNotification: new FormControl({ value: false, disabled: util.isInvestor() })
    });

    this.fg.patchValue(data);
  }

  ngOnInit(): void {
  }

  saveStatus() {
    console.log("Save Status", this.fg.value);

    if (this.statusFg.valid == false) {
      this.toastr.error("Status not valid");
      return;
    }
   
    this.syndicationService.changeSyndicationStatus(this.data.id, this.statusFg.value).subscribe({
      next: (data: any) => {
        this.toastr.success("Status successfully updated");
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      }
    })
  }

  save() {
    console.log("Save Draft", this.fg.value);

    if (this.fg.valid == false) {
      this.toastr.error("Form not valid");
      return;
    }

    let payload = {
      id: this.data.id,
      ...this.fg.value
    }

    this.dialogRef.close(payload);
  }
}
