import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {toggleLoading} from "../../modules/redux";
import {IThirdPartyDetermination} from "../../models";
import {Store} from "@ngrx/store";
import {AppConfigService, LookupService, UuidService} from "../../services";
import {BusinessUnitService} from "../../modules/business-units/services";
import {ToastrService} from "ngx-toastr";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import { UtilService} from "../../modules/shared/services/util/util.service";
import { MatDialog } from '@angular/material/dialog';
import { AddAuthorizedSignatoryComponent } from 'src/app/dialogs/thirdparty/add-authorized-signatory/add-authorized-signatory.component';
import { AddVotingRightsOwnerComponent } from 'src/app/dialogs/thirdparty/add-voting-rights-owner/add-voting-rights-owner.component';
import { ThirdPartyAuthorizedSignatory, ThirdPartyOwnerOfVotingRights } from 'src/app/models/third-party-determination';

@Component({
  selector: 'app-third-party-determination',
  templateUrl: './third-party-determination.component.html',
  styleUrls: ['./third-party-determination.component.scss']
})
export class ThirdPartyDeterminationComponent {
  fg: FormGroup;
  fgThirdPartyInformation: FormGroup;

  constructor(private store: Store,
              public lookupService: LookupService,
              private businessUnitService: BusinessUnitService,
              private toastr: ToastrService,
              public util: UtilService,
              public dialog: MatDialog,
              // private uuid: UuidService,
  ) {

    this.fg = new FormGroup({
      id: new FormControl(),
      businessUnitId: new FormControl(),

      isRegistered: new FormControl(),
      isOwnerofVotingRights: new FormControl(),
      thirdPartyOwnerOfVotingRights: new FormArray([]),

      isPubliclyTraded: new FormControl(),
      thirdPartyAuthorizedSignatory: new FormArray([]),

      isThisPartyRegisteredOnBehalf: new FormControl(),
      isAthirdPartyDirecting: new FormControl(),
      willAthirdPartyHasAfinancialInterest: new FormControl(),
    });
  

    this.fgThirdPartyInformation = new FormGroup({
      personFirstName: new FormControl('', [Validators.required]),
      personMiddleName: new FormControl(''),
      personLastName: new FormControl('', [Validators.required]),
      personSuffix: new FormControl(''),

      businessStreetAddress: new FormControl('', [Validators.required]),
      businessCity: new FormControl('', [Validators.required]),
      businessCountryId: new FormControl('', [Validators.required]),
      businessStateOrProvince: new FormControl(''),
      businessZipPostalCode: new FormControl('', [Validators.required]),
      businessLegalDomicile: new FormControl('', [Validators.required]),
    });

    this.getThirdParty();
  }


  //#region Regulatory People 
  launchAddVotingRightsOwnerModal() {
    this.dialog.open(AddVotingRightsOwnerComponent, {
      width: '750px',
    })
    .afterClosed().subscribe(data => {
      console.log("data", data);
      if (!!data) {
        this.createItemForVotingRightOwners(data);
      }
    })
  }

  createItemForVotingRightOwners(data: ThirdPartyOwnerOfVotingRights) {
    var item = new FormGroup({
      ownerOfVotingRights: new FormControl(data.ownerOfVotingRights),
      publiclyTradedCompany: new FormControl(data.publiclyTradedCompany),
      ownership: new FormControl(data.ownership)
    });

    this.getOwnersOfVotingRights.push(item);

    // console.log(this.fg.value, this.getOwnersOfVotingRights.length);
    // console.log(item.value)
  }

  get getOwnersOfVotingRights() : FormArray {
    return this.fg.get('thirdPartyOwnerOfVotingRights') as FormArray;
  }

  deleteFromOwnersOfVotingRights(i: number) {
    this.getOwnersOfVotingRights.removeAt(i);
  }
  //#endregion

  //#region Regulatory Entities
  launchAuthorizedSignatoryModal() {
    this.dialog.open(AddAuthorizedSignatoryComponent, {
      width: '750px',
    })
    .afterClosed().subscribe(data => {
      // console.log("data", data);
      if (!!data) {
        this.createItemForAuthorizedSignatories(data);
      }
    })
  }


  createItemForAuthorizedSignatories(data: ThirdPartyAuthorizedSignatory) {
    var newItem = new FormGroup({
      // id: new FormControl(),
      authorizedSignatory: new FormControl(data.authorizedSignatory),
      companyName: new FormControl(data.companyName),
      ownership: new FormControl(data.ownership)
    });

    this.getThirdPartyAuthorizedSignatories.push(newItem);
  }

  get getThirdPartyAuthorizedSignatories() : FormArray {
    return this.fg.get('thirdPartyAuthorizedSignatory') as FormArray;
  }

  deleteFromAuthorizedSignatories(i: number) {
    this.getThirdPartyAuthorizedSignatories.removeAt(i);
  }
  //#endregion

  isOneOrMoreSelected() {
    const Q1 = this.fg.get('isThisPartyRegisteredOnBehalf')?.value || false;
    const Q2 = this.fg.get('isAthirdPartyDirecting')?.value || false;
    const Q3 = this.fg.get('willAthirdPartyHasAfinancialInterest')?.value || false;
    return Q1 || Q2 || Q3;
  }

  showOwnersOfVotingRightsTable(): boolean {
    return this.fg.get('isOwnerofVotingRights')?.value || false;
  }

  showAuthorizedSignatoriesTable(): boolean {
    return this.fg.get('isPubliclyTraded')?.value || false;
  }

  getThirdParty() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.businessUnitService.getUserBusinessUnitThirdParty().subscribe({
      next: (res: IThirdPartyDetermination) => {
        // assign the top form control
        console.log("results", res);
        this.fg.patchValue(res);

        (res.thirdPartyOwnerOfVotingRights || []).forEach((x: ThirdPartyOwnerOfVotingRights) => this.createItemForVotingRightOwners(x));
        (res.thirdPartyAuthorizedSignatory || []).forEach((x: ThirdPartyAuthorizedSignatory) => this.createItemForAuthorizedSignatories(x));

        // patch values for the third party info
        this.fgThirdPartyInformation.patchValue(res);

        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Third Party Determination Loaded!", $localize`:@@companyName:Rondeivu`);
      }, error: error => {
        this.toastr.error("Unable to get third party determination!", $localize`:@@companyName:Rondeivu`);
        this.store.dispatch(toggleLoading({loading: false}));
      }
    });
  }

  submit() {
    console.log(this.fg.value)

    if (this.showOwnersOfVotingRightsTable() && this.getOwnersOfVotingRights.length == 0) {
      // make sure the table has rows
      this.toastr.error("There has to be at leaset one 'owner of voting right' added");
      return;
    }

    if (this.showAuthorizedSignatoriesTable() && this.getThirdPartyAuthorizedSignatories.length == 0) {
      // make sure the table has rows
      this.toastr.error("There has to be at least one 'owner authorized signatory' added");
      return;
    }


    if (this.isOneOrMoreSelected() && this.fgThirdPartyInformation.valid == false) {
      // make sure the third party information is filled in
      this.toastr.error("Please enter all required 'Third Party Information' fields");
      this.fgThirdPartyInformation.markAllAsTouched();
      return;
    }


    let payload = Object.assign(this.fg.value, this.fgThirdPartyInformation.value);

    console.log("Form Submitted", payload);

    this.store.dispatch(toggleLoading({loading: true}));
    this.businessUnitService.editUserBusinessUnitThirdParty(payload).subscribe({
      next: res => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Third Party Determination Saved!", $localize`:@@companyName:Rondeivu`);
      }, error: error => {
        this.toastr.error("Unable to update third party determination!", $localize`:@@companyName:Rondeivu`);
        this.store.dispatch(toggleLoading({loading: false}));
      }
    });
  }
}
