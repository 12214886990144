<div style="overflow-y: auto; height: calc(100vh - 242px);">
  <div class="container-fluid">
    <div style="overflow-x: hidden;">
      <div class="row justify-content-center" style="padding-top: 25px;">
        <div class="col-11">
          <span class="rm-title rm-title-3">Employee Account</span><br/>
        </div>
      </div>
      <br/>
      <!--    WELL START-->
      <div class="row justify-content-center">
        <div class="col-11">
          <app-user-card [firstName]="selectedEmployee?.user?.firstName"
                         [lastName]="selectedEmployee?.user?.lastName"
                         [displayName]="selectedEmployee?.user?.email"
                         [borderColor]="'transparent'"
                         [sideColor]="'#002150'"
                         [image]="selectedEmployee?.user?.imageUrl"
                         [size]="userCardSize.LG"
          ></app-user-card>
          <form [formGroup]="userForm"
                (ngSubmit)="save()">
            <!--          row start-->
            <div class="row" style="padding-top:50px;">
              <div class="col-12">
                <mat-label style="font-size: medium; margin-top: 25px;">Employee Title</mat-label>
                <mat-form-field class="form-field" appearance="outline">
                  <input class="form-input" matInput formControlName="title"
                         placeholder="e.g. Operations Manager"/>
                </mat-form-field>
              </div>
              <div class="col-12" style="padding-top: 36px;">
                <span>You have the option to temporarily suspend an employee from your company without permanently deleting their profile.
                  While suspended, the employee will not have access to your company or any associated deals.
                  <b>Upon choosing to re-activate them, all previous permissions and settings will be reinstated.</b>
                </span><br/>
                <mat-checkbox style="padding: 25px;" color="primary" formControlName="isEmployeeActive">Employee is
                  Active
                </mat-checkbox>
              </div>
            </div>
            <!--          row start-->
            <div class="row" style="padding-top: 20px;">
              <div class="col-12 text-end">
                <!--                <button type="button" mat-raised-button (click)="back()">BACK</button> &nbsp;-->
                <button type="submit" mat-raised-button color="primary">SAVE</button>
              </div>
            </div>
            <!--          rows end-->
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

