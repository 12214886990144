import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {LoadingService, LookupService} from "../../../../../services";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
  Config,
  getConfig, getLookups,
  getSelectedBusinessUnit,
  initialConfigState,
  initialLookupsState,
  Lookups,
  toggleLoading
} from "../../../../redux";
import {AdminService} from "../../../services";
import {IRondeivuEmployee} from "../../../models";
import {Observable, Subscription} from "rxjs";
import {IBusinessUnit} from "../../../../business-units/models";
import {HttpErrorResponse} from "@angular/common/http";
import {FormControl, FormGroup} from "@angular/forms";
import {IUserResponse} from "../../../../users/models/i-users";

@Component({
  selector: 'app-rondeivu-employee-modal',
  templateUrl: './rondeivu-employee-modal.component.html',
  styleUrls: ['./rondeivu-employee-modal.component.scss']
})
export class RondeivuEmployeeModalComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  // lookup$: Subscription = new Subscription();
  // selectedBusinessUnit$: Subscription = new Subscription();
  bu: IBusinessUnit = {} as unknown as IBusinessUnit;
  config: Config = initialConfigState;
  // lookups: Lookups = initialLookupsState;
  // selectedUser: IUserResponse = {} as unknown as IUserResponse;
  userList: IUserResponse[] = [];

  newEmployeeModalState : boolean;
  potentialEmployees$: Observable<IUserResponse[]>;
  selectedUser?: IUserResponse = undefined;

  userForm = new FormGroup({
    id: new FormControl(),
    user: new FormControl(),
    title: new FormControl(),
    calendarLink: new FormControl(),
    countryCode: new FormControl(),
    mobile: new FormControl(),
    publicNotes: new FormControl()
  });

  constructor(private loader: LoadingService, 
              private store: Store,
              private adminService: AdminService, 
              public lookupService: LookupService,
              private toastr: ToastrService,
              public dialogRef: MatDialogRef<RondeivuEmployeeModalComponent>, @Inject(MAT_DIALOG_DATA) public data: IRondeivuEmployee) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    // if there is data injected, then this is not a "New Employee" Modal
    this.newEmployeeModalState = !data; 

    this.potentialEmployees$ = this.adminService.getPotentialRondeivuEmployees();

    // this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
    //   this.bu = Object.assign({}, bu);
    //   if (!!this.bu && !!this.bu.businessUnitId) {
    //     this.subscribeToDatasource();
    //   }
    // });
  }

  ngOnInit(): void {
    if (!!this.data) {
      this.userForm.patchValue(this.data);
    }
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    // this.lookup$.unsubscribe();
    // this.selectedBusinessUnit$.unsubscribe();
  }

  onSelectionChange(event: any) {
    this.selectedUser = event.value;
  }

  subscribeToDatasource() {
    console.log("Fetching Users...");
    this.store.dispatch(toggleLoading({loading: true}));
    //fetch the potential employees
    this.adminService.getPotentialRondeivuEmployees().subscribe({
      next: (users: IUserResponse[]) => {
        this.userList = Object.assign([], users);
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: err => {
        this.toastr.error("Unable to get users!", $localize`:@@companyName:Rondeivu`);
        this.store.dispatch(toggleLoading({loading: false}));
      }
    });
  }

  /**
   * The add new employee action
   */
  addNewEmployee() {

    if (!this.selectedUser) {
      this.toastr.error("No user selected from list")
      return;
    }
    const payload = {
      appUserId: this.selectedUser.id
    };

    this.store.dispatch(toggleLoading({loading: true}));
    this.adminService.addRondeivuEmployees(payload).subscribe({
      next: (user: any) => {
        this.dialogRef.close(user);
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Added Rondeivu Employee!", $localize`:@@companyName:Rondeivu`);
      }, error: (err: HttpErrorResponse) => {
        if (!!err && !!err.error.errors && err.error.errors.length > 0) {
          this.toastr.error(err.error.errors[0], $localize`:@@companyName:Rondeivu`);
        } else {
          this.toastr.error("Unable to add employee!", $localize`:@@companyName:Rondeivu`);
        }
        this.store.dispatch(toggleLoading({loading: false}));
      }
    });
  }

  /**
   * The form submit used when editing an employee
   */
  submitEdit() {
    this.dialogRef.close(this.userForm.value);
  }

}
