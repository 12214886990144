<div class="rm-header">
  <span>Add Entity</span>
</div>
<form [formGroup]="entityForm" (ngSubmit)="submit()">
  <mat-dialog-content>
    <div class="row justify-content-center" style="padding-bottom: 20px; padding-top: 25px;">
      <div class="col-11 ">
        <div class="row" style="padding-bottom: 25px;">
          <app-user-card [firstName]="entityForm.get('businessUnit')?.value['displayName']"
                         [displayName]="entityForm.get('businessUnit')?.value['profile']"
                         [borderColor]="'transparent'"
                         [sideColor]="'#ff0000'"
                         [image]="entityForm.get('businessUnit')?.value['imageUrl']"
                         [size]="UserCardSize.LG"
          ></app-user-card>
        </div>
        <!--          row start-->
        <div class="row">
          <div class="col-12">
            <mat-form-field class="form-field" appearance="outline">
              <mat-label style="font-size: medium;">Entity Name</mat-label>
              <input class="form-input" matInput formControlName="entityName" [required]="true"
                     placeholder="Exact Legal Name"/>
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field class="form-field" appearance="outline">
              <mat-label style="font-size: medium;">Entity Type</mat-label>
              <mat-select id="entityType" formControlName="entityType" [required]="true">
                <mat-option *ngFor="let c of getTypeLookup()" [value]="c.key">
                  {{ c.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!--          row start-->
        <div class="row">
          <div class="col-12">
            <mat-form-field class="form-field" appearance="outline">
              <mat-label style="font-size: medium;">Domicile</mat-label>
              <mat-select id="country" formControlName="domicile" [required]="true">
                <mat-option *ngFor="let c of lookups.countries" [value]="c.key">
                  {{ c.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

    </div>

  </mat-dialog-content>
  <mat-dialog-actions class="row justify-content-center">
    <div class="col-6 text-start">
      <button mat-raised-button type="button" (click)="dialogRef.close()">Cancel</button>
    </div>
    <div class="col-6 text-end">
      <button mat-raised-button color="primary" type="submit" [disabled]="entityForm.invalid">SUBMIT</button>
    </div>
  </mat-dialog-actions>
</form>

