<div class="rm-header">
  <span>Legal Entity Information</span>
</div>

<form [formGroup]="form" (submit)="submit()">
  <mat-dialog-content style="display: flex; flex-direction: column; gap: 25px; padding-top: 15px;">

    <!-- Errors Section  -->
    <div class="row" *ngIf="!!data?.business?.errors && data.business.errors.length > 0">
      <h3>Legal Entity Errors</h3>
      <div class="col-12">
        <ul style="color: red;">
          <li *ngFor="let e of data?.business?.errors; index as i">
            {{ e.kycSectionType + ' - ' + e.errorMessage }}
          </li>
        </ul>
      </div>
    </div>

    <!-- Admin Only Section  -->
    <div class="row" *ngIf="util.isType('admin')">
      <h3>Admin Only Section</h3>
      <div class="col-12">
        <div class="col-6">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>KYC Legal Entity Type</mat-label>
            <mat-select id="kycLegalEntityType" formControlName="kycLegalEntityType"
                        [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}">
              <mat-option *ngFor="let s of lookups.kycLegalEntityType" [value]="s.key">
                {{ s['detail'] }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- <div class="col-6" style="display: flex;">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>KYC Legal Entity Status</mat-label>
            <mat-select id="kycLegalEntityStatus" formControlName="rondeivuStatus"
                        [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                        (selectionChange)="changeStatus()">
              <mat-option *ngFor="let s of lookups.kycLegalEntityRondeivuStatus" [value]="s.key">
                {{ s['value'] }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
      </div>
    </div>

    <!-- Basic Information  -->
    <div class="row">
      <h3>Basic Information</h3>
      <div class="col-12">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Legal Name</mat-label>
          <input matInput id="bn" type="text"
                 formControlName="legalName"
                 [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                 placeholder="Legal Name" required/>
        </mat-form-field>
      </div>
      <div class="col-6" *ngIf="isBusiness(Level.LONG) || isBusiness(Level.FULL) || isBusiness(Level.EXTENDED)">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Street Address</mat-label>
          <input matInput id="st" type="text"
                 formControlName="streetAddress"
                 [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                 placeholder="Street Address" required/>
        </mat-form-field>
      </div>
      <div class="col-6" *ngIf="isBusiness(Level.LONG) || isBusiness(Level.FULL) || isBusiness(Level.EXTENDED)">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>City</mat-label>
          <input matInput id="city" type="text"
                 formControlName="city"
                 [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                 placeholder="City" required/>
        </mat-form-field>
      </div>
      <div class="col-4" *ngIf="isBusiness(Level.LONG) || isBusiness(Level.FULL) || isBusiness(Level.EXTENDED)">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Country</mat-label>
          <mat-select id="country" formControlName="countryId"
                      [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                      required>
            <mat-option *ngFor="let c of lookups.countries" [value]="c.key">
              {{ c.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-4" *ngIf="isBusiness(Level.LONG) || isBusiness(Level.FULL) || isBusiness(Level.EXTENDED)">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>State/Province</mat-label>
          <mat-select id="state" formControlName="stateOrProvince"
                      [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                      required>
            <div *ngIf="form.get('countryId')?.value == 'US'">
              <mat-option *ngFor="let s of lookups.stateTypes" [value]="s.key">
                {{ s.value }}
              </mat-option>
            </div>
            <div *ngIf="form.get('countryId')?.value == 'CA'">
              <mat-option *ngFor="let p of lookups.provinceTypes" [value]="p.key">
                {{ p.value }}
              </mat-option>
            </div>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-4" *ngIf="isBusiness(Level.LONG) || isBusiness(Level.FULL) || isBusiness(Level.EXTENDED)">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Zip/Postal Code</mat-label>
          <input matInput id="zip" type="text"
                 formControlName="zipPostalCode"
                 [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                 placeholder="Zip/Postal Code" required/>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Legal Domicile</mat-label>
          <mat-select id="domicile"
                      formControlName="legalDomicile"
                      [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                      required>
            <mat-option *ngFor="let c of lookups.countries" [value]="c.key">
              {{ c.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6" *ngIf="isBusiness(Level.FULL) || isBusiness(Level.EXTENDED)">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Tax Id Type</mat-label>
          <mat-select id="txtype"
                      formControlName="taxIdType"
                      [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                      required>
            <mat-option *ngFor="let c of lookups.taxIdType" [value]="c.key">
              {{ c.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6" *ngIf="isBusiness(Level.FULL) || isBusiness(Level.EXTENDED)">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Tax ID Number</mat-label>
          <input matInput id="txnum" type="text"
                 formControlName="taxIdNumber"
                 [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                 placeholder="Tax ID Number" required/>
        </mat-form-field>
      </div>
    </div>

    <!-- Proof of Business  -->
    <div class="row" 
         *ngIf="isBusiness(Level.LONG) || isBusiness(Level.FULL) || isBusiness(Level.EXTENDED)">

      <h3>Proof of Business</h3>

      <div class="col-12" style="display: flex; flex-direction: row; gap: 25px;">
        <div class="col-6">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Proof of Business Type</mat-label>
            <mat-select id="proofId"
                        formControlName="legalEntityProofofDocumentType"
                        [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
            >
              <mat-option *ngFor="let t of lookups.legalEntityProofofDocumentType" [value]="t.key">
                {{ t.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-4" *ngIf="form.get('legalEntityProofofDocumentType')?.value == 'Other'">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Other Type</mat-label>
            <input matInput id="otherId" type="text"
                   formControlName="legalEntityProofofDocumentOtherName"
                   [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                   required
                   placeholder="What type of proof of business is it?"/>
          </mat-form-field>
        </div>
      </div>



      <!--      -->
      <!-- FILE UPLOAD-->
      <!--      -->
      <div class="col-12">
        <span style="font-size: small; color: grey">Files under 2MB are allowed.</span><br/>
        <input style="padding: 0; height:auto;" type="file" placeholder="Choose File..."
               (change)="addDocuments($event)" #fileUpload
               [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
               [disabled]="data.disabled"
               [multiple]="!data.business"/>
      </div>
      <!-- FILE TABLE-->
      <div class="col-12 pt-4 pb-4">
        <table class="table table-striped form-table">
          <thead>
          <th class="form-table-header">File</th>
          <th class="form-table-header">Uploaded Date</th>
          <th class="form-table-header">Actions</th>
          </thead>
          <tbody>
          <tr *ngFor="let f of form.get('kycLegalEntityProofofDocuments')?.value; index as i;">
            <td style="padding-left: 5px; line-height: 36px;">{{ !!data.business ? f.fileName : f.name }}</td>
            <td style="line-height: 36px;">{{ f.created | date }}</td>
            <td class="text-center">
              <button mat-icon-button type="button" *ngIf="!!data.business && !!f.id"
                      [disabled]="data.disabled"
                      [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                      (click)="downloadDoc(f.id, f.fileName)">
                <mat-icon [color]="data.disabled? undefined : 'primary'">download</mat-icon>
              </button>&nbsp;
              <button mat-icon-button type="button" *ngIf="!!data.business && !!f.id"
                      [disabled]="data.disabled"
                      [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                      (click)="deleteDoc(f.id, i)">
                <mat-icon [color]="data.disabled? undefined : 'warn'">delete</mat-icon>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>


    </div>

    <div class="row" *ngIf="isBusiness(Level.LONG) || isBusiness(Level.FULL) || isBusiness(Level.EXTENDED)">
      
      <!--      <div class="col-4">-->
      <!--        <mat-form-field appearance="outline" class="form-field">-->
      <!--          <input matInput [matDatepicker]="datepicker" formControlName="personProofofIdentificationIdExpiryDate"-->
      <!--                 required/>-->
      <!--          <mat-hint>MM/DD/YYYY</mat-hint>-->
      <!--          <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>-->
      <!--          <mat-datepicker #datepicker></mat-datepicker>-->
      <!--          <mat-label>Proof of Business Expiry</mat-label>-->
      <!--          <mat-error *ngIf="form.invalid">Please select a date.</mat-error>-->
      <!--        </mat-form-field>-->
      <!--      </div>-->
    </div>

  </mat-dialog-content>


  <!--      row-->
  <mat-dialog-actions class="space-between" style="padding-top:25px;">
    <button mat-button type="button" mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="data.disabled"
            [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}">Submit
    </button>
  </mat-dialog-actions>
</form>
