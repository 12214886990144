<div style="height:calc(100vh - 112px); overflow-y: auto;">
  <div class="container-fluid">
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-11">
        <span class="rm-title rm-title-3">Invitations</span><br/>
        <span
          class="rm-title rm-title-6 rm-lighter">This is where you can manage your personal invitations:</span>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <table mat-table [dataSource]="dataSource"
                     [multiTemplateDataRows]="true" matSort (matSortChange)="sortData($event)">
                <!-- Search Header -->
                <ng-container matColumnDef="table-name">
                  <th colspan="3" mat-header-cell *matHeaderCellDef>
                    <div style="display: flex; justify-content: space-between;">
                      <div><span style="font-size: large;">My Invitations</span></div>
                    </div>
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="3" mat-header-cell *matHeaderCellDef>
                    <form>
                      <mat-form-field style="width: 100%;">
                        <mat-label>Search</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input>
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form>
                  </th>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="invite">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="invite"> Invite</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px 15px;">
                    <app-user-card [firstName]="element.businessUnit?.['displayName']"
                                   [borderColor]="'transparent'"
                                   [sideColor]="'#ff0000'"
                                   [image]="element.businessUnit?.['imageUrl']"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="type"> Type</th>
                  <td mat-cell style="min-width: 100px;"
                      *matCellDef="let element">
                    {{ element['type'] }}
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="name"> Project Name</th>
                  <td mat-cell *matCellDef="let element" style="min-width: 200px;">
                    <app-user-card *ngIf="!!element['projectName']"
                                   [firstName]="element['projectName']"
                                   [borderColor]="'transparent'"
                                   [sideColor]="'#002150'"
                                   [imageShape]="'square'"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="created">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="created"> Date</th>
                  <td mat-cell *matCellDef="let element"
                      style="min-width: 200px;"> {{ element["created"] | date : 'medium' }}
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="status"> Status</th>
                  <td mat-cell *matCellDef="let element">
                    <span [appStatus]="element.status">{{ element.status }} </span>
                  </td>
                </ng-container>
                <!-- Actions Column -->
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef class="text-center"> Actions</th>
                  <td mat-cell *matCellDef="let element" class="text-center">
                    <div style="width:100%; display: flex; justify-content: center;">
                      <!-- ACCEPT -->
                      <button color="primary" mat-raised-button matTooltip="Accept Invitation"
                              [matTooltipDisabled]="element.status != 'PENDING'"
                              [disabled]="element.status != 'PENDING'"
                              matTooltipPosition="left"
                              (click)="acceptInvite(element)"
                      >
                        ACCEPT
                      </button>&nbsp;
                      <!-- DECLINE -->
                      <button color="warn" mat-raised-button matTooltip="Decline Invitation"
                              [matTooltipDisabled]="element.status != 'PENDING'"
                              [disabled]="element.status != 'PENDING'"
                              matTooltipPosition="right"
                              (click)="declineInvite(element)"
                      >
                        DECLINE
                      </button>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="['table-name','search-header'];"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"></tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter.</td>
                </tr>
              </table>
            </div>
          </div>
          <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[5, 10, 25, 100]"
                         aria-label="Select page of users"></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
