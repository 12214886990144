import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Config, getConfig, getLookups, initialConfigState, initialLookupsState, Lookups} from "../../../redux";
import {Store} from "@ngrx/store";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { LookupService } from 'src/app/services';

@Component({
  selector: 'app-decline-modal',
  templateUrl: './decline-modal.component.html',
  styleUrls: ['./decline-modal.component.scss']
})
export class DeclineModalComponent implements OnInit, OnDestroy {

  // config$: Subscription = new Subscription();
  // lookup$: Subscription = new Subscription();
  // config: Config = initialConfigState;
  // lookups: Lookups = initialLookupsState;

  constructor(
    public lookupService: LookupService,
    public dialogRef: MatDialogRef<DeclineModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { reason: string }) {

    // this.config$ = this.store.select(getConfig).subscribe(config => {
    //   this.config = config;
    // });
    // this.lookup$ = this.store.select(getLookups).subscribe(lookups => {
    //   this.lookups = lookups;
    // });
  }


  ngOnInit(): void {
  }

  ngOnDestroy() {
    // this.config$.unsubscribe();
    // this.lookup$.unsubscribe();
  }

}
