import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {Subscription} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {
  Config,
  getConfig,
  getLookups,
  getSelectedBusinessUnit,
  initialConfigState,
  initialLookupsState,
  initialThemeState,
  Lookups,
  Theme,
  toggleLoading,
  updateDealId,
  updateEditingBusinessUnit,
  updateSelectedEntity,
  updateSelectedEntityId,
} from "../../../redux";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {IBusinessUnit} from "../../../business-units/models";
import {EntitiesService} from "../../services/entities.service";
import {IBusinessUnitEntity} from "../../models/entity";
import {UtilService} from "../../../shared/services";
import {ModalSize} from "../../../shared/services/util/util.service";
import {EntityModalComponent} from "../entity-modal/entity-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {toggleNavigating} from "../../../redux/stores/config/config.actions";
import { IEntityDTO } from 'src/app/modules/deals/models/syndication';
import { LookupService } from 'src/app/services';
import { DeleteConfirmationModalComponent, PromptModalComponent } from 'src/app/modules/shared/components';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-entity-list',
  templateUrl: './entity-list.component.html',
  styleUrls: ['./entity-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EntityListComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  // lookup$: Subscription = new Subscription();
  // selectedBusinessUnit$: Subscription = new Subscription();

  dataSource: MatTableDataSource<IBusinessUnitEntity> = new MatTableDataSource();

  entityList: any[] = [];
  sortedNda: any[] = [];
  config: Config = initialConfigState;
  // lookups: Lookups = initialLookupsState;
  // theme: Theme = initialThemeState;
  columnsToDisplay = ['business', 'entityName', 'entityType', 'domicile', 'created', 'actions'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: IBusinessUnitEntity | null = {} as IBusinessUnitEntity;
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  constructor(private store: Store,
              private router: Router,
              private entityService: EntitiesService,
              private toastr: ToastrService,
              public lookupService: LookupService,
              private dialog: MatDialog,
              public util: UtilService) {

    this.config$ = this.store.select(getConfig).subscribe((configState: any) => {
      this.config = configState;
    });

    // this.lookup$ = this.store.select(getLookups).subscribe((lookups: any) => {
    //   this.lookups = lookups;
    // });

    // this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
    //   if (!!bu && !!bu.businessUnitId) {
    //     this.subscribeToDatasource();
    //   }
    // });
    this.subscribeToDatasource();
  }

  ngOnInit(): void {
    this.store.dispatch(updateEditingBusinessUnit({businessUnit: {} as IBusinessUnit}));
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    // this.lookup$.unsubscribe();
    // this.selectedBusinessUnit$.unsubscribe();
  }

  subscribeToDatasource() {
    console.log("Fetching Entities...");
    this.store.dispatch(updateDealId({dealId: ''}));
    this.store.dispatch(toggleLoading({loading: true}));
    //fetch the users
    this.entityService.getBusinessUnitEntities().subscribe({
      next: (entities: IBusinessUnitEntity[]) => {
        console.log("entities", entities)
        this.entityList = entities;
        this.dataSource = new MatTableDataSource(this.entityList);
        // this.initDataSource();
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: err => {
        this.toastr.error("Unable to get entities!");
        this.store.dispatch(toggleLoading({loading: false}));
      }
    });
  }

  initDataSource() {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const lc_filter = filter.toLowerCase();
      let DN = !!data.businessUnit.displayName ? data.businessUnit.displayName?.toLowerCase().includes(lc_filter) : false;
      let SL = !!data.businessUnit.slug ? data.businessUnit.slug?.toLowerCase().includes(lc_filter) : false;

      return DN || SL;
    };
    if (!!this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  viewEntity(element: IBusinessUnitEntity) {
    this.store.dispatch(updateSelectedEntityId({entityId: element.id}));
    this.store.dispatch(updateSelectedEntity({entity: element}));
    this.store.dispatch(toggleNavigating({navigating: true}));
    setTimeout(() => {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/entities/' + element.id]).then(() => {
        this.store.dispatch(toggleNavigating({navigating: false}));
      });
    }, 1000);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //sort
  sortData(sort: Sort) {
    const data = this.entityList.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedNda = data;
      return;
    }

    this.sortedNda = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'business':
          return compare(a.businessUnit?.displayName, b.businessUnit?.displayName, isAsc);
        case 'entityName':
          return compare(a.entityName, b.entityName, isAsc);
        case 'entityType':
          return compare(a.entityType, b.entityType, isAsc);
        case 'domicile':
          return compare(a.domicile, b.domicile, isAsc);
        default:
          return 0;
      }
    });

    this.dataSource = new MatTableDataSource(this.sortedNda);
    this.initDataSource();
  }

  addEntity() {
    let dialogRef = this.dialog.open(EntityModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      disableClose: true,
      data: {
        entity: {
          businessUnit: this.config.selected_business_unit
        }
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (!!result) {
        let payload: any = {
          id: '',
          businessUnitId: result.businessUnit.businessUnitId,
          entityName: result.entityName,
          entityType: result.entityType,
          domicile: result.domicile
        }
        console.log("Adding new entity!");
        this.store.dispatch(toggleLoading({loading: true}));
        this.entityService.addBusinessUnitEntity(payload).subscribe({
          next: (res: any) => {
            this.subscribeToDatasource();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("Entity added!", $localize`:@@companyName:Rondeivu`);
          }, error: error => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to add entity!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  deleteEntity(entity: IBusinessUnitEntity) {
    console.log("delete entity", entity);
    this.dialog.open(DeleteConfirmationModalComponent)
    .afterClosed()
    .subscribe({
      next: (confirm: boolean) => {
        if (confirm === true) {
          // delete the entity
          this.entityService.deleteBusinessUnitEntity(entity.id).subscribe({
            next: () => {
              this.toastr.success("Entity successfully deleted.");
              this.subscribeToDatasource();
            },
            error: (err: HttpErrorResponse) => {
              this.toastr.error("Could not delete the entity!");
            }
          })
        }
      }
    });
  }
}


function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
