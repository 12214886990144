import {AfterViewInit, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { UtilService } from 'src/app/modules/shared/services';

@Component({
  selector: 'app-terms-and-conditions-modal',
  templateUrl: './terms-and-conditions-modal.component.html',
  styleUrls: ['./terms-and-conditions-modal.component.scss']
})
export class TermsAndConditionsModalComponent implements AfterViewInit  {

  disableAgreementButton: boolean = true;

  constructor(
    private util: UtilService,
    public dialogRef: MatDialogRef<TermsAndConditionsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {termsAndConditionsRequired:boolean,bodyHtml:string,pdfDownloadLink:string}
  ) {
  }

  @ViewChild('scroll', { static: true }) dialogContent!: ElementRef;

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.dialogContent?.nativeElement) {
        console.log("native", this.dialogContent, this.dialogContent.nativeElement)
        this.dialogContent.nativeElement.scrollTop = 0;
      }
    }, 500);
  }

  onScroll(e: any) {
    if (!!e.target) {
      let target = e.target;
      console.log(target.scrollHeight - target.scrollTop, target.clientHeight);
      if (target.scrollHeight - target.scrollTop - 20 < target.clientHeight) {
        console.log('Scrolled to the bottom!');
        this.disableAgreementButton = false;
      }
    }
  }

  action(data: {termsAndConditionsRequired:boolean,bodyHtml:string,pdfDownloadLink:string}):void{
    this.dialogRef.close(data);
  }

  logOut() {
    this.util.logOut().then(() => {
      this.dialogRef.close();
    })
  }
}
