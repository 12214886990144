<div class="rm-header">
  <span>Edit Subscription Document</span>
</div>

  <mat-dialog-content>

    <!-- Status (if not ADMIN) -->
    <div class="row" *ngIf="!!subDoc && !util.isAdmin()">
      <h2>{{ lookupService.getLookupValueForKey(lookupService.lookups.subDocStatus, subDoc.status) }}</h2>
    </div>

    <!-- ADMIN  -->
    <div class="row" *ngIf="util.isAdmin()">
      <span style="font-weight: bold;">SUB DOC STATUS</span>
      <br/>
      <em>Update the sub doc status below.</em>
      <br/><br/>
      <div class="row">
        <div class="col-8">
          <form [formGroup]="subDocStatusFg" (ngSubmit)="saveStatus()">

            <mat-form-field class="form-field" appearance="outline">
              <mat-label>Status</mat-label>
              <mat-select formControlName="status" formControlName="status"
                          [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBDOCS_STATUS)"
                          [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBDOCS_STATUS) ? util.NoAccessCursor: ''}"
                          [title]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBDOCS_STATUS) ? util.NoAccessMsg : ''"
              >
                <mat-option *ngFor="let s of lookupService.lookups.subDocStatus" [value]="s.key">
                  {{ s.value }}
                </mat-option>
              </mat-select>
              <mat-hint align="end">Changing the status will change the Sub Doc workflow</mat-hint>
            </mat-form-field>
          </form>
        </div>
        <div class="col-4" style="padding-top: 8px;">
          <button mat-raised-button
                  color="primary"
                  type="button"
                  (click)="saveStatus()"
                  [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBDOCS_STATUS)"
                  [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBDOCS_STATUS) ? util.NoAccessCursor: ''}"
                  [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBDOCS_STATUS) ? util.NoAccessMsg : ''"
                  matTooltipPosition="above"
          >
            Save SubDoc Status
          </button>
        </div>
      </div>
      <hr>
    </div>
    <!-- <div class="row" style="padding: 24px 12px;">
      <span style="font-weight: bold;">SIGNATORIES</span><br/>
      <em>The list of signatories below are extracted from the KYC signatories linked to the Investing Entity selected
        above.</em>
    </div> -->
    <!--                        -->
    <!--   SIGNATORIES TABLE START     -->
    <!--                        -->
    <!--                        -->
    <div class="row">
      <table class="user-table"
             mat-table
             [dataSource]="userSource"
             [multiTemplateDataRows]="true"
             matSort
      >
        <ng-container matColumnDef="table-name">
          <th colspan="5" mat-header-cell *matHeaderCellDef>
            <span style="font-size: large;">Subscription Doc Users / Signatories</span>
          </th>
        </ng-container>
        <!-- COLUMN -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> User Access / Signatories</th>
          <td mat-cell *matCellDef="let element">
            {{ element.fullName }}
          </td>
        </ng-container>
        <!-- COLUMN -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email Address</th>
          <td mat-cell *matCellDef="let element">
            {{ element.email }}
          </td>
        </ng-container>
        <!-- COLUMN -->
        <ng-container matColumnDef="accessType">
          <th mat-header-cell *matHeaderCellDef> Access Type</th>
          <td mat-cell *matCellDef="let element">
            {{ element.accessType }}
          </td>
        </ng-container>
        <!-- COLUMN -->
        <ng-container matColumnDef="profile">
          <th mat-header-cell *matHeaderCellDef> Profile</th>
          <td mat-cell *matCellDef="let element">
            {{ element.profile }}
          </td>
        </ng-container>
        <!-- COLUMN -->
        <ng-container matColumnDef="emailStatus">
          <th mat-header-cell *matHeaderCellDef> Email Status</th>
          <td mat-cell *matCellDef="let element">
            {{ element.emailStatus }}
          </td>
        </ng-container>
        <!-- COLUMN -->
        <ng-container matColumnDef="sigStatus">
          <th mat-header-cell *matHeaderCellDef> Signature Status</th>
          <td mat-cell *matCellDef="let element">
            {{ element.signatureStatus }}
          </td>
        </ng-container>

        <!-- Row Definitions -->
        <tr mat-header-row *matHeaderRowDef="['table-name'];"></tr>
        <tr mat-header-row *matHeaderRowDef="userColumns; sticky:true;"></tr>
        <tr mat-row class="user-row" *matRowDef="let element; columns: userColumns;"></tr>
      </table>
    </div>
    <!--                        -->
    <!--                        -->
    <!--  USER TABLE END        -->
    <!--                        -->
    <!--                        -->

    <div class="row" style="padding: 24px 12px;">
      <span style="font-weight: bold;">FILE UPLOADS</span><br/>
      <em>Below are any supporting documents for thie subdoc, i.e. side letters, wet signature pdfs, etc.</em>
    </div>
    <!--                        -->
    <!--   FILE TABLE START     -->
    <!--                        -->
    <!--                        -->
    <div class="row pb-3">
      <table class="file-table"
             mat-table
             [dataSource]="fileSource"
             [multiTemplateDataRows]="true"
             matSort
      >
        <ng-container matColumnDef="table-name">
          <th colspan="6" mat-header-cell *matHeaderCellDef>
            <div style="display: flex; justify-content: space-between;">
              <span style="font-size: large; margin-top:8px;">Sub Doc Supporting File Uploads</span>
              <div style="display: flex;">
                <div>
                  <button mat-raised-button color="primary"
                          type="button"
                          (click)="downloadAll()"
                          [disabled]="!util.hasPermission(util.Permission.CAN_DOWNLOAD_ALL_DEAL_SUBDOCS_UPLOAD)"
                          [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_DOWNLOAD_ALL_DEAL_SUBDOCS_UPLOAD) ? util.NoAccessCursor: ''}"
                          [title]="!util.hasPermission(util.Permission.CAN_DOWNLOAD_ALL_DEAL_SUBDOCS_UPLOAD) ? util.NoAccessMsg : ''"
                  >
                    DOWNLOAD ALL (ZIP)
                  </button>&nbsp;
                </div>
                <div style="margin-top: -15px;">
                  <span style="font-size: small; color: grey">Files under 10MB are allowed.</span><br/>
                  <input style="padding: 0; height:auto;" type="file" placeholder="Choose File..."
                         (change)="addDocuments($event)" #fileUpload
                         [disabled]="!util.hasPermission(util.Permission.CAN_ADD_DEAL_SUBDOCS_UPLOAD)"
                         [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_DEAL_SUBDOCS_UPLOAD) ? util.NoAccessCursor: ''}"
                         [title]="!util.hasPermission(util.Permission.CAN_ADD_DEAL_SUBDOCS_UPLOAD) ? util.NoAccessMsg : ''"
                  />
                </div>
              </div>
            </div>
          </th>
        </ng-container>
        <!-- COLUMN -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Filename</th>
          <td mat-cell *matCellDef="let element">
            {{ element.filename }}
          </td>
        </ng-container>
        <!-- COLUMN -->
        <ng-container matColumnDef="size">
          <th mat-header-cell *matHeaderCellDef> Size</th>
          <td mat-cell *matCellDef="let element">
            {{ element.fileSize }}
          </td>
        </ng-container>
        <!-- COLUMN -->
        <ng-container matColumnDef="uploadedBy">
          <th mat-header-cell *matHeaderCellDef> Uploaded By</th>
          <td mat-cell *matCellDef="let element" style="padding: 5px;">
            <app-user-card [firstName]="element.uploadedBy.firstName"
                           [lastName]="element.uploadedBy.lastName"
                           [image]="element.uploadedBy.imageUrl"
                           [borderColor]="'transparent'"
                           [sideColor]="'#004fe1'"
            ></app-user-card>
          </td>
        </ng-container>
        <!-- COLUMN -->
        <ng-container matColumnDef="uploadedOn">
          <th mat-header-cell *matHeaderCellDef> Uploaded On</th>
          <td mat-cell *matCellDef="let element">
            {{ element.created | date }}
          </td>
        </ng-container>
        <!-- COLUMN -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Actions</th>
          <td mat-cell *matCellDef="let element">
            <div style="display: flex;">
              <button mat-icon-button
                      (click)="downloadSubdoc(element)"
                      [disabled]="!util.hasPermission(util.Permission.CAN_DOWNLOAD_DEAL_SUBDOCS_UPLOAD)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_DOWNLOAD_DEAL_SUBDOCS_UPLOAD) ? util.NoAccessCursor: ''}"
                      [title]="!util.hasPermission(util.Permission.CAN_DOWNLOAD_DEAL_SUBDOCS_UPLOAD) ? util.NoAccessMsg : 'Download'"
              >
                <mat-icon>download</mat-icon>
              </button> &nbsp;
              <button mat-icon-button color="warn"
                      (click)="deleteSubdocFile(element)"
                      [disabled]="!util.hasPermission(util.Permission.CAN_DELETE_DEAL_SUBDOCS_UPLOAD)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_DELETE_DEAL_SUBDOCS_UPLOAD) ? util.NoAccessCursor: ''}"
                      [title]="!util.hasPermission(util.Permission.CAN_DELETE_DEAL_SUBDOCS_UPLOAD) ? util.NoAccessMsg : 'Delete'"
                      *ngIf="util.isAdmin()">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <!-- Row Definitions -->
        <tr mat-header-row *matHeaderRowDef="['table-name'];"></tr>
        <tr mat-header-row *matHeaderRowDef="fileColumns; sticky:true;"></tr>
        <tr mat-row class="user-row" *matRowDef="let element; columns: fileColumns;"></tr>
      </table>

    </div>
    <!--                        -->
    <!--                        -->
    <!--  USER TABLE END        -->
    <!--                        -->
    <!--                        -->


  </mat-dialog-content>

  <mat-dialog-actions style="display: flex; justify-content: end;">
    <button mat-raised-button type="button" [mat-dialog-close]="isModified">Close</button>
  </mat-dialog-actions>
