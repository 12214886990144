import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {RondeivuHttpClient} from "../../handlers";
import {Observable} from "rxjs";
import { apiVersion } from 'src/app/models/iApiVersion';

@Injectable({
  providedIn: 'root'
})
export class ApiConfigService {
  private readonly BASE_URL = environment.rondeivu_api;
  private readonly FRAGMENT = '/apiconfig';
  private readonly VERSION_FRAGMENT = '/get-version';

  constructor(private http: RondeivuHttpClient) {
  }

  /**
   * Fetch the api version
   */
  getVersion(): Observable<apiVersion> {
    return this.http.get<apiVersion>(this.BASE_URL + this.FRAGMENT + this.VERSION_FRAGMENT);
  }
}
