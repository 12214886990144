import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IApprovalRequest, ApprovalType, IApproval} from "../../models";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {Subscription} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {
  Config,
  getConfig,
  getLookups,
  getSelectedBusinessUnit,
  initialConfigState,
  initialLookupsState,
  Lookups, toggleLoading, updateDealId, updateEditingBusinessUnit
} from "../../../redux";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {IBusinessUnit} from "../../../business-units/models";
import {ApprovalsService} from "../../services/approvals/approvals.service";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {UtilService} from "../../../shared/services";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-investor-approvals',
  templateUrl: './investor-approvals.component.html',
  styleUrls: ['./investor-approvals.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class InvestorApprovalsComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();

  ApprovalType = ApprovalType;
  approvalType = this.ApprovalType.INVESTOR
  dataSource: MatTableDataSource<IApproval> = new MatTableDataSource();
  approvalsList: any[] = [];

  columnsToDisplay = ['Company', 'User', 'Identifiers', 'Created', 'Status'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  mandateColumns = ['firmAUM', 'programAUM', 'timeline', 'created', 'view', 'actions'];
  expandedElement: IApproval | null = {} as IApproval;

  lookups: Lookups = initialLookupsState;
  config: Config = initialConfigState;
  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  constructor(
    private approvalsService: ApprovalsService,
    private router: Router,
    private store: Store,
    private toastr: ToastrService,
    public util: UtilService
  ) {
    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });

    this.lookup$ = this.store.select(getLookups).subscribe(lookups => {
      this.lookups = lookups;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.selectedBusinessUnit = Object.assign({}, bu);
      if (!!this.selectedBusinessUnit && !!this.selectedBusinessUnit.businessUnitId) {
        this.subscribeToDatasource();
      }
    });
  }

  ngOnInit(): void {
    this.store.dispatch(updateEditingBusinessUnit({businessUnit: {} as IBusinessUnit}));
    this.expandedElement = {} as IApproval;
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
  }

  subscribeToDatasource() {
    console.log("Fetching Investor Approvals...");
    this.store.dispatch(updateDealId({dealId: ''}));
    this.store.dispatch(toggleLoading({loading: true}));
    this.approvalsService.getApprovals(this.approvalType.toString()).subscribe({
      next: (approvals: any) => {
        this.approvalsList = approvals;
        this.dataSource = new MatTableDataSource(this.approvalsList);
        this.initDataSource();
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: (err: HttpErrorResponse) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get approvals!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  initDataSource() {
    this.dataSource.filterPredicate = (data: IApproval, filter: string) => {
      const lc_filter = filter.toLowerCase();
      let DN = !!data.businessUnit.displayName ? data.businessUnit.displayName?.toLowerCase().includes(lc_filter) : false;
      let SL = !!data.businessUnit.slug ? data.businessUnit.slug?.toLowerCase().includes(lc_filter) : false;

      return DN || SL;
    };
    if (!!this.paginator) {
      this.paginator.pageSize = 100;
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * Approve a business unit
   * @param b business unit
   */
  approveBusinessUnit(b: any) {
    this.sendApproval("Business Unit Approved!", {id: b.id, type: "businessunit", status: "APPROVED"});
  }

  /**
   * Decline a business unit
   * @param b business unit
   */
  declineBusinessUnit(b: any) {
    this.sendApproval("Business Unit Declined!", {id: b.id, type: "businessunit", status: "DECLINED"});
  }

  /**
   * Approve a mandate
   * @param m mandate
   */
  approveMandate(m: any) {
    this.sendApproval("Mandate Approved!", {id: m.id, type: "mandate", status: "APPROVED"});
  }

  /**
   * Decline a mandate
   * @param m mandate
   */
  declineMandate(m: any) {
    this.sendApproval("Mandate Declined!", {id: m.id, type: "mandate", status: "DECLINED"});
  }

  private sendApproval(msg: string, payload: IApprovalRequest) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.approvalsService.addApproval(payload).subscribe({
      next: (res: { status: string }) => {
        this.setObjectStatus(payload, res);
        this.dataSource = new MatTableDataSource(this.approvalsList);
        this.initDataSource();
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success(msg, $localize`:@@companyName:Rondeivu`);
      }, error: err => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to send approval!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  setObjectStatus(payload: { id: string, type: string, status: string }, res: { status: string }) {
    if (payload.type == 'businessunit') {
      for (let i = 0; i <= this.approvalsList.length - 1; i++) {
        if (this.approvalsList[i].businessUnit.id == payload.id) {
          this.approvalsList[i].businessUnit.status = res.status;
          break;
        }
      }
    } else if (payload.type == 'deal') {
      for (let i = 0; i <= this.approvalsList.length - 1; i++) {
        if (!!this.approvalsList[i].deals && this.approvalsList[i].deals.length > 0) {
          let deals = this.approvalsList[i].deals;
          for (let x = 0; x <= deals.length - 1; x++) {
            if (deals[x].id == payload.id) {
              deals[x].dealWorkflow = res.status;
            }
          }
        }
      }
    } else if (payload.type == 'mandate') {
      for (let i = 0; i <= this.approvalsList.length - 1; i++) {
        if (!!this.approvalsList[i].mandates && this.approvalsList[i].mandates.length > 0) {
          let mandates = this.approvalsList[i].mandates;
          for (let x = 0; x <= mandates.length - 1; x++) {
            if (mandates[x].id == payload.id) {
              mandates[x].status = res.status;
            }
          }
        }
      }
    }
  }

  /**
   * Get the timeline text given the key
   * @param typeId
   */
  getTimelineText(typeId: string) {
    let res = '-';
    if (!!this.lookups && !!this.lookups.timeLineTypes) {
      for (let i = 0; i <= this.lookups.timeLineTypes.length - 1; i++) {
        if (this.lookups.timeLineTypes[i].key === typeId) {
          res = this.lookups.timeLineTypes[i].value;
          break;
        }
      }
    }
    return res;
  }

  navToMandate(id: string) {
    this.store.dispatch(toggleLoading({loading: true}));
    setTimeout(() => {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/mandates/' + id]).then(() => {
        this.store.dispatch(toggleLoading({loading: false}));
      });
    }, 1000);
  }

  canExpand(element: any): boolean {
    return !!element && ((!!element.deals && element.deals.length > 0) || (!!element.mandates && element.mandates.length > 0));
  }

  expandRow(element: any) {
    if (this.canExpand(element)) {
      this.expandedElement = this.expandedElement === element ? null : element;
    }
  }

}
