export {BusinessUnitsNavigationComponent} from "./business-units-navigation/business-units-navigation.component";
export {BusinessUnitsListComponent} from "./business-units-list/business-units-list.component";
export {BusinessUnitModalComponent} from "./business-unit-modal/business-unit-modal.component";
export {
  BusinessUnitEmployeeModalComponent
} from "./business-unit-employee-modal/business-unit-employee-modal.component";

//pages
export {BusinessUnitInfoComponent} from "./business-unit-info/business-unit-info.component";
export {BusinessUnitDealsComponent} from "./business-unit-deals/business-unit-deals.component";
export {BusinessUnitMandatesComponent} from "./business-unit-mandates/business-unit-mandates.component";
export {BusinessUnitEmployeesComponent} from "./business-unit-employees/business-unit-employees.component";
export {
  BusinessUnitEmployeeNavigationComponent
} from "./business-unit-employee-navigation/business-unit-employee-navigation.component";
export {
  BusinessUnitEmployeeInnerNavigationComponent
} from "./business-unit-employee-inner-navigation/business-unit-employee-inner-navigation.component";
export {BusinessUnitEmployeeInfoComponent} from "./business-unit-employee-info/business-unit-employee-info.component";
export {
  BusinessUnitEmployeePermissionsComponent
} from "./business-unit-employee-permissions/business-unit-employee-permissions.component";
export {BusinessUnitKycComponent} from "./business-unit-kyc/business-unit-kyc.component";
export {BusinessUnitEntityComponent} from "./business-unit-entity/business-unit-entity.component";
// export {
//   BusinessUnitThirdPartyDeterminationComponent
// } from "./business-unit-third-party-determination/business-unit-third-party-determination.component";



