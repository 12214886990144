<div class="rm-header">
  <span>Natural Person Information</span>
</div>
<form [formGroup]="form" (submit)="submit()">
  <!--  <mat-toolbar style="width: calc(100% + 48px); margin-left: -24px; height: 50px;">-->
  <!--    <div style="width:100%;display: flex; justify-items: end; justify-content: end;">-->
  <!--      <button type="button" mat-icon-button (click)="getSearches()">-->
  <!--        <mat-icon>comment</mat-icon>-->
  <!--      </button>-->
  <!--      <button type="button" mat-icon-button (click)="getComments()">-->
  <!--        <mat-icon>outlined_flag</mat-icon>-->
  <!--      </button>-->
  <!--      <button type="button" mat-icon-button (click)="getDetails()">-->
  <!--        <mat-icon>warning_amber</mat-icon>-->
  <!--      </button>-->
  <!--      <button type="button" mat-icon-button (click)="getMonitors()">-->
  <!--        <mat-icon>thumb_up_off_alt</mat-icon>-->
  <!--      </button>-->
  <!--    </div>-->
  <!--  </mat-toolbar>-->
  <mat-dialog-content style="display: flex; flex-direction: column; gap: 25px; padding-top: 15px;">
    <!-- make different distinct sections -->
    <div class="row" id="errors" *ngIf="!!data.person?.errors && data.person.errors.length > 0">
      <h3>User Errors</h3>
      <div class="col-12" style="padding-bottom: 25px;">
        <ul style="color: red;">
          <li *ngFor="let e of data.person?.errors; index as i">
            {{ e.kycSectionType + ' - ' + e.errorMessage }}
          </li>
        </ul>
      </div>
    </div>

    <!-- ADMIN ONLY SECTION  -->
    <div class="row" id="admin-only" *ngIf="util.isType('admin')">
      <h3>Admin Only Section</h3>
      <div class="row">
        <div class="col-6">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>KYC Person Type</mat-label>
            <mat-select id="kycPersonType" formControlName="kycPersonType" required>
            <mat-option *ngFor="let s of lookups.kycPersonType" [value]="s.key">
              {{ s['detail'] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        </div>
        <!-- <div class="col-6">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>KYC Person Status</mat-label>
            <mat-select id="kycPersonStatus" formControlName="rondeivuStatus" (selectionChange)="changeStatus()"
                        [disabled]="!data.person || data.disabled" required
                        [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}">
              <mat-option *ngFor="let s of lookups.kycPersonRondeivuStatus" [value]="s.key">
                {{ s['value'] }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
      </div>
    </div>

    <!-- Basic Person Information SECTION -->
    <div class="row" id="basic-info">
      <h3>Basic Information</h3>
      <div class="row">
        <div class="col-3">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>First Name</mat-label>
            <input matInput id="fn" type="text"
                    formControlName="firstName"
                    [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                    placeholder="First Name" required/>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Middle Name</mat-label>
            <input matInput id="mn" type="text"
                    formControlName="middleName"
                    [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                    placeholder="Middle Name"/>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Last Name</mat-label>
            <input matInput id="ln" type="text"
                    formControlName="lastName"
                    [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                    placeholder="Last Name"
                    required/>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Suffix</mat-label>
            <input matInput id="su" type="text"
                    formControlName="suffix"
                    [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                    placeholder="Suffix"/>
          </mat-form-field>
        </div>
      </div>

      <!-- Person Address (Street Address & City) -->
      <div class="row" *ngIf="isKyc(Level.LONG) || isKyc(Level.FULL) || isKyc(Level.EXTENDED)">
        <div class="col-9">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Street Address</mat-label>
            <input matInput id="st" type="text"
                   formControlName="streetAddress"
                   [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                   placeholder="StreetAddress"
                   required/>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>City</mat-label>
            <input matInput id="city" type="text"
                   formControlName="city"
                   [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                   placeholder="City"
                   required/>
          </mat-form-field>
        </div>
      </div>

      <!-- Country, State, ZipCode -->
      <div class="row">
        <div class="col-4">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Country</mat-label>
            <mat-select id="country" formControlName="countryId" required [disabled]="data.disabled"
                        [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}">
              <mat-option *ngFor="let c of lookups.countries" [value]="c.key">
                {{ c.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-4" *ngIf="isKyc(Level.LONG) || isKyc(Level.FULL) || isKyc(Level.EXTENDED)">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>State/Province</mat-label>
            <mat-select id="state" formControlName="stateOrProvince" [disabled]="data.disabled"
                        [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}">
              <div *ngIf="form.get('countryId')?.value == 'US'">
                <mat-option *ngFor="let s of lookups.stateTypes" [value]="s.key">
                  {{ s.value }}
                </mat-option>
              </div>
              <div *ngIf="form.get('countryId')?.value == 'CA'">
                <mat-option *ngFor="let p of lookups.provinceTypes" [value]="p.key">
                  {{ p.value }}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-4" *ngIf="isKyc(Level.LONG) || isKyc(Level.FULL) || isKyc(Level.EXTENDED)">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Zip/Postal Code</mat-label>
            <input matInput id="zip" type="text"
                   formControlName="zipPostalCode"
                   [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                   placeholder="Zip/Postal Code"/>
          </mat-form-field>
        </div>
      </div>

      <!-- Person Job title, and year of Birth -->
      <div class="row">
        <div class="col-6">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Job Title</mat-label>
            <input matInput id="jt" type="text"
                   formControlName="jobTitle"
                   [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                   placeholder="Job Title (e.g. Director)"/>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Year of Birth</mat-label>
            <input matInput id="yob" type="number"
                   formControlName="yearofBirth"
                   [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                   required
                   placeholder="Year of Birth"/>
          </mat-form-field>
        </div>
      </div>
      
      <div class="row">
        <div class="col-12" *ngIf="isKyc(Level.EXTENDED)">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Principal Occupation</mat-label>
            <input matInput id="occupation" type="text"
                   formControlName="principalOccupation"
                   [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                   placeholder="Principal Occupation (e.g. Director of Product)"/>
          </mat-form-field>
        </div>
      </div>

    </div>

   
    <!-- Government Issued ID SECTION  -->
    <div class="row" id="govt-id" *ngIf="isKyc(Level.FULL) || isKyc(Level.EXTENDED)">
      <h3>Government Issued Id</h3>
      <div class="row">

        <div class="col-4">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Government Issued ID Type</mat-label>
            <mat-select id="govIdType" formControlName="personGovernmentIssuedIdType" [disabled]="data.disabled"
                        [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}" required>
              <mat-option *ngFor="let t of lookups.personGovernmentIssuedIdType" [value]="t.key">
                {{ t.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
   
        <div class="col-4">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Government Issuer ID Number</mat-label>
            <input matInput id="idNumber" type="text"
                    formControlName="personGovernmentIssuedIdNumber"
                    [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                    required
                    placeholder="Government Issued ID Number"/>
          </mat-form-field>
        </div>

        <div class="col-4">
          <mat-form-field appearance="outline" class="form-field">
            <input matInput [matDatepicker]="datepicker1"
                    [disabled]="data.disabled"
                    formControlName="personGovernmentIssuedIdExpiryDate"
                    [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                    required/>
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="datepicker1"></mat-datepicker-toggle>
            <mat-datepicker #datepicker1></mat-datepicker>
            <mat-label>Government Issuer ID Expiry</mat-label>
            <mat-error *ngIf="form.invalid">Please select a date.</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <!-- PROOF OF IDENTIFICATION SECTION  -->
    <div class="row" id="govt-id" *ngIf="isKyc(Level.FULL) || isKyc(Level.EXTENDED)">
      <h3>Proof of Identification</h3>

      <div class="col-12">
        <span style="font-size: small; color: grey">Files under 2MB are allowed.</span><br/>
        <input style="padding: 0; height:auto;" type="file" placeholder="Choose File..."
                (change)="addDocuments($event, 'personProofofIdentificationDocuments')" #fileUpload
                [multiple]="!data.person"
                [disabled]="data.disabled"
                [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"/>
      </div>

      <!-- FILE TABLE-->
      <div class="col-12">
        <table class="table table-striped form-table">
          <thead>
          <th class="form-table-header">File</th>
          <th class="form-table-header">Date</th>
          <th class="form-table-header">Actions</th>
          </thead>
          <tbody>
          <tr *ngFor="let f of form.get('personProofofIdentificationDocuments')?.value; index as i;">
            <td style="padding-left: 5px; line-height: 36px;">{{ !!data.person ? f.fileName : f.name }}</td>
            <td style="line-height: 36px;">{{ f.created | date }}</td>
            <td class="text-center">
              <button mat-icon-button type="button" *ngIf="!!data.person && !!f.id"
                      [disabled]="data.disabled"
                      [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                      (click)="downloadProofOfIdentification(f.id, f.fileName)">
                <mat-icon [color]="data.disabled ? undefined: 'primary'">download</mat-icon>
              </button>&nbsp;
              <button mat-icon-button type="button" *ngIf="!!data.person && !!f.id"
                      [disabled]="data.disabled"
                      [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                      (click)="deleteProofOfIdentification(f.id, i)">
                <mat-icon [color]="data.disabled ? undefined: 'warn'">delete</mat-icon>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Proof of Identification Type</mat-label>
            <mat-select id="proofId"
                        formControlName="personProofofIdentificationIdType"
                        [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                        [disabled]="data.disabled">
              <mat-option *ngFor="let t of lookups.personProofofIdentificationType" [value]="t.key">
                {{ t.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col" *ngIf="form.get('personProofofIdentificationIdType')?.value == 'OTHER'">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Other Type</mat-label>
            <input matInput id="otherId" type="text"
                    formControlName="personProofofIdentificationOtherName"
                    [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                    [disabled]="data.disabled"
                    required
                    placeholder="What type of identification is it?"/>
          </mat-form-field>
        </div>

        <div class="col">
          <mat-form-field appearance="outline" class="form-field">
            <input matInput [matDatepicker]="datepicker"
                    formControlName="personProofofIdentificationIdExpiryDate"
                    [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                    [disabled]="data.disabled"
                    required/>
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
            <mat-datepicker #datepicker></mat-datepicker>
            <mat-label>Proof of ID Expiry</mat-label>
            <mat-error *ngIf="form.invalid">Please select a date.</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <!-- PROOF OF ADDRESS SECTION  -->
    <div class="row" id="govt-id" *ngIf="isKyc(Level.FULL) || isKyc(Level.EXTENDED)">
      <h3>Proof of Address</h3>
      <div class="row">
        <span style="font-size: small; color: grey">Files under 2MB are allowed.</span><br/>
        <input style="padding: 0; height:auto;" type="file"
                [disabled]="data.disabled"
                [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                placeholder="Choose File..."
                (change)="addDocuments($event, 'personProofofAddressDocuments')" #addUpload [multiple]="!data.person"/>
      </div>
  
      <!-- FILE TABLE-->
      <div class="col-12">
        <table class="table table-striped form-table">
          <thead>
          <th class="form-table-header">File</th>
          <th class="form-table-header">Date</th>
          <th class="form-table-header">Actions</th>
          </thead>
          <tbody>
          <tr *ngFor="let f of form.get('personProofofAddressDocuments')?.value; index as i;">
            <td style="padding-left: 5px; line-height: 36px;">{{ !!data.person ? f.fileName : f.name }}</td>
            <td style="line-height: 36px;">{{ f.created | date }}</td>
            <td class="text-center">
              <button mat-icon-button type="button"
                      *ngIf="!!data.person && !!f.id"
                      [disabled]="data.disabled"
                      [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                      (click)="downloadProofOfAddress(f.id, f.fileName)">
                <mat-icon [color]="data.disabled ? undefined: 'primary'">download</mat-icon>
              </button>&nbsp;
              <button mat-icon-button type="button"
                      *ngIf="!!data.person && !!f.id"
                      [disabled]="data.disabled"
                      [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                      (click)="deleteProofOfAddress(f.id, i)">
                <mat-icon [color]="data.disabled ? undefined: 'warn'">delete</mat-icon>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
  
      <div class="row">
        <div class="col">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Proof of Address Document Type</mat-label>
            <mat-select id="proofAdd" formControlName="personProofofAddressDocumentType"
                        [disabled]="data.disabled"
                        [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                        required>
              <mat-option *ngFor="let t of lookups.personProofofAddressDocumentType" [value]="t.key">
                {{ t.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col" *ngIf="form.get('personProofofAddressDocumentType')?.value == 'OTHER'">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Other Type</mat-label>
            <input matInput id="otherAddType"
                    type="text"
                    formControlName="personProofofAddressDocumentOtherName"
                    [disabled]="data.disabled"
                    [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                    required
                    placeholder="What type of proof of address is it?"/>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline" class="form-field">
            <input matInput [matDatepicker]="datepicker2"
                    [disabled]="data.disabled"
                    [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}"
                    formControlName="personProofofAddressIssuedDate" required/>
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="datepicker2"></mat-datepicker-toggle>
            <mat-datepicker #datepicker2></mat-datepicker>
            <mat-label>Proof of Address Issue Date</mat-label>
            <mat-error *ngIf="form.invalid">Please select a date.</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <!--      row-->
  <mat-dialog-actions style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
    <button mat-raised-button type="button" (click)="dialogRef.close()">
      Cancel
    </button>
    <button mat-raised-button color="primary" type="submit"
        [disabled]="data.disabled"
        [ngStyle]="{'cursor': data.disabled ? 'not-allowed' : ''}">
      Submit
    </button>
  </mat-dialog-actions>
</form>
