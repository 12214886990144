<div class="rm-header">
  <span>Confirm Delete</span>
</div>
<mat-dialog-content>
  <div style="padding-bottom: 25px; padding-top: 25px; min-width: 150px;">
    <span style="font-size: large;">Please confirm you would like to delete.</span>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="space-between">
  <button mat-raised-button [mat-dialog-close]="false">Cancel</button>
  <button mat-raised-button color="warn" [mat-dialog-close]="true">Delete</button>
</mat-dialog-actions>
