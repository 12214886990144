import {Injectable, OnDestroy} from '@angular/core';
import {ILookup, LookupType} from "../../models";
import {Store} from "@ngrx/store";
import {
  Config,
  initialConfigState,
  getConfig,
  updateAreasOfFocus,
  updateAssetTypes,
  updateCountries,
  updateCountryCodes,
  updateCurrencyTypes,
  updateDealCloseTypes,
  updateDealInterestTypes,
  updateDealNdaRequirementTypes,
  updateDealTypes,
  updateDealWorkflowStatus,
  updateIssuerEngagementAgreementStatusTypes,
  updateInvestorDescriptionTypes,
  updateInvestorMandateWorkflowStatus,
  updateInvestorTypes,
  updateIssuerTypes,
  updateProvinceTypes,
  updateSecDealTypes,
  updateStateTypes,
  updateTimelineTypes,
  updateUserIntakeSourceTypes,
  updateIssuerNDAStatusTypes,
  updateKYCStatusTypes,
  updateDealNotesAccessTypes,
  updateTeamUserRoleTypes,
  updateMemberInviteStatus,
  updateDealAlertTypes,
  updateViewAccessControls,
  updateDataroomTypes,
  updateBusinessUnitProfileTypes,
  updateBusinessUnitWorkflowStatus,
  updateIndicationOfInterestRestrictionTypes,
  updateInvestorDealWorkflowStatus,
  updateNoteTypes,
  updateSyndicationRestrictionTypes,
  updateSyndicationStatus,
  updateSubscriptionRestrictionTypes,
  updateSubscriptionStatus,
  updateRondeivuEmployeeRoleTypes,
  updateNdaTypes,
  updateEnvelopeStatus,
  updateKycInvestorTypes,
  updateKycIssuerTypes,
  updateAnchorInterestTypes,
  updateIssuerLegalEntityType,
  updateTaxIdType,
  updateLegalEntityProofofDocumentType,
  updateEntityTabDocumentationType,
  updatePersonGovernmentIssuedIdType,
  updatePersonProofofIdentificationIdType,
  updatePersonProofofAddressDocumentType,
  updateKycLegalEntityRondeivuStatus,
  updateKycPersonRondeivuStatus,
  updatePersonProofofIdentificationType,
  updateEntityTaxDocumentationType,
  updateKycType,
  updateKycRondeivuStatus,
  updateKycPersonType,
  updateKycLegalEntityType,
  updateBusinessUnitEntityType, updateSubDocStatus,
  Lookups,
  initialLookupsState
} from "../../modules/redux";
import {environment} from "../../../environments/environment";
import {RondeivuHttpClient} from "../../handlers";
import {Observable, Subscription, tap} from "rxjs";
import {ToastrService} from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class LookupService implements OnDestroy {

  // lookup$: Subscription = new Subscription();
  // config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;

  readonly BASE_URL = environment.rondeivu_api + '/lookups';
  readonly PRIVATE = '/private';
  readonly PUBLIC = '/public';
  constructor(private http: RondeivuHttpClient, private store: Store, private toastr: ToastrService) {
    // this.store.select(getConfig).subscribe((config: Config) => {
    //   if (!!config) {
    //     this.config = config;
    //   }
    // });

  }

  loadPublicLookups$() : Observable<Lookups> {
    return this.http.get<Lookups>(this.BASE_URL + this.PUBLIC).pipe(tap(res => {
      this.lookups = Object.assign({}, this.lookups, res);

      this.store.dispatch(updateCountries({items: res['countries'] as ILookup[]}));
      this.store.dispatch(updateCountryCodes({items: res['countryCodes'] as ILookup[]}));
      this.store.dispatch(updateProvinceTypes({items: res['provinceTypes'] as ILookup[]}));
      this.store.dispatch(updateStateTypes({items: res['stateTypes'] as ILookup[]}));
      this.store.dispatch(updateInvestorTypes({items: res['investorTypes'] as ILookup[]}));
      this.store.dispatch(updateIssuerTypes({items: res['issuerTypes'] as ILookup[]}));
    }));
  }

  loadPrivateLookups$() : Observable<Lookups> {
    return this.http.get<Lookups>(this.BASE_URL + this.PRIVATE).pipe(tap(res => {
      this.lookups = Object.assign({}, this.lookups, res);
      console.log("Private lookups", this.lookups);

      this.store.dispatch(updateBusinessUnitProfileTypes({items: res['businessUnitProfileTypes'] as ILookup[]}));
      this.store.dispatch(updateBusinessUnitWorkflowStatus({items: res['businessUnitWorkflowStatus'] as ILookup[]}));
      this.store.dispatch(updateAreasOfFocus({items: res['areasOfFocus'] as ILookup[]}));
      this.store.dispatch(updateAssetTypes({items: res['assetTypes'] as ILookup[]}));
      this.store.dispatch(updateDealNdaRequirementTypes({items: res['dealNdaRequirementTypes'] as ILookup[]}));
      this.store.dispatch(updateDealTypes({items: res['dealTypes'] as ILookup[]}));
      this.store.dispatch(updateDealWorkflowStatus({items: res['dealWorkflowStatus'] as ILookup[]}));
      this.store.dispatch(updateInvestorDescriptionTypes({items: res['investorDescriptionTypes'] as ILookup[]}));
      this.store.dispatch(updateTimelineTypes({items: res['timeLineTypes'] as ILookup[]}));
      this.store.dispatch(updateInvestorMandateWorkflowStatus({items: res['investorMandateWorkflowStatus'] as ILookup[]}));
      this.store.dispatch(updateDealCloseTypes({items: res['dealCloseTypes'] as ILookup[]}));
      this.store.dispatch(updateSecDealTypes({items: res['secDealTypes'] as ILookup[]}));
      this.store.dispatch(updateCurrencyTypes({items: res['currencyTypes'] as ILookup[]}));
      this.store.dispatch(updateDealInterestTypes({items: res['dealInterestTypes'] as ILookup[]}));
      this.store.dispatch(updateUserIntakeSourceTypes({items: res['userIntakeSourceTypes'] as ILookup[]}));
      this.store.dispatch(updateIssuerEngagementAgreementStatusTypes({items: res['issuerEngagementAgreementStatusTypes'] as ILookup[]}));
      this.store.dispatch(updateIssuerNDAStatusTypes({items: res['issuerNDAStatusTypes'] as ILookup[]}));
      this.store.dispatch(updateKYCStatusTypes({items: res['kycStatusTypes'] as ILookup[]}));
      this.store.dispatch(updateDealNotesAccessTypes({items: res['dealNotesAccessTypes'] as ILookup[]}));
      this.store.dispatch(updateTeamUserRoleTypes({items: res['teamUserRoleTypes'] as ILookup[]}));
      this.store.dispatch(updateMemberInviteStatus({items: res['memberInviteStatus'] as ILookup[]}));
      this.store.dispatch(updateDealAlertTypes({items: res['dealAlertTypes'] as ILookup[]}));
      this.store.dispatch(updateViewAccessControls({items: res['viewAccessControls'] as ILookup[]}));
      this.store.dispatch(updateDataroomTypes({items: res['dataroomTypes'] as ILookup[]}));
      this.store.dispatch(updateIndicationOfInterestRestrictionTypes({items: res['indicationOfInterestRestrictionTypes'] as ILookup[]}));
      this.store.dispatch(updateInvestorDealWorkflowStatus({items: res['investorDealWorkflowStatus'] as ILookup[]}));
      this.store.dispatch(updateNoteTypes({items: res['noteTypes'] as ILookup[]}));
      this.store.dispatch(updateSyndicationRestrictionTypes({items: res['syndicationRestrictionTypes'] as ILookup[]}));
      this.store.dispatch(updateSyndicationStatus({items: res['syndicationStatus'] as ILookup[]}));
      this.store.dispatch(updateSubscriptionRestrictionTypes({items: res['subscriptionRestrictionTypes'] as ILookup[]}));
      this.store.dispatch(updateSubscriptionStatus({items: res['subscriptionStatus'] as ILookup[]}));
      this.store.dispatch(updateRondeivuEmployeeRoleTypes({items: res['rondeivuEmployeeRoleTypes'] as ILookup[]}));
      this.store.dispatch(updateNdaTypes({items: res['ndaTypes'] as ILookup[]}));
      this.store.dispatch(updateEnvelopeStatus({items: res['envelopeStatus'] as ILookup[]}));
      this.store.dispatch(updateKycInvestorTypes({items: res['kycInvestorTypes'] as ILookup[]}));
      this.store.dispatch(updateKycIssuerTypes({items: res['kycIssuerTypes'] as ILookup[]}));
      this.store.dispatch(updateAnchorInterestTypes({items: res['anchorInterestTypes'] as ILookup[]}));
      this.store.dispatch(updateIssuerLegalEntityType({items: res['issuerLegalEntityType'] as ILookup[]}));
      this.store.dispatch(updateTaxIdType({items: res['taxIdType'] as ILookup[]}));
      this.store.dispatch(updateLegalEntityProofofDocumentType({items: res['legalEntityProofofDocumentType'] as ILookup[]}));
      this.store.dispatch(updateEntityTabDocumentationType({items: res['entityTabDocumentationType'] as ILookup[]}));
      this.store.dispatch(updatePersonGovernmentIssuedIdType({items: res['personGovernmentIssuedIdType'] as ILookup[]}));
      this.store.dispatch(updatePersonProofofIdentificationIdType({items: res['personProofofIdentificationIdType'] as ILookup[]}));
      this.store.dispatch(updatePersonProofofAddressDocumentType({items: res['personProofofAddressDocumentType'] as ILookup[]}));
      this.store.dispatch(updateEntityTaxDocumentationType({items: res['entityTaxDocumentationType'] as ILookup[]}));
      this.store.dispatch(updatePersonProofofIdentificationType({items: res['personProofofIdentificationType'] as ILookup[]}));
      this.store.dispatch(updateKycPersonRondeivuStatus({items: res['kycPersonRondeivuStatus'] as ILookup[]}));
      this.store.dispatch(updateKycLegalEntityRondeivuStatus({items: res['kycLegalEntityRondeivuStatus'] as ILookup[]}));
      this.store.dispatch(updateKycType({items: res['kycType'] as ILookup[]}));
      this.store.dispatch(updateKycRondeivuStatus({items: res['kycRondeivuStatus'] as ILookup[]}));
      this.store.dispatch(updateKycPersonType({items: res['kycPersonType'] as ILookup[]}));
      this.store.dispatch(updateKycLegalEntityType({items: res['kycLegalEntityType'] as ILookup[]}));
      this.store.dispatch(updateBusinessUnitEntityType({items: res['businessUnitEntityType'] as ILookup[]}));
      this.store.dispatch(updateSubDocStatus({items: res['subDocStatus'] as ILookup[]}));
    }));
  }

  getLookupValueForKey(lookup: ILookup[], key: string): string {
    if (this.lookups == null || lookup == null)
      return "n/a";

    return lookup.find(x => x.key == key)?.value || "n/a";
  }

  ngOnDestroy(): void {
    // this.lookup$.unsubscribe();
  }



  // loadLookup(lookupType: LookupType) {
  //   this.lookup$.add(this.lookup(lookupType.toString()).subscribe({
  //     next: (res) => {
  //       console.log("Lookups", lookupType, res);
  //       switch (lookupType) {
  //         case LookupType.Public:

  //           this.lookups = Object.assign({}, this.lookups, res);

  //           this.store.dispatch(updateCountries({items: res['countries'] as ILookup[]}));
  //           this.store.dispatch(updateCountryCodes({items: res['countryCodes'] as ILookup[]}));
  //           this.store.dispatch(updateProvinceTypes({items: res['provinceTypes'] as ILookup[]}));
  //           this.store.dispatch(updateStateTypes({items: res['stateTypes'] as ILookup[]}));
  //           this.store.dispatch(updateInvestorTypes({items: res['investorTypes'] as ILookup[]}));
  //           this.store.dispatch(updateIssuerTypes({items: res['issuerTypes'] as ILookup[]}));
  //           break;
  //         case LookupType.Private:

  //           this.lookups = Object.assign({}, this.lookups, res);

  //           this.store.dispatch(updateBusinessUnitProfileTypes({items: res['businessUnitProfileTypes'] as ILookup[]}));
  //           this.store.dispatch(updateBusinessUnitWorkflowStatus({items: res['businessUnitWorkflowStatus'] as ILookup[]}));
  //           this.store.dispatch(updateAreasOfFocus({items: res['areasOfFocus'] as ILookup[]}));
  //           this.store.dispatch(updateAssetTypes({items: res['assetTypes'] as ILookup[]}));
  //           this.store.dispatch(updateDealNdaRequirementTypes({items: res['dealNdaRequirementTypes'] as ILookup[]}));
  //           this.store.dispatch(updateDealTypes({items: res['dealTypes'] as ILookup[]}));
  //           this.store.dispatch(updateDealWorkflowStatus({items: res['dealWorkflowStatus'] as ILookup[]}));
  //           this.store.dispatch(updateInvestorDescriptionTypes({items: res['investorDescriptionTypes'] as ILookup[]}));
  //           this.store.dispatch(updateTimelineTypes({items: res['timeLineTypes'] as ILookup[]}));
  //           this.store.dispatch(updateInvestorMandateWorkflowStatus({items: res['investorMandateWorkflowStatus'] as ILookup[]}));
  //           this.store.dispatch(updateDealCloseTypes({items: res['dealCloseTypes'] as ILookup[]}));
  //           this.store.dispatch(updateSecDealTypes({items: res['secDealTypes'] as ILookup[]}));
  //           this.store.dispatch(updateCurrencyTypes({items: res['currencyTypes'] as ILookup[]}));
  //           this.store.dispatch(updateDealInterestTypes({items: res['dealInterestTypes'] as ILookup[]}));
  //           this.store.dispatch(updateUserIntakeSourceTypes({items: res['userIntakeSourceTypes'] as ILookup[]}));
  //           this.store.dispatch(updateIssuerEngagementAgreementStatusTypes({items: res['issuerEngagementAgreementStatusTypes'] as ILookup[]}));
  //           this.store.dispatch(updateIssuerNDAStatusTypes({items: res['issuerNDAStatusTypes'] as ILookup[]}));
  //           this.store.dispatch(updateKYCStatusTypes({items: res['kycStatusTypes'] as ILookup[]}));
  //           this.store.dispatch(updateDealNotesAccessTypes({items: res['dealNotesAccessTypes'] as ILookup[]}));
  //           this.store.dispatch(updateTeamUserRoleTypes({items: res['teamUserRoleTypes'] as ILookup[]}));
  //           this.store.dispatch(updateMemberInviteStatus({items: res['memberInviteStatus'] as ILookup[]}));
  //           this.store.dispatch(updateDealAlertTypes({items: res['dealAlertTypes'] as ILookup[]}));
  //           this.store.dispatch(updateViewAccessControls({items: res['viewAccessControls'] as ILookup[]}));
  //           this.store.dispatch(updateDataroomTypes({items: res['dataroomTypes'] as ILookup[]}));
  //           this.store.dispatch(updateIndicationOfInterestRestrictionTypes({items: res['indicationOfInterestRestrictionTypes'] as ILookup[]}));
  //           this.store.dispatch(updateInvestorDealWorkflowStatus({items: res['investorDealWorkflowStatus'] as ILookup[]}));
  //           this.store.dispatch(updateNoteTypes({items: res['noteTypes'] as ILookup[]}));
  //           this.store.dispatch(updateSyndicationRestrictionTypes({items: res['syndicationRestrictionTypes'] as ILookup[]}));
  //           this.store.dispatch(updateSyndicationStatus({items: res['syndicationStatus'] as ILookup[]}));
  //           this.store.dispatch(updateSubscriptionRestrictionTypes({items: res['subscriptionRestrictionTypes'] as ILookup[]}));
  //           this.store.dispatch(updateSubscriptionStatus({items: res['subscriptionStatus'] as ILookup[]}));
  //           this.store.dispatch(updateRondeivuEmployeeRoleTypes({items: res['rondeivuEmployeeRoleTypes'] as ILookup[]}));
  //           this.store.dispatch(updateNdaTypes({items: res['ndaTypes'] as ILookup[]}));
  //           this.store.dispatch(updateEnvelopeStatus({items: res['envelopeStatus'] as ILookup[]}));
  //           this.store.dispatch(updateKycInvestorTypes({items: res['kycInvestorTypes'] as ILookup[]}));
  //           this.store.dispatch(updateKycIssuerTypes({items: res['kycIssuerTypes'] as ILookup[]}));
  //           this.store.dispatch(updateAnchorInterestTypes({items: res['anchorInterestTypes'] as ILookup[]}));
  //           this.store.dispatch(updateIssuerLegalEntityType({items: res['issuerLegalEntityType'] as ILookup[]}));
  //           this.store.dispatch(updateTaxIdType({items: res['taxIdType'] as ILookup[]}));
  //           this.store.dispatch(updateLegalEntityProofofDocumentType({items: res['legalEntityProofofDocumentType'] as ILookup[]}));
  //           this.store.dispatch(updateEntityTabDocumentationType({items: res['entityTabDocumentationType'] as ILookup[]}));
  //           this.store.dispatch(updatePersonGovernmentIssuedIdType({items: res['personGovernmentIssuedIdType'] as ILookup[]}));
  //           this.store.dispatch(updatePersonProofofIdentificationIdType({items: res['personProofofIdentificationIdType'] as ILookup[]}));
  //           this.store.dispatch(updatePersonProofofAddressDocumentType({items: res['personProofofAddressDocumentType'] as ILookup[]}));
  //           this.store.dispatch(updateEntityTaxDocumentationType({items: res['entityTaxDocumentationType'] as ILookup[]}));
  //           this.store.dispatch(updatePersonProofofIdentificationType({items: res['personProofofIdentificationType'] as ILookup[]}));
  //           this.store.dispatch(updateKycPersonRondeivuStatus({items: res['kycPersonRondeivuStatus'] as ILookup[]}));
  //           this.store.dispatch(updateKycLegalEntityRondeivuStatus({items: res['kycLegalEntityRondeivuStatus'] as ILookup[]}));
  //           this.store.dispatch(updateKycType({items: res['kycType'] as ILookup[]}));
  //           this.store.dispatch(updateKycRondeivuStatus({items: res['kycRondeivuStatus'] as ILookup[]}));
  //           this.store.dispatch(updateKycPersonType({items: res['kycPersonType'] as ILookup[]}));
  //           this.store.dispatch(updateKycLegalEntityType({items: res['kycLegalEntityType'] as ILookup[]}));
  //           this.store.dispatch(updateBusinessUnitEntityType({items: res['businessUnitEntityType'] as ILookup[]}));
  //           this.store.dispatch(updateSubDocStatus({items: res['subDocStatus'] as ILookup[]}));
  //           break;
  //       }

  //       console.log(this.lookups)
  //     }, error: (error: any) => {
  //       this.toastr.error("Unable to get lookup " + lookupType.toString(), $localize`:@@companyName:Rondeivu`);
  //     }
  //   }));
  // }

  // loadLookups() {
  //   // lookups
  //   this.loadLookup(LookupType.Public);
  // }

  // lookup(fragment: string): Observable<any> {
  //   return this.http.get(this.BASE_URL + '/' + fragment);
  // }
}
