import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Route, Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {IBusinessUnit} from "../modules/business-units/models";
import {BusinessUnitService} from "../modules/business-units/services";
import {UtilService} from "../modules/shared/services";
import {AuthService} from "../services";
import {updateBusinessUnits, updateSelectedBusinessUnit} from "../modules/redux";
import { DataService } from '../services/data/data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private readonly LOGIN_PATH = '/auth/login';

  constructor(
    private util: UtilService,
    private auth: AuthService,
    private store: Store,
    private dataService: DataService,
    private router: Router,
    private toastr: ToastrService,
    private businessUnitService: BusinessUnitService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    console.log("Running auth guard!");
    // check the validation of the cognito session
    return new Promise<boolean | UrlTree>((resolve) => {
      this.auth.isValidCognitoSession().then((validCognito: boolean) => {
        console.log("valid cognito session", validCognito);
        let businessUnitSlug = route.paramMap.get('id') || '';

        const specials = /[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g;
        if (specials.test(businessUnitSlug) == true) {
          console.log("Business Unit Slug has special characters", businessUnitSlug)
          this.util.logOut();
          resolve(this.router.createUrlTree([this.LOGIN_PATH]));
          return;
        };

        console.log("businessUnitSlug", businessUnitSlug)

        this.businessUnitService.getUserBusinessUnits().subscribe({
          next: (businessUnits: IBusinessUnit[]) => { 
            // console.log("business units", businessUnits);
            this.dataService.businessUnits = businessUnits;

            // if there are no business units, then log the user out
            if (businessUnits.length == 0) {
              this.toastr.error("You don't have any business units.  Please contact admin.");
              this.util.logOut().then(() => console.log("Logged out!!!"));
              resolve(this.router.createUrlTree([this.LOGIN_PATH]));
              return;
            }

            // console.log("business units length > 1", businessUnitSlug);
            this.store.dispatch(updateBusinessUnits({businessUnits: businessUnits}));
            
            // does the list of BUs include the slug that is being requested?
            var bu = businessUnits.filter(x => x.slug.toLowerCase() == businessUnitSlug.toLowerCase());

            if (bu.length == 0) {
              this.toastr.error(`You don't have access to the '${businessUnitSlug}' business unit.  Please contact admin.`);
              this.util.logOut().then(() => console.log("Logged out!!!"));
              resolve(this.router.createUrlTree([this.LOGIN_PATH]));
              return;
            }

            // you have access to the business unit
            var businessUnit = bu[0];
            console.log("Current Business unit is ", businessUnit);

            this.dataService.currentBusinessUnit = businessUnit;
            this.store.dispatch(updateSelectedBusinessUnit({businessUnit}));
            resolve(true)

          },
          error: console.error
        });
      })
      .catch(error => {
        console.error("Cognito Error", error);
        this.util.logOut();
        resolve(this.router.createUrlTree(['auth', 'login']))
      });
    });
  }

  // canAccess(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
  //   return new Promise<boolean | UrlTree>((resolve, reject) => {
  //     this.auth.isValidCognitoSession().then((validCognito: boolean) => {
  //       this.isValidBusinessUnit(route, state).then((validBusinessUnit: boolean) => {
  //         resolve(true);
  //       }).catch(businessUnitError => {
  //         reject(false);
  //         this.util.logOut().then(() => {
  //           this.toastr.error("Access denied!", $localize`:@@companyName:Rondeivu`);
  //         });
  //       });
  //     }).catch(cognitoError => {
  //       this.util.setRedirectPath(state.url);
  //       this.router.navigate([this.LOGIN_PATH]).then(() => {
  //         this.toastr.error("Unable to determine access!", $localize`:@@companyName:Rondeivu`);
  //         reject(false);
  //       });
  //     })
  //   });
  // }

  // /**
  //  * Fetches the user business units and determines if the requested slug belongs to the users business units
  //  * @param route
  //  * @param state
  //  * @private
  //  */
  // private isValidBusinessUnit(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
  //   return new Promise<boolean>((resolve, reject) => {
  //     let verified = false;
  //     let businessUnitId = route.paramMap.get('id') || '';
  //     this.businessUnitService.getUserBusinessUnits().subscribe({
  //       next: (businessUnits: IBusinessUnit[]) => {
  //         // verify by slug
  //         if (businessUnits.length == 0) {
  //           this.util.logOut().then(() => {
  //             this.toastr.warning('Sorry, you have no available business unit access. Please try again later!', $localize`:@@companyName:Rondeivu`);
  //             resolve(true);
  //             return;
  //           });
  //         } else {
  //           let verifiedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
  //           businessUnits.forEach(businessUnit => {
  //             if (businessUnit.slug == businessUnitId) {
  //               verifiedBusinessUnit = businessUnit;
  //               verified = true;
  //             }
  //           });
  //           if (verified) {
  //             this.store.dispatch(updateSelectedBusinessUnit({businessUnit: verifiedBusinessUnit}));
  //             resolve(true);
  //           } else {
  //             if (this.hasAdminBusinessUnits(businessUnits)) {
  //               const specials = /[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g;
  //               if (!specials.test(businessUnits[0].slug)) {
  //                 // there are business units but the user does not have permission to the requested business unit
  //                 let path: string = '/' + businessUnits[0].slug + '/deals';
  //                 const message: string = 'You do not have employee access to the requested business unit, use your Admin account to access this route!';
  //                 // user has an admin business unit so override requested business unit to reach the route
  //                 const adminBusinessUnit = this.getFirstAdminBusinessUnit(businessUnits);
  //                 if (!!adminBusinessUnit) {
  //                   path = state.url.replace(businessUnitId, adminBusinessUnit.slug);
  //                   this.store.dispatch(updateSelectedBusinessUnit({businessUnit: adminBusinessUnit}));
  //                   this.router.navigate([path]).then(() => {
  //                     this.toastr.warning(message, $localize`:@@companyName:Rondeivu`);
  //                     resolve(true);
  //                   });
  //                 }
  //               } else {
  //                 this.toastr.warning('Invalid Business Unit Slug!', $localize`:@@companyName:Rondeivu`);
  //                 resolve(true);
  //               }
  //             } else {
  //               this.toastr.warning('You do not have access to the requested business unit!', $localize`:@@companyName:Rondeivu`);
  //               reject(false);
  //             }
  //           }
  //         }
  //       }, error: (err: any) => {
  //         // error subscribing to business units
  //         this.toastr.error("Access denied by auth guard!", $localize`:@@companyName:Rondeivu`);
  //         reject(false);
  //       }
  //     });
  //   });
  // }

  // private hasAdminBusinessUnits(businessUnits: IBusinessUnit[]): boolean {
  //   let has = false;
  //   businessUnits.forEach(businessUnit => {
  //     if (businessUnit.businessUnitType?.toLowerCase() == 'admin') {
  //       has = true;
  //     }
  //   });
  //   return has;
  // }

  // private getFirstAdminBusinessUnit(businessUnits: IBusinessUnit[]): IBusinessUnit | null {
  //   for (let i = 0; i <= businessUnits.length - 1; i++) {
  //     if (businessUnits[i].businessUnitType?.toLowerCase() == 'admin') {
  //       return businessUnits[i];
  //     }
  //   }
  //   return null;
  // }
}
