import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {
  BusinessUnitKycListComponent,
  // KycInformationComponent,
  // KycListComponent,
  // KycNavigationComponent
} from "./components";
import {KycResolver} from "../../resolvers/kyc.resolver";

const routes: Routes = [
  {path: '', component: BusinessUnitKycListComponent},
  // {
  //   path: ':id', component: KycNavigationComponent,
  //   resolve: {
  //     KycResolver
  //   },
  //   children: [
  //     {path: 'information', component: KycInformationComponent},
  //     {path: 'documents', component: KycDocumentsComponent},
  //     {path: '', redirectTo: 'information', pathMatch: 'full'},
  //     {path: '**', redirectTo: 'information'},
  //   ]
  // },
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class KycRoutingModule {}
