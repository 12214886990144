import {Component, ComponentFactoryResolver, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Subscription} from "rxjs";
import {
  Config,
  getConfig,
  getLookups,
  initialConfigState,
  initialLookupsState,
  Lookups,
  toggleLoading
} from "../../../redux";
import {FormArray, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {UtilService} from "../../../shared/services";
import {Store} from "@ngrx/store";
import {LookupService, UuidService} from "../../../../services";
import {ToastrService} from "ngx-toastr";
import {SubdocService} from "../../services/subdoc/subdoc.service";
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ISubDocField, ISubDocFieldsResponse, ISyndicationSubdoc, SubDocFieldType, IDomNode} from "../../models/syndication";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'app-deal-subscription-subdoc-form-modal',
  templateUrl: './deal-subscription-subdoc-form-modal.component.html',
  styleUrls: ['./deal-subscription-subdoc-form-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DealSubscriptionSubdocFormModalComponent implements OnInit {
  fg: FormGroup = new FormGroup({});

  fieldResponse: ISubDocFieldsResponse = {} as unknown as ISubDocFieldsResponse;
  allDomNodes: IDomNode[] = [];


  constructor(
    public util: UtilService,
    private store: Store,
    private toastr: ToastrService,
    private subDocService: SubdocService,
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<DealSubscriptionSubdocFormModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {fields : ISubDocFieldsResponse}) {

    console.log("SubDoc", this.data.fields);
    this.parseHtmlContent(this.data.fields);
  
  }

  ngOnInit(): void {
  }

  // getFields() {
  //   console.log("Fetching Fields...");
  //   this.store.dispatch(toggleLoading({loading: true, message: "This may take a few seconds!" }));
  //   this.subDocService.getDocumentFields(this.data.subdoc.id).subscribe({
  //     next: (fields: ISubDocFieldsResponse) => {
  //       console.log("----> Fields", fields)
  //       // this.fieldResponse = fields;
  //       // this.fieldsList = fields.fieldsList;
  //       this.parseHtmlContent(fields);

        

  //       // add the current answers to the form
  //       // this.setAnswersFromFieldList(this.fieldsList);
  //       // parse the html
  //       // this.processContent(this.fieldResponse.content);
  //       this.store.dispatch(toggleLoading({loading: false}));
  //     }, error: err => {
  //       this.store.dispatch(toggleLoading({loading: false}));
  //       this.toastr.error("Unable to get fields!", $localize`:@@companyName:Rondeivu`);
  //     }
  //   });
  // }
  innerHtml = "<div class='test'></div>";
  private parseHtmlContent(fieldsDto: ISubDocFieldsResponse) {
    var fields = fieldsDto.fieldsList;

    const parser = new DOMParser();
    const doc = parser.parseFromString(fieldsDto.content, 'text/html');
    const body = doc.body;
    if (body == null || body.childNodes.length == 0) {
      this.toastr.error("Could not parse document, please contact admin!");
      return;
    }

    var xConcordPageBreak = body.querySelectorAll('x-concord-page-break');
    var xConcordField = body.querySelectorAll('x-concord-field');

    console.log(xConcordPageBreak);
    console.log(xConcordField);

    xConcordPageBreak.forEach(x => {
      const hr = document.createElement("hr");
      hr.classList.add('test');
      x.replaceWith(hr);
    });

    var value: string | undefined;
    xConcordField.forEach(x => {
      const uuid = x.getAttribute("data-field-uuid");
      console.log("uuid", x.nodeName, uuid);

      // matFormField.classList.add("test");
      // matFormField.style.backgroundColor = "#ccc";
      // matFormField.style.border = "thin solid red !important";
      // matFormField.innerHTML = "" + uuid;

      const fieldsFromList = fields.filter(f => f.uuid == uuid);

      if (fieldsFromList.length > 0) {
        const field = fieldsFromList[0];
        console.log(field.type, field);

        if (field.type == SubDocFieldType.TEXT || field.type == SubDocFieldType.PARAGRAPH) {
          value = field.values.length > 0 ? field.values[0] : '';
          this.fg.addControl(field.uuid, new FormControl(value, []));
          // var elem = document.createElement("mat-form-field");
          // elem.innerHTML = `
          //  <input matInput class='test' type='text' formControlName='${uuid}' 
          //   [placeholder]='${field.placeholder}' [disabled]='${field.locked}' />`

          var elem = document.createElement("input");
          elem.setAttribute("formControlName", field.uuid);
          elem.classList.add('test');

          x.replaceWith(elem);
        }
        // else if (field.type == SubDocFieldType.PARAGRAPH) {
        //   value = field.values.length > 0 ? field.values[0] : '';
        //   this.fg.addControl(field.uuid, new FormControl(value, []));
        //   var elem = document.createElement("mat-form-field");
        //   elem.innerHTML = `
        //    <input matInput class='test' type='text' formControlName='${uuid}' 
        //     [placeholder]='${field.placeholder}' [disabled]='${field.locked}' />`

        //   x.replaceWith(elem);
        // }
        else if (field.type == SubDocFieldType.SIGNER_TITLE || 
                  field.type == SubDocFieldType.SIGNER_COMPANY ||
                  field.type == SubDocFieldType.SIGNER_FULL_NAME
        ) {
          let value = field.values.length > 0 ? field.values[0] : '';
          this.fg.addControl(field.uuid, new FormControl(value, []));
          const elem = document.createElement("span");
          elem.classList.add("test");
          elem.style.width = "200px;"
          elem.style.height = "60px";
          elem.innerHTML = value;
          x.replaceWith(elem);
        }
        // else if (field.type == SubDocFieldType.SIGNER_TITLE) {
        //   let value = field.values.length > 0 ? field.values[0] : '';
        //   this.fg.addControl(field.uuid, new FormControl(value, []));
        //   const elem = document.createElement("span");
        //   elem.classList.add("test");
        //   elem.style.width = "200px;"
        //   elem.style.height = "60px";
        //   elem.innerHTML = value;
        //   x.replaceWith(elem);
        // }
      }
    })

    this.innerHtml = doc.body.innerHTML;

    return;
    body.childNodes.forEach((x : ChildNode) => {
      if (x.nodeType === Node.ELEMENT_NODE) {
        let node = x as HTMLElement;
        // console.log("Node", node, node.tagName)
        



        if (node.tagName.toLowerCase() == 'x-concord-page-break') {
          this.allDomNodes.push(<IDomNode> {
            content: this.sanitizer.bypassSecurityTrustHtml("<hr />")
          });
        }
        else {
          var concordField = node.querySelector('x-concord-field');
          // if it includes a x-concord-field
          if (concordField == null) {
            // just add it to the content
            this.allDomNodes.push(<IDomNode> {
              content: this.sanitizer.bypassSecurityTrustHtml(node.outerHTML)
            });
          }
          else {
            var uuid = concordField.getAttribute('data-field-uuid')
            const fieldsFromList = fields.filter(f => f.uuid == uuid);
  
            if (fieldsFromList.length > 0) {
              const field = fieldsFromList[0];
              this.allDomNodes.push(<IDomNode> {
                ...field,
                content: this.sanitizer.bypassSecurityTrustHtml(node.outerHTML)
              });
  
  
              
              // create the form control in the form Group
              if (field.type == SubDocFieldType.CHECKBOX) {
  
                if (!!field.options) {
                  var fcs = field.options?.map(o => {
                    var val: boolean = field.values.includes(o.key);
                    return new FormGroup({
                      id: new FormControl(o.key),
                      title: new FormControl(o.value),
                      chkboxValue: new FormControl(val)
                    });
                  })
      
                  this.fg.addControl(field.uuid, new FormArray(fcs));
                }
              } else {
                let value = field.values.length > 0 ? field.values[0] : '';
                this.fg.addControl(field.uuid, new FormControl(value, []));
              }
            }
          }
  
        }


      }
    })
  }

  getCheckboxList(uuid: string) : FormArray {
    return this.fg.get(uuid) as FormArray;
  }


  /**
   * Save the form fields
   * @param approveForSig
   */
  saveFields(approveForSig: boolean) {
    console.log("Updating Fields...", this.fg.value);

    // format the field so that the info makes sense
    var fgValues = this.fg.value;
    var response : ISubDocFieldsResponse = {
      subDocId: this.data.fields.subDocId,
      content: "",
      approveForInvestorSignatures: approveForSig,
      fieldsList: []
    };

    var allConcordNodes = this.allDomNodes.filter(x => !!x.uuid);
    allConcordNodes.forEach(x => {
      // iterate each nodes that has a UUID (ie. it should be fillable)
      // get the value from the fg
      let value = fgValues[x.uuid];

      if (value != null) {
        // value
        if (typeof value === 'string') {
          // if there is no value, or its an empty string, then remove the value
          x.values = !!value ? [value] : [];
        }
        else if (Array.isArray(value)) {
          var formattedValues: string[] = [];
          value.forEach(v => {
            if (v['chkboxValue']) {
              formattedValues.push(v['id']);
            }
          })

          x.values = formattedValues;
        }
      }
    })

    response.fieldsList = allConcordNodes;
    this.store.dispatch(toggleLoading({loading: true, message: "This operation may take a few seconds!"}));
    this.subDocService.updateDocumentFields(this.data.fields.subDocId, response).subscribe({
      next: (res: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Sub Doc Fields Updated!", $localize`:@@companyName:Rondeivu`);
        this.dialogRef.close();
      }, error: err => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get fields!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

 

  downloadSubdoc() {
    var subDocId = this.data.fields.subDocId;

    this.store.dispatch(toggleLoading({loading: true}));
    this.subDocService.getSubdocPdf(subDocId).subscribe({
      next: (res: Blob) => {
        let filename = 'subdoc-download.pdf';
        let blob: Blob = res as Blob;
        let a = document.createElement('a');
        a.download = filename;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.store.dispatch(toggleLoading({loading: false}));
      },
      error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to download file!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }
}

