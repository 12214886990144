import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {RondeivuHttpClient} from "../../handlers";
import {ICognitoUser} from "../../models";
import {HttpHeaders} from "@angular/common/http";
import {Store} from "@ngrx/store";
import {getLookups, initialLookupsState, Lookups, updateCognitoUser, updateUserGroups} from "../../modules/redux";
import {Observable} from "rxjs";
import {Auth} from "aws-amplify";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly BASE_URL = environment.rondeivu_api;
  private readonly LEGACY_FRAGMENT = '/auth/registerappuser';
  private readonly FRAGMENT = '/auth/register';
  private lookups = initialLookupsState;

  constructor(private http: RondeivuHttpClient, private store: Store) {
    // The registration process that calls the add app
    // user flow should already have a cognito user token,
    // otherwise this will fail to provide the required lookup info.
    this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });
  }

  /**
   * TEST - register the app user with Rondeivu API
   * @param sub
   * @param user
   */
  legacyAddAppUser(sub: string, user: ICognitoUser) {
    let appUser = this.getAppUserFromCognitoUser(sub, user);
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.BASE_URL + this.LEGACY_FRAGMENT, appUser, {headers: httpHeaders});
  }

  /**
   * Register the app user with Rondeivu API. This also registers a Cognito user.
   * @param sub
   * @param user
   */
  addAppUser(user: any) :Observable<any>{
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.BASE_URL + this.FRAGMENT, user, {headers: httpHeaders});
  }

  /**
   * We need to country from lookup by string
   */
  public getCountryKey(country: string) {
    let k = "1a551739d9c440bda84b00e3dddc7750"
    this.lookups.countries.forEach(l => {
      if (l.value.toLowerCase() == country.toLowerCase()) {
        k = l.key;
      }
    });
    return k;
  }

  /**
   * This is the payload transformation
   */
  private getAppUserFromCognitoUser(sub: string, cognitoUser: ICognitoUser): any {
    return {
      subId: sub,
      countryId: this.getCountryKey(cognitoUser.country_code),
      firstName: cognitoUser.given_name,
      lastName: cognitoUser.family_name,
      companyName: cognitoUser.company,
      jobTitle: cognitoUser.jobTitle,
      email: cognitoUser.email,
      countryCode: cognitoUser.country_code,
      mobile: cognitoUser.phone_number,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      profile: cognitoUser.profile,
      subType: cognitoUser.subType,
      referredByEmail: cognitoUser.referredBy || "",
      inviteToken: ""
    };
  }

  /**
   * Determines if there is a Cognito user and session, that the session is valid and the user is email verified
   * @private
   */
  public isValidCognitoSession(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      Auth.currentAuthenticatedUser().then((cognitoUser : any) => {
        console.log("currentAuthenticatedUser", cognitoUser);

        // if there are no user info
        if (cognitoUser == null || Object.keys(cognitoUser).length == 0) {
          reject(false);
          return;
        }

        // dispatch to store
        // this.store.dispatch(updateCognitoUser({cognito: cognitoUser}));

        Auth.currentSession().then((currentUserSession: any) => {
          console.log("currentSession", currentUserSession, currentUserSession.isValid())
          if (!!cognitoUser && !!currentUserSession && !currentUserSession.isValid()) {
            cognitoUser.refreshSession(currentUserSession.getRefreshToken(), (err: any, session: {
              idToken: any;
              refreshToken: any;
              accessToken: any;
            }) => {
              console.log('Cognito refresh token failed!!!');
              if (!!err) {
                reject(false);
              }
            });
          }

          // session is valid
          if (!!currentUserSession && currentUserSession.isValid()) {
            const userGroups = currentUserSession.idToken.payload['cognito:groups'];
            const emailVerified = currentUserSession.idToken.payload['email_verified'] || false;
            // dispatch the cognito user groups
            if (!!userGroups) {
              this.store.dispatch(updateUserGroups({groups: userGroups}));
            }

            // dispatch the cognito user
            Auth.currentUserInfo()
              .then((user: any) => {
                console.log("Current User Info", user)
                if (Object.keys(user).length == 0) {
                  // if the object is empty, then reject 
                  reject(false);
                  return;
                }
                this.store.dispatch(updateCognitoUser({cognito: user}));
              })
              .catch(err => {
                console.error("UserInfo Error", err);
                reject(false);
              });

            // user is not email verified
            if (emailVerified == false) {
              reject(false);
            } else {
              resolve(true);
            }
          } else {
            reject(false);
          }
        });
      })
      .catch(err => {
        // there is no current user
        console.error(err);
        reject(false)
      });
    })
  }
}
