<div style="overflow: auto; height: calc(100vh - 176px);">
  <div class="container-fluid">
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-6 col-md-5">
        <span class="rm-title rm-title-3">Syndication</span>&nbsp;
        <br/>
        <em style="color: orange;">
          {{ syndication?.issuerPoolIsLocked ? 'Issuer has locked syndication pool' : 'Syndication pool is open, allocations not yet visible' }}
        </em>
      </div>
      <div class="col-6 text-end">
        <div class="col-11 text-end">
          <button mat-raised-button
                  color="warn"
                  (click)="unlock(syndication)"
                  *ngIf="syndication?.issuerPoolIsLocked"
                  [disabled]="!util.hasPermission(util.Permission.CAN_LOCK_DEAL_SYNDICATION)"
                  [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_LOCK_DEAL_SYNDICATION) ? util.NoAccessCursor: ''}"
                  [matTooltip]="!util.hasPermission(util.Permission.CAN_LOCK_DEAL_SYNDICATION) ? util.NoAccessMsg : ''"
                  matTooltipPosition="left"
          >
            Unlock Syndication Pool
          </button>
          <button mat-raised-button
                  color="warn"
                  (click)="lock(syndication)"
                  *ngIf="!syndication?.issuerPoolIsLocked"
                  [disabled]="!util.hasPermission(util.Permission.CAN_LOCK_DEAL_SYNDICATION)"
                  [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_LOCK_DEAL_SYNDICATION) ? util.NoAccessCursor: ''}"
                  [matTooltip]="!util.hasPermission(util.Permission.CAN_LOCK_DEAL_SYNDICATION) ? util.NoAccessMsg : ''"
                  matTooltipPosition="left"
          >
            Lock Syndication Pool
          </button>
        </div>
      </div>
      <!--      -->
      <!--      -->
      <!--     ADMIN PANEL START-->
      <!--      -->
      <!--      -->
      <div class="row justify-content-center" *ngIf="syndication?.showDealSignatoriesSection">
        <div class="col-11" style="padding-top:25px;">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span style="font-weight: bold;">Issuer Signatories</span>
                </mat-panel-title>
                <mat-panel-description>
                  <span style="font-weight: lighter;">{{ currentSignatoriesStr }}</span>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <!-- ROW START -->
              <form [formGroup]="form" (submit)="submit()">
                <div class="row" style="padding: 15px 12px;" *ngIf="!!businessUnitEntities">
                  <div class="col-8">
                    <mat-form-field class="form-field" appearance="outline">
                      <mat-label>Entity to Invest</mat-label>
                      <mat-select id="entInv" [formControlName]="FORM_INV_ENT" required
                                  (selectionChange)="changeEntity($event)">
                        <mat-option *ngFor="let entity of businessUnitEntities" [value]="entity">
                          {{ entity.entityName }}
                        </mat-option>
                      </mat-select>
                      <mat-hint>Please select the Entity through which you will to invest.</mat-hint>
                    </mat-form-field>
                    <mat-error>{{ getErrorMsg() }}</mat-error>
                  </div>
                </div>
                <!-- ROW START -->
                <div class="row" style="padding: 15px 12px;">
                  <div class="col-12">
                    <mat-label>SIGNATORIES</mat-label>
                    <br/>
                    <mat-hint>The list of signatories below are extracted from the KYC signatories liked to the
                      Investing Entity
                      selected above.
                    </mat-hint>
                  </div>
                </div>
                <!-- ROW START -->
                <div class="row" style="padding: 15px 12px;">
                  <div class="col-8">
                    <mat-form-field class="form-field" appearance="outline" *ngIf="!!businessUnitEntities">
                      <mat-label>Signatories</mat-label>
                      <mat-select id="sigs" [formControlName]="FORM_SELECTED_SIG" required
                                  (selectionChange)="changeSelectedSignatory($event)">
                        <mat-option *ngFor="let signatory of getKycSignatoriesFromSelectedEntity()" [value]="signatory">
                          {{ signatory.name }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="getSignatories().length == 0">
                        You must add at least one signatory to the Sub Doc before initiation
                      </mat-error>
                      <mat-hint>If there is anyone you would like to add who is not on the list of the KYC signatories,
                        <a (click)="addNewSignatory()"
                           [ngStyle]="{color: isAddDisabled()? 'grey': 'blue', cursor: isAddDisabled()? 'not-allowed': 'pointer'}"
                        >
                          click here
                        </a> to add them to the
                        Sub Doc.
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col-4">
                    <div style="padding-top: 7px;">
                      <button mat-raised-button type="button" color="primary"
                              (click)="addSelectedSignatory()"
                              [disabled]="isAddDisabled() || !form.get(FORM_SELECTED_SIG)?.value"
                              [ngStyle]="{cursor: isAddDisabled() ? 'not-allowed': 'pointer'}"
                              [matTooltip]="isAddDisabled() ? getDisabledMsg() : ''"
                              matTooltipPosition="left"
                      >
                        ADD SIGNATORY TO SUBDOC
                      </button>
                    </div>
                  </div>
                </div>

                <!-- ROW START -->
                <div class="row" style="padding: 15px 12px;" *ngIf="!!form.get(FORM_SIGNATORIES)?.value">
                  <div class="col-12" *ngIf="getSignatories().length > 0">
                    <table class="table table-striped form-table">
                      <thead>
                      <tr>
                        <th class="form-table-header" colspan="4">Subscription Docs Users (Order of Signatories is
                          Important)
                        </th>
                      </tr>
                      <tr>
                        <th class="form-table-header">User Access/ Signatories</th>
                        <th class="form-table-header">Email Address</th>
                        <!--              <th class="form-table-header">Access Type</th>-->
                        <th class="form-table-header">Signing Order</th>
                        <th class="form-table-header">Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let signatory of form.get(FORM_SIGNATORIES)?.value; index as i;">
                        <td style="padding-left: 5px; line-height: 36px;">
                          <div *ngIf="!form.get(FORM_SIGNATORIES)?.value[i].external">
                            <span>{{ form.get(FORM_SIGNATORIES)?.value[i].fullName }}</span></div>
                          <div *ngIf="form.get(FORM_SIGNATORIES)?.value[i].external">
                            <input matInput id="sigName"
                                   type="text"
                                   placeholder="John Smith"
                                   [(ngModel)]="form.get(FORM_SIGNATORIES)?.value[i].fullName"
                                   [ngModelOptions]="{standalone: true}"
                            />
                          </div>
                          <div *ngIf="!form.get(FORM_SIGNATORIES)?.value[i].external"
                               style="margin-top:-15px; font-size: x-small; font-weight: lighter;">
                            <em>KYC Approved Signatory</em>
                          </div>
                          <div *ngIf="form.get(FORM_SIGNATORIES)?.value[i].external"
                               style="margin-top:-15px; font-size: x-small; font-weight: lighter;">
                            <em style="color: red;">Signatory not in Approved KYC</em>
                          </div>
                        </td>
                        <td style="line-height: 36px; padding-top: 16px;">
                          <input matInput id="sigEmail"
                                 type="text"
                                 placeholder="example@rondeivu.com"
                                 [(ngModel)]="form.get(FORM_SIGNATORIES)?.value[i].email"
                                 [ngModelOptions]="{standalone: true}"
                          />
                        </td>
                        <td style="line-height: 36px; padding-top: 16px;">
                          {{ i + 1 }}
                        </td>
                        <td class="text-center">
                          <button mat-icon-button type="button">
                            <mat-icon color="warn"
                                      (click)="removeSignatory(i)"
                                      matTooltip="Remove Signatory"
                                      matTooltipPosition="left"
                            >delete
                            </mat-icon>
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!--                ROW START-->
                <div class="row">
                  <div class="col-12 text-end">
                    <div>
                      <button mat-raised-button type="submit" color="primary">
                        SAVE
                      </button>
                    </div>
                  </div>
                </div>


              </form>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
      <!--      -->
      <!--      -->
      <!--      ADMIN PANEL END-->
      <!--      -->
      <!--      -->


      <!--      -->
      <!--      -->
      <!--     CARDS START-->
      <!--      -->
      <!--      -->
      <div class="row justify-content-center" style="padding-top: 15px;">
        <div class="col-11 col-md-3 pool" *ngIf="util.isType('investor')">
          <span class="title">Total Deal Indicated Interest</span><br/><br/>
          <span class="value">{{ syndication?.percentCommitted || 0 }}%</span>
        </div>
        <div class="col-11 col-md-3 pool" *ngIf="util.isType('investor')">
          <span class="title">Amount Remaining</span><br/><br/>
          <span class="value">{{ syndication?.amountRemaining || 0 | currency: 'USD':true:'2.0' }} Million USD</span>
        </div>
        <div class="col-11 col-md-3 pool" *ngIf="!util.isType('investor')">
          <span class="title">Total Indication of Interest</span><br/><br/>
          <span class="value">{{ syndication?.totalIndicationsOfInterest || 0 | currency: 'USD':true:'2.0' }} Million USD</span>
        </div>
        <div class="col-11 col-md-3 pool" *ngIf="!util.isType('investor')">
          <span class="title">Total Allocation</span><br/><br/>
          <span class="value">{{ syndication?.totalAllocation || 0 | currency: 'USD':true:'2.0' }} Million USD</span>
        </div>
        <div class="col-11 col-md-3 pool">
          <span class="title">Fundraise Size on Rondeivu</span><br/><br/>
          <span class="value">{{ syndication?.totalFundraiseSize || 0 | currency: 'USD':true:'2.0' }} Million USD</span>
        </div>
      </div>
      <br/>
      <!--      -->
      <!--      -->
      <!--     CARDS END -->
      <!--      -->
      <!--      -->

      <!--      -->
      <!--      -->
      <!--     TABLE START-->
      <!--      -->
      <!--      -->
      <div class="row justify-content-center d-none d-md-flex" style="padding-bottom: 150px; padding-top: 15px;">
        <div class="col-11">
          <div class="table-container">
            <div class="row table-inner-container">
              <div class="table-inner-inner-container">
                <!--                -->
                <!-- SUBSCRIPTION TABLE START-->
                <!--                -->
                <table class="subscription-table"
                       mat-table [dataSource]="dataSource"
                       [multiTemplateDataRows]="true" matSort>
                  <ng-container matColumnDef="table-name">
                    <th colspan="8" mat-header-cell *matHeaderCellDef>
                      <span style="font-size: large;">Subscriptions</span>
                    </th>
                  </ng-container>
                  <!-- Investor -->
                  <ng-container matColumnDef="businessUnit">
                    <th mat-header-cell *matHeaderCellDef> Investor</th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px;">
                      <app-user-card [firstName]="element.investor?.['displayName']"
                                     [displayName]="element.investor?.['profile']"
                                     [borderColor]="'transparent'"
                                     [sideColor]="'#ff0000'"
                                     [image]="element.investor?.['imageUrl']"
                      ></app-user-card>
                    </td>
                  </ng-container>
                  <!-- IOI -->
                  <ng-container matColumnDef="ioi">
                    <th mat-header-cell *matHeaderCellDef> IoI Amount (USD)</th>
                    <td mat-cell *matCellDef="let element">
                      <div *ngIf="!util.isType('admin')">
                        <span style="font-weight: bold; color: #0a53be">
                          {{ element.indicationAmount | currency: 'USD':true:'2.0' }}
                        </span>
                      </div>
                      <div *ngIf="util.isType('admin')">
                        <div *ngIf="!element.editIndicationAmount">
                          <span style="font-weight: bold; line-height: 40px; height:42px; color: #0a53be">
                            {{ element.indicationAmount | currency: 'USD':true:'2.0' }}
                          </span>
                          <button mat-icon-button
                                  type="button"
                                  (click)="element.editIndicationAmount = true;"
                                  matTooltip="Edit IoI Amount"
                                  matTooltipPosition="above"
                          >
                            <mat-icon>edit</mat-icon>
                          </button>
                        </div>
                        <div *ngIf="element.editIndicationAmount"
                             style="padding-top: 20px; padding-right: 25px;">
                          <mat-form-field floatLabel="never" appearance="outline">
                            <input matInput
                                   type="number"
                                   placeholder="i.e. 10"
                                   [(ngModel)]="element.indicationAmount"
                                   [ngModelOptions]="{standalone: true}"
                                   [disabled]="syndication.issuerPoolIsLocked"
                                   (change)="element.editIndicationAmount = false; promptSave(element);"
                            />
                            <mat-icon color="warn"
                                      style="cursor: pointer;" matSuffix
                                      (click)="element.editIndicationAmount = false;">cancel
                            </mat-icon>
                          </mat-form-field>
                        </div>
                      </div>
                    </td>
                  </ng-container>
                  <!-- Allocation -->
                  <ng-container matColumnDef="allocation">
                    <th mat-header-cell *matHeaderCellDef> Allocation (USD)</th>
                    <td mat-cell *matCellDef="let element">
                      <div *ngIf="util.isType('investor')">
                        <span style="font-weight: bold; color: #986801">
                          {{ element.allocationAmount | currency: 'USD':true:'2.0' }}
                        </span>
                      </div>
                      <div *ngIf="!util.isType('investor')">
                        <div *ngIf="!element.editAllocationAmount">
                          <span style="font-weight: bold; line-height: 40px; height:42px; color: #986801">
                            {{ element.allocationAmount | currency: 'USD':true:'2.0' }}
                          </span>
                          <button mat-icon-button
                                  type="button"
                                  (click)="element.editAllocationAmount = true;"
                                  matTooltip="Edit Allocation"
                                  matTooltipPosition="above"
                          >
                            <mat-icon>edit</mat-icon>
                          </button>
                        </div>
                        <div *ngIf="element.editAllocationAmount"
                             style="padding-top: 20px; padding-right: 25px;">
                          <mat-form-field floatLabel="never" appearance="outline">
                            <input matInput
                                   type="number"
                                   placeholder="i.e. 5"
                                   [(ngModel)]="element.allocationAmount"
                                   [ngModelOptions]="{standalone: true}"
                                   [disabled]="syndication.issuerPoolIsLocked"
                                   (change)="element.editAllocationAmount = false; promptSave(element);"
                            />
                            <mat-icon
                              color="warn"
                              style="cursor: pointer;"
                              matSuffix
                              (click)="element.editAllocationAmount = false;"
                            >
                              cancel
                            </mat-icon>
                          </mat-form-field>
                        </div>
                      </div>
                    </td>
                  </ng-container>
                  <!-- Restrictions -->
                  <ng-container matColumnDef="restrictionType">
                    <th mat-header-cell *matHeaderCellDef>Restriction</th>
                    <td mat-cell *matCellDef="let element">
                      {{ getRestrictionType(element.restrictionType) }}
                    </td>
                  </ng-container>
                  <!-- Notes -->
                  <ng-container matColumnDef="notes">
                    <th mat-header-cell *matHeaderCellDef> Notes</th>
                    <td mat-cell *matCellDef="let element">
                      <button mat-icon-button
                              *ngIf="!!element.notes"
                              (click)="showNotes(element.notes)"
                              matTooltip="Show Notes"
                              matTooltipPosition="above"
                      >
                        <mat-icon color="primary">description</mat-icon>
                      </button>
                    </td>
                  </ng-container>
                  <!-- Subscription Status -->
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> Status</th>
                    <td mat-cell *matCellDef="let element">
                      {{ getStatusString(element) }}
                    </td>
                  </ng-container>
                  <!-- Subscription Created -->
                  <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> Created</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.created | date }}
                    </td>
                  </ng-container>
                  <!-- Subscription Actions -->
                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> Actions</th>
                    <td mat-cell *matCellDef="let element">
                      <div style="display: flex;">
                        <button mat-raised-button
                                color="primary"
                                *ngIf="element.showInitiateSubDocsButton"
                                (click)="openSubdocModal(element)"
                                [disabled]="syndication?.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_ADD_DEAL_SUBDOCS)"
                                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_DEAL_SUBDOCS) ? util.NoAccessCursor: ''}"
                                [matTooltip]="!util.hasPermission(util.Permission.CAN_ADD_DEAL_SUBDOCS) ? util.NoAccessMsg : ''"
                                matTooltipPosition="above"
                        >
                          Initiate Sub Doc
                        </button> &nbsp;
                        <button mat-icon-button
                                (click)="save(element)"
                                color="primary"
                                *ngIf="element.showSaveDraftButton"
                                [disabled]="syndication?.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_EDIT_DEAL_SYNDICATION)"
                                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SYNDICATION) ? util.NoAccessCursor: ''}"
                                [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SYNDICATION) ? util.NoAccessMsg : 'Save'"
                                matTooltipPosition="above"
                        >
                          <mat-icon>save</mat-icon>
                        </button> &nbsp;
                        <button mat-icon-button
                                color="warn"
                                (click)="delete(element)"
                                [disabled]="syndication?.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_DELETE_DEAL_SYNDICATION)"
                                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_DELETE_DEAL_SYNDICATION) ? util.NoAccessCursor: ''}"
                                [matTooltip]="!util.hasPermission(util.Permission.CAN_DELETE_DEAL_SYNDICATION) ? util.NoAccessMsg : 'Delete'"
                                matTooltipPosition="above"
                                *ngIf="util.isType('admin')">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </td>
                  </ng-container>
                  <!-- expand-->
                  <ng-container matColumnDef="expand">
                    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                    <td mat-cell *matCellDef="let element">
                      <button mat-icon-button
                              aria-label="expand row"
                              (click)="expandRow(element);"
                              *ngIf="element.subDocs?.length > 0"
                      >
                        <mat-icon *ngIf="element.expanded" [matBadge]="element.subDocs.length" matBadgeColor="accent">
                          keyboard_arrow_up
                        </mat-icon>
                        <mat-icon *ngIf="!element.expanded" [matBadge]="element.subDocs.length" matBadgeColor="accent">
                          keyboard_arrow_down
                        </mat-icon>
                      </button>
                    </td>
                  </ng-container>
                  <!-- Expanded Content Column -->
                  <ng-container matColumnDef="expandedDetail">
                    <td mat-cell
                        class="expanded-detail-row"
                        *matCellDef="let element"
                        [attr.colspan]="matchesColsToDisplayWithExpand.length">
                      <div class="element-detail"
                           [@detailExpand]="element.expanded ? 'expanded' : 'collapsed'">
                        <!--                        -->
                        <!--   SUBDOC TABLE START   -->
                        <!--                        -->
                        <!--                        -->
                        <table class="subdoc-table"
                               mat-table
                               [dataSource]="element.subDocs"
                               [multiTemplateDataRows]="true"
                               matSort
                        >
                          <ng-container matColumnDef="table-name">
                            <th colspan="6" mat-header-cell *matHeaderCellDef>
                              <span style="font-size: large;">Sub Docs</span>
                            </th>
                          </ng-container>
                          <!-- ENTITY -->
                          <ng-container matColumnDef="entity">
                            <th mat-header-cell *matHeaderCellDef> Entity</th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px;">
                              <app-user-card [firstName]="element.entityName"
                                             [displayName]="element.entityType"
                                             [borderColor]="'transparent'"
                                             [sideColor]="'#004fe1'"
                              ></app-user-card>
                            </td>
                          </ng-container>
                          <!-- AMOUNT -->
                          <ng-container matColumnDef="amount">
                            <th mat-header-cell *matHeaderCellDef> Amount (USD)</th>
                            <td mat-cell *matCellDef="let element">
                              <div>
                                <span
                                  style="font-weight: bold; color: #986801">
                                  {{ element.amount | currency: 'USD':true:'2.0' }}
                                </span>
                              </div>
                            </td>
                          </ng-container>
                          <!-- SUBDOC STATUS -->
                          <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef> Subdoc Status</th>
                            <td mat-cell *matCellDef="let element">
                              {{ getSubdocStatusString(element) }}
                            </td>
                          </ng-container>
                          <!-- DATE OF SUBSCRIPTION -->
                          <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef> Date of Subscription</th>
                            <td mat-cell *matCellDef="let element">
                              {{ element.subscriptionDate | date }}
                            </td>
                          </ng-container>
                          <!-- SUBDOC CREATED -->
                          <ng-container matColumnDef="created">
                            <th mat-header-cell *matHeaderCellDef> Sub Doc Created</th>
                            <td mat-cell *matCellDef="let element">
                              {{ element.created | date }}
                            </td>
                          </ng-container>
                          <!-- ACTIONS -->
                          <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef> Actions</th>
                            <td mat-cell *matCellDef="let element">
                              <div style="display: flex;">
                                <button mat-raised-button
                                        color="primary"
                                        (click)="fillSubdoc(element)"
                                        *ngIf="element.showInvestorFillDataAndApproveButton"
                                        [disabled]="syndication?.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBDOCS_FILL_INVESTOR)"
                                        [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBDOCS_FILL_INVESTOR) ? util.NoAccessCursor: ''}"
                                        [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBDOCS_FILL_INVESTOR) ? util.NoAccessMsg : 'Edit Sub Doc'"
                                        matTooltipPosition="above"
                                >
                                  Review & Approve Sub Doc Data
                                </button> &nbsp;
                                <button mat-raised-button
                                        color="primary"
                                        (click)="approveInvestorSubdoc(element)"
                                        *ngIf="element.showRondeivuApprovalButton"
                                        [disabled]="syndication?.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBDOCS_APPROVE_INVESTOR)"
                                        [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBDOCS_APPROVE_INVESTOR) ? util.NoAccessCursor: ''}"
                                        [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBDOCS_APPROVE_INVESTOR) ? util.NoAccessMsg : 'Edit Sub Doc'"
                                        matTooltipPosition="above"
                                >
                                  Send to Investor for Review
                                </button> &nbsp;
                                <button mat-raised-button
                                        color="primary"
                                        *ngIf="element.showIssuerApprovalButton"
                                        (click)="approveIssuerSubdoc(element)"
                                        [disabled]="syndication?.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBDOCS_APPROVE_ISSUER)"
                                        [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBDOCS_APPROVE_ISSUER) ? util.NoAccessCursor: ''}"
                                        [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBDOCS_APPROVE_ISSUER) ? util.NoAccessMsg : 'Edit Sub Doc'"
                                        matTooltipPosition="above"
                                >
                                  Approve & Send for Signatures
                                </button> &nbsp;
                                <button mat-icon-button
                                        (click)="editSubdoc(element)"
                                        [disabled]="syndication?.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_ACCESS_DEAL_SYNDICATION)"
                                        [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_SYNDICATION) ? util.NoAccessCursor: ''}"
                                        [matTooltip]="!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_SYNDICATION) ? util.NoAccessMsg : 'Edit Sub Doc'"
                                        matTooltipPosition="above"
                                >
                                  <mat-icon>edit</mat-icon>
                                </button> &nbsp;
                                <button mat-icon-button
                                        (click)="downloadSubdoc(element)"
                                        [disabled]="syndication?.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_ACCESS_DEAL_SUBDOCS_PDF)"
                                        [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_SUBDOCS_PDF) ? util.NoAccessCursor: ''}"
                                        [matTooltip]="!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_SUBDOCS_PDF) ? util.NoAccessMsg : 'Download Sub Doc'"
                                        matTooltipPosition="above"
                                >
                                  <mat-icon>download</mat-icon>
                                </button> &nbsp;
                                <button mat-icon-button
                                        color="warn"
                                        (click)="deleteSubdoc(element)"
                                        [disabled]="syndication?.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_DELETE_DEAL_SUBDOCS)"
                                        [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_DELETE_DEAL_SUBDOCS) ? util.NoAccessCursor: ''}"
                                        [matTooltip]="!util.hasPermission(util.Permission.CAN_DELETE_DEAL_SUBDOCS) ? util.NoAccessMsg : 'Delete Sub Doc'"
                                        matTooltipPosition="above"
                                >
                                  <mat-icon>delete</mat-icon>
                                </button>
                              </div>
                            </td>
                          </ng-container>
                          <!-- Row Definitions -->
                          <tr mat-header-row *matHeaderRowDef="['table-name'];"></tr>
                          <tr mat-header-row *matHeaderRowDef="subdocCols; sticky:true;"></tr>
                          <tr mat-row class="subdoc-row" *matRowDef="let element; columns: subdocCols;"></tr>
                        </table>
                        <!--                        -->
                        <!--                        -->
                        <!--  SUBDOC TABLE END      -->
                        <!--                        -->
                        <!--                        -->
                      </div>
                    </td>
                  </ng-container>
                  <!-- Row Definitions -->
                  <tr mat-header-row *matHeaderRowDef="['table-name'];"></tr>
                  <tr mat-header-row *matHeaderRowDef="matchesColsToDisplayWithExpand; sticky:true;"></tr>
                  <tr mat-row *matRowDef="let element; columns: matchesColsToDisplayWithExpand;" class="element-row">
                  </tr>
                  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="subscription-detail-row"></tr>
                  <!--                  <tr mat-row *matRowDef="let element; columns: matchesColsToDisplay;"></tr>-->
                </table>
                <!--                -->
                <!-- SUBSCRIPTION TABLE START-->
                <!--                -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      -->
      <!--      -->
      <!--     TABLE END-->
      <!--      -->
      <!--      -->

      <!--                -->
      <!-- LIST VIEW START-->
      <!--                -->
      <div class="row justify-content-center d-flex d-md-none" style="padding-bottom: 150px; padding-top: 15px;">
        <div class="col-12">
          <ul style="list-style-type: none; padding: 25px 0 0 0;">
            <li *ngFor="let element of dataSource.data"
                style="margin-bottom: 5px; display: flex; justify-content: space-between;">
              <app-user-card [firstName]="element.investor?.['displayName']"
                             [displayName]="element.investor?.['profile']"
                             [borderColor]="'transparent'"
                             [sideColor]="'#ff0000'"
                             [image]="element.investor?.['imageUrl']"
              ></app-user-card>
              <div style="display: flex;">
                <div>
                  <span style="font-weight: bold; color: #0a53be;line-height: 42px;">
                    {{ element.indicationAmount | currency: 'USD':true:'2.0' }}
                  </span>
                </div>&nbsp;
                <div>
                  <span style="font-weight: bold; color: #986801; line-height: 42px;">
                    {{ element.allocationAmount | currency: 'USD':true:'2.0' }}
                  </span>
                </div>&nbsp;
                <!--                <button mat-icon-button-->
                <!--                        color="primary"-->
                <!--                        (click)="save(element)"-->
                <!--                        *ngIf="!util.isType('investor')"-->
                <!--                        [disabled]="syndication?.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_EDIT_DEAL_SYNDICATION)"-->
                <!--                        [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SYNDICATION) ? util.NoAccessCursor: ''}"-->
                <!--                        [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SYNDICATION) ? util.NoAccessMsg : 'Save'"-->
                <!--                        matTooltipPosition="left"-->
                <!--                >-->
                <!--                  <mat-icon>save</mat-icon>-->
                <!--                </button> &nbsp;-->
                <button mat-icon-button
                        (click)="delete(element)"
                        [disabled]="syndication?.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_DELETE_DEAL_SYNDICATION)"
                        [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_DELETE_DEAL_SYNDICATION) ? util.NoAccessCursor: ''}"
                        [matTooltip]="!util.hasPermission(util.Permission.CAN_DELETE_DEAL_SYNDICATION) ? util.NoAccessMsg : 'Delete'"
                        matTooltipPosition="left"
                        *ngIf="util.isType('admin')">
                  <mat-icon color="warn">delete</mat-icon>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--                -->
    <!-- LIST VIEW END  -->
    <!--                -->
  </div>
</div>
