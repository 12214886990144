import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {IDealMatchData} from "../../../models";

@Component({
  selector: 'app-deal-matching-modal',
  templateUrl: './deal-matching-modal.component.html',
  styleUrls: ['./deal-matching-modal.component.scss']
})
export class DealMatchingModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DealMatchingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDealMatchData,
  ) {
    console.log("Investor Match Dialog", data)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  action(data: IDealMatchData): void {
    data.save = true;
    this.dialogRef.close(data);
  }

  ngOnInit(): void {

  }

}
