<div class="rm-header">
  <span>{{ data.isEditing ? 'Edit' : 'Add' }} User</span>
</div>

<form [formGroup]="userForm" (ngSubmit)="submit()">
  <mat-dialog-content>
    <div class="row justify-content-center" style="padding-bottom: 20px; padding-top: 25px;">
      <!--      row-->
      <div class="col-11">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>First Name</mat-label>
          <input id="fn" matInput type="text" formControlName="firstName"/>
        </mat-form-field>
      </div>
      <!--      row-->
      <div class="col-11">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Last Name</mat-label>
          <input id="ln" matInput type="text" formControlName="lastName"/>
        </mat-form-field>
      </div>
      <!--      row-->
      <div class="col-11">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Email</mat-label>
          <input id="email" matInput type="text" formControlName="email"/>
        </mat-form-field>
      </div>
      <!--      row-->
      <div class="col-5">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Country Code</mat-label>
          <mat-select id="cc" formControlName="countryCode">
            <mat-option *ngFor="let cc of lookups.countryCodes" [value]="cc.key">
              {{ cc.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Phone Number</mat-label>
          <input id="mobile" matInput type="phone" formControlName="mobile"/>
        </mat-form-field>
      </div>
      <!--      row-->
      <div class="col-11">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Temporary Password</mat-label>
          <input id="password" matInput [type]="!showPassword ? 'password' : 'text'" formControlName="tempPassword"
                 (keydown.enter)="submit();"/>
          <button mat-icon-button matSuffix (click)="showPassword = !showPassword" type="button"
                  [attr.aria-label]="'Hide password'" [attr.aria-pressed]="!showPassword">
            <mat-icon>{{ !showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-error>Password must be a combination of lower-case, upper-case, numbers, special characters, and at
            least 8 characters long.
          </mat-error>
        </mat-form-field>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions class="row justify-content-center">
    <div class="col-6 text-start">
      <button mat-raised-button type="button" (click)="dialogRef.close()">Cancel</button>
    </div>
    <div class="col-6 text-end">
      <button mat-raised-button color="primary" type="submit" [disabled]="userForm.invalid">SUBMIT</button>
    </div>
  </mat-dialog-actions>
</form>

