import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of, tap } from 'rxjs';
import { UserSettingsService } from '../modules/settings/services';
import { updateUserSettings, UserSettings } from '../modules/redux';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsResolver implements Resolve<UserSettings> {

  constructor(private userSettingsService: UserSettingsService, private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserSettings> {
    return this.userSettingsService.getUserSettings().pipe(tap(userSettings => {
      this.store.dispatch(updateUserSettings({user: userSettings}));
    }));
  }
}
