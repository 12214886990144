import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {
  Config,
  getConfig,
  getDealSyndication,
  getLookups,
  getSelectedBusinessUnit,
  getSelectedDeal,
  initialConfigState,
  initialLookupsState,
  Lookups,
  toggleLoading, updateDealSyndication
} from "../../../redux";
import {IDealCard} from "../../../shared/models";
import {IBusinessUnit} from "../../../business-units/models";
import {DealService, SyndicationService} from "../../services";
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {LockInModalComponent} from "../lock-in-modal/lock-in-modal.component";
import {NotesModalComponent} from "../notes-modal/notes-modal.component";
import {
  ConfirmationModalComponent,
  DeleteConfirmationModalComponent, PromptModalComponent
} from "../../../shared/components";
import {ILookup} from "../../../../models";
import {UtilService} from "../../../shared/services";
import {ModalSize} from "../../../shared/services/util/util.service";
import {IDealSyndication} from "../../models";
import {
  IEntityDTO,
  ISignatoryDTO,
  ISubdocAccessPerson,
  ISyndicationItem,
  ISyndicationSubdoc
} from "../../models/syndication";
import {SubdocService} from "../../services/subdoc/subdoc.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {
  DealSubscriptionSubdocEditModalComponent,
  DealSubscriptionSubdocFormModalComponent,
  DealSubscriptionSubdocModalComponent
} from "../index";
import {FormControl, FormGroup} from "@angular/forms";
import {UuidService} from "../../../../services";


@Component({
  selector: 'app-syndication',
  templateUrl: './syndication.component.html',
  styleUrls: ['./syndication.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class SyndicationComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  selectedDeal$: Subscription = new Subscription();
  selectedDeal: IDealCard = {} as unknown as IDealCard;
  syndicationItem$: Subscription = new Subscription();

  dataSource: MatTableDataSource<ISyndicationItem> = new MatTableDataSource();
  matchesColsToDisplay = ['businessUnit', 'ioi', 'allocation', 'notes', 'status', 'date', 'actions'];
  matchesColsToDisplayWithExpand = [...this.matchesColsToDisplay, 'expand'];
  subdocCols = ['entity', 'amount', 'status', 'date', 'created', 'actions'];
  expandedElement: ISyndicationItem = {} as unknown as ISyndicationItem;
  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;

  syndication: IDealSyndication = {} as unknown as IDealSyndication;
  reason: string = '';
  numbers = Array(11).fill(11).map((x, i) => i);

  selectedSubdocs: ISyndicationSubdoc[] = [];
  businessUnitEntities: IEntityDTO[] = [];
  dealSignatories: any[] = [];

  currentSignatoriesStr = '';

  bu: IBusinessUnit = {} as unknown as IBusinessUnit;
  dealId = '';

  form = new FormGroup({
    investingEntity: new FormControl(),
    selectedSignatory: new FormControl(),
    subdocSignatories: new FormControl(),
  });

  protected readonly FORM_INV_ENT = 'investingEntity';
  protected readonly FORM_SELECTED_SIG = 'selectedSignatory';
  protected readonly FORM_SIGNATORIES = 'subdocSignatories';

  constructor(
    private dealService: DealService,
    private syndicationService: SyndicationService,
    private subDocService: SubdocService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private toastrService: ToastrService,
    public dialog: MatDialog,
    public util: UtilService,
    private uuid: UuidService
  ) {
    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.bu = Object.assign({}, bu);
    });

    this.selectedDeal$ = this.store.select(getSelectedDeal).subscribe((deal: IDealCard) => {
      if (this.selectedDeal.id != deal.id) {
        this.selectedDeal = deal;
        this.subscribeToDatasource();
      } else {
        this.selectedDeal = deal;
      }
    });

    this.syndicationItem$ = this.store.select(getDealSyndication).subscribe((dealSyndication: IDealSyndication) => {
      this.syndication = dealSyndication;
      this.dataSource = new MatTableDataSource(this.syndication.syndicationItems);
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedDeal$.unsubscribe();
    this.syndicationItem$.unsubscribe();
  }

  // ISSUER SIGNATORIES START
  changeEntity(event: any) {
    this.form.get(this.FORM_INV_ENT)?.setValue(event.value);
    this.form.get(this.FORM_SELECTED_SIG)?.setValue(null);
    this.form.get(this.FORM_SIGNATORIES)?.setValue([]);
  }

  changeSelectedSignatory(event: any) {
    this.form.get(this.FORM_SELECTED_SIG)?.setValue(event.value);
  }

  getKycSignatoriesFromSelectedEntity(): any[] {
    return this.getSelectedEntity()?.kycSignatories || [];
  }

  getSelectedEntity() {
    return this.form.get(this.FORM_INV_ENT)?.value || {} as unknown as IEntityDTO;
  }

  getErrorMsg() {
    return this.getSelectedEntity().kycErrorMessage;
  }

  getSignatories() {
    return this.form.get(this.FORM_SIGNATORIES)?.value || [];
  }

  isAddDisabled() {
    return this.getSignatories().length >= this.syndication.numberOfIssuerSubDocSignatories;
  }

  getDisabledMsg() {
    return this.syndication.numberOfIssuerSubDocSignatories == 1 ? 'Maximum of one (1) Signatory' : 'Maximum of two (2) Signatories';
  }

  addNewSignatory() {
    if (!this.isAddDisabled()) {
      let signatories: any[] = this.form.get(this.FORM_SIGNATORIES)?.value || [];
      signatories?.push({
        id: this.uuid.generateUUID(),
        fullName: '',
        email: '',
        accessType: null,
        external: true
      });
      this.form.get(this.FORM_SIGNATORIES)?.setValue(signatories);
    }
  }

  addSelectedSignatory() {
    if (!this.isAddDisabled()) {
      let selected = this.form.get(this.FORM_SELECTED_SIG)?.value || {} as unknown as ISignatoryDTO;
      let signatories: any[] = this.form.get(this.FORM_SIGNATORIES)?.value || [];
      signatories?.push({
        id: selected.businessUnitEntityKycAuthorizedSignatoryId,
        fullName: selected.name,
        email: '',
        accessType: null,
        external: false
      });
      this.form.get(this.FORM_SIGNATORIES)?.setValue(signatories);
    }
  }

  removeSignatory(idx: number) {
    let arr = this.form.get(this.FORM_SIGNATORIES)?.value;
    arr.splice(idx, 1);
    this.form.get(this.FORM_SIGNATORIES)?.setValue(arr);
  }

  getEntitiesForBusinessUnit(businessUnitId: string) {
    console.log("Fetching Entities...");
    this.store.dispatch(toggleLoading({loading: true}));
    this.subDocService.getSubdocEntities(businessUnitId).subscribe({
      next: (entities: IEntityDTO[]) => {
        this.businessUnitEntities = entities;
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: err => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.error("Unable to get entities!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  getDealSignatories() {
    console.log("Fetching Deal Signatories...");
    this.store.dispatch(toggleLoading({loading: true}));
    this.dealService.getDealSignatories().subscribe({
      next: (signatories: any) => {
        this.dealSignatories = Object.assign(signatories);
        this.setCurrentSignatoriesString(this.dealSignatories);
        this.form.get(this.FORM_SIGNATORIES)?.setValue(signatories);
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: err => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.error("Unable to get deal signatories!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  saveDealSignatories() {
    const payload = this.form.get(this.FORM_SIGNATORIES)?.value || [];
    console.log("Updating Deal Signatories...");
    this.store.dispatch(toggleLoading({loading: true}));
    this.dealService.updateDealSignatories(payload).subscribe({
      next: (signatories: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.success("Deal Signatories Updated!", $localize`:@@companyName:Rondeivu`);
      }, error: err => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.error("Unable to update deal signatories!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  /**
   * The form submit
   */
  submit() {
    this.saveDealSignatories();
  }

  private setCurrentSignatoriesString(signatories: any) {
    this.currentSignatoriesStr = 'Current Signatories: ';
    for (let i = 0; i <= signatories.length - 1; i++) {
      this.currentSignatoriesStr += signatories[i].fullName + (i == signatories.length - 1 ? '' : ', ');
    }
  }

  // ISSUER SIGNATORIES END

  /**
   * Get display value of the restriction type
   * @param rtKey
   */
  getRestrictionType(rtKey: string) {
    let rt = '';
    this.lookups.indicationOnInterestRestrictionTypes.forEach((l: ILookup) => {
      if (l.key === rtKey) {
        rt = l.value;
      }
    });
    return rt;
  }

  subscribeToDatasource(): void {
    this.getSyndication();
    this.getDealSignatories();
  }

  getSyndication() {
    console.log("Fetching Syndication...");
    this.store.dispatch(toggleLoading({loading: true}));
    this.syndicationService.getSyndication().subscribe({
      next: (syndication: any) => {
        this.syndication = syndication;
        // admin - get the businessUnitId from the syndication item
        let businessUnitId = this.util.isType('admin') ? this.syndication.issuerBusinessUnitId : this.config.selected_business_unit.businessUnitId;
        this.getEntitiesForBusinessUnit(businessUnitId);
        this.store.dispatch(updateDealSyndication({dealSyndication: syndication}));
        this.dataSource = new MatTableDataSource(this.syndication.syndicationItems);
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: err => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.error("Unable to get syndication!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  changeLockStatus(locked: boolean, silent: boolean) {
    let payload = {
      isLocked: locked,
      silentPoolLockChange: silent
    };
    this.store.dispatch(toggleLoading({loading: true}));
    this.syndicationService.changeLockStatus(payload).subscribe({
      next: (res: any) => {
        // this.matchesSource = new MatTableDataSource(this.syndication.syndicationItems);
        this.syndication.issuerPoolIsLocked = locked;
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.success(locked ? "Pool Locked!" : "Pool Unlocked!", $localize`:@@companyName:Rondeivu`);
      }, error: err => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.error("Unable to change lock status!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  lock(element: any) {
    const lockRef = this.dialog.open(LockInModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      maxWidth: '800px',
      data: {},
      disableClose: true
    });

    lockRef.afterClosed().subscribe(result => {
      // console.log("Dialog output:", result);
      if (!!result) {
        let silent = !result.notify;
        this.changeLockStatus(true, silent);
      }
    });
  }

  unlock(element: any) {
    this.changeLockStatus(false, true);
  }

  save(element: any) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.syndicationService.updateSyndication(element).subscribe({
      next: (res: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.success("Syndication Updated!", $localize`:@@companyName:Rondeivu`);
      }, error: err => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.error("Unable to update syndication!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  delete(element: any) {
    const delRef = this.dialog.open(DeleteConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      data: element,
      disableClose: true
    });

    delRef.afterClosed().subscribe(result => {
      // console.log("Dialog output:", result);
      if (!!result) {
        this.store.dispatch(toggleLoading({loading: true}));
        this.syndicationService.deleteSyndication(result).subscribe({
          next: (res: any) => {
            //replace in list
            if (!!result) {
              let idx = -1;
              for (let i = 0; i <= this.syndication.syndicationItems?.length - 1; i++) {
                if (this.syndication.syndicationItems[i].id == result.id) {
                  idx = i;
                  break;
                }
              }
              this.syndication.syndicationItems.splice(idx, 1);
              this.dataSource = new MatTableDataSource(this.syndication.syndicationItems);
            }
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastrService.success("Syndication Removed!", $localize`:@@companyName:Rondeivu`);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastrService.error("Unable to remove syndication!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  showNotes(notes: string) {
    const delRef = this.dialog.open(NotesModalComponent, {
      height: 'auto',
      width: 'auto',
      data: {notes: notes},
      disableClose: true
    });
  }

  getStatusString(element: ISyndicationItem) {
    if (!!this.config) {
      return this.util.getLookupValueForKey(this.lookups.subscriptionStatus, element.status);
    }
    return '';
  }

  getSubdocStatusString(element: ISyndicationItem) {
    if (!!this.config) {
      return this.util.getLookupValueForKey(this.lookups.subDocStatus, element.status);
    }
    return '';
  }


  /**
   * Opens the sub doc modal
   * @param element
   */
  openSubdocModal(element: ISyndicationItem) {
    const delRef = this.dialog.open(DealSubscriptionSubdocModalComponent, {
      height: 'auto',
      width: 'auto',
      data: {
        businessUnitId: element.investor.id,
        subscriptionId: element.id,
        amount: element.allocationAmount
      },
      disableClose: true
    });

    delRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.getSyndication();
      }
    });
  }

  /**
   * Edit the sub doc
   * @param element
   */
  editSubdoc(element: ISyndicationSubdoc) {
    const delRef = this.dialog.open(DealSubscriptionSubdocEditModalComponent, {
      height: 'auto',
      width: 'auto',
      data: {
        subdoc: element
      },
      disableClose: true
    });

    delRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.getSyndication();
      }
    });
  }

  promptSave(element: any) {
    const modalRef = this.dialog.open(PromptModalComponent, {
      height: 'auto',
      width: 'auto',
      data: {
        title: 'Save Subscription',
        message: 'Would you like to save the subscription?',
        btnText: 'SAVE SUBSCRIPTION'
      },
      disableClose: true
    });

    modalRef.afterClosed().subscribe(result => {
      if (result) {
        this.save(element);
      }
    });
  }

  downloadSubdoc(element: ISyndicationSubdoc) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.subDocService.getSubdocPdf(element.id).subscribe({
      next: (res: Blob) => {
        let dt = element.subscriptionDate.split('T')[0];
        let filename = element.entityName + '-' + dt + '.pdf';
        let blob: Blob = res as Blob;
        let a = document.createElement('a');
        a.download = filename;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.store.dispatch(toggleLoading({loading: false}));
      },
      error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.error("Unable to download file!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  fillSubdoc(element: ISyndicationSubdoc) {
    const delRef = this.dialog.open(DealSubscriptionSubdocFormModalComponent, {
      height: 'auto',
      width: 'auto',
      minWidth: '900px',
      data: {
        subdoc: element
      },
      disableClose: true
    });

    delRef.afterClosed().subscribe(result => {
      if (!!result) {
      }
    });
  }


  /**
   * Delete the sub doc
   * @param element
   */
  deleteSubdoc(element: ISyndicationSubdoc) {
    const delRef = this.dialog.open(DeleteConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      data: {
        subdoc: element
      }
    });

    delRef.afterClosed().subscribe(result => {
      if (!!result) {
        let id = result.subdoc.id;
        this.store.dispatch(toggleLoading({loading: true}));
        this.subDocService.deleteSubdoc(id).subscribe({
          next: (res: any) => {
            this.getSyndication();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastrService.success("Sub Doc Removed!", $localize`:@@companyName:Rondeivu`);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastrService.error("Unable to remove sub doc!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  /**
   * Approve the investor sub doc
   * @param element
   */
  approveInvestorSubdoc(element: ISyndicationSubdoc) {
    const confirm = this.dialog.open(ConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      data: {
        subdoc: element
      }
    });

    confirm.afterClosed().subscribe(result => {
      if (!!result) {
        let id = result.subdoc.id;
        this.store.dispatch(toggleLoading({loading: true}));
        this.subDocService.approvalSendToInvestor(id, {}).subscribe({
          next: (res: any) => {
            this.getSyndication();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastrService.success("Sub Doc Approved!", $localize`:@@companyName:Rondeivu`);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastrService.error("Unable to approve sub doc!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  /**
   * Approve Issuer the sub doc
   * @param element
   */
  approveIssuerSubdoc(element: ISyndicationSubdoc) {
    const confirm = this.dialog.open(ConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      data: {
        subdoc: element
      }
    });

    confirm.afterClosed().subscribe(result => {
      if (!!result) {
        let id = result.subdoc.id;
        this.store.dispatch(toggleLoading({loading: true}));
        this.subDocService.approvalSendToIssuer(id, {}).subscribe({
          next: (res: any) => {
            this.getSyndication();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastrService.success("Sub Doc Issuer Approved!", $localize`:@@companyName:Rondeivu`);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastrService.error("Unable to issuer approve sub doc!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }


  expandRow(element: any) {
    element.expanded = !element.expanded;
    // this.expandedElement = this.expandedElement === element ? null : element;
    // this.selectedSubdocs = this.expandedElement.subDocs;
  }

}
