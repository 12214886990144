
<div id="rightnav" *ngIf="!!theme && !!config">
  <!--  drawer end-->
  <div id="content">
    <!-- toolbar start-->
    <mat-toolbar id="toolbar-nav" class="mat-primary space-between" *ngIf="!!theme"
                 [ngStyle]="{'background-color': theme.header_bg,color: theme.header_fg}"
                 [hidden]="!config.show_navigation_toolbar">
      <div style="display: flex; justify-content: start; align-items: center; gap: 8px;">
        <img src="assets/images/Rondeivu_Logo_Lockup-3_white.png" style="height: 46px;">
        <span style="font-size: 28px; height: 46px; line-height: 46px; margin-bottom: 6px;">{{appEnvironment}}</span>
      </div>
      <!-- context button -->
      <div class="space-between" style="gap: 10px;">
        <!--        inbox button-->
        <button mat-mini-fab color="primary" [matMenuTriggerFor]="navigation"
                *ngIf="!!cognitoUser" class="d-md-none">
          <mat-icon>menu</mat-icon>
        </button>
        <!--        inbox button-->
        <div [matMenuTriggerFor]="tasks" *ngIf="!!cognitoUser">
          <button mat-mini-fab color="primary" class="d-md-block">
            <mat-icon [matBadge]="(!!config.tasks && config.tasks.length > 0) ? config.tasks.length : ''"
                      matBadgeColor="warn">
              inbox
            </mat-icon>
          </button>
        </div>
        <!--        user menu button-->
        <div>
          <app-user-card [matMenuTriggerFor]="menu" *ngIf="!!userSettings || selectBusinessUnit"
                         [firstName]="userSettings.firstName"
                         [lastName]="userSettings.lastName"
                         [displayName]="config.selected_business_unit.displayName.toUpperCase()"
                         [borderColor]="'transparent'"
                         [sideColor]="'transparent'"
                         [background]="'#FFFFFF'"
                         [size]="userCardSize.MD"
                         [image]="selectedBusinessUnit.imageUrl"
                         style="cursor: pointer;"
          ></app-user-card>
        </div>
        <!--user menu end-->
      </div>
      <!-- NAV MENU START-->
      <mat-menu #navigation="matMenu" id="navigation">
        <ul style="list-style-type: none;">
          <li [matTooltip]="obj.toolTip"
              matTooltipPosition="right"
              *ngFor="let obj of links"
              (click)="navigateToNavItem(obj)"
              routerLinkActive="active-title"
              [hidden]="obj.hidden"
              style="width: 100%;"
          >
            <button mat-button [disabled]="!obj.enabled"
                    style="width: 100%; text-align: left;">
              <mat-icon>{{ obj.icon }}</mat-icon>&nbsp;
              <span style="line-height: 24px;">{{ obj.title }}</span>
            </button>
          </li>
        </ul>
      </mat-menu>
      <!--NAV MENU END-->
      <!-- TASK MENU START-->
      <mat-menu #tasks="matMenu" id="tasks">
        <div style="display: flex; padding: 10px 8px;">
          <div class="text-center task-icon">
            <mat-icon>info_outline</mat-icon>
          </div>
          <h3 style="margin: 0; font-weight: bold;">Notifications & Tasks</h3>
        </div>
        <ul id="task-item-list">
          <li *ngFor="let t of config.tasks">
            <div *ngIf="!!cognitoUser" class="task-item"
                 [ngStyle]="{'background-color':getTaskBg(t)}">
              <div class="text-center task-icon">
                <mat-icon>{{ getTaskImage(t.status) }}</mat-icon>
              </div>
              <div>
                <span style="font-size:medium; font-weight: bold;">{{ t?.title }}</span><br/>
                <span style="font-size:small;">{{ t?.message }}</span><br/>
                <span style="font-size:small; font-weight: lighter; cursor: pointer; color: #0033ff;"
                      (click)="goToTask(t)"><u>{{ t?.linkText }}</u></span>
              </div>
            </div>
          </li>
          <li *ngIf="config.tasks.length == 0" class="task-item">
            <span style="font-size:medium;">You have no tasks.</span>
          </li>
        </ul>
      </mat-menu>
      <!--TASK MENU END-->
      <!--     USER MENU START-->
      <mat-menu #menu="matMenu" id="menu">
        <div *ngIf="!!userSettings" style="width: 100%; text-align: center; padding: 24px 16px; gap: 16px;">
          <div style="width: 90%; margin:auto;">
            <app-user-card [firstName]="userSettings.firstName"
                           [lastName]="userSettings.lastName"
                           [displayName]="userSettings.email"
                           [borderColor]="'transparent'"
                           [sideColor]="'#002150'"
                           [background]="'#FFF'"
                           [size]="userCardSize.LG"
                           [image]="userSettings.imageUrl"
            >
            </app-user-card>
          </div>
          <br/>
          <div>
            <span style="font-weight: bold;">{{ config.selected_business_unit_role.role?.name || 'Consultant' }}</span>
          </div>
          <br/>
          <button mat-raised-button (click)="navToProfile()">
            <span style="color: #0047AB">MANAGE PROFILE</span>
          </button>
        </div>


        <div *ngIf="!!config?.business_units"
             style=" border-top: 1px solid rgba(128,128,128,0.50); border-bottom: 1px solid rgba(128,128,128,0.50);">

          <div class="business-row" *ngFor="let businessUnit of config.business_units; index as i;"
              (click)="selectBusinessUnit(businessUnit);"
              [ngStyle]="{'background-color': getRowColor(businessUnit)}">
            <app-user-card
              [firstName]="businessUnit.displayName"
              [displayName]="businessUnit.businessUnitType.toUpperCase()"
              [image]="businessUnit.imageUrl"
              [size]="userCardSize.MD"
              [ngStyle]="{'cursor':!businessUnit.isEmployeeActive ? util.NoAccessCursor: ''}"
              [matTooltip]="!businessUnit.isEmployeeActive ? 'You have been temporarily suspended.  Please contact admin.' : ''"
              matTooltipPosition="left"
            >
            </app-user-card>
          </div>
        </div>

        <div style="width: 100%; text-align: center; padding: 25px;">
          <button mat-raised-button color="primary" (click)="logOut()" style="height: auto; width: auto;">
            SIGN OUT
          </button>
        </div>

        <div style="width: 100%; text-align: center; padding: 5px; font-size: smaller;" [title]="buildDate">
          App Version: {{ appVersion }} | API Version : {{ (apiVersion$ | async)?.version }}
        </div>

      </mat-menu>
      <!--      MENU END-->
      <!--      MENU END-->
      <!--      MENU END-->
    </mat-toolbar>
    <!-- toolbar end -->
    <mat-drawer-container id="sidenav">
      <mat-drawer id="drawer"
                  mode="side"
                  [opened]="config.show_navigation_menu"
                  [ngStyle]="{'background':theme.nav_bg}"
                  class="d-none d-md-block" [disableClose]="true">
        <div id="nav-list" *ngIf="!!links">
          <mat-nav-list dense style="height:100%;">
            <mat-list-item [title]="obj.toolTip"
                           [class]="getActiveRouteClass(obj)"
                           *ngFor="let obj of links"
                           (click)="navigateToNavItem(obj)"
                           routerLinkActive="active-title"
                           [hidden]="obj.hidden"
            >
              <!-- nav item content -->
              <mat-icon matListIcon [ngStyle]="{'color': isActiveRoute(obj)? theme.nav_highlight : theme.nav_fg}">
                {{ obj.icon }}
              </mat-icon>
              <div>
                <span class="nav-title" style="font-size: small;"
                      [ngStyle]="{'color': isActiveRoute(obj)? theme.nav_highlight : theme.nav_fg}">
                  {{ obj.title }}
                </span>
              </div>
            </mat-list-item>
          </mat-nav-list>
        </div>
        <div id="drawer-bg"></div>
        <div style="position: absolute; top: calc(90vh - 36px);"></div>
      </mat-drawer>
      <mat-drawer-content
        [ngStyle]="{
          'background-color': theme.content_bg,
          color: theme.content_fg
        }" style="overflow: hidden;"
      >

        <div [@appFade]="animationState" *ngIf="show" class="router-container">
          <router-outlet></router-outlet>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</div>
