<div class="container-fluid">
  <div class="row justify-content-center pt-4">
    <div class="col-6 col-md-5">
      <span class="rm-title rm-title-3">Syndication</span>&nbsp;
      <br/>
      <em style="color: orange;" *ngIf="!!syndication">
        {{ syndication.issuerPoolIsLocked ? 'Issuer has locked syndication pool' : 'Syndication pool is open, allocations not yet visible' }}
      </em>
    </div>
    <div class="col-6 text-end">
      <div class="col-11 text-end" *ngIf="!!syndication && (util.isIssuer() || util.isAdmin())">
        <button mat-raised-button
                color="warn"
                (click)="changeLockStatus(false)"
                *ngIf="syndication.issuerPoolIsLocked == true"
                [disabled]="!util.hasPermission(util.Permission.CAN_LOCK_DEAL_SYNDICATION)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_LOCK_DEAL_SYNDICATION) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_LOCK_DEAL_SYNDICATION) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
        >
          Unlock Syndication Pool
        </button>
        <button mat-raised-button
                color="warn"
                (click)="changeLockStatus(true)"
                *ngIf="syndication.issuerPoolIsLocked == false"
                [disabled]="!util.hasPermission(util.Permission.CAN_LOCK_DEAL_SYNDICATION)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_LOCK_DEAL_SYNDICATION) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_LOCK_DEAL_SYNDICATION) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
        >
          Lock Syndication Pool
        </button>
      </div>
    </div>
    <!--      -->
    <!--      -->
    <!--     ISSUER SIGNATORIES START-->
    <!--      -->
    <!--      -->
    <div class="row justify-content-center" *ngIf="!!syndication && syndication.showDealSignatoriesSection == true">
      <div class="col-11 pt-4">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span style="font-weight: bold;">Issuer Signatories</span>
              </mat-panel-title>
              <mat-panel-description>
                <span class="text-muted">{{ currentSignatoriesStr }}</span>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <form [formGroup]="form" (submit)="saveDealSignatories()">
              <div class="row">
                <p>
                  Set your SubDoc signatories for this deal from this panel.  These signatories are extracted from approved KYC processes.
                  <br />
                  The number of Issuer signatories can be a <b>minimum of one</b> and a <b>maximum of two</b> signatories.
                </p>
              </div>
              <div class="row pt-2" *ngIf="businessUnitEntities == null || businessUnitEntities.length == 0">
                <div class="col-12" style="color: red">
                  There are no issuing entities registered for this Busisness Unit, please create one first and complete its KYC.
                  <br />
                  Once the KYC is approved by Rondeivu, you will be able to set the Issuing Entity & SubDoc signatories from this form.
                </div>
              </div>
              <div class="row pt-2" *ngIf="businessUnitEntities.length > 0">
                <div class="col-8">
                  <mat-form-field class="form-field" appearance="outline">
                    <mat-label>Entity to Invest</mat-label>
                    <mat-select id="entInv" [formControlName]="FORM_INV_ENT" required
                                (selectionChange)="changeEntity($event)">
                      <mat-option *ngFor="let entity of businessUnitEntities" [value]="entity">
                        {{ entity.entityName }}
                      </mat-option>
                    </mat-select>
                    <mat-hint align="end">Please select the Entity through which you will to invest.  Changing the Entities will reset your current signatories.</mat-hint>
                  </mat-form-field>
                  <mat-error>{{ getErrorMsg() }}</mat-error>
                </div>
              </div>
              <!-- ROW START -->

              <div *ngIf="businessUnitEntities?.length">
                <div class="row pt-4">
                  <div class="pb-1" style="font-size: large; font-weight: bold;">Signatories</div>
                  <p>
                    The list of signatories below are extracted from the KYC signatories liked to the
                    Investing Entity selected above.
                  </p>
                </div>
                <!-- ROW START -->
                <div class="row pt-1">
                  <div class="col-8">
                    <mat-form-field class="form-field" appearance="outline">
                      <mat-label>Signatories</mat-label>
                      <mat-select id="sigs" [formControlName]="FORM_SELECTED_SIG" required
                                  (selectionChange)="changeSelectedSignatory($event)">
                        <mat-option *ngFor="let signatory of getKycSignatoriesFromSelectedEntity()" [value]="signatory">
                          {{ signatory.name }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="getSignatories().length == 0">
                        You must add at least one signatory to the Sub Doc before initiation
                      </mat-error>
                      <!-- <mat-hint>If there is anyone you would like to add who is not on the list of the KYC signatories,
                        <a (click)="addNewSignatory()"
                            [ngStyle]="{color: isAddDisabled()? 'grey': 'blue', cursor: isAddDisabled()? 'not-allowed': 'pointer'}"
                        >
                          click here
                        </a> to add them to the
                        Sub Doc.
                      </mat-hint> -->
                    </mat-form-field>
                  </div>
                  <div class="col-4">
                    <div style="padding-top: 7px;">
                      <button mat-raised-button type="button" color="primary"
                              (click)="addSelectedSignatory()"
                              [disabled]="isAddDisabled() || !form.get(FORM_SELECTED_SIG)?.value"
                              [ngStyle]="{cursor: isAddDisabled() ? 'not-allowed': 'pointer'}"
                              [matTooltip]="isAddDisabled() ? getDisabledMsg() : ''"
                              matTooltipPosition="left"
                      >
                        Add Issuer Signatory to the Sub Doc
                      </button>
                    </div>
                  </div>
                </div>
  
                <!-- ROW START -->
                <div class="row pt-2" *ngIf="!!form.get(FORM_SIGNATORIES)?.value">
                  <div class="col-12" *ngIf="getSignatories().length > 0">
                    <table class="table table-striped form-table">
                      <thead>
                      <tr>
                        <th class="form-table-header" colspan="4">Subscription Docs Users (Order of Signatories is Important)</th>
                      </tr>
                      <tr>
                        <th class="form-table-header">User Access/ Signatories</th>
                        <th class="form-table-header">Email Address</th>
                        <th class="form-table-header">Signing Order</th>
                        <th class="form-table-header">Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let signatory of form.get(FORM_SIGNATORIES)?.value; index as i;">
                        <td style="padding-left: 5px; line-height: 36px;">
                          <div *ngIf="!form.get(FORM_SIGNATORIES)?.value[i].external">
                            <span>{{ form.get(FORM_SIGNATORIES)?.value[i].fullName }}</span></div>
                          <div *ngIf="form.get(FORM_SIGNATORIES)?.value[i].external">
                            <input matInput id="sigName"
                                    type="text"
                                    placeholder="John Smith"
                                    [(ngModel)]="form.get(FORM_SIGNATORIES)?.value[i].fullName"
                                    [ngModelOptions]="{standalone: true}"
                            />
                          </div>
                          <div *ngIf="!form.get(FORM_SIGNATORIES)?.value[i].external"
                                style="margin-top:-15px; font-size: x-small; font-weight: lighter;">
                            <em>KYC Approved Signatory</em>
                          </div>
                          <div *ngIf="form.get(FORM_SIGNATORIES)?.value[i].external"
                                style="margin-top:-15px; font-size: x-small; font-weight: lighter;">
                            <em style="color: red;">Signatory not in Approved KYC</em>
                          </div>
                        </td>
                        <td style="line-height: 36px; padding-top: 16px;">
                          <input matInput id="sigEmail"
                                  type="text"
                                  placeholder="example@rondeivu.com"
                                  [(ngModel)]="form.get(FORM_SIGNATORIES)?.value[i].email"
                                  [ngModelOptions]="{standalone: true}"
                          />
                        </td>
                        <td style="line-height: 36px; padding-top: 16px;">
                          {{ i + 1 }}
                        </td>
                        <td class="text-center">
                          <button mat-icon-button type="button">
                            <mat-icon color="warn"
                                      (click)="removeSignatory(i)"
                                      matTooltip="Remove Signatory"
                                      matTooltipPosition="left"
                            >delete
                            </mat-icon>
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
  
                <div class="row">
                  <div class="col-12 text-end">
                    <div>
                      <button mat-raised-button type="submit" color="primary">
                        Save Issuer Signatories
                      </button>
                    </div>
                  </div>
                </div>  
              </div>

            </form>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <!--      -->
    <!--      -->
    <!--      ADMIN PANEL END-->
    <!--      -->
    <!--      -->


    <!--      -->
    <!--      -->
    <!--     CARDS START-->
    <!--      -->
    <!--      -->
    <div *ngIf="syndication">
      <!-- Investor top cards  -->
      <div class="row justify-content-center pt-3" *ngIf="util.isInvestor()">
        <div class="col-11 col-md-3 pool">
          <span class="title">Total Deal Indicated Interest</span><br/><br/>
          <span class="value">{{ syndication.percentCommitted }}%</span>
        </div>
        <div class="col-11 col-md-3 pool">
          <span class="title">Amount Remaining</span><br/><br/>
          <span class="value">{{ syndication.amountRemaining | currency: 'USD': 'symbol': '1.0-0' }} Million USD</span>
        </div>
        <div class="col-11 col-md-3 pool">
          <span class="title">Total Indication of Interest</span><br/><br/>
          <span class="value">{{ syndication.totalIndicationsOfInterest | currency: 'USD': 'symbol': '1.0-0' }} Million USD</span>
        </div>
      </div>

      <!-- Issuer & Admin top cards  -->
      <div class="row justify-content-center pt-3" *ngIf="util.isIssuer() || util.isAdmin()">
        <div class="col-11 col-md-3 pool">
          <span class="title">Total Indication of Interest</span><br/><br/>
          <span class="value">{{ syndication.totalIndicationsOfInterest | currency: 'USD': 'symbol': '1.0-0' }} Million USD</span>
        </div>
        <div class="col-11 col-md-3 pool">
          <span class="title">Total Allocation</span><br/><br/>
          <span class="value">{{ syndication.totalAllocation | currency: 'USD': 'symbol': '1.0-0' }} Million USD</span>
        </div>
        <div class="col-11 col-md-3 pool">
          <span class="title">Fundraise Size on Rondeivu</span><br/><br/>
          <span class="value">{{ syndication.totalFundraiseSize | currency: 'USD': 'symbol': '1.0-0' }} Million USD</span>
        </div>
      </div>
    </div>
    <br/>
    <!--      -->
    <!--      -->
    <!--     CARDS END -->
    <!--      -->
    <!--      -->

    <!--      -->
    <!--      -->
    <!--     TABLE START-->
    <!--      -->
    <!--      -->
    <div class="row justify-content-center d-none d-md-flex" style="padding-bottom: 150px; padding-top: 15px;">
      <div class="col-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <!--                -->
              <!-- SUBSCRIPTION TABLE START-->
              <!--                -->
              <table class="subscription-table"
                      mat-table [dataSource]="dataSource"
                      [multiTemplateDataRows]="true" matSort>
                <ng-container matColumnDef="table-name">
                  <th colspan="8" mat-header-cell *matHeaderCellDef>
                    <span style="font-size: large;">
                      Subscriptions <small>(all amounts are in USD)</small> 
                      <span *ngIf="syndication.issuerPoolIsLocked" style="margin-left: 10px; font-weight: bold;">Subscription pool is LOCKED</span>
                    </span>
                  </th>
                </ng-container>
                <!-- Investor -->
                <ng-container matColumnDef="businessUnit">
                  <th mat-header-cell *matHeaderCellDef> Investor</th>
                  <td mat-cell *matCellDef="let element" style="padding: 5px;">
                    <app-user-card [firstName]="element.investor?.['displayName']"
                                    [displayName]="element.investor?.['profile']"
                                    [borderColor]="'transparent'"
                                    [sideColor]="'#ff0000'"
                                    [image]="element.investor?.['imageUrl']"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- IOI -->
                <ng-container matColumnDef="ioi">
                  <th mat-header-cell *matHeaderCellDef> IoI Amount</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.indicationAmount | currency: 'USD': 'symbol': '1.0-0' }}
                  </td>
                </ng-container>
                <!-- Allocation -->
                <ng-container matColumnDef="allocation">
                  <th mat-header-cell *matHeaderCellDef> Allocation</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.allocationAmount | currency: 'USD': 'symbol': '1.0-0' }}
                  </td>
                </ng-container>
                <!-- Restrictions -->
                <ng-container matColumnDef="restrictionType">
                  <th mat-header-cell *matHeaderCellDef>Restriction</th>
                  <td mat-cell *matCellDef="let element">
                    {{ getRestrictionType(element.restrictionType) }}
                  </td>
                </ng-container>
                <!-- Notes -->
                <ng-container matColumnDef="notes">
                  <th mat-header-cell *matHeaderCellDef> Notes</th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-icon-button
                            *ngIf="!!element.notes"
                            (click)="showNotes(element.notes)"
                            title="Show Notes"
                    >
                      <mat-icon color="primary">description</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <!-- Subscription Status -->
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef> Status</th>
                  <td mat-cell *matCellDef="let element">
                    {{ getStatusString(element) }}
                  </td>
                </ng-container>
                <!-- Subscription Created -->
                <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef> Created</th>
                  <td mat-cell *matCellDef="let element">
                    <div class="date-stacked">
                      <span>{{ element.created | date : 'mediumDate' }}</span>
                      <span>{{ element.created | date : 'shortTime' }}</span>
                    </div>
                  </td>
                </ng-container>
                <!-- Subscription Actions -->
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef> Actions</th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-raised-button
                            color="primary"
                            *ngIf="element.showInitiateSubDocsButton"
                            (click)="openSubdocModal(element)"
                            [disabled]="syndication.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_ADD_DEAL_SUBDOCS)"
                            [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_DEAL_SUBDOCS) ? util.NoAccessCursor: ''}"
                            [title]="!util.hasPermission(util.Permission.CAN_ADD_DEAL_SUBDOCS) ? util.NoAccessMsg : 'Open SubDoc Dialog Box'"
                    >
                      Initiate Sub Doc
                    </button> &nbsp;
                    <button mat-icon-button
                            (click)="editAllocationModal(element)"
                            color="primary"
                            *ngIf="util.isAdmin() || util.isIssuer()"
                            [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SYNDICATION) ? util.NoAccessCursor: ''}"
                            [title]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SYNDICATION) ? util.NoAccessMsg : 'Edit'"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                    <!-- <button mat-icon-button
                            (click)="editAllocationModal(element)"
                            color="primary"
                            *ngIf="util.isAdmin() || util.isIssuer()"
                            [disabled]="syndication.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_EDIT_DEAL_SYNDICATION)"
                            [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SYNDICATION) ? util.NoAccessCursor: ''}"
                            [title]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SYNDICATION) ? util.NoAccessMsg : 'Edit'"
                    >
                      <mat-icon>edit</mat-icon>
                    </button> &nbsp; -->
                    <button mat-icon-button
                            color="warn"
                            (click)="delete(element)"
                            [disabled]="syndication.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_DELETE_DEAL_SYNDICATION)"
                            [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_DELETE_DEAL_SYNDICATION) ? util.NoAccessCursor: ''}"
                            [title]="!util.hasPermission(util.Permission.CAN_DELETE_DEAL_SYNDICATION) ? util.NoAccessMsg : 'Delete'"
                            *ngIf="util.isAdmin()">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <!-- expand-->
                <ng-container matColumnDef="expand">
                  <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-icon-button title="Expand to see the SubDocs for this item"
                            aria-label="expand row"
                            (click)="element.expanded = !element.expanded"
                            *ngIf="element.subDocs.length > 0">
                      <mat-icon *ngIf="element.expanded" [matBadge]="element.subDocs.length" matBadgeColor="accent">keyboard_arrow_up</mat-icon>
                      <mat-icon *ngIf="!element.expanded" [matBadge]="element.subDocs.length" matBadgeColor="accent">keyboard_arrow_down</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <!-- Expanded Content Column -->
                <ng-container matColumnDef="expandedDetail">
                  <td mat-cell
                      class="expanded-detail-row"
                      *matCellDef="let element"
                      [attr.colspan]="matchesColsToDisplayWithExpand.length">
                    <div class="element-detail"
                          [@detailExpand]="element.expanded ? 'expanded' : 'collapsed'">
                      <!--                        -->
                      <!--   SUBDOC TABLE START   -->
                      <!--                        -->
                      <!--                        -->
                      <table class="subdoc-table"
                              mat-table
                              [dataSource]="element.subDocs"
                              [multiTemplateDataRows]="true"
                              matSort
                      >
                        <ng-container matColumnDef="table-name">
                          <th colspan="6" mat-header-cell *matHeaderCellDef>
                            <span style="font-size: large;">Sub Docs</span>
                          </th>
                        </ng-container>
                        <!-- ENTITY -->
                        <ng-container matColumnDef="entity">
                          <th mat-header-cell *matHeaderCellDef> Entity</th>
                          <td mat-cell *matCellDef="let element" style="padding: 5px;">
                            <app-user-card [firstName]="element.entityName"
                                            [displayName]="element.entityType"
                                            [borderColor]="'transparent'"
                                            [sideColor]="'#004fe1'"
                            ></app-user-card>
                          </td>
                        </ng-container>
                        <!-- AMOUNT -->
                        <ng-container matColumnDef="amount">
                          <th mat-header-cell *matHeaderCellDef> Amount (USD)</th>
                          <td mat-cell *matCellDef="let element">
                            <div>
                              <span
                                style="font-weight: bold; color: #986801">
                                {{ element.amount | currency: 'USD':true:'2.0' }}
                              </span>
                            </div>
                          </td>
                        </ng-container>
                        <!-- SUBDOC STATUS -->
                        <ng-container matColumnDef="status">
                          <th mat-header-cell *matHeaderCellDef> Subdoc Status</th>
                          <td mat-cell *matCellDef="let element">
                            {{ getSubdocStatusString(element) }}
                          </td>
                        </ng-container>
                        <!-- DATE OF SUBSCRIPTION -->
                        <ng-container matColumnDef="date">
                          <th mat-header-cell *matHeaderCellDef> Date of Subscription</th>
                          <td mat-cell *matCellDef="let element">
                            {{ element.subscriptionDate | date }}
                          </td>
                        </ng-container>
                        <!-- SUBDOC CREATED -->
                        <ng-container matColumnDef="created">
                          <th mat-header-cell *matHeaderCellDef> Sub Doc Created</th>
                          <td mat-cell *matCellDef="let element">
                            {{ element.created | date }}
                          </td>
                        </ng-container>
                        <!-- ACTIONS -->
                        <ng-container matColumnDef="actions">
                          <th mat-header-cell *matHeaderCellDef> Actions</th>
                          <td mat-cell *matCellDef="let element">
                            <div style="display: flex;">
                              <button mat-raised-button
                                      color="primary"
                                      (click)="fillSubdoc(element)"
                                      *ngIf="element.showInvestorFillDataAndApproveButton"
                                      [disabled]="syndication.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBDOCS_FILL_INVESTOR)"
                                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBDOCS_FILL_INVESTOR) ? util.NoAccessCursor: ''}"
                                      [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBDOCS_FILL_INVESTOR) ? util.NoAccessMsg : 'Edit Sub Doc'"
                                      matTooltipPosition="above"
                              >
                                Review & Approve Sub Doc Data
                              </button> &nbsp;
                              <button mat-raised-button
                                      color="primary"
                                      (click)="approveInvestorSubdoc(element)"
                                      *ngIf="element.showRondeivuApprovalButton"
                                      [disabled]="syndication.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBDOCS_APPROVE_INVESTOR)"
                                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBDOCS_APPROVE_INVESTOR) ? util.NoAccessCursor: ''}"
                                      [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBDOCS_APPROVE_INVESTOR) ? util.NoAccessMsg : 'Edit Sub Doc'"
                                      matTooltipPosition="above"
                              >
                                Send to Investor for Review
                              </button> &nbsp;
                              <button mat-raised-button
                                      color="primary"
                                      *ngIf="element.showIssuerApprovalButton"
                                      (click)="approveIssuerSubdoc(element)"
                                      [disabled]="syndication.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBDOCS_APPROVE_ISSUER)"
                                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBDOCS_APPROVE_ISSUER) ? util.NoAccessCursor: ''}"
                                      [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBDOCS_APPROVE_ISSUER) ? util.NoAccessMsg : 'Edit Sub Doc'"
                                      matTooltipPosition="above"
                              >
                                Approve & Send for Signatures
                              </button> &nbsp;
                              <button mat-icon-button
                                      (click)="editSubdoc(element)"
                                      [disabled]="syndication.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_ACCESS_DEAL_SYNDICATION)"
                                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_SYNDICATION) ? util.NoAccessCursor: ''}"
                                      [matTooltip]="!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_SYNDICATION) ? util.NoAccessMsg : 'Edit Sub Doc'"
                                      matTooltipPosition="above"
                              >
                                <mat-icon>edit</mat-icon>
                              </button> &nbsp;
                              <button mat-icon-button
                                      (click)="downloadSubdoc(element)"
                                      [disabled]="syndication.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_ACCESS_DEAL_SUBDOCS_PDF)"
                                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_SUBDOCS_PDF) ? util.NoAccessCursor: ''}"
                                      [matTooltip]="!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_SUBDOCS_PDF) ? util.NoAccessMsg : 'Download Sub Doc'"
                                      matTooltipPosition="above"
                              >
                                <mat-icon>download</mat-icon>
                              </button> &nbsp;
                              <button mat-icon-button
                                      color="warn"
                                      (click)="deleteSubdoc(element)"
                                      [disabled]="syndication.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_DELETE_DEAL_SUBDOCS)"
                                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_DELETE_DEAL_SUBDOCS) ? util.NoAccessCursor: ''}"
                                      [matTooltip]="!util.hasPermission(util.Permission.CAN_DELETE_DEAL_SUBDOCS) ? util.NoAccessMsg : 'Delete Sub Doc'"
                                      matTooltipPosition="above"
                              >
                                <mat-icon>delete</mat-icon>
                              </button>
                            </div>
                          </td>
                        </ng-container>
                        <!-- Row Definitions -->
                        <tr mat-header-row *matHeaderRowDef="['table-name'];"></tr>
                        <tr mat-header-row *matHeaderRowDef="subdocCols; sticky:true;"></tr>
                        <tr mat-row class="subdoc-row" *matRowDef="let element; columns: subdocCols;"></tr>
                      </table>
                      <!--                        -->
                      <!--                        -->
                      <!--  SUBDOC TABLE END      -->
                      <!--                        -->
                      <!--                        -->
                    </div>
                  </td>
                </ng-container>
                <!-- Row Definitions -->
                <tr mat-header-row *matHeaderRowDef="['table-name'];"></tr>
                <tr mat-header-row *matHeaderRowDef="matchesColsToDisplayWithExpand; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: matchesColsToDisplayWithExpand;" class="element-row">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="subscription-detail-row"></tr>
                <!--                  <tr mat-row *matRowDef="let element; columns: matchesColsToDisplay;"></tr>-->
              </table>
              <!--                -->
              <!-- SUBSCRIPTION TABLE START-->
              <!--                -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--      -->
    <!--      -->
    <!--     TABLE END-->
    <!--      -->
    <!--      -->

    <!--                -->
    <!-- LIST VIEW START-->
    <!--                -->
    <div class="row justify-content-center d-flex d-md-none" style="padding-bottom: 150px; padding-top: 15px;">
      <div class="col-12">
        <ul style="list-style-type: none; padding: 25px 0 0 0;">
          <li *ngFor="let element of dataSource.data"
              style="margin-bottom: 5px; display: flex; justify-content: space-between;">
            <app-user-card [firstName]="element.investor.displayName"
                            [displayName]="element.investor.profile"
                            [borderColor]="'transparent'"
                            [sideColor]="'#ff0000'"
                            [image]="element.investor.imageUrl"
            ></app-user-card>
            <div style="display: flex;">
              <div>
                <span style="font-weight: bold; color: #0a53be;line-height: 42px;">
                  {{ element.indicationAmount | currency: 'USD':true:'2.0' }}
                </span>
              </div>&nbsp;
              <div>
                <span style="font-weight: bold; color: #986801; line-height: 42px;">
                  {{ element.allocationAmount | currency: 'USD':true:'2.0' }}
                </span>
              </div>&nbsp;
              <!--                <button mat-icon-button-->
              <!--                        color="primary"-->
              <!--                        (click)="save(element)"-->
              <!--                        *ngIf="!util.isType('investor')"-->
              <!--                        [disabled]="syndication?.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_EDIT_DEAL_SYNDICATION)"-->
              <!--                        [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SYNDICATION) ? util.NoAccessCursor: ''}"-->
              <!--                        [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SYNDICATION) ? util.NoAccessMsg : 'Save'"-->
              <!--                        matTooltipPosition="left"-->
              <!--                >-->
              <!--                  <mat-icon>save</mat-icon>-->
              <!--                </button> &nbsp;-->
              <button mat-icon-button
                      (click)="delete(element)"
                      [disabled]="syndication.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_DELETE_DEAL_SYNDICATION)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_DELETE_DEAL_SYNDICATION) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_DELETE_DEAL_SYNDICATION) ? util.NoAccessMsg : 'Delete'"
                      matTooltipPosition="left"
                      *ngIf="util.isType('admin')">
                <mat-icon color="warn">delete</mat-icon>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!--                -->
  <!-- LIST VIEW END  -->
  <!--                -->
</div>
