<div style="overflow-y: auto; height: calc(100vh - 176px);">
  <div class="container-fluid">
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-11">
        <span class="rm-title rm-title-3">Subscription</span>&nbsp;
        <mat-icon style="cursor: help;" class="rm-header-icon"
                  [matTooltip]="subscription?.issuerPoolIsLocked ? 'Issuer has locked-in subscription pool' : 'Subscription pool is open'"
                  matTooltipPosition="right"
        >{{ subscription?.issuerPoolIsLocked ? 'lock' : 'lock_open' }}
        </mat-icon>
        <br/>
        <span class="rm-title rm-title-6 rm-lighter">These are all of the subscription details of this deal.</span>
      </div>
    </div>
    <!-- row start-->
    <div class="row justify-content-center" style="padding-top: 15px;">
      <div class="col-11 col-md-3 pool">
        <span class="title">Total Subscription Amount</span><br/><br/>
        <span class="value">${{ subscription?.totalFundraiseSize || 0 }} Million USD</span>
      </div>
      <div class="col-11 col-md-3 pool">
        <span class="title">Subscriptions in last 3 months</span><br/><br/>
        <span class="value">${{ subscription?.subscriptionsInLast3Months || 0 }} Million USD</span>
      </div>
      <div class="col-11 col-md-3 pool">
        <span class="title">Subscriptions in last month</span><br/><br/>
        <span class="value">${{ subscription?.subscriptionsInLastMonth || 0 }} Million USD</span>
      </div>
    </div>
    <br/>
    <!--    row start-->
    <div class="row justify-content-center" *ngIf="!util.isType('investor')">
      <div class="col-11 text-end">
        <button mat-raised-button
                (click)="unlock(subscription)"
                *ngIf="subscription?.issuerPoolIsLocked"
                [disabled]="!util.hasPermission(util.Permission.CAN_LOCK_DEAL_SUBSCRIPTION)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_LOCK_DEAL_SUBSCRIPTION) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_LOCK_DEAL_SUBSCRIPTION) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
        >UNLOCK SUBSCRIPTION POOL
        </button>
        <button mat-raised-button
                color="primary"
                (click)="lock(subscription)"
                *ngIf="!subscription?.issuerPoolIsLocked"
                [disabled]="!util.hasPermission(util.Permission.CAN_LOCK_DEAL_SUBSCRIPTION)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_LOCK_DEAL_SUBSCRIPTION) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_LOCK_DEAL_SUBSCRIPTION) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
        >LOCK SUBSCRIPTION POOL
        </button>
      </div>
    </div>

    <!--    table start-->
    <div class="row justify-content-center d-none d-md-flex" style="padding-bottom: 150px; padding-top: 15px;">
      <div class="col-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <!--MATCH TABLE-->
              <table style="width: 100%;" mat-table [dataSource]="dataSource" [multiTemplateDataRows]="true" matSort>
                <ng-container matColumnDef="table-name">
                  <th colspan="6" mat-header-cell *matHeaderCellDef>
                    <span style="font-size: large;">Subscription Allocation</span>
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="businessUnit">
                  <th mat-header-cell *matHeaderCellDef> Investor</th>
                  <td mat-cell *matCellDef="let element" style="padding: 5px;">
                    <app-user-card [firstName]="element.investor?.['displayName']"
                                   [displayName]="element.investor?.['profile']"
                                   [borderColor]="'transparent'"
                                   [sideColor]="'#ff0000'"
                                   [image]="element.investor?.['imageUrl']"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="amount">
                  <th mat-header-cell *matHeaderCellDef>Subscription Amount ($M USD)</th>
                  <td mat-cell *matCellDef="let element">
                    <div *ngIf="!util.isType('admin')">
                      {{ element['subscriptionAmount'] }}
                    </div>
                    <mat-form-field appearance="outline" style="padding-top: 15px;" *ngIf="util.isType('admin')">
                      <input matInput type="number" placeholder="i.e. 10" [(ngModel)]="element['subscriptionAmount']"
                             [disabled]="subscription.issuerPoolIsLocked"/>
                    </mat-form-field>
                  </td>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="notes">
                  <th mat-header-cell *matHeaderCellDef> Notes</th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-icon-button *ngIf="!!element.notes" (click)="showNotes(element.notes)">
                      <mat-icon>description</mat-icon>
                    </button>
                    <!--              <span *ngIf="!!element.notes" [matTooltip]="element.notes" matTooltipPosition="right" style="cursor: help;"><mat-icon>description</mat-icon></span>-->
                  </td>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef> Status</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.status || '-' }}
                  </td>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef> Date</th>
                  <td mat-cell *matCellDef="let element">
                    <div class="date-stacked">
                      <span>{{ element.created | date : 'mediumDate' }}</span>
                      <span>{{ element.created | date : 'shortTime' }}</span>
                    </div>
                  </td>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef> Actions</th>
                  <td mat-cell *matCellDef="let element">
                    <div style="display: flex;">
                      <button mat-raised-button
                              color="primary"
                              (click)="save(element)"
                              *ngIf="util.isType('admin')"
                              [disabled]="subscription?.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBSCRIPTION)"
                              [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBSCRIPTION) ? util.NoAccessCursor: ''}"
                              [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBSCRIPTION) ? util.NoAccessMsg : ''"
                              matTooltipPosition="left"
                      >SAVE DRAFT
                      </button> &nbsp;
                      <button mat-icon-button
                              (click)="delete(element)"
                              [disabled]="subscription?.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_DELETE_DEAL_SUBSCRIPTION)"
                              [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_DELETE_DEAL_SUBSCRIPTION) ? util.NoAccessCursor: ''}"
                              [matTooltip]="!util.hasPermission(util.Permission.CAN_DELETE_DEAL_SUBSCRIPTION) ? util.NoAccessMsg : ''"
                              matTooltipPosition="left"
                              *ngIf="util.isType('admin')">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </td>
                </ng-container>
                <!-- Search Header -->
                <tr mat-header-row *matHeaderRowDef="['table-name'];"></tr>
                <tr mat-header-row *matHeaderRowDef="matchesColsToDisplay; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: matchesColsToDisplay;"></tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    list start-->
    <div class="row justify-content-center d-flex d-md-none" style="padding-bottom: 150px; padding-top: 15px;">
      <div class="col-12">
        <ul style="list-style-type: none; padding: 25px 0 0 0;">
          <li *ngFor="let element of dataSource.data"
              style="margin-bottom: 5px; display: flex; justify-content: space-between;">
            <app-user-card [firstName]="element.investor?.['displayName']"
                           [displayName]="element.investor?.['profile']"
                           [borderColor]="'transparent'"
                           [sideColor]="'#ff0000'"
                           [image]="element.investor?.['imageUrl']"
            ></app-user-card>
            <div style="display: flex;">
              <button mat-raised-button
                      color="primary"
                      (click)="save(element)"
                      *ngIf="util.isType('admin')"
                      [disabled]="subscription?.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBSCRIPTION)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBSCRIPTION) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SUBSCRIPTION) ? util.NoAccessMsg : ''"
                      matTooltipPosition="left"
              >SAVE DRAFT
              </button> &nbsp;
              <button mat-icon-button
                      (click)="delete(element)"
                      [disabled]="subscription?.issuerPoolIsLocked || !util.hasPermission(util.Permission.CAN_DELETE_DEAL_SUBSCRIPTION)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_DELETE_DEAL_SUBSCRIPTION) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_DELETE_DEAL_SUBSCRIPTION) ? util.NoAccessMsg : ''"
                      matTooltipPosition="left"
                      *ngIf="util.isType('admin')">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>



