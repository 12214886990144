<div style="overflow-y: auto; height: calc(100vh - 176px);">
  <div class="container-fluid">
    <!--    top form start-->
    <div class="row justify-content-center" style="padding-top: 25px; padding-bottom: 150px;">
      <!--      LEFT SIDE-->
      <div class="col-12 col-md-6">
        <div class="row justify-content-end">
          <div class="col-12 col-md-11">
            <span class="rm-title rm-title-3">Deal Card Information</span><br/>
            <span class="rm-title rm-title-6 rm-lighter">This is where you can edit the deal card information based on deal state.</span>
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-12 col-md-11">
            <mat-tab-group mat-align-tabs="start" [selectedIndex]="selected.value"
                           (selectedIndexChange)="changeTab($event)">
              <mat-tab label="Pre-NDA">
                <div class="tab-surface">
                  <app-deal-card style="width: 400px;" [deal]="preNdaDeal" [canFlip]="false"
                                 [canView]="false"></app-deal-card>
                </div>
              </mat-tab>
              <mat-tab label="Post-NDA">
                <div class="tab-surface">
                  <app-deal-card style="width: 400px;" [deal]="postNdaDeal" [canFlip]="false"
                                 [canView]="false"></app-deal-card>
                </div>
              </mat-tab>
              <mat-tab label="Post-Close">
                <div class="tab-surface">
                  <app-deal-card style="width: 400px;" [deal]="postClose" [canFlip]="false"
                                 [canView]="false"></app-deal-card>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
      <!--      RIGHT SIDE-->
      <div class="col-12 col-md-6">
        <div class="row justify-content-center">
          <!--      RIGHT SIDE-->
          <div class="col-11" style="border-radius: 12px; border: 1px solid rgba(128,128,128,0.5); padding: 12px;">
            <div class="row">
              <span style="font-size: large; font-weight: bold;">Deal Card Details</span>
            </div>
            <!--        image input-->
            <div class="row" style="padding-top: 25px;">
              <div class="col-6">
                <span style="font-size: medium; padding-top: 20px;">{{getStateTitle()}} Deal Card Image</span><br/>
                <span style="font-size: small; color: grey">PNG and JPG files under 2MB are allowed.</span>
                <input style="padding: 0; height:auto;" type="file" placeholder="Choose File..."
                       (change)="addDocuments($event)" #fileUpload/>
              </div>
            </div>
            <br/><br/>
            <!--        row start-->
            <div class="row">
              <div class="col-12">
                <!--            project name-->
                <mat-label style="font-size: medium;">Project Name</mat-label>
                <mat-form-field class="form-field" appearance="outline">
                  <input class="form-input" matInput placeholder="e.g. Project Alpha"
                         [(ngModel)]="preNdaDeal.projectName"
                         (ngModelChange)="changeName($event)" [required]="true"/>
                </mat-form-field>
              </div>
            </div>
            <!--            <hr class="dashes"/>-->
            <!--        row start-->
            <div class="row" *ngIf="selected?.value == 1 || selected?.value == 2">
              <div class="col-12">
                <!--            asset name-->
                <mat-label style="font-size: medium;">Asset Name (Post-NDA Only)</mat-label>
                <mat-form-field class="form-field" appearance="outline">
                  <input class="form-input" matInput placeholder="e.g. 30 Story Building in Downtown Toronto"
                         [(ngModel)]="postNdaDeal.assetName"
                         (ngModelChange)="postClose.assetName = postNdaDeal.assetName"
                         [required]="true"/>
                </mat-form-field>
              </div>
            </div>
            <!--        row start-->
            <div class="row" *ngIf="selected?.value == 0">
              <div class="col-12">
                <span style="font-size: medium;">Pre-NDA Description</span><br/>
                <app-notepad [minHeight]="'250px'" [text]="preNdaDeal.description"
                             (textChange)="preNdaDeal.description = $event"></app-notepad>
              </div>
            </div>
            <!--        row start-->
            <div class="row" *ngIf="selected?.value == 1">
              <div class="col-12">
                <span style="font-size: medium;">Post-NDA Description</span><br/>
                <app-notepad [minHeight]="'150px'" [text]="postNdaDeal.description"
                             (textChange)="postNdaDeal.description = $event"></app-notepad>
              </div>
            </div>
            <!--        row start-->
            <div class="row" *ngIf="selected?.value == 2">
              <div class="col-12">
                <span style="font-size: medium;">Post-Close Description</span><br/>
                <app-notepad [minHeight]="'150px'" [text]="postClose.description"
                             (textChange)="postClose.description = $event"></app-notepad>
              </div>
            </div>
            <div class="row" style="padding-top: 20px;">
              <div class="col-12 text-end">
                <button mat-raised-button
                        color="primary"
                        (click)="saveDealInformation()"
                        [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_INFO)"
                        [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_INFO) ? util.NoAccessCursor: ''}"
                        [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_INFO) ? util.NoAccessMsg : ''"
                        matTooltipPosition="left"
                >Save Changes
                </button>
              </div>
            </div>
          </div>
          <!--      RIGHT SIDE END-->
        </div>
      </div>
    </div>
  </div>
</div>

