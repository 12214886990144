<div class="rm-header">
  <span class="">Add Config</span>
</div>

<div class="container">
  <br/><br/>
  <mat-dialog-content class="mat-typography">
    <!--    GENERAL SETTINGS-->
    <form id="buForm" #buForm
          [formGroup]="userForm"
          (ngSubmit)="submit()">
      <!--      row start-->
      <div class="row justify-content-center" style="padding-top: 10px;">
        <div class="col-11" *ngIf="!data.isEditing">
          <mat-label>Key</mat-label>
          <mat-form-field class="form-field" appearance="outline">
            <input matInput id="key" type="text" formControlName="key"/>
          </mat-form-field>
        </div>
        <div class="col-11" *ngIf="data.isEditing" style="padding-bottom: 20px;">
          <mat-label>Key</mat-label>&nbsp;
          <mat-icon style="padding-top: 5px; cursor: help;"
                    matTooltip="Lookup keys are locked in order to maintain data integrity.
                    Deactivate the lookup to perform a virtual system delete."
                    matTooltipPosition="right">
            lock
          </mat-icon>
          <br/><br/>
          <span
            style="border: 1px dashed rgba(128,128,128,0.5); border-radius: 3px; padding: 15px 150px 15px 5px; font-weight: lighter;">
            {{userForm.get('key')?.value}}
          </span>
        </div>
      </div>
      <!--      row start-->
      <div class="row justify-content-center">
        <div class="col-11">
          <mat-label>Value</mat-label>
          <mat-form-field class="form-field" appearance="outline">
            <input matInput id="value" type="text" formControlName="value"/>
          </mat-form-field>
        </div>
      </div>
      <!--      row start-->
      <div class="row justify-content-center">
        <div class="col-11">
          <mat-label>Active</mat-label>&nbsp;
          <mat-checkbox color="primary" formControlName="active"></mat-checkbox>
        </div>
      </div>

      <div class="row" style="padding-top: 50px; padding-bottom: 25px;">
        <div class="col-6">
          <button mat-raised-button type="button" (click)="dialogRef.close()">Cancel</button>
        </div>
        <div class="col-6 text-end">
          <button mat-raised-button color="primary" type="submit" [disabled]="userForm.invalid"
                  (click)="dialogRef.close(data)">SUBMIT
          </button>
        </div>
      </div>
    </form>
  </mat-dialog-content>
</div>
