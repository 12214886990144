<div class="rm-header">
  <span>Add Deal Team Member</span>
</div>
<!--    form start-->
<form [formGroup]="userForm"
      (ngSubmit)="addExternalEmployee()">
  <mat-dialog-content>
    <div class="container-fluid">
      <div class="col-11 pt-4">
        <mat-label>Please choose one:</mat-label>
        <br/><br/>
        <mat-radio-group [(ngModel)]="curPage" [ngModelOptions]="{standalone: true}">
          <mat-radio-button [value]="0" color="primary">Company Employee</mat-radio-button>&nbsp;
          <mat-radio-button [value]="1" color="primary">External Consultant</mat-radio-button>
        </mat-radio-group>
      </div>
      <div [ngSwitch]="curPage" class="col-11" style="padding: 25px 0;">
    <span *ngSwitchCase="0">You can easily add any of your current active employees to this deal as deal team member.
      Employees will not show up on this list if they are already on the deal team.</span>
        <span *ngSwitchCase="1">You can add any external person to this deal team.
      They will be sent an email to accept or decline this invitation.</span>
      </div>
      <!--company employee-->
      <div *ngIf="curPage == 0">
        <div style="max-height: 500px; overflow-y: auto; margin-right: -36px;">
          <div *ngFor="let user of employees" class="employee-row" style="padding:5px 0;">
            <div style="display:flex; justify-content: space-between; margin-right: 25px;">
              <!--        user menu button-->
              <app-user-card [firstName]="user['firstName']"
                             [lastName]="user['lastName']"
                             [displayName]="user['email']"
                             [borderColor]="'transparent'"
                             [background]="'#F1EDE4'"
                             [sideColor]="'#002150'"
                             [image]="user['imageUrl']"
              ></app-user-card>
              <mat-checkbox color="primary" style="padding-top: 10px;" (change)="toggleEmployee(user)"></mat-checkbox>
            </div>
          </div>
          <div *ngIf="employees.length == 0" style="padding-bottom: 25px;">
            <span><b>There are currently no active employees in your company that are not already added to this deal team.</b></span>
          </div>
        </div>
      </div>
      <!--external consultant-->
      <div *ngIf="curPage == 1">
        <!--    header-->
        <div class="row">
          <!--    body-->
          <div class="col-12">
            <div style="overflow: hidden;">
              <!--    role-->
              <div class="row justify-content-center" style="padding-top: 15px;">
                <!--firstname-->
                <div class="col-6">
                  <mat-form-field class="form-field" appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input matInput type="text" id="fn" formControlName="firstName"/>
                  </mat-form-field>
                </div>
                <!--lastName-->
                <div class="col-6">
                  <mat-form-field class="form-field" appearance="outline">
                    <mat-label>Last Name</mat-label>
                    <input matInput type="text" id="ln" formControlName="lastName"/>
                  </mat-form-field>
                </div>
              </div>
              <!--        member-->
              <div class="row" style="padding-bottom: 25px;">
                <div class="col-12">
                  <mat-form-field class="form-field" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput type="email" id="we" formControlName="email" [required]="true"/>
                    <mat-error *ngIf="userForm.get('email')?.invalid">Must be a valid email</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <!--Navigation-->
  <mat-dialog-actions class="space-between">
    <button mat-raised-button type="button" (click)="dialogRef.close()">Cancel</button>
    <button mat-raised-button *ngIf="curPage == 0"
      color="primary" type="button" [disabled]="selectedEmployees.length == 0"
      (click)="addEmployees()">
      Add Deal Team Member
    </button>
    <button mat-raised-button  type="submit"
      *ngIf="curPage == 1" color="primary"
      [disabled]="userForm.invalid">
      Add Deal Team Memeber
    </button>
  </mat-dialog-actions>
</form>
<!--  form end-->


