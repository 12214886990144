import packageJson from '../../package.json';
export const environment = {
  production: false,
  version: packageJson.version,
  buildNumber: packageJson.buildNumber,
  buildDate: packageJson.buildDate,
  cognito: {
    auth: {
      region: "us-east-1",
      userPoolId: "us-east-1_tSaZSYOKI",
      userPoolWebClientId: "1eaoeoe41gfmrflichlaud6e3c"
    },
  },
  /// Sentry
  sentry_dns: 'https://442b779906f94de5af4f19e8a727c56a@o1134668.ingest.sentry.io/4504991034441728',
  sentry_trace: 'development',
  sentry_origin: 'https://rondeivu.com/api',

  rondeivu_api: 'https://api-dev.rondeivu.com/api',
  // rondeivu_api: 'https://localhost:7293/api',
  // rondeivu_api: 'https://052cw0l5-7293.use.devtunnels.ms/api',
  rondeivu_signalr: 'https://api-dev.rondeivu.com/rondeivuhub',
  rondeivu_app_title: '[DEV] Rondeivu',
  rondeivu_env_title: 'DEV',
  rondeivu_header_title: 'Rondeivu',
  rondeivu_address_1: '354 Pequot Avenue <br/> Southport, CT 06890 <br/>United States',
  rondeivu_address_2: '1 Adelaide Street East, Suite 3001 <br/>Toronto, ON M5C 2V9 <br/>Canada',
  rondeivu_address_3: '',
  rondeivu_email: 'info@rondeivu.com',
  rondeivu_phone: '+1 (888) 123-4567',
  rondeivu_api_mock: 'http://localhost:3000',
  rondeivu_default_load_delay_ms: 1000,
  rondeivu_spinner_style: 9,
  rondeivu_colors: {
    default_theme: {
      flag_bg: '#fff',
      header_bg: '#002150',
      header_fg: '#FFF',
      subheader_bg: '#E8EDF6',
      subheader_fg: '#0044c1',
      nav_bg: '#002150',
      nav_fg: '#c7c7c7',
      nav_highlight: '#fff',
      content_bg: '#FFF',
      content_fg: '#000',
      pill_1_bg: '#002150',
      pill_1_fg: '#fff',
      pill_2_bg: '#002150',
      pill_2_fg: '#fff',
      pill_3_bg: '#002150',
      pill_3_fg: '#fff',
      status: {
        approved: '#03552E',
        accepted: '#03552E',
        auto_accepted: '#956C00',
        active: '',
        declined: '#B71C1C',
        closed: '#414141',
        live: '#03552E',
        onboarding: '#956C00',
        pending: '#956C00',
        completed: '#03552E',
        not_required: '#133d60',
        sent: '#133d60',
        suspended: '',
        in_review: '#B71C1C',
        confirmed: '#03552E',
        unconfirmed: '#956C00',
        not_registered: '#133d60',
        force_change_password: '#B71C1C',
      },
      tasks: {
        task: '#e9e9e9',
        complete: '#6dff46',
        pending: '#ffde89',
        info: '#ff9946'
      }
    }
  },
  hj: '5072827'
};
