<div class="rm-header">
  <span>Initiate Subscription Document</span>
</div>
<form [formGroup]="form" (submit)="submit()">

  <mat-dialog-content *ngIf="pageStatus == PageStatus.EDITING">
    <!--    EDITING-->
    <p>
      You can initiate Subscription documents from this page, please follow the instructions below to generate your SubDoc.
    </p>
  
    <div style="display: flex; flex-direction: column; gap: 25px !important;">
      <!-- ROW START -->

      <div class="row">
        <div class="align-center">
          <mat-icon>looks_one</mat-icon><span>Indicate the amount of subscription in single unit US Dollars, ie. 5000000 for $5 Million USD</span>
        </div>
        <div class="col-6">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Amount of Subscription</mat-label>
            <input matInput id="amount" type="number"
                    [formControlName]="FORM_AMOUNT"
                    required/>
            <mat-hint align="end">The amount of subscription should be in single unit US Dollars</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <!-- ROW START -->
      <div class="row">
        <div class="align-center">
          <mat-icon>looks_two</mat-icon><span>Select the Legal Entity that you want to invest through (KYC must be approved)</span>
        </div>

        <div class="col-6">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Entity to Invest</mat-label>
            <mat-select id="entInv" [formControlName]="FORM_INV_ENT" required (selectionChange)="changeEntity()">
              <mat-option *ngFor="let t of businessUnitEntities" [value]="t">
                {{ t.entityName }}
              </mat-option>
            </mat-select>
            <mat-hint align="end">Please select the Entity through which you will to invest.</mat-hint>
          </mat-form-field>
          <mat-error>{{ getErrorMsg() }}</mat-error>
        </div>
      </div>

      <!-- ROW START -->
      <div class="row">
        <div class="align-center">
          <mat-icon>looks_3</mat-icon><span>Select the signatories that you have set in your Approved KYC</span>
        </div>
        <mat-hint align="end">The list of signatories below are extracted from the KYC signatories liked to the Investing Entity
          selected above. Please select each signatory from the list and click "Add Investor Signatory to Sub Doc" to add the signatory to the list.
        </mat-hint>

        <div class="row" *ngIf="!!selectedEntity && selectedEntity.kycSignatories.length > 0">
          <div class="col-6">
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>Signatories</mat-label>
              <mat-select id="sigs" [formControlName]="FORM_SELECTED_SIG" required>
                <mat-option *ngFor="let t of getKycSignatoriesFromSelectedEntity()" [value]="t">
                  {{ t.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="getSignatories().length == 0">
                You must add at least one signatory to the Sub Doc before initiation
              </mat-error>
              <!-- <mat-hint>If there is anyone you would like to add who is not on the list of the KYC signatories,
                <a (click)="addNewSignatory()"
                  [ngStyle]="{color: isDisabled()? 'grey': 'blue', cursor: isDisabled()? 'not-allowed': 'pointer'}"
                >
                  click here
                </a> to add them to the
                Sub Doc.
              </mat-hint> -->
            </mat-form-field>
          </div>

          <div class="col-4">
            <div style="padding-top: 7px;">
              <button mat-raised-button type="button" color="primary"
                      (click)="addSelectedSignatory()"
                      [disabled]="isDisabled() || !form.get(FORM_SELECTED_SIG)?.value"
                      [ngStyle]="{cursor: isDisabled()? 'not-allowed': 'pointer'}"
                      [matTooltip]="isDisabled()? 'Maximum of two (2) Signatories': ''"
                      matTooltipPosition="left"
              >
                Add Signatory to SubDoc
              </button>
            </div>
          </div>
        </div>

      </div>

      <!-- ROW START -->
      <div class="row" *ngIf="!!form.get(FORM_SIGNATORIES)?.value">
        <div class="col-12" *ngIf="getSignatories().length > 0">
          <table class="table table-striped form-table">
            <thead>
            <tr>
              <th class="form-table-header" colspan="4">Subscription Docs Users (Order of Signatories is Important)</th>
            </tr>
            <tr>
              <th class="form-table-header">User Access/ Signatories</th>
              <th class="form-table-header">Email Address</th>
              <!--              <th class="form-table-header">Access Type</th>-->
              <th class="form-table-header">Signing Order</th>
              <th class="form-table-header">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let signatory of form.get(FORM_SIGNATORIES)?.value; index as i;">
              <td style="padding-left: 5px; line-height: 36px;">
                <div *ngIf="!form.get(FORM_SIGNATORIES)?.value[i].external">
                  <span>{{ form.get(FORM_SIGNATORIES)?.value[i].name }}</span></div>
                <div *ngIf="form.get(FORM_SIGNATORIES)?.value[i].external">
                  <input matInput id="sigName"
                        type="text"
                        placeholder="John Smith"
                        [(ngModel)]="form.get(FORM_SIGNATORIES)?.value[i].name"
                        [ngModelOptions]="{standalone: true}"
                  />
                </div>
                <div *ngIf="!form.get(FORM_SIGNATORIES)?.value[i].external"
                    style="margin-top:-15px; font-size: x-small; font-weight: lighter;">
                  <em>KYC Approved Signatory</em>
                </div>
                <div *ngIf="form.get(FORM_SIGNATORIES)?.value[i].external"
                    style="margin-top:-15px; font-size: x-small; font-weight: lighter;">
                  <em style="color: red;">Signatory not in Approved KYC</em>
                </div>
              </td>
              <td style="line-height: 36px; padding-top: 16px;">
                <input matInput id="sigEmail"
                      type="text"
                      placeholder="example@rondeivu.com"
                      [(ngModel)]="form.get(FORM_SIGNATORIES)?.value[i].email"
                      [ngModelOptions]="{standalone: true}"
                />
              </td>
              <!--              <td style="line-height: 36px; padding-top: 16px;">-->
                <!--                <mat-radio-group color="primary" aria-label="Select an option"-->
                <!--                                 [(ngModel)]="form.get(FORM_SIGNATORIES)?.value[i].accessType"-->
                <!--                                 [ngModelOptions]="{standalone: true}">-->
                <!--                  <mat-radio-button [value]="1">Fill & Sign</mat-radio-button>&nbsp;-->
                <!--                  <mat-radio-button [value]="0">Read Only</mat-radio-button>-->
                <!--                </mat-radio-group>-->
              <!--                Sign-->
              <!--              </td>-->
              <td style="line-height: 36px; padding-top: 16px;">
                {{ i + 1 }}
              </td>
              <td class="text-center">
                <button mat-icon-button type="button">
                  <mat-icon color="warn"
                            (click)="removeSignatory(i)"
                            matTooltip="Remove Signatory"
                            matTooltipPosition="left"
                  >delete
                  </mat-icon>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- ROW START -->
      <div class="row">
        <div class="align-center">
          <mat-icon>looks_4</mat-icon><span>Affirm that your KYC is up to date</span>
        </div>
        <div class="col-12">
          <span>
            <b>By signing my full name in the textbox below, I affirm that all the information in the KYC forms is accurate and up to date.</b>
          </span>
          <br>
          <span *ngIf="!!selectedEntity">
            You can access the KYC for your selected entity by <a (click)="openKyc()" style="color: blue; cursor: pointer;">clicking here</a>
          </span>
        </div>
        <div class="col-6 pt-3">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Please type your full name</mat-label>
            <input matInput id="auth"
                  type="text"
                  placeholder="e.g. John Smith"
                  required
                  [formControlName]="FORM_SIGN"
            />
            <mat-hint align="end">Please type your full name</mat-hint>
          </mat-form-field>
        </div>
      </div>
    </div>

  </mat-dialog-content>

  <!--    COMPLETE-->
  <mat-dialog-content *ngIf="pageStatus == PageStatus.COMPLETE">
    <!-- ROW START -->
    <div class="row" style="padding: 15px 12px;">
      <div class="col-12">
        <span class="rm-title-3">Sub Doc Successfully Created!</span><br/>
      </div>
    </div>
    <div class="row" style="padding: 15px 12px;">
      <div class="col-12">
        <span style="font-weight: bold;">Congratulations, Rondeivu is now preparing your subscription document!</span><br/>
      </div>
    </div>
    <div class="row" style="padding: 15px 12px;">
      <div class="col-12">
        <span>You will soon receive an email with instructions to add/edit details and approve a final draft.
          Signatories will then receive an email notification when the document is ready for signature.</span>
      </div>
    </div>
  </mat-dialog-content>



  <mat-dialog-actions style="justify-content: space-between; justify-items: end;">
    <div *ngIf="pageStatus == PageStatus.EDITING">
      <button mat-raised-button type="button" (click)="dialogRef.close()">Cancel</button>
    </div>
    <div *ngIf="pageStatus == PageStatus.EDITING">
      <button mat-raised-button
              color="primary"
              type="button"
              (click)="initSubDoc()"
              [disabled]="form.invalid || (getSignatories().length == 0)"
      >
        Initiate Subscription Documents
      </button>
    </div>
    <div *ngIf="pageStatus == PageStatus.COMPLETE"
         style="width: 100%; display: flex; justify-items: end; justify-content: end;">
      <button mat-raised-button type="button" (click)="dialogRef.close(true)">CLOSE</button>
    </div>
  </mat-dialog-actions>
</form>
