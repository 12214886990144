import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Subscription} from "rxjs";
import {Config, getConfig, getLookups, initialConfigState, initialLookupsState, Lookups} from "../../../redux";
import {Store} from "@ngrx/store";
import { LookupService } from 'src/app/services';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-indicate-interest-modal',
  templateUrl: './indicate-interest-modal.component.html',
  styleUrls: ['./indicate-interest-modal.component.scss']
})
export class IndicateInterestModalComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  // lookup$: Subscription = new Subscription();
  config: Config = initialConfigState;
  // lookups: Lookups = initialLookupsState;

  fg: FormGroup;
  // editingAmount = true;

  constructor(
    private store: Store, 
    public dialogRef: MatDialogRef<IndicateInterestModalComponent>,
    public lookupService: LookupService,
    // @Inject(MAT_DIALOG_DATA) public data: { amount: number, restrictionType: string, notes: string }
  
  ) {

    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });

    this.fg = new FormGroup({
      amount: new FormControl('', [Validators.required, Validators.min(1000), Validators.max(1_000_000_001)]),
      restrictionType: new FormControl('', Validators.required),
      notes: new FormControl('')
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    // this.lookup$.unsubscribe();
  }

  getSelectedDealName() {
    return this.config.selected_deal.projectName || 'Unknown';
  }

  submit() {
    if (this.fg.valid == false) {
      return;
    }

    this.dialogRef.close(this.fg.value);
  }

}

