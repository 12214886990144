<div class="container-fluid with-padding">
  
<div class="row">
  <div class="col-12 ">
    <div style="display: flex; justify-content: start; gap: 5px;">
      <mat-icon color="primary">account_balance</mat-icon>&nbsp;
      <span class="rm-title rm-title-3">Regulatory Questions & Third Party Information</span>
    </div>
    <div class="pt-1">Please answer the regulatory third-party-determination questions below</div>
  </div>
</div>
<!--  form body start-->
<form id="tpForm" [formGroup]="fg" (ngSubmit)="submit()">
  <input type="hidden" name="id" formControlName="id" >
  <input type="hidden" name="businessUnitId" formControlName="businessUnitId">

  <div class="row justify-content-center">
    <!--      centered content start-->
    <div class="col-12 ">
      <div>
        <!--ROW START-->
        <div class="row pt-4">
          <div class="col-12">
            <span class="rm-title-3">Regulatory Questions</span>
          </div>
        </div>
        <!--ROW START-->
        <div class="row pt-3">
          <div class="col-8">
            <span>Is the client a registrant under the securities laws of any country?</span>
          </div>
          <div class="col-md-4 text-end">
            <mat-radio-group color="primary" aria-label="Select an option" formControlName="isRegistered">
              <mat-radio-button [value]="true">Yes</mat-radio-button>&nbsp;
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <!--ROW START-->
        <div class="row pt-3">
          <div class="col-8">
            <span>Does the client or any of the authorized signatories own, alone or as part of a group, 10% or more of the voting rights of a publicly traded company?</span>
          </div>

          <div class="col-md-4 text-end">
            <mat-radio-group color="primary" aria-label="Select an option" formControlName="isOwnerofVotingRights">
              <mat-radio-button [value]="true">Yes</mat-radio-button>&nbsp;
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-12 pt-3" *ngIf="showOwnersOfVotingRightsTable()">
            <table class="table table-striped form-table">
              <thead>
              <th class="form-table-header">Owner of Voting Rights</th>
              <th class="form-table-header">Publicly Traded Company</th>
              <th class="form-table-header">Percent (%) Owned</th>
              <th class="form-table-header">Actions</th>
              </thead>
              <tbody formArrayName="thirdPartyOwnerOfVotingRights">
                <tr *ngFor="let item of getOwnersOfVotingRights.controls; let i = index" [formGroupName]="i">
                  <td>{{item.value.ownerOfVotingRights}}</td>
                  <td>{{item.value.publiclyTradedCompany}}</td>
                  <td>{{item.value.ownership}}</td>
                  <td class="text-center">
                    <button mat-icon-button type="button">
                      <mat-icon color="warn" (click)="deleteFromOwnersOfVotingRights(i)" title="Remove">
                        delete
                      </mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>


          <div class="col-12 text-start" *ngIf="showOwnersOfVotingRightsTable()">
            <button mat-raised-button color="primary" type="button"
                    (click)="launchAddVotingRightsOwnerModal()">
              Add Owner of Voting Rights
            </button>
          </div>
        </div>

        <!--ROW START-->
        <div class="row pt-3">
          <div class="col-8">
            <span>Are any of the authorized signatories of the entity Directors or Officers of a company whose shares are traded on the stock exchange or on the OTC market?</span>
          </div>
          <div class="col-md-4 text-end">
            <mat-radio-group color="primary" aria-label="Select an option" formControlName="isPubliclyTraded">
              <mat-radio-button [value]="true">Yes</mat-radio-button>&nbsp;
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-12 pt-3" *ngIf="showAuthorizedSignatoriesTable()">
            <table class="table table-striped form-table">
              <thead>
              <th class="form-table-header">Authorized Signatory</th>
              <th class="form-table-header">Company Name</th>
              <th class="form-table-header">Percent (%) Owned</th>
              <th class="form-table-header">Actions</th>
              </thead>
              <tbody formArrayName="thirdPartyAuthorizedSignatory">
                <tr *ngFor="let item of getThirdPartyAuthorizedSignatories.controls; let i = index" [formGroupName]="i">
                <td>{{item.value.authorizedSignatory}}</td>
                <td>{{item.value.companyName}}</td>
                <td>{{item.value.ownership}}</td>
                <td class="text-center">
                  <button mat-icon-button type="button">
                    <mat-icon color="warn" (click)="deleteFromAuthorizedSignatories(i)" title="Remove">
                      delete
                    </mat-icon>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="col-12 text-start" *ngIf="showAuthorizedSignatoriesTable()">
            <button mat-raised-button color="primary" type="button" (click)="launchAuthorizedSignatoryModal()">
              Add Authorized Signatory
            </button>
          </div>
        </div>


        <!-- line break  -->
        <hr class="mt-5 mb-5">


        <!--ROW START-->
        <div class="row pt-2">
          <div class="col-12">
            <span class="rm-title-3">Third Party Information</span>
          </div>
        </div>
        <!--ROW START-->
        <div class="row pt-4">
          <div class="col-8">
            <span>Is the third party registering on behalf of the entity?</span>
          </div>
          <div class="col-md-4 text-end">
            <mat-radio-group color="primary" aria-label="Select an option"
                              formControlName="isThisPartyRegisteredOnBehalf">
              <mat-radio-button [value]="true">Yes</mat-radio-button>&nbsp;
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <!--ROW START-->
        <div class="row pt-4">
          <div class="col-8">
            <span>Is the third
              party directing the entity to conduct any activity or financial transactions with respect to
              prospective investments through Rondeivu?</span>
          </div>
          <div class="col-md-4 text-end">
            <mat-radio-group color="primary" aria-label="Select an option"
                              formControlName="isAthirdPartyDirecting">
              <mat-radio-button [value]="true">Yes</mat-radio-button>&nbsp;
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <!--ROW START-->
        <div class="row pt-4">
          <div class="col-8">
            <span>Will the third party will have a financial interest in prospective
              investments through Rondeivu?</span>
          </div>
          <div class="col-md-4 text-end">
            <mat-radio-group color="primary" aria-label="Select an option"
                              formControlName="willAthirdPartyHasAfinancialInterest">
              <mat-radio-button [value]="true">Yes</mat-radio-button>&nbsp;
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <!--ROW START-->
        <form [formGroup]="fgThirdPartyInformation" *ngIf="isOneOrMoreSelected()">

          <div class="row pt-4">
            <!-- FIRST NAME-->
            <div class="col-12 col-md-4">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>First Name</mat-label>
                <input matInput id="fname" formControlName="personFirstName"/>
                <!-- <mat-error *ngIf="form.invalid">Please enter a first name.</mat-error> -->
              </mat-form-field>
            </div>
            <!-- MIDDLE NAME-->
            <div class="col-12 col-md-2">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Middle Name</mat-label>
                <input matInput id="mname" formControlName="personMiddleName"/>
              </mat-form-field>
            </div>
            <!-- LAST NAME-->
            <div class="col-12 col-md-4">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput id="lname" formControlName="personLastName"/>
                <!-- <mat-error *ngIf="form.invalid">Please enter a last name.</mat-error> -->
              </mat-form-field>
            </div>
            <!-- SUFFIX-->
            <div class="col-12 col-md-2">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Suffix</mat-label>
                <input matInput id="suffix" formControlName="personSuffix"/>
              </mat-form-field>
            </div>
          </div>

          <!--ROW START-->
          <div class="row pt-2 pb-3">
            <div class="col-12">
              <span class="rm-title-4">Business Information</span><br />
            </div>
          </div>

          <!--ROW START-->
          <div class="row">
            <!-- STREET-->
            <div class="col-12 col-md-8">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Street Address</mat-label>
                <input matInput id="street" formControlName="businessStreetAddress"/>
                <!-- <mat-error *ngIf="form.invalid">Please enter a street address.</mat-error> -->
              </mat-form-field>
            </div>
            <!--        City-->
            <div class="col-12 col-md-4">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>City</mat-label>
                <input matInput id="city" formControlName="businessCity"/>
                <!-- <mat-error *ngIf="form.invalid">Please enter a city.</mat-error> -->
              </mat-form-field>
            </div>
          </div>

          <!--ROW START-->
          <div class="row">
            <!-- Country-->
            <div class="col-12 col-md-4">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Country</mat-label>
                <mat-select id="country" formControlName="businessCountryId">
                  <mat-option *ngFor="let c of lookupService.lookups.countries" [value]="c.key">
                    {{ c.value }}
                  </mat-option>
                </mat-select>
                <!-- <mat-error *ngIf="form.invalid">Please choose one.</mat-error> -->
              </mat-form-field>
            </div>
            <!-- State OR Province-->
            <div class="col-12 col-md-4">

              <!-- state selection-->
              <mat-form-field class="form-field" appearance="outline"
                              *ngIf="fgThirdPartyInformation.get('businessCountryId')?.value == 'US'">
                <mat-label>State</mat-label>
                <mat-select id="state"
                            formControlName="businessStateOrProvince">
                  <mat-option *ngFor="let s of lookupService.lookups.stateTypes" [value]="s.key">
                    {{ s.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- province selection-->
              <mat-form-field class="form-field" appearance="outline"
                              *ngIf="fgThirdPartyInformation.get('businessCountryId')?.value == 'CA'">
                <mat-label>Province</mat-label>
                <mat-select id="province"
                            formControlName="businessStateOrProvince">
                  <mat-option *ngFor="let p of lookupService.lookups.provinceTypes" [value]="p.key">
                    {{ p.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>
            <!-- ZIP OR POSTAL CODE-->
            <div class="col-12 col-md-4">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Zip/Postal Code</mat-label>
                <input matInput id="zip" formControlName="businessZipPostalCode"/>
                <!-- <mat-error *ngIf="form.invalid">Please enter a zip/postal code.</mat-error> -->
              </mat-form-field>
            </div>
          </div>

          <!--ROW START-->
          <div class="row">
            <!-- LEGAL DOMICILE-->
            <div class="col-4">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Legal Domicile (Place of Registration)</mat-label>
                <mat-select id="dom" formControlName="businessLegalDomicile">
                  <mat-option *ngFor="let c of lookupService.lookups.countries" [value]="c.key">
                    {{ c.value }}
                  </mat-option>
                </mat-select>
                <!-- <mat-error *ngIf="form.invalid">Please choose one.</mat-error> -->
                <mat-hint align="end">This is the place of registration of your legal entity</mat-hint>
              </mat-form-field>
            </div>
          </div>

        </form>

        
          <!-- Actions-->
          <div class="row pt-5 pb-5">
            <div class="col-12 text-end">
              <button mat-raised-button color="primary" type="submit">
                Save Changes
              </button>
            </div>
          </div>

      </div>
    </div>
  </div>
</form>


</div>
