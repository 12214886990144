<div class="rm-header">
  <span style="font-size: x-large;">{{data.isEditing ? 'Edit' : 'Add'}} Employee</span>
</div>
<br/>
<div class="row justify-content-center" style="padding-bottom: 20px;" *ngIf="!!data.employee.user">
  <div class="col-11">
    <!--        user menu button-->
    <app-user-card [firstName]="data.employee?.user['firstName'] "
                   [lastName]="data.employee?.user['lastName']"
                   [displayName]="data.employee?.user['email']"
                   [image]="data.employee?.user['imageUrl']"
                   [borderColor]="'transparent'"
                   [background]="'#F1EDE4'"
                   [sideColor]="'#002150'"
    ></app-user-card>
  </div>
</div>
<form [formGroup]="userForm" (ngSubmit)="submit()">
  <div class="row justify-content-center" style="padding-bottom: 20px;">
    <div class="col-11">
      <!--      row-->
      <mat-form-field class="form-field" appearance="outline">
        <mat-label>Email</mat-label>
        <input id="email" name="email" matInput type="email" placeholder="example@rondeivu.com" formControlName="email">
      </mat-form-field>
      <!--      row-->
      <mat-form-field class="form-field" appearance="outline" [hidden]="!data.isEditing">
        <mat-label>Title</mat-label>
        <input id="title" name="title" matInput type="text" placeholder="i.e. CEO" formControlName="title">
      </mat-form-field>
      <!--      row-->
      <mat-form-field class="form-field" appearance="outline" [hidden]="!data.isEditing">
        <mat-label>Role</mat-label>
        <mat-select formControlName="role">
          <mat-option *ngFor="let r of lookups.teamUserRoleTypes" [value]="r.key">
            {{r.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!--      row-->
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-6 text-start">
      <button mat-raised-button type="button" (click)="dialogRef.close()">Cancel</button>
    </div>
    <div class="col-6 text-end">
      <button mat-raised-button color="primary" type="submit"
              [disabled]="userForm.invalid">{{data.isEditing ? 'SAVE' : '+ ADD'}}</button>
    </div>
  </div>
</form>
