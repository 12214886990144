<div style="height:calc(100vh - 64px); overflow-y: auto;">
  <div class="container-fluid">
    <br/><br/>
    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
        <div style="display: flex;">
          <mat-icon color="primary" class="rm-header-icon">store</mat-icon>&nbsp;
          <span class="rm-title rm-title-3">Entities</span>
        </div>
        <div>
          <span class="rm-title rm-title-6 rm-lighter">These are all of the entities on the system:</span>
        </div>
      </div>
      <div class="col-md-3 text-end d-none d-md-block">
        <button mat-raised-button
                color="primary"
                (click)="addEntity()"
        >
          <mat-icon>store</mat-icon>&nbsp;Add Entity
        </button>
      </div>
    </div>
    <!--    table start-->
    <div class="row justify-content-center d-none d-md-flex pt-4">
      <div class="col-12 col-md-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <table mat-table [dataSource]="dataSource"
                     [multiTemplateDataRows]="true" matSort (matSortChange)="sortData($event)">
                <!-- Search Header -->
                <ng-container matColumnDef="table-name">
                  <th colspan="2" mat-header-cell *matHeaderCellDef>
                    <span style="font-size: large;">Entities</span>
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="4" mat-header-cell *matHeaderCellDef>
                    <form>
                      <mat-form-field style="width: 100%;">
                        <mat-label>Search</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input/>
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form>
                  </th>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="business">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="business"> Business Unit</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    <app-user-card [firstName]="element.businessUnit?.displayName"
                                   [displayName]="element.businessUnit?.profile"
                                   [borderColor]="'transparent'"
                                   [sideColor]="'#ff0000'"
                                   [image]="element.businessUnit?.imageUrl"
                                   (click)="util.isAdmin()? util.viewBusinessUnit(element.businessUnit?.id, 'entities') : null"
                                   [ngStyle]="{'cursor': util.isAdmin()? 'pointer':''}"
                                   [highlightOnHover]="util.isAdmin()"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="entityName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="entityName"> Entity Name</th>
                  <td mat-cell *matCellDef="let element" style="padding: 5px;">
                    <span>{{ element.entityName }}</span>
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="entityType">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="entityType">Entity Type</th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ lookupService.getLookupValueForKey(lookupService.lookups.businessUnitEntityType, element.entityType) }}</span>
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="domicile">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="domicile"> Domicile</th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element.domicile }}</span>
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="created">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="created"> Created</th>
                  <td mat-cell *matCellDef="let element">
                    <div class="date-stacked">
                      <span>{{ element.created | date : 'mediumDate' }}</span>
                      <span>{{ element.created | date : 'shortTime' }}</span>
                    </div>
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef> Actions</th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-icon-button type="button" color="warn" *ngIf="util.isAdmin()"
                      (click)="deleteEntity(element)" title="Delete this Entity">
                      <mat-icon>delete</mat-icon>
                    </button>
                    <button mat-icon-button type="button" (click)="viewEntity(element)" title="View Entity">
                      <mat-icon>east</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <!-- expand-->
                <ng-container matColumnDef="expand">
                  <th mat-header-cell *matHeaderCellDef aria-label="row actions" style="width: 50px;">&nbsp;</th>
                  <td mat-cell *matCellDef="let approval" style="width: 50px;">
                    <button mat-icon-button aria-label="expand row"
                            (click)="(expandedElement = expandedElement === approval ? null : approval); $event.stopPropagation()">
                      <mat-icon *ngIf="expandedElement !== approval">keyboard_arrow_down</mat-icon>
                      <mat-icon *ngIf="expandedElement === approval">keyboard_arrow_up</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                  <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                    <div class="example-element-detail"
                         [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                      <div class="approval-row detail-row">

                      </div>
                    </div>
                  </td>
                </ng-container>
                <!--table rows-->
                <tr mat-header-row *matHeaderRowDef="['table-name','search-header'];"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                    class="example-element-row"
                    [class.example-expanded-row]="expandedElement === element">
                  <!--              (click)="expandedElement = expandedElement === element ? null : element">-->
                </tr>
                <!--              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>-->
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter.</td>
                </tr>
              </table>
            </div>
          </div>
          <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[25, 50, 100]"
                         [pageSize]="100"
                         aria-label="Select page of users"></mat-paginator>
        </div>
      </div>
    </div>
    <!--    list start-->
    <div class="row justify-content-center d-flex d-md-none" style="padding-bottom: 150px; padding-top: 15px;">
      <div class="col-12">
        <ul style="list-style-type: none; padding: 25px 0 0 0;">
          <li *ngFor="let element of dataSource.data"
              style="margin-bottom: 5px; display: flex; justify-content: space-between;">
            <app-user-card [firstName]="element.businessUnit?.displayName"
                           [displayName]="element.businessUnit?.profile"
                           [borderColor]="'transparent'"
                           [sideColor]="'#ff0000'"
                           [image]="element.businessUnit?.['imageUrl']"
            ></app-user-card>
            <div style="display: flex;">
              <button mat-icon-button (click)="viewEntity(element)" matTooltip="View Entity"
                      matTooltipPosition="left">
                <mat-icon>east</mat-icon>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
