import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ILookup, INavItem} from "../../../../models";
import {ThemePalette} from "@angular/material/core";
import {Subscription} from "rxjs";
import {IBusinessUnit} from "../../../business-units/models";
import {
  Config,
  getConfig,
  getLookups,
  getSelectedBusinessUnit,
  getThemeState,
  initialConfigState,
  initialLookupsState,
  initialThemeState,
  Lookups,
  Theme,
  updateSelectedBusinessUnitEntity,
  updateSelectedBusinessUnitEntityId,
  updateSelectedEntity,
  updateSelectedEntityId
} from "../../../redux";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {toggleNavigating} from "../../../redux/stores/config/config.actions";
import {EntitiesService} from "../../services/entities.service";
import {IBusinessUnitEntity} from "../../models/entity";
import {AppFade} from "../../../../animations/animations";
import { LookupService } from 'src/app/services';
import { AppService } from 'src/app/services/app-services/app.service';
import { DataService } from 'src/app/services/data/data.service';

@Component({
  selector: 'app-entity-navigation',
  templateUrl: './entity-navigation.component.html',
  styleUrls: ['./entity-navigation.component.scss'],
  animations: [AppFade()],
})
export class EntityNavigationComponent implements OnInit, OnDestroy {
  loading = false;
  animationState = false;

  links: INavItem[] = [
    {title: 'Information', link: '/information', enabled: true, hidden: false, icon: '', toolTip: ''},
    {title: 'KYC', link: '/kyc', enabled: true, hidden: false, icon: '', toolTip: ''}
  ];
  activeLink: INavItem = {} as unknown as INavItem;
  background: ThemePalette = undefined;

  entityId: string | null = '';
  selectedEntity!: IBusinessUnitEntity;
  route$: Subscription = new Subscription();
  config$: Subscription = new Subscription();
  theme$: Subscription = new Subscription();
  // selectedBusinessUnit$: Subscription = new Subscription();
  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
  config: Config = initialConfigState;
  theme: Theme = initialThemeState;

  constructor(
    private entitiesService: EntitiesService,
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private store: Store,
    private toastrService: ToastrService,
    public lookupService: LookupService
  ) {
    this.route$ = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.loading = true;
        this.store.dispatch(toggleNavigating({navigating: true}));
        setTimeout(() => {
          this.store.dispatch(toggleNavigating({navigating: false}));
          this.loading = false
        }, 1000);
      }
    });

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    // this.lookup$ = this.store.select(getLookups).subscribe(lookups => {
    //   this.lookups = lookups;
    // });

    this.theme$ = this.store.select(getThemeState).subscribe((theme: any) => {
      this.theme = theme;
    });

    // this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
    //   if (!!bu && !!bu.businessUnitId && this.selectedBusinessUnit.businessUnitId != bu.businessUnitId) {
    //     this.selectedBusinessUnit = bu;
    //     this.subscribeToDatasource();
    //   }
    // });

    this.subscribeToDatasource();
  }

  ngOnInit(): void {
    this.animationState = true;
  }

  subscribeToDatasource() {
    this.getEntityIdFromRoute();
    this.getEntity();
    this.setActiveLink();
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.theme$.unsubscribe();
    // this.lookup$.unsubscribe();
    // this.theme$.unsubscribe();
    // this.selectedBusinessUnit$.unsubscribe();
    this.route$.unsubscribe();
  }

  getEntity() {
    this.loading = true;
    setTimeout(() => {
      if (!!this.entityId) {
        this.entitiesService.getBusinessUnitEntityById(this.entityId).subscribe({
          next: (entity: IBusinessUnitEntity) => {
            this.selectedEntity = entity;
            this.store.dispatch(updateSelectedBusinessUnitEntity({businessUnitEntity: entity}));
            this.store.dispatch(updateSelectedEntityId({entityId: entity.id}));
            this.store.dispatch(updateSelectedEntity({entity: entity}));
            this.toastrService.success("Entity Loaded!", $localize`:@@companyName:Rondeivu`);
            this.loading = false;
          }, error: (error: any) => {
            this.loading = false;
            this.toastrService.error("Unable to get entity!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
      this.loading = false;
    }, 1000);
  }

  getEntityIdFromRoute() {
    this.entityId = this.route.snapshot.paramMap.get('id');
    if (!!this.entityId) {
      this.store.dispatch(updateSelectedBusinessUnitEntityId({businessUnitEntityId: this.entityId}));
    }
  }

  setActiveLink() {
    const fragments = this.router.url.split('/');
    let link = '/' + fragments[fragments.length - 1];
    this.links.forEach(l => {
      if (l.link == link) {
        this.activeLink = l;
      }
    });
  }

  navigate(obj: { title: string; link: string; enabled: boolean }) {
    this.store.dispatch(toggleNavigating({navigating: true}));
    this.animationState = false;
    setTimeout(() => {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/entities/' + this.entityId + obj.link]).then(() => {
        this.animationState = true;
        this.store.dispatch(toggleNavigating({navigating: false}));
      });
    }, 300);
  }

  goBack() {
    this.store.dispatch(toggleNavigating({navigating: true}));
    this.animationState = false;
    setTimeout(() => {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/entities']).then(() => {
        this.animationState = true;
        this.store.dispatch(toggleNavigating({navigating: false}));
      });
    }, 300);
  }
}
