<div style="height:calc(100vh - 176px); overflow-y: auto;">
  <div class="container-fluid">
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-8 col-md-9">
        <span class="rm-title rm-title-3">Employees</span><br/>
        <span
          class="rm-title rm-title-6 rm-lighter">These are all of the employees for the selected business unit:</span>
      </div>
      <div class="col-4 col-md-2 text-end">
        <button mat-raised-button color="primary" style="height: auto;width: auto;" (click)="addEmployee()">
          <mat-icon>person_add</mat-icon>&nbsp;Add New Employee
        </button>
      </div>
    </div>
    <!--    table start-->
    <div class="row justify-content-center d-none d-md-flex" style="padding-bottom: 150px; padding-top: 15px;">
      <div class="col-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <table mat-table [dataSource]="dataSource"
                     [multiTemplateDataRows]="true"
                     matSort
                     (matSortChange)="sortData($event)"
              >
                <!-- Search Header -->
                <ng-container matColumnDef="table-name">
                  <th colspan="3" mat-header-cell *matHeaderCellDef>
                    <span style="font-size: large;">Business Unit Employees</span>
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="4" mat-header-cell *matHeaderCellDef>
                    <form>
                      <mat-form-field style="width: 100%;">
                        <mat-label>Search</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input/>
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form>
                  </th>
                </ng-container>
                <!-- Employee Column -->
                <ng-container matColumnDef="employee">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="employee"> Employee</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    <app-user-card [firstName]="element.user?.['firstName']"
                                   [lastName]="element.user?.['lastName']"
                                   [displayName]="element.user?.['email']"
                                   [borderColor]="'transparent'"
                                   [background]="'#F1EDE4'"
                                   [sideColor]="'#002150'"
                                   [image]="element.user?.['imageUrl']"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- Title Column -->
                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="title"> Title</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{element['title']}}
                  </td>
                </ng-container>
                <!-- Role Column -->
                <ng-container matColumnDef="role">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="role"> Role</th>
                  <td mat-cell *matCellDef="let element"> {{element["role"]}}
                  </td>
                </ng-container>
                <!-- Member Invite Column -->
                <ng-container matColumnDef="invite">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="status"> Invite Status</th>
                  <td mat-cell *matCellDef="let element">
                    <span [appStatus]="element.memberInviteStatus">{{element.memberInviteStatus}} </span>
                  </td>
                </ng-container>
                <!-- Active Status Column -->
                <ng-container matColumnDef="active">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="active"> Employee Status</th>
                  <td mat-cell *matCellDef="let element">
                    <span
                      [appStatus]="element.isEmployeeActive ? 'Active':'Suspended'">{{element.isEmployeeActive ? 'Active' : 'Suspended'}} </span>
                  </td>
                </ng-container>
                <!-- Created Column -->
                <ng-container matColumnDef="created">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="created"> Created</th>
                  <td mat-cell *matCellDef="let element">
                    <div class="date-stacked">
                      <span>{{ element.created | date : 'mediumDate' }}</span>
                      <span>{{ element.created | date : 'shortTime' }}</span>
                    </div>
                  </td>
                </ng-container>
                <!-- Actions Column -->
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef> Actions</th>
                  <td mat-cell *matCellDef="let element">
                    <div style="display: flex;">
                      <button color="primary" mat-icon-button (click)="viewInfo(element)" matTooltip="Employee Account"
                              matTooltipPosition="left">
                        <mat-icon>account_circle</mat-icon>
                      </button>
                      <button mat-icon-button (click)="viewPermissions(element)" matTooltip="Employee Permissions"
                              matTooltipPosition="left">
                        <mat-icon>settings</mat-icon>
                      </button>
                      <button color="warn" mat-icon-button (click)="delete(element)" matTooltip="Delete Employee"
                              matTooltipPosition="right">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="['table-name','search-header']"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"></tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter.</td>
                </tr>
              </table>
            </div>
          </div>
          <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[5, 10, 25, 100]"
                         aria-label="Select page of users"></mat-paginator>
        </div>
      </div>
    </div>
    <!--    list start-->
    <div class="row justify-content-center d-flex d-md-none" style="padding-bottom: 150px; padding-top: 15px;">
      <div class="col-12">
        <ul style="list-style-type: none; padding: 25px 0 0 0;">
          <li *ngFor="let element of dataSource.data"
              style="margin-bottom: 5px; display: flex; justify-content: space-between;">
            <app-user-card [firstName]="element.user?.['firstName']"
                           [lastName]="element.user?.['lastName']"
                           [displayName]="element.user?.['email']"
                           [borderColor]="'transparent'"
                           [background]="'#F1EDE4'"
                           [sideColor]="'#002150'"
                           [image]="element.user?.['imageUrl']"
            ></app-user-card>
            <div style="display: flex;">
              <button color="primary"
                      mat-icon-button
                      (click)="viewInfo(element)"
                      [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_BUSINESS_UNITS_FULL)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_BUSINESS_UNITS_FULL) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_BUSINESS_UNITS_FULL) ? util.NoAccessMsg : 'Employee Account'"
                      matTooltipPosition="left"
              >
                <mat-icon>info</mat-icon>
              </button>
              <button mat-icon-button
                      (click)="viewPermissions(element)"
                      [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_BUSINESS_UNITS_FULL)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_BUSINESS_UNITS_FULL) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_BUSINESS_UNITS_FULL) ? util.NoAccessMsg : 'Employee Permissions'"
                      matTooltipPosition="left"

              >
                <mat-icon>list</mat-icon>
              </button>
              <button color="warn"
                      mat-icon-button
                      (click)="delete(element)"
                      [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_BUSINESS_UNITS_FULL)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_BUSINESS_UNITS_FULL) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_BUSINESS_UNITS_FULL) ? util.NoAccessMsg : 'Delete Employee'"
                      matTooltipPosition="left"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
