<div class="rm-header">
  <span>{{ data.locked ? 'Lock' : 'Unlock' }} Syndication Pool</span>
</div>

<div class="row justify-content-center pt-4 pb-4">
  <div class="col-12" *ngIf="data.locked == true">
    Locking the syndicaiton pool will <b>disallow</b> any more investors from Indicating Interest on this deal.
  </div>
  <div class="col-12" *ngIf="data.locked == false">
    Unlocking the syndicaiton pool will <b>allow</b> investors to Indicate Interest on this deal.
  </div>
</div>
<mat-dialog-actions  class="space-between">
  <button mat-raised-button [mat-dialog-close]="false">Cancel</button>
  <button mat-raised-button color="warn" [mat-dialog-close]="true">
    {{ data.locked ? 'Lock' : 'Unlock' }} Syndication Pool
  </button>
</mat-dialog-actions>
