<div class="container-fluid">
  <div class="row justify-content-center" style="padding-top: 25px;">
    <div class="col-6 col-md-8">
      <span class="rm-title rm-title-3">Entity KYC</span><br/>
      <span class="rm-title rm-title-6 rm-lighter">Select a KYC.</span>
    </div>
    <div class="col-6 col-md-3 text-end">
      <button mat-raised-button color="primary" (click)="addKyc()">Start New KYC Process for Entity</button>
    </div>
  </div>
  <!--    table start-->
  <div class="row justify-content-center d-none d-md-flex" style="padding-top:25px; padding-bottom: 150px;">
    <div class="col-12 col-md-11">
      <div class="table-container">
        <div class="row table-inner-container">
          <div class="table-inner-inner-container">
            <table mat-table [dataSource]="dataSource"
                    [multiTemplateDataRows]="true"
                    matSort
                    (matSortChange)="sortData($event)"
            >
              <!-- Search Header -->
              <ng-container matColumnDef="table-name">
                <th colspan="3" mat-header-cell *matHeaderCellDef>KYC</th>
              </ng-container>
              <!-- Search Header -->
              <ng-container matColumnDef="search-header">
                <th colspan="3" mat-header-cell *matHeaderCellDef>
                  <form>
                    <mat-form-field style="width: 100%;">
                      <mat-label>Search</mat-label>
                      <input type="text" matInput (keyup)="applyFilter($event)" #input/>
                      <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                  </form>
                </th>
              </ng-container>
              <!-- Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="name"> KYC Name</th>
                <td mat-cell *matCellDef="let element">
                  <div style="display: flex;" [title]="element.businessUnitEntityKycId">
                    <span style="font-size: small;">{{ element.kycName }} </span>&nbsp;
                    <mat-icon *ngIf="element.archived" style="color: rgba(128,128,128,0.5)" title="KYC is locked, and cannot be editted">lock</mat-icon>
                  </div>
                </td>
              </ng-container>
              <!-- Column -->
              <ng-container matColumnDef="entityName">
                <th mat-header-cell *matHeaderCellDef> Entity Name</th>
                <td mat-cell *matCellDef="let element">
                  <span style="font-size: small;">{{ element.businessUnitEntityName }} </span>
                </td>
              </ng-container>
              <!--  Column -->
              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="type"> Entity Type</th>
                <td mat-cell *matCellDef="let element">
                  <span style="font-size: small;">{{ element.entityType }} </span>
                </td>
              </ng-container>
              <!--  Column -->
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="status"> Status</th>
                <td mat-cell *matCellDef="let element">
                  <span style="font-size: small;">{{ lookupService.getLookupValueForKey(lookupService.lookups.kycRondeivuStatus, element.kycRondeivuStatus) }} </span>
                </td>
              </ng-container>
              <!--  Column -->
              <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="created"> Created</th>
                <td mat-cell *matCellDef="let element">
                  <div class="date-stacked">
                    <span>{{ element.created | date : 'mediumDate' }}</span>
                    <span>{{ element.created | date : 'shortTime' }}</span>
                  </div>
                </td>
              </ng-container>
              <!--  Column -->
              <ng-container matColumnDef="comments">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="comments"> Comments</th>
                <td mat-cell *matCellDef="let element">
                  <span style="font-size: small;">{{ element.publicComments }} </span>
                </td>
              </ng-container>
              <!-- Actions Column -->
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Actions</th>
                <td mat-cell *matCellDef="let element">
                  <div style="display: flex; justify-content: center;">
                    <button mat-icon-button
                            (click)="downloadKyc(element)"
                            title="Download PDF"
                    >
                      <mat-icon>download</mat-icon>
                    </button>
                    <!-- <button *ngIf="!element.archived"
                            mat-icon-button
                            (click)="cloneKyc(element)"
                            title="Clone KYC"
                    >
                      <mat-icon>content_copy</mat-icon>
                    </button> -->
                    <button [disabled]="element.archived && util.isAdmin() == false" 
                            mat-icon-button
                            (click)="viewKyc(element)"
                            [title]="element.archived ? 'Archived KYCs cannot be editted' : 'Edit KYC'"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                  </div>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="['table-name','search-header']"></tr>
              <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>
              <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"></tr>
              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter.</td>
              </tr>
            </table>
          </div>
        </div>
        <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[5, 10, 25, 100]"
                        aria-label="Select page of users"></mat-paginator>
      </div>
    </div>
  </div>
</div>
