export interface ILookup {
  key: string;
  value: string;
  detail?: string;
  permissions?: [];
  active: boolean;
}

export interface ILookups {
  businessUnitProfileTypes: [];
  businessUnitWorkflowStatus: [];
  appUserWorkflowStatus: [];
  areasOfFocus: [];
  assetTypes: [];
  countries: [];
  countryCodes: [];
  dealNdaRequirementTypes: [];
  dealTypes: [];
  dealWorkflowStatus: [];
  investorDescriptionTypes: [];
  investorTypes: [];
  investorMandateWorkflowStatus: [];
  issuerTypes: [];
  dealCloseTypes: [];
  secDealTypes: [];
  currencyTypes: [];
  provinceTypes: [];
  stateTypes: [];
  timeLineTypes: [];
  dealInterestTypes: [];
  userIntakeSourceTypes: [];
  engagementAgreementStatusTypes: [];
  issuerNDAStatusTypes: [];
  kycStatusTypes: [];
  dealNotesAccessTypes: [];
  teamUserRoleTypes: [];
  memberInviteStatus: [];
  dealAlertTypes: [];
  viewAccessControls: [];
  dataroomTypes: [];
  indicationOnInterestRestrictionTypes: [];
  investorDealWorkflowStatus: [];
  noteTypes: [];
  syndicationRestrictionTypes: [];
  syndicationStatus: [];
  subscriptionRestrictionTypes: [];
  subscriptionStatus: [];
  rondeivuEmployeeRoleTypes: [];
  ndaTypes: [];
  envelopeStatus: [];
  kycInvestorTypes: [];
  kycIssuerTypes: [];
  anchorInterestTypes: [];
  issuerLegalEntityType: [];
  taxIdType: [];
  legalEntityProofofDocumentType: [];
  entityTabDocumentationType: [];
  personGovernmentIssuedIdType: [];
  personProofofIdentificationIdType: [];
  personProofofAddressDocumentType: [];
  entityTaxDocumentationType: [];
  personProofofIdentificationType: [];
  kycPersonRondeivuStatus: [];
  kycLegalEntityRondeivuStatus: [];
  kycType: [];
  kycRondeivuStatus: [];
  kycPersonType: [];
  kycLegalEntityType: [];
  businessUnitEntityType: [];
  subDocStatus: [];
}

export enum LookupType {
  Private = 'private',
  Public = 'public',
}
