<div style="height:calc(100vh - 176px); overflow-y: auto;">
  <div class="container-fluid">
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-12 col-md-6">
        <span class="rm-title rm-title-3">KYC Verification</span><br/>
        <span
          class="rm-title rm-title-6 rm-lighter">These are of the kyc processes for the selected business unit:</span>
      </div>
      <div class="col-12 col-md-3 text-end">
        <mat-form-field class="form-field" appearance="outline" *ngIf="!!dataSource && !!dataSource.data[0]">
          <mat-label>Status</mat-label>
          <mat-select [(ngModel)]="dataSource.data[0].status"
                      (selectionChange)="updateStatus($event)"
                      [disabled]="!util.hasPermission(util.Permission.CAN_CHANGE_KYC_STATUS)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_CHANGE_KYC_STATUS) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_CHANGE_KYC_STATUS) ? util.NoAccessMsg : ''"
                      matTooltipPosition="left"
          >
            <mat-option *ngFor="let s of lookups.kycStatusTypes" [value]="s.key">
              <span>{{ s.value }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-2 text-end" style="padding-top: 10px;">
        <button mat-raised-button color="primary"
                (click)="addKyc(dataSource.data[0])"
                [disabled]="!util.hasPermission(util.Permission.CAN_ADD_KYC)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_KYC) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_ADD_KYC) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
                hidden
        >
          <mat-icon>note_add</mat-icon>&nbsp;INITIATE
          KYC
        </button>
      </div>
    </div>
    <div class="row justify-content-center" style="padding-top: 25px;"
         *ngIf="!!config">
      <div class="col-12 col-md-11">
        <app-user-card [firstName]="config.editing_business_unit.displayName || undefined"
                       [displayName]="config.editing_business_unit.businessUnitType || undefined"
                       [borderColor]="'transparent'"
                       [sideColor]="'#ff0000'"
                       [size]="userCardSize.LG"
                       [image]="config.editing_business_unit.imageUrl || undefined"
        ></app-user-card>
      </div>
    </div>

    <!--    -->
    <!-- TABLE START   -->
    <!--    -->
    <div class="row justify-content-center d-none d-md-flex" style="padding-bottom: 150px; padding-top: 15px;">
      <div class="col-12 col-md-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <table mat-table [dataSource]="entityKycSource"
                     [multiTemplateDataRows]="true" matSort (matSortChange)="sortData($event)">
                <!-- Search Header -->
                <ng-container matColumnDef="table-name">
                  <th colspan="3" mat-header-cell *matHeaderCellDef>
                    <span style="font-size: large;">Entities</span>
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="3" mat-header-cell *matHeaderCellDef>
                    <form>
                      <mat-form-field style="width: 100%;">
                        <mat-label>Search</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input/>
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form>
                  </th>
                </ng-container>
                <!-- COLUMN START -->
                <ng-container matColumnDef="entityName">
                  <th colspan="1" mat-header-cell
                      *matHeaderCellDef class="inner-header" mat-sort-header="entityName">Entity Name
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element.businessUnitEntityName }}</span>
                  </td>
                </ng-container>
                <!-- COLUMN START -->
                <ng-container matColumnDef="entityType">
                  <th colspan="1" mat-header-cell
                      *matHeaderCellDef class="inner-header" mat-sort-header="entityType">Entity Type
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ getEntityTypeString(element.entityType) }}</span>
                  </td>
                </ng-container>
                <!-- COLUMN START -->
                <ng-container matColumnDef="kycName">
                  <th mat-header-cell
                      *matHeaderCellDef class="inner-header" mat-sort-header="kycName"> KYC Name
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element.kycName }}</span>
                  </td>
                </ng-container>
                <!-- COLUMN START -->
                <ng-container matColumnDef="kycStatus">
                  <th mat-header-cell
                      *matHeaderCellDef class="inner-header" mat-sort-header="kycStatus"> KYC Status
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ getStatusString(element.kycRondeivuStatus) }}</span>
                  </td>
                </ng-container>
                <!-- COLUMN START -->
                <ng-container matColumnDef="created">
                  <th mat-header-cell
                      *matHeaderCellDef class="inner-header" mat-sort-header="created"> Created
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="date-stacked">
                      <span>{{ element.created | date : 'mediumDate' }}</span>
                      <span>{{ element.created | date : 'shortTime' }}</span>
                    </div>
                  </td>
                </ng-container>
                <!-- COLUMN START -->
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef class="inner-header"> Actions</th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-icon-button type="button"
                            (click)="viewEntityKyc(element)"
                            matTooltip="View Entity KYC"
                            matTooltipPosition="left">
                      <mat-icon>east</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <!-- ROWS START -->
                <tr mat-header-row *matHeaderRowDef="['table-name','search-header'];"></tr>
                <tr mat-header-row *matHeaderRowDef="entityKycColumns; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: entityKycColumns;"></tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter.</td>
                </tr>
              </table>
            </div>
          </div>
          <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[25, 50, 100]"
                         [pageSize]="100"
                         aria-label="Select page"></mat-paginator>
        </div>
      </div>
    </div>
    <!--    -->
    <!-- TABLE END   -->
    <!--    -->



  </div>
</div>
