<div style="height:calc(100vh - 176px); overflow-y: auto;">
  <div class="container-fluid pb-5">
    <!--    GENERAL SETTINGS-->
    <form id="buForm" #buForm
          [formGroup]="userForm"
          (ngSubmit)="save()">
      <div class="row justify-content-center" style="padding-top: 25px;">
        <div class="col-12 col-md-11">
          <span class="rm-title rm-title-3">Business Unit Information</span><br/>
          <span class="rm-title rm-title-6 rm-lighter">This is the detailed information about the business unit:</span>
        </div>
      </div>
      <div class="row justify-content-center" style="padding-top: 25px;">
        <div class="col-12 col-md-11">
          <div class="row">
            <span style="font-size: large; font-weight: bold;">General Settings</span>
          </div>
          <br/>
          <div class="row" style="padding-bottom: 15px;">
            <app-user-card [firstName]="userForm.get('displayName')?.value || undefined"
                           [displayName]="userForm.get('businessUnitType')?.value || undefined"
                           [borderColor]="'transparent'"
                           [sideColor]="'#ff0000'"
                           [size]="userCardSize.LG"
                           [image]="userForm.get('imageUrl')?.value || undefined"
            ></app-user-card>
          </div>
          <div class="row">
            <div class="col-12">
              <button type="button"
                      color="primary"
                      mat-raised-button
                      (click)="openImageUploadModal()"
                      [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_BUSINESS_UNITS_SIMPLE)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_BUSINESS_UNITS_SIMPLE) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_BUSINESS_UNITS_SIMPLE) ? util.NoAccessMsg : ''"
                      matTooltipPosition="left"
              >Upload Your Business Logo
              </button>&nbsp;
              <button type="button"
                      color="warn"
                      mat-raised-button
                      (click)="clearImage()"
                      [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_BUSINESS_UNITS_SIMPLE)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_BUSINESS_UNITS_SIMPLE) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_BUSINESS_UNITS_SIMPLE) ? util.NoAccessMsg : ''"
                      matTooltipPosition="left"
              >Clear Logo Image
              </button>
            </div>
          </div>


          <!-- row -->
          <div class="row justify-content-center pt-4">
            <div class="col-12 col-md-6">
              <mat-form-field class="form-field" appearance="outline" float="always">
                <mat-label>Display Name</mat-label>
                <input matInput id="dName" name="dName" placeholder="Display Name"
                  [required]="true" minlength="4" formControlName="displayName"/>
                <mat-hint align="end">This is the display name of the Business Unit, and appears in most places</mat-hint>
                <mat-error *ngIf="userForm.invalid">Must be at least 4 characters long.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <mat-form-field class="form-field" appearance="outline" float="always">
                <mat-label>Business Unit Domicile</mat-label>
                <mat-select id="cod" [required]="true" formControlName="domicile" placeholder="Business Unit Domicile">
                  <mat-option *ngFor="let opt of lookupService.lookups.countries" [value]="opt.key">
                    {{opt.value}}
                  </mat-option>
                </mat-select>
                <mat-hint align="end">The domicile of the Business Unit</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <!--  row-->
          <div class="row justify-content-center pt-4">
            <div class="col-12 col-md-6">
              <mat-form-field class="form-field" appearance="outline" float="always">
                <mat-label>Legal Entity Name</mat-label>
                <input matInput type="textarea" id="iName" name="iName" placeholder="Legal Entity Name (for NDA)" [required]="true" minlength="4"
                       formControlName="internalName"/>
                <mat-error *ngIf="userForm.invalid">Must be at least 4 characters long.</mat-error>
                <mat-hint align="end">This name will be injected into the Issuer NDA</mat-hint>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <mat-form-field class="form-field" appearance="outline" float="always">
                <mat-label>Business Unit Slug</mat-label>
                <input matInput id="buSlug" name="buSlug" [required]="true" placeholder="Business Unit Slug"
                    pattern="^\S+$" formControlName="slug"/>
                <mat-error *ngIf="userForm.invalid">Slug must be non-empty and contain no whitespace.</mat-error>
                <mat-hint align="end">Used for unique URL of a Business Unit in the browser</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <!--  row -->
          <div class="row justify-content-center pt-4">
            <div class="col-12 col-md-6">
              <mat-form-field class="form-field" appearance="outline" float="always">
                <mat-label>Legal Entity Domicile (for NDA)</mat-label>
                <input matInput id="legal" name="legal" placeholder="Legal Entity Domicile (for NDA)" formControlName="legalEntityDomicile"/>
                <mat-hint align="end">This domicile will be injected into the Issuer NDA</mat-hint>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <mat-form-field class="form-field" appearance="outline" float="always">
                <mat-label>Relationship Notes</mat-label>
                <textarea matInput id="iDesc" name="iDesc" formControlName="intakeDescription"
                          style="height:100px; max-height: 100px;"
                ></textarea>
                <mat-hint align="end">Type any relationship notes into this box for future reference</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <!-- row -->
          <div class="row justify-content-center pt-4">
            <div class="col-12 col-md-6">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Business Unit Type</mat-label>
                <input matInput id="type" name="type" formControlName="businessUnitType"/>
                <mat-hint align="end">Investor or Issuer, this can't be changed after the initial registration</mat-hint>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Representative</mat-label>
                <mat-select id="rep" [required]="true" formControlName="representative">
                  <mat-option *ngFor="let e of employees"
                              [value]="e.user?.id">{{e.user?.['firstName'] + ' ' + e.user?.['lastName'] + ' [' + e.user?.['email'] + ']'}}</mat-option>
                </mat-select>
                <mat-error *ngIf="userForm.invalid">Please select one.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <!--  row-->
          <div class="row justify-content-center pt-4">

            <!-- Investor BUs only  -->
            <div class="col-12 col-md-6" *ngIf="userForm.get('businessUnitType')?.value === 'INVESTOR'">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Sub Type (Investor)</mat-label>
                <mat-select id="toi" [required]="true" formControlName="subType">
                  <mat-option *ngFor="let i of lookupService.lookups.investorTypes" [value]="i.value">
                    {{i.value}}
                  </mat-option>
                </mat-select>
                <mat-hint align="end">i.e. Pension Fund, Family Office, etc</mat-hint>
                <mat-error *ngIf="userForm.invalid">Please select one.</mat-error>
              </mat-form-field>
            </div>

            <!-- Issuer BUs only  -->
            <div class="col-12 col-md-6" *ngIf="userForm.get('businessUnitType')?.value === 'ISSUER'">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Sub Type (Issuer)</mat-label>
                <mat-select id="ti" [required]="true" formControlName="subType">
                  <mat-option *ngFor="let i of lookupService.lookups.issuerTypes" [value]="i.value">
                    {{i.value}}
                  </mat-option>
                </mat-select>
                <mat-hint align="end">i.e. GP, Asset Manager, Family Office, etc</mat-hint>
                <mat-error *ngIf="userForm.invalid">Please select one.</mat-error>
              </mat-form-field>
            </div>


            <div class="col-12 col-md-6">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Profile Link</mat-label>
                <input matInput id="profile-link" formControlName="profileLink" readonly />
                <mat-hint align="end">This feature not currently in operation</mat-hint>
              </mat-form-field>
            </div>
          </div>

    
          <div class="row justify-content-center pt-4">
            <div class="col-12 text-end">
              <button mat-raised-button
                      color="primary"
                      type="submit"
                      [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_BUSINESS_UNITS_SIMPLE)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_BUSINESS_UNITS_SIMPLE) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_BUSINESS_UNITS_SIMPLE) ? util.NoAccessMsg : ''"
                      matTooltipPosition="left"
              >
                Save Business Unit Settings
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <br/>
    <hr/>
    <br/>
    <!--    ADMIN SETTINGS-->
    <form [formGroup]="adminForm"
          (ngSubmit)="adminSave()">
      <div class="row justify-content-center">
        <div class="col-12 col-md-11">
          <div class="row">
            <span style="font-size: large; font-weight: bold;">Admin Settings</span>
          </div>
          <br/>
          <!--          row start-->
          <div class="row">
            <div class="col-12 col-md-6">
              <!--              relationship start-->
              <mat-form-field appearance="outline" class="form-field">
                <mat-label>Relationship Indication Date</mat-label>
                <input matInput [matDatepicker]="datepicker" [formControl]="adminForm.controls.relationshipStartDate"/>
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker #datepicker></mat-datepicker>
                <mat-hint align="end">This date pretains to 506(b) offering types & cooling down periods</mat-hint>
              </mat-form-field>
            </div>
            <!-- BU status-->
            <div class="col-12 col-md-6">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Business Unit Status</mat-label>
                <mat-select id="status"
                            [required]="true"
                            formControlName="workflowStatus"
                            [disabled]="!util.hasPermission(util.Permission.CAN_CHANGE_BUSINESS_UNITS_STATUS)"
                            [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_CHANGE_BUSINESS_UNITS_STATUS) ? util.NoAccessCursor: ''}"
                            [matTooltip]="!util.hasPermission(util.Permission.CAN_CHANGE_BUSINESS_UNITS_STATUS) ? util.NoAccessMsg : ''"
                            matTooltipPosition="left"
                >
                  <mat-option *ngFor="let i of lookupService.lookups.businessUnitWorkflowStatus" [value]="i.key">
                    {{i.value}}
                  </mat-option>
                </mat-select>
                <mat-hint align="end">Changing this may send emails to Employees of the Business Unit</mat-hint>
                <mat-error *ngIf="userForm.invalid">Please select one.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <br/>
          <!--          row start-->
          <div class="row pt-2">
            <div class="col-12 col-md-6">
              <!--         Issuer Signed Engagement Agreement<-->
              <mat-label style="font-size: medium;">Accreditation for 506(c) Deals</mat-label>
              <br>
              <em class="text-muted">This needs to be checked for the user to be able to view 506(c) offerings post-NDA</em>  
              <br/>
              <mat-checkbox color="primary" formControlName="accreditationPassed">Accreditation Passed</mat-checkbox>
            </div>
            <div class="col-12 col-md-6">
              <mat-label style="font-size: medium;">Attestation Status</mat-label>
              <br>
              <em class="text-muted">The pertains to the attestation signed by investors upon registration</em>
              <br/>
              <span style="font-weight: lighter;">{{adminForm.get('attestationSignedComments')?.value}}</span>
            </div>
          </div>
          <br/>
          <!--          row start-->
          <div class="row pt-2">
            <div class="col-12 col-md-6">
              <!--         Issuer Signed Engagement Agreement<-->
              <mat-label style="font-size: medium;">Investor Global NDA</mat-label>
              <br>
              <em class="text-muted">Checking this box will allow the investor to see all deals post NDA, unless there are other restrictions</em>
              <br/>
              <mat-checkbox color="primary" formControlName="globalNdaSigned">Global NDA Signed</mat-checkbox>
            </div>
          </div>
          <br/>
          <!-- row-->
          <div class="row">

          </div>
          <!-- BUTTONS-->
          <div class="row pt-4">
            <div class="col-12 text-end">
              <button mat-raised-button
                      color="primary"
                      type="submit"
                      [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_BUSINESS_UNITS_FULL)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_BUSINESS_UNITS_FULL) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_BUSINESS_UNITS_FULL) ? util.NoAccessMsg : ''"
                      matTooltipPosition="left"
              >Save Business Unit Admin Settings
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <br>
  </div>
</div>


