import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {first, map, Subscription} from "rxjs";
import {IBusinessUnit, IBusinessUnitEmployee} from "../../models";
import {MatTableDataSource} from "@angular/material/table";
import {
  Config,
  getConfig,
  getEditingBusinessUnit,
  getLookups,
  getSelectedBusinessUnit,
  initialConfigState,
  initialLookupsState,
  initialThemeState,
  Lookups,
  Theme, toggleLoading
} from "../../../redux";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {ToastrService} from "ngx-toastr";
import {Store} from "@ngrx/store";
import {MatDialog} from "@angular/material/dialog";
import {
  DeleteConfirmationModalComponent, UserCardSize
} from "../../../shared/components";
import {Router} from "@angular/router";
import {UtilService} from "../../../shared/services";
import {HttpErrorResponse} from "@angular/common/http";
// import {ModalSize} from "../../../shared/services/util/util.service";
import {EntityKycService} from "../../../entities/services/entity-kyc.service";
import {toggleNavigating} from "../../../redux/stores/config/config.actions";
// import {IBusinessUnitEntity} from "../../../entities/models/entity";
import { LookupService } from 'src/app/services';
import { DataService } from 'src/app/services/data/data.service';
import { IBusinessUnitEntityKyc } from 'src/app/modules/kyc/models/kyc';

@Component({
  selector: 'app-business-unit-kyc',
  templateUrl: './business-unit-kyc.component.html',
  styleUrls: ['./business-unit-kyc.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BusinessUnitKycComponent implements OnInit {

  entityKycSource: MatTableDataSource<IBusinessUnitEntityKyc> = new MatTableDataSource();
  sortedTableData: IBusinessUnitEntityKyc[] = [];
  entityKycColumns = ['entityName', 'entityType', 'kycName', 'kycStatus', 'created', 'actions'];

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;
  constructor(
              private toastr: ToastrService,
              private store: Store,
              private dialog: MatDialog,
              public lookupService: LookupService,
              private dataService: DataService,
              private router: Router,
              private entityKycService: EntityKycService,
              public util: UtilService
  ) {
   
  }
  ngOnInit() {
    this.subscribeToDatasource();
  }

  subscribeToDatasource(): void {
    console.log("Fetching Business Unit KYC...");
    this.entityKycService.getEntityKyc().pipe(first()).subscribe({
      next: (kycs: IBusinessUnitEntityKyc[]) => {
        console.log(kycs);
        this.sortedTableData = kycs;
        this.entityKycSource = new MatTableDataSource(kycs);
      },
      error: (err: HttpErrorResponse) => {
        console.log("ERROR", err);
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.entityKycSource.filter = filterValue.trim().toLowerCase();

    if (this.entityKycSource.paginator) {
      this.entityKycSource.paginator.firstPage();
    }
  }

  /**
   * Navigate to the entity kyc
   * @param element
   */
  viewEntityKyc(element: any) {
    this.store.dispatch(toggleNavigating({navigating: true}));
    setTimeout(() => {
      this.router.navigate(['/' + this.dataService.currentBusinessUnit?.slug + '/entities/' + element.businessUnitEntityId + '/kyc/' + element.businessUnitEntityKycId]).then(() => {
        this.store.dispatch(toggleNavigating({navigating: false}));
      });
    }, 500);
  }

  //sort
  sortData(sort: Sort) {
    const data = this.sortedTableData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedTableData = data;
      return;
    }
    this.sortedTableData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'entityName':
          return compare(a.businessUnitEntityName, b.businessUnitEntityName, isAsc);
        case 'entityType':
          return compare(a.businessUnitEntityType, b.businessUnitEntityType, isAsc);
        case 'kycName':
          return compare(a.kycName, b.kycName, isAsc);
        case 'kycStatus':
          return compare(a.kycRondeivuStatus, b.kycRondeivuStatus, isAsc);
        case 'created':
          return compare(a.createdOn, b.createdOn, isAsc);
        default:
          return 0;
      }
    });

    this.entityKycSource = new MatTableDataSource(this.sortedTableData);
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
