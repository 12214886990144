<div style="overflow-y: auto; height: calc(100vh - 176px);">
  <div class="container-fluid">
    <!--    GENERAL SETTINGS-->
    <form [formGroup]="userForm"
          (ngSubmit)="save()">
      <div class="row justify-content-center p-4">
        <div class="col-12 col-md-11">
          <span class="rm-title rm-title-3">Deal Settings & Actions</span><br/>
          <span
            class="rm-title rm-title-6 rm-lighter">This is where you can edit the deal settings and workflow state.</span>
        </div>
      </div>


      <div class="row justify-content-center p-4">
        <div class="col-12 col-md-11">
          <div class="row">
            <span style="font-size: large; font-weight: bold;">General Settings</span>
          </div>
          <br/>
          <!-- row start -->
          <div class="row">
            <div class="col-12 col-md-6">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Deal NDA Requirements</mat-label>
                <mat-select id="country"
                            formControlName="dealNdaRequirementType"
                            [required]="true"
                            placeholder="No NDA">
                  <mat-option *ngFor="let c of lookups.dealNdaRequirementTypes" [value]="c.key">
                    {{ c.value }}
                  </mat-option>
                </mat-select>
                <mat-hint align="end">What NDA is required from the investor</mat-hint>
                <mat-error *ngIf="userForm.invalid">Please choose one.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Deal Interest Type</mat-label>
                <mat-select id="dit" formControlName="dealInterestType"
                            [required]="true"
                            placeholder="Subscribe"
                            [matTooltip]="'Not Implemented'">
                  <mat-option *ngFor="let c of lookups.dealInterestTypes" [value]="c.key">
                    {{ c.value }}
                  </mat-option>
                </mat-select>
                <mat-hint align="end">This option is not in use right now</mat-hint>
                <mat-error *ngIf="userForm.invalid">Please choose one.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <br/>
          <!-- row start -->
          <div class="row">
            <div class="col-12 col-md-6">
              <!--             total fund size-->
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Type of Offering</mat-label>
                <mat-select id="secdt" formControlName="secDealType"
                            [required]="true"
                            placeholder="506(c)">
                  <mat-option *ngFor="let c of lookups.secDealTypes" [value]="c.key">
                    {{ c.value }}
                  </mat-option>
                </mat-select>
                <mat-hint align="end">The type of offering has an impact of what the user needs to do to get to POST-NDA</mat-hint>
                <mat-error *ngIf="userForm.invalid">Please choose one.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <mat-form-field appearance="outline" floatLabel="always" class="form-field">
                <mat-label>Expected Syndication Date</mat-label>
                <input matInput [matDatepicker]="datepicker" formControlName="syndicationDate"/>
                <mat-hint align="end">MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker #datepicker></mat-datepicker>
                <!-- <mat-error *ngIf="userForm.invalid">Please select a date.</mat-error> -->
              </mat-form-field>
            </div>
          </div>
          <br/>
          <!-- row start -->
          <div class="row">

            <div class="col-12 col-md-6">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Deal Close Type</mat-label>
                <mat-select id="dct" formControlName="dealCloseType" required placeholder="Draw Down">
                  <mat-option *ngFor="let c of lookups.dealCloseTypes" [value]="c.key">
                    {{ c.value }}
                  </mat-option>
                </mat-select>
                <mat-hint align="end">This changed if the deal displays the "Syndication" tab or the "Subscription" tab</mat-hint>
                <mat-error *ngIf="userForm.invalid">Please choose one.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-12 col-md-6">
              <!--     Expected Close Date-->
              <mat-form-field appearance="outline" floatLabel="always" class="form-field">
                <mat-label>Expected Close Date</mat-label>
                <input matInput [matDatepicker]="datepicker2" formControlName="closeDate"/>
                <mat-hint align="end">MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="datepicker2"></mat-datepicker-toggle>
                <mat-datepicker #datepicker2></mat-datepicker>
                <mat-error *ngIf="userForm.invalid">Please select a date.</mat-error>
              </mat-form-field>
            </div>
          </div>

          <!-- Deal MNPI  -->
          <div class="row pt-4">
            <div class="col-12">
              <!--  Deal is MNPI-->
              <mat-radio-group formControlName="dealIsMnpi">
                <mat-label>Does this deal contain Material Non-Public Information (MNPI)?</mat-label>
                <br />
                <mat-radio-button *ngFor="let subtask of [{key:'yes',value:true},{key:'no',value:false}]"
                                  color="primary"
                                  [value]="subtask.value">
                  {{ subtask.key }}&nbsp;
                </mat-radio-button>
              </mat-radio-group>
              <mat-error style="font-size: x-small;" *ngIf="userForm.invalid">Please choose one.</mat-error>
            </div>
          </div>
          <br/>


          <!-- SUBDOC -->
          <div class="row" style="padding-top: 25px;padding-bottom: 25px;">
            <div class="col-12 col-md-11">
              <div class="row">
                <span style="font-size: large; font-weight: bold;">Subdoc Settings</span>
              </div>
            </div>
          </div>

          <!--  row start -->
          <div class="row">
            <div class="col-12 col-md-6">
              <mat-form-field class="form-field" float="always" appearance="outline">
                <mat-label>SubDoc Concord Template Id</mat-label>
                <input matInput id="tempId" type="text" placeholder="SubDoc Concord Template Id" formControlName="subDocsTemplateId"/>
                <mat-hint>{{ 'Your default SubDoc template ID is: ' + userForm.get('subDocsDefaultTemplateId')?.value }}</mat-hint>
              </mat-form-field>
            </div>

            <div class="col-12 col-md-6">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Number of Issuer SubDoc Signers</mat-label>
                <mat-select formControlName="numberOfIssuerSignatories"
                            [required]="true">
                  <mat-option [value]="1">Single Signer</mat-option>
                  <mat-option [value]="2">Dual Signer</mat-option>
                </mat-select>
                <mat-hint align="end">The number of Issuers that are REQUIRED to sign the SubDoc</mat-hint>
                <mat-error *ngIf="userForm.invalid">Please choose one.</mat-error>
              </mat-form-field>
            </div>
          </div>

          <!-- row start -->
          <div class="row pt-4">
            <mat-checkbox color="primary" formControlName="issuerRequiresApprovalBeforeSignatures">
              Issuer requires approval before signatures<br />
              <mat-hint>This inserts a step between the last investor signatory and the SubDoc being sent to the first issuer signatory</mat-hint>
            </mat-checkbox>
          </div>
         
          <!-- BUTTONS -->
          <div class="row" style="padding-top: 50px;">
            <div class="col-12 text-end">
              <button mat-raised-button
                      color="primary"
                      type="submit"
                      [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SETTINGS)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SETTINGS) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SETTINGS) ? util.NoAccessMsg : ''"
                      matTooltipPosition="left"
              >Save Primary Deal Settings
              </button>
            </div>
          </div>



          <div class="row pt-5">
            <button mat-raised-button class="col-6"
                    color="warn"
                    type="button"
                    (click)="getSubDocTemplateErrors()"
                    [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SETTINGS)"
                    [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SETTINGS) ? util.NoAccessCursor: ''}"
                    [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SETTINGS) ? util.NoAccessMsg : ''"
                    matTooltipPosition="left"
            >Check Concord template for potential errors
            </button>
            <br>
            <mat-hint>After saving use this button to run an initial checks on the Concord template, with the Id saved on the form</mat-hint>

            <br><br>
            <!-- SUBDOC TEMPLATE ERRORS START -->
            <div class="row" style="margin-top: 25px; padding:10px; background-color: whitesmoke;"
                  *ngIf="subdocTemplateErrors.length > 0">
              <div class="col-12">
                <span style="color: red; font-weight: bold;">Sub Doc Template Errors</span>
              </div>
              <div class="col-12" *ngFor="let error of subdocTemplateErrors">
                <span style="color: red;">- {{ error }}</span>
              </div>
            </div>
            <!-- SUBDOC TEMPLATE ERRORS END -->
          </div>


        </div>
      </div>
      <br/>
    </form>
    <br/>
    <br/>

    <!-- ADMIN SETTINGS -->
    <form [formGroup]="adminForm"
          (ngSubmit)="adminSave()">
      <div class="row justify-content-center"
           style="padding-bottom: 25px;"
           [hidden]="!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_SETTINGS_ADMIN)"
      >
        <div class="col-12 col-md-11">
          <hr/>
          <div class="row">
            <span style="font-size: large; font-weight: bold;">Admin Settings</span>
          </div>
          <br/>
          <!-- row start -->
          <div class="row">
            <div class="col-12 col-md-6">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Issuer's Deal NDA Signed Status</mat-label>
                <mat-select id="a1" formControlName="issuerNdaStatus"
                            [required]="true"
                            placeholder="Pending">
                  <mat-option *ngFor="let c of lookups.issuerNDAStatusTypes" [value]="c.key">
                    {{ c.value }}
                  </mat-option>
                </mat-select>
                <mat-hint align="end">This will not impact the ability to go LIVE</mat-hint>
                <mat-error *ngIf="adminForm.invalid">Please choose one.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-12 col-md-6">
              <!--         Issuer Signed Engagement Agreement<-->
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Issuer's Engagement Agreement Signed Status</mat-label>
                <mat-select id="a3" formControlName="issuerEngagementAgreementStatus"
                            [required]="true"
                            placeholder="Pending">
                  <mat-option *ngFor="let c of lookups.issuerNDAStatusTypes" [value]="c.key">
                    {{ c.value }}
                  </mat-option>
                </mat-select>
                <mat-hint align="end">This will not impact the ability to go LIVE</mat-hint>
                <mat-error *ngIf="adminForm.invalid">Please choose one.</mat-error>
              </mat-form-field>
            </div>

          </div>

          <br/>

          <!-- row start -->
          <div class="row">
            <div class="col-12 col-md-12">
              <!-- Issuer Can Edit Deal Data Room -->
              <mat-label style="font-size: medium;">Deal Dataroom - Issuer Can Edit Deal Data Room</mat-label>
              <br />
              <em class="text-muted">This controls whether the Issuer has edit access to the main deal dataroom, ie. Add, delete, move files</em>
              <br/>
              <mat-radio-group formControlName="issuerCanEditDealDataroom">
                <mat-radio-button *ngFor="let obj of [{key:'Yes',value:true},{key:'No',value:false}]" color="primary"
                                  [value]="obj.value">
                  {{ obj.key }}&nbsp;
                </mat-radio-button>
              </mat-radio-group>
              <mat-error style="font-size: x-small;" *ngIf="adminForm.invalid">Please choose one.</mat-error>
            </div>
          </div>
          <br/>
          <!-- row start -->
          <div class="row">
            <div class="col-12 col-md-12">
              <!-- Issuer Can Edit Deal Data Room -->
              <mat-label style="font-size: medium;">DATAROOM - Investor Can Download Entire Data Room</mat-label>
              <br />
              <em class="text-muted">This controls whether any Investor has the ability to "download all" the MAIN dataroom in a zip file</em>
              <br/>
              <mat-radio-group formControlName="investorCanDownloadDataroom">
                <mat-radio-button *ngFor="let obj of [{key:'Yes',value:true},{key:'No',value:false}]" color="primary"
                                  [value]="obj.value">
                  {{ obj.key }}&nbsp;
                </mat-radio-button>
              </mat-radio-group>
              <mat-error style="font-size: x-small;" *ngIf="adminForm.invalid">Please choose one.</mat-error>
            </div>
          </div>
          <br/>
          <!-- row start -->
          <div class="row">
            <div class="col-12 col-md-6">
              <!-- Issuer Can Edit Deal Data Room -->
              <mat-label style="font-size: medium;">Issuer Can Edit Deal Information Card</mat-label>
              <br/>
              <em class="text-muted">This feature is not currently in use</em>
              <br />
              <mat-radio-group formControlName="issuerCanEditDealCardInformation" [disabled]="true">
                <mat-radio-button *ngFor="let obj of [{key:'Yes',value:true},{key:'No',value:false}]" color="primary"
                                  [value]="obj.value">
                  {{ obj.key }}&nbsp;
                </mat-radio-button>
              </mat-radio-group>
              <mat-error style="font-size: x-small;" *ngIf="adminForm.invalid">Please choose one.</mat-error>
            </div>
          </div>
          <br/>
          <!-- BUTTONS -->
          <div class="row" style="padding-top: 50px;">
            <div class="col-12 text-end">
              <button mat-raised-button
                      color="primary"
                      type="submit"
                      [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SETTINGS_ADMIN)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SETTINGS_ADMIN) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SETTINGS_ADMIN) ? util.NoAccessMsg : ''"
                      matTooltipPosition="left"
              >Save Deal Admin Settings
              </button>
            </div>
          </div>

        </div>
      </div>
    </form>
    <br/>

    <form [formGroup]="workflowForm"
          (ngSubmit)="workFlowSave()">
      <div class="row justify-content-center"
           style="padding-bottom: 150px;"
           [hidden]="!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_SETTINGS_ADMIN)"
      >
        <div class="col-12 col-md-11">
          <!-- DEAL WORKFLOW -->
          <hr/>
          
          <div class="row" style="padding-top: 50px;">
            <div class="row">
              <span style="font-size: large; font-weight: bold;">Deal Workflow</span>
              <em class="muted-text">Note: Setting the deal status to <b>LIVE</b> will send an email to the issuer.</em>
            </div>
            <div class="col-12 col-md-6 pt-4">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Deal Status</mat-label>
                <mat-select id="a2"
                            formControlName="dealWorkflow"
                            [required]="true"
                            placeholder="Live"
                            [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_STATUS)"
                            [ngStyle]="{'cursor':(!util.hasPermission(util.Permission.CAN_EDIT_DEAL_STATUS) ? util.NoAccessCursor: '')}"
                            [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_STATUS) ? util.NoAccessMsg : ''"
                            matTooltipPosition="left"
                >
                  <mat-option *ngFor="let c of lookups.dealWorkflowStatus" [value]="c.key">
                    {{ c.value }}
                  </mat-option>
                </mat-select>
                <mat-hint align="end">Set the Deal Status, this can set workflow into motion</mat-hint>
                <mat-error *ngIf="adminForm.invalid">Please choose one.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row" style="padding-top: 50px;">
            <div class="col-12 text-end">
              <button mat-raised-button
                      color="primary"
                      type="submit"
                      [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_STATUS)"
                      [ngStyle]="{'cursor':(!util.hasPermission(util.Permission.CAN_EDIT_DEAL_STATUS) ? util.NoAccessCursor: '')}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_STATUS) ? util.NoAccessMsg : ''"
                      matTooltipPosition="left"
              >Save Deal Workflow Status
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

