import {Component, OnDestroy, OnInit} from '@angular/core';
import {first, Observable, Subscription, tap} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {
  Config,
  getConfig,
  getDealSyndication,
  getLookups,
  getSelectedBusinessUnit,
  getSelectedDeal,
  initialConfigState,
  initialLookupsState,
  Lookups,
  toggleLoading, updateDealSyndication
} from "../../../redux";
import {IDealCard} from "../../../shared/models";
import {IBusinessUnit} from "../../../business-units/models";
import {DealService, SyndicationService} from "../../services";
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {LockInModalComponent} from "../lock-in-modal/lock-in-modal.component";
import {NotesModalComponent} from "../notes-modal/notes-modal.component";
import {
  ConfirmationModalComponent,
  DeleteConfirmationModalComponent, PromptModalComponent
} from "../../../shared/components";
import {UtilService} from "../../../shared/services";
import {ModalSize} from "../../../shared/services/util/util.service";
import {IDealSyndication} from "../../models";
import {
  IEntityDTO,
  ISignatoryDTO,
  ISubdocAccessPerson,
  ISyndicationItem,
  ISyndicationSubdoc
} from "../../models/syndication";
import {SubdocService} from "../../services/subdoc/subdoc.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {
  DealSubscriptionSubdocEditModalComponent,
  DealSubscriptionSubdocFormModalComponent,
  DealSubscriptionSubdocModalComponent
} from "../index";
import {FormControl, FormGroup} from "@angular/forms";
import {LookupService, UuidService} from "../../../../services";
import { AllocationEditModalComponent } from '../allocation-edit-modal/allocation-edit-modal.component';
import { SubDocsPersonModel } from '../../models/entities-for-subdoc';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-syndication',
  templateUrl: './syndication.component.html',
  styleUrls: ['./syndication.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class SyndicationComponent implements OnInit, OnDestroy {

  // config$: Subscription = new Subscription();
  // lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  selectedDeal$: Subscription = new Subscription();
  selectedDeal: IDealCard = {} as unknown as IDealCard;
  syndicationItem$: Subscription = new Subscription();

  // dealSyndication$: Observable<IDealSyndication>;

  dataSource: MatTableDataSource<ISyndicationItem> = new MatTableDataSource();
  matchesColsToDisplay = ['businessUnit', 'ioi', 'allocation', 'notes', 'status', 'date', 'actions'];
  matchesColsToDisplayWithExpand = [...this.matchesColsToDisplay, 'expand'];
  subdocCols = ['entity', 'amount', 'status', 'date', 'created', 'actions'];
  config: Config = initialConfigState;

  syndication!: IDealSyndication;
  reason: string = '';

  selectedSubdocs: ISyndicationSubdoc[] = [];
  businessUnitEntities: IEntityDTO[] = [];
  dealSignatories: any[] = [];

  currentSignatoriesStr = '';

  bu: IBusinessUnit = {} as unknown as IBusinessUnit;
  dealId = '';

  form = new FormGroup({
    investingEntity: new FormControl(),
    selectedSignatory: new FormControl(),
    subdocSignatories: new FormControl(),
  });

  protected readonly FORM_INV_ENT = 'investingEntity';
  protected readonly FORM_SELECTED_SIG = 'selectedSignatory';
  protected readonly FORM_SIGNATORIES = 'subdocSignatories';

  constructor(
    private dealService: DealService,
    private syndicationService: SyndicationService,
    private subDocService: SubdocService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private toastrService: ToastrService,
    public dialog: MatDialog,
    public util: UtilService,
    private uuid: UuidService,
    private lookupService: LookupService
  ) {

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.bu = Object.assign({}, bu);
    });

    this.subscribeToDatasource();

    // this.selectedDeal$ = this.store.select(getSelectedDeal).subscribe((deal: IDealCard) => {
    //   if (this.selectedDeal.id != deal.id) {
    //     this.selectedDeal = deal;
    //     this.subscribeToDatasource();
    //   } else {
    //     this.selectedDeal = deal;
    //   }
    // });

    // this.syndicationItem$ = this.store.select(getDealSyndication).subscribe((dealSyndication: IDealSyndication) => {
    //   this.syndication = Object.assign({}, dealSyndication);

    //   // console.log("Syndication", this.syndication, this.syndication.syndicationItems)
    //   // this.syndication.syndicationItems?.forEach(x => {
    //   //   console.log(x);
    //   //   x.id = "1231231";
    //   //   // x.expanded = true;
    //   // });
    //   this.dataSource = new MatTableDataSource(this.syndication.syndicationItems);
    // });
  }

  ngOnInit() {
    // console.log("syndication service start")
  }

  ngOnDestroy() {
    // this.config$.unsubscribe();
    // this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedDeal$.unsubscribe();
    this.syndicationItem$.unsubscribe();
  }

  subscribeToDatasource(): void {
    this.getSyndication();
    this.getDealSignatories();
  }

  getSyndication() {
    console.log("Fetching Syndication...");
    this.syndicationService.getSyndication().subscribe({
      next: (result: IDealSyndication) => { 
        console.log("Deal Syndication", result)
        this.syndication = result;
        this.dataSource.data = this.syndication.syndicationItems;

        // only get the issuer Entity if 'showDealSignatoriesSection' is true
        if (this.syndication.showDealSignatoriesSection) {
          this.getEntitiesForBusinessUnit(result.issuerBusinessUnitId);
        }
        // this.store.dispatch(updateDealSyndication({dealSyndication: Object.assign({}, result)}));
      }
    });

    // this.store.dispatch(toggleLoading({loading: true}));
    // this.syndicationService.getSyndication().subscribe({
    //   next: (syndication: any) => {
    //     this.syndication = syndication;
    //     // admin - get the businessUnitId from the syndication item
    //     let businessUnitId = this.util.isType('admin') ? this.syndication.issuerBusinessUnitId : this.config.selected_business_unit.businessUnitId;
    //     this.getEntitiesForBusinessUnit(businessUnitId);
    //     this.store.dispatch(updateDealSyndication({dealSyndication: syndication}));
    //     this.dataSource = new MatTableDataSource(this.syndication.syndicationItems);
    //     this.store.dispatch(toggleLoading({loading: false}));
    //   }, error: err => {
    //     this.store.dispatch(toggleLoading({loading: false}));
    //     this.toastrService.error("Unable to get syndication!", $localize`:@@companyName:Rondeivu`);
    //   }
    // });
  }


  // ISSUER SIGNATORIES START
  changeEntity(event: any) {
    this.form.get(this.FORM_INV_ENT)?.setValue(event.value);
    this.form.get(this.FORM_SELECTED_SIG)?.setValue(null);
    this.form.get(this.FORM_SIGNATORIES)?.setValue([]);
  }

  changeSelectedSignatory(event: any) {
    this.form.get(this.FORM_SELECTED_SIG)?.setValue(event.value);
  }

  getKycSignatoriesFromSelectedEntity(): any[] {
    return this.getSelectedEntity()?.kycSignatories || [];
  }

  getSelectedEntity() {
    return this.form.get(this.FORM_INV_ENT)?.value || {} as unknown as IEntityDTO;
  }

  getErrorMsg() {
    return this.getSelectedEntity().kycErrorMessage;
  }

  getSignatories() {
    return this.form.get(this.FORM_SIGNATORIES)?.value || [];
  }

  isAddDisabled() : boolean {
    if (this.syndication == null)
      return false;

    return this.getSignatories().length >= this.syndication.numberOfIssuerSubDocSignatories;
  }

  getDisabledMsg() : string {
    if (this.syndication == null)
      return 'n/a';

    return this.syndication.numberOfIssuerSubDocSignatories == 1 ? 'Maximum of one (1) Signatory' : 'Maximum of two (2) Signatories';
  }

  addNewSignatory() {
    if (!this.isAddDisabled()) {
      let signatories: any[] = this.form.get(this.FORM_SIGNATORIES)?.value || [];
      signatories?.push({
        id: this.uuid.generateUUID(),
        fullName: '',
        email: '',
        accessType: null,
        external: true
      });
      this.form.get(this.FORM_SIGNATORIES)?.setValue(signatories);
    }
  }

  addSelectedSignatory() {
    if (!this.isAddDisabled()) {
      let selected = this.form.get(this.FORM_SELECTED_SIG)?.value || {} as unknown as ISignatoryDTO;
      let signatories: any[] = this.form.get(this.FORM_SIGNATORIES)?.value || [];
      signatories?.push({
        id: selected.businessUnitEntityKycAuthorizedSignatoryId,
        fullName: selected.name,
        email: '',
        accessType: null,
        external: false
      });
      this.form.get(this.FORM_SIGNATORIES)?.setValue(signatories);
    }
  }

  removeSignatory(idx: number) {
    let arr = this.form.get(this.FORM_SIGNATORIES)?.value;
    arr.splice(idx, 1);
    this.form.get(this.FORM_SIGNATORIES)?.setValue(arr);
  }

  getEntitiesForBusinessUnit(businessUnitId: string) {
    console.log("Fetching Entities...");
    // this.store.dispatch(toggleLoading({loading: true}));
    this.subDocService.getSubdocEntities(businessUnitId).subscribe({
      next: (entities: IEntityDTO[]) => {
        console.log("Fetched Entities", entities);
        this.businessUnitEntities = entities;
        // this.store.dispatch(toggleLoading({loading: false}));
      }, error: err => {
        // this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.error("Unable to get entities!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  getDealSignatories() {
    console.log("Fetching Deal Signatories...");
    this.dealService.getDealSignatories().subscribe({
      next: (signatories: SubDocsPersonModel[]) => {
        console.log("Current Signatories", signatories)
        this.dealSignatories = Object.assign(signatories);
        this.setCurrentSignatoriesString(this.dealSignatories);
        this.form.get(this.FORM_SIGNATORIES)?.setValue(signatories);
      }, error: err => {
        this.toastrService.error("Unable to get deal signatories!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  saveDealSignatories() {
    const payload = this.form.get(this.FORM_SIGNATORIES)?.value || [];
    console.log("Updating Deal Signatories...");
    this.store.dispatch(toggleLoading({loading: true}));
    this.dealService.updateDealSignatories(payload).subscribe({
      next: (signatories: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.success("Deal Signatories Updated!", $localize`:@@companyName:Rondeivu`);
      }, error: err => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.error("Unable to update deal signatories!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  private setCurrentSignatoriesString(signatories: any) {
    this.currentSignatoriesStr = 'Current Signatories: ';
    for (let i = 0; i <= signatories.length - 1; i++) {
      this.currentSignatoriesStr += signatories[i].fullName + (i == signatories.length - 1 ? '' : ', ');
    }
  }

  // ISSUER SIGNATORIES END
  changeLockStatus(locked: boolean) {

    this.dialog.open(LockInModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      maxWidth: '800px',
      data: { locked },
      disableClose: true
    })
    .afterClosed().subscribe(result => {
      console.log("Dialog output:", result);
      if (result === true) {


        let payload = {
          isLocked: locked,
          silentPoolLockChange: true // this is for backwards compatability
        };
    
        this.store.dispatch(toggleLoading({loading: true}));
        this.syndicationService.changeLockStatus(payload).subscribe({
          next: (res: any) => {
            // this.matchesSource = new MatTableDataSource(this.syndication.syndicationItems);
            this.syndication.issuerPoolIsLocked = locked;
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastrService.success(locked ? "Pool Locked!" : "Pool Unlocked!", $localize`:@@companyName:Rondeivu`);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastrService.error("Unable to change lock status!", $localize`:@@companyName:Rondeivu`);
          }
        });

      }
    });

  }

  // lockSyndicationPool(element: IDealSyndication) {
  //   const lockRef = this.dialog.open(LockInModalComponent, {
  //     height: 'auto',
  //     width: this.util.getModalWidth(ModalSize.SMALL),
  //     maxWidth: '800px',
  //     data: {},
  //     disableClose: true
  //   });

  //   lockRef.afterClosed().subscribe(result => {
  //     // console.log("Dialog output:", result);
  //     if (!!result) {
  //       let silent = !result.notify;
  //       this.changeLockStatus(true);
  //     }
  //   });
  // }

  // unlockSyndicationPool(element: IDealSyndication) {
  //   this.changeLockStatus(false);
  // }

  editAllocationModal(element: ISyndicationItem) {
    console.log("Edit Allocation", element)
    this.dialog.open(AllocationEditModalComponent, {
      height: 'auto',
      minWidth: '600px',
      data: element,
      disableClose: true
    })
    .afterClosed().subscribe(result => {
      console.log("result", result);
      if (!!result) {
        // this.getSyndication();
        this.syndicationService.updateSyndication(result).pipe(first()).subscribe({
          next: () => {
            this.getSyndication();
          },
          error: (err: HttpErrorResponse) => {
            this.toastrService.error("Could not update entry");
          }
        })

      }
    });
  }

  save(element: any) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.syndicationService.updateSyndication(element).subscribe({
      next: (res: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.success("Syndication Updated!", $localize`:@@companyName:Rondeivu`);
      }, error: err => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.error("Unable to update syndication!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  delete(element: any) {
    const delRef = this.dialog.open(DeleteConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      data: element,
      disableClose: true
    });

    delRef.afterClosed().subscribe(result => {
      // console.log("Dialog output:", result);
      if (!!result) {
        this.store.dispatch(toggleLoading({loading: true}));
        this.syndicationService.deleteSyndication(result).subscribe({
          next: (res: any) => {
            //replace in list
            if (!!result) {
              let idx = -1;
              for (let i = 0; i <= this.syndication.syndicationItems?.length - 1; i++) {
                if (this.syndication.syndicationItems[i].id == result.id) {
                  idx = i;
                  break;
                }
              }
              this.syndication.syndicationItems.splice(idx, 1);
              this.dataSource = new MatTableDataSource(this.syndication.syndicationItems);
            }
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastrService.success("Syndication Removed!", $localize`:@@companyName:Rondeivu`);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastrService.error("Unable to remove syndication!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  showNotes(notes: string) {
    const delRef = this.dialog.open(NotesModalComponent, {
      height: 'auto',
      width: 'auto',
      data: {notes: notes},
      disableClose: true
    });
  }

  getStatusString(element: ISyndicationItem) {
    return this.lookupService.getLookupValueForKey(this.lookupService.lookups.subscriptionStatus, element.status);
  }
  getSubdocStatusString(element: ISyndicationItem) {
    return this.lookupService.getLookupValueForKey(this.lookupService.lookups.subDocStatus, element.status);
  }
  getRestrictionType(element: ISyndicationItem) : string {
    return this.lookupService.getLookupValueForKey(this.lookupService.lookups.syndicationRestrictionTypes, element.restrictionType);
  }

  /**
   * Opens the sub doc modal
   * @param element
   */
  openSubdocModal(element: ISyndicationItem) {
    const delRef = this.dialog.open(DealSubscriptionSubdocModalComponent, {
      height: 'auto',
      width: 'auto',
      data: {
        businessUnitId: element.investor.id,
        subscriptionId: element.id,
        amount: element.allocationAmount
      },
      disableClose: true
    });

    delRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.getSyndication();
      }
    });
  }

  /**
   * Edit the sub doc
   * @param element
   */
  editSubdoc(element: ISyndicationSubdoc) {
    const delRef = this.dialog.open(DealSubscriptionSubdocEditModalComponent, {
      height: 'auto',
      width: 'auto',
      data: {
        subdoc: element
      },
      disableClose: true
    });

    delRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.getSyndication();
      }
    });
  }

  promptSave(element: any) {
    const modalRef = this.dialog.open(PromptModalComponent, {
      height: 'auto',
      width: 'auto',
      data: {
        title: 'Save Subscription',
        message: 'Would you like to save the subscription?',
        btnText: 'SAVE SUBSCRIPTION'
      },
      disableClose: true
    });

    modalRef.afterClosed().subscribe(result => {
      if (result) {
        this.save(element);
      }
    });
  }

  downloadSubdoc(element: ISyndicationSubdoc) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.subDocService.getSubdocPdf(element.id).subscribe({
      next: (res: Blob) => {
        let dt = element.subscriptionDate.split('T')[0];
        let filename = element.entityName + '-' + dt + '.pdf';
        let blob: Blob = res as Blob;
        let a = document.createElement('a');
        a.download = filename;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.store.dispatch(toggleLoading({loading: false}));
      },
      error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.error("Unable to download file!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  fillSubdoc(element: ISyndicationSubdoc) {
    const delRef = this.dialog.open(DealSubscriptionSubdocFormModalComponent, {
      height: 'auto',
      width: 'auto',
      minWidth: '900px',
      data: {
        subdoc: element
      },
      disableClose: true
    });

    delRef.afterClosed().subscribe(result => {
      if (!!result) {
      }
    });
  }


  /**
   * Delete the sub doc
   * @param element
   */
  deleteSubdoc(element: ISyndicationSubdoc) {
    const delRef = this.dialog.open(DeleteConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      data: {
        subdoc: element
      }
    });

    delRef.afterClosed().subscribe(result => {
      if (!!result) {
        let id = result.subdoc.id;
        this.store.dispatch(toggleLoading({loading: true}));
        this.subDocService.deleteSubdoc(id).subscribe({
          next: (res: any) => {
            this.getSyndication();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastrService.success("Sub Doc Removed!", $localize`:@@companyName:Rondeivu`);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastrService.error("Unable to remove sub doc!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  /**
   * Approve the investor sub doc
   * @param element
   */
  approveInvestorSubdoc(element: ISyndicationSubdoc) {
    const confirm = this.dialog.open(ConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      data: {
        subdoc: element
      }
    });

    confirm.afterClosed().subscribe(result => {
      if (!!result) {
        let id = result.subdoc.id;
        this.store.dispatch(toggleLoading({loading: true}));
        this.subDocService.approvalSendToInvestor(id, {}).subscribe({
          next: (res: any) => {
            this.getSyndication();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastrService.success("Sub Doc Approved!", $localize`:@@companyName:Rondeivu`);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastrService.error("Unable to approve sub doc!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  /**
   * Approve Issuer the sub doc
   * @param element
   */
  approveIssuerSubdoc(element: ISyndicationSubdoc) {
    const confirm = this.dialog.open(ConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      data: {
        subdoc: element
      }
    });

    confirm.afterClosed().subscribe(result => {
      if (!!result) {
        let id = result.subdoc.id;
        this.store.dispatch(toggleLoading({loading: true}));
        this.subDocService.approvalSendToIssuer(id, {}).subscribe({
          next: (res: any) => {
            this.getSyndication();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastrService.success("Sub Doc Issuer Approved!", $localize`:@@companyName:Rondeivu`);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastrService.error("Unable to issuer approve sub doc!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }
}
