<div class="rm-header">
  <span>Confirm</span>
</div>
<mat-dialog-content>
  <div style="padding-bottom: 25px; padding-top: 25px; min-width: 150px;">
    <span style="font-size: large;">Please confirm you would like to perform this action.</span>
  </div>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: space-between;">
  <div>
    <button mat-raised-button (click)="dialogRef.close()">Cancel</button>
  </div>
  <div>
    <button mat-raised-button color="primary" (click)="dialogRef.close(data)">CONFIRM</button>
  </div>
</mat-dialog-actions>
