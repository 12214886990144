<div class="rm-header">
  <span>{{data.isEditing ? 'Edit' : 'Add'}} Media</span>
</div>

<div>
  <div style="padding-top:25px;">
    <form [formGroup]="userForm"
          (ngSubmit)="save()">
      <mat-dialog-content class="row">
        <!--      PREVIEW    -->
        <div class="col-12 d-none d-md-block">
          <div class="media">
            <div class="row">
              <div class="col-12">
                <span style="font-size: large; font-weight: bold;">Media Preview</span>
              </div>
            </div>
            <div style="padding: 12px; display: flex;">
              <div style="width: 250px; min-width: 100px; max-width: 250px;">
                <img class="img-top" [src]="userForm.get('imageUrl')?.value || ''" style="border-radius: 12px 0 0 12px;"
                     [default]="'/assets/images/image-placeholder.png'"
                     alt="Media Image Broken :(">
              </div>
              <div class="info-side">
                <div class="row">
                  <div class="col-12">
                    <span class="title">{{userForm.get('title')?.value}}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="description">
                    <span>{{userForm.get('description')?.value}}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-9">
                    <span style="font-weight: lighter;">{{userForm.get('linkUrlPreview')?.value}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--      DETAILS    -->
        <div class="col-12">
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="row">
                <span style="font-size: large; font-weight: bold;">Media Details</span>
              </div>
              <br/>
              <!--        image input-->
              <div class="row">
                <div class="col-6">
                  <span style="font-size: medium; padding-top: 20px;">Media Thumbnail<sup>*</sup></span><br/>
                  <span style="font-size: small; color: grey">PNG and JPG files under 2MB are allowed.</span><br/>
                  <input style="padding: 0; height:auto;" type="file" placeholder="Choose File..."
                         (change)="addImageFile($event)" #fileUpload/>
                </div>
              </div>
              <!--          row start-->
              <div class="row" style="padding-top: 20px;">
                <div class="col-12">
                  <mat-form-field class="form-field" appearance="outline">
                    <mat-label style="font-size: medium;">Title</mat-label>
                    <input class="form-input" matInput formControlName="title" [required]="true"
                           placeholder="e.g. Project Alpha"/>
                  </mat-form-field>
                </div>
              </div>
              <!--          row start-->
              <div class="row">
                <div class="col-12">
                  <mat-form-field class="form-field" appearance="outline">
                    <mat-label style="font-size: medium;">Description</mat-label>
                    <input class="form-input" matInput formControlName="description" [required]="true"
                           placeholder="Type here..."/>
                  </mat-form-field>
                </div>
              </div>
              <!--          row start-->
              <div class="row">
                <div class="col-12">
                  <mat-form-field class="form-field" appearance="outline">
                    <mat-label style="font-size: medium;">Link URL</mat-label>
                    <input class="form-input" matInput formControlName="linkUrl" [required]="true"
                           placeholder="Format: http://..."/>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-dialog-content>

      <!-- BUTTONS-->
      <mat-dialog-actions class="row">
        <div class="col-6 text-start">
          <button mat-raised-button type="button" (click)="dialogRef.close()">Cancel
          </button>
        </div>
        <div class="col-6 text-end">
          <button mat-raised-button color="primary" type="submit" [disabled]="userForm.invalid">SUBMIT
          </button>
        </div>
      </mat-dialog-actions>

    </form>
  </div>
</div>


