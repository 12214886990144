import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-voting-rights-owner',
  templateUrl: './add-voting-rights-owner.component.html',
  styleUrls: ['./add-voting-rights-owner.component.scss']
})
export class AddVotingRightsOwnerComponent {

  fg: FormGroup = new FormGroup({
      ownerOfVotingRights: new FormControl(undefined, Validators.required),
      publiclyTradedCompany: new FormControl(undefined, Validators.required),
      ownership: new FormControl(undefined, [Validators.required, Validators.min(0), Validators.max(100)])
    });

}
