<div style="height:calc(100vh - 176px); overflow-y: auto;">
  <div class="container-fluid">
      
    <div class="row justify-content-center pt-4">
      <div class="col-8 col-md-9">
        <span class="rm-title rm-title-3">KYC Verification</span><br/>
        <span
          class="rm-title rm-title-6 rm-lighter">These are of the kyc processes for the selected business unit:</span>
      </div>
      <div class="col-4 col-md-2 text-end">
        
      </div>
    </div>
  
    <!--    -->
    <!-- TABLE START   -->
    <!--    -->
    <div class="row justify-content-center d-none d-md-flex pt-4 pb-2">
      <div class="col-12 col-md-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <table mat-table [dataSource]="entityKycSource"
                     [multiTemplateDataRows]="true" matSort (matSortChange)="sortData($event)">
                <!-- Search Header -->
                <ng-container matColumnDef="table-name">
                  <th colspan="3" mat-header-cell *matHeaderCellDef>
                    <span style="font-size: large;">Entities</span>
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="3" mat-header-cell *matHeaderCellDef>
                    <!-- <form>
                      <mat-form-field style="width: 100%;">
                        <mat-label>Search</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input/>
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form> -->
                  </th>
                </ng-container>
                <!-- COLUMN START -->
                <ng-container matColumnDef="entityName">
                  <th colspan="1" mat-header-cell
                      *matHeaderCellDef class="inner-header" mat-sort-header="entityName">Entity Name
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element.businessUnitEntityName }}</span>
                  </td>
                </ng-container>
                <!-- COLUMN START -->
                <ng-container matColumnDef="entityType">
                  <th colspan="1" mat-header-cell
                      *matHeaderCellDef class="inner-header" mat-sort-header="entityType">Entity Type
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ lookupService.getLookupValueForKey(lookupService.lookups.businessUnitEntityType, element.entityType) }}</span>
                  </td>
                </ng-container>
                <!-- COLUMN START -->
                <ng-container matColumnDef="kycName">
                  <th mat-header-cell
                      *matHeaderCellDef class="inner-header" mat-sort-header="kycName"> KYC Name
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element.kycName }}</span>
                  </td>
                </ng-container>
                <!-- COLUMN START -->
                <ng-container matColumnDef="kycStatus">
                  <th mat-header-cell
                      *matHeaderCellDef class="inner-header" mat-sort-header="kycStatus"> KYC Status
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ lookupService.getLookupValueForKey(lookupService.lookups.kycRondeivuStatus, element.kycRondeivuStatus)}}</span>
                  </td>
                </ng-container>
                <!-- COLUMN START -->
                <ng-container matColumnDef="created">
                  <th mat-header-cell
                      *matHeaderCellDef class="inner-header" mat-sort-header="created"> Created
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="date-stacked">
                      <span>{{ element.created | date : 'mediumDate' }}</span>
                      <span>{{ element.created | date : 'shortTime' }}</span>
                    </div>
                  </td>
                </ng-container>
                <!-- COLUMN START -->
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef class="inner-header"> Actions</th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-icon-button type="button"
                            (click)="viewEntityKyc(element)"
                            title="View Entity KYC">
                      <mat-icon>east</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <!-- ROWS START -->
                <tr mat-header-row *matHeaderRowDef="['table-name','search-header'];"></tr>
                <tr mat-header-row *matHeaderRowDef="entityKycColumns; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: entityKycColumns;"></tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter.</td>
                </tr>
              </table>
            </div>
          </div>
          <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[25, 50, 100]"
                         [pageSize]="100"
                         aria-label="Select page"></mat-paginator>
        </div>
      </div>
    </div>
    <!--    -->
    <!-- TABLE END   -->
    <!--    -->



  </div>
</div>
