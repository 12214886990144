import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomScrollDirective, DefaultImageDirective, DndDirective} from "./directives";
import {NgMaterialModule} from "../ng-material/ng-material.module";
import {AngularEditorModule} from "@kolkov/angular-editor";
import {FormsModule} from "@angular/forms";
import {StatusDirective} from './directives';
import {NgxCaptureModule} from "ngx-capture";
import {
  CodeInputComponent,
  DataRoomComponent,
  DealCardComponent,
  DeleteConfirmationModalComponent,
  HistoryComponent,
  IframeThumbnailComponent,
  NotepadComponent,
  TimelineComponent,
  ConfirmationModalComponent,
  UserCardComponent,
  BusinessUnitCardComponent,
  UserCardModalComponent,
  PermissionCheckComponent,
  DateDisplayComponent,
  PromptModalComponent
} from "./components";
import {DataRoomService, DocumentsService, HistoryService, UtilService} from "./services";
import {FileSizePipe} from "./pipes/file-size.pipe";
import {ImageCropperModule} from "ngx-image-cropper";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {KycDocumentService} from "./services/documents/kyc-document.service";
import { LookupService } from 'src/app/services';

@NgModule({
  declarations: [
    DealCardComponent,
    TimelineComponent,
    DefaultImageDirective,
    NotepadComponent,
    DndDirective,
    StatusDirective,
    DeleteConfirmationModalComponent,
    IframeThumbnailComponent,
    DataRoomComponent,
    CustomScrollDirective,
    CodeInputComponent,
    FileSizePipe,
    HistoryComponent,
    ConfirmationModalComponent,
    UserCardComponent,
    BusinessUnitCardComponent,
    UserCardModalComponent,
    PermissionCheckComponent,
    DateDisplayComponent,
    PromptModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AngularEditorModule,
    NgMaterialModule,
    NgxCaptureModule,
    ImageCropperModule,
    DragDropModule,
  ],
  providers: [
    // KycService,
    DataRoomService,
    DocumentsService,
    // KycDocumentService,
    UtilService,
    HistoryService
  ],
  exports: [
    DealCardComponent,
    TimelineComponent,
    DefaultImageDirective,
    NotepadComponent,
    DndDirective,
    StatusDirective,
    DeleteConfirmationModalComponent,
    IframeThumbnailComponent,
    DataRoomComponent,
    CustomScrollDirective,
    CodeInputComponent,
    FileSizePipe,
    HistoryComponent,
    ConfirmationModalComponent,
    UserCardComponent,
    BusinessUnitCardComponent,
    PermissionCheckComponent
  ]
})
export class SharedModule {
}
