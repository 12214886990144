<div style="overflow-y: auto; height: calc(100vh - 176px);">
  <div class="container-fluid">
    <!--    GENERAL SETTINGS-->
    <form [formGroup]="userForm"
          (ngSubmit)="save()">
      <div class="row justify-content-center" style="padding-top: 25px;">
        <div class="col-12 col-md-11">
          <span class="rm-title rm-title-3">Deal Settings & Actions</span><br/>
          <span
            class="rm-title rm-title-6 rm-lighter">This is where you can edit the deal settings and workflow state.</span>
        </div>
      </div>
      <div class="row justify-content-center" style="padding-top: 25px;">
        <div class="col-12 col-md-11">
          <div class="row">
            <span style="font-size: large; font-weight: bold;">General Settings</span>
          </div>
          <br/>
          <!-- row start -->
          <div class="row">
            <div class="col-12 col-md-6">
              <mat-label style="font-size: medium;">Deal NDA Requirements</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <mat-select id="country"
                            formControlName="dealNdaRequirementType"
                            [required]="true"
                            placeholder="No NDA">
                  <mat-option *ngFor="let c of lookups.dealNdaRequirementTypes" [value]="c.key">
                    {{ c.value }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="userForm.invalid">Please choose one.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <mat-label style="font-size: medium;">Deal Interest Type</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <mat-select id="dit" formControlName="dealInterestType"
                            [required]="true"
                            placeholder="Subscribe"
                            [matTooltip]="'Not Implemented'">
                  <mat-option *ngFor="let c of lookups.dealInterestTypes" [value]="c.key">
                    {{ c.value }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="userForm.invalid">Please choose one.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <br/>
          <!-- row start -->
          <div class="row">
            <div class="col-12 col-md-6">
              <!--             total fund size-->
              <mat-label style="font-size: medium;">Type of Offering</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <mat-select id="secdt" formControlName="secDealType"
                            [required]="true"
                            placeholder="506 (c)">
                  <mat-option *ngFor="let c of lookups.secDealTypes" [value]="c.key">
                    {{ c.value }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="userForm.invalid">Please choose one.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <!-- Expected Syndication Date-->
              <mat-label style="font-size: medium;">Expected Syndication Date</mat-label>
              <!--              <mat-form-field class="form-field" appearance="outline">-->
              <!--                <input type="date" matInput id="expsd" name="expsd" formControlName="expSyndicationDate" [required]="true"/>-->
              <!--                <mat-error *ngIf="userForm.invalid">Please select a date.</mat-error>-->
              <!--              </mat-form-field>-->
              <mat-form-field appearance="outline" class="form-field">
                <input matInput [matDatepicker]="datepicker" formControlName="syndicationDate"/>
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker #datepicker></mat-datepicker>
                <mat-error *ngIf="userForm.invalid">Please select a date.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <br/>
          <!-- row start -->
          <div class="row">
            <div class="col-12 col-md-6">
              <!--  Deal is MNPI-->
              <mat-label style="font-size: medium;">Deal is MNPI</mat-label>
              <br/>
              <mat-radio-group formControlName="dealIsMnpi">
                <mat-radio-button *ngFor="let subtask of [{key:'yes',value:true},{key:'no',value:false}]"
                                  color="primary"
                                  [value]="subtask.value">
                  {{ subtask.key }}&nbsp;
                </mat-radio-button>
              </mat-radio-group>
              <mat-error style="font-size: x-small;" *ngIf="userForm.invalid">Please choose one.</mat-error>
            </div>
            <div class="col-12 col-md-3">
              <!--     Expected Close Date-->
              <mat-label style="font-size: medium;">Expected Close Date</mat-label>
              <!--              <mat-form-field class="form-field" appearance="outline">-->
              <!--                <input type="date" matInput id="cr" name="cr" formControlName="expCloseDate" [required]="true"/>-->
              <!--                <mat-error *ngIf="userForm.invalid">Please select a date.</mat-error>-->
              <!--              </mat-form-field>-->
              <mat-form-field appearance="outline" class="form-field">
                <input matInput [matDatepicker]="datepicker2" formControlName="closeDate"/>
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="datepicker2"></mat-datepicker-toggle>
                <mat-datepicker #datepicker2></mat-datepicker>
                <mat-error *ngIf="userForm.invalid">Please select a date.</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6">
              <!--            Deal Close Type-->
              <mat-label style="font-size: medium;">Deal Close Type</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <mat-select id="dct" formControlName="dealCloseType"
                            [required]="true"
                            placeholder="Draw Down">
                  <mat-option *ngFor="let c of lookups.dealCloseTypes" [value]="c.key">
                    {{ c.value }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="userForm.invalid">Please choose one.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <br/>
          <!-- SUBDOC -->
          <div class="row" style="padding-top: 25px;padding-bottom: 25px;">
            <div class="col-12 col-md-11">
              <div class="row">
                <span style="font-size: large; font-weight: bold;">Subdoc Settings</span>
              </div>
            </div>
          </div>

          <!--  row start -->
          <div class="row">
            <div class="col-12 col-md-6">
              <mat-label style="font-size: medium;">SubDoc Template ID</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input matInput id="tempId" type="text" formControlName="subDocsTemplateId"/>
                <mat-hint>{{ 'Your default SubDoc template ID is: ' + userForm.get('subDocsDefaultTemplateId')?.value }}</mat-hint>
              </mat-form-field>
            </div>

            <div class="col-12 col-md-6">
              <mat-label style="font-size: medium;">Number of Issuer SubDoc Signers</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <mat-select formControlName="numberOfIssuerSignatories"
                            [required]="true">
                  <mat-option [value]="1">Single Signer</mat-option>
                  <mat-option [value]="2">Dual Signer</mat-option>
                </mat-select>
                <mat-error *ngIf="userForm.invalid">Please choose one.</mat-error>
              </mat-form-field>
            </div>
          </div>

          <!-- row start -->
          <div class="row" style="padding-top: 25px;">
            <div class="col-12 col-md-6">
              <mat-checkbox color="primary" formControlName="issuerRequiresApprovalBeforeSignatures">
                Issuer requires approval before signatures
              </mat-checkbox>
            </div>
            <div class="col-12 col-md-6">
              <button mat-raised-button
                      color="warn"
                      type="button"
                      (click)="getSubDocTemplateErrors()"
                      [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SETTINGS)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SETTINGS) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SETTINGS) ? util.NoAccessMsg : ''"
                      matTooltipPosition="left"
              >Check for Template Errors
              </button>
            </div>
          </div>

          <!-- SUBDOC TEMPLATE ERRORS START -->
          <div class="row" style="margin-top: 25px; padding:10px; background-color: whitesmoke;"
               *ngIf="subdocTemplateErrors.length > 0">
            <div class="col-12">
              <span style="color: red; font-weight: bold;">Sub Doc Template Errors</span>
            </div>
            <div class="col-12" *ngFor="let error of subdocTemplateErrors">
              <span style="color: red;">- {{ error }}</span>
            </div>
          </div>
          <!-- SUBDOC TEMPLATE ERRORS END -->

          <!-- BUTTONS -->
          <div class="row" style="padding-top: 50px;">
            <div class="col-12 text-end">
              <button mat-raised-button
                      color="primary"
                      type="submit"
                      [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SETTINGS)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SETTINGS) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SETTINGS) ? util.NoAccessMsg : ''"
                      matTooltipPosition="left"
              >SAVE CHANGES
              </button>
            </div>
          </div>
        </div>
      </div>
      <br/>
    </form>
    <br/>
    <br/>

    <!-- ADMIN SETTINGS -->
    <form [formGroup]="adminForm"
          (ngSubmit)="adminSave()">
      <div class="row justify-content-center"
           style="padding-bottom: 25px;"
           [hidden]="!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_SETTINGS_ADMIN)"
      >
        <div class="col-12 col-md-11">
          <hr/>
          <div class="row">
            <span style="font-size: large; font-weight: bold;">Admin Settings</span>
          </div>
          <br/>
          <!-- row start -->
          <div class="row">
            <div class="col-12 col-md-6">
              <mat-label style="font-size: medium;">Issuer Signed NDA</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <mat-select id="a1" formControlName="issuerNdaStatus"
                            [required]="true"
                            placeholder="Pending">
                  <mat-option *ngFor="let c of lookups.issuerNDAStatusTypes" [value]="c.key">
                    {{ c.value }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="adminForm.invalid">Please choose one.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <br/>
          <!-- row start -->
          <div class="row">
            <div class="col-12 col-md-6">
              <!--         Issuer Signed Engagement Agreement<-->
              <mat-label style="font-size: medium;">Issuer Signed Engagement Agreement</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <mat-select id="a3" formControlName="issuerEngagementAgreementStatus"
                            [required]="true"
                            placeholder="Pending">
                  <mat-option *ngFor="let c of lookups.issuerNDAStatusTypes" [value]="c.key">
                    {{ c.value }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="adminForm.invalid">Please choose one.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <br/>
          <!-- row start -->
          <div class="row">
            <div class="col-12 col-md-6">
              <!-- Issuer Can Edit Deal Data Room -->
              <mat-label style="font-size: medium;">DATAROOM - Issuer Can Edit Deal Data Room</mat-label>
              <br/>
              <mat-radio-group formControlName="issuerCanEditDealDataroom">
                <mat-radio-button *ngFor="let obj of [{key:'Yes',value:true},{key:'No',value:false}]" color="primary"
                                  [value]="obj.value">
                  {{ obj.key }}&nbsp;
                </mat-radio-button>
              </mat-radio-group>
              <mat-error style="font-size: x-small;" *ngIf="adminForm.invalid">Please choose one.</mat-error>
            </div>
          </div>
          <br/>
          <!-- row start -->
          <div class="row">
            <div class="col-12 col-md-6">
              <!-- Issuer Can Edit Deal Data Room -->
              <mat-label style="font-size: medium;">DATAROOM - Investor Can Download Entire Data Room</mat-label>
              <br/>
              <mat-radio-group formControlName="investorCanDownloadDataroom">
                <mat-radio-button *ngFor="let obj of [{key:'Yes',value:true},{key:'No',value:false}]" color="primary"
                                  [value]="obj.value">
                  {{ obj.key }}&nbsp;
                </mat-radio-button>
              </mat-radio-group>
              <mat-error style="font-size: x-small;" *ngIf="adminForm.invalid">Please choose one.</mat-error>
            </div>
          </div>
          <br/>
          <!-- row start -->
          <div class="row">
            <div class="col-12 col-md-6">
              <!-- Issuer Can Edit Deal Data Room -->
              <mat-label style="font-size: medium;">Issuer Can Edit Deal Information Card</mat-label>
              <br/>
              <mat-radio-group formControlName="issuerCanEditDealCardInformation" [disabled]="true">
                <mat-radio-button *ngFor="let obj of [{key:'Yes',value:true},{key:'No',value:false}]" color="primary"
                                  [value]="obj.value">
                  {{ obj.key }}&nbsp;
                </mat-radio-button>
              </mat-radio-group>
              <mat-error style="font-size: x-small;" *ngIf="adminForm.invalid">Please choose one.</mat-error>
            </div>
          </div>
          <br/>
          <!-- BUTTONS -->
          <div class="row" style="padding-top: 50px;">
            <div class="col-12 text-end">
              <button mat-raised-button
                      color="primary"
                      type="submit"
                      [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SETTINGS_ADMIN)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SETTINGS_ADMIN) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_SETTINGS_ADMIN) ? util.NoAccessMsg : ''"
                      matTooltipPosition="left"
              >SAVE CHANGES
              </button>
            </div>
          </div>

        </div>
      </div>
    </form>
    <br/>
    <form [formGroup]="workflowForm"
          (ngSubmit)="workFlowSave()">
      <div class="row justify-content-center"
           style="padding-bottom: 150px;"
           [hidden]="!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_SETTINGS_ADMIN)"
      >
        <div class="col-12 col-md-11">
          <hr/>
          <!-- DEAL WORKFLOW -->
          <div class="row" style="padding-top: 50px;">
            <div class="row">
              <span style="font-size: large; font-weight: bold;">Deal Workflow</span>
            </div>
            <br/>
            <br/>
            <div class="col-12 col-md-6">
              <mat-label style="font-size: medium;">Deal Status</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <mat-select id="a2"
                            formControlName="dealWorkflow"
                            [required]="true"
                            placeholder="Live"
                            [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_STATUS)"
                            [ngStyle]="{'cursor':(!util.hasPermission(util.Permission.CAN_EDIT_DEAL_STATUS) ? util.NoAccessCursor: '')}"
                            [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_STATUS) ? util.NoAccessMsg : ''"
                            matTooltipPosition="left"
                >
                  <mat-option *ngFor="let c of lookups.dealWorkflowStatus" [value]="c.key">
                    {{ c.value }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="adminForm.invalid">Please choose one.</mat-error>
              </mat-form-field>
              <span style="font-size: small; color: grey">Note: Setting the deal status to <b>LIVE</b> will send an email to the issuer.</span>
            </div>
          </div>
          <div class="row" style="padding-top: 50px;">
            <div class="col-12 text-end">
              <button mat-raised-button
                      color="primary"
                      type="submit"
                      [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_STATUS)"
                      [ngStyle]="{'cursor':(!util.hasPermission(util.Permission.CAN_EDIT_DEAL_STATUS) ? util.NoAccessCursor: '')}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_STATUS) ? util.NoAccessMsg : ''"
                      matTooltipPosition="left"
              >SAVE CHANGES
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

