<div style="overflow-y: auto; height: calc(100vh - 176px);">
  <div class="container-fluid">
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-12 col-md-11">
        <span class="rm-title rm-title-3">Deal Team Members</span><br/>
        <span class="rm-title rm-title-6 rm-lighter">This is where you can manage the users that can interact with this deal.</span>
      </div>
    </div>

    <div class="row justify-content-center" style="padding-top: 15px;">
      <div class="col-12 col-md-8" style="display: flex;">
        <mat-form-field style="width: 100%;" appearance="outline">
          <mat-label>Selected Deal Team</mat-label>
          <mat-select id="tur" name="tur" style="font-size: small;" (selectionChange)="onChangeDealTeam($event)"
                      [(ngModel)]="selectedDealTeam">
            <mat-option *ngFor="let dt of dealTeams" [value]="dt">
              {{ dt.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>&nbsp;
      </div>
      <div class="col-12 col-md-3" style="display: flex; justify-content: end;">
        <div style="padding-top: 10px; display: flex;">
          <div style="margin-right: 5px;">
            <button type="button" mat-raised-button (click)="viewHistory()" matTooltip="View History"
                    *ngIf="util.isType('admin')"
                    matTooltipPosition="right">
              <mat-icon>history</mat-icon>
              VIEW HISTORY
            </button>&nbsp;
          </div>
          <div>
            <button mat-raised-button
                    color="primary"
                    class="frm-btn"
                    (click)="addMember()"
                    [disabled]="!util.hasPermission(util.Permission.CAN_ADD_DEAL_TEAMMEMBERS)"
                    [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_DEAL_TEAMMEMBERS) ? util.NoAccessCursor: ''}"
                    [matTooltip]="!util.hasPermission(util.Permission.CAN_ADD_DEAL_TEAMMEMBERS) ? util.NoAccessMsg : ''"
                    matTooltipPosition="left"
            >
              <mat-icon>person_add</mat-icon>
              NEW
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--    table start-->
    <div class="row justify-content-center d-none d-md-flex" style="padding-bottom: 150px;">
      <div class="col-12 col-md-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <table mat-table [dataSource]="dataSource"
                     [multiTemplateDataRows]="true"
                     matSort
                     (matSortChange)="sortData($event)"
              >
                <!-- Search Header -->
                <ng-container matColumnDef="table-name">
                  <th colspan="3" mat-header-cell *matHeaderCellDef>Deal Team Members</th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="2" mat-header-cell *matHeaderCellDef>
                    <form>
                      <mat-form-field style="width: 100%;">
                        <mat-label>Search</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input/>
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form>
                  </th>
                </ng-container>
                <!-- First Name Column -->
                <ng-container matColumnDef="firstName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="user"> Member</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    <!--                    {{element['firstName'] + " " + element['lastName']}}<br/>{{element['email']}}-->
                    <!--        user menu button-->
                    <app-user-card [firstName]="element['firstName'] "
                                   [lastName]="element['lastName']"
                                   [displayName]="element['email']"
                                   [borderColor]="'transparent'"
                                   [background]="'#F1EDE4'"
                                   [sideColor]="'#002150'"
                                   [size]="userCardSize.SM"
                                   [image]="element['imageUrl']"
                    ></app-user-card>
                    <!--user menu end-->
                  </td>
                </ng-container>
                <!--  Column -->
                <ng-container matColumnDef="invite">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="status"> Status</th>
                  <td mat-cell *matCellDef="let element">
                    <span style="font-size: small;">{{ element["inviteStatus"] }} </span></td>
                </ng-container>
                <!--  Column -->
                <ng-container matColumnDef="role">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="role"> Role</th>
                  <td mat-cell *matCellDef="let element">
                    <span style="font-size: small;">{{ element["role"] }} </span></td>
                </ng-container>
                <!-- Created Column -->
                <ng-container matColumnDef="created">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="created"> Created</th>
                  <td mat-cell *matCellDef="let element">
                    <div class="date-stacked">
                      <span>{{ element.created | date : 'mediumDate' }}</span>
                      <span>{{ element.created | date : 'shortTime' }}</span>
                    </div>
                  </td>
                </ng-container>
                <!--  Column -->
                <ng-container matColumnDef="rootFolders">
                  <th mat-header-cell *matHeaderCellDef> Root Folders</th>
                  <td mat-cell *matCellDef="let element" class="text-start">
                    <div style="display: inline-flex;">
                      <div
                        style="font-size: x-small; background: rgba(211,211,211,0.5); border: 1px solid rgba(0,0,0,0.5); border-radius: 12px; padding:0 5px; margin: 0 2px; text-overflow: ellipsis;"
                        *ngFor="let r of getSorted(element.permissions['rootFolders'])">
                        <div
                          style="display: flex; vertical-align:middle; max-height: 24px; min-width: 36px; overflow: hidden; text-overflow: ellipsis;">
                          <mat-icon style="font-size: small; margin: 3px -5px 0 3px;">folder</mat-icon>
                          <span style="text-overflow: ellipsis;">{{ r.name }}</span>
                        </div>
                      </div>
                    </div>
                  </td>
                </ng-container>
                <!--  Column -->
                <ng-container matColumnDef="userNote">
                  <th mat-header-cell *matHeaderCellDef class="text-center"> User Deal Note Permissions</th>
                  <td mat-cell *matCellDef="let element" class="text-center">
                    <span style="font-size: small;">{{ element.permissions['dealNotePermission'] }} </span></td>
                </ng-container>
                <!--  Column -->
                <ng-container matColumnDef="enableUpload">
                  <th mat-header-cell *matHeaderCellDef class="text-center"> Enable Data Room Upload?</th>
                  <td mat-cell *matCellDef="let element" class="text-center">
                    <span>{{ element.permissions['canUploadDataroom'] ? 'Yes' : 'No' }} </span></td>
                </ng-container>
                <!-- Actions Column -->
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef> Actions</th>
                  <td mat-cell *matCellDef="let element">
                    <div style="display: flex;">
                      <button
                        hidden
                        color="primary"
                        mat-icon-button
                        (click)="viewInfo(element)"
                        [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_TEAMMEMBERS)"
                        [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_TEAMMEMBERS) ? util.NoAccessCursor: ''}"
                        [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_TEAMMEMBERS) ? util.NoAccessMsg : 'Member Account'"
                        matTooltipPosition="left">
                        <mat-icon>info</mat-icon>
                      </button>
                      <button mat-icon-button
                              (click)="viewPermissions(element)"
                              [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_TEAMMEMBERS)"
                              [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_TEAMMEMBERS) ? util.NoAccessCursor: ''}"
                              [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_TEAMMEMBERS) ? util.NoAccessMsg : 'Member Permissions'"
                              matTooltipPosition="left"
                      >
                        <mat-icon>settings</mat-icon>
                      </button>
                      <button mat-icon-button
                              color="warn"
                              (click)="delete(element)"
                              [disabled]="!util.hasPermission(util.Permission.CAN_DELETE_DEAL_TEAMMEMBERS)"
                              [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_DELETE_DEAL_TEAMMEMBERS) ? util.NoAccessCursor: ''}"
                              [matTooltip]="!util.hasPermission(util.Permission.CAN_DELETE_DEAL_TEAMMEMBERS) ? util.NoAccessMsg : ''"
                              matTooltipPosition="left"
                      >
                        <mat-icon>delete</mat-icon>
                      </button>&nbsp;
                    </div>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="['table-name','search-header']"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"></tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter.</td>
                </tr>
              </table>
            </div>
          </div>
          <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[5, 10, 25, 100]"
                         aria-label="Select page of users"></mat-paginator>
        </div>
      </div>
    </div>
    <!--    list start-->
    <div class="row justify-content-center d-flex d-md-none" style="padding-bottom: 150px; padding-top: 15px;">
      <div class="col-12">
        <ul style="list-style-type: none; padding: 25px 0 0 0;">
          <li *ngFor="let element of dataSource.data"
              style="margin-bottom: 5px; display: flex; justify-content: space-between;">
            <app-user-card [firstName]="element?.firstName"
                           [lastName]="element?.lastName"
                           [displayName]="element?.email"
                           [borderColor]="'transparent'"
                           [background]="'#F1EDE4'"
                           [sideColor]="'#002150'"
                           [size]="userCardSize.SM"
                           [image]="element?.imageUrl"
            ></app-user-card>
            <div style="display: flex;">
              <button color="primary"
                      mat-icon-button
                      (click)="viewInfo(element)"
                      [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_TEAMMEMBERS)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_TEAMMEMBERS) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_TEAMMEMBERS) ? util.NoAccessMsg : 'Member Account'"
                      matTooltipPosition="left">
                <mat-icon>info</mat-icon>
              </button>
              <button mat-icon-button
                      (click)="viewPermissions(element)"
                      matTooltip="Member Permissions"
                      matTooltipPosition="left">
                <mat-icon>list</mat-icon>
              </button>
              <button mat-icon-button
                      color="warn"
                      (click)="delete(element)"
                      [disabled]="!util.hasPermission(util.Permission.CAN_DELETE_DEAL_TEAMMEMBERS)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_DELETE_DEAL_TEAMMEMBERS) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_DELETE_DEAL_TEAMMEMBERS) ? util.NoAccessMsg : ''"
                      matTooltipPosition="left"
              >
                <mat-icon>delete</mat-icon>
              </button>&nbsp;
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
