<table *ngIf="!!sectionQuestions && sectionQuestions.length > 0" style="width: 100%; padding:15px 0;">
  <tr *ngFor="let q of sectionQuestions">


    <!--CHECKBOX-->
    <td *ngIf="q.questionType == 'CHECKBOX'" style="text-align: center; padding: 7px;">
      <mat-checkbox color="primary" (change)="emitMatCheckboxChange(q.questionAnswerAttribute, $event)"
                    [ngModel]="q.questionAnswer == 'true'" [disabled]="disableAll"
                    [ngStyle]="{'cursor': disableAll ? 'not-allowed': ''}"></mat-checkbox>
    </td>
    <td *ngIf="q.questionType == 'CHECKBOX'" style="padding:7px;">
      <span style="font-weight: bold;">{{ q.questionText }}</span>
    </td>


    <!--RADIO-->
    <td *ngIf="q.questionType == 'RADIO'" style="padding-bottom: 7px;">
      <span style="font-weight: bold;">{{ q.questionText }}</span>
    </td>
    <td *ngIf="q.questionType == 'RADIO'" style="text-align: right;">
      <div style="min-width: 150px;" [ngStyle]="{'cursor': disableAll ? 'not-allowed': ''}">
        <mat-radio-group color="primary" aria-label="Select an option"
                         (change)="emitMatRadioChange(q.questionAnswerAttribute, $event)" [ngModel]="q.questionAnswer"
                         [disabled]="disableAll" [ngStyle]="{'cursor': disableAll ? 'not-allowed': ''}">
          <mat-radio-button [value]="'true'">Yes</mat-radio-button>&nbsp;
          <mat-radio-button [value]="'false'">No</mat-radio-button>
        </mat-radio-group>
      </div>
    </td>


    <!--TEXT-->
    <td *ngIf="q.questionType == 'TEXT'" style="padding-bottom: 15px;">
      <span style="font-weight: bold;">{{ q.questionText }}</span>
      <mat-form-field class="form-field" appearance="outline">
        <input matInput id="value" type="text" (change)="emitMatInputChange(q.questionAnswerAttribute, $event)"
               [ngModel]="q.questionAnswer" [disabled]="disableAll"
               [ngStyle]="{'cursor': disableAll ? 'not-allowed': ''}"/>
      </mat-form-field>
    </td>


  </tr>
</table>
