import {Config} from "./config.model";
import {environment} from "../../../../../environments/environment";

export const initialConfigState: Config = new Config({
  is_loading: { loading: false },
  is_navigating: false,
  right_drawer_open: false,
  right_drawer_tab_idx: 0,
  rondeivu_app_title: environment.rondeivu_app_title,
  rondeivu_header_title: environment.rondeivu_header_title,
  rondeivu_address_1: environment.rondeivu_address_1,
  rondeivu_address_2: environment.rondeivu_address_2,
  rondeivu_address_3: environment.rondeivu_address_3,
  rondeivu_email: environment.rondeivu_email,
  rondeivu_phone: environment.rondeivu_phone,
  rondeivu_api: environment.rondeivu_api,
  rondeivu_api_mock: environment.rondeivu_api_mock,
  rondeivu_nav_items: [],
  rondeivu_theme_dark: false
});
