import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {toggleLoading} from "../../modules/redux";
import {IThirdPartyDetermination} from "../../models";
import {Store} from "@ngrx/store";
import {AppConfigService, LookupService, UuidService} from "../../services";
import {BusinessUnitService} from "../../modules/business-units/services";
import {ToastrService} from "ngx-toastr";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import { UtilService} from "../../modules/shared/services/util/util.service";

@Component({
  selector: 'app-third-party-determination',
  templateUrl: './third-party-determination.component.html',
  styleUrls: ['./third-party-determination.component.scss']
})
export class ThirdPartyDeterminationComponent {
  fg: FormGroup;
  fgThirdPartyInformation: FormGroup;

  constructor(private store: Store,
              public lookupService: LookupService,
              private businessUnitService: BusinessUnitService,
              private toastr: ToastrService,
              public util: UtilService,
              private uuid: UuidService,
  ) {

    this.fg = new FormGroup({
      id: new FormControl(),
      businessUnitId: new FormControl(),

      isRegistered: new FormControl(),
      isOwnerofVotingRights: new FormControl(),
      regulatoryPeople: new FormArray([]),

      isPubliclyTraded: new FormControl(),
      regulatoryEntities: new FormArray([]),

      isThisPartyRegisteredOnBehalf: new FormControl(),
      isAthirdPartyDirecting: new FormControl(),
      willAthirdPartyHasAfinancialInterest: new FormControl(),
    });
  

    this.fgThirdPartyInformation = new FormGroup({
      personFirstName: new FormControl('', [Validators.required]),
      personMiddleName: new FormControl(''),
      personLastName: new FormControl('', [Validators.required]),
      personSuffix: new FormControl(''),

      businessStreetAddress: new FormControl('', [Validators.required]),
      businessCity: new FormControl('', [Validators.required]),
      businessCountryId: new FormControl('', [Validators.required]),
      businessStateOrProvince: new FormControl(''),
      businessZipPostalCode: new FormControl('', [Validators.required]),
      businessLegalDomicile: new FormControl('', [Validators.required]),
    });

    this.getThirdParty();
  }


  //#region Regulatory People 
  createItemForRegulatoryPeople(data?: any) {
    var item = new FormGroup({
      id: new FormControl(this.uuid.generateUUID()),
      ownerOfVotingRight: new FormControl('', [Validators.required]),
      publicilyTradedCompany: new FormControl('', [Validators.required]),
      ownerShip: new FormControl(0, [Validators.required, Validators.min(0), Validators.max(100)])
    });

    if (!!data) {
      item.patchValue(data);
    }

    this.getRegulatoryPeople.push(item);
  }

  get getRegulatoryPeople() : FormArray {
    return this.fg.get('regulatoryPeople') as FormArray;
  }

  deleteFromRegulatoryPeople(i: number) {
    this.getRegulatoryPeople.removeAt(i);
  }
  //#endregion

  //#region Regulatory Entities
  createItemForRegulatoryEntities(data?: any) {
    var newItem = new FormGroup({
      id: new FormControl(this.uuid.generateUUID()),
      authorizedSignatory: new FormControl('', [Validators.required]),
      companyName: new FormControl('', [Validators.required]),
      ownerShip: new FormControl(0, [Validators.required, Validators.min(0), Validators.max(100)])
    });

    if (!!data) {
      newItem.patchValue(data);
    }

    this.getRegulatoryEntities.push(newItem);
  }

  get getRegulatoryEntities() : FormArray {
    return this.fg.get('regulatoryEntities') as FormArray;
  }

  deleteFromRegulatoryEntities(i: number) {
    this.getRegulatoryEntities.removeAt(i);
  }
  //#endregion

  isOneOrMoreSelected() {
    const Q1 = this.fg.get('isThisPartyRegisteredOnBehalf')?.value || false;
    const Q2 = this.fg.get('isAthirdPartyDirecting')?.value || false;
    const Q3 = this.fg.get('willAthirdPartyHasAfinancialInterest')?.value || false;
    return Q1 || Q2 || Q3;
  }

  showNaturalPersonsTable(): boolean {
    return this.fg.get('isOwnerofVotingRights')?.value || false;
  }

  showLegalEntitiesTable(): boolean {
    return this.fg.get('isPubliclyTraded')?.value || false;
  }

  getThirdParty() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.businessUnitService.getUserBusinessUnitThirdParty().subscribe({
      next: (res: IThirdPartyDetermination) => {
        // assign the top form control
        console.log("results", res);
        this.fg.patchValue(res);
        (res.regulatoryPeople || []).forEach(x => this.createItemForRegulatoryPeople(x));
        (res.regulatoryEntities || []).forEach(x => this.createItemForRegulatoryEntities(x));

        // patch values for the third party info
        this.fgThirdPartyInformation.patchValue(res);

        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Third Party Determination Loaded!", $localize`:@@companyName:Rondeivu`);
      }, error: error => {
        this.toastr.error("Unable to get third party determination!", $localize`:@@companyName:Rondeivu`);
        this.store.dispatch(toggleLoading({loading: false}));
      }
    });
  }

  submit() {

    if (this.showNaturalPersonsTable() && this.getRegulatoryPeople.length == 0) {
      // make sure the table has rows
      this.toastr.error("There has to be at leaset one 'owner of voting right' added");
      return;
    }

    if (this.showLegalEntitiesTable() && this.getRegulatoryEntities.length == 0) {
      // make sure the table has rows
      this.toastr.error("There has to be at least one 'owner authorized signatory' added");
      return;
    }


    if (this.isOneOrMoreSelected() && this.fgThirdPartyInformation.valid == false) {
      // make sure the third party information is filled in
      this.toastr.error("Please enter all required 'Third Party Information' fields");
      this.fgThirdPartyInformation.markAllAsTouched();
      return;
    }


    let payload = Object.assign(this.fg.value, this.fgThirdPartyInformation.value);

    console.log("Form Submitted", payload);

    this.store.dispatch(toggleLoading({loading: true}));
    this.businessUnitService.editUserBusinessUnitThirdParty(payload).subscribe({
      next: res => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Third Party Determination Saved!", $localize`:@@companyName:Rondeivu`);
      }, error: error => {
        this.toastr.error("Unable to update third party determination!", $localize`:@@companyName:Rondeivu`);
        this.store.dispatch(toggleLoading({loading: false}));
      }
    });
  }
}
