import {ILookup} from "../../../../models";

export class Lookups {
  businessUnitProfileTypes: ILookup[] = [];
  businessUnitWorkflowStatus: ILookup[] = [];
  appUserWorkflowStatus: ILookup[] = [];
  areasOfFocus: ILookup[] = [];
  assetTypes: ILookup[] = [];
  countries: ILookup[] = [];
  countryCodes: ILookup[] = [];
  dealNdaRequirementTypes: ILookup[] = [];
  dealTypes: ILookup[] = [];
  dealWorkflowStatus: ILookup[] = [];
  investorDescriptionTypes: ILookup[] = [];
  investorTypes: ILookup[] = [];
  investorMandateWorkflowStatus: ILookup[] = [];
  issuerTypes: ILookup[] = [];
  dealCloseTypes: ILookup[] = [];
  secDealTypes: ILookup[] = [];
  currencyTypes: ILookup[] = [];
  provinceTypes: ILookup[] = [];
  stateTypes: ILookup[] = [];
  timeLineTypes: ILookup[] = [];
  dealInterestTypes: ILookup[] = [];
  userIntakeSourceTypes: ILookup[] = [];
  issuerEngagementAgreementStatusTypes: ILookup[] = [];
  issuerNDAStatusTypes: ILookup[] = [];
  kycStatusTypes: ILookup[] = [];
  dealNotesAccessTypes: ILookup[] = [];
  teamUserRoleTypes: ILookup[] = [];
  memberInviteStatus: ILookup[] = [];
  dealAlertTypes: ILookup[] = [];
  viewAccessControls: ILookup[] = [];
  dataroomTypes: ILookup[] = [];
  indicationOfInterestRestrictionTypes: ILookup[] = [];
  investorDealWorkflowStatus: ILookup[] = [];
  noteTypes: ILookup[] = [];
  syndicationRestrictionTypes: ILookup[] = [];
  syndicationStatus: ILookup[] = [];
  subscriptionRestrictionTypes: ILookup[] = [];
  subscriptionStatus: ILookup[] = [];
  rondeivuEmployeeRoleTypes: ILookup[] = [];
  ndaTypes: ILookup[] = [];
  envelopeStatus: ILookup[] = [];
  kycInvestorTypes: ILookup[] = [];
  kycIssuerTypes: ILookup[] = [];
  anchorInterestTypes: ILookup[] = [];
  issuerLegalEntityType: ILookup[] = [];
  taxIdType: ILookup[] = [];
  legalEntityProofofDocumentType: ILookup[] = [];
  entityTabDocumentationType: ILookup[] = [];
  personGovernmentIssuedIdType: ILookup[] = [];
  personProofofIdentificationIdType: ILookup[] = [];
  personProofofAddressDocumentType: ILookup[] = [];
  entityTaxDocumentationType: ILookup[] = [];
  personProofofIdentificationType: ILookup[] = [];
  kycPersonRondeivuStatus: ILookup[] = [];
  kycLegalEntityRondeivuStatus: ILookup[] = [];
  kycType: ILookup[] = [];
  kycRondeivuStatus: ILookup[] = [];
  kycPersonType: ILookup[] = [];
  kycLegalEntityType: ILookup[] = [];
  businessUnitEntityType: ILookup[] = [];
  subDocStatus: ILookup[] = [];

  constructor(data: any) {
    Object.assign(this, data);
  }
}

