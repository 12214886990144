<div style="height:calc(100vh - 64px); overflow-y: auto;">
  <div class="container-fluid">
    <br/><br/>
    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
        <div style="display: flex;">
          <mat-icon color="primary" class="rm-header-icon">people</mat-icon>&nbsp;
          <span class="rm-title rm-title-3">Users</span>
        </div>
        <div>
          <span class="rm-title rm-title-6 rm-lighter">These are all of the system users:</span>
        </div>
      </div>
      <div class="col-md-3 text-end d-none d-md-block">
        <button mat-raised-button
                color="primary"
                (click)="addUser()"
                [disabled]="!util.hasPermission(util.Permission.CAN_ADD_USERS)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_USERS) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_ADD_USERS) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
        >
          <mat-icon>person_add_alt</mat-icon>&nbsp;ADD USER
        </button>
      </div>
      <div class="col-12 d-flex d-md-none" style="padding-top: 25px;">
        <button mat-raised-button
                color="primary"
                (click)="addUser()"
                [disabled]="!util.hasPermission(util.Permission.CAN_ADD_USERS)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_USERS) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_ADD_USERS) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
        >
          <mat-icon>person_add_alt</mat-icon>&nbsp;ADD USER
        </button>
      </div>
    </div>
    <!--    table start-->
    <div class="row justify-content-center d-none d-md-flex" style="padding-top: 25px;">
      <div class="col-12 col-md-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <table mat-table [dataSource]="dataSource"
                     [multiTemplateDataRows]="true" matSort (matSortChange)="sortData($event)">
                <!-- Search Header -->
                <ng-container matColumnDef="table-name">
                  <th colspan="4" mat-header-cell *matHeaderCellDef>
                    <span style="font-size: large;">Users</span>
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="3" mat-header-cell *matHeaderCellDef>
                    <form>
                      <mat-form-field style="width: 100%;">
                        <mat-label>Search</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input/>
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form>
                  </th>
                </ng-container>
                <!-- User Column -->
                <ng-container matColumnDef="user">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="user"> User</th>
                  <td mat-cell style="max-width: 200px; overflow-wrap: break-word; padding: 5px;"
                      *matCellDef="let element">
                    <!--        user menu button-->
                    <app-user-card [firstName]="element['firstName'] "
                                   [lastName]="element['lastName']"
                                   [displayName]="element['email']"
                                   [borderColor]="'transparent'"
                                   [sideColor]="'#002150'"
                                   [background]="'#F1EDE4'"
                                   [image]="element['imageUrl']"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- Company Column -->
                <ng-container matColumnDef="Company">
                  <th mat-header-cell *matHeaderCellDef> Business Units</th>
                  <td mat-cell *matCellDef="let element">
                    <ul style="list-style: none;">
                      <li *ngFor="let bue of element['appUserEmployees']">
                        <a [routerLink]="getBusinessUnitUrl(bue)">{{ bue.businessUnit.displayName }}</a><br/>
                        <ul>
                          <li><span>TITLE: {{ bue.title }}</span></li>
                          <li><span>ROLE: {{ bue.role }}</span></li>
                          <li><span>INVITE: {{ bue.memberInviteStatus }}</span></li>
                        </ul>
                      </li>
                    </ul>
                  </td>
                </ng-container>
                <!-- Status Column -->
                <ng-container matColumnDef="Status">
                  <th mat-header-cell *matHeaderCellDef> STATUS</th>
                  <td mat-cell *matCellDef="let element">
                    <span [appStatus]="element.status">{{ element.status }} </span>
                  </td>
                </ng-container>
                <!-- Status Column -->
                <ng-container matColumnDef="registered">
                  <th mat-header-cell *matHeaderCellDef> Registered</th>
                  <td mat-cell *matCellDef="let element">
                    <mat-icon *ngIf="element.userHasRegistered == true" color="primary">check</mat-icon>
                    <mat-icon *ngIf="element.userHasRegistered == false" color="warn">close</mat-icon>
                  </td>
                </ng-container>
                <!-- Status Column -->
                <ng-container matColumnDef="cognito">
                  <th mat-header-cell *matHeaderCellDef> Cognito Status</th>
                  <td mat-cell *matCellDef="let element">
                    <span [appStatus]="element.status">{{ element.status }} </span>
                  </td>
                </ng-container>
                <!-- Status Column -->
                <ng-container matColumnDef="mfa">
                  <th mat-header-cell *matHeaderCellDef> MFA Type</th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element.mfaType }} </span>
                  </td>
                </ng-container>
                <!-- Status Column -->
                <ng-container matColumnDef="emailVerified">
                  <th mat-header-cell *matHeaderCellDef> Email Verified</th>
                  <td mat-cell *matCellDef="let element">
                    <mat-icon *ngIf="element.emailVerified == true" color="primary">check</mat-icon>
                    <mat-icon *ngIf="element.emailVerified == false" color="warn">close</mat-icon>
                  </td>
                </ng-container>
                <!-- Status Column -->
                <ng-container matColumnDef="phoneVerified">
                  <th mat-header-cell *matHeaderCellDef> Phone Verified</th>
                  <td mat-cell *matCellDef="let element">
                    <mat-icon *ngIf="element.phoneNumberVerified == true" color="primary">check</mat-icon>
                    <mat-icon *ngIf="element.phoneNumberVerified == false" color="warn">close</mat-icon>
                  </td>
                </ng-container>
                <!-- Items Column -->
                <ng-container matColumnDef="Items">
                  <th mat-header-cell *matHeaderCellDef> ITEMS</th>
                  <td mat-cell *matCellDef="let element"> {{ element.items }}</td>
                </ng-container>
                <!-- Date Submitted Column -->
                <ng-container matColumnDef="Date">
                  <th mat-header-cell *matHeaderCellDef
                      mat-sort-header="created" sortActionDescription="Sort by date submitted"> Created
                  </th>
                  <td mat-cell *matCellDef="let element"> 
                    <div class="date-stacked">
                      <span>{{ element.created | date : 'mediumDate' }}</span>
                      <span>{{ element.created | date : 'shortTime' }}</span>
                    </div>
                  </td>
                </ng-container>
                <!-- EDIT Column -->
                <ng-container matColumnDef="edit">
                  <th mat-header-cell *matHeaderCellDef> Actions</th>
                  <td mat-cell *matCellDef="let element">
                    <div style="display: flex;">
                      <button mat-icon-button
                              (click)="viewHistory(element)"
                              matTooltip="View History"
                              matTooltipPosition="left">
                        <mat-icon>history</mat-icon>
                      </button>
                      <button mat-icon-button
                              (click)="viewUser(element)"
                              [disabled]="!util.hasPermission(util.Permission.CAN_ACCESS_USERS)"
                              [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ACCESS_USERS) ? util.NoAccessCursor: ''}"
                              [matTooltip]="!util.hasPermission(util.Permission.CAN_ACCESS_USERS) ? util.NoAccessMsg : 'View User'"
                              matTooltipPosition="left"
                      >
                        <mat-icon>east</mat-icon>
                      </button>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="expand">
                  <th mat-header-cell *matHeaderCellDef aria-label="row actions" style="width: 50px;">&nbsp;</th>
                  <td mat-cell *matCellDef="let approval" style="width: 50px;">
                    <button mat-icon-button aria-label="expand row"
                            (click)="(expandedElement = expandedElement === approval ? null : approval); $event.stopPropagation()">
                      <mat-icon *ngIf="expandedElement !== approval">keyboard_arrow_down</mat-icon>
                      <mat-icon *ngIf="expandedElement === approval">keyboard_arrow_up</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                  <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                    <div class="example-element-detail"
                         [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                      <div class="approval-row detail-row">
                      </div>
                    </div>
                  </td>
                </ng-container>

                <!--              table rows-->
                <tr mat-header-row *matHeaderRowDef="['table-name','search-header'];"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                    class="example-element-row"
                    [class.example-expanded-row]="expandedElement === element">
                  <!--              (click)="expandedElement = expandedElement === element ? null : element">-->
                </tr>
                <!--              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>-->
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter.</td>
                </tr>
              </table>
            </div>
          </div>
          <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[25, 50, 100]"
                         [pageSize]="100"
                         aria-label="Select page of users"></mat-paginator>
        </div>
      </div>
    </div>
    <!--    list start-->
    <div class="row justify-content-center d-flex d-md-none" style="padding-bottom: 150px; padding-top: 15px;">
      <div class="col-12">
        <ul style="list-style-type: none; padding: 25px 0 0 0;">
          <li *ngFor="let element of dataSource.data"
              style="margin-bottom: 5px; display: flex; justify-content: space-between;">
            <app-user-card [firstName]="element['firstName'] "
                           [lastName]="element['lastName']"
                           [displayName]="element['email']"
                           [borderColor]="'transparent'"
                           [sideColor]="'#002150'"
                           [background]="'#F1EDE4'"
                           [image]="element['imageUrl']"
            ></app-user-card>
            <div style="display: flex;">
              <button mat-icon-button
                      (click)="viewHistory(element)"
                      matTooltip="View History"
                      matTooltipPosition="left">
                <mat-icon>history</mat-icon>
              </button>
              <button mat-icon-button
                      (click)="viewUser(element)"
                      [disabled]="!util.hasPermission(util.Permission.CAN_ACCESS_USERS)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ACCESS_USERS) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_ACCESS_USERS) ? util.NoAccessMsg : 'View User'"
                      matTooltipPosition="left"
              >
                <mat-icon>east</mat-icon>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
