import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  Config, getAssetTypes,
  getConfig, getDealTypes,
  getLookups,
  getSelectedBusinessUnit,
  getSelectedMandateId,
  initialConfigState,
  initialLookupsState,
  Lookups,
  toggleLoading
} from "../../../redux";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {MandateService} from "../../services";
import {IBusinessUnit} from "../../../business-units/models";
import {FormControl, FormGroup} from "@angular/forms";
import {ILookup} from "../../../../models";
import {delay, Observable, startWith, Subscription} from "rxjs";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {ToastrService} from "ngx-toastr";
import {iMandateRequest} from "../../models";
import {map} from "rxjs/operators";
import {UtilService} from "../../../shared/services";

@Component({
  selector: 'app-mandate-info',
  templateUrl: './mandate-info.component.html',
  styleUrls: ['./mandate-info.component.scss']
})
export class MandateInfoComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  selectedMandateId$: Subscription = new Subscription();

  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;
  mandate: any = {};

  // title: new FormControl({value: '', disabled: !this.util.isType('admin')}, []),

  //reactive form
  userForm = new FormGroup({
    id: new FormControl(),
    countryId: new FormControl('', []),
    stateOrProvinceId: new FormControl('', []),
    city: new FormControl('', []),
    firmAum: new FormControl(0),
    programAum: new FormControl(0),
    targetNetMoic: new FormControl(0),
    targetNetIrr: new FormControl(0),
    minimumDuration: new FormControl(0),
    maximumDuration: new FormControl(0),
    minTicketSize: new FormControl(0),
    maxTicketSize: new FormControl(0),
    avgTicketSize: new FormControl(0),
    investorMandateDeals: new FormControl([], []),
    investorMandateAssets: new FormControl([], []),
    investorMandateAreasOfFocus: new FormControl([], []),
    investorMandateAnchorInterests: new FormControl([], []),
    investorMandateTimeLines: new FormControl(),
    notes: new FormControl('', []),
    workflowStatus: new FormControl({value: '', disabled: true})
  });

  // deal type and asset class selection
  selectedInvestorMandateAssets: string[] = [];
  selectedAssetIdx: boolean[] = [];
  selectedInvestorMandateDeals: string[] = [];
  selectedDealIdx: boolean[] = [];
  selectedInvestorAnchorInterests: string[] = [];
  selectedAnchorIdx: boolean[] = [];

  //theme input
  @ViewChild('themeInput') themeInput: any;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  themeCtrl = new FormControl('');
  filteredThemes: Observable<string[]>;
  selectedThemes: string[] = [];

  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
  mandateId = '';

  constructor(
    private store: Store,
    private router: Router,
    private mandateService: MandateService,
    private toastr: ToastrService,
    public util: UtilService
  ) {

    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });

    this.lookup$ = this.store.select(getLookups).subscribe(lookups => {
      this.lookups = lookups;
    });

    this.lookup$.add(this.store.select(getDealTypes).subscribe(dealTypes => {
      this.patchFormControls();
    }));

    this.lookup$.add(this.store.select(getAssetTypes).subscribe(assetTypes => {
      this.patchFormControls();
    }));

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.selectedBusinessUnit = Object.assign({}, bu);
    });

    this.selectedMandateId$ = this.store.select(getSelectedMandateId).pipe(delay(0)).subscribe((id: string) => {
      this.mandateId = id;
      if (!!this.selectedBusinessUnit && !!this.selectedBusinessUnit.businessUnitId && !!this.mandateId) {
        this.subscribeToDatasource();
      }
    });

    //add the possible themes
    this.filteredThemes = this.themeCtrl.valueChanges.pipe(
      startWith(null),
      map((theme: string | null) => (theme ? this._filter(theme) : this.lookups.areasOfFocus.map(l => {
        return l.value
      }).slice())),
    );
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedMandateId$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
  }

  subscribeToDatasource(): void {
    this.mandateService.getMandate(this.config.selected_mandate_id).subscribe(res => {
      this.mandate = res;
      this.patchFormControls();
      this.userForm.patchValue(this.mandate);
      this.patchFormControlsPostPatch();
    });

    if (!!this.selectedBusinessUnit && this.selectedBusinessUnit.businessUnitType?.toLowerCase() == 'admin') {
      this.userForm.get('workflowStatus')?.enable();
    }
  }

  patchFormControls() {
    if (!!this.mandate) {
      this.applyAssetTypes();
      this.applyDealTypes();
      this.applyAnchorTypes();
      this.applyKeywords();
      this.applyInvestorMandateTimeLines();
    }
  }

  patchFormControlsPostPatch() {
    if (!!this.mandate) {
      this.applyInvestorMandateTimeLines();
    }
  }

  private applyDealTypes() {
    this.applySelection(this.selectedInvestorMandateDeals, this.selectedDealIdx, this.mandate.investorMandateDeals, this.lookups.dealTypes);
  }

  private applyAssetTypes() {
    this.applySelection(this.selectedInvestorMandateAssets, this.selectedAssetIdx, this.mandate.investorMandateAssets, this.lookups.assetTypes);
  }

  private applyAnchorTypes() {
    this.applySelection(this.selectedInvestorAnchorInterests, this.selectedAnchorIdx, this.mandate.investorMandateAnchorInterests, this.lookups.anchorInterestTypes);
  }

  private applyInvestorMandateTimeLines() {
    if (!!this.mandate?.investorMandateTimeLines && !!this.mandate.investorMandateTimeLines[0]) {
      for (let x = 0; x <= this.lookups.timeLineTypes.length - 1; x++) {
        if (this.mandate.investorMandateTimeLines[0].key === this.lookups.timeLineTypes[x].key) {
          const k = this.lookups.timeLineTypes[x];
          this.userForm.get("investorMandateTimeLines")?.setValue(k.key);
        }
      }
    }
  }

  private applyKeywords() {
    if (!!this.mandate?.investorMandateAreasOfFocus) {
      //map to array of string
      this.selectedThemes = this.mandate.investorMandateAreasOfFocus.map((theme: any) => {
        return theme.key;
      });
    }
  }


  //synchronizes the form data with the provided keys as paired with the lookup
  private applySelection(cache: string[], idxCache: boolean[], keys: any[], lookup: ILookup[]) {
    if (!!keys) {
      cache = keys;
      for (let i = 0; i <= keys.length - 1; i++) {
        for (let x = 0; x <= lookup.length - 1; x++) {
          if (keys[i].key === lookup[x].key) {
            idxCache[x] = true;
          }
        }
      }
    }
  }

  save() {
    let payload = this.userForm.getRawValue() as unknown as iMandateRequest;

    //standalone form obj
    payload.investorMandateAssets = this.mandate.investorMandateAssets.map((x: any) => {
      return {key: x.key};
    });
    //standalone form obj
    payload.investorMandateDeals = this.mandate.investorMandateDeals.map((y: any) => {
      return {key: y.key};
    });
    //standalone form obj
    payload.investorMandateAnchorInterests = this.mandate.investorMandateAnchorInterests.map((z: any) => {
      return {key: z.key};
    });
    //areas of focus has to be mapped to obj
    payload.investorMandateAreasOfFocus = Object.assign(this.selectedThemes.map(t => {
      return {"key": t};
    }));

    //format the timelines
    payload.investorMandateTimeLines = [
      {
        key: this.userForm.get("investorMandateTimeLines")?.value || '',
      }
    ];

    this.store.dispatch(toggleLoading({loading: true}));
    this.mandateService.updateMandate(payload).subscribe({
      next: res => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Mandate Updated!", $localize`:@@companyName:Rondeivu`);
      }, error: error => {
        this.toastr.error("Unable to update mandate!", $localize`:@@companyName:Rondeivu`);
        this.store.dispatch(toggleLoading({loading: false}));
      }
    });

  }

  /**
   * Form action for selecting deal type
   * @param deal
   */
  toggleDealType(deal: ILookup) {
    this.toggleLookup(this.mandate.investorMandateDeals, deal);
  }

  /**
   * Form action for selecting asset class
   * @param asset
   */
  toggleAssetClass(asset: ILookup) {
    this.toggleLookup(this.mandate.investorMandateAssets, asset);
  }

  /**
   * Form action for selecting anchor interest
   * @param asset
   */
  toggleAnchorInterest(asset: ILookup) {
    this.toggleLookup(this.mandate.investorMandateAnchorInterests, asset);
  }

  /**
   * This is a core function for all component pages that use the multicheckbox for the lookup selection
   * @param list the list to modify
   * @param lookup the lookup to toggle
   * @private
   */
  private toggleLookup(list: { key: string }[], lookup: ILookup) {
    if (!!list) {
      //search for the key of the lookup
      let found = false;
      let foundIdx = -1;
      for (let i = 0; i <= list.length - 1; i++) {
        if (list[i].key === lookup.key) {
          found = true;
          foundIdx = i;
          break;
        }
      }
      //only add if not found
      if (!found) {
        list.push(lookup);
      } else {
        if (foundIdx >= 0) {
          list.splice(foundIdx, 1);
        }
      }
      // console.log(list);
    }
  }

  /**
   * Add theme/keyword to the list
   */
  addTheme(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add our theme
    // console.log(value);
    if (value && !this.selectedThemes.includes(value)) {
      this.selectedThemes.push(value);
    }
    // Clear the input value
    event.chipInput!.clear();
    this.themeCtrl.setValue(null);
  }

  /**
   * Remove theme/keyword form the list
   * @param theme
   */
  removeTheme(theme: string): void {
    const index = this.selectedThemes.indexOf(theme);

    if (index >= 0) {
      this.selectedThemes.splice(index, 1);
    }
  }

  /**
   * Form action for selecting or typing a theme/keyword
   * @param event the selected event
   */
  selectedTheme(event: MatAutocompleteSelectedEvent): void {
    if (!this.selectedThemes.includes(event.option.viewValue)) {
      this.selectedThemes.push(event.option.viewValue);
    }
    if (!!this.themeInput) {
      this.themeInput.nativeElement.value = '';
    }
    this.themeCtrl.setValue(null);
  }

  /*
  Filters the autocomplete list
   */
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    // console.log(this.lookups.areasOfFocus.map(l=>{return l.value}));
    return this.lookups.areasOfFocus.map(l => {
      return l.value
    }).filter(theme => theme.toLowerCase().includes(filterValue));
  }

}
