import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  Config,
  getConfig,
  getEditingBusinessUnit,
  getSelectedBusinessUnit,
  initialConfigState,
  initialThemeState,
  Theme, toggleLoading
} from "../../../redux";

import {ToastrService} from "ngx-toastr";
import {Store} from "@ngrx/store";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {BusinessUnitService} from "../../services";
import {IBusinessUnit, IEmployeeResponse} from "../../models";
import {Subscription} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {
  BusinessUnitEmployeeModalComponent
} from "../business-unit-employee-modal/business-unit-employee-modal.component";
import {
  DeleteConfirmationModalComponent
} from "../../../shared/components";
import {ModalSize, UtilService} from "../../../shared/services/util/util.service";
import {Router} from "@angular/router";
import { AddNewEmployeeComponent } from 'src/app/dialogs/add-new-employee/add-new-employee.component';

@Component({
  selector: 'app-business-unit-employees',
  templateUrl: './business-unit-employees.component.html',
  styleUrls: ['./business-unit-employees.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BusinessUnitEmployeesComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  editingBusinessUnit$: Subscription = new Subscription();

  dataSource: MatTableDataSource<IEmployeeResponse> = new MatTableDataSource();
  employees: IEmployeeResponse[] = [];
  sortedEmployees: IEmployeeResponse[] = [];
  config: Config = initialConfigState;
  theme: Theme = initialThemeState;
  columnsToDisplay = ['employee', 'title', 'role', 'invite', 'active', 'created', 'actions'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  adminBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;

  constructor(private businessUnitService: BusinessUnitService,
              private toastr: ToastrService,
              private store: Store,
              private dialog: MatDialog,
              public util: UtilService,
              private router: Router
  ) {
    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.adminBusinessUnit = Object.assign({}, bu);
    });

    this.editingBusinessUnit$ = this.store.select(getEditingBusinessUnit).subscribe((sbu: IBusinessUnit) => {
      if (!!sbu && this.selectedBusinessUnit.businessUnitId != sbu.businessUnitId) {
        this.selectedBusinessUnit = Object.assign({}, sbu);
        if (!!this.adminBusinessUnit && !!this.adminBusinessUnit.businessUnitId &&
          !!this.selectedBusinessUnit && !!this.selectedBusinessUnit.businessUnitId &&
          this.adminBusinessUnit.businessUnitType == 'ADMIN') {
          this.subscribeToDatasource();
        }
      }
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.editingBusinessUnit$.unsubscribe();
  }

  subscribeToDatasource(): void {
    console.log("Fetching Business Unit Employees...");
    this.getEmployees();
  }

  getEmployees() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.businessUnitService.getBusinessUnitEmployees(this.selectedBusinessUnit.businessUnitId).subscribe({
      next: (employees: IEmployeeResponse[]) => {
        this.employees = employees;
        this.dataSource = new MatTableDataSource(this.employees);
        this.initDataSource();
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: (error: any) => {
        this.toastr.error("Unable to get business unit employees!", $localize`:@@companyName:Rondeivu`);
        this.store.dispatch(toggleLoading({loading: false}));
      }
    });
  }

  initDataSource() {
    this.dataSource.filterPredicate = (data: IEmployeeResponse, filter: string) => {
      const FN = data?.user.firstName?.toLowerCase().includes(filter.toLowerCase());
      const LN = data?.user.lastName?.toLowerCase().includes(filter.toLowerCase());
      const EMAIL = data?.user.email?.toLowerCase().includes(filter.toLowerCase());
      return FN || LN || EMAIL;
    };
    if (!!this.paginator) {
      this.paginator.pageSize = 10;
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete(employee: IEmployeeResponse) {
    const delRef = this.dialog.open(DeleteConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      data: employee
    });

    delRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.store.dispatch(toggleLoading({loading: true}));
        this.businessUnitService.deleteBusinessUnitEmployee(employee.id).subscribe({
          next: res => {
            this.subscribeToDatasource();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("Business Unit Employee Removed!", $localize`:@@companyName:Rondeivu`);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to remove employee!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  viewInfo(employee: IEmployeeResponse) {
    if (!!employee.id) {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/business-units/' + this.config.editing_business_unit.businessUnitId + '/employees/' + employee.id + '/account']);
    }
  }

  viewPermissions(employee: IEmployeeResponse) {
    console.log('/' + this.config.selected_business_unit.slug + '/business-units/' + this.config.editing_business_unit.businessUnitId + '/employees/' + employee.id + '/permissions');
    if (!!employee.id) {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/business-units/' + this.config.editing_business_unit.businessUnitId + '/employees/' + employee.id + '/permissions']);
    }
  }

  editEmployee(employee: IEmployeeResponse) {
    let employeeRef = this.dialog.open(BusinessUnitEmployeeModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      disableClose: true,
      data: {
        isEditing: true,
        employee: employee
      }
    });

    employeeRef.afterClosed().subscribe(result => {
      if (!!result) {
        console.log("Dialog closed with payload!");
        this.store.dispatch(toggleLoading({loading: true}));
        this.businessUnitService.updateBusinessUnitEmployee(result).subscribe({
          next: (res: any) => {
            this.subscribeToDatasource();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("Business Unit Employee Updated!", $localize`:@@companyName:Rondeivu`);
          }, error: error => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to update employee!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  addEmployee() {
    this.dialog.open(AddNewEmployeeComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      disableClose: true
    })
    .afterClosed().subscribe(result => {
      if (!!result) {
        console.log("Dialog closed with payload!");
        this.store.dispatch(toggleLoading({loading: true}));
        this.businessUnitService.addBusinessUnitEmployee(result).subscribe({
          next: (res: any) => {
            this.subscribeToDatasource();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("Business Unit Employee Added!", $localize`:@@companyName:Rondeivu`);
          }, error: error => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to add employee!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  //sort
  sortData(sort: Sort) {
    const data = this.employees.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedEmployees = data;
      return;
    }

    this.sortedEmployees = data.sort((a: IEmployeeResponse, b: IEmployeeResponse) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'employee':
          const n1 = a.user.firstName + ' ' + a.user.lastName;
          const n2 = b.user.firstName + ' ' + b.user.lastName;
          return compare(n1 || '', n2 || '', isAsc);
        case 'title':
          return compare(a.title || '', b.title || '', isAsc);
        case 'active':
          return compare(a.isEmployeeActive.toString() || '', b.isEmployeeActive.toString() || '', isAsc);
        case 'status':
          return compare(a.memberInviteStatus || '', b.memberInviteStatus || '', isAsc);
        case 'created':
          return compare(a.user.created || '', b.user.created || '', isAsc);
        default:
          return 0;
      }
    });

    this.dataSource = new MatTableDataSource(this.sortedEmployees);
    this.initDataSource();
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
