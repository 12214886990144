import {Injectable} from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {Location} from '@angular/common';
import {config, first, from, mergeMap, Observable, of, tap} from 'rxjs';
import {IBusinessUnit, IBusinessUnitRole} from "../modules/business-units/models";
import {
  Config,
  getSelectedBusinessUnit,
  updateBusinessUnits,
  updateNavItems,
  updateSelectedBusinessUnit, updateSelectedBusinessUnitRole,
  updateSelectedUserView
} from "../modules/redux";
import {BusinessUnitService} from "../modules/business-units/services";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {INavItem} from "../models";
import {AppConfigService, AuthService} from "../services";
import {UtilService} from "../modules/shared/services";
import { HttpErrorResponse } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

enum RedirectType {
  ALLOW,
  PAGE_NOT_FOUND,
  FIRST_AVAILABLE_NAV_ITEM
}

@Injectable({
  providedIn: 'root'
})
export class BusinessUnitResolver implements Resolve<IBusinessUnit | undefined> {

  // private readonly LOGIN_PATH = '/auth/login';
  //TODO - if third-party-determination is added as an api nav object then remove from list below
  // private readonly EXCEPTION_LIST = ['/contact-us', '/settings', '/history', '/documents', '/third-party-determination'];
  // private buid: string | null = '';
  // private selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;

  // private validNavItem: INavItem | null = null;

  constructor(
    private title: Title,
    private toastr: ToastrService,
    private businessUnitService: BusinessUnitService,
    private store: Store,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IBusinessUnit | undefined> {
    console.log("Running business unit resolver!");
    return this.store.select(getSelectedBusinessUnit).pipe(first(), tap((bu : IBusinessUnit) => {
      this.title.setTitle((environment.production ? "" : environment.rondeivu_app_title + " ") + "| " + (bu.businessUnitType == "ADMIN" ? 'ADMIN' : bu.displayName));
  
        this.store.dispatch(updateSelectedBusinessUnit({businessUnit: bu}));
        this.store.dispatch(updateSelectedUserView({view: bu.businessUnitType}));

        const employeeId = bu.employeeId || '';

        console.log("Employee Id is ", employeeId);

        // get the user role for this business unit
        this.businessUnitService.getEmployeePermissions(employeeId).pipe(first()).subscribe({
          next: (role: IBusinessUnitRole) => {
            // console.log("BU employee permissions", role);
            this.store.dispatch(updateSelectedBusinessUnitRole({businessUnitRole: role}));

            // resolve(bu);
          }, error: err => {
            this.toastr.error("Could not load Employee Permissions");
            // reject(false);
          }
        });
    }));


    
    // return from(new Promise<IBusinessUnit>((resolve, reject) => {
    //   this.store.select(getSelectedBusinessUnit).pipe(first()).subscribe({
    //     next: (bu: IBusinessUnit) => {
    //       // you got the BU, 
    //       // console.log("Selected BU", bu);

    //       // change the title of the Windwo depending on BU name/type and environment
    //       this.title.setTitle((environment.production ? "" : environment.rondeivu_app_title + " ") + "| " + (bu.businessUnitType == "ADMIN" ? 'ADMIN' : bu.displayName));
  
    //       this.store.dispatch(updateSelectedBusinessUnit({businessUnit: bu}));
    //       this.store.dispatch(updateSelectedUserView({view: bu.businessUnitType}));
  
    //       const employeeId = bu.employeeId || '';
  
    //       // console.log("Employee Id is ", employeeId);
  
    //       // get the user role for this business unit
    //       this.businessUnitService.getEmployeePermissions(employeeId).pipe(first()).subscribe({
    //         next: (role: IBusinessUnitRole) => {
    //           // console.log("BU employee permissions", role);
    //           this.store.dispatch(updateSelectedBusinessUnitRole({businessUnitRole: role}));
    //           resolve(bu);
    //         }, error: err => {
    //           this.toastr.error("Could not load Employee Permissions");
    //           reject(false);
    //         }
    //       });
    //     }
    //   });
    // }))
  
  }

  // private canAccessRoute(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Promise<boolean> {
  //   return new Promise<boolean>((resolve, reject) => {
  //     // get the data from the current business unit & dispatch
  //     let buVerified = false;
  //     let buSlug = route.paramMap.get('id');

  //     this.businessUnitService.getUserBusinessUnits().subscribe((businessUnits: IBusinessUnit[]) => {
  //       //all business units for this user
  //       this.store.dispatch(updateBusinessUnits({businessUnits: businessUnits}));
  //       //verify by slug
  //       businessUnits.forEach(bu => {
  //         if (bu.slug === buSlug) {
  //           this.selectedBusinessUnit = Object.assign({}, bu);
  //           this.store.dispatch(updateSelectedBusinessUnit({businessUnit: this.selectedBusinessUnit}));
  //           this.store.dispatch(updateSelectedUserView({view: this.selectedBusinessUnit.businessUnitType}));
  //           buVerified = true;
  //         }
  //       });

  //       const employeeId = this.selectedBusinessUnit.employeeId || '';

  //       //get the user role for this business unit
  //       this.businessUnitService.getEmployeePermissions(employeeId).subscribe({
  //         next: (role: IBusinessUnitRole) => {
  //           this.store.dispatch(updateSelectedBusinessUnitRole({businessUnitRole: role}));
  //           resolve(true);
  //         }, error: err => {
  //           reject(false);
  //         }
  //       });
  //     });

  //     // get the navigation items & dispatch
  //   });
  // }

  // private canAccessRoute_eric(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //   return new Promise<boolean>((resolve, reject) => {
  //     this.auth.isValidCognitoSession().then((validCognito: boolean) => {
  //       this.fetchAndDispatchBusinessUnits(route, state).then((validBusinessUnit: boolean) => {
  //         this.fetchAndDispatchNavItem(route, state).then((allowed: RedirectType) => {
  //           switch (allowed) {
  //             case RedirectType.ALLOW:
  //               resolve(true);
  //               break;
  //             case RedirectType.PAGE_NOT_FOUND:
  //               resolve(true);
  //               this.router.navigate(['/' + this.selectedBusinessUnit.slug]).then(() => {
  //                 this.toastr.warning("Redirecting to page not found!", $localize`:@@companyName:Rondeivu`);
  //               });
  //               break;
  //             case RedirectType.FIRST_AVAILABLE_NAV_ITEM:
  //               resolve(true);
  //               this.router.navigate(['/' + this.selectedBusinessUnit.slug + this.validNavItem?.link]).then(() => {
  //                 this.toastr.warning("Redirecting to available route!", $localize`:@@companyName:Rondeivu`);
  //               });
  //               break;
  //           }
  //         }).catch(navErr => {
  //           reject(false);
  //           this.router.navigate([this.LOGIN_PATH]).then(() => {
  //             this.toastr.error("Unable to determine navigation access!", $localize`:@@companyName:Rondeivu`);
  //           });
  //         });
  //       }).catch(businessUnitError => {
  //         reject(false);
  //         this.util.logOut().then(() => {
  //           this.toastr.error("Access denied!", $localize`:@@companyName:Rondeivu`);
  //         });
  //       });
  //     }).catch(cognitoError => {
  //       this.util.setRedirectPath(state.url);
  //       this.router.navigate([this.LOGIN_PATH]).then(() => {
  //         this.toastr.error("Unable to determine access!", $localize`:@@companyName:Rondeivu`);
  //         reject(false);
  //       });
  //     })
  //   });
  // }


  // private fetchAndDispatchBusinessUnits(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
  //   return new Promise<boolean>((resolve, reject) => {
  //     let buVerified = false;
  //     this.buid = route.paramMap.get('id');

  //     this.businessUnitService.getUserBusinessUnits().subscribe((businessUnits: IBusinessUnit[]) => {
  //       //all business units for this user
  //       this.store.dispatch(updateBusinessUnits({businessUnits: businessUnits}));
  //       //verify by slug
  //       businessUnits.forEach(bu => {
  //         if (bu.slug === this.buid) {
  //           this.selectedBusinessUnit = Object.assign({}, bu);
  //           this.store.dispatch(updateSelectedBusinessUnit({businessUnit: this.selectedBusinessUnit}));
  //           this.store.dispatch(updateSelectedUserView({view: this.selectedBusinessUnit.businessUnitType}));
  //           buVerified = true;
  //         }
  //       });

  //       const employeeId = this.selectedBusinessUnit.employeeId || '';

  //       //get the user role for this business unit
  //       this.businessUnitService.getEmployeePermissions(employeeId).subscribe({
  //         next: (role: IBusinessUnitRole) => {
  //           this.store.dispatch(updateSelectedBusinessUnitRole({businessUnitRole: role}));
  //           resolve(true);
  //         }, error: err => {
  //           reject(false);
  //         }
  //       });
  //     });
  //   });
  // }

  /**
   *
   * @param route
   * @param state
   * @private
   */
  // private fetchAndDispatchNavItem(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<RedirectType> {
  //   return new Promise<RedirectType>((resolve, reject) => {
  //     // fetch and dispatch the nav items
  //     this.appConfig.getNavigationItems().subscribe({
  //       next: (navItems: INavItem[]) => {

  //         //todo for testing only
  //         // navItems.forEach(n => {
  //         //   n.enabled = false;
  //         // });
  //         // navItems[1].enabled = true;

  //         this.store.dispatch(updateNavItems({items: navItems}));
  //         const noQueryParams = state.url.split('?')[0];
  //         const requestedRoute = noQueryParams.split('/')[2];
  //         const childRoute = '/' + requestedRoute;

  //         // all items are disabled or hidden
  //         if ((requestedRoute == null)) {
  //           resolve(RedirectType.ALLOW);
  //         }

  //         // requested route is in exception list
  //         if (this.EXCEPTION_LIST.includes(childRoute)) {
  //           resolve(RedirectType.ALLOW);
  //         }

  //         // get the item that matches the requested route
  //         let navMatch = navItems.filter(n => {
  //           return n.link == childRoute ? n : null;
  //         })[0];

  //         if (!!navMatch) {
  //           // matched item is available
  //           if (navMatch.enabled && !navMatch.hidden) {
  //             resolve(RedirectType.ALLOW);
  //           } else {
  //             //get first available nav item
  //             this.validNavItem = navItems.filter((n: INavItem) => {
  //               return (n.enabled && !n.hidden) ? n : null;
  //             })[0];
  //             if (!!this.validNavItem) {
  //               resolve(RedirectType.FIRST_AVAILABLE_NAV_ITEM);
  //             } else {
  //               resolve(RedirectType.PAGE_NOT_FOUND);
  //             }
  //           }
  //         } else {
  //           // no match for the requested route
  //           reject(false);
  //         }
  //       }, error: () => {
  //         // error subscribing to nav items
  //         reject(false);
  //       }
  //     });
  //   });
  // }

}
