import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Location} from '@angular/common';
import {
  ApiConfigService,
  AppConfigService,
  LoadingService,
  LookupService,
  UuidService
} from 'src/app/services';
import {environment} from 'src/environments/environment';
import {delay, first, Observable, Subscription} from 'rxjs';
import {Store} from "@ngrx/store";
import {
  CognitoUser,
  Config, fetchTasks,
  getCognitoUser,
  getConfig,
  getNavItems,
  getSelectedBusinessUnit,
  getThemeState, getUserSettings,
  initialCognitoUserState,
  initialConfigState,
  initialThemeState,
  initialUserState,
  Theme,
  toggleDarkMode,
  toggleLoading,
  updateConfig,
  updateDrawerState,
  updateDrawerTabIdx,
  updateNavItems,
  updateSelectedBusinessUnit,
  updateSelectedUserView,
  updateUserSettings,
  UserSettings
} from "../../modules/redux";
import {IBusinessUnit} from "../../modules/business-units/models";
import {ToastrService} from "ngx-toastr";
import {IAttestation, INavItem, INote, ITask, LookupType} from "../../models";
import {TermsAndConditionsService} from "../../services/terms-and-conditions/terms-and-conditions.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AttestationService} from "../../services/attestation/attestation.service";
import {AttestationModalComponent} from "../attestation-modal/attestation-modal.component";
import {TermsAndConditionsModalComponent} from "../terms-and-conditions-modal/terms-and-conditions-modal.component";
import {loadLookup} from "../../modules/redux/stores/lookups/lookups.actions";
import {UtilService} from "../../modules/shared/services";
import {ModalSize} from "../../modules/shared/services/util/util.service";
import {UserCardSize} from "../../modules/shared/components";
import {UserSettingsService} from "../../modules/settings/services";
import {IUserSettings} from "../../modules/settings/models/i-user-settings";
import {toggleNavigating} from "../../modules/redux/stores/config/config.actions";
import {AppFade} from "../../animations/animations";
import { HttpErrorResponse } from '@angular/common/http';
import { apiVersion } from 'src/app/models/iApiVersion';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [AppFade()],
})
export class NavigationComponent implements OnInit, OnDestroy, AfterViewInit {

  // appVersion = require('../../../../package.json').version;
  appVersion: string = environment.version + "." + environment.buildNumber;
  buildDate: string = (!!environment.buildDate) ? new Date(environment.buildDate).toLocaleString() : "";

  //redux
  animationState = false;
  config$: Subscription = new Subscription();
  nav$: Subscription = new Subscription();
  cognito$: Subscription = new Subscription();
  theme$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  userSetting$: Subscription = new Subscription();
  apiVersion$ : Observable<apiVersion>;
  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
  cognitoUser = initialCognitoUserState;
  userSettings: IUserSettings = initialUserState;
  config: Config = initialConfigState;
  theme: Theme = initialThemeState;
  //navigation
  readonly ADMIN_REDIRECT_PATH = '/dashboard';
  readonly REDIRECT_PATH = '/deals';
  //animation
  enterAnim = false;
  exitAnim = false;
  show = true;
  //sidenav
  link = '';
  links: INavItem[] = [];
  //notes
  notes: INote[] = [];

  appEnvironment: string = environment.rondeivu_env_title;
  //view props

  private readonly HEADER_HEIGHT = 64;
  screenHeight = 0;
  //modals
  userCardSize = UserCardSize;

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private appConfig: AppConfigService,
    private uuidService: UuidService,
    private router: Router,
    private location: Location,
    private loader: LoadingService,
    private toastr: ToastrService,
    private termsService: TermsAndConditionsService,
    private attestationService: AttestationService,
    private dialog: MatDialog,
    public util: UtilService,
    private userSettingsService: UserSettingsService,
    private apiConfigService: ApiConfigService,
    private lookupService: LookupService
  ) {
    this.uuidService.generateUUID();

    // set the api Version, it will get it on the html via ASYNC pipe
    this.apiVersion$ = this.apiConfigService.getVersion();

    console.log("RESOLVERS constructors -->", this.activatedRoute.snapshot.data);

    this.selectedBusinessUnit = this.activatedRoute.snapshot.data['businessUnit'] as IBusinessUnit;

    if (this.selectedBusinessUnit != null) {
      this.store.dispatch({type: fetchTasks});

      // first get the Terms & Conditions, then the Attestation
      this.getTermsConditionsThenAttestation();
    }

    // user settings
    this.userSettings = this.activatedRoute.snapshot.data['userSettings'] as UserSettings;
    
    // only display items that are Enabled and NOT Hidden
    let navItems: INavItem[] = this.activatedRoute.snapshot.data['navigation'] as INavItem[];
    this.links = navItems.filter(x => x.hidden == false);

    // get the first nav that is enabled
    let enabledNavs = this.links.filter(x => x.enabled);

    if (enabledNavs.length == 0) {
      // there are no enabled navs
      this.toastr.error("There are no enabled Navigation items for this user.");
      this.router.navigate([this.selectedBusinessUnit.slug, 'error']);
      return;
    }
    
    // subscribe to config
    this.config$ = this.store.select(getConfig).subscribe((configState: Config) => {
      this.config = configState;
      if (this.config?.show_navigation_toolbar) {
        this.screenHeight = screen.height - this.HEADER_HEIGHT;
      } else {
        this.screenHeight = screen.height
      }
    });

    // subscribe to the cognito store
    this.cognito$ = this.store.select(getCognitoUser).subscribe((cognito: CognitoUser) => {
      this.cognitoUser = cognito;
    });

    // // subscribe to the theme store
    // this.theme$ = this.store.select(getThemeState).subscribe((themeState: any) => {
    //   this.theme = themeState;
    // });

    // this.userSetting$ = this.store.select(getUserSettings).subscribe((userSettings: IUserSettings) => {
    //   this.userSettings = userSettings;
    // });

    this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationStart) {
        this.animationState = false;
      }
      if (e instanceof NavigationEnd) {
        this.animationState = true;
      }
    });
  }

  ngOnInit() {
    this.animationState = true;
    this.store.dispatch(loadLookup({lookup: LookupType.Private}));
  }

  ngOnDestroy() {
    this.theme$.unsubscribe();
    this.config$.unsubscribe();
    this.cognito$.unsubscribe();
    this.nav$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.userSetting$.unsubscribe();
  }

  ngAfterViewInit() {
    // If NAV not selected, then select a nav
    const urlSplitWithQueryParam = this.router.url.split('?');
    // const queryParamsStr = urlSplitWithQueryParam.length > 1 ? urlSplitWithQueryParam[1] : '';
    const urlSplt = urlSplitWithQueryParam[0].split('/').filter(x => !!x);

    if (urlSplt.length == 1) {
      // no nav has been selected
      let enabledNavs = this.links.filter(x => x.enabled);

      setTimeout(() => {
        this.navigateToNavItem(enabledNavs[0]);
      })
    }
  }

  logOut() {
    this.ngOnDestroy();
    this.util.logOut().then(() => {
      console.log("Logged User Out")
    });
  }

  /**
   * Get the user settings
   */
  // getUserSettings(): void {
  //   // this.store.dispatch(toggleLoading({loading: true}));
  //   this.userSettingsService.getUserSettings().subscribe({
  //     next: (userSettings: IUserSettings) => {
  //       console.log("User Settings", userSettings);
  //       // this.userSettings = userSettings;
  //       this.store.dispatch(updateUserSettings({user: userSettings}));
  //     }, 
  //     error: console.error
  //   });
  // }


  getTermsConditionsThenAttestation() {
    this.termsService.getTermsAndConditions().pipe(first()).subscribe({
      next: (res: { termsAndConditionsRequired: boolean, bodyHtml: string, pdfDownloadLink: string }) => {
        console.log("T&C result", res);
        if (!!res && res.termsAndConditionsRequired) {
          this.dialog.open(TermsAndConditionsModalComponent, {
            height: 'auto',
            width: this.util.getModalWidth(ModalSize.MEDIUM),
            backdropClass: "black-out-modal",
            disableClose: true,
            data: res
          })
          .afterClosed().subscribe(result => {
            if (!!result) {
              this.termsService.signTermsAndConditions().subscribe((res: any) => {
                this.toastr.success("Terms and Conditions Signed!", $localize`:@@companyName:Rondeivu`);
                this.getAttestation();
              });
            }
          });
        } else {
          this.getAttestation();
        }
      }, error: console.error
    });
  }

  /**
   * Creates the attestation modal and handles signing
   */
  getAttestation() {
    // console.log("Get Attestation");
    this.attestationService.getAttestation().pipe(first()).subscribe({
      next: (res: IAttestation) => {
        console.log("Attestation results", res);
        if (!!res && res.attestationRequired) {
          this.dialog.open(AttestationModalComponent, {
            height: 'auto',
            width: this.util.getModalWidth(ModalSize.MEDIUM),
            backdropClass: "black-out-modal",
            disableClose: true,
            data: res
          })
          .afterClosed().subscribe(result => {
            if (!!result) {
              // console.log("Dialog closed with payload!");
              this.attestationService.signAttestation().subscribe((res: any) => {
                this.toastr.success("Thank you, Welcome to Rondeivu!", $localize`:@@companyName:Rondeivu`);
              });
            }
          });
        }
      }, error: console.error
    });
  }

  /**
   * User selects a business unit
   * @param businessUnit
   */
  selectBusinessUnit(businessUnit: IBusinessUnit) {
    if (businessUnit.isEmployeeActive == false) {
      this.toastr.error("You do not have access to this Business Unit, as you are temporarily suspended.")
      return;
    }
  
    // this.store.dispatch(toggleLoading({loading: true}));
    // this.store.dispatch(updateSelectedBusinessUnit({businessUnit: businessUnit}));
    // this.store.dispatch(updateSelectedUserView({view: businessUnit.businessUnitType.toString()}));
    // this.store.dispatch(loadLookup({lookup: LookupType.Private}));
    // this.util.clearStoreCache();
    // this.ngOnDestroy();
    // this.ngOnInit();
    this.store.dispatch(toggleLoading({loading: true}));
    this.router.navigate([ businessUnit.slug ]).then(() => {
      this.toastr.success(`Now acting as '${businessUnit.displayName}'`);
      this.store.dispatch(toggleLoading({loading: false}));
      window.location.reload();
    });
  }

  /**
   * Helper funcion to get the row color as determined by business unit selection
   * @param b selected business unit
   */
  getRowColor(b: IBusinessUnit): string {
    if (!!b && !!this.config.selected_business_unit) {
      if (b.businessUnitId === this.config.selected_business_unit.businessUnitId) {
        return 'whitesmoke';
      }
    }
    return '';
  }

  //legacy testing
  navToProfile() {
    this.store.dispatch(toggleNavigating({navigating: true}));
    this.animationState = false;
    setTimeout(() => {
      this.router.navigate([this.config.selected_business_unit.slug + '/settings']).then(() => {
        this.store.dispatch(toggleNavigating({navigating: false}));
        this.animationState = true;
      });
    }, 500);
  }

  /**
   * Navigate to the route according to the task
   * @param task
   */
  goToTask(task: ITask) {
    let link = ''
    if (!!task) {
      // if an action exists then dispatch it
      if (!!task.action) {
        this.store.dispatch({type: task.action});
      }
      // routing construction
      if (!!task.businessUnitSlug && !!task.route) {
        link = task.businessUnitSlug + '/' + task.route;
        if (!!task.routeId) {
          link += '/' + task.routeId;
          if (!!task.fragment) {
            link += '/' + task.fragment;
          }
        }
        if (!!task.fragment) {
          this.toastr.warning("Task Error: No Object Id", $localize`:@@companyName:Rondeivu`);
        }
      } else {
        this.toastr.warning("Task Error: No Business Unit", $localize`:@@companyName:Rondeivu`);
      }
      // task routing
      if (link != '') {
        if (!!task.queryParams) {
          this.animationState = false;
          this.store.dispatch(toggleNavigating({navigating: true}));
          setTimeout(() => {
            this.router.navigate([link], {queryParams: task.queryParams}).then(() => {
              this.store.dispatch(toggleNavigating({navigating: false}));
              this.animationState = true;
            });
          }, 500);
        } else {
          this.animationState = false;
          this.store.dispatch(toggleNavigating({navigating: true}));
          setTimeout(() => {
            this.router.navigate([link]).then(() => {
              this.store.dispatch(toggleNavigating({navigating: false}));
              this.animationState = true;
            });
          }, 500);
        }
      }
    }
  }

  getTaskBg(task: ITask) {
    // switch on status
    switch (task.status?.toLowerCase()) {
      case 'task':
        return this.theme.tasks.task;
      case 'complete':
        return this.theme.tasks.complete;
      case 'pending':
        return this.theme.tasks.pending;
      case 'info':
        return this.theme.tasks.info;
      default:
        return '';
    }
  }

  getTaskImage(status: string) {
    // switch on status
    switch (status?.toLowerCase()) {
      case 'task':
        return 'pending_actions';
      case 'complete':
        return 'task_alt';
      case 'pending':
        return 'schedule';
      case 'info':
        return 'info_outline';
      default:
        return 'pending_action';
    }
  }

  /**
   * The main navigation triggers an animation before routing
   * @param navItem
   */
  navigateToNavItem(navItem: INavItem) {
    console.log("Navigating to ", navItem);
    if (navItem.enabled == false || navItem.hidden == true) {
      // this item cannot be accessed by the user
      this.toastr.error("You cannot access this navigation item");
      return;
    }

    this.store.dispatch(toggleNavigating({navigating: true}));
    this.router.navigate([this.config.selected_business_unit.slug + navItem.link], { queryParams: navItem.queryParams }).then(() => {
      this.store.dispatch(toggleNavigating({navigating: false}));
    });
  }

  isActiveRoute(navObj: INavItem) : boolean {
    const urlSplitWithQueryParam = this.router.url.split('?');
    const queryParamsStr = urlSplitWithQueryParam.length > 1 ? urlSplitWithQueryParam[1] : '';
    const urlSplt = urlSplitWithQueryParam[0].split('/').filter(x => !!x);
    // const queryParams = this.router.parseUrl(this.router.url).queryParams;

    if (urlSplt.length <= 1) {
      return false;
    }

    var navRouteSegment = urlSplt[1];
    if (urlSplitWithQueryParam.length > 1) {
      // compare route and query params
      if (navObj.link === "/" + navRouteSegment && 
          queryParamsStr == new URLSearchParams(navObj.queryParams).toString()) {
        return true
      }
      else {
        return false
      }
    }
    else {
      return navObj.link === "/" + navRouteSegment;
    }
  }

  getActiveRouteClass(navObj: INavItem) : string {
    // let isActive = this.isActiveRoute(navObj);
    if (navObj.enabled == false) {
      return 'nav-item nav-item-disabled';
    }

    return this.isActiveRoute(navObj) ? 'nav-item active-title' : 'nav-item';
  }

  handleEsc() {
    //do nothing
  }

  getNavItemLinkWithParams(navObj: any) {
    if (!!navObj.params) {
      let str = '?';
      let keys = Object.keys(navObj.params);
      keys.forEach(key => {
        str += key + '=' + navObj.params[key].toString();
      });
      return navObj.link + str;
    }
    return navObj.link;
  }

  /**
   * Toggles the right drawer open even dispatching the tab index based off the icon name
   * @param event
   */
  toggleDrawer(event: string) {
    //the event returns the name of the icon being used
    switch (event) {
      case 'description':
        // console.log('dispatch: ' + updateConfig.type);
        this.store.dispatch(updateConfig({
          config: {
            ...this.config,
            right_drawer_open: !this.config.right_drawer_open,
            right_drawer_tab_idx: 0
          }
        }));
        break;
      case 'format_list_numbered':
        // console.log('dispatch: ' + updateConfig.type);
        this.store.dispatch(updateConfig({
          config: {
            ...this.config,
            right_drawer_open: !this.config.right_drawer_open,
            right_drawer_tab_idx: 1
          }
        }));
        break;
      case 'speaker_notes':
        // console.log('dispatch: ' + updateConfig.type);
        this.store.dispatch(updateConfig({
          config: {
            ...this.config,
            right_drawer_open: !this.config.right_drawer_open,
            right_drawer_tab_idx: 2
          }
        }));
        break;
      default:
        // console.log('dispatch: ' + updateConfig.type);
        this.store.dispatch(updateConfig({
          config: {
            ...this.config,
            right_drawer_open: !this.config.right_drawer_open,
            right_drawer_tab_idx: 0
          }
        }));
    }
  }

  /**
   * Toggles the app dark mode
   * @param val
   */
  toggleDarkMode(val: boolean) {
    // console.log('drawer tab idx change: ' + val);
    this.config.rondeivu_theme_dark = val;
    // console.log('dispatch: ' + toggleDarkMode.type);
    this.store.dispatch(toggleDarkMode({mode: val}));
  }

  /**
   * Triggered when the right drawer is closed
   * @param event
   */
  closeDrawer(event: boolean) {
    // console.log('drawer tab idx change: ' + event);
    if (this.config.right_drawer_open && !event) {
      // console.log('dispatch: ' + updateDrawerState.type);
      this.store.dispatch(updateDrawerState({open: event}));
    }
  }

  /**
   * Triggered when the right drawer tab group is changed
   * @param event
   */
  changeTab(event: number) {
    // console.log('drawer tab idx change: ' + event);
    if (this.config.right_drawer_tab_idx != event) {
      // console.log('dispatch: ' + updateDrawerTabIdx.type);
      this.store.dispatch(updateDrawerTabIdx({idx: event}));
    }
  }

}
