<div class="rm-header">
  <span>Investor Attestation</span>
</div>
<mat-dialog-content>
  <!-- <h1>Sign Attestation</h1> -->
  <div style="width: 100%;">
    <div class="row justify-content-center">
      <div class="pt-2" >
        <span style="font-size: medium; font-weight: lighter;">{{data.attestationDisplayText}}</span>
      </div>

      <div style="display: flex; flex-direction: column; gap: 15px; padding: 25px">
        <div class="row" *ngFor="let d of data.attestationHtmls">
          <div style="display: flex; gap: 25px; align-items: center; min-height: 40px">
            <mat-checkbox color="primary"></mat-checkbox>
            <span [innerHTML]="d"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions style="display: flex; justify-content: space-between;">
  <button mat-raised-button (click)="logOut()">Logout</button>
  <button mat-raised-button color="primary" (click)="action(data)"
      [disabled]="!canAction()">Submit
    Attestation
  </button>
</mat-dialog-actions>
