// navigation components
export {DealNavigationComponent} from "./deal-navigation/deal-navigation.component";
export {DealListComponent} from "./deal-list/deal-list.component";

// deal components
export {CommunicationComponent} from "./communication/communication.component";
export {DealAnalyticsComponent} from "./deal-analytics/deal-analytics.component";
export {DealMetricsComponent} from "./deal-metrics/deal-metrics.component";
export {DealSettingsComponent} from "./deal-settings/deal-settings.component";
export {DealMatchingComponent} from "./deal-matching/deal-matching.component";
export {DealDataRoomComponent} from "./deal-data-room/deal-data-room.component";
export {DocumentsComponent} from "./documents/documents.component";
export {DealHighlightsComponent} from "./deal-highlights/deal-highlights.component";
export {MediaComponent} from "./media/media.component";
export {DealTeamComponent} from "./deal-team/deal-team.component";
export {DealTeamModalComponent} from "./deal-team/deal-team-modal/deal-team-modal.component";

export {DealTeamMemberModalComponent} from "./deal-team-member-modal/deal-team-member-modal.component";
export {DealModalComponent} from "./deal-modal/deal-modal.component";
export {LockInModalComponent} from "./lock-in-modal/lock-in-modal.component";
export {SyndicationComponent} from "./syndication/syndication.component";
export {SubscriptionComponent} from "./subscription/subscription.component";
export {SubscriptionModalComponent} from "./subscription-modal/subscription-modal.component";
export {IndicateInterestModalComponent} from "./indicate-interest-modal/indicate-interest-modal.component";
export {NotesModalComponent} from "./notes-modal/notes-modal.component";
export {MnpiModalComponent} from "./deal-nda/mnpi-modal/mnpi-modal.component";
export {NegotiateModalComponent} from "./deal-nda/negotiate-modal/negotiate-modal.component";
export {DeclineModalComponent} from "./decline-modal/decline-modal.component";
export {InvestorTestComponent} from "./investor-test/investor-test.component";

export {DealViewComponent} from "./deal-view/deal-view.component";
export {DealEditComponent} from "./deal-edit/deal-edit.component";
export {DealMatchingModalComponent} from "./deal-matching/deal-matching-modal/deal-matching-modal.component";
export {MediaModalComponent} from "./media/media-modal/media-modal.component";
export {DealNdaComponent} from "./deal-nda/deal-nda.component";
export {DealHighlightsModalComponent} from "./deal-highlights/deal-highlights-modal/deal-highlights-modal.component";

export {DealTeamNavigationComponent} from "./deal-team-navigation/deal-team-navigation.component";
export {DealTeamInnerNavigationComponent} from "./deal-team-inner-navigation/deal-team-inner-navigation.component";
export {DealTeamMemberInfoComponent} from "./deal-team-member-info/deal-team-member-info.component";
export {DealTeamMemberPermissionsComponent} from "./deal-team-member-permissions/deal-team-member-permissions.component";

export {DealSubscriptionComponent} from "./deal-subscription/deal-subscription.component";
export {DealSubscriptionSubdocModalComponent} from "./deal-subscription-subdoc-modal/deal-subscription-subdoc-modal.component";
export {DealSubscriptionSubdocEditModalComponent} from "./deal-subscription-subdoc-edit-modal/deal-subscription-subdoc-edit-modal.component";
export {DealSubscriptionSubdocFormModalComponent} from "./deal-subscription-subdoc-form-modal/deal-subscription-subdoc-form-modal.component";
export {DealSubscriptionSubdocFormFieldComponent} from "./deal-subscription-subdoc-form-modal/deal-subscription-subdoc-form-field/deal-subscription-subdoc-form-field.component";

