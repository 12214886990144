<div style="height:calc(100vh - 64px); overflow-y: auto;">
  <div class="container-fluid">
    <br/><br/>
    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
        <div style="display: flex;">
          <mat-icon color="primary" class="rm-header-icon">store</mat-icon>&nbsp;
          <span class="rm-title rm-title-3">KYC</span>
        </div>
        <div>
          <span
            class="rm-title rm-title-6 rm-lighter">{{ util.isType('admin') ? 'These are all of the SUBMITTED KYC:' : 'These are all of the kyc for your business unit:' }}</span>
        </div>
      </div>
      <div class="col-md-3 text-end d-none d-md-block">
        <!--        <button mat-raised-button *ngIf="!util.isType('admin')"-->
        <!--                color="primary"-->
        <!--                (click)="addEntity()"-->
        <!--        >-->
        <!--          <mat-icon>store</mat-icon>&nbsp;ADD ENTITY-->
        <!--        </button>-->
      </div>
      <div class="col-12 d-flex d-md-none" style="padding-top: 25px;">
        <!--        <button mat-raised-button *ngIf="!util.isType('admin')"-->
        <!--                color="primary"-->
        <!--                (click)="addEntity()"-->
        <!--        >-->
        <!--          <mat-icon>store</mat-icon>&nbsp;ADD ENTITY-->
        <!--        </button>-->
      </div>
    </div>
    <!--    table start-->
    <div class="row justify-content-center d-none d-md-flex" style="padding-top: 25px;">
      <div class="col-12 col-md-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <table mat-table [dataSource]="dataSource"
                     [multiTemplateDataRows]="true" matSort (matSortChange)="sortData($event)">
                <!-- Search Header -->
                <ng-container matColumnDef="table-name">
                  <th colspan="4" mat-header-cell *matHeaderCellDef>
                    <span style="font-size: large;">KYC</span>
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="4" mat-header-cell *matHeaderCellDef>
                    <form>
                      <mat-form-field style="width: 100%;">
                        <mat-label>Search</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input/>
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form>
                  </th>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="business">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="business"> Business Unit</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    <app-user-card [firstName]="element.businessUnitMiniModel?.displayName"
                                   [displayName]="element.businessUnitMiniModel?.profile"
                                   [borderColor]="'transparent'"
                                   [sideColor]="'#ff0000'"
                                   [image]="element.businessUnitMiniModel?.['imageUrl']"
                                   (click)="util.isType('admin')? util.viewBusinessUnit(element.businessUnitMiniModel?.id, 'entities') : null"
                                   [ngStyle]="{'cursor': util.isType('admin')? 'pointer':''}"
                                   [highlightOnHover]="util.isType('admin')"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="entityName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="entityName"> Entity Name</th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element.businessUnitEntityName }}</span>
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="entityType">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="entityType">Entity Type</th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ getEntityTypeString(element) }}</span>
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="kycName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="kycName"> KYC Name</th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element.kycName }}</span>
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="kycStatus">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="kycStatus"> KYC Status</th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ getStatusString(element.kycRondeivuStatus) }}</span>
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="kycCreated">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="kycCreated"> KYC Created On</th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element.createdOn | date }}</span>
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="kycModified">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="kycModified"> KYC Last Modified On</th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element.lastModified | date }}</span>
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef> View</th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-icon-button type="button" (click)="viewEntityKyc(element)" matTooltip="View KYC"
                            matTooltipPosition="left">
                      <mat-icon>east</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <!--table rows-->
                <tr mat-header-row *matHeaderRowDef="['table-name','search-header'];"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;">
                  <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter.</td>
                </tr>
              </table>
            </div>
          </div>
          <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[25, 50, 100]"
                         [pageSize]="100"
                         aria-label="Select page of users"></mat-paginator>
        </div>
      </div>
    </div>
    <!--    list start-->
    <div class="row justify-content-center d-flex d-md-none" style="padding-bottom: 150px; padding-top: 15px;">
      <div class="col-12">
        <ul style="list-style-type: none; padding: 25px 0 0 0;">
          <li *ngFor="let element of dataSource.data"
              style="margin-bottom: 5px; display: flex; justify-content: space-between;">
            <app-user-card [firstName]="element.businessUnitEntityName"
                           [displayName]="element.kycName"
                           [borderColor]="'transparent'"
                           [sideColor]="'#ff0000'"
                           [image]="element.businessUnitMiniModel?.['imageUrl']"
            ></app-user-card>
            <div style="display: flex;">
              <button mat-icon-button (click)="viewEntityKyc(element)" matTooltip="View KYC"
                      matTooltipPosition="left">
                <mat-icon>east</mat-icon>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
