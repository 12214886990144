<div class="container-fluid pt-4">
  <div class="row justify-content-center">
    <div class="col-12 col-md-11">
      <span class="rm-title rm-title-3">Deal Highlights</span><br/>
    </div>
  </div>
  <div class="row justify-content-center" style="padding-bottom: 25px;">
    <div class="col-12 col-md-11">
      <span class="rm-title rm-title-6 rm-lighter">These are all of this deal's highlights.</span>
    </div>
  </div>
  <!-- issuer-->
  <div class="row justify-content-center pb-1">
    <div class="col-11">
      <span style="font-size: x-large; color: #002150;">Issuer</span>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-11">
      <!--        row start-->
      <div class="row">
        <div class="col-12">
          <span style="font-weight: bolder; font-size: large;">{{highlights?.issuer?.businessUnit?.displayName}}</span>
        </div>
      </div>
      <!--        row start-->
      <div class="row pb-4">
        <table class="issuers-display" border="0">
          <tr>
            <td>Administrators</td>
            <td>
              <div *ngFor="let admin of highlights?.issuer?.businessUnitEmployees" style="display: inline-flex;">
                <div class="pill"
                      [class]="(admin?.firstName != '') && (admin?.lastName != '') ? 'grey-pill' : 'yellow-pill'"
                      (click)="view(admin)">
                  <span>{{(admin?.firstName != '') && (admin?.lastName != '') ? (admin.firstName + ' ' + admin.lastName) : admin?.email}}</span>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>Users On the Deal Team</td>
            <td>
              <div *ngFor="let user of highlights?.issuer?.dealTeamMembers" style="display: inline-flex;">
                <div class="pill"
                      [class]="(user?.firstName != '') && (user?.lastName != '') ? 'grey-pill' : 'yellow-pill'"
                      (click)="view(user)">
                  <span>{{(user?.firstName != '') && (user?.lastName != '') ? (user.firstName + ' ' + user.lastName) : user?.email}}</span>
                </div>
              </div>
    
            </td>
          </tr>

          <tr>
            <td>Issuer NDA Status</td>
            <td>
              <span [appStatus]="highlights?.issuer?.issuerNdaStatus">
                {{highlights?.issuer?.issuerNdaStatus}}
              </span>
            </td>
          </tr>

          <tr>
            <td>Issuer Engagement Letter Status</td>
            <td>
              <span [appStatus]="highlights?.issuer?.issuerEngagementAgreementStatus">
                {{highlights?.issuer?.issuerEngagementAgreementStatus}}
              </span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>

  <!--investors-->
  <!--    table start-->
  <div class="row justify-content-center d-none d-md-flex pt-2 pb-4">
    <div class="col-11">
      <div class="table-container">
        <div class="row table-inner-container">
          <div class="table-inner-inner-container">
            <table mat-table [dataSource]="dataSource" [multiTemplateDataRows]="true" matSort>
              <!-- Search Header -->
              <ng-container matColumnDef="table-name">
                <th colspan="4" mat-header-cell *matHeaderCellDef>
                  <span style="font-size: large;">Investors</span>
                </th>
              </ng-container>
              <!-- Search Header -->
              <ng-container matColumnDef="search-header">
                <th colspan="5" mat-header-cell *matHeaderCellDef>
                  <form>
                    <mat-form-field style="width: 100%;">
                      <mat-label>Search</mat-label>
                      <input type="text" matInput (keyup)="applyFilter($event)" #input/>
                      <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                  </form>
                </th>
              </ng-container>
              <!-- Last Name Column -->
              <ng-container matColumnDef="business">
                <th mat-header-cell *matHeaderCellDef> Business Unit</th>
                <td mat-cell *matCellDef="let element">
                  <app-user-card [firstName]="element?.businessUnit?.displayName"
                                  [displayName]="element?.businessUnit?.profile"
                                  [image]="element?.businessUnit?.imageUrl"
                                  [borderColor]="'transparent'"
                                  [sideColor]="'#ff0000'"
                                  [imageShape]="'square'"></app-user-card>
                </td>
              </ng-container>
              <!-- Last Name Column -->
              <ng-container matColumnDef="admins">
                <th mat-header-cell *matHeaderCellDef> # Admin Users</th>
                <td mat-cell *matCellDef="let element">
                  {{element?.businessUnitEmployees?.length}}
                </td>
              </ng-container>
              <!-- Last Name Column -->
              <ng-container matColumnDef="dealTeams">
                <th mat-header-cell *matHeaderCellDef> # Deal Team Users</th>
                <td mat-cell *matCellDef="let element">
                  {{element?.dealTeamMembers?.length}}
                </td>
              </ng-container>
              <!-- Last Name Column -->
              <ng-container matColumnDef="kycStatus">
                <th mat-header-cell *matHeaderCellDef> KYC Status</th>
                <td mat-cell *matCellDef="let element">
                  <span [appStatus]="element.kycStatus">
                      {{element?.kycStatus}}
                  </span>
                </td>
              </ng-container>
              <!-- Last Name Column -->
              <ng-container matColumnDef="ndaStatus">
                <th mat-header-cell *matHeaderCellDef> Signed NDA(s)</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element?.isGlobalNdaSigned" style="font-size: large; font-weight: bold; cursor: help;" matTooltip="Global NDA" matTooltipPosition="right">G </span>
                  <span *ngIf="element?.isDealNdaSigned" style="font-size: large; font-weight: bold; cursor: help;" matTooltip="Deal-by-Deal NDA" matTooltipPosition="right">D </span>
                  <span *ngIf="element?.isIssuerNdaSigned" style="font-size: large; font-weight: bold; cursor: help;" matTooltip="Issuer's own NDA" matTooltipPosition="right">I </span>
                  <!-- {{element?.isGlobalNdaSigned || element?.isDealNdaSigned}} -->
                </td>
              </ng-container>

              <ng-container matColumnDef="isPreNdaReason">
                <th mat-header-cell *matHeaderCellDef>IsPreNDA</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element.isPreNdaReason != null" [title]="element.isPreNdaReason">
                    Pre-NDA<mat-icon>info</mat-icon>
                  </span>
                  <span *ngIf="element.isPreNdaReason == null">Post NDA</span>
                </td>
              </ng-container>

              <!-- Last Name Column -->
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Investor Status</th>
                <td mat-cell *matCellDef="let element">
                  {{element?.investorWorkflowStatus}}
                </td>
              </ng-container>
              <!-- PRE NDA Column -->
              <ng-container matColumnDef="preNda">
                <th mat-header-cell *matHeaderCellDef> Pre-NDA Reason</th>
                <td mat-cell *matCellDef="let element">
                  <div style="display: flex;">
                    <span>{{element.isPreNda ? 'PRE-NDA' : 'POST-NDA'}}</span>&nbsp;
                    <mat-icon *ngIf="element?.isPreNda" color="primary" style="cursor: help; margin-top: -2px;"
                              [matTooltip]="element?.isPreNdaReason" matTooltipPosition="right">info
                    </mat-icon>
                  </div>
                </td>
              </ng-container>
              <!-- Title Column -->
              <ng-container matColumnDef="interest">
                <th mat-header-cell *matHeaderCellDef> Total Interest</th>
                <td mat-cell *matCellDef="let element">
                  {{'$' + element?.totalInterest + 'M USD'}}
                </td>
              </ng-container>
              <!-- EDIT Column -->
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Actions</th>
                <td mat-cell *matCellDef="let element">
                  <div style="display: flex;">
                    <button mat-icon-button
                            (click)="edit(element)"
                            [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_HIGHLIGHTS)"
                            [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_HIGHLIGHTS) ? util.NoAccessCursor: ''}"
                            [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_HIGHLIGHTS) ? util.NoAccessMsg : 'Edit Investor'"
                            matTooltipPosition="left"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button
                            (click)="viewBusinessUnit(element)"
                            [disabled]="!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_HIGHLIGHTS)"
                            [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_HIGHLIGHTS) ? util.NoAccessCursor: ''}"
                            [matTooltip]="!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_HIGHLIGHTS) ? util.NoAccessMsg : 'View Business Unit'"
                            matTooltipPosition="left"
                    >
                      <mat-icon>east</mat-icon>
                    </button>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions" style="width: 50px;">&nbsp;</th>
                <td mat-cell *matCellDef="let approval" style="width: 50px;">
                  <button mat-icon-button aria-label="expand row"
                          (click)="(expandedElement = expandedElement === approval ? null : approval); $event.stopPropagation()">
                    <mat-icon *ngIf="expandedElement !== approval">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="expandedElement === approval">keyboard_arrow_up</mat-icon>
                  </button>
                </td>
              </ng-container>

              <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                  <div class="example-element-detail"
                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="['table-name','search-header']"></tr>
              <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>
              <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                  class="example-element-row"
                  [class.example-expanded-row]="expandedElement === element">
                <!--              (click)="expandedElement = expandedElement === element ? null : element">-->
              </tr>
              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter.</td>
              </tr>
            </table>
          </div>
        </div>
        <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[5, 10, 25, 100]"
                        pageSize="100" aria-label="Select page of users"></mat-paginator>
      </div>
    </div>
  </div>
  <!--    list start-->
  <div class="row justify-content-center d-flex d-md-none" style="padding-bottom: 150px; padding-top: 15px;">
    <div class="col-12">
      <ul style="list-style-type: none; padding: 25px 0 0 0;">
        <li *ngFor="let element of dataSource.data"
            style="margin-bottom: 5px; display: flex; justify-content: space-between;">
          <app-user-card [firstName]="element?.businessUnit?.displayName"
                          [displayName]="element?.businessUnit?.profile"
                          [borderColor]="'transparent'"
                          [sideColor]="'#ff0000'"
                          [image]="element?.['imageUrl']"
                          [imageShape]="'square'"
          ></app-user-card>
          <div style="display: flex;">
            <button mat-icon-button
                    (click)="edit(element)"
                    [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_HIGHLIGHTS)"
                    [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_HIGHLIGHTS) ? util.NoAccessCursor: ''}"
                    [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_HIGHLIGHTS) ? util.NoAccessMsg : 'Edit Investor'"
                    matTooltipPosition="left"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button
                    (click)="viewBusinessUnit(element)"
                    [disabled]="!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_HIGHLIGHTS)"
                    [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_HIGHLIGHTS) ? util.NoAccessCursor: ''}"
                    [matTooltip]="!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_HIGHLIGHTS) ? util.NoAccessMsg : 'View Business Unit'"
                    matTooltipPosition="left"
            >
              <mat-icon>east</mat-icon>
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
