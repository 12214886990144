<div class="rm-header">
  <span>Sub Doc Review/Edit & Approve for Signature(s)</span>
</div>

<div class="pt-4 space-between">
    <span style="font-size: large;">
      SubDoc Fill
    </span>

    <button mat-raised-button type="button" color="primary"
          (click)="downloadSubdoc()"
    >
    Download SubDoc PDF
    </button>
</div>
<div class="row pt-3">
  <div class="col-12">
    <p>
      Your Subscription Document has been generated in ConcordNow (our partner document-signatories platform).
      <br>
      You can use the web form below to view the SubDoc and fill in the required information, or edit the information that has been pre-filled by our platform using your Approved KYC.
    </p>
  </div>
  <div class="col-12" style="display: flex; gap: 100px; overflow-x: hidden;">
    <mat-icon color="primary" *ngFor="let i of [1,2,3,4,5,6,7,8,9,10,11,12]">arrow_downward</mat-icon>
  </div>
</div>
<mat-dialog-content style="border-top: thick solid #ccc; border-bottom: thick solid #ccc">
  <!--  HTML TOP PADDING ON THIS DIV -->
  <form [formGroup]="fg" [innerHTML]="innerHtml">

    <!-- <div *ngFor="let n of allDomNodes" class="concord-node">
      <span  [ngSwitch]="n.type">
        <span *ngSwitchCase="'PARAGRAPH'">
          <mat-form-field>
            <input matInput type="text" formControlName="{{n.uuid}}" 
              [placeholder]="n.placeholder" [disabled]="n.locked" />
          </mat-form-field>
        </span>

        <span *ngSwitchCase="'TEXT'">
          <mat-form-field>
            <input matInput type="text" formControlName="{{n.uuid}}" 
              [placeholder]="n.placeholder" [disabled]="n.locked" />
          </mat-form-field>
        </span>

        <span *ngSwitchCase="'RADIO'">
          <mat-radio-group formControlName="{{n.uuid}}" [disabled]="n.locked" color="primary" 
            style="display: flex; flex-direction: column;">
            <mat-radio-button class="example-radio-button" *ngFor="let item of n.options" [value]="item.key">
              {{item.value}}
            </mat-radio-button>
          </mat-radio-group>
        </span>

        <span *ngSwitchCase="'CHECKBOX'" [formArrayName]="n.uuid">
          <div *ngFor="let chxbox of getCheckboxList(n.uuid).controls; let i = index" [formGroupName]="i">
            <input type="hidden" formControlName="id">
            <mat-checkbox formControlName="chkboxValue" color="primary"> {{ chxbox.value.title }} </mat-checkbox>
          </div>
        </span>

        <span *ngSwitchCase="'SIGNER_TITLE'">SIGNER_TITLE
          <span *ngFor="let val of n.values">{{val}}</span>
        </span>

        <span *ngSwitchCase="'SIGNER_FULL_NAME'">SIGNER_FULL_NAME
          <span *ngFor="let val of n.values">{{val}}</span>
        </span>

        <span *ngSwitchCase="'SIGNATURE'">SIGNATURE
          <span *ngFor="let val of n.values">{{val}}</span>
        </span>

        <span *ngSwitchDefault [innerHtml]="n.content"></span>
      </span>
    </div> -->
  </form>
</mat-dialog-content>

<mat-dialog-actions class="space-between">
  <button mat-raised-button type="button" [mat-dialog-close]="false">Close</button>
  <div style="display: flex; justify-items: end; justify-content: end; gap: 10px;">
    <button mat-raised-button type="button" color="primary" (click)="saveFields(false)">Save Draft</button>
    <button mat-raised-button type="button" color="primary" (click)="saveFields(true)">Save Document & Send to Investor for Signature(s)
    </button>
  </div>
</mat-dialog-actions>
