<div style="height:calc(100vh - 176px); overflow-y: auto;">
  <div class="container-fluid">
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-8 col-md-9">
        <span class="rm-title rm-title-3">Mandates</span><br/>
        <span
          class="rm-title rm-title-6 rm-lighter">These are all of the mandates for the selected business unit:</span>
      </div>
      <div class="col-4 col-md-2 text-end">
        <button mat-raised-button
                color="primary"
                style="height: auto;width: auto;"
                (click)="openMandateModal()"
                [disabled]="!util.hasPermission(util.Permission.CAN_ADD_MANDATES)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_MANDATES) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_ADD_MANDATES) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
        >
          <mat-icon>library_add</mat-icon>&nbsp;Add New Mandate
        </button>
      </div>
    </div>
    <!--    table start-->
    <div class="row justify-content-center d-none d-md-flex" style="padding-bottom: 150px; padding-top: 15px;">
      <div class="col-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <table mat-table [dataSource]="dataSource"
                     [multiTemplateDataRows]="true"
                     matSort
                     (matSortChange)="sortData($event)"
              >
                <!-- Search Header -->
                <ng-container matColumnDef="table-name">
                  <th colspan="3" mat-header-cell *matHeaderCellDef style="font-size: large;">Business Unit Mandates
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="4" mat-header-cell *matHeaderCellDef>
                    <form>
                      <mat-form-field style="width: 100%;">
                        <mat-label>Search</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input/>
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form>
                  </th>
                </ng-container>
                <!-- Employee Column -->
                <ng-container matColumnDef="employee">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="employee"> Created By</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    <!--        user menu button-->
                    <app-user-card [firstName]="element.createdBy?.['firstName'] "
                                   [lastName]="element.createdBy?.['lastName']"
                                   [displayName]="element.createdBy?.['email']"
                                   [borderColor]="'transparent'"
                                   [background]="'#F1EDE4'"
                                   [sideColor]="'#002150'"
                                   [image]="element.createdBy?.['imageUrl']"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef> TITLE</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ element['firstName'] }}
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="f-aum">
                  <th mat-header-cell *matHeaderCellDef> AUM</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ 'Firm: ' + element['firmAum'] + ', Program: ' + element['programAum'] }}
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="p-aum">
                  <th mat-header-cell *matHeaderCellDef> PROGRAM AUM</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ element['programAum'] }}
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="ticket">
                  <th mat-header-cell *matHeaderCellDef> Ticket Size</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ 'Min: ' + element['minTicketSize'] + ', Max: ' + element['maxTicketSize'] + ', Avg: ' + element['avgTicketSize'] }}
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="duration">
                  <th mat-header-cell *matHeaderCellDef> Duration</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ 'Min: ' + element['minimumDuration'] + ', Max: ' + element['maximumDuration'] }}
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="own">
                  <th mat-header-cell *matHeaderCellDef> OWNERSHIP TYPE</th>
                  <td mat-cell *matCellDef="let element"> {{ element["role"] }}
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef> DEAL TYPES</th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element.memberInviteStatus }} </span>
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="assets">
                  <th mat-header-cell *matHeaderCellDef> ASSET CLASSES</th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element.memberInviteStatus }} </span>
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="status"> Status</th>
                  <td mat-cell *matCellDef="let element">
                    <span [appStatus]="element.status">{{ element.status }} </span>
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="created">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="created"> Date Created</th>
                  <td mat-cell *matCellDef="let element">
                    <div class="date-stacked">
                      <span>{{ element.created | date : 'mediumDate' }}</span>
                      <span>{{ element.created | date : 'shortTime' }}</span>
                    </div>
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="edit">
                  <th mat-header-cell *matHeaderCellDef> View</th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="viewMandate(element)" matTooltip="View Mandate"
                            matTooltipPosition="left">
                      <mat-icon>east</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="['table-name','search-header']"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"></tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter.</td>
                </tr>
              </table>
            </div>
          </div>
          <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[5, 10, 25, 100]"
                         aria-label="Select page of users"></mat-paginator>
        </div>
      </div>
    </div>
    <!--    list start-->
    <div class="row justify-content-center d-flex d-md-none" style="padding-bottom: 150px; padding-top: 15px;">
      <div class="col-12">
        <ul style="list-style-type: none; padding: 25px 0 0 0;">
          <li *ngFor="let element of dataSource.data"
              style="margin-bottom: 5px; display: flex; justify-content: space-between;">
            <app-user-card [firstName]="element?.createdBy?.firstName"
                           [lastName]="element?.createdBy?.lastName"
                           [displayName]="element?.createdBy?.email"
                           [borderColor]="'transparent'"
                           [background]="'#F1EDE4'"
                           [sideColor]="'#002150'"
                           [image]="element?.createdBy?.imageUrl"
            ></app-user-card>
            <div style="display: flex;">
              <button mat-icon-button (click)="viewMandate(element)" matTooltip="View Mandate"
                      matTooltipPosition="left">
                <mat-icon>east</mat-icon>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
