<div class="rm-header">
  <div style="display: flex; justify-content: space-between;">
    <span>New Mandate</span>
    <button mat-icon-button [matTooltip]="'Discard changes and close'" matTooltipPosition="left"
            style="margin-top: -10px;"
            (click)="dialogRef.close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div class="modal-container">
  <form id="buForm" #buForm
        [formGroup]="userForm"
        (ngSubmit)="createMandate()">
    <mat-dialog-content>
      <!--Header-->
      <div class="row justify-content-center d-none d-md-flex">
        <div class="c col-12 text-center">
          <div class="box">
            <span id="timeline"></span>
          </div>
          <div class="box stack-top justify-content-center">
            <div class="col-2 text-center" *ngFor="let n of getTitles(); index as i;">
              <div [class]="isSelectedPage(i) ? 'numberCircleSelected' : 'numberCircle'">{{ i + 1 }}</div>
              <br/>
              <span [class]="isSelectedPage(i) ? 'titleSelected' : 'title'">{{ getTitle(i) }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal Page 0-->
      <div *ngIf="curPage===0" class="row justify-content-center modal-page">
        <div class="col-12 text-align-c text-center" style="padding-bottom: 20px;">
          <span style="font-size: xx-large;"><b>Welcome to Rondeivu!</b></span><br/><br/><span
          style="font-size: x-large;">Let's get started.</span>
        </div>
        <br/><br/>
        <div class="col-10 text-align-c text-center" style="padding-bottom: 25px;">
          <span>Spend a few minutes completing this on-boarding questionnaire, and we'll use these details to source only the deals you want to see.</span>
        </div>
        <div class="col-10">
          <div class="mat-typography" style="overflow: hidden;">
            <!--        Firm-->
            <div class="row">
              <div class="col-12 col-md-6">
                <mat-form-field class="form-field" floatLabel="always">
                  <mat-label>Firm AUM (Million USD)</mat-label>
                  <input type="number" matInput id="firm" placeholder="i.e. 5000" [required]="true"
                         formControlName="firmAum"/>
                  <mat-hint>This is your firm's total AUM</mat-hint>
                  <mat-error *ngIf="showErr">Please enter a number.</mat-error>
                </mat-form-field>
              </div>
              <!--        Program-->
              <div class="col-12 col-md-6">
                <mat-form-field class="form-field" floatLabel="always">
                  <mat-label>Program AUM (Million USD)</mat-label>
                  <input type="number" matInput id="program" placeholder="i.e. 2000" [required]="true"
                         formControlName="programAum"/>
                  <mat-hint>This is the size of your team's portfolio</mat-hint>
                  <mat-error *ngIf="showErr && !userForm.get('programAum')?.value">Please enter a number.</mat-error>
                </mat-form-field>
                <br>
                <mat-checkbox id="same-aum" name="same-aum" [(ngModel)]="sameFirmAum" color="primary"
                              [ngModelOptions]="{standalone: true}"
                              (click)="toggleProgramAUM()">Same as Firm AUM
                </mat-checkbox>

              </div>
            </div>
            <div class="d-flex d-md-none">
              &nbsp;
            </div>
            <!--        Mandate Type-->
            <div class="row">
              <div class="col-12 col-md-6">
                <mat-label>Which describes your firm best?</mat-label>
                <mat-error *ngIf="showErr && !userForm.get('investorMandateDescriptions')?.value"
                           style="font-size: small;">Please choose one.
                </mat-error>
                <mat-radio-group color="primary"
                                 class="radio-group" id="mt" [required]="true"
                                 formControlName="investorMandateDescriptions">
                  <mat-radio-button class="radio-button" *ngFor="let m of lookups.investorDescriptionTypes" [value]="m">
                    {{ m.value }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <br/>
          </div>
        </div>
      </div>
      <!-- Modal Page 1-->
      <div *ngIf="curPage===1" class="row justify-content-center modal-page">
        <div class="col-10 text-align-c text-center" style="padding-bottom: 25px;">
          <span>Select all of the deal types and asset classes that your team invests in.</span>
        </div>
        <br><br><br>
        <div class="col-12">
          <div class="mat-typography">
            <div class="row justify-content-center">
              <!--        Deal Type-->
              <div class="col-12 col-md-4">
                <div class="radio-group">
                  <mat-label>Deal Type<sup>*</sup></mat-label>
                  <div *ngFor="let dealType of lookups.dealTypes; index as i;">
                    <mat-checkbox [ngModelOptions]="{standalone: true}" color="primary"
                                  [id]="'dt'+ i" [name]="'dt'+i" [(ngModel)]="selectedDealIdx[i]"
                                  (change)="toggleDealType(dealType)">{{ dealType.value }}
                    </mat-checkbox>
                    <br/>
                  </div>
                  <mat-error *ngIf="showErr && (mandate.investorMandateDeals.length == 0)" style="font-size: small;">
                    Please select at least one deal
                    type.
                  </mat-error>
                </div>
              </div>
              <!--        Asset Class-->
              <div class="col-12 col-md-4">
                <div class="radio-group">
                  <mat-label>Asset Class<sup>*</sup></mat-label>
                  <div *ngFor="let asset of lookups.assetTypes;index as i;">
                    <mat-checkbox [ngModelOptions]="{standalone: true}" color="primary"
                                  [id]="'ac'+ i" [name]="'ac'+i" [(ngModel)]="selectedAssetIdx[i]"
                                  (change)="toggleAssetClass(asset)">{{ asset.value }}
                    </mat-checkbox>
                    <br/>
                  </div>
                  <mat-error *ngIf="showErr && (mandate.investorMandateAssets.length == 0)" style="font-size: small;">
                    Please select at least one asset class.
                  </mat-error>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal Page 2-->
      <div *ngIf="curPage===2" class="row justify-content-center modal-page">
        <div class="col-10 text-align-c text-center" style="padding-bottom: 25px;">
          <!-- <span>.</span> -->
        </div>
        <div class="col-10 text-align-c text-center" style="padding-bottom: 25px;">
          <span>In the second field, type or select 5-10 themes or keywords of deals you are interested in to further refine the deal flow you will receive.</span>
        </div>
        <div class="col-10">
          <div class="mat-typography">
            <!--        Tell Us-->
            <div class="row">
              <div class="col-12">
                <mat-form-field class="form-field" floatLabel="always">
                  <mat-label>Now tell us some of the themes and/or areas of focus for your portfolio in the next 12
                    months
                  </mat-label>
                  <textarea matInput id="tell" formControlName="notes"></textarea>
                </mat-form-field>
              </div>
            </div>
            <!--        Themes-->
            <div class="row">
              <div class="col-12">
                <mat-form-field class="chip-list" floatLabel="always">
                  <mat-label>Keywords</mat-label>
                  <mat-chip-list #chipList aria-label="Theme selection">
                    <mat-chip
                      *ngFor="let st of selectedThemes"
                      (removed)="removeTheme(st)">
                      {{ st }}
                      <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip>
                    <input
                      [placeholder]="selectedThemes.length==0? 'i.e. sustainable, litigationfinance, cashflowpositive': ''"
                      #themeInput
                      [formControl]="themeCtrl"
                      [matAutocomplete]="auto"
                      [matChipInputFor]="chipList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      (matChipInputTokenEnd)="addTheme($event)"/>
                  </mat-chip-list>
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTheme($event)">
                    <mat-option *ngFor="let ft of filteredThemes | async" [value]="ft">
                      {{ ft }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
            <br><br>
          </div>
        </div>
      </div>
      <!-- Modal Page 3-->
      <div *ngIf="curPage===3" class="row justify-content-center modal-page">
        <div class="col-10 text-align-c text-center" style="padding-bottom: 25px;">
          <span>We want to help you achieve your portfolio objectives. Input your return targets and liquidity constraints, we'll vet the deals to ensure you're only seeing ones that can realistically achieve your goals.</span>
        </div>
        <div class="col-10">
          <div class="mat-typography">
            <!--          row start-->
            <div class="row">
              <div class="col-12">
                <span style="font-size: large">MOIC</span>
              </div>
            </div>
            <!--     row start-->
            <div class="row">
              <!--        Target Net MOIC-->
              <div class="col-12 col-md-6">
                <mat-form-field class="form-field" floatLabel="always">
                  <mat-label>Target Net MOIC (x multiple)</mat-label>
                  <input type="number" matInput id="tnmoic" placeholder="i.e. 2.5" [required]="true"
                         formControlName="targetNetMoic"/>
                  <mat-error *ngIf="userForm.invalid">Please enter a number.</mat-error>
                </mat-form-field>
              </div>
              <!--        Target Net IRR (% percent)-->
              <div class="col-12 col-md-6">
                <mat-form-field class="form-field" floatLabel="always">
                  <mat-label>Target Net IRR (% percent)</mat-label>
                  <input type="number" matInput id="tniir" placeholder="i.e. 15" [required]="true"
                         formControlName="targetNetIrr"/>
                  <mat-error *ngIf="userForm.invalid">Please enter a number.</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row" style="padding-top: 25px;">
              <div class="col-12">
                <span style="font-size: large">Duration</span>
              </div>
            </div>
            <!--row start-->
            <div class="row">
              <!--        Minimum Duration (years)-->
              <div class="col-12 col-md-6">
                <mat-form-field class="form-field" floatLabel="always">
                  <mat-label>Minimum Duration (years)</mat-label>
                  <input type="number" matInput id="minD" placeholder="i.e. 2" [required]="true"
                         formControlName="minimumDuration"/>
                  <mat-error *ngIf="userForm.invalid">Please enter a number.</mat-error>
                </mat-form-field>
              </div>
              <!--        Maximum Duration (years)-->
              <div class="col-12 col-md-6">
                <mat-form-field class="form-field" floatLabel="always">
                  <mat-label>Maximum Duration (years)</mat-label>
                  <input type="number" matInput id="maxD" placeholder="i.e. 10" [required]="true"
                         formControlName="maximumDuration"/>
                  <mat-error *ngIf="userForm.invalid">Please enter a number.</mat-error>
                </mat-form-field>
              </div>
            </div>
            <br><br>
          </div>
        </div>
      </div>
      <!-- Modal Page 4-->
      <div *ngIf="curPage===4" class="row justify-content-center modal-page">
        <div class="col-10 text-align-c text-center" style="padding-bottom: 25px;">
          <span><b>Final step</b> - let's make sure we're not sending you deals that aren't in your ticket size range, or that have a timeline from origination to closing that is shorter than your due diligence timeline.</span>
        </div>
        <div class="col-10">
          <div class="mat-typography">
            <!--   row start-->
            <div class="row">
              <mat-label>Ticket Size (Million USD)</mat-label>
              <!--        Minimum Ticket Size-->
              <div class="col-12 col-md-4">
                <mat-form-field class="form-field" floatLabel="always">
                  <mat-label>Minimum Ticket Size</mat-label>
                  <input type="number" matInput id="mints" formControlName="minTicketSize"
                         placeholder="i.e. 5" [required]="true"/>
                  <mat-error *ngIf="userForm.invalid">Please enter a number.</mat-error>
                </mat-form-field>
              </div>
              <!--        Maximum Ticket Size-->
              <div class="col-12 col-md-4">
                <mat-form-field class="form-field" floatLabel="always">
                  <mat-label>Maximum Ticket Size</mat-label>
                  <input type="number" matInput id="maxts" placeholder="i.e. 15" [required]="true"
                         formControlName="maxTicketSize"/>
                  <mat-error *ngIf="userForm.invalid">Please enter a number.</mat-error>
                </mat-form-field>
              </div>
              <!--        Average Ticket Size-->
              <div class="col-12 col-md-4">
                <mat-form-field class="form-field" floatLabel="always">
                  <mat-label>Average Ticket Size</mat-label>
                  <input type="number" matInput id="avgts" placeholder="i.e. 10" required
                         formControlName="avgTicketSize"/>
                  <mat-error *ngIf="userForm.invalid">Please enter a number.</mat-error>
                </mat-form-field>
              </div>
            </div>
            <!--        Minimum Timeline to Close-->
            <div class="row">
              <div class="col-12 col-md-6">
                <mat-label>Minimum Timeline to Close</mat-label>
                <mat-error *ngIf="userForm.invalid && userForm.get('investorMandateTimeLines')?.touched"
                           style="font-size: small;">Please choose one.
                </mat-error>
                <mat-radio-group
                  class="radio-group" id="mttc" [required]="true" formControlName="investorMandateTimeLines"
                  color="primary">
                  <mat-radio-button class="radio-button" *ngFor="let mttc of lookups.timeLineTypes" [value]="mttc">
                    {{ mttc.value }}
                  </mat-radio-button>
                  <br>
                </mat-radio-group>
              </div>
              <!--  Anchor or lead -->
              <div class="col-12 col-md-6">
                <div class="radio-group">
                  <mat-label>Are you interested in being any of the following?</mat-label>
                  <div *ngFor="let anchor of lookups.anchorInterestTypes; index as i;">
                    <mat-checkbox [ngModelOptions]="{standalone: true}" color="primary"
                                  [id]="'anchor'+ i" [name]="'anchor'+i" [(ngModel)]="selectedAnchorInterestIdx[i]"
                                  (change)="toggleAnchorInterest(anchor)">{{ anchor.value }}
                    </mat-checkbox>
                    <br/>
                  </div>
                </div>
              </div>
            </div>
            <br><br>
          </div>
        </div>
      </div>
    </mat-dialog-content>


    <!--Navigation-->
    <mat-dialog-actions class="row justify-content-center">
      <div class="col-6 text-start">
        <button mat-raised-button type="button" *ngIf="!firstPage()" (click)="back()">
          Back
        </button>
      </div>
      <div class="col-6 text-end">
        <button mat-raised-button color="primary" type="button" *ngIf="!lastPage()" (click)="next()">
          Next
        </button>
        <div (mouseenter)="isStepValid()">
          <button mat-raised-button color="primary" type="submit" *ngIf="lastPage()"
                  [disabled]="userForm.invalid">
            Submit for Review
          </button>
        </div>
      </div>
    </mat-dialog-actions>
  </form>
</div>




