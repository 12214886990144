<div class="rm-header">
  <div style="display: flex; justify-content: space-between;">
    <span>New Deal</span>
    <button mat-icon-button [matTooltip]="'Discard changes and close'" matTooltipPosition="left"
            style="margin-top: -10px;"
            (click)="dialogRef.close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div class="modal-container">
  <form id="dealForm" #dealForm [formGroup]="userForm" (ngSubmit)="submit()">
    <!--  Modal Content-->
    <mat-dialog-content>
      <!--Header-->
      <div class="row justify-content-center d-none d-md-block">
        <div class="c col-12 text-center">
          <div class="box">
            <span id="timeline"></span>
          </div>
          <div class="box stack-top justify-content-center">
            <div class="col-2 text-center" *ngFor="let n of getTitles(); index as i;">
              <div [class]="isSelectedPage(i) ? 'numberCircleSelected' : 'numberCircle'">{{ i + 1 }}</div>
              <br/>
              <span [class]="isSelectedPage(i) ? 'titleSelected' : 'title'">{{ getTitle(i) }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal Page 0-->
      <div *ngIf="curPage===0" class="row justify-content-center modal-page">
        <div class="col-12 text-align-c text-center" style="padding-bottom: 20px;">
          <span style="font-size: xx-large;"><b>Welcome to Rondeivu!</b></span><br/><br/><span
          style="font-size: x-large;">Let's get started.</span>
        </div>
        <br/><br/>
        <div class="col-12 col-md-10 text-align-c text-center" style="padding-bottom: 50px;">
          <span>Spend a few minutes completing this on-boarding questionnaire, and we'll get your deal in front of institutional investors who have expressed an interest in your deal types.</span>
        </div>
        <div class="col-12 col-md-10">
          <div class="mat-typography">
            <!--        Country-->
            <div class="row">
              <div class="col-12">
                <mat-form-field class="form-field">
                  <mat-label>Entity Name</mat-label>
                  <input matInput id="ename" [required]="true" formControlName="issuerEntityName"/>
                  <mat-error *ngIf="userForm.invalid">Please add an entity name for the deal.</mat-error>
                </mat-form-field>
              </div>
            </div>
            <!--        Country-->
            <div class="row">
              <div class="col-12">
                <mat-form-field class="form-field">
                  <mat-label>Country</mat-label>
                  <mat-select id="country" [required]="true" formControlName="countryId">
                    <mat-option *ngFor="let c of lookups.countries" [value]="c.key">
                      {{ c.value }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="userForm.invalid">Please choose one.</mat-error>
                </mat-form-field>
              </div>
            </div>
            <!--        State-->
            <div class="row">
              <div class="col-12">
                <mat-form-field class="form-field"
                                *ngIf="userForm.get('countryId')?.value == 'US'">
                  <mat-label>State</mat-label>
                  <mat-select id="state"
                              [required]="userForm.get('countryId')?.value == 'US'"
                              formControlName="stateorProvinceId">
                    <!--                <mat-option>Placeholder</mat-option>-->
                    <mat-option *ngFor="let s of lookups.stateTypes" [value]="s.key">
                      {{ s.value }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="userForm.invalid">Please choose one.</mat-error>
                </mat-form-field>
                <mat-form-field class="form-field" *ngIf="userForm.get('countryId')?.value == 'CA'">
                  <mat-label>Province</mat-label>
                  <mat-select id="province"
                              [required]="userForm.get('countryId')?.value == 'CA'"
                              formControlName="stateorProvinceId">
                    <!--                <mat-option>Placeholder</mat-option>-->
                    <mat-option *ngFor="let p of lookups.provinceTypes" [value]="p.key">
                      {{ p.value }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="userForm.invalid">Please choose one.</mat-error>
                </mat-form-field>
              </div>
            </div>
            <!--        City-->
            <div class="row" style="padding-bottom: 50px;">
              <div class="col-12">
                <mat-form-field class="form-field">
                  <mat-label>City</mat-label>
                  <input matInput id="city" [required]="true" formControlName="city"/>
                  <mat-error *ngIf="userForm.invalid">Please add a city.</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal Page 1-->
      <div *ngIf="curPage===1" class="row justify-content-center modal-page">
        <div class="col-12 col-md-10 text-align-c text-center" style="padding-bottom: 25px;">
          <span>Great! Tell us a bit more about your deal. Select the deal type and asset class, and then using the keywords box, input the 5-10 key characteristics of the deal, or select from the drop down list.</span>
        </div>
        <br/><br/><br>
        <div class="col-12">
          <div class="mat-typography">
            <!--        Deal Type-->
            <div class="row justify-content-center">
              <div class="col-12 col-md-5">
                <div class="radio-group">
                  <mat-label>Deal Type<sup>*</sup></mat-label>
                  <mat-checkbox [id]="'dt'+ i" [name]="'dt'+i" [(ngModel)]="selectedDealIdx[i]"
                                [ngModelOptions]="{standalone: true}"
                                *ngFor="let dealType of lookups.dealTypes; index as i;"
                                (change)="toggleDealType(dealType)" color="primary">{{ dealType.value }}
                  </mat-checkbox>
                  <mat-error *ngIf="showErr && (dealRequest.issuerDeals.length == 0)" style="font-size: small;">Please
                    select at least one deal type.
                  </mat-error>
                </div>
              </div>
              <!--        Asset Class-->
              <div class="col-12 col-md-5">
                <div class="radio-group">
                  <mat-label>Asset Class<sup>*</sup></mat-label>
                  <mat-checkbox [id]="'ac'+ i" [name]="'ac'+i" [(ngModel)]="selectedAssetIdx[i]"
                                [ngModelOptions]="{standalone: true}"
                                *ngFor="let asset of lookups.assetTypes;index as i;"
                                (change)="toggleAssetClass(asset)" color="primary">{{ asset.value }}
                  </mat-checkbox>
                  <mat-error *ngIf="showErr && (dealRequest.issuerDealAssets.length == 0)" style="font-size: small;">
                    Please select at least one asset class.
                  </mat-error>
                </div>
              </div>
            </div>
            <!--        Themes-->
            <div class="row justify-content-center">
              <div class="col-12 col-md-10">
                <mat-form-field class="chip-list">
                  <mat-label>Keywords</mat-label>
                  <mat-chip-list #chipList aria-label="Theme selection">
                    <mat-chip
                      *ngFor="let st of selectedThemes"
                      (removed)="removeTheme(st)">
                      {{ st }}
                      <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip>
                    <input
                      [placeholder]="selectedThemes.length==0?'i.e. sustainable, litigationfinance, cashflowpositive':''"
                      #themeInput
                      [formControl]="themeCtrl"
                      [matAutocomplete]="auto"
                      [matChipInputFor]="chipList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      (matChipInputTokenEnd)="addTheme($event)"/>
                  </mat-chip-list>
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTheme($event)">
                    <mat-option *ngFor="let ft of filteredThemes | async" [value]="ft">
                      {{ ft }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
            <br><br>
          </div>
        </div>
      </div>
      <!-- Modal Page 2-->
      <div *ngIf="curPage===2" class="row justify-content-center modal-page">
        <div class="col-12 col-md-10 text-align-c text-center" style="padding-bottom: 25px;">
          <span>Alright, how much are you looking to raise, and when does the fundraise need to close?</span>
        </div>
        <div class="col-12 col-md-10">
          <div class="mat-typography">
            <!--        Currency Denomination-->
            <div class="row" style="padding-bottom: 25px;" hidden>
              <div class="col-12">
                <mat-form-field class="form-field">
                  <mat-label>Currency Denomination</mat-label>
                  <br><span>Use the Dropdown to select the currency you use.</span>
                  <mat-select id="cd" [required]="true" formControlName="currencyType">
                    <mat-option *ngFor="let cur of lookups.currencyTypes" [value]="cur.key">
                      {{ cur.value }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="userForm.invalid">Please choose one.</mat-error>
                </mat-form-field>
              </div>
            </div>
            <!-- Total Transaction Size-->
            <div class="row" style="padding-bottom: 20px;">
              <div class="col-12 col-md-6">
                <mat-form-field class="form-field" floatLabel="always">
                  <mat-label>Total Transaction Size (Million USD)</mat-label>
                  <input type="number" matInput id="totSz" placeholder="i.e. 100" [required]="true"
                         formControlName="fundraiseSize"/>
                  <mat-error *ngIf="userForm.invalid">Please enter a number.</mat-error>
                </mat-form-field>
              </div>
              <!--        Capacity to raise-->
              <div class="col-12 col-md-6">
                <mat-form-field class="form-field" floatLabel="always">
                  <mat-label>Capacity to Raise on Rondeivu (Million USD)</mat-label>
                  <input type="number" matInput id="cr" placeholder="i.e. 75" [required]="true"
                         formControlName="rondeivuFundraiseSize"/>
                  <mat-error *ngIf="userForm.invalid">Please enter a number.</mat-error>
                </mat-form-field>
              </div>
            </div>
            <!--        Timeline to Final Close-->
            <div class="row">
              <div class="col-12 col-md-4">
                <mat-label>Timeline to Final Close</mat-label>
                <mat-error
                  *ngIf="userForm.get('timeLineTypeId')?.touched  && userForm.get('timeLineTypeId')?.value == null"
                  style="font-size: small;">Please choose one.
                </mat-error>
                <mat-radio-group color="primary"
                                 class="radio-group" id="ttfc" [required]="true" formControlName="timeLineTypeId">
                  <mat-radio-button class="radio-button" *ngFor="let ttfc of lookups.timeLineTypes" [value]="ttfc.key">
                    {{ ttfc.value }}
                  </mat-radio-button>
                  <br>
                </mat-radio-group>
              </div>
              <!--        Has First Deal Closed-->
              <div class="col-12 col-md-4">
                <mat-label>Has first close occurred?</mat-label>
                <mat-error
                  *ngIf="userForm.get('firstCloseOccured')?.touched  && userForm.get('firstCloseOccured')?.value == null"
                  style="font-size: small;">Please choose one.
                </mat-error>
                <mat-radio-group color="primary"
                                 class="radio-group" id="hfdc" [required]="true" formControlName="firstCloseOccured">
                  <mat-radio-button class="radio-button" [value]="true">Yes</mat-radio-button>
                  <mat-radio-button class="radio-button" [value]="false">No</mat-radio-button>
                </mat-radio-group>
              </div>
              <!--  Anchor or lead -->
              <div class="col-12 col-md-4">
                <mat-label>Are you seeking an anchor/lead?</mat-label>
                <mat-error
                  *ngIf="userForm.get('anchorSeeking')?.touched  && userForm.get('anchorSeeking')?.value == null"
                  style="font-size: small;">Please choose one.
                </mat-error>
                <mat-radio-group color="primary"
                                 class="radio-group" id="seek" [required]="true" formControlName="anchorSeeking">
                  <mat-radio-button class="radio-button" [value]="true">Yes</mat-radio-button>
                  <mat-radio-button class="radio-button" [value]="false">No</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <br/><br/>
          </div>
        </div>
      </div>
      <!-- Modal Page 3-->
      <div *ngIf="curPage===3" class="row justify-content-center modal-page">
        <div class="col-12 col-md-10 text-align-c text-center" style="padding-bottom: 25px;">
          <span>This information is only shared with Rondeivu for the time being, and not investors.  Please provide best estimates of expected returns and investment duration.</span>
        </div>
        <div class="col-12 col-md-10">
          <div class="mat-typography">
            Target Return (Net MOIC)
            <div class="row" style="padding-bottom: 20px;">
              <!--        Bear Case-->
              <div class="col-12 col-md-4">
                <mat-form-field class="form-field" floatLabel="always">
                  <mat-label>Bear Case</mat-label>
                  <input type="number" matInput id="bearc" placeholder="i.e. 2" [required]="true"
                         formControlName="targetMoicBear"/>
                  <mat-error *ngIf="userForm.invalid">Please enter a number.</mat-error>
                </mat-form-field>
              </div>
              <!--        Base Case-->
              <div class="col-12 col-md-4">
                <mat-form-field class="form-field" floatLabel="always">
                  <mat-label>Base Case</mat-label>
                  <input type="number" matInput id="basec" placeholder="i.e. 2.5" [required]="true"
                         formControlName="targetMoicBase"/>
                  <mat-error *ngIf="userForm.invalid">Please enter a number.</mat-error>
                </mat-form-field>
              </div>
              <!--        Best Case-->
              <div class="col-12 col-md-4">
                <mat-form-field class="form-field" floatLabel="always">
                  <mat-label>Best Case</mat-label>
                  <input type="number" matInput id="bestc" placeholder="i.e. 3" [required]="true"
                         formControlName="targetMoicBest">
                  <mat-error *ngIf="userForm.invalid">Please enter a number.</mat-error>
                </mat-form-field>
              </div>
            </div>
            Investment Timeline (Years)
            <div class="row">
              <!--          min duration-->
              <div class="col-12 col-md-4">
                <mat-form-field class="form-field" floatLabel="always">
                  <mat-label>Minimum Duration</mat-label>
                  <input type="number" matInput id="mind" placeholder="i.e. 2" [required]="true"
                         formControlName="minimumDuration">
                  <mat-error *ngIf="userForm.invalid">Please enter a number.</mat-error>
                </mat-form-field>
              </div>
              <!--            expected duration-->
              <div class="col-12 col-md-4">
                <mat-form-field class="form-field" floatLabel="always">
                  <mat-label>Expected Duration</mat-label>
                  <input type="number" matInput id="expd" placeholder="i.e. 5" required
                         formControlName="expectedDuration">
                  <mat-error *ngIf="userForm.invalid">Please enter a number.</mat-error>
                </mat-form-field>
              </div>
              <!--            max duration-->
              <div class="col-12 col-md-4">
                <mat-form-field class="form-field" floatLabel="always">
                  <mat-label>Maximum Duration</mat-label>
                  <input type="number" matInput id="maxD" placeholder="i.e. 10" required
                         formControlName="maximumDuration">
                  <mat-error *ngIf="userForm.invalid">Please enter a number.</mat-error>
                </mat-form-field>
              </div>
            </div>
            <br/><br/>
          </div>
        </div>
      </div>
      <!-- Modal Page 4-->
      <div *ngIf="curPage===4" class="row justify-content-center modal-page">
        <div class="col-12 col-md-10 text-align-c text-center" style="padding-bottom: 25px;">
          <span>Lastly, provide summary details on the deal/asset and any additional pertinent information on the investment that will help Rondeivu successfully complete your fundraise.</span>
        </div>
        <div class="col-12 col-md-10">
          <div class="mat-typography">
            <!--        Notes-->
            <div class="row">
              <div class="col-12">
                <mat-form-field class="form-field" floatLabel="always">
                  <mat-label>Private Notes</mat-label>
                  <textarea matInput id="mints" formControlName="notes" placeholder="Type here..."></textarea>
                </mat-form-field>
              </div>
            </div>
            <br><br>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <!--Modal Navigation-->
    <mat-dialog-actions class="row justify-content-center">
      <div class="col-6 text-start">
        <button mat-raised-button type="button" *ngIf="!firstPage()" (click)="back()">
          Back
        </button>
      </div>
      <div class="col-6 text-end">
        <button mat-raised-button color="primary" type="button" *ngIf="!lastPage()" (click)="next()">
          Next
        </button>
        <div>
          <button mat-raised-button color="primary" type="submit" *ngIf="lastPage()">
            Submit for Review
          </button>
        </div>
      </div>
    </mat-dialog-actions>
  </form>
</div>
