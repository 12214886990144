<div class="rm-header">
  <span>Initiate Entity KYC</span>
</div>
<form [formGroup]="userForm" (ngSubmit)="submit()">
  <mat-dialog-content>
    <!--      row-->
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-11">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Type</mat-label>
          <mat-select formControlName="entityType" [required]="true">
            <mat-option *ngFor="let r of lookups.businessUnitEntityType" [value]="r.key">
              {{ r.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!--      row-->
    <div class="row justify-content-center" style="padding-bottom: 25px;">
      <div class="col-11">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Entity Name</mat-label>
          <input id="ename" matInput type="text" placeholder="Type Here..." formControlName="entityName"
                 [required]="true"/>
        </mat-form-field>
      </div>
    </div>
    <!--      row-->
  </mat-dialog-content>
  <!--      row-->
  <mat-dialog-actions class="row justify-content-center">
    <div class="col-6 text-start">
      <button mat-raised-button type="button" (click)="dialogRef.close()">Cancel</button>
    </div>
    <div class="col-6 text-end">
      <button mat-raised-button color="primary" type="submit">SUBMIT</button>
    </div>
  </mat-dialog-actions>
</form>
