<div style="overflow-y: auto; height: calc(100vh - 112px);">
  <div class="container-fluid">
    <div style="overflow-x: hidden;">
      <div class="row justify-content-center pt-4">
        <div class="col-12 col-md-11">
          <span class="rm-title rm-title-3">User Settings</span><br/><br/>
          <span class="rm-title rm-title-6">
            Update user settings, 2 Factor Authentication (2FA), and reset password from this page
          </span>
        </div>
      </div>
      <div class="row justify-content-center pt-3">
        <div class="col-12 col-md-11">
          <div class="row">
            <app-user-card [firstName]="userForm.get('firstName')?.value || undefined"
                           [lastName]="userForm.get('lastName')?.value || undefined"
                           [displayName]="userForm.get('email')?.value || undefined"
                           [borderColor]="'transparent'"
                           [background]="'#F1EDE4'"
                           [sideColor]="'#002150'"
                           [size]="userCardSize.LG"
                           [image]="userForm.get('imageUrl')?.value || undefined"
            ></app-user-card>
          </div>
          <br/>
          <div class="row justify-content-center">
            <div class="col-12 text-start">
              <button type="button" color="primary" mat-raised-button (click)="openImageUploadModal()">Upload User Profile Image</button>&nbsp;
              <button type="button" color="warn" mat-raised-button (click)="clearImage()">Clear User Image</button>
            </div>
          </div>
        </div>
      </div>
      <!--    WELL START-->
      <div class="row justify-content-center pt-5">
        <div class="col-12 col-md-11">
          <form [formGroup]="userForm"
                (ngSubmit)="save()">
            <div class="row">
              <span style="font-size: large; font-weight: bold;">Account Data</span>
            </div>
            <br/>
            <div class="row">
              <div class="col-12 col-md-4">
                <mat-form-field class="form-field" appearance="outline">
                  <mat-label>First Name</mat-label>
                  <input class="form-input" matInput formControlName="firstName" placeholder="i.e. John"/>
                </mat-form-field>
              </div>
              <div class="col-12 col-md-4">
                <mat-form-field class="form-field" appearance="outline">
                  <mat-label>Last Name</mat-label>
                  <input class="form-input" matInput formControlName="lastName" placeholder="i.e. Smith"/>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-8">
                <mat-form-field class="form-field" appearance="outline">
                  <mat-label>Email</mat-label>
                  <input class="form-input" matInput formControlName="email" placeholder="i.e. example@rondeivu.com"/>
                  <mat-hint align="end">Please contact Rondeivu at info&#64;rondeivu.com to change your email address.</mat-hint>
                </mat-form-field>
              </div>
            </div>
            <!--          row start-->
            <div class="row" style="padding-top: 20px;">
              <div class="col-12 text-end">
                <button type="submit" mat-raised-button color="primary">Save Changes</button>
              </div>
            </div>
            <!--          rows end-->
          </form>
        </div>
      </div>
      <br/><br/>
      <!--    WELL START-->
      <div class="row justify-content-center">
        <div class="col-12 col-md-11">
          <hr/>
          <br/>
          <form [formGroup]="phoneForm"
                (ngSubmit)="savePhone()">
            <div class="row">
              <span style="font-size: large; font-weight: bold;">Phone & 2FA Verification</span>
            </div>
            <br/>
            <div class="row">

              <div class="col-12 col-md-4">
                <mat-label style="font-size: medium;">Country Code</mat-label>
                <mat-form-field class="form-field" appearance="outline">
                  <mat-select id="cc" formControlName="countryCode">
                    <mat-option *ngFor="let cc of lookups.countryCodes" [value]="cc.key">
                      {{ cc.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-12 col-md-8">
                <mat-label style="font-size: medium;">Phone Number</mat-label>
                <mat-form-field class="form-field" appearance="outline">
                  <input class="form-input" matInput formControlName="mobile" placeholder="555-555-5555"/>
                </mat-form-field>
              </div>

              <div class="col-12">
                <div class="row" *ngIf="!userForm.get('phoneNumberVerified')?.value">
                  <div class="col-12 text-center">
                    <div class="notify">
                      <mat-icon id="notify-icon">warning</mat-icon>
                      <span style="line-height: 24px;height:24px;">Your phone number requires verification</span>&nbsp;
                      <!--                      <button mat-raised-button class="notify-button" type="button" (click)="openPhoneVerifyModal()">-->
                      <!--                        Verify-->
                      <!--                        Now-->
                      <!--                      </button>-->
                    </div>
                  </div>
                </div>

                <div class="col-12 text-center" *ngIf="userForm.get('phoneNumberVerified')?.value">
                  <div class="notify-good ">
                    <span style="font-size: 0.88rem;">Your phone number is verified</span>&nbsp;
                    <mat-icon id="notify-good-icon">done</mat-icon>
                  </div>
                </div>
              </div>
            </div>

            <!--          row start-->
            <div class="row pt-4">
              <div class="col-12 text-end">
                <button type="submit" mat-raised-button color="primary">Save Phone Number & Enable 2 Factor Authentication</button>
              </div>
            </div>
            <!--          rows end-->
          </form>
        </div>
      </div>
      <br/><br/>
      <!--WELL START-->
      <div class="row justify-content-center pb-5">
        <div class="col-12 col-md-11">
          <hr/>
          <form [formGroup]="adminForm" class="pt-4"
                (ngSubmit)="changePassword()">
            <!-- This hidden input prevents the autofill of the password fields. -->
            <input autocomplete="false" name="hidden" type="text" hidden>
            <div class="row">
              <span style="font-size: large; font-weight: bold;">Account Password</span>
            </div>
            <br/>
            <!--          row start-->
            <div class="row">
              <div class="col-12 col-md-6">
                <mat-form-field class="form-field" appearance="outline">
                  <mat-label>Old Password</mat-label>
                  <input class="form-input" matInput formControlName="oldPassword" autocomplete="new-password"
                         aria-autocomplete="none" type="password"/>
                  <mat-error *ngIf="adminForm.get('oldPassword')?.invalid && adminForm.get('oldPassword')?.dirty">
                    Password must be a combination of lower-case,
                    upper-case, numbers, special characters, and at
                    least 8 characters long.
                  </mat-error>
                  <mat-hint align="end">This is your old password</mat-hint>
                </mat-form-field>
              </div>
              <div class="col-12 col-md-6">
                <mat-form-field class="form-field" appearance="outline">
                  <mat-label>New Password</mat-label>
                  <input class="form-input" matInput formControlName="newPassword" autocomplete="new-password"
                         aria-autocomplete="none" type="password"/>
                  <mat-error *ngIf="adminForm.get('newPassword')?.invalid && adminForm.get('newPassword')?.dirty">
                    Password must be a combination of lower-case,
                    upper-case, numbers, special characters, and at
                    least 8 characters long.
                  </mat-error>
                  <mat-hint align="end">This is your NEW password</mat-hint>
                </mat-form-field>
              </div>
            </div>
            <div class="row pt-4">
              <div class="col-12 text-end">
                <button type="submit" mat-raised-button color="primary"
                        [disabled]="adminForm.invalid">
                  Update Password
                </button>
              </div>
            </div>
            <!--          rows end-->
          </form>
        </div>
      </div>
      <!--      WELL END-->
    </div>
  </div>
</div>

