<div class="container-fluid" style="height: calc(100vh - 64px); overflow-y: auto;">
  <br/> <br/>
  <div class="row justify-content-center" style="padding-bottom: 25px;">
    <div class="col-11">
      <div style="display:flex; padding-bottom: 5px;">
        <div>
          <mat-icon color="primary">phone</mat-icon>
        </div>&nbsp;
        <span class="rm-title rm-title-3">Contact Us</span>
      </div>
      <br/>
      <div style="margin-left: 25px;">
        <span>Thank you for choosing <b>rondeivu</b>!<br/> Please feel free to contact or book a meeting with your personal representative below:</span>
      </div>
    </div>
  </div>
  <div class="row justify-content-center" style="padding: 0 50px;">
    <div style="border-radius: 12px; border: 3px solid rgba(0,0,0,0.2); padding: 50px;">
      <div class="col-12" style="padding-bottom: 25px;">
        <span style="font-size: large; font-weight: bold;"><u>Your Representative:</u></span>
      </div>
      <div class="col-12" *ngIf="!!contact?.user?.firstName || !!contact?.user?.lastName">
        <div style="margin-top: 5px; display: inline-flex;">
          <mat-icon color="primary">person</mat-icon>
          <span
            style="margin-top: 4px; margin-left: 5px;">{{ contact?.user?.firstName + ' ' + contact?.user?.lastName }}</span>
        </div>
      </div>
      <div class="col-12" *ngIf="!!contact?.user?.countryCode && contact?.user?.mobile">
        <div style="margin-top: 5px; display: inline-flex;">
          <mat-icon color="accent">phone</mat-icon>
          <span
            style="margin-top: 4px; margin-left: 5px;">{{ contact?.user?.countryCode + ' ' + contact?.user?.mobile }}</span>
        </div>
      </div>
      <div class="col-12" *ngIf="!!contact?.user?.email">
        <div style="margin-top: 5px; display: inline-flex;">
          <mat-icon color="accent">email</mat-icon>
          <span style="margin-top: 4px; margin-left: 5px;">{{ contact?.user?.email }}</span><br/>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center" *ngIf="link != null">
    <div class="col-11">
      <iframe [src]="link" width="100%" height="800">
      </iframe>
    </div>
  </div>
</div>

<!--{{contact| json}}-->
