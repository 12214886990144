<div style="overflow-y: auto; height: calc(100vh - 178px);">
  <div class="container-fluid">
    <div style="overflow-x: hidden;">
      <div class="row justify-content-center" style="padding-top: 25px;">
        <div class="col-12 col-md-6">
          <span class="rm-title rm-title-3">User Information</span><br/>
          <span class="rm-title rm-title-6 rm-lighter">This is where you can edit a users settings</span>
        </div>
        <div class="col-12 col-md-5">
          <button mat-raised-button
                  color="primary"
                  style="margin-bottom: 5px;"
                  (click)="userPasswordReset()"
                  [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_USERS)"
                  [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_USERS) ? util.NoAccessCursor: ''}"
                  [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_USERS) ? util.NoAccessMsg : ''"
                  matTooltipPosition="left"
          >Reset User's Password
          </button>&nbsp;
          <button mat-raised-button
                  color="warn"
                  (click)="forcePasswordReset()"
                  [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_USERS)"
                  [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_USERS) ? util.NoAccessCursor: ''}"
                  [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_USERS) ? util.NoAccessMsg : ''"
                  matTooltipPosition="left"
          >Force User to Reset Password on Next Login
          </button>
        </div>
      </div>
      <br/>
      <!--    WELL START-->
      <div class="row justify-content-center" style="padding-bottom: 50px;">
        <div class="col-11">
          <form [formGroup]="userForm"
                (ngSubmit)="save()">
            <div class="row justify-content-center" style="padding-bottom: 25px;">
              <div class="col-12 ">
                <app-user-card [firstName]="userForm.get('firstName')?.value || undefined"
                               [lastName]="userForm.get('lastName')?.value || undefined"
                               [displayName]="userForm.get('email')?.value || undefined"
                               [borderColor]="'transparent'"
                               [sideColor]="'#002150'"
                               [background]="'#F1EDE4'"
                               [size]="userCardSize.LG"
                               [image]="userForm.get('imageUrl')?.value || undefined"
                ></app-user-card>
              </div>
            </div>
            <div class="row justify-content-center" style="padding-bottom: 25px;">
              <div class="col-12">
                <button type="button"
                        color="primary"
                        mat-raised-button
                        (click)="openImageUploadModal()"
                        [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_USERS)"
                        [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_USERS) ? util.NoAccessCursor: ''}"
                        [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_USERS) ? util.NoAccessMsg : ''"
                        matTooltipPosition="left"
                >Upload User Profile Photo
                </button>&nbsp;
                <button type="button"
                        color="warn"
                        mat-raised-button
                        (click)="clearImage()"
                        [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_USERS)"
                        [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_USERS) ? util.NoAccessCursor: ''}"
                        [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_USERS) ? util.NoAccessMsg : ''"
                        matTooltipPosition="left"
                >Clear Image
                </button>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 pt-4">
                <!--          row start-->
                <div class="row">
                  <div class="col-12 col-md-6">
                    <mat-form-field class="form-field" appearance="outline">
                      <mat-label>First Name</mat-label>
                      <input class="form-input" matInput formControlName="firstName" required="true"
                             placeholder="i.e. John"/>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-6">
                    <mat-form-field class="form-field" appearance="outline">
                      <mat-label>Last Name</mat-label>
                      <input class="form-input" matInput formControlName="lastName" required="true"
                             placeholder="i.e. Smith"/>
                    </mat-form-field>
                  </div>
                </div>
                <!--          row start-->
                <div class="row pt-4">
                  <div class="col-12">
                    <mat-form-field class="form-field" appearance="outline">
                      <mat-label>Email</mat-label>
                      <input class="form-input" matInput formControlName="email" required="true"
                             placeholder="i.e. example@rondeivu.com"/>
                      <mat-icon matSuffix color="primary" title="Email verified" *ngIf="userForm.get('emailVerified')?.value == true">check</mat-icon>
                      <mat-icon matSuffix color="warn" title="Email NOT verified" *ngIf="userForm.get('emailVerified')?.value == false">close</mat-icon>
                    </mat-form-field>
                    <!-- <mat-checkbox color="primary" formControlName="emailVerified">Email Verified</mat-checkbox>
                    <br />
                    <em class="muted-text">Has the user verified their email address via the emailed code</em> -->
                  </div>
                </div>
                <!--  row-->
                <div class="row pt-5">
                  <div class="col-3">
                    <mat-form-field class="form-field" appearance="outline">
                      <mat-label>Country Code</mat-label>
                      <mat-select id="cc" formControlName="countryCode">
                        <mat-option *ngFor="let cc of lookups.countryCodes" [value]="cc.key">
                          {{cc.value}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-9">
                    <mat-form-field class="form-field" appearance="outline">
                      <mat-label>Phone Number</mat-label>
                      <input class="form-input" matInput formControlName="mobile" required="true"/>
                      <mat-icon matSuffix color="primary" title="Phone number verified" *ngIf="userForm.get('phoneNumberVerified')?.value == true">check</mat-icon>
                      <mat-icon matSuffix color="warn" title="Phone number NOT verified" *ngIf="userForm.get('phoneNumberVerified')?.value == false">close</mat-icon>

                    </mat-form-field>
                  </div>
                  <!-- <div class="col-6">
                    <mat-checkbox color="primary" formControlName="phoneNumberVerified">Phone Verified</mat-checkbox>
                    <br>
                    <em class="muted-text">Has the user verified their phone number for 2-Factor Authentication</em>
                  </div> -->
                  <div class="col-12 text-end">
                    <button mat-raised-button
                            type="button"
                            color="warn"
                            (click)="reset2Fa()"
                            [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_USERS)"
                            [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_USERS) ? util.NoAccessCursor: ''}"
                            [title]="!util.hasPermission(util.Permission.CAN_EDIT_USERS) ? util.NoAccessMsg : ''"
                    >Reset the 2 Factor Authentication for User
                    </button>
                  </div>
                </div>
                

                <div class="row pt-5">
                  <div class="col-6">
                    <mat-form-field class="form-field" appearance="outline">
                      <mat-label>Referral's Email Address</mat-label>
                      <input class="form-input" matInput formControlName="referredByEmail" required="true"
                             placeholder="info@rondeivu.com"/>
                      <mat-hint align="end">This is the referral email the user input at registration</mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col-6">
                    <mat-form-field class="form-field" appearance="outline">
                      <mat-label>Profile Link</mat-label>
                      <input matInput id="profile-link" formControlName="profileLink"/>
                      <mat-hint align="end">This feature is currently disabled</mat-hint>
                    </mat-form-field>
                  </div>
                </div>
                <!--          row start-->
                <div class="row">
                  <div class="col-12" style="padding-bottom: 15px;">
                    <mat-label style="font-size: medium;">User State</mat-label>
                    <br/>
                    <mat-checkbox color="primary" formControlName="active">Active</mat-checkbox>
                    <br/>
                    <em class="muted-text">If box is unchecked the user will be unable to access Rondeivu services</em>
                  </div>
                </div>

                <!--          row start-->
                <div class="row">
                  <div class="col-6" hidden>
                    <mat-label style="font-size: medium;">MFA Method</mat-label>
                    <mat-form-field class="form-field" appearance="outline">
                      <mat-select formControlName="mfaType">
                        <mat-option>NONE</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" style="padding-top:15px;">
              <div class="col-12 text-end">
                <button type="submit"
                        mat-raised-button
                        color="primary"
                        [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_USERS)"
                        [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_USERS) ? util.NoAccessCursor: ''}"
                        [title]="!util.hasPermission(util.Permission.CAN_EDIT_USERS) ? util.NoAccessMsg : ''"
                >Save Changes to User Info
                </button>
              </div>
            </div>
            <!--          rows end-->
          </form>
        </div>
      </div>
      <br/><br/>
    </div>
  </div>
</div>

