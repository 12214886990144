import {Injectable} from '@angular/core';
import {Config, getConfig, initialConfigState} from "../../../redux";
import {environment} from "../../../../../environments/environment";
import {Store} from "@ngrx/store";
import {RondeivuHttpClient} from "../../../../handlers";
import {UuidService} from "../../../../services";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SubdocService {

  private config: Config = initialConfigState;
  private readonly BASE_URL = environment.rondeivu_api;
  private readonly FRAGMENT = '/subdocs';

  private readonly FOR_DEAL = '/for-deal';
  private readonly ENTITIES = '/entities';
  private readonly DOWNLOAD_PDF = '/download-pdf';
  private readonly APPROVAL = '/approval';
  private readonly SEND_TO_INVESTOR = '/rondeivu-send-to-investor';
  private readonly SEND_TO_SIGNATORY = '/issuer-send-to-issuer-signatory';
  private readonly UPLOAD = '/upload';
  private readonly DOWNLOAD_ZIP = '/download-zip';
  private readonly DOWNLOAD_FILE = '/download';
  private readonly STATUS = '/status';
  private readonly FIELDS = '/fields';
  private readonly TEMPLATE_ERRORS = '/subdoc-template-errors';

  constructor(private store: Store, private http: RondeivuHttpClient, private uuid: UuidService) {
    this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
  }

  getSubdocsForDeal(dealId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.FOR_DEAL + '/' + dealId);
  }

  getSubdocEntities(businessUnitId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.ENTITIES + '/' + businessUnitId);
  }

  addSubdoc(dealId: string, subdoc: any) {
    return this.http.post(this.BASE_URL + this.FRAGMENT + '/' + dealId, subdoc);
  }

  getSubdocById(subdocId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + '/' + subdocId);
  }

  deleteSubdoc(subdocId: string) {
    return this.http.delete(this.BASE_URL + this.FRAGMENT + '/' + subdocId);
  }

  getSubdocPdf(subdocId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.DOWNLOAD_PDF + '/' + subdocId, {responseType: 'blob'});
  }

  approvalSendToInvestor(subdocId: string, payload: any) {
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.APPROVAL + this.SEND_TO_INVESTOR + '/' + subdocId, payload);
  }

  approvalSendToIssuer(subdocId: string, payload: any) {
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.APPROVAL + this.SEND_TO_SIGNATORY + '/' + subdocId, payload);
  }

  uploadSubdocDocument(subdocId: string, payload: any): Observable<any> {
    let formData = new FormData();
    formData.append("formFile", payload);
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.UPLOAD + '/' + subdocId, formData);
  }

  downloadDocumentZip(subdocId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.UPLOAD + this.DOWNLOAD_ZIP + '/' + subdocId, {responseType: 'blob'});
  }

  downloadFile(fileId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.UPLOAD + this.DOWNLOAD_FILE + '/' + fileId, {responseType: 'blob'});
  }

  deleteFile(fileId: string) {
    return this.http.delete(this.BASE_URL + this.FRAGMENT + this.UPLOAD + '/' + fileId);
  }

  updateSubdocStatus(subdocId: string, payload: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.STATUS + '/' + subdocId, payload);
  }

  getDocumentFields(subdocId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.FIELDS + '/' + subdocId);
  }

  updateDocumentFields(subdocId: string, payload: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.FIELDS + '/' + subdocId, payload);
  }

  getSubdocTemplateErrors(subdocId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.TEMPLATE_ERRORS + '/' + subdocId);
  }
}
