<div class="container-fluid" style="height: calc(100vh - 64px); overflow-y: auto;">
  <br/> <br/>
  <div class="row justify-content-center" style="padding-bottom: 25px;">
    <div class="col-12 col-md-11">
      <div style="padding-bottom: 5px;">
        <mat-icon color="primary" style="padding-top: 5px;">account_balance</mat-icon>&nbsp;
        <span class="rm-title rm-title-3">Regulatory Questions & Third Party Information</span>
      </div>
      <br/>
      <div>
        <span>Thank you for choosing <b>rondeivu</b>!<br/> Please answer the <b>regulatory questions</b> and configure your <b>third-party-determination</b> below:</span>
      </div>
    </div>
  </div>
  <!--  form body start-->
  <form id="tpForm" [formGroup]="form" (ngSubmit)="submit()">
    <div class="row justify-content-center">
      <!--      centered content start-->
      <div class="col-12 col-md-11">
        <div class="mat-typography">
          <!--ROW START-->
          <div class="row" style="padding: 25px 0 0 0;">
            <div class="col-12">
              <span class="rm-title-2">Regulatory Questions</span>
            </div>
          </div>
          <!--ROW START-->
          <div class="row" style="padding-top: 25px;">
            <div class="col-8">
              <span>Is the client a registrant under the securities laws of any country?</span>
            </div>
            <div class="col-md-4 text-center">
              <mat-radio-group color="primary" aria-label="Select an option" formControlName="isRegistered">
                <mat-radio-button [value]="true">Yes</mat-radio-button>&nbsp;
                <mat-radio-button [value]="false">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <!--ROW START-->
          <div class="row" style="padding-top: 25px;">
            <div class="col-8">
              <span>Does the client or any of the authorized signatories own, alone or as part of a group, 10% or more of the voting rights of a publicly traded company?</span>
            </div>
            <div class="col-md-4 text-center">
              <mat-radio-group color="primary" aria-label="Select an option" formControlName="isOwnerofVotingRights">
                <mat-radio-button [value]="true">Yes</mat-radio-button>&nbsp;
                <mat-radio-button [value]="false">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <!--ROW START-->
          <div class="row" *ngIf="showNaturalPersonsTable()" style="padding: 50px 0;">
            <!-- table start-->
            <table class="table table-striped form-table">
              <thead>
              <th class="form-table-header">Owner of Voting Right</th>
              <th class="form-table-header">Publicly Traded Company</th>
              <th class="form-table-header">Percent(%) Owned</th>
              <th class="form-table-header">Actions</th>
              </thead>
              <tbody>
              <tr *ngFor="let cp of form.get(FORM_REG_PEOPLE)?.value; index as i;">
                <td>
                  <input class="form-table-input"
                         matInput
                         type="text"
                         placeholder="type here..."
                         [(ngModel)]="form.get(FORM_REG_PEOPLE)?.value[i].ownerOfVotingRight"
                         [ngModelOptions]="{standalone: true}"
                  />
                </td>
                <td>
                  <input class="form-table-input"
                         matInput
                         type="text"
                         placeholder="type here..."
                         [(ngModel)]="form.get(FORM_REG_PEOPLE)?.value[i].publicilyTradedCompany"
                         [ngModelOptions]="{standalone: true}"
                  />
                </td>
                <td>
                  <input class="form-table-input"
                         matInput
                         type="number"
                         placeholder="type here..."
                         [(ngModel)]="form.get(FORM_REG_PEOPLE)?.value[i].ownerShip"
                         [ngModelOptions]="{standalone: true}"
                  />
                </td>
                <td class="text-center">
                  <button mat-icon-button
                          type="button">
                    <mat-icon color="warn"
                              (click)="deleteFormArray(FORM_REG_PEOPLE, i)"
                              [matTooltip]="'Remove Owner of Company ' + (i + 1)"
                              matTooltipPosition="left">
                      delete
                    </mat-icon>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--ROW START-->
          <div class="row" *ngIf="showNaturalPersonsTable()" style="padding-bottom: 50px;">
            <div class="col-12 text-start">
              <button mat-raised-button color="primary" type="button"
                      (click)="addNewObjectToCollection(FORM_REG_PEOPLE)">
                Add Natural Person
              </button>
            </div>
          </div>
          <!--ROW START-->
          <div class="row" style="padding-top: 25px;">
            <div class="col-8">
              <span>Are any of the authorized signatories of the entity a Director or Officer of a company whose shares are traded on the stock exchange or on the OTC market?</span>
            </div>
            <div class="col-md-4 text-center">
              <mat-radio-group color="primary" aria-label="Select an option" formControlName="isPubliclyTraded">
                <mat-radio-button [value]="true">Yes</mat-radio-button>&nbsp;
                <mat-radio-button [value]="false">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <!--ROW START-->
          <div class="row" *ngIf="showLegalEntitiesTable()" style="padding: 50px 0;">
            <!-- table start-->
            <table class="table table-striped form-table">
              <thead>
              <th class="form-table-header">Authorized Signatory</th>
              <th class="form-table-header">Company Name</th>
              <th class="form-table-header">Percent(%) Owned</th>
              <th class="form-table-header">Actions</th>
              </thead>
              <tbody>
              <tr *ngFor="let cp of form.get(FORM_REG_ENT)?.value; index as i;">
                <td>
                  <input class="form-table-input"
                         matInput
                         type="text"
                         placeholder="type here..."
                         [(ngModel)]="form.get(FORM_REG_ENT)?.value[i].authorizedSignatory"
                         [ngModelOptions]="{standalone: true}"
                  />
                </td>
                <td>
                  <input class="form-table-input"
                         matInput
                         type="text"
                         placeholder="type here..."
                         [(ngModel)]="form.get(FORM_REG_ENT)?.value[i].companyName"
                         [ngModelOptions]="{standalone: true}"
                  />
                </td>
                <td>
                  <input class="form-table-input"
                         matInput type="number"
                         placeholder="type here..."
                         [(ngModel)]="form.get(FORM_REG_ENT)?.value[i].ownerShip"
                         [ngModelOptions]="{standalone: true}"
                  />
                </td>
                <td class="text-center">
                  <button mat-icon-button
                          type="button">
                    <mat-icon color="warn"
                              (click)="deleteFormArray(FORM_REG_ENT, i)"
                              [matTooltip]="'Remove Director of Company ' + (i + 1)"
                              matTooltipPosition="left">
                      delete
                    </mat-icon>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--ROW START-->
          <div class="row" *ngIf="showLegalEntitiesTable()" style="padding-bottom: 150px;">
            <div class="col-12 text-start">
              <button mat-raised-button color="primary" type="button" (click)="addNewObjectToCollection(FORM_REG_ENT)">
                Add Legal Entity
              </button>
            </div>
          </div>

          <hr/>


          <!--ROW START-->
          <div class="row" style="padding: 15px 0;">
            <div class="col-12">
              <span class="rm-title-2">Third Party Information</span>
            </div>
          </div>
          <!--ROW START-->
          <div class="row" style="padding-top: 25px;">
            <div class="col-8">
              <span>Is the third party registering on behalf of the entity?</span>
            </div>
            <div class="col-md-4 text-center">
              <mat-radio-group color="primary" aria-label="Select an option"
                               formControlName="isThisPartyRegisteredOnBehalf" (change)="chooseOption()">
                <mat-radio-button [value]="true">Yes</mat-radio-button>&nbsp;
                <mat-radio-button [value]="false">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <!--ROW START-->
          <div class="row" style="padding-top: 25px;">
            <div class="col-8">
              <span>Is the third
                party directing the entity to conduct any activity or financial transactions with respect to
                prospective investments through Rondeivu?</span>
            </div>
            <div class="col-md-4 text-center">
              <mat-radio-group color="primary" aria-label="Select an option"
                               formControlName="isAthirdPartyDirecting" (change)="chooseOption()">
                <mat-radio-button [value]="true">Yes</mat-radio-button>&nbsp;
                <mat-radio-button [value]="false">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <!--ROW START-->
          <div class="row" style="padding-top: 25px;">
            <div class="col-8">
              <span>Will the third party will have a financial interest in prospective
                investments through Rondeivu?</span>
            </div>
            <div class="col-md-4 text-center">
              <mat-radio-group color="primary" aria-label="Select an option"
                               formControlName="willAthirdPartyHasAfinancialInterest" (change)="chooseOption()">
                <mat-radio-button [value]="true">Yes</mat-radio-button>&nbsp;
                <mat-radio-button [value]="false">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <!--ROW START-->
          <div class="row" *ngIf="isOneOrMoreSelected()" style="padding-top:75px;">
            <!-- FIRST NAME-->
            <div class="col-12 col-md-4">
              <mat-label>First Name*</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input matInput id="fname" formControlName="personFirstName" [required]="isOneOrMoreSelected()"/>
                <mat-error *ngIf="form.invalid">Please enter a first name.</mat-error>
              </mat-form-field>
            </div>
            <!-- MIDDLE NAME-->
            <div class="col-12 col-md-2">
              <mat-label>Middle Name</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input matInput id="mname" formControlName="personMiddleName"/>
              </mat-form-field>
            </div>
            <!-- LAST NAME-->
            <div class="col-12 col-md-4">
              <mat-label>Last Name*</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input matInput id="lname" formControlName="personLastName" [required]="isOneOrMoreSelected()"/>
                <mat-error *ngIf="form.invalid">Please enter a last name.</mat-error>
              </mat-form-field>
            </div>
            <!-- SUFFIX-->
            <div class="col-12 col-md-2">
              <mat-label>Suffix</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input matInput id="suffix" formControlName="personSuffix"/>
              </mat-form-field>
            </div>
          </div>
          <!--ROW START-->
          <div class="row" *ngIf="isOneOrMoreSelected()" style="padding: 15px 0;">
            <div class="col-12">
              <span class="rm-title-6">Business Information</span>
            </div>
          </div>

          <!--ROW START-->
          <div class="row" *ngIf="isOneOrMoreSelected()">
            <!-- STREET-->
            <div class="col-12 col-md-8">
              <mat-label>Street Address*</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input matInput id="street" formControlName="businessStreetAddress" [required]="isOneOrMoreSelected()"/>
                <mat-error *ngIf="form.invalid">Please enter a street address.</mat-error>
              </mat-form-field>
            </div>
            <!--        City-->
            <div class="col-12 col-md-4">
              <mat-label>City*</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input matInput id="city" formControlName="businessCity" [required]="isOneOrMoreSelected()"/>
                <mat-error *ngIf="form.invalid">Please enter a city.</mat-error>
              </mat-form-field>
            </div>
          </div>

          <!--ROW START-->
          <div class="row" *ngIf="isOneOrMoreSelected()">
            <!-- Country-->
            <div class="col-12 col-md-4">
              <mat-label>Country*</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <mat-select id="country" formControlName="businessCountryId" [required]="isOneOrMoreSelected()">
                  <mat-option *ngFor="let c of lookups.countries" [value]="c.key">
                    {{ c.value }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.invalid">Please choose one.</mat-error>
              </mat-form-field>
            </div>
            <!-- State OR Province-->
            <div class="col-12 col-md-4">

              <!-- state selection-->
              <mat-label *ngIf="form.get('businessCountryId')?.value == 'US'">State*</mat-label>
              <mat-form-field class="form-field" appearance="outline"
                              *ngIf="form.get('businessCountryId')?.value == 'US'">
                <mat-select id="state"
                            [required]="form.get('businessCountryId')?.value == 'US'"
                            formControlName="businessStateOrProvince">
                  <mat-option *ngFor="let s of lookups.stateTypes" [value]="s.key">
                    {{ s.value }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.invalid">Please choose one.</mat-error>
              </mat-form-field>

              <!-- province selection-->
              <mat-label *ngIf="form.get('businessCountryId')?.value == 'CA'">Province*</mat-label>
              <mat-form-field class="form-field" appearance="outline"
                              *ngIf="form.get('businessCountryId')?.value == 'CA'">
                <mat-select id="province"
                            [required]="form.get('businessCountryId')?.value == 'CA'"
                            formControlName="businessStateOrProvince">
                  <!--                <mat-option>Placeholder</mat-option>-->
                  <mat-option *ngFor="let p of lookups.provinceTypes" [value]="p.key">
                    {{ p.value }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.invalid">Please choose one.</mat-error>
              </mat-form-field>

            </div>
            <!-- ZIP OR POSTAL CODE-->
            <div class="col-12 col-md-4">
              <mat-label>Zip/Postal Code*</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input matInput id="zip" formControlName="businessZipPostalCode" [required]="isOneOrMoreSelected()"/>
                <mat-error *ngIf="form.invalid">Please enter a zip/postal code.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <!--ROW START-->
          <div class="row" *ngIf="isOneOrMoreSelected()">
            <!-- LEGAL DOMICILE-->
            <div class="col-12">
              <mat-label>Legal Domicile*</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <mat-select id="dom" formControlName="businessLegalDomicile" [required]="isOneOrMoreSelected()">
                  <mat-option *ngFor="let c of lookups.countries" [value]="c.key">
                    {{ c.value }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.invalid">Please choose one.</mat-error>
              </mat-form-field>

            </div>
          </div>

          <!-- Actions-->
          <div class="row" style="padding-bottom: 150px; padding-top:50px;">
            <div class="col-12 text-end">
              <button mat-raised-button color="primary" type="submit">
                SAVE CHANGES
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <!--  {{ form.getRawValue() | json }}-->

</div>
