import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {DealAdminService, DealService} from './services';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {GoogleMapsModule} from '@angular/google-maps';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {DealsRoutingModule} from './deals-routing.module';
import {
  DealListComponent,
  DealModalComponent,
  DealNavigationComponent,
  DealTeamMemberModalComponent,
  CommunicationComponent,
  DealAnalyticsComponent,
  DealMatchingComponent,
  DocumentsComponent,
  DealHighlightsComponent,
  MediaComponent,
  DealTeamComponent,
  LockInModalComponent,
  DealMetricsComponent,
  DealSettingsComponent,
  SyndicationComponent,
  SubscriptionComponent,
  DealDataRoomComponent,
  IndicateInterestModalComponent,
  SubscriptionModalComponent,
  NotesModalComponent,
  MnpiModalComponent,
  DeclineModalComponent,
  InvestorTestComponent,
  NegotiateModalComponent,
  DealViewComponent,
  DealEditComponent,
  DealMatchingModalComponent,
  MediaModalComponent,
  DealNdaComponent,
  DealHighlightsModalComponent,
  DealTeamModalComponent,
  DealTeamNavigationComponent,
  DealTeamInnerNavigationComponent,
  DealTeamMemberInfoComponent,
  DealTeamMemberPermissionsComponent,
  DealSubscriptionComponent,
  DealSubscriptionSubdocModalComponent,
  DealSubscriptionSubdocEditModalComponent,
  DealSubscriptionSubdocFormModalComponent,
  DealSubscriptionSubdocFormFieldComponent
} from './components';
import {SharedModule} from "../shared/shared.module";
import {ClipboardModule} from "@angular/cdk/clipboard";
import {NgMaterialModule} from "../ng-material/ng-material.module";
import { AllocationEditModalComponent } from './components/allocation-edit-modal/allocation-edit-modal.component';


@NgModule({
  declarations: [
    DealNavigationComponent,
    DealListComponent,
    DealHighlightsComponent,
    DealTeamComponent,
    DealTeamModalComponent,
    DealAnalyticsComponent,
    CommunicationComponent,
    DocumentsComponent,
    MediaComponent,
    DealMatchingComponent,
    DealModalComponent,
    DealTeamMemberModalComponent,
    DealMetricsComponent,
    DealSettingsComponent,
    SyndicationComponent,
    DealViewComponent,
    DealEditComponent,
    SubscriptionComponent,
    DealMatchingModalComponent,
    MediaModalComponent,
    LockInModalComponent,
    IndicateInterestModalComponent,
    SubscriptionModalComponent,
    NotesModalComponent,
    DealDataRoomComponent,
    DealNdaComponent,
    MnpiModalComponent,
    DeclineModalComponent,
    DealHighlightsModalComponent,
    InvestorTestComponent,
    NegotiateModalComponent,
    DealTeamModalComponent,
    DealTeamNavigationComponent,
    DealTeamInnerNavigationComponent,
    DealTeamMemberInfoComponent,
    DealTeamMemberPermissionsComponent,
    DealSubscriptionComponent,
    DealSubscriptionSubdocModalComponent,
    DealSubscriptionSubdocEditModalComponent,
    DealSubscriptionSubdocFormModalComponent,
    DealSubscriptionSubdocFormFieldComponent,
    AllocationEditModalComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    DealsRoutingModule,
    NgxChartsModule,
    GoogleMapsModule,
    HttpClientModule,
    ClipboardModule,
    NgMaterialModule
  ],
  providers: [DealService, DealAdminService],
  exports: []
})
export class DealsModule {
}
