<div class="rm-header">
    <span>Edit Indications of Interest & Allocations</span>
</div>
<form [formGroup]="fg">
    <mat-dialog-content>
        <form [formGroup]="statusFg" *ngIf="util.isAdmin()">
            <div class="row">
                <p>Admin can edit the status of this particular syndication/subscription from the investor</p>
            </div>
            <div class="row">
                <div class="col-6">
                    <mat-form-field appearance="outline" floatLabel="always" class="form-field">
                        <mat-label>Syndication / Subscription Status</mat-label>
                        <mat-select formControlName="status">
                            <mat-option *ngFor="let item of lookupSerivice.lookups.subscriptionStatus" [value]="item.key">
                                {{item.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-4 pt-2">
                    <button mat-raised-button
                        color="primary" (click)="saveStatus()">
                        Set Syndication / Subscription Status
                    </button>
                </div>
            </div>
        </form>

        <div class="row pb-5">
            <div class="col-12">
                <p>
                    You can edit the segments that you have access to edit.
                </p>
            </div>
            <div class="col-6">
                <mat-form-field appearance="outline" floatLabel="always" class="form-field">
                    <mat-label>Indication of Interest Amount (USD)</mat-label>
                    <input matInput type="number" formControlName="indicationAmount" />
                    <mat-hint align="end">Enter the amount in full (e.g. 5000000 for $5 Million)</mat-hint>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field appearance="outline" floatLabel="always" class="form-field">
                    <mat-label>Issuer Allocation Amount (USD)</mat-label>
                    <input matInput type="number" formControlName="allocationAmount" />
                    <mat-hint align="end">Enter the amount in full (e.g. 5000000 for $5 Million)</mat-hint>
                </mat-form-field>     
            </div>
            <div class="col-12">
                <mat-form-field appearance="outline" floatLabel="always" class="form-field">
                    <mat-label>Restriction Type</mat-label>
                    <mat-select formControlName="restrictionType">
                        <mat-option *ngFor="let item of lookupSerivice.lookups.indicationOfInterestRestrictionTypes" [value]="item.key">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                    <mat-hint align="end">Choose the one that describes your interest restrictions</mat-hint>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field appearance="outline" floatLabel="always" class="form-field">
                    <mat-label>Additional Investor Notes</mat-label>
                    <textarea matInput type="text"  formControlName="notes" placeholder="Investor notes here">
                    </textarea>
                    <mat-hint align="end">Notes will be visible to Rondeivu and the Issuer</mat-hint>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-checkbox color="primary" formControlName="sendInvestorNotification">
                    <b>Send Email Notification to Investor</b><br />
                    <em class="text-muted">
                        It is important to send a notification to the investor, so they know to start their Sub Docs process
                    </em>
                </mat-checkbox>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="space-between">
        <button mat-button mat-dialog-close>Cancel</button>
        <span>
            <button mat-raised-button color="primary" (click)="save()">{{ fg.value['sendInvestorNotification'] ? 'Save & Send Notification to Investor' : 'Save Draft' }}</button>
        </span>
    </mat-dialog-actions>

</form>