
<div class="rm-header">
  <span>{{data.type.charAt(0).toUpperCase() + data.type.slice(1) + ' Deal ' + (data.type == 'match' ? 'to' : 'and') + ' Investor'}}</span>
</div>

<div class="container">
  <!--<h1 mat-dialog-title>{{data.type.charAt(0).toUpperCase() + data.type.slice(1) + ' Deal ' +  (data.type == 'match'? 'to':'and') + ' Investor'}}</h1>-->
  <div mat-dialog-content class="row">
    <div class="col-12 pt-4">
      <p *ngIf="data.type == 'match'">
        Match <b>'{{data.displayName}}'</b> with this deal!<br>
        Please note that matching an investor will send an email to the employees of the investing Business Unit.
      </p>
      <p *ngIf="data.type == 'unmatch'">
        Remove <b>'{{data.displayName}}'</b> from this deal!<br>
        Emails will not be sent upon deal unmatching.
      </p>
    </div>
    <div class="col-12 pt-4">
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label>Reason for {{data.type}}ing this investor:</mat-label>
        <textarea matInput [(ngModel)]="data.reason" placeholder="(Optional)" style="max-height: 400px;"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions class="space-between">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" *ngIf="data.type == 'match'" (click)="action(data)" cdkFocusInitial>Match Investor</button>
    <button mat-raised-button color="warn" *ngIf="data.type == 'unmatch'" (click)="action(data)" cdkFocusInitial>Remove Investor Match</button>
  </div>
</div>

