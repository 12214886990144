import {Component, OnDestroy, OnInit} from '@angular/core';
import {ThemePalette} from "@angular/material/core";
import {
  Config,
  getConfig,
  getEditingBusinessUnit,
  getSelectedBusinessUnit,
  initialConfigState,
  initialThemeState, showNotesTab,
  Theme,
  toggleLoading,
  updateEditingBusinessUnit
} from "../../../redux";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {BusinessUnitService} from "../../services";
import {IBusinessUnit} from "../../models";
import {ToastrService} from "ngx-toastr";
import {INavItem} from "../../../../models";
import {Subscription} from "rxjs";
import {UtilService} from "../../../shared/services";
import {ApprovalsService} from "../../../approvals/services/approvals/approvals.service";
import {IApprovalRequest} from "../../../approvals/models";
import {toggleNavigating} from "../../../redux/stores/config/config.actions";
import {AppFade} from "../../../../animations/animations";

@Component({
  selector: 'app-business-units-navigation',
  templateUrl: './business-units-navigation.component.html',
  styleUrls: ['./business-units-navigation.component.scss'],
  animations: [AppFade()],
})
export class BusinessUnitsNavigationComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  editingBusinessUnit$: Subscription = new Subscription();
  route$: Subscription = new Subscription();

  loading = false;
  animationState = false;

  readonly ISSUER_LINKS: INavItem[] = [
    {title: 'Information', link: '/information', enabled: true, hidden: false, icon: "", toolTip: ""},
    {
      title: 'Regulatory',
      link: '/regulatory',
      enabled: true,
      hidden: false,
      icon: "",
      toolTip: ""
    },
    {title: 'Entities', link: '/entities', enabled: true, hidden: false, icon: "", toolTip: ""},
    {title: 'Deals', link: '/deals', enabled: true, hidden: false, icon: "", toolTip: ""},
    {title: 'Employees', link: '/employees', enabled: true, hidden: false, icon: "", toolTip: ""},
  ];
  readonly INVESTOR_LINKS: INavItem[] = [
    {title: 'Information', link: '/information', enabled: true, hidden: false, icon: "", toolTip: ""},
    {
      title: 'Regulatory',
      link: '/regulatory',
      enabled: true,
      hidden: false,
      icon: "",
      toolTip: ""
    },
    {title: 'Entities', link: '/entities', enabled: true, hidden: false, icon: "", toolTip: ""},
    {title: 'Matched Deals', link: '/deals', enabled: true, hidden: false, icon: "", toolTip: ""},
    {title: 'Mandates', link: '/mandates', enabled: true, hidden: false, icon: "", toolTip: ""},
    {title: 'Employees', link: '/employees', enabled: true, hidden: false, icon: "", toolTip: ""},
  ];
  readonly ADMIN_LINKS: INavItem[] = [
    {title: 'Information', link: '/information', enabled: true, hidden: false, icon: "", toolTip: ""},
    {
      title: 'Regulatory',
      link: '/regulatory',
      enabled: true,
      hidden: false,
      icon: "",
      toolTip: ""
    },
    {title: 'Deals', link: '/entities', enabled: true, hidden: false, icon: "", toolTip: ""},
    {title: 'Mandates', link: '/mandates', enabled: true, hidden: false, icon: "", toolTip: ""},
    {title: 'Employees', link: '/employees', enabled: true, hidden: false, icon: "", toolTip: ""},
  ];
  links: INavItem[] = [];
  activeLink: INavItem = {} as unknown as INavItem;
  background: ThemePalette = undefined;

  editingBusinessUnitId: string | null = '';
  editingBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;

  config: Config = initialConfigState;
  theme: Theme = initialThemeState;

  constructor(
    private approvalService: ApprovalsService,
    private businessUnitService: BusinessUnitService,
    private toastrService: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    public util: UtilService
  ) {

    this.route$ = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.loading = true;
        this.store.dispatch(toggleNavigating({navigating: true}));
        setTimeout(() => {
          this.loading = false;
          this.store.dispatch(toggleNavigating({navigating: false}));
        }, 1000);
        this.setActiveLink();
      }
    });

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((selectedBusinessUnit: IBusinessUnit) => {
      this.selectedBusinessUnit = selectedBusinessUnit;
    });

    this.editingBusinessUnit$ = this.store.select(getEditingBusinessUnit).subscribe((bu: IBusinessUnit) => {
      if (!!bu && !!bu.businessUnitId && this.editingBusinessUnit.businessUnitId != bu.businessUnitId) {
        this.editingBusinessUnit = Object.assign({}, bu);
        this.subscribeToDataSource();
      }
    });
    this.setActiveLink();
  }

  ngOnInit(): void {
    this.animationState = true;
  }

  private getLinks() {
    switch (this.editingBusinessUnit.businessUnitType.toUpperCase()) {
      case 'ADMIN':
        this.links = this.ADMIN_LINKS;
        break;
      case 'INVESTOR':
        this.links = this.INVESTOR_LINKS;
        break;
      case 'ISSUER':
      default:
        this.links = this.ISSUER_LINKS;
      // break;
    }
  }

  getApprovalPermission(): boolean {
    if (!!this.editingBusinessUnit.businessUnitType) {
      switch (this.editingBusinessUnit.businessUnitType.toUpperCase()) {
        case 'ISSUER':
          return this.util.hasPermission(this.util.Permission.CAN_EDIT_BU_APPROVALS_ISSUER);
        case 'INVESTOR':
          return this.util.hasPermission(this.util.Permission.CAN_EDIT_BU_APPROVALS_INVESTOR);
      }
    }
    return false;
  }

  ngOnDestroy() {
    this.store.dispatch(updateEditingBusinessUnit({businessUnit: {} as IBusinessUnit}));
    this.store.dispatch(showNotesTab({show: false}));
    this.config$.unsubscribe();
    this.editingBusinessUnit$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.route$.unsubscribe();
  }

  subscribeToDataSource() {
    console.log("Configuring Business Unit Navigation...");
    this.getEditingBusinessUnitIdFromUri();
    this.getEditingBusinessUnit();
    //update the notes view
    if (this.util.isType('admin')) {
      this.store.dispatch(showNotesTab({show: true}));
    } else {
      this.store.dispatch(showNotesTab({show: false}));
    }
    this.getLinks();
    this.setActiveLink();
  }

  setActiveLink() {
    const fragments = this.router.url.split('/');
    let link = '/' + fragments[fragments.length - 1];
    this.links.forEach(l => {
      if (l.link == link) {
        this.activeLink = l;
      }
    });
  }

  allowApproval() {
    if (!!this.editingBusinessUnit && !!this.editingBusinessUnit.businessUnitType) {
      return this.editingBusinessUnit.status === 'PENDING' && this.util.isType('admin');
    }
    return false;
  }

  /**
   * Approve a business unit
   * @param b business unit
   */
  approveBusinessUnit(b: any) {
    this.sendApproval("Business Unit Approved!", {id: b.businessUnitId, type: "businessunit", status: "APPROVED"});
  }

  /**
   * Decline a business unit
   * @param b business unit
   */
  declineBusinessUnit(b: any) {
    this.sendApproval("Business Unit Declined!", {id: b.businessUnitId, type: "businessunit", status: "DECLINED"});
  }

  private sendApproval(msg: string, payload: IApprovalRequest) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.approvalService.addApproval(payload).subscribe({
      next: (res: { status: string }) => {
        this.editingBusinessUnit.status = res.status
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.success(msg, $localize`:@@companyName:Rondeivu`);
      }, error: err => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.error("Unable to add approval!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  getEditingBusinessUnit() {
    if (!!this.selectedBusinessUnit && !!this.editingBusinessUnitId) {
      this.businessUnitService.getBusinessUnit(this.editingBusinessUnitId).subscribe({
        next: (bu: IBusinessUnit) => {
          this.toastrService.success("Business Unit Loaded!", $localize`:@@companyName:Rondeivu`);
          this.store.dispatch(updateEditingBusinessUnit({businessUnit: bu}));
        }, error: (error: any) => {
          this.toastrService.error("Unable to get business unit!", $localize`:@@companyName:Rondeivu`);
        }
      });
    }
  }

  getEditingBusinessUnitIdFromUri() {
    this.editingBusinessUnitId = this.route.snapshot.paramMap.get('id');
  }

  navigate(obj: INavItem) {
    this.store.dispatch(toggleNavigating({navigating: true}));
    this.animationState = false;
    setTimeout(() => {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/business-units/' + this.editingBusinessUnit.businessUnitId + obj.link]).then(() => {
        this.animationState = true;
        this.store.dispatch(toggleNavigating({navigating: false}));
      });
    }, 300);
  }

  goBack() {
    this.store.dispatch(toggleNavigating({navigating: true}));
    this.animationState = false;
    setTimeout(() => {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/business-units']).then(() => {
        this.animationState = true;
        this.store.dispatch(toggleNavigating({navigating: false}));
      });
    }, 300);
  }


}
