<div style="overflow-y: auto; height: calc(100vh - 112px);">
  <div class="container-fluid">
    <div style="overflow-x: hidden;">
      <br/><br/>
      <div class="row justify-content-center">
        <div class="col-5">
          <span style="font-size: x-large; color: #002150;">Legal Information</span><br/>
          <span style="font-size: small;">This is where you can edit your legal settings.</span>
        </div>
        <div class="col-6 text-end">
<!--          <button mat-raised-button color="primary" (click)="forcePasswordReset()">FORCE PASSWORD RESET</button>-->
        </div>
      </div>
      <br/><br/>
      <!--    WELL START-->
<!--      <div class="row justify-content-center">-->
<!--        <div class="col-11" style="border: 1px solid rgba(128,128,128,0.5); border-radius: 12px; padding: 12px;">-->
<!--          <form [formGroup]="userForm"-->
<!--                (ngSubmit)="save()">-->
<!--            <div class="row">-->
<!--              <span style="font-size: large;">Settings</span>-->
<!--            </div>-->
<!--            <br/>-->
<!--            &lt;!&ndash;          row start&ndash;&gt;-->
<!--            <div class="row">-->
<!--              <div class="col-6">-->
<!--                <mat-label style="font-size: medium;">First Name</mat-label>-->
<!--                <mat-form-field class="form-field" appearance="outline">-->
<!--                  <input class="form-input" matInput formControlName="firstName" required placeholder="i.e. John"/>-->
<!--                </mat-form-field>-->
<!--              </div>-->
<!--              <div class="col-6">-->
<!--                <mat-label style="font-size: medium;">Last Name</mat-label>-->
<!--                <mat-form-field class="form-field" appearance="outline">-->
<!--                  <input class="form-input" matInput formControlName="lastName" required placeholder="i.e. Smith"/>-->
<!--                </mat-form-field>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;          row start&ndash;&gt;-->
<!--            <div class="row">-->
<!--              <div class="col-6">-->
<!--                <mat-label style="font-size: medium;">Email</mat-label>-->
<!--                <mat-form-field class="form-field" appearance="outline">-->
<!--                  <input class="form-input" matInput formControlName="email" required-->
<!--                         placeholder="i.e. example@rondeivu.com"/>-->
<!--                </mat-form-field>-->
<!--              </div>-->
<!--              <div class="col-1">-->
<!--                <mat-label style="font-size: medium;">CC</mat-label>-->
<!--                <mat-form-field class="form-field" appearance="outline">-->
<!--                  <input class="form-input" matInput formControlName="countryCode" required placeholder="+1"/>-->
<!--                </mat-form-field>-->
<!--              </div>-->
<!--              <div class="col-5">-->
<!--                <mat-label style="font-size: medium;">Phone Number</mat-label>-->
<!--                <mat-form-field class="form-field" appearance="outline">-->
<!--                  <input class="form-input" matInput formControlName="mobile" required placeholder="555-555-5555"/>-->
<!--                </mat-form-field>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;          row start&ndash;&gt;-->
<!--            <div class="row" style="padding-bottom: 50px;">-->
<!--              <div class="col-6">-->
<!--                <mat-checkbox formControlName="emailVerified">Email Verified</mat-checkbox>-->
<!--              </div>-->
<!--              <div class="col-6">-->
<!--                <mat-checkbox formControlName="phoneVerified">Phone Verified</mat-checkbox>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;          row start&ndash;&gt;-->
<!--            <div class="row">-->
<!--              <div class="col-6">-->
<!--                <mat-label style="font-size: medium;">Referral’s Email Address</mat-label>-->
<!--                <mat-form-field class="form-field" appearance="outline">-->
<!--                  <input class="form-input" matInput formControlName="referredByEmail" required placeholder="i.e. CEO"/>-->
<!--                </mat-form-field>-->
<!--              </div>-->
<!--              <div class="col-6">-->
<!--                <mat-label style="font-size: medium;">User State</mat-label><br/>-->
<!--                <mat-checkbox formControlName="phoneVerified">Active</mat-checkbox><br/>-->
<!--                <span style="font-weight: lighter;">If box is unchecked the user will be unable to access Rondeivu services.</span>-->
<!--              </div>-->
<!--            </div>-->

<!--            &lt;!&ndash;          row start&ndash;&gt;-->
<!--            <div class="row">-->
<!--              <div class="col-6">-->
<!--                <mat-label style="font-size: medium;">MFA Method</mat-label>-->
<!--                <mat-form-field class="form-field" appearance="outline">-->
<!--                  <mat-select formControlName="mfaType">-->
<!--                    <mat-option>NONE</mat-option>-->
<!--                  </mat-select>-->
<!--                </mat-form-field>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="row">-->
<!--              <div class="col-12 text-end">-->
<!--                <button type="submit" mat-raised-button color="primary">Save Changes</button>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;          rows end&ndash;&gt;-->
<!--          </form>-->
<!--        </div>-->
<!--      </div>-->
      <br/><br/>
    </div>
  </div>
</div>

