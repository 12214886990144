<div class="space-between employee-header p-2">
  <div class="space-between">
    <button type="button" mat-icon-button title="Go Back to the Entity" (click)="back()">
      <mat-icon>west</mat-icon>
    </button>
    <span style="display: flex; flex-direction: column; margin-left: 5px">
      <span>{{selectedEntityKyc.kycName}}</span>
      <em class="text-muted" style="font-size: smaller">{{ lookupService.getLookupValueForKey(lookupService.lookups.kycRondeivuStatus,  selectedEntityKyc.kycRondeivuStatus)}}</em>
    </span>
    <!-- <app-user-card
      [firstName]="selectedEntityKyc.kycName"
      [displayName]="selectedEntityKyc.kycRondeivuStatus"
      [icon]="'picture_as_pdf'"
      [size]="UserCardSize.SM"
    >
    </app-user-card> -->
  </div>
  <div>
    <form [formGroup]="form">
      <!--      <mat-form-field class="form-field" *ngIf="util.isType('admin')">-->
      <!--        <mat-label>KYC Status</mat-label>-->
      <!--        <mat-select id="kycStatus" formControlName="kycRondeivuStatus" (selectionChange)="changeStatus()">-->
      <!--          <mat-option *ngFor="let s of lookups.kycRondeivuStatus" [value]="s.key">-->
      <!--            {{ s['value'] }}-->
      <!--          </mat-option>-->
      <!--        </mat-select>-->
      <!--      </mat-form-field>&nbsp;-->
      <button type="button" mat-raised-button color="primary" (click)="togglePdfView()">
        <mat-icon>{{ viewingPDF ? 'edit' : 'picture_as_pdf' }}</mat-icon>&nbsp;{{ viewingPDF ? 'Back to Editting current KYC' : 'View PDF of current KYC' }}
      </button>
    </form>
  </div>
</div>

<div *ngIf="!viewingPDF">
  <router-outlet></router-outlet>
</div>
<div *ngIf="viewingPDF">
  <ngx-extended-pdf-viewer
    [src]="base64"
    [useBrowserLocale]="true"
    [textLayer]="true"
    [showHandToolButton]="true"
    [showPresentationModeButton]="false"
    [showDownloadButton]="true"
    [showPrintButton]="true"
    [showOpenFileButton]="false"
    [showPropertiesButton]="false"
    [showSidebarButton]="false"
    backgroundColor="#333333"
  ></ngx-extended-pdf-viewer>
</div>
