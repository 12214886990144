import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {Store} from "@ngrx/store";
import {
  Config,
  getConfig, getLookups,
  initialConfigState,
  initialLookupsState,
  Lookups
} from "../../../redux";
import {IBusinessUnit} from "../../models";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BusinessUnitService} from "../../services";
import { LookupService } from 'src/app/services';

@Component({
  selector: 'app-business-unit-modal',
  templateUrl: './business-unit-modal.component.html',
  styleUrls: ['./business-unit-modal.component.scss']
})
export class BusinessUnitModalComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  // lookup$: Subscription = new Subscription();

  // lookups: Lookups = initialLookupsState;
  config: Config = initialConfigState;
  employees: any[] = [];

  userForm = new FormGroup({
    id: new FormControl(),
    slug: new FormControl('', [Validators.required, Validators.minLength(4), Validators.max(12)]),
    displayName: new FormControl('', []),
    internalName: new FormControl('', []),
    businessUnitType: new FormControl("", [Validators.required]),
    subType: new FormControl("", [Validators.required]),
    domicile: new FormControl('', [Validators.required]),
    intakeDescription: new FormControl(''),
  });

  constructor(public dialogRef: MatDialogRef<BusinessUnitModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private businessUnitService: BusinessUnitService,
              public lookupService: LookupService,
              private toastr: ToastrService, 
              private store: Store
  ) {
    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    // this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
    //   this.lookups = lookups;
    // });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    // this.lookup$.unsubscribe();
  }

  submit() {
    this.dialogRef.close(this.userForm.value as IBusinessUnit);
  }
}
