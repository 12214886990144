import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {Auth} from 'aws-amplify';

import {from, Observable, retry, Subscription, tap} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {Store} from "@ngrx/store";
import {
  getConfig, getEditingBusinessUnit, getSelectedBusinessUnit, getSelectedDealId
} from "../modules/redux";
import {ToastrService} from "ngx-toastr";
import {IBusinessUnit} from "../modules/business-units/models";
import {UtilService} from "../modules/shared/services";

/**
 * This will append jwt token for the http requests.
 *
 * @export
 * @class JwtInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class RondeivuInterceptor implements HttpInterceptor, OnDestroy {

  config$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  editingBusinessUnit$: Subscription = new Subscription();
  dealId$: Subscription = new Subscription();
  private buid: string = '';
  private sbuid: string = '';
  private dealId: string = '';

  private readonly DEFAULT_MAX_RETRIES = 0;

  constructor(private store: Store, private util: UtilService, private toastr: ToastrService) {
    this.config$ = this.store.select(getConfig).subscribe(config => {
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.buid = bu?.businessUnitId || '';
    });

    this.editingBusinessUnit$ = this.store.select(getEditingBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.sbuid = bu?.businessUnitId || '';
    });

    this.dealId$ = this.store.select(getSelectedDealId).subscribe((dealId: string) => {
      this.dealId = dealId || '';
    });
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.editingBusinessUnit$.unsubscribe();
    this.dealId$.unsubscribe();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(Auth.currentSession().then((data: any) => {
      return data
    }).catch(() => null))
      .pipe(retry(this.DEFAULT_MAX_RETRIES),
        switchMap((auth: any) => { // switchMap() is used instead of map().

          let req = request;
          if (auth != null && auth.accessToken != null) {
            let jwt = auth.accessToken.jwtToken;
            req = request.clone({
              setHeaders: {
                Authorization: `Bearer ${jwt}`,
                buid: this.buid,
                sbuid: this.sbuid,
                dealId: this.dealId
              }
            });
          }

          // console.log("Cloned",with_auth_request);
          return next.handle(req).pipe(tap({
            error: (err: HttpErrorResponse) => {
              let errStr = '';
              if (!!err && !!err.error && !!err.error.errors && err.error.errors.length > 0) {
                errStr = err?.error?.errors[0];
              }
              switch (err.status) {
                case 0:
                  this.toastr.info("There was an issue connecting with Rondeivu. Please try again in a few minutes.", "Rondeivu Connection", {disableTimeOut: true});
                  break;
                case 415:
                  this.toastr.warning("The media type is not supported.", "Rondeivu Exception", {disableTimeOut: true});
                  break;
                case 400:
                case 401:
                  this.util.logOut().then(() => {
                    this.toastr.error("Access Denied.", "Rondeivu Exception", {disableTimeOut: true});
                  });
                  break;
                case 402:
                case 403:
                case 406:
                  this.toastr.warning(errStr, "Rondeivu Exception", {disableTimeOut: true});
                  break;
                case 404:
                  this.toastr.warning("You may not be authorized to view this object. Please contact Rondeivu if the issue persists.", "Rondeivu Authorization Exception", {disableTimeOut: true});
                  break;
                case 504:
                  this.toastr.error("Request timed out.  Please try again in a few minutes.", "Rondeivu Exception", {disableTimeOut: true});
                  break;
                case 500:
                default:
                  this.toastr.error(err.message, "Server Error");
              }
            }
          }));
        })
      );
  }
}
