import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {
  Config,
  getConfig,
  getLookups,
  initialConfigState,
  initialLookupsState,
  Lookups,
  toggleLoading
} from "../../../redux";
import {FormControl, FormGroup} from "@angular/forms";
import {UtilService} from "../../../shared/services";
import {Store} from "@ngrx/store";
import {UuidService} from "../../../../services";
import {ToastrService} from "ngx-toastr";
import {SubdocService} from "../../services/subdoc/subdoc.service";
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import {ISyndicationSubdoc} from "../../models/syndication";
import {HttpErrorResponse} from "@angular/common/http";
import {DeleteConfirmationModalComponent} from "../../../shared/components";

@Component({
  selector: 'app-deal-subscription-subdoc-edit-modal',
  templateUrl: './deal-subscription-subdoc-edit-modal.component.html',
  styleUrls: ['./deal-subscription-subdoc-edit-modal.component.scss']
})
export class DealSubscriptionSubdocEditModalComponent implements OnInit {
  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;


  userSource: MatTableDataSource<ISubdocUser> = new MatTableDataSource();
  fileSource: MatTableDataSource<ISubdocFile> = new MatTableDataSource();

  userColumns = ['name', 'email', 'profile', 'emailStatus', 'sigStatus'];
  fileColumns = ['name', 'size', 'uploadedBy', 'uploadedOn', 'actions'];

  isModified = false;


  protected readonly FORM_STATUS = 'status';
  protected readonly FORM_USERS = 'subDocAccessUser';
  protected readonly FORM_FILES = 'uploadedFiles';

  form = new FormGroup({
    status: new FormControl(),
    subDocAccessUser: new FormControl(),
    uploadedFiles: new FormControl(),
  });

  @ViewChild('fileUpload') private fileInput: ElementRef | undefined;

  constructor(
    public util: UtilService,
    private store: Store,
    private uuid: UuidService,
    private toastr: ToastrService,
    private subDocService: SubdocService,
    private router: Router,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DealSubscriptionSubdocEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { subdoc: ISyndicationSubdoc }) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });
  }


  ngOnInit(): void {
    if (!!this.data) {
      this.getSubdoc();
    }
  }

  getSubdoc() {
    console.log("Fetching SubDoc...");
    this.store.dispatch(toggleLoading({loading: true}));
    this.subDocService.getSubdocById(this.data.subdoc.id).subscribe({
      next: (subdoc: ISubdocResponse) => {
        // console.log(subdoc);
        this.form.get(this.FORM_STATUS)?.setValue(subdoc.status);
        this.form.get(this.FORM_USERS)?.setValue(subdoc.subDocAccessUser);
        this.form.get(this.FORM_FILES)?.setValue(subdoc.uploadedFiles);
        this.userSource = new MatTableDataSource(subdoc.subDocAccessUser);
        this.fileSource = new MatTableDataSource(subdoc.uploadedFiles);
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: err => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get subdocs!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  submit(): void {
    // this.dialogRef.close(this.form.getRawValue());
  }

  saveStatus() {
    let status = this.form.get(this.FORM_STATUS)?.value || '';
    console.log("Updating SubDoc Status...");
    this.store.dispatch(toggleLoading({loading: true}));
    this.subDocService.updateSubdocStatus(this.data.subdoc.id, {status: status}).subscribe({
      next: (res: any) => {
        this.getSubdoc();
        this.isModified = true;
        this.toastr.success("Status Updated!", $localize`:@@companyName:Rondeivu`);
      }, error: err => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to update subdoc status!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  downloadAll() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.subDocService.downloadDocumentZip(this.data.subdoc.id).subscribe({
      next: (res: any) => {
        let filename = 'download.zip';
        let blob: Blob = res as Blob;
        let a = document.createElement('a');
        a.download = filename;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.store.dispatch(toggleLoading({loading: false}));
      },
      error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to download files!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  downloadSubdoc(element: ISubdocFile) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.subDocService.downloadFile(element.id).subscribe({
      next: (res: any) => {
        let filename = element.filename;
        let blob: Blob = res as Blob;
        let a = document.createElement('a');
        a.download = filename;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.store.dispatch(toggleLoading({loading: false}));
      },
      error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to download file!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  deleteSubdoc(element: ISubdocFile) {
    const delRef = this.dialog.open(DeleteConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      data: {
        file: element
      },
      disableClose: true
    });

    delRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.store.dispatch(toggleLoading({loading: true}));
        this.subDocService.deleteFile(result.file.id).subscribe({
          next: (res: any) => {
            this.getSubdoc();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("File Deleted", $localize`:@@companyName:Rondeivu`);
          }, error: (err: any) => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.warning("Unable to delete document!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  /**
   * Action for adding a new file
   * @param event
   */
  addDocuments(event: any) {
    // console.log(event);
    if (!!event && !!event.target && !!event.target.files[0]) {
      this.uploadSupportingFile(event.target.files[0]);
      if (!!this.fileInput) {
        this.fileInput.nativeElement.value = '';
      }
    }
  }

  private uploadSupportingFile(file: any) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.subDocService.uploadSubdocDocument(this.data.subdoc.id, file).subscribe({
      next: (file: ISubdocFile) => {
        let files = this.form.get(this.FORM_FILES)?.value || [];
        files.push(file);
        this.form.get(this.FORM_FILES)?.setValue(files);
        this.fileSource = new MatTableDataSource(files);
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("File Uploaded!", $localize`:@@companyName:Rondeivu`);
      }, error: (err: HttpErrorResponse) => {
        console.log(err);
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to upload file!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

}


interface ISubdocResponse {
  amount: number;
  created: string;
  dealId: string;
  entityName: string;
  entityType: string;
  id: string;
  showInvestorFillDataAndApproveButton: boolean;
  showIssuerApprovalButton: boolean;
  showRondeivuApprovalButton: boolean;
  status: string;
  subDocAccessUser: ISubdocUser[];
  subscriptionAmount: number;
  subscriptionDate: string;
  subscriptionId: string;
  uploadedFiles: ISubdocFile[];
}

interface ISubdocUser {
  id: string;
  fullName: string;
  email: string;
  profile: string;
  emailStatus: string;
  signatureStatus: string;
  sortOrder: number;
  accessType: string;
}

interface ISubdocFile {
  id: string;
  filename: string;
  fileSize: string;
  uploadedBy: {
    id: string;
    firstName: string;
    lastName: string;
    imageUrl: string;
    created: string;
  };
  created: string;
}


