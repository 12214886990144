<div id="deal-card" [class]="getSideClass()" [@cardFlip]="side" (mouseenter)="hovering=true;"
     (mouseleave)="hovering=false;">

  <div class="col-12" *ngIf="side==0">
    <!--    the pills-->
    <!--    <div class="pills">&nbsp;-->
    <!--      <span *ngFor="let p of deal.keywords" class="rounded-pill"-->
    <!--            [ngStyle]="{'background-color':p.bg_color, 'color':p.color}">{{p.title}}</span>-->
    <!--    </div>-->
    <!--    the image-->
    <div id="card-img-top">
      <img id="img-top" [class]="getImgClass()" [src]="deal.dealImageUrl"
           [default]="'/assets/images/image-placeholder.png'">
    </div>
    <!--    the pills-->
    <div id="pill-container">
      <div id="card-pills" *ngFor="let p of deal.pills"
           class="rounded-pill" [title]="p.tooltip" 
           [ngStyle]="{'background-color': p.backgroundColor, 'color':p.fontColor, 'border-color': p.borderColor}"
      ><span style="font-size: 10px; width: 100%;">{{p.name.toUpperCase()}}</span></div>
    </div>

    <div id="deal-card-body">
      <!--      title-->
      <div id="card-title">
        <span style="font-size: large;"><b>{{deal.projectName}}</b></span><br/>
        <span style="font-size: small; color: grey">{{deal.assetName || ""}}</span><br/>
        <!--        <hr><br/>-->
      </div>

      <!--      description-->
      <div id="card-desc">
        <span [innerHTML]="deal.description"></span>
        <!--        <div class="col-12 col-right" *ngIf="deal.status != 'CLOSED'">-->
        <!--          <span class="read-more" (click)="cardClicked()">Read More</span>-->
        <!--        </div>-->
      </div>

    </div>
<!--   TIMELINE START-->
    <div id="card-tl">
<!--      timeline component-->
      <app-timeline *ngIf="!!deal.dealTimeline && deal.dealCloseType == 'DRAWDOWN'"
                    [dealTimeline]="deal.dealTimeline"></app-timeline>
<!--      For EVERGREEN deals show a title-->
<!--      <div class="row justify-content-center" *ngIf="deal.dealCloseType == 'EVERGREEN'">-->
<!--        <div class="col-11" style="display: flex; justify-content: center;">-->
<!--          <div style="display:flex; vertical-align: middle;">-->
<!--            <mat-icon style="color: darkgreen;">recycling</mat-icon>&nbsp;-->
<!--            <span style="font-size:large;color: green;padding-top: 2px;">{{deal.dealCloseType?.toUpperCase()}}</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <!--      <div class="row justify-content-center" *ngIf="deal.dealWorkflow == 'PENDING'">-->
      <!--        <div class="col-10" style="display: inline-flex; vertical-align: middle;">-->
      <!--          <mat-icon style="color: darkblue;">schedule</mat-icon>-->
      <!--          <span style="font-size: large; padding-top:2px;">Pending Deal</span>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
<!--    TIMELINE END-->
    <!--      buttons-->
    <div id="card-buttons">
      <button class="edit-btn" mat-icon-button (click)="cardClicked()" [disabled]="!canFlip">
        <mat-icon [title]="canFlip? 'Flip for more information' : ''">flip</mat-icon>
      </button>
      <button class="view-btn" mat-button title="View Deal"
        (click)="navToDeal(deal.id)" [disabled]="!canView">View Deal</button>
    </div>
  </div>
  <!--  -->
  <!--  -->
  <!--  -->
  <!-- BACK CONTENT  -->
  <!--  -->
  <!--  -->
  <!--  -->
  <div class="back-content" *ngIf="side==1">
    <div>
      <div id="back-header">
        <span style="font-size: large;"><b>{{deal.projectName}}</b></span><br/>
        <span style="font-size: small; color: grey">{{deal.assetName || " "}}</span>
      </div>
      <br/>
      <br/>
      <div class="row justify-content-center" style="height:50px; max-height: 50px;">
        <div class="col-12" *ngIf="deal.dealCloseType != 'EVERGREEN'">
          <mat-progress-bar id="progress-raised" 
                            color="accent"
                            [value]="deal.percentFundRaise"
                            [title]="deal.projectName + ' has raised ' + deal.percentFundRaise + '% of a the total amount.'"
                            >
          </mat-progress-bar>
          <div class="col-12 text-center">
            <span class="text-muted">{{deal.percentFundRaise + '% raised'}}</span>
          </div>
  
        </div>
      </div>
      <br/>
      <br/>
      <br/>
      <div class="row">
        <div class="col-12 text-center">
          <b class="muted-text">Multiple on Invested Capital (MOIC)</b>
        </div>
      </div>
      <div class="row justify-content-center pt-2">
        <div class="col-3 inner-well">
          <span style="font-size: small; color: grey;">Bear</span><br/>
          <span style="font-size: x-large; color: black;">{{deal.targetMoicBear}}x</span><br/>
        </div>
        <div class="col-3 inner-well">
          <span style="font-size: small; color: grey;">Base</span><br/>
          <span style="font-size: x-large; color: black;">{{deal.targetMoicBase}}x</span><br/>
        </div>
        <div class="col-3 inner-well">
          <span style="font-size: small; color: grey">Best</span><br/>
          <span style="font-size: x-large; color: black;"> {{deal.targetMoicBest}}x</span><br>
        </div>
      </div>
      <br/>
      <div class="row">
        <div class="col-12 text-center">
          <b class="muted-text">Investment Timeline</b>
        </div>
      </div>
      <div class="row justify-content-center pt-2">
        <div class="col-3 inner-well">
          <span style="font-size: small; color: grey;">Min.</span><br>
          <span style="font-size: x-large; color: black;">{{deal.minimumDuration}} yrs</span><br>
        </div>
        <div class="col-3 inner-well">
          <span style="font-size: small; color: grey;">Expected</span><br>
          <span style="font-size: x-large; color: black;">{{deal.expectedDuration}} yrs</span><br>
        </div>
        <div class="col-3 inner-well">
          <span style="font-size: small; color: grey;">Max.</span><br>
          <span style="font-size: x-large; color: black;"> {{deal.maximumDuration}} yrs</span><br>
        </div>
      </div>
      <br/>
    </div>
    <div style="height: 36px; padding-top: 5px;">
      <div class="row">
        <!--      buttons-->
        <div>
          <button style="margin-left: -15px;" mat-icon-button (click)="cardClicked()" [disabled]="!canFlip">
            <mat-icon title="Flip card">flip</mat-icon>
          </button>
          <button class="view-btn" mat-button title="View Deal"
            (click)="navToDeal(deal.id)" [disabled]="!canView">View Deal</button>
        </div>
      </div>
    </div>
  </div>
</div>

