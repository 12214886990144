import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {
  Config, fetchTasks,
  getConfig,
  getLookups,
  getSelectedBusinessUnit, getSelectedEntityId,
  initialConfigState,
  initialLookupsState,
  Lookups, toggleLoading
} from "../../../redux";
import {IBusinessUnit} from "../../../business-units/models";
import {UserCardSize} from "../../../shared/components";
import {FormControl, FormGroup} from "@angular/forms";
import {Store} from "@ngrx/store";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {UtilService} from "../../../shared/services";
import {EntitiesService} from "../../services/entities.service";
import {IBusinessUnitEntity} from "../../models/entity";
import {ILookup} from "../../../../models";
import { LookupService } from 'src/app/services';

@Component({
  selector: 'app-entity-information',
  templateUrl: './entity-information.component.html',
  styleUrls: ['./entity-information.component.scss']
})
export class EntityInformationComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  selectedEntityId$: Subscription = new Subscription();

  private config: Config = initialConfigState;
  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
  entityId: string = '';

  userCardSize = UserCardSize;

  //reactive form
  entityForm = new FormGroup({
    id: new FormControl(),
    businessUnit: new FormControl(),
    entityName: new FormControl(),
    entityType: new FormControl(),
    domicile: new FormControl(),
  });

  constructor(
    private store: Store,
    // private router: Router,
    private toastr: ToastrService,
    private entitiesService: EntitiesService,
    private activatedRoute: ActivatedRoute,

    // private dialog: MatDialog,
    public lookupService: LookupService,
    public util: UtilService,
  ) {
    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe(businessUnit => {
      this.selectedBusinessUnit = businessUnit;
    });

    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });

    // this.selectedEntityId$ = this.store.select(getSelectedEntityId).subscribe(entityId => {
    //   this.entityId = entityId;
    //   if (!!this.selectedBusinessUnit && !!this.selectedBusinessUnit.businessUnitId) {
    //     this.subscribeToDatasource();
    //   }
    // });

    this.entityId = this.activatedRoute.parent?.snapshot.paramMap.get('id') as string;
    this.subscribeToDatasource();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    // this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedEntityId$.unsubscribe();
  }

  subscribeToDatasource(): void {
    this.entitiesService.getBusinessUnitEntityById(this.entityId).subscribe({
      next: (entity: IBusinessUnitEntity) => {
        console.log("Entity", entity);
        this.entityForm.patchValue(entity);
      }, error: error => {
        this.toastr.error("Something went wrong!");
      }
    });
  }

  save() {
    let payload: any = {
      id: this.entityForm.get('id')?.value,
      businessUnitId: this.entityForm.get('businessUnit')?.value?.id,
      entityName: this.entityForm.get('entityName')?.value,
      entityType: this.entityForm.get('entityType')?.value,
      domicile: this.entityForm.get('domicile')?.value
    };

    console.log("SAVE", payload);
    // console.log(this.entityForm.value);
    // return;


    this.store.dispatch(toggleLoading({loading: true}));
    this.entitiesService.updateBusinessUnitEntity(payload).subscribe({
      next: (entity: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Entity Information Saved!", $localize`:@@companyName:Rondeivu`);
        this.subscribeToDatasource();
        this.store.dispatch({type: fetchTasks});
      }, error: error => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Something went wrong!");
      }
    });
  }

  getEntityTypeString(entity: IBusinessUnitEntity) : string {
    if (!!entity && !!entity.businessUnit) {
      // for admin look at the business unit type of the entity
      if (entity.businessUnit.profile.toLowerCase() == 'investor') {
        return this.lookupService.getLookupValueForKey(this.lookupService.lookups.kycInvestorTypes, entity.entityType);
      } else if (entity.businessUnit.profile.toLowerCase() == 'issuer') {
        return this.lookupService.getLookupValueForKey(this.lookupService.lookups.kycIssuerTypes, entity.entityType);
      }
    }
    return '';
  }

  getEntityTypeList(entity: IBusinessUnitEntity) : ILookup[] {
    if (!!entity && !!entity.businessUnit) {
      // for admin look at the business unit type of the entity
      if (entity.businessUnit.profile.toLowerCase() == 'investor') {
        return this.lookupService.lookups.kycInvestorTypes;
      } else if (entity.businessUnit.profile.toLowerCase() == 'issuer') {
        return this.lookupService.lookups.kycIssuerTypes;
      }
    }
    return [];
  }

}
