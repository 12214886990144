<div class="rm-header">
  <span class="">Phone 2FA Verification</span>
</div>
<div class="container">
  <mat-dialog-content class="mat-typography">
    <!--  <span>You are about to apply Multi-Factor Authentication (MFA) to your account. On your next login you will be prompted to input a code sent to-->
    <!--    <span style="color: dodgerblue">{{userForm.get('countryCode')?.value + userForm.get('mobile')?.value}}</span>. Click verify to apply MFA with this phone number.-->
    <!--  </span>-->
    <div style="padding-bottom: 10px;">
      <app-code-input [loading]="loading"
                      (onPasteError)="pasteError('Phone Code Error: ',$event);"
                      (onReset)="dialogRef.close()"
                      (onNext)="confirm($event)"
                      [backTitle]="'Cancel'"
                      [nextTitle]="'VERIFY'"
      >
        <span>We sent a code via SMS to <span
          style="color: dodgerblue">{{ phoneForm.get('countryCode')?.value + ' ' + phoneForm.get('mobile')?.value }}</span>, please enter the code above to verify your phone number.</span>
      </app-code-input>
    </div>
  </mat-dialog-content>
</div>


