import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {Subscription} from "rxjs";
import {IBusinessUnit, IBusinessUnitEmployee} from "../../models";
import {MatTableDataSource} from "@angular/material/table";
import {
  Config,
  getConfig,
  getEditingBusinessUnit,
  getLookups,
  getSelectedBusinessUnit,
  initialConfigState,
  initialLookupsState,
  initialThemeState,
  Lookups,
  Theme, toggleLoading
} from "../../../redux";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {ToastrService} from "ngx-toastr";
import {Store} from "@ngrx/store";
import {MatDialog} from "@angular/material/dialog";
import {
  DeleteConfirmationModalComponent, KycModalComponent, UserCardSize
} from "../../../shared/components";
import {Router} from "@angular/router";
import {KycService, UtilService} from "../../../shared/services";
import {HttpErrorResponse} from "@angular/common/http";
import {ModalSize} from "../../../shared/services/util/util.service";
import {EntityKycService} from "../../../entities/services/entity-kyc.service";
import {toggleNavigating} from "../../../redux/stores/config/config.actions";
import {IBusinessUnitEntity} from "../../../entities/models/entity";

@Component({
  selector: 'app-business-unit-kyc',
  templateUrl: './business-unit-kyc.component.html',
  styleUrls: ['./business-unit-kyc.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BusinessUnitKycComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;
  theme: Theme = initialThemeState;
  selectedBusinessUnit$: Subscription = new Subscription();
  editingBusinessUnit$: Subscription = new Subscription();

  buRequest: IBusinessUnit = {} as unknown as IBusinessUnit;

  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  entityKycSource: MatTableDataSource<any> = new MatTableDataSource();
  entityKycColumns = ['entityName', 'entityType', 'kycName', 'kycStatus', 'created', 'actions'];
  entityKycList: any[] = [];
  entityKycListSorted: any[] = [];

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  adminBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;

  userCardSize = UserCardSize;

  constructor(private kycService: KycService,
              private toastr: ToastrService,
              private store: Store,
              private dialog: MatDialog,
              private router: Router,
              private entityKycService: EntityKycService,
              public util: UtilService
  ) {
    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
      this.buRequest = Object.assign({}, config.editing_business_unit);
    });
    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.adminBusinessUnit = Object.assign({}, bu);
    });

    this.editingBusinessUnit$ = this.store.select(getEditingBusinessUnit).subscribe((sbu: IBusinessUnit) => {
      if (!!sbu && this.selectedBusinessUnit.businessUnitId != sbu.businessUnitId) {
        this.selectedBusinessUnit = Object.assign({}, sbu);
        if (!!this.adminBusinessUnit && !!this.adminBusinessUnit.businessUnitId &&
          !!this.selectedBusinessUnit && !!this.selectedBusinessUnit.businessUnitId &&
          this.adminBusinessUnit.businessUnitType == 'ADMIN') {
          this.subscribeToDatasource();
        }
      }
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.editingBusinessUnit$.unsubscribe();
  }

  subscribeToDatasource(): void {
    console.log("Fetching Business Unit KYC...");
    this.getKycs();
    this.getEntityKyc();
  }

  /**
   * Main data fetch
   */
  getKycs() {
    this.kycService.getKycs().subscribe({
      next: (kyc: any[]) => {
        if (kyc.length > 1) {
          this.toastr.warning("More than one business unit returned!", $localize`:@@companyName:Rondeivu`);
        }
        this.dataSource = new MatTableDataSource(kyc);
      }, error: (err: HttpErrorResponse) => {
        this.toastr.error("Unable to get KYC information!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  getEntityKyc() {
    this.entityKycService.getEntityKyc().subscribe({
      next: (kyc: any[]) => {
        this.entityKycList = kyc;
        this.entityKycSource = new MatTableDataSource<any>(this.entityKycList);
        this.initDataSource();
      }, error: (err: HttpErrorResponse) => {
        this.toastr.error("Unable to get entity KYC!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  initDataSource() {
    this.entityKycSource.filterPredicate = (data: any, filter: string) => {
      const lc_filter = filter.toLowerCase();
      let EN = !!data.businessUnitEntityName ? data.businessUnitEntityName?.toLowerCase().includes(lc_filter) : false;
      let ET = !!data.entityType ? data.entityType?.toLowerCase().includes(lc_filter) : false;
      let KN = !!data.kycName ? data.kycName?.toLowerCase().includes(lc_filter) : false;
      let KS = !!data.kycRondeivuStatus ? data.kycRondeivuStatus?.toLowerCase().includes(lc_filter) : false;

      return EN || ET || KN || KS;
    };
    if (!!this.paginator) {
      this.paginator.pageSize = 10;
      this.entityKycSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.entityKycSource.sort = this.sort;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.entityKycSource.filter = filterValue.trim();

    if (this.entityKycSource.paginator) {
      this.entityKycSource.paginator.firstPage();
    }
  }

  /**
   * Adds a kyc process to this business unit
   * @param kycObject
   */
  addKyc(kycObject?: any) {
    // if this is the first kyc we take the profile from the editing business unit
    let profile = this.config.editing_business_unit.businessUnitType;
    if (!!kycObject) {
      profile = kycObject.businessUnit?.profile;
    }

    const delRef = this.dialog.open(KycModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      disableClose: true,
      data: {
        isEditing: false,
        kyc: {},
        profile: profile
      }
    });

    delRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.store.dispatch(toggleLoading({loading: true}));
        this.kycService.addKyc(result).subscribe({
          next: (res: any) => {
            this.subscribeToDatasource();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("KYC Initiated!", $localize`:@@companyName:Rondeivu`);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to add kyc!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  /**
   * Deletes a KYC process from this business unit
   * @param kycProcess
   */
  delete(kycProcess: any) {
    const delRef = this.dialog.open(DeleteConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      data: kycProcess
    });

    delRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.store.dispatch(toggleLoading({loading: true}));
        this.kycService.deleteKyc(kycProcess).subscribe(res => {
          this.subscribeToDatasource();
          this.store.dispatch(toggleLoading({loading: false}));
          this.toastr.success("KYC Removed!", $localize`:@@companyName:Rondeivu`);
        }, err => {
          this.store.dispatch(toggleLoading({loading: false}));
          this.toastr.error("Unable to remove kyc!", $localize`:@@companyName:Rondeivu`);
        });
      }
    });
  }

  /**
   * Navigate to the entity kyc
   * @param element
   */
  viewEntityKyc(element: any) {
    this.store.dispatch(toggleNavigating({navigating: true}));
    setTimeout(() => {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/entities/' + element.businessUnitEntityId + '/kyc/' + element.businessUnitEntityKycId]).then(() => {
        this.store.dispatch(toggleNavigating({navigating: false}));
      });
    }, 1000);
  }

  /**
   * Updates the business unit kyc status
   * @param event
   */
  updateStatus(event: any) {
    let payload = {
      status: event.value
    };
    this.store.dispatch(toggleLoading({loading: true}));
    this.kycService.updateBusinessUnitKycStatus(payload).subscribe((res: any) => {
      this.store.dispatch(toggleLoading({loading: false}));
      this.toastr.success("KYC Status Updated!", $localize`:@@companyName:Rondeivu`);
    }, (err: any) => {
      this.store.dispatch(toggleLoading({loading: false}));
      this.toastr.error("Unable to update kyc status!", $localize`:@@companyName:Rondeivu`);
    });
  }

  /**
   * Get the entity kyc status from lookup
   * @param key
   */
  getStatusString(key: string) {
    if (!!this.config) {
      return this.util.getLookupValueForKey(this.lookups.kycRondeivuStatus, key);
    }
    return '';
  }

  /**
   * Get the entity type from string
   * @param key
   */
  getEntityTypeString(key: string) {
    if (!!this.config) {
      return this.util.getLookupValueForKey(this.lookups.businessUnitEntityType, key);
    }
    return '';
  }

  //sort
  sortData(sort: Sort) {
    const data = this.entityKycList.slice();
    if (!sort.active || sort.direction === '') {
      this.entityKycListSorted = data;
      return;
    }

    this.entityKycListSorted = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'entityName':
          return compare(a.businessUnitEntityName, b.businessUnitEntityName, isAsc);
        case 'entityType':
          return compare(a.entityType, b.entityType, isAsc);
        case 'kycName':
          return compare(a.kycName, b.kycName, isAsc);
        case 'kycStatus':
          return compare(a.kycRondeivuStatus, b.kycRondeivuStatus, isAsc);
        case 'created':
          return compare(a.created, b.created, isAsc);
        default:
          return 0;
      }
    });

    this.entityKycSource = new MatTableDataSource(this.entityKycListSorted);
    this.initDataSource();
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
