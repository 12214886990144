import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {
  Config,
  getConfig,
  getLookups, getSelectedEntityKyc,
  initialConfigState,
  initialLookupsState,
  Lookups, toggleLoading, updateSelectedEntityKyc,
} from "../../../redux";
import {FormControl, FormGroup} from "@angular/forms";
import {ConfirmationModalComponent, DeleteConfirmationModalComponent, UserCardSize} from "../../../shared/components";
import {UtilService} from "../../../shared/services";
import {ModalSize} from "../../../shared/services/util/util.service";
import {KycPersonModalComponent} from "../kyc-person-modal/kyc-person-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {KycPersonSelectModalComponent} from "../kyc-person-select-modal/kyc-person-select-modal.component";
import {KycBusinessModalComponent} from "../kyc-business-modal/kyc-business-modal.component";
import {KycBusinessSelectModalComponent} from "../kyc-business-select-modal/kyc-business-select-modal.component";
import {
  IEntityKyc,
  IKycAnswer,
  IEntityKycError,
  IKycQuestion,
  IKycSection
} from "../../models/entity";
import {EntityKycPersonService} from "../../services/entity-kyc-person.service";
import {ToastrService} from "ngx-toastr";
import {IKycEntityImportPerson, IKycPerson} from "../../models/i-kyc-person";
import {EntityKycService} from "../../services/entity-kyc.service";
import {EntityKycBusinessService} from "../../services/entity-kyc-business.service";
import {IKycEntityImportLegalEntity, IKycLegalEntity} from "../../models/i-kyc-legal-entity";
import {Router} from "@angular/router";
import {Level, ModalFunction, IQuestionAnswer, SectionType} from "../../models/kyc";
import {LookupService, UuidService} from "../../../../services";
import {KycImportModalComponent} from "../kyc-import-modal/kyc-import-modal.component";

@Component({
  selector: 'app-kyc-input',
  templateUrl: './kyc-input.component.html',
  styleUrls: ['./kyc-input.component.scss']
})
export class KycInputComponent implements OnInit, OnDestroy {

  protected readonly SectionType = SectionType;
  protected readonly Level = Level;
  protected readonly userCardSize = UserCardSize;
  protected readonly IN_USE_COLOR = '#0e41af';
  protected readonly NOT_IN_USE_COLOR = '#ffa516';
  protected readonly IN_USE_TEXT = 'Currently used in this KYC';
  protected readonly NOT_IN_USE_TEXT = 'Not used in this KYC';
  formDisabled = false; // this is used to test the form lock state
  step: string = 'ONBOARDING';

  config$: Subscription = new Subscription();
  // lookup$: Subscription = new Subscription();
  selectedEntityKyc$: Subscription = new Subscription();

  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;
  selectedEntityKycId: string = '';
  selectedEntityKyc: IEntityKyc = {} as unknown as IEntityKyc;
  openState = true;
  formDirty = false;
  showCertificationOnly = false;

  /*
   This form is for:
    PUT /api/businesssunitentitykyc
   */
  form: FormGroup = new FormGroup({
    id: new FormControl(),
    archived: new FormControl(),
    entityType: new FormControl(),
    kycRondeivuStatus: new FormControl(),
    kycName: new FormControl(''),
    publicComments: new FormControl(''),
    privateComments: new FormControl(''),
    onboardingPeople: new FormControl([]), // {id:string}
    onboardingEntities: new FormControl([]), // {id:string,natureoftheBusiness:string,businessSourceofFund:string}
    controlPeople: new FormControl([]),// {id:string}
    regulatoryPeople: new FormControl([]), // {id:string,ownerShip:number}
    regulatoryEntities: new FormControl([]), // {id:string,ownerShip:number}
    dboPeople: new FormControl([]), // {id:string,ownerShip:number}
    dboEntities: new FormControl([]), // {id:string,ownerShip:number}
    idboPeople: new FormControl([]), // {id:string,ownerShip:number}
    idboEntities: new FormControl([]), // {id:string,ownerShip:number}
    trusteePeople: new FormControl([]), // {id:string}
    trusteeEntities: new FormControl([]), // {id:string}
    trustSettlorPeople: new FormControl([]), // {id:string}
    trustSettlorEntities: new FormControl([]), // {id:string}
    trustBeneficiariesPeople: new FormControl([]), // {id:string}
    trustBeneficiariesEntities: new FormControl([]), // {id:string}
    governingBodyPeople: new FormControl([]), // {id:string}
    governingBodyEntities: new FormControl([]), // {id:string}
    authorizedSignatoryPeople: new FormControl([]), // {id:string,ownerShip:number}
    primaryContacts: new FormControl([]), // {id:string,firstName:string,lastName:string,email:string}
    directors: new FormControl([]), // {id:string}
    headerQuestionsAnswers: new FormControl([]), // {questionAnswerAttribute:string,questionAnswer:string}
    questionsAnswers: new FormControl([]) // {questionAnswerAttribute:string,questionAnswer:string}
  });

  protected readonly FORM_ARCHIVED = 'archived';
  protected readonly FORM_STATUS = 'kycRondeivuStatus';
  protected readonly FORM_ENT_TYPE = 'entityType';
  protected readonly FORM_PUB_COMM = 'publicComments';
  protected readonly FORM_PRIV_COMM = 'privateComments';
  protected readonly FORM_KYC_NAME = 'kycName';
  protected readonly FORM_OB_PEOPLE = 'onboardingPeople';
  protected readonly FORM_OB_ENT = 'onboardingEntities';
  protected readonly FORM_CTRL_PEOPLE = 'controlPeople';
  protected readonly FORM_REG_PEOPLE = 'regulatoryPeople';
  protected readonly FORM_REG_ENT = 'regulatoryEntities';
  protected readonly FORM_DBO_PEOPLE = 'dboPeople';
  protected readonly FORM_DBO_ENT = 'dboEntities';
  protected readonly FORM_IDBO_PEOPLE = 'idboPeople';
  protected readonly FORM_IDBO_ENT = 'idboEntities';
  protected readonly FORM_TRUST_PEOPLE = 'trusteePeople';
  protected readonly FORM_TRUST_ENT = 'trusteeEntities';
  protected readonly FORM_TRUST_SET_PEOPLE = 'trustSettlorPeople';
  protected readonly FORM_TRUST_SET_ENT = 'trustSettlorEntities';
  protected readonly FORM_TRUST_BEN_PEOPLE = 'trustBeneficiariesPeople';
  protected readonly FORM_TRUST_BEN_ENT = 'trustBeneficiariesEntities';
  protected readonly FORM_GB_PEOPLE = 'governingBodyPeople';
  protected readonly FORM_GB_ENT = 'governingBodyEntities';
  protected readonly FORM_AUTH_PEOPLE = 'authorizedSignatoryPeople';
  protected readonly FORM_PRIMARY_CONTACTS = 'primaryContacts';
  protected readonly FORM_DIRECTORS = 'directors';
  protected readonly FORM_HEADER_QUESTIONS = 'headerQuestionsAnswers';
  protected readonly FORM_QUESTIONS = 'questionsAnswers';

  protected readonly FORM_QUESTION_REG = 'RequlatoryQuestion_own_10_percent_or_more';
  protected readonly FORM_QUESTION_REG_2 = 'RequlatoryQuestion_authorized_signatories_a_director_of_OTC'
  protected readonly FORM_QUESTION_DBO = 'DBOQuestion_Control_Person_listed_above_is_also_a_Direct_Beneficial_Owner';
  protected readonly FORM_QUESTION_IDBO = 'IDBOQuestion_No_Owners';


  // RIGHT SIDE PANEL - stores a list of
  formCache: FormGroup = new FormGroup({
    people: new FormControl([]), // kyc people
    businesses: new FormControl([]), // kyc legal entities
  });

  protected readonly FORM_CACHE_PEOPLE = 'people';
  protected readonly FORM_CACHE_BUSINESSES = 'businesses';

  constructor(
    private store: Store,
    public util: UtilService,
    private uuid: UuidService,
    private dialog: MatDialog,
    public lookupService: LookupService,
    private toastr: ToastrService,
    private router: Router,
    private entityKycService: EntityKycService,
    private entityKycBusinessService: EntityKycBusinessService,
    private entityKycPersonService: EntityKycPersonService
  ) {
    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
      this.openState = !!util.screenWidth ? (util.screenWidth >= util.MOBILE_WIDTH) : true;
    });
    // this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
    //   this.lookups = lookups;
    //   // this.kycTypes.concat(this.lookups.kycIssuerTypes);
    //   // this.kycTypes.concat(this.lookups.kycInvestorTypes);
    // });

    this.selectedEntityKyc$ = this.store.select(getSelectedEntityKyc).subscribe((kyc: IEntityKyc) => {
      this.selectedEntityKyc = kyc
      this.selectedEntityKycId = kyc.id;

      console.log("Selected KYC", kyc);
      this.fetchPeople();
      this.fetchLegalEntities();
      this.patchFormFromEntityKyc();
    });
  }

  private patchFormFromEntityKyc() {
    // patch the form collections
    this.form.get('id')?.patchValue(this.selectedEntityKycId);
    this.form.get(this.FORM_ARCHIVED)?.patchValue(this.selectedEntityKyc.archived);
    this.form.get(this.FORM_STATUS)?.patchValue(this.selectedEntityKyc.kycRondeivuStatus);
    this.form.get(this.FORM_ENT_TYPE)?.patchValue(this.selectedEntityKyc.entityType);
    this.form.get(this.FORM_PUB_COMM)?.patchValue(this.selectedEntityKyc.publicComments);
    this.form.get(this.FORM_PRIV_COMM)?.patchValue(this.selectedEntityKyc.privateComments);

    if (!this.util.isType('admin')) {
      this.form.get(this.FORM_PRIV_COMM)?.disable();
    }

    this.form.get(this.FORM_KYC_NAME)?.patchValue(this.selectedEntityKyc.kycName);

    if (this.isFormDisabled()) {
      this.form.get(this.FORM_PUB_COMM)?.disable();
      this.form.get(this.FORM_PRIV_COMM)?.disable();
      this.form.get(this.FORM_KYC_NAME)?.disable();
    }
    this.addKycPeopleToPropFromSection(this.FORM_OB_PEOPLE, SectionType.ONBOARDING);
    this.addKycLegalEntitiesToPropFromSection(this.FORM_OB_ENT, SectionType.ENTITY_INFORMATION);
    this.addKycPeopleToPropFromSection(this.FORM_CTRL_PEOPLE, SectionType.CONTROL_PERSON);
    this.addKycPeopleToPropFromSection(this.FORM_REG_PEOPLE, SectionType.REGULATORY_QUESTIONS_1);
    this.addKycLegalEntitiesToPropFromSection(this.FORM_REG_ENT, SectionType.REGULATORY_QUESTIONS_2);
    this.addKycPeopleToPropFromSection(this.FORM_DBO_PEOPLE, SectionType.DIRECT_BENEFICIAL_OWNERS);
    this.addKycLegalEntitiesToPropFromSection(this.FORM_DBO_ENT, SectionType.DIRECT_BENEFICIAL_OWNERS);
    this.addKycPeopleToPropFromSection(this.FORM_IDBO_PEOPLE, SectionType.INDIRECT_BENEFICIAL_OWNERS);
    this.addKycLegalEntitiesToPropFromSection(this.FORM_IDBO_ENT, SectionType.INDIRECT_BENEFICIAL_OWNERS);
    this.addKycPeopleToPropFromSection(this.FORM_TRUST_PEOPLE, SectionType.TRUSTEES);
    this.addKycLegalEntitiesToPropFromSection(this.FORM_TRUST_ENT, SectionType.TRUSTEES);
    this.addKycPeopleToPropFromSection(this.FORM_TRUST_SET_PEOPLE, SectionType.TRUST_SETTLORS);
    this.addKycLegalEntitiesToPropFromSection(this.FORM_TRUST_SET_ENT, SectionType.TRUST_SETTLORS);
    this.addKycPeopleToPropFromSection(this.FORM_TRUST_BEN_PEOPLE, SectionType.TRUST_BENEFICIARIES);
    this.addKycLegalEntitiesToPropFromSection(this.FORM_TRUST_BEN_ENT, SectionType.TRUST_BENEFICIARIES);
    this.addKycPeopleToPropFromSection(this.FORM_GB_PEOPLE, SectionType.GOVERNING_BODY);
    this.addKycLegalEntitiesToPropFromSection(this.FORM_GB_ENT, SectionType.GOVERNING_BODY);
    this.addKycPeopleToPropFromSection(this.FORM_AUTH_PEOPLE, SectionType.AUTHORIZED_SIGNATORIES);
    this.addKycPeopleToPropFromSection(this.FORM_PRIMARY_CONTACTS, SectionType.PRIMARY_CONTACT);
    this.addKycPeopleToPropFromSection(this.FORM_DIRECTORS, SectionType.DIRECTORS);


    // set the current header question answers to the form
    let headerQuestions: IKycAnswer[] = [];
    if (!!this.selectedEntityKyc && !!this.selectedEntityKyc.kycHeader) {
      if (!!this.selectedEntityKyc.kycHeader.kycQuestions) {
        // add all the header questions
        headerQuestions = this.translateKycQuestion(this.selectedEntityKyc.kycHeader.kycQuestions);
      }
    }
    this.form.get(this.FORM_HEADER_QUESTIONS)?.setValue(headerQuestions);

    // set the current question answers to the form
    let questions: IKycAnswer[] = [];
    if (!!this.selectedEntityKyc && !!this.selectedEntityKyc.kycSections) {
      for (let i = 0; i <= this.selectedEntityKyc.kycSections.length - 1; i++) {
        if (!!this.selectedEntityKyc.kycSections[i]) {
          // add all the section header questions
          if (!!this.selectedEntityKyc.kycSections[i].kycSectionHeader) {
            if (!!this.selectedEntityKyc.kycSections[i].kycSectionHeader.kycQuestions) {
              questions = questions.concat(this.translateKycQuestion(this.selectedEntityKyc.kycSections[i].kycSectionHeader.kycQuestions));
            }
          }
          // add all the section footer questions
          if (!!this.selectedEntityKyc.kycSections[i].kycSectionFooter) {
            if (!!this.selectedEntityKyc.kycSections[i].kycSectionFooter.kycQuestions) {
              questions = questions.concat(this.translateKycQuestion(this.selectedEntityKyc.kycSections[i].kycSectionFooter.kycQuestions));
            }
          }
        }
      }
    }
    this.form.get(this.FORM_QUESTIONS)?.setValue(questions);
    this.formDirty = false;
  }

  private translateKycQuestion(questions: IKycQuestion[]): IKycAnswer[] {
    let newQ: IKycAnswer[] = [];
    if (!!questions) {
      for (let i = 0; i <= questions.length - 1; i++) {
        // only add non-empty question answers
        if (questions[i].questionAnswer != '') {
          newQ.push({
            questionAnswerAttribute: questions[i].questionAnswerAttribute,
            questionAnswer: questions[i].questionAnswer
          } as IKycAnswer);
        }
      }
    }
    return newQ;
  }


  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    // this.lookup$.unsubscribe();
    this.selectedEntityKyc$.unsubscribe();
  }

  /**
   * Form panel step
   */
  setStep(step: string) {
    this.step = step;
  }

  nextPanel(sectionName: string) {
    if (this.canSave()) {
      this.saveKyc(false);
    }
    this.toPanel(sectionName, 1);
  }

  prevPanel(sectionName: string) {
    this.toPanel(sectionName, -1);
  }

  isFormDisabled(): boolean {
    let statusOk;
    switch (this.selectedEntityKyc.kycRondeivuStatus) {
      case 'SUBMITTED':
        statusOk = false;
        break;
      default:
        statusOk = true;
    }
    return this.selectedEntityKyc.archived || !statusOk;
  }

  /**
   * Determines if the kyc can be submitted by checking for the existence of section errors
   * */
  canSubmit(): boolean {
    let canSubmit = true;
    if (!!this.selectedEntityKyc && !!this.selectedEntityKyc?.kycSections) {
      for (let i = 0; i <= this.selectedEntityKyc.kycSections.length - 1; i++) {
        if (!!this.selectedEntityKyc.kycSections[i]?.errors) {
          if (this.selectedEntityKyc.kycSections[i]?.errors.length > 0) {
            canSubmit = false;
            break;
          }
        }
      }
    }
    return canSubmit && !this.formDirty && !this.isFormDisabled();
  }

  /**
   * Determines if the kyc can be saved based on status
   */
  canSave(): boolean {
    return !this.isFormDisabled();
  }

  getByIdFromFormCache(id: string, prop: string) {
    let ary = this.formCache.get(prop)?.value;
    return ary.filter((obj: any) => {
      return obj.id == id;
    })[0];
  }

  /*
  Kyc Entity Section people have to be translated into the form required
  for the put,  i.e. removing the errors attribute
  */
  private addKycPeopleToPropFromSection(prop: string, section: string) {
    let people: any[] = this.getKycSection(section)?.kycPeople || [];
    let noErrors = [];
    for (let i = 0; i <= people.length - 1; i++) {

      switch (prop) {
        case this.FORM_AUTH_PEOPLE:
        case this.FORM_DBO_PEOPLE:
        case this.FORM_IDBO_PEOPLE:
          noErrors.push({id: people[i].id, ownerShip: people[i].percentageOwnership});
          break;
        case this.FORM_REG_PEOPLE:
          noErrors.push({
            id: people[i].id,
            ownerShip: people[i].percentageOwnership,
            ownerOfVotingRight: people[i].ownerOfVotingRight,
            publicilyTradedCompany: people[i].publicilyTradedCompany
          });
          break;
        case this.FORM_PRIMARY_CONTACTS:
          noErrors.push({
            id: people[i].id,
            firstName: people[i].firstName,
            lastName: people[i].lastName,
            email: people[i].email
          });
          break;
        default:
          noErrors.push({id: people[i].id});
      }

    }
    this.form.get(prop)?.patchValue(noErrors);
  }

  /*
  Kyc Entity Section legal entities have to be translated into the form required
  for the put,  i.e. removing the errors attribute
  */
  private addKycLegalEntitiesToPropFromSection(prop: string, section: string) {
    let legalEntities: any[] = this.getKycSection(section)?.legalEntity || [];
    let noErrors = [];
    for (let i = 0; i <= legalEntities.length - 1; i++) {

      switch (prop) {
        case this.FORM_OB_ENT:
          noErrors.push({
            id: legalEntities[i].id,
            businessSourceofFund: legalEntities[i].businessSourceofFund,
            natureoftheBusiness: legalEntities[i].natureoftheBusiness
          });
          break;
        case this.FORM_DBO_ENT:
        case this.FORM_IDBO_ENT:
          noErrors.push({id: legalEntities[i].id, ownerShip: legalEntities[i].percentageOwnership});
          break;
        case this.FORM_REG_ENT:
          noErrors.push({
            id: legalEntities[i].id,
            ownerShip: legalEntities[i].percentageOwnership,
            authorizedSignatory: legalEntities[i].authorizedSignatory,
            companyName: legalEntities[i].companyName
          });
          break;
        default:
          noErrors.push({id: legalEntities[i].id});
      }

    }
    this.form.get(prop)?.patchValue(noErrors);
  }

  /**
   * Updates the form questions when the question answer is emitted
   * @param event
   */
  updateFormQuestion(event: IQuestionAnswer) {
    // clear out the form array based on the question attribute
    if (event.questionAnswer == 'false') {
      switch (event.questionAnswerAttribute) {
        case this.FORM_QUESTION_REG:
          this.form.get(this.FORM_REG_PEOPLE)?.setValue([]);
          break;
        case this.FORM_QUESTION_REG_2:
          this.form.get(this.FORM_REG_ENT)?.setValue([]);
          break;
        case this.FORM_QUESTION_DBO:
          this.form.get(this.FORM_DBO_PEOPLE)?.setValue([]);
          this.form.get(this.FORM_DBO_ENT)?.setValue([]);
          break;
        case this.FORM_QUESTION_IDBO:
          this.form.get(this.FORM_IDBO_PEOPLE)?.setValue([]);
          this.form.get(this.FORM_IDBO_ENT)?.setValue([]);
          break;
      }
    }
    this.updateCollectionQuestion(this.FORM_QUESTIONS, event);
    this.formDirty = true;
  }

  /**
   * Updates the form header questions when the question answer is emitted
   * @param event
   */
  updateFormHeaderQuestion(event: IQuestionAnswer) {
    this.updateCollectionQuestion(this.FORM_HEADER_QUESTIONS, event);
  }

  private updateCollectionQuestion(collection: string, event: IQuestionAnswer) {
    let questions = this.form.get(collection)?.value;
    console.log(collection + ' - ' + JSON.stringify(event));
    // init if null
    if (!questions) {
      questions = [];
    }
    // question exists already
    let attrFound = false;
    for (let i = 0; i <= questions.length - 1; i++) {
      if (questions[i].questionAnswerAttribute == event.questionAnswerAttribute) {
        attrFound = true;
        questions[i].questionAnswer = event.questionAnswer.toString();
      }
    }
    // question doesn't exist
    if (!attrFound) {
      questions.push({
        questionAnswerAttribute: event.questionAnswerAttribute,
        questionAnswer: event.questionAnswer.toString()
      });
    }
    this.form.get(collection)?.setValue(questions);
  }

  /**
   * Finds and returns the question in the question collection by attribute name
   * @param collection the question collection to search
   * @param attr the attribute name of the question
   */
  getQuestionByAttribute(collection: string, attr: string): IQuestionAnswer | null {
    let questions = this.form.get(collection)?.value;
    // init if null
    if (!questions) {
      questions = [];
    }
    // question exists already
    for (let i = 0; i <= questions.length - 1; i++) {
      if (questions[i].questionAnswerAttribute == attr) {
        return questions[i] as IQuestionAnswer;
      }
    }
    return null;
  }

  /**
   * Adds a person to the form array as indicated by the prop
   * @param prop the name of the form array property
   * @param level the required level
   */
  selectPersonForField(prop: string, level?: string) {
    if (!this.isFormCacheEmpty(this.FORM_CACHE_PEOPLE)) {
      let people = this.formCache.get(this.FORM_CACHE_PEOPLE)?.value;
      const delRef = this.dialog.open(KycPersonSelectModalComponent, {
        height: 'auto',
        width: this.util.getModalWidth(ModalSize.SMALL),
        maxWidth: '700px',
        disableClose: true,
        data: {
          people: people,
          level: level
        }
      });
      delRef.afterClosed().subscribe((result: { function: string, person: any }) => {
        if (!!result) {
          switch (result.function) {
            case ModalFunction.ADD:
              this.addPersonToCollection(prop, level);
              break;
            case ModalFunction.EDIT:
              this.editPerson(result.person, level);
              // add the person to the field
              let formValue = this.form.get(prop)?.value;
              formValue.push({id: result.person.id});
              this.form.get(prop)?.setValue(formValue);
              break;
            case ModalFunction.SELECT:
              let formArray = this.form.get(prop)?.value;
              formArray.push({id: result.person.id});
              this.form.get(prop)?.setValue(formArray);
              break;
          }
          this.formDirty = true;
        }
      });
    } else {
      const delRef = this.dialog.open(KycPersonModalComponent, {
        height: 'auto',
        width: this.util.getModalWidth(ModalSize.MEDIUM),
        disableClose: true,
        data: {
          level: level,
          disabled: this.isFormDisabled()
        }
      });
      delRef.afterClosed().subscribe(result => {
        if (!!result) {
          // create the new person and add it to the prop collection
          this.createPerson(result, prop);
          this.formDirty = true;
        }
      });
    }
  }

  /**
   * Adds a legal entity to the form array as indicated by the prop
   * @param prop the name of the form array property
   * @param level the level of the legal entity to add
   */

  // Reza 
  selectLegalEntityForField(prop: string, level?: string) {
    if (!this.isFormCacheEmpty(this.FORM_CACHE_BUSINESSES)) {
      let businesses = this.formCache.get(this.FORM_CACHE_BUSINESSES)?.value;
      const delRef = this.dialog.open(KycBusinessSelectModalComponent, {
        height: 'auto',
        width: this.util.getModalWidth(ModalSize.SMALL),
        maxWidth: '700px',
        disableClose: true,
        data: {
          businesses: businesses,
          level: level
        }
      });
      delRef.afterClosed().subscribe((result: { function: string, business: any }) => {
        if (!!result) {
          switch (result.function) {
            case ModalFunction.ADD:
              this.addBusinessToCollection(prop, level);
              break;
            case ModalFunction.EDIT:
              this.editBusiness(result.business, level);
              // add the person to the field
              let formValue = this.form.get(prop)?.value;
              formValue.push({id: result.business.id});
              this.form.get(prop)?.setValue(formValue);
              break;
            case ModalFunction.SELECT:
              let formArray = this.form.get(prop)?.value;
              formArray.push({id: result.business.id});
              this.form.get(prop)?.setValue(formArray);
              break;
          }
          this.formDirty = true;
        }
      });
    } else {
      const delRef = this.dialog.open(KycBusinessModalComponent, {
        height: 'auto',
        width: this.util.getModalWidth(ModalSize.MEDIUM),
        disableClose: true,
        data: {
          level: level,
          disabled: this.isFormDisabled()
        }
      });
      delRef.afterClosed().subscribe(result => {
        if (!!result) {
          // create the new legal entity and add it to the prop collection
          this.createLegalEntity(result, prop);
          this.formDirty = true;
        }
      });
    }
  }

  /**
   * Opens the person modal and adds result to the person list and the optionally provided collection
   * @param prop the collection to add the new person to
   * @param level the level of the person to add
   */
  addPersonToCollection(prop?: string, level?: string): void {
    const delRef = this.dialog.open(KycPersonModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.MEDIUM),
      disableClose: true,
      data: {
        level: level,
        disabled: this.isFormDisabled()
      }
    });

    delRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.createPerson(result, prop);
      }
    });
  }

  /**
   * Adds a new object with a generated id to the collection
   * @param collection the collection to add the new object to
   */
  addNewObjectToCollection(collection: string) {
    let objs = this.form.get(collection)?.value || [];
    objs.push({id: this.uuid.generateUUID()});
    this.form.get(collection)?.setValue(objs);
  }

  /**
   * Opens the legal entity modal and adds legal entity to main list and optionally provided collection
   * @param prop the collection to add the new legal entity to
   * @param level the required level
   */
  addBusinessToCollection(prop?: string, level?: string): void {
    const delRef = this.dialog.open(KycBusinessModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.MEDIUM),
      disableClose: true,
      data: {
        level: level,
        disabled: this.isFormDisabled()
      }
    });

    delRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.createLegalEntity(result, prop);
      }
    });
  }

  /**
   * Opens the person modal and replaces the person in the people collection
   * @param person
   * @param level
   */
  editPerson(person: IKycPerson, level?: string) {
    let levelCache = person.kycPersonType;
    if (!!level) {
      person.kycPersonType = level;
    }
    const delRef = this.dialog.open(KycPersonModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.MEDIUM),
      disableClose: true,
      data: {
        person: person,
        disabled: this.isFormDisabled()
      }
    });

    delRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.updatePerson(result);
      } else {
        person.kycPersonType = levelCache;
      }
    });
  }

  getFormCacheObjById(cacheProp: string, id: string) {
    let objs = this.formCache.get(cacheProp)?.value;
    for (let i = 0; i <= objs.length - 1; i++) {
      if (objs[i].id == id) {
        return objs[i];
      }
    }
  }

  /**
   * Prompts the user for delete confirmation if the natural person is not used in the form
   * @param person the natural person to remove
   */
  deletePerson(person: IKycPerson) {
    if (!this.isPersonUsedOnForm(person)) {
      const delRef = this.dialog.open(DeleteConfirmationModalComponent, {
        height: 'auto',
        maxWidth: this.util.getModalWidth(ModalSize.SMALL),
        disableClose: true,
        data: {
          person: person
        }
      });

      delRef.afterClosed().subscribe(result => {
        if (!!result) {
          this.removePerson(result.person);
        }
      });
    } else {
      this.toastr.warning("Person is in use on form!", $localize`:@@companyName:Rondeivu`);
    }
  }

  /**
   * Parses the errors for a natural person from the kyc object matching the kyc person
   * @param person the kyc person being matched
   * @param section the section to get the errors from
   */
  getPersonErrorsForSection(person: IKycPerson, section: string): IEntityKycError[] {
    let errors: IEntityKycError[] = [];
    if (!!this.selectedEntityKyc && !!this.selectedEntityKyc.kycSections) {
      for (let i = 0; i <= this.selectedEntityKyc.kycSections.length - 1; i++) {
        if (!!this.selectedEntityKyc.kycSections[i] && !!this.selectedEntityKyc.kycSections[i].kycPeople) {
          if (this.selectedEntityKyc.kycSections[i].kycSectionType == section) {
            const people = this.selectedEntityKyc.kycSections[i].kycPeople || [];
            for (let z = 0; z <= people.length - 1; z++) {
              if (people[z].id == person.id) {
                if (!!people[z].errors && people[z].errors.length > 0) {
                  errors = errors.concat(people[z].errors as IEntityKycError[]);
                }
              }
            }
          }
        }
      }
    }
    return errors;
  }

  isFormCollectionEmpty(collection: string): boolean {
    let formList = this.form.get(collection)?.value || [];
    return formList.length == 0;
  }

  /**
   * Parses the errors for a legal entity from the kyc object matching the kyc legal entity
   * @param legalEntity the kyc person being matched
   * @param section the section to get the errors from
   */
  getBusinessErrorsForSection(legalEntity: IKycLegalEntity, section: string): IEntityKycError[] {
    let errors: IEntityKycError[] = [];
    if (!!this.selectedEntityKyc && !!this.selectedEntityKyc.kycSections) {
      for (let i = 0; i <= this.selectedEntityKyc.kycSections.length - 1; i++) {
        if (!!this.selectedEntityKyc.kycSections[i] && !!this.selectedEntityKyc.kycSections[i].legalEntity) {
          if (this.selectedEntityKyc.kycSections[i].kycSectionType == section) {
            const legalEntities = this.selectedEntityKyc.kycSections[i].legalEntity || [];
            for (let z = 0; z <= legalEntities.length - 1; z++) {
              if (legalEntities[z].id == legalEntity.id) {
                if (!!legalEntities[z].errors && legalEntities[z].errors.length > 0) {
                  errors = errors.concat(legalEntities[z].errors as IEntityKycError[]);
                }
              }
            }
          }
        }
      }
    }
    return errors;
  }

  /**
   * Opens the business modal and replaces the business in the businesses collection
   * @param business
   * @param level
   */
  editBusiness(business: IKycLegalEntity, level?: string) {
    let levelCache = business.kycLegalEntityType;
    if (!!level) {
      business.kycLegalEntityType = level;
    }
    const delRef = this.dialog.open(KycBusinessModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.MEDIUM),
      disableClose: true,
      data: {
        business: business,
        disabled: this.isFormDisabled()
      }
    });

    delRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.updateLegalEntity(result);
      } else {
        business.kycLegalEntityType = levelCache;
      }
    });
  }

  /**
   * Prompts the user for delete confirmation if the legal entity is not being used in the form
   * @param business the legal entity to remove
   */
  deleteBusiness(business: IKycLegalEntity) {
    if (!this.isBusinessUsedOnForm(business)) {
      const delRef = this.dialog.open(DeleteConfirmationModalComponent, {
        height: 'auto',
        maxWidth: this.util.getModalWidth(ModalSize.SMALL),
        disableClose: true,
        data: {
          business: business
        }
      });

      delRef.afterClosed().subscribe(result => {
        if (!!result) {
          this.removeLegalEntity(result.business);
        }
      });
    } else {
      this.toastr.warning("Legal entity is in use on form!", $localize`:@@companyName:Rondeivu`);
    }
  }

  getPersonStatusColor(person: IKycPerson): string {
    return this.isPersonUsedOnForm(person) ? this.IN_USE_COLOR : this.NOT_IN_USE_COLOR;
  }

  getPersonStatusText(person: IKycPerson): string {
    return this.isPersonUsedOnForm(person) ? this.IN_USE_TEXT : this.NOT_IN_USE_TEXT;
  }

  getBusinessStatusColor(business: IKycLegalEntity): string {
    return this.isBusinessUsedOnForm(business) ? this.IN_USE_COLOR : this.NOT_IN_USE_COLOR;
  }

  getBusinessStatusText(business: IKycLegalEntity): string {
    return this.isBusinessUsedOnForm(business) ? this.IN_USE_TEXT : this.NOT_IN_USE_TEXT;
  }

  /**
   * Determines if the section exists on the kyc data
   * @param section the section type
   */
  isSection(section: string) {
    if (!!this.selectedEntityKyc && !!this.selectedEntityKyc.kycSections) {
      for (let i = 0; i <= this.selectedEntityKyc.kycSections.length - 1; i++) {
        if (!!this.selectedEntityKyc.kycSections[i]) {
          if (this.selectedEntityKyc.kycSections[i].kycSectionType == section) {
            return true;
          }
        }
      }
    }
    return false;
  }

  /**
   * Removes an object from a collection
   * @param control the name of the form control
   * @param idx the index of the object
   */
  deleteFormArray(control: string, idx: number) {
    const delRef = this.dialog.open(DeleteConfirmationModalComponent, {
      height: 'auto',
      maxWidth: this.util.getModalWidth(ModalSize.SMALL),
      disableClose: true,
      data: {
        index: idx
      }
    });

    delRef.afterClosed().subscribe(result => {
      if (!!result) {
        let arr = this.form.get(control)?.value;
        arr.splice(result.index, 1);
        this.form.get(control)?.setValue(arr);
        this.formDirty = true;
      }
    });
  }


  getKycSection(section: string) {
    if (!!this.selectedEntityKyc && !!this.selectedEntityKyc?.kycSections) {
      for (let i = 0; i <= this.selectedEntityKyc.kycSections.length - 1; i++) {
        if (this.selectedEntityKyc.kycSections[i]?.kycSectionType == section) {
          return this.selectedEntityKyc.kycSections[i];
        }
      }
    }
    return {} as unknown as IKycSection;
  }

  getSectionColor(section: string) {
    const kycSection = this.getKycSection(section);
    if (!!kycSection.errors && kycSection.errors.length > 0) {
      return 'red';
    }
    return 'green';
  }

  getSectionIcon(section: string) {
    const kycSection = this.getKycSection(section);
    if (!!kycSection.errors && kycSection.errors.length > 0) {
      return 'error_outline';
    }
    return 'check_circle';
  }

  getSectionTooltip(section: string) {
    const kycSection = this.getKycSection(section);
    if (!!kycSection.errors && kycSection.errors.length > 0) {
      return kycSection.errors[0].errorMessage;
    }
    return '';
  }

  saveAdminSettings() {
    let payload = {
      kycId: this.form.get('id')?.value,
      kycRondeivuStatus: this.form.get(this.FORM_STATUS)?.value,
      publicComments: this.form.get(this.FORM_PUB_COMM)?.value,
      privateComments: this.form.get(this.FORM_PRIV_COMM)?.value,
      kycName: this.form.get(this.FORM_KYC_NAME)?.value,
      archived: this.form.get(this.FORM_ARCHIVED)?.value,
    }
    console.log("Updating Entity KYC Admin Settings...");
    this.store.dispatch(toggleLoading({loading: true}));
    this.entityKycService.updateEntityKycAdminSettings(payload).subscribe({
      next: () => {
        this.fetchAndDispatchEntityKyc();
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Entity KYC Admin Settings Updated!", $localize`:@@companyName:Rondeivu`);
      }, error: (error: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to update entity kyc admin settings!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  saveKyc(shortSpinner?: boolean) {
    console.log("Updating Entity KYC...");

    // start the spinner, if it is a next panel action the spinner will be stopped by the load of kyc
    this.store.dispatch(toggleLoading({loading: true}));

    this.entityKycService.updateEntityKyc(this.form.getRawValue()).subscribe({
      next: () => {
        this.fetchAndDispatchEntityKyc();
        if (shortSpinner) {
          this.store.dispatch(toggleLoading({loading: false}));
        }
        this.toastr.success("Entity KYC Updated!", $localize`:@@companyName:Rondeivu`);
      }, error: (error: any) => {
        if (shortSpinner) {
          this.store.dispatch(toggleLoading({loading: false}));
        }
        this.toastr.error("Unable to update entity kyc!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  submitKyc() {
    if (this.canSubmit()) {
      const delRef = this.dialog.open(ConfirmationModalComponent, {
        height: 'auto',
        maxWidth: this.util.getModalWidth(ModalSize.SMALL),
        disableClose: true,
        data: {}
      });

      delRef.afterClosed().subscribe(result => {
        if (!!result) {
          this.submitKycToApi();
        }
      });
    }
  }

  private submitKycToApi() {
    console.log("Submitting Entity KYC...");
    this.store.dispatch(toggleLoading({loading: true}));
    this.entityKycService.submitEntityKyc(this.form.getRawValue()).subscribe({
      next: () => {
        this.fetchAndDispatchEntityKyc();
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Entity KYC Submitted!", $localize`:@@companyName:Rondeivu`);
      }, error: (error: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to submit entity kyc!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  private fetchAndDispatchEntityKyc() {
    this.entityKycService.getEntityKycById(this.selectedEntityKycId).subscribe({
      next: (kyc: any) => {
        this.store.dispatch(updateSelectedEntityKyc({entityKyc: kyc}));
      }, error: (error: any) => {
        this.toastr.error("Unable to get entity kyc!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  private toPanel(sectionName: string, step: number) {
    let section = this.getKycSection(sectionName);
    let idx = this.selectedEntityKyc.kycSections.indexOf(section);
    let nextSection = this.selectedEntityKyc.kycSections[idx + step];

    if (!!nextSection) {
      this.setStep(nextSection.kycSectionType);
    }
  }

  /**
   * KYC PEOPLE
   */
  private fetchPeople() {
    this.store.dispatch(toggleLoading({loading: true}));
    console.log("Fetching Entity KYC People...");
    this.entityKycPersonService.getKycPeopleByKycId(this.selectedEntityKycId).subscribe({
      next: (people: IKycPerson[]) => {
        console.log("Fetch Persons", people);
        this.formCache.get(this.FORM_CACHE_PEOPLE)?.setValue(people);
        // this.refreshFormErrorsForPeople(); // Reza 
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: (error: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get kyc people!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  private updatePerson(person: IKycPerson) {
    this.store.dispatch(toggleLoading({loading: true}));
    console.log("Updating Entity KYC People...");
    this.entityKycPersonService.updateKycPerson(person).subscribe({
      next: () => {
        this.fetchPeople();
        this.toastr.success("Person Updated!", $localize`:@@companyName:Rondeivu`);
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: (error: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to update kyc person!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  private removePerson(person: IKycPerson) {
    this.store.dispatch(toggleLoading({loading: true}));
    console.log("Removing Entity KYC People...");
    this.entityKycPersonService.deleteKycPerson(this.selectedEntityKycId, person.id).subscribe({
      next: () => {
        this.fetchPeople();
        this.toastr.success("Person Removed!", $localize`:@@companyName:Rondeivu`);
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: (error: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to remove kyc person!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  private isPersonUsedOnForm(person: IKycPerson): boolean {
    return this.idUsedInFormPropData(person.id, this.FORM_OB_PEOPLE) ||
      this.idUsedInFormPropData(person.id, this.FORM_CTRL_PEOPLE) ||
      this.idUsedInFormPropData(person.id, this.FORM_DBO_PEOPLE) ||
      this.idUsedInFormPropData(person.id, this.FORM_IDBO_PEOPLE) ||
      this.idUsedInFormPropData(person.id, this.FORM_TRUST_PEOPLE) ||
      this.idUsedInFormPropData(person.id, this.FORM_TRUST_SET_PEOPLE) ||
      this.idUsedInFormPropData(person.id, this.FORM_TRUST_BEN_PEOPLE) ||
      this.idUsedInFormPropData(person.id, this.FORM_GB_PEOPLE) ||
      this.idUsedInFormPropData(person.id, this.FORM_AUTH_PEOPLE) ||
      this.idUsedInFormPropData(person.id, this.FORM_DIRECTORS);

  }

  private isBusinessUsedOnForm(business: IKycLegalEntity): boolean {
    return this.idUsedInFormPropData(business.id, this.FORM_OB_ENT) ||
      this.idUsedInFormPropData(business.id, this.FORM_REG_ENT) ||
      this.idUsedInFormPropData(business.id, this.FORM_DBO_ENT) ||
      this.idUsedInFormPropData(business.id, this.FORM_IDBO_ENT) ||
      this.idUsedInFormPropData(business.id, this.FORM_TRUST_ENT) ||
      this.idUsedInFormPropData(business.id, this.FORM_TRUST_SET_ENT) ||
      this.idUsedInFormPropData(business.id, this.FORM_TRUST_BEN_ENT) ||
      this.idUsedInFormPropData(business.id, this.FORM_GB_ENT);
  }

  private idUsedInFormPropData(id: string, prop: string): boolean {
    let data = this.form.get(prop)?.value;
    if (!!data) {
      for (let i = 0; i <= data.length - 1; i++) {
        if (data[i].id == id) {
          return true;
        }
      }
    }

    return false;
  }

  /**
   * Creates person via api and adds result to collection if prop is present
   * @param person the person to add
   * @param prop the prop collection the person is added to
   * @private
   */
  private createPerson(person: IKycPerson, prop?: string) {
    this.store.dispatch(toggleLoading({loading: true}));
    person.businessUnitEntityKycId = this.selectedEntityKyc.id;
    console.log("Adding Entity KYC Person...");
    this.entityKycPersonService.addKycPerson(person).subscribe({
      next: (person: IKycPerson) => {
        //if the prop is sent add person to that collection
        if (!!prop) {
          let people = this.form.get(prop)?.value || [];
          people.push(person);
          this.form.get(prop)?.setValue(people);
        }
        this.fetchPeople();
        this.toastr.success("Person Added!", $localize`:@@companyName:Rondeivu`);
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: (error: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to add kyc person!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  private refreshFormErrorsForPeople() {
    let naturalPersons = this.formCache.get(this.FORM_CACHE_PEOPLE)?.value;
    // loop each natural person
    for (let i = 0; i <= naturalPersons.length - 1; i++) {
      // loop each section and look for the person
      for (let j = 0; j <= this.selectedEntityKyc.kycSections.length - 1; j++) {
        let sectionPeople = this.selectedEntityKyc.kycSections[j].kycPeople || [];
        for (let k = 0; k <= sectionPeople.length - 1; k++) {
          // if the person exists apply the new errors
          if (sectionPeople[k].id == naturalPersons[i].id) {
            let sectionErrors: IEntityKycError[] = [];
            for (let h = 0; h <= naturalPersons[i].errors.length - 1; h++) {
              if (naturalPersons[i].errors[h].kycSectionType == this.selectedEntityKyc.kycSections[j].kycSectionType) {
                sectionErrors.push(naturalPersons[i].errors[h]);
              }
            }
            // apply the new errors to the section people
            sectionPeople[k].errors = sectionErrors;
            this.selectedEntityKyc.kycSections[j].kycPeople = sectionPeople;
          }
        }
      }
    }
  }

  /**
   * KYC LEGAL ENTITIES
   */
  private fetchLegalEntities() {
    console.log("Fetching Entity KYC Legal Entiies...");
    this.entityKycBusinessService.getKycLegalEntityByKycId(this.selectedEntityKycId).subscribe({
      next: (legalEntities: IKycLegalEntity[]) => {
        console.log("Fetch Legal Entities", legalEntities);
        this.formCache.get(this.FORM_CACHE_BUSINESSES)?.setValue(legalEntities);
        // this.refreshFormErrorsForBusinesses(); // Reza
      }, error: (error: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get kyc legal entities!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  private updateLegalEntity(legalEntity: IKycLegalEntity) {
    this.store.dispatch(toggleLoading({loading: true}));
    console.log("Updating Entity KYC Legal Entity...");
    this.entityKycBusinessService.updateKycLegalEntity(legalEntity).subscribe({
      next: () => {
        this.fetchLegalEntities();
        this.toastr.success("Legal Entity Updated!", $localize`:@@companyName:Rondeivu`);
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: (error: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to update kyc legal entity!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  private removeLegalEntity(legalEntity: IKycLegalEntity) {
    this.store.dispatch(toggleLoading({loading: true}));
    console.log("Removing Entity KYC Legal Entity...");
    this.entityKycBusinessService.deleteKycLegalEntity(this.selectedEntityKycId, legalEntity.id).subscribe({
      next: () => {
        this.fetchLegalEntities();
        this.toastr.success("Legal Entity Removed!", $localize`:@@companyName:Rondeivu`);
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: (error: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to remove kyc legal entity!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  /**
   * Creates legal entity via api and adds result to collection if prop is present
   * @param legalEntity the legal entity to add
   * @param prop the prop collection the legal entity is added to
   * @private
   */
  private createLegalEntity(legalEntity: IKycLegalEntity, prop?: string) {
    this.store.dispatch(toggleLoading({loading: true}));
    legalEntity.businessUnitEntityKycId = this.selectedEntityKyc.id;
    console.log("Adding Entity KYC Legal Entity...");
    this.entityKycBusinessService.addKycLegalEntity(legalEntity).subscribe({
      next: (legalEntity: IKycLegalEntity) => {
        //if the prop is sent add person to that collection
        if (!!prop) {
          let entities = this.form.get(prop)?.value || [];
          entities.push(legalEntity);
          this.form.get(prop)?.setValue(entities);
        }
        this.fetchLegalEntities();
        this.toastr.success("Legal Entity Added!", $localize`:@@companyName:Rondeivu`);
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: (error: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to add kyc legal entity!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  private refreshFormErrorsForBusinesses() {
    let legalEntities = this.formCache.get(this.FORM_CACHE_BUSINESSES)?.value;
    // loop each legal entity
    for (let i = 0; i <= legalEntities.length - 1; i++) {
      // loop each section and look for the legal entity
      for (let j = 0; j <= this.selectedEntityKyc.kycSections.length - 1; j++) {
        let sectionLegalEntities = this.selectedEntityKyc.kycSections[j].legalEntity || [];
        for (let k = 0; k <= sectionLegalEntities.length - 1; k++) {
          // if the legal entity exists apply the new errors
          if (sectionLegalEntities[k].id == legalEntities[i].id) {
            let sectionErrors: IEntityKycError[] = [];
            for (let h = 0; h <= legalEntities[i].errors.length - 1; h++) {
              if (legalEntities[i].errors[h].kycSectionType == this.selectedEntityKyc.kycSections[j].kycSectionType) {
                sectionErrors.push(legalEntities[i].errors[h]);
              }
            }
            // apply the new errors to the section legal entities
            sectionLegalEntities[k].errors = sectionErrors;
            this.selectedEntityKyc.kycSections[j].legalEntity = sectionLegalEntities;
          }
        }
      }
    }
  }

  importNaturalPersons() {
    this.store.dispatch(toggleLoading({loading: true}));
    console.log("Fetching Entity Natural Persons...");
    this.entityKycPersonService.getKycPeopleToImport(this.selectedEntityKycId).subscribe({
      next: (res: IKycEntityImportPerson[]) => {
        console.log(res);
        this.openImportModal('people', res);
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: (error: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get natural persons!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  importLegalEntities() {
    this.store.dispatch(toggleLoading({loading: true}));
    console.log("Fetching Entity Legal Entities...");
    this.entityKycBusinessService.getKycLegalEntityToImport(this.selectedEntityKycId).subscribe({
      next: (res: IKycEntityImportLegalEntity[]) => {
        console.log(res);
        this.openImportModal('legalentities', res);
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: (error: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get legal entities!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  private openImportModal(type: string, collection: IKycEntityImportPerson[] | IKycEntityImportLegalEntity[]) {
    const delRef = this.dialog.open(KycImportModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      disableClose: true,
      data: {
        type: type,
        collection: collection
      }
    });
    delRef.afterClosed().subscribe((result: string[]) => {
      if (!!result) {
        switch (type) {
          case 'people':
            this.applyPeopleImport(result);
            break;
          case 'legalentities':
            this.applyBusinessImport(result);
            break;
        }
      }
    });
  }

  private applyPeopleImport(ids: string[]) {
    this.store.dispatch(toggleLoading({loading: true}));
    console.log("Importing Entity Natural Persons...");
    this.entityKycService.importPeople(this.selectedEntityKycId, ids).subscribe({
      next: (res: any) => {
        this.fetchPeople();
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: (error: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to import natural persons!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  private applyBusinessImport(ids: string[]) {
    this.store.dispatch(toggleLoading({loading: true}));
    console.log("Importing Entity Legal Entities...");
    this.entityKycService.importLegalEntities(this.selectedEntityKycId, ids).subscribe({
      next: (res: any) => {
        this.fetchLegalEntities();
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: (error: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to import legal entities!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }


  /**
   * Determines if a form control array is empty
   * @param prop the name of the form control
   */
  private isFormCacheEmpty(prop: string) {
    let formArray = this.formCache.get(prop)?.value;
    if (!!formArray) {
      return formArray.length == 0;
    }
    return true;
  }

}
