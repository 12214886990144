import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from "@angular/common/http";
import {
  BusinessUnitDealsComponent,
  BusinessUnitEmployeeInfoComponent, BusinessUnitEmployeeInnerNavigationComponent,
  BusinessUnitEmployeeModalComponent, BusinessUnitEmployeeNavigationComponent,
  BusinessUnitEmployeePermissionsComponent,
  BusinessUnitEmployeesComponent, BusinessUnitEntityComponent,
  BusinessUnitInfoComponent,
  BusinessUnitKycComponent,
  BusinessUnitMandatesComponent,
  BusinessUnitModalComponent,
  BusinessUnitsNavigationComponent
} from './components';
import {BusinessUnitsRoutingModule} from "./business-units-routing.module";
import {BusinessUnitsListComponent} from './components';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BusinessUnitService} from "./services";
import {NgMaterialModule} from "../ng-material/ng-material.module";
import {SharedModule} from "../shared/shared.module";



@NgModule({
  declarations: [
    BusinessUnitsNavigationComponent,
    BusinessUnitsListComponent,
    BusinessUnitModalComponent,
    BusinessUnitInfoComponent,
    BusinessUnitDealsComponent,
    BusinessUnitMandatesComponent,
    BusinessUnitEmployeesComponent,
    BusinessUnitKycComponent,
    BusinessUnitEmployeeModalComponent,
    BusinessUnitEmployeeInfoComponent,
    BusinessUnitEmployeePermissionsComponent,
    BusinessUnitEmployeeNavigationComponent,
    BusinessUnitEmployeeInnerNavigationComponent,
    BusinessUnitEntityComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BusinessUnitsRoutingModule,
    HttpClientModule,
    NgMaterialModule,
    SharedModule
  ],
  providers:[BusinessUnitService]
})
export class BusinessUnitsModule {}
