<div class="rm-header">
  <span>Edit Deal Team Member</span>
</div>

<div class="row" style="padding-top: 15px;">
  <!--        user menu button-->
  <app-user-card [firstName]="data.member['firstName'] "
                 [lastName]="data.member['lastName']"
                 [displayName]="data.member['email']"
                 [borderColor]="'transparent'"
                 [background]="'#F1EDE4'"
                 [sideColor]="'#002150'"
                 [image]="data.member['imageUrl']"
  ></app-user-card>
</div>


<form [formGroup]="userForm" (ngSubmit)="submit()">
  <mat-dialog-content>
    <!-- row start-->
    <div class="row form-row" style="padding-top: 25px;">
      <!--  Team Member Role-->
      <div class="col-11">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Team Member Role</mat-label>
          <mat-select id="tu1r" formControlName="role">
            <mat-option *ngFor="let p of lookups.teamUserRoleTypes" [value]="p.key">
              {{ p.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-1" style="padding-top: 15px;">
        <mat-icon color="primary" class="info-icon"
                  matTooltip="The role of the team member as determined by the team lead."
                  matTooltipPosition="right">
          info
        </mat-icon>
      </div>
      <!--  Team Member Role End-->
    </div>
    <!--User Note Permission-->
    <div class="row form-row">
      <div class="col-11">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>User Deal Note Permissions</mat-label>
          <mat-select formControlName="dealNotePermission">
            <mat-option *ngFor="let r of lookups.dealNotesAccessTypes" [value]="r.key">
              {{ r.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-1" style="padding-top: 15px;">
        <mat-icon color="primary" class="info-icon"
                  matTooltip="Allows this deal team member to modify notes about the deal."
                  matTooltipPosition="right">info
        </mat-icon>
      </div>
    </div>
    <!-- row end-->
    <!-- row start-->
    <div class="row form-row" *ngIf="isSelectedDealTeamType('investor')">
      <div class="col-11">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Root Folders</mat-label>
          <mat-select [multiple]="true" formControlName="rootFolders"
                      [compareWith]="rootCompare">
            <mat-option *ngFor="let f of config.selected_deal_root_folders" [value]="f">
              {{ f.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-1" style="padding-top: 15px;">
        <mat-icon color="primary" class="info-icon"
                  matTooltip="The data room root folders that this deal team member can access."
                  matTooltipPosition="right">info
        </mat-icon>
      </div>
    </div>
    <!-- row end-->
    <!-- row start-->
    <div class="row form-row" *ngIf="isSelectedDealTeamType('issuer')">
      <div class="col-6">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Enable Data Room Upload?</mat-label>
          <mat-select id="dm" formControlName="canUploadDataroom">
            <mat-option [value]="true">Yes</mat-option>
            <mat-option [value]="false">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-1" style="padding-top: 15px;">
        <mat-icon color="primary" class="info-icon"
                  matTooltip="Allows this deal team member to upload files to the data room."
                  matTooltipPosition="right">info
        </mat-icon>
      </div>
    </div>
  </mat-dialog-content>

  <!--  row end-->
  <mat-dialog-actions class="row justify-content-center" style="padding-top: 25px;">
    <div class="col-6">
      <button mat-raised-button type="button" (click)="dialogRef.close()">Cancel</button>
    </div>
    <div class="col-6 text-end">
      <button mat-raised-button color="primary" type="submit">SUBMIT</button>
    </div>
  </mat-dialog-actions>
</form>


