<div class="rm-header">
  <span>Indicate Interest</span>
</div>
<form [formGroup]="fg" (ngSubmit)="submit()">
  <mat-dialog-content>
    <div class="row justify-content-center pt-4">
      <div class="col-12">
        <p>
          To express your interest in <b>'{{ getSelectedDealName() }}'</b>, please complete the form below. We will inform you of your final allocation once it has been confirmed.
        </p>
        <p>
          Submitting this Indication of Interest is non-binding and does not constitute a commitment to invest.
          Completing this form does not guarantee that you will receive an allocation.
        </p>
      </div>
    </div>
      <div class="row justify-content-center pt-4">
    
        <!-- Amount -->
        <div class="col-6">
          <mat-form-field class="form-field" appearance="outline" >
            <mat-label>Indication of Interest Amount (USD)</mat-label>
            <input id="amt"
                  name="amt"
                  type="number"
                  [min]="0"
                  formControlName="amount"
                  matInput
                  required
            >
            <mat-error *ngIf="fg.get('amount')?.hasError('required')">Please indicate an amount</mat-error>
            <mat-error *ngIf="fg.get('amount')?.hasError('min')">Indication ammount cannot be below 1,000</mat-error>
            <mat-error *ngIf="fg.get('amount')?.hasError('max')">Indication ammount cannot exceed 1,000,000,000</mat-error>
            <mat-hint align="end">Enter the amount in full (e.g. 5000000 for $5 Million)</mat-hint>
          </mat-form-field>
        </div>
    
        <!-- Restriction Type -->
        <div class="col-6">
          <mat-form-field class="form-field" appearance="outline">
            <mat-select formControlName="restrictionType" placeholder="Restriction Type">
              <mat-option *ngFor="let r of lookupService.lookups.indicationOfInterestRestrictionTypes" [value]="r.key">
                {{ r.value }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="fg.get('restrictionType')?.hasError('required')">Please select one</mat-error>
            <mat-hint align="end">Choose the one that describes your interest restrictions</mat-hint>
          </mat-form-field>
        </div>
    
        <!-- Additional Notes -->
        <div class="col-12 pt-3">
          <h2>Additional Notes</h2>
          <p>Please provide any additional notes regarding your indication of interest here, such as contingencies or
            ongoing due diligence processes.
            Notes will be visible to Rondeivu and the Issuer.
          </p>
          <mat-form-field class="form-field" floatLabel="never">
            <textarea id="notes"
                      name="notes"
                      formControlName="notes"
                      matInput
                      placeholder="Type additional notes here..."
            ></textarea>
          </mat-form-field>
        </div>
      </div>
  </mat-dialog-content>
  <mat-dialog-actions class="space-between">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="!fg.valid"
            >Submit Indication of Interest
    </button>
  </mat-dialog-actions>
</form>
