<div style="height:calc(100vh - 112px); overflow-y: auto;">
  <div class="container-fluid">
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-11">
        <span class="rm-title rm-title-3">Company Settings</span><br/>
        <span class="rm-title rm-title-6 rm-lighter">These are all of the employees for your company:</span>
      </div>
    </div>
    <br/>
    <div class="row justify-content-center" style="padding-bottom: 25px;">
      <div class="col-11">
        <app-user-card [firstName]="selectedBusinessUnit.displayName"
                       [displayName]="selectedBusinessUnit.slug"
                       [borderColor]="'transparent'"
                       [sideColor]="'#ff0000'"
                       [size]="userCardSize.LG"
                       [image]="selectedBusinessUnit.imageUrl"
        ></app-user-card>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-11 text-start">
        <button type="button" color="primary" mat-raised-button (click)="openImageUploadModal()">UPLOAD IMAGE</button>&nbsp;
        <button type="button" color="warn" mat-raised-button (click)="clearImage()">CLEAR IMAGE</button>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-11 text-end">
        <button mat-raised-button color="primary" style="height: auto;width: auto;" (click)="showCreateModal()">
          <mat-icon>person_add</mat-icon>&nbsp;ADD EMPLOYEE
        </button>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <table mat-table [dataSource]="dataSource" [multiTemplateDataRows]="true" matSort>
                <!-- Search Header -->
                <ng-container matColumnDef="table-name">
                  <th colspan="3" mat-header-cell *matHeaderCellDef>
                    <span style="font-size: large;">Employees</span>
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="3" mat-header-cell *matHeaderCellDef>
                    <form>
                      <mat-form-field style="width: 100%;">
                        <mat-label>Search</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input>
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form>
                  </th>
                </ng-container>
                <!-- Employee Column -->
                <ng-container matColumnDef="employee">
                  <th mat-header-cell *matHeaderCellDef> Employee</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    <app-user-card [firstName]="element.user?.['firstName'] "
                                   [lastName]="element.user?.['lastName']"
                                   [displayName]="element.user?.['email']"
                                   [borderColor]="'transparent'"
                                   [sideColor]="'#002150'"
                                   [image]="element.user?.['imageUrl']"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- Title Column -->
                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef> Title</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{element['title']}}
                  </td>
                </ng-container>
                <!-- Role Column -->
                <ng-container matColumnDef="role">
                  <th mat-header-cell *matHeaderCellDef> Role</th>
                  <td mat-cell *matCellDef="let element"> {{element["role"]}}
                  </td>
                </ng-container>
                <!-- Member Invite Column -->
                <ng-container matColumnDef="invite">
                  <th mat-header-cell *matHeaderCellDef> Invite Status</th>
                  <td mat-cell *matCellDef="let element">
                    <span [appStatus]="element.memberInviteStatus">{{element.memberInviteStatus}} </span>
                  </td>
                </ng-container>
                <!-- Actions Column -->
                <ng-container matColumnDef="Edit">
                  <th mat-header-cell *matHeaderCellDef> Actions</th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-icon-button *ngIf="element?.['userCanEdit']" (click)="editEmployee(element)">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button *ngIf="element?.['userCanDelete']" (click)="delete(element)">
                      <mat-icon color="warn">delete</mat-icon>
                    </button>
                    <button mat-icon-button (click)="viewInfo(element)">
                      <mat-icon color="primary">info</mat-icon>
                    </button>
                    <button mat-icon-button (click)="viewPermissions(element)">
                      <mat-icon color="primary">list</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <!--                expand-->
                <ng-container matColumnDef="expand">
                  <th mat-header-cell *matHeaderCellDef aria-label="row actions" style="width: 50px;">&nbsp;</th>
                  <td mat-cell *matCellDef="let approval" style="width: 50px;">
                    <button mat-icon-button aria-label="expand row"
                            (click)="(expandedElement = expandedElement === approval ? null : approval); $event.stopPropagation()">
                      <mat-icon *ngIf="expandedElement !== approval">keyboard_arrow_down</mat-icon>
                      <mat-icon *ngIf="expandedElement === approval">keyboard_arrow_up</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                  <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                    <div class="example-element-detail"
                         [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="['table-name','search-header']"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                    class="example-element-row"
                    [class.example-expanded-row]="expandedElement === element">
                  <!--              (click)="expandedElement = expandedElement === element ? null : element">-->
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter.</td>
                </tr>
              </table>
            </div>
          </div>
          <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[5, 10, 25, 100]"
                         aria-label="Select page of users"></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
